import { createSlice } from '@reduxjs/toolkit';

const initialState = {
  isLoading: false,
  error: false,
  errorResponse: {},
  pagination: {
    totalItems: 0,
    currentPage: 1,
    itemsPerPage: 10,
    sortBy: '',
    sortOrder: 'asc',
    ponum: '',
    siteid: '',
    vendor: '',
    vendorid: '',
    caastatus: '',
    forInvoice: '',
  },
  inputList: [
    {
      FilterValue: '',
      input_rank: null,
      selectFilterValue: '',
      selectFilterLabel: '',
      presentIcon: '',
      filerForm: false,
      FilterLabel: '',
      selectedFilterRecords: [],
    },
  ],
  items: [],
  recordItems: [],
};

const purchaseOrderSlice = createSlice({
  name: 'purchaseOrderSlice',
  initialState,
  reducers: {
    fetchPurchaseOrders(state, action) {
      const stateValues = { ...state };
      stateValues.isLoading = true;
      stateValues.pagination = { ...stateValues.pagination, ...action.payload };
      return { ...stateValues };
    },

    fetchSelectedPurchaseOrderList(state) {
      state.isLoading = false;
    },

    setAddNewInputFilterData(state, action) {
      const stateValues = { ...state };
      stateValues.inputList = [...stateValues.inputList, action.payload];
      return { ...stateValues };
    },

    setPOPagination(state, action) {
      const stateValues = { ...state };
      stateValues.pagination = { ...stateValues.pagination, ...action.payload };
      return { ...stateValues };
    },

    setInputFilterData(state, action) {
      if (action.payload.length != 0) {
        state.inputList = action.payload;
      } else {
        const stateValues = { ...state };
        stateValues.inputList = [...initialState.inputList];
        return { ...stateValues };
      }
    },

    setPurchaseOrders(state, action) {
      const poList = [];
      action.payload?.rows.forEach(obj => {
        poList.push({
          poId: obj.poid,
          contractNumber: obj.contractrefnum,
          poNumber: obj.ponum,
          vendor: obj.vendorVendor?.vendor_name,
          startDate: obj.startdate,
          endDate: obj.enddate,
          status: obj.caastatus,
        });
      });
      const stateValues = { ...state };
      stateValues.items = [...poList];
      stateValues.pagination = {
        ...stateValues.pagination,
        ...action.payload?.pagination,
      };

      return { ...stateValues };
    },

    setSelectedPurchsaeOrderRecords(state, action) {
      const selectedPurchsaeOrderRecords = [];
      action.payload?.forEach(obj => {
        let objKeys = Object.keys(obj);
        let objValues = Object.values(obj);
        selectedPurchsaeOrderRecords.push({
          label: objValues[0],
          value: objValues[0],
        });
      });
      const stateValues = { ...state };
      stateValues.recordItems = [...selectedPurchsaeOrderRecords];
      return { ...stateValues };
    },

    resetPurchaseOrders() {
      return { ...initialState };
    },

    unsetError(state) {
      const stateValues = { ...state };
      stateValues.error = false;
      stateValues.errorResponse = {};
      return { ...stateValues };
    },

    setError(state, action) {
      const stateValues = { ...state };
      stateValues.error = true;
      stateValues.errorResponse = action.payload;
      return { ...stateValues };
    },

    setIsLoading(state, action) {
      const stateValues = { ...state };
      stateValues.isLoading = action.payload;
      return { ...stateValues };
    },
  },
});

export const {
  fetchPurchaseOrders,
  fetchSelectedPurchaseOrderList,
  setPOPagination,
  setPurchaseOrders,
  setSelectedPurchsaeOrderRecords,
  setInputFilterData,
  setAddNewInputFilterData,
  resetPurchaseOrders,
  setError,
  unsetError,
  setIsLoading,
} = purchaseOrderSlice.actions;

export default purchaseOrderSlice.reducer;
