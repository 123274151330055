import DatePicker from 'react-datepicker';
import { useState, useEffect } from 'react';
import 'react-datepicker/dist/react-datepicker.css';
import { Button, Form, Tab, Table, Tabs, Row, Col } from 'react-bootstrap';
import ReactSelect from 'react-select';
import './AuditDetails.scss';
import { useDispatch, useSelector } from 'react-redux';
import {
  setAuditData,
  fetchCircuits,
} from 'store/slices/AuditModules/auditSlice';
import { isEmptyVal } from 'utils/utils';
import _ from 'lodash';

const CrewAudits = () => {
  const [auditDate, setAuditDate] = useState(new Date());
  const [auditQuater, setAuditQuater] = useState();
  const [contractorData, setContractorData] = useState({});
  const [selectedCircuit, setCircuit] = useState({});
  const audit = useSelector(state => state.audit);
  const auditData = useSelector(state => state.audit.auditFormData);
  const [filterCircuitData, setFilterCircuitData] = useState([]);

  const auditContactors = audit?.contractors
    ?.slice()
    .sort((option1, option2) =>
      String(option1?.contractorname)
        .trim()
        .localeCompare(String(option2?.contractorname).trim()),
    );

  const auditAuditors = audit?.auditors
    ?.slice()
    .sort((option1, option2) =>
      String(option1?.auditorName)
        .trim()
        .localeCompare(String(option2?.auditorName).trim()),
    );

  const circuitList = audit?.circuits
    ?.slice()
    .sort((option1, option2) =>
      String(option1?.circuitnum)
        .trim()
        .localeCompare(String(option2?.circuitnum).trim()),
    );
  const [defaultCircuit, setDefaultCircuit] = useState({
    label: 'Select',
    value: '',
  });

  useEffect(() => {
    handleAuditDate(auditDate);
    getCircuitptions();
  }, []);

  useEffect(() => {
    setDefaultCircuit({
      label: audit?.auditFormData?.circuitNmbr
        ? audit?.auditFormData?.circuitNmbr
        : 'Select Circuit Number',
      value: audit?.auditFormData?.circuitNmbr
        ? audit?.auditFormData?.circuitNmbr
        : '',
    });
  }, [auditData]);

  const dispatch = useDispatch();

  const handleContractor = e => {
    const selectedContractor = audit.contractors.find(
      item => item.contractorname === e.target.value,
    );
    dispatch(
      setAuditData({
        contractrefid: selectedContractor?.contractrefid,
      }),
    );
    setContractorData(selectedContractor);
    dispatch(setAuditData(selectedContractor));
  };

  const handleCircuitChange = e => {
    const selectedOption = audit?.circuits.find(
      item => item.circuitnum === e?.value,
    );
    setCircuit(selectedOption);
    dispatch(
      setAuditData({
        circuitNmbr: selectedOption?.circuitnum,
        opco: selectedOption?.opco,
        state: selectedOption?.state,
        workAreaShortDesc: selectedOption?.workareaid,
      }),
    );
    //setDefaultCircuit(Number(selectedOption.circuitid));
    setDefaultCircuit({
      label: e?.value,
      value: e?.value,
    });
  };

  const handleInputChange = value => {
    if (!isEmptyVal(value)) {
      dispatch(
        fetchCircuits({
          search: value,
        }),
      );
    }
  };

  const debouncedInputChange = _.debounce(handleInputChange, 500);

  const handleAuditDate = date => {
    let quater = Math.ceil((date.getMonth() + 1) / 3);
    setAuditQuater(quater);
    setAuditDate(date);
    dispatch(
      setAuditData({
        auditDate: date.toLocaleDateString('en-US'),
        auditQuarter: quater,
      }),
    );
  };

  const handleChange = e => {
    const { name, value } = e.target;
    const updatedData = { ...audit.auditFormData, [name]: value };
    dispatch(setAuditData(updatedData));
  };

  const getCircuitptions = () => {
    const options = [];

    audit?.circuits?.map((circuit, index) => {
      options.push({ label: circuit.label, value: circuit.label });
    });
    setFilterCircuitData(audit?.circuits);
  };

  return (
    <>
      <div className="aepBreadcrumb auditInnerContainer">
        Forestry Contract Crew Audits
      </div>
      <Form className="auditSection">
        <Row className="mbAudit">
          <Col sm={4}>
            <Form.Group>
              <Form.Label>Contractor:</Form.Label>
              <Form.Select
                aria-label="contractor"
                value={audit?.auditFormData?.contractorname}
                onChange={e => handleContractor(e)}
                className="mb-3"
                name="contractor-name"
                required
              >
                <option value="" disabled selected hidden>
                  Select Contractor Name
                </option>
                {auditContactors?.map(
                  (contractor, index) =>
                    contractor?.contractorname && (
                      <option key={index} value={contractor.contractorname}>
                        {contractor.contractorname}
                      </option>
                    ),
                )}
              </Form.Select>
            </Form.Group>
          </Col>
          <Col sm={4}>
            <Form.Group>
              <Form.Label>Contract ID:</Form.Label>
              <Form.Control
                aria-label="contractID"
                value={audit.auditFormData?.contractrefid}
                className="mb-3"
                name="contractor-id"
                type="text"
                disabled
              />
            </Form.Group>
          </Col>
          <Col sm={4}>
            <Form.Group>
              <Form.Label>Audit Number:</Form.Label>
              <Form.Control
                type="text"
                value={audit.auditFormData?.auditNmbr}
                name="auditNmbr"
                onChangeCapture={e => handleChange(e)}
              />
            </Form.Group>
          </Col>
        </Row>
        <Row className="mbAudit">
          <Col sm={4}>
            <Form.Group>
              <Form.Label>Audit Date:</Form.Label>
              <DatePicker
                selected={auditDate}
                value={audit.auditFormData?.auditDate}
                onChange={date => handleAuditDate(date)}
                className="date-picker-icon-top"
              />
            </Form.Group>
          </Col>
          <Col sm={4}>
            <Form.Group>
              <Form.Label>Audit Quarter</Form.Label>
              <Form.Control
                type="text"
                value={audit.auditFormData?.auditQuarter}
                disabled
              />
            </Form.Group>
          </Col>
          <Col sm={4}>
            <Form.Group>
              <Form.Label>Auditor Name:</Form.Label>
              <Form.Select
                aria-label="auditorName"
                onChangeCapture={e => handleChange(e)}
                value={audit.auditFormData?.seqAuditorNmbr}
                className="mb-3"
                name="seqAuditorNmbr"
                required
              >
                <option value="" selected disabled hidden>
                  Select Auditor Name
                </option>
                {auditAuditors?.map((auditor, index) => (
                  <option key={index} value={auditor.seqAuditorNmbr}>
                    {auditor.auditorName}
                  </option>
                ))}
              </Form.Select>
            </Form.Group>
          </Col>
        </Row>
        <Row className="mbAudit">
          <Col sm={4}>
            <Form.Group>
              <Form.Label>Crew Type:</Form.Label>
              <Form.Control
                type="text"
                onChangeCapture={e => handleChange(e)}
                value={audit.auditFormData?.crewType}
                name="crewType"
              />
            </Form.Group>
          </Col>
          <Col sm={4}>
            <Form.Group>
              <Form.Label>Crew Number:</Form.Label>
              <Form.Control
                type="text"
                onChangeCapture={e => handleChange(e)}
                value={audit.auditFormData?.crewNmbr}
                name="crewNmbr"
              />
            </Form.Group>
          </Col>
          <Col sm={4}>
            <Form.Group>
              <Form.Label>Circuit Number:</Form.Label>

              {/* <Form.Select
                aria-label="circuitNo"
                className="mb-3"
                onChange={e => handleCircuitChange(e)}
                value={defaultCircuit}
              >
                <option value="" selected disabled hidden>
                  Select Circuit
                </option>
                {circuitList?.map((circuit, index) => (
                  <option key={index} value={circuit.circuitid}>
                    {circuit.circuitnum}
                  </option>
                ))}
              </Form.Select> */}
              <ReactSelect
                // id={index}
                classNamePrefix="poSelect"
                value={defaultCircuit}
                isClearable={false}
                isSearchable={true}
                name="circuit"
                options={audit?.circuitList}
                menuPosition="absolute"
                styles={{
                  menuPortal: base => ({
                    ...base,
                    zIndex: 9999,
                    fontSize: '16px',
                  }),
                }}
                maxMenuHeight={500}
                onChange={e => handleCircuitChange(e)}
                onInputChange={e => debouncedInputChange(e)}
              />
            </Form.Group>
          </Col>
        </Row>
        <Row className="mbAudit">
          <Col sm={4}>
            <Form.Group>
              <Form.Label>Timesheet Number:</Form.Label>
              <Form.Control
                type="text"
                onChangeCapture={e => handleChange(e)}
                value={audit.auditFormData?.recordNmbr}
                name="recordNmbr"
              />
            </Form.Group>
          </Col>
          <Col sm={4}>
            <Form.Group>
              <Form.Label>Operating Company:</Form.Label>
              <Form.Control
                type="text"
                value={audit.auditFormData?.opco}
                disabled
              />
            </Form.Group>
          </Col>
          <Col sm={4}>
            <Form.Group>
              <Form.Label>State:</Form.Label>
              <Form.Control
                type="text"
                value={audit.auditFormData?.state}
                disabled
              />
            </Form.Group>
          </Col>
        </Row>
        <Row className="mbAudit">
          <Col sm={4}>
            <Form.Group>
              <Form.Label>Work Area:</Form.Label>
              <Form.Control
                type="text"
                value={audit.auditFormData?.workAreaShortDesc}
                disabled
              />
            </Form.Group>
          </Col>
          <Col sm={4}>
            <Form.Group>
              <Form.Label>Foreman Name:</Form.Label>
              <Form.Control
                type="text"
                value={audit.auditFormData?.foremanName}
                onChangeCapture={e => handleChange(e)}
                name="foremanName"
              />
            </Form.Group>
          </Col>
          <Col sm={4}>
            <Form.Group>
              <Form.Label>Pole No:</Form.Label>
              <Form.Control
                type="text"
                value={audit.auditFormData?.poleNo}
                onChangeCapture={e => handleChange(e)}
                name="poleNo"
              />
            </Form.Group>
          </Col>
        </Row>
      </Form>
    </>
  );
};
export { CrewAudits };
