import { CaretDown } from 'assets/images/CaretIcon/CaretDown/CaretDown';
import { CaretUp } from 'assets/images/CaretIcon/CaretUp/CaretUp';
import { AEPFilterComponent } from 'components/common/Filter/AEPFilterComponent';
import { AEPPagination } from 'components/common/Pagination/AEPPagination';
import { getPOStatusByKey, optionsPoListTab } from 'constants/poTabs.ts';
import { ROUTES_STRING } from 'constants/routes';
import { POStatusDisplayValue } from 'constants/statusValues';
import { ModuleACL } from 'enums/entitlements.ts';
import { PurchaseOrder } from 'enums/fields.ts';
import { Page } from 'enums/page.ts';
import { PurchaseOrderStatus } from 'enums/statuses.ts';
import _ from 'lodash';
import { useEffect, useState } from 'react';
import { Col, Row, Tab, Table, Tabs } from 'react-bootstrap';
import { useDispatch, useSelector } from 'react-redux';
import { useLocation, useNavigate } from 'react-router-dom';
import { ClipLoader } from 'react-spinners';
import { resetFilters, setPOFilters } from 'store/slices/filterSlice';
import { setInvoicePagination } from 'store/slices/Invoice/invoiceSlice';
import { setActivePage } from 'store/slices/pageSlice';
import { fetchPurchaseOrderDetails } from 'store/slices/purchaseOrder/purchaseOrderDetailsSlice';
import {
  fetchPurchaseOrders,
  fetchSelectedPurchaseOrderList,
  resetPurchaseOrders,
  setPOPagination,
} from 'store/slices/purchaseOrder/purchaseOrderSlice';
import { setTimesheetPagination } from 'store/slices/timesheet/timesheetSlice';
import { filterAllowedOptionsACL } from 'utils/aclHelper';
import { isEmptyVal } from 'utils/utils';
import './PurchaseOrderList.scss';

const PurchaseOrderList = () => {
  const dispatch = useDispatch();
  const navigate = useNavigate();
  const location = useLocation();
  const type = 'Purchase Order';

  const [modalShow, setModalShow] = useState(false);
  const [selectedRow, setSelectedRow] = useState(null);
  const [chipFilterValues, setChipFilterValues] = useState({});

  const poListLoading = useSelector(state => state.purchaseOrder.isLoading);
  const inputListData = useSelector(state => state.filter.po);
  const selectedFilterRecordList = useSelector(
    state => state.purchaseOrder.recordItems,
  );

  const [filterChip, setFilterchip] = useState(false);
  const [continueBtnDisable, setContinueBtnDisable] = useState(true);
  const purchaseOrders = useSelector(state => state.purchaseOrder.items);
  const pagination = useSelector(state => state.purchaseOrder.pagination);
  const currentPage = useSelector(
    state => state.purchaseOrder.pagination.currentPage,
  );
  const sortBy = useSelector(state => state.purchaseOrder.pagination.sortBy);
  const sortOrder = useSelector(
    state => state.purchaseOrder.pagination.sortOrder,
  );
  const user = useSelector(state => state.auth);
  const [key, setKey] = useState('all');
  const [tabs, setTabs] = useState([]);

  const headers = [
    { key: PurchaseOrder.poNumber, value: 'Purchase Order' },
    { key: PurchaseOrder.contractNumber, value: 'Contract Number' },
    { key: PurchaseOrder.vendor, value: 'Vendor Name' },
    { key: PurchaseOrder.startDate, value: 'Start Date' },
    { key: PurchaseOrder.endDate, value: 'End Date' },
    { key: PurchaseOrder.status, value: 'Status' },
  ];

  const poFiltersValues = [
    { label: 'Purchase Order', value: 'ponum' },
    { label: 'Contract Number', value: 'contractrefnum' },
    { label: 'Vendor Name', value: 'vendor' },
    { label: 'Vendor Id', value: 'vendorid' },
    { label: 'Site', value: 'siteid' },
  ];

  const handlePurchaseOrderListClick = purchaseOrder => {
    setSelectedRow(purchaseOrder.poId);
    dispatch(fetchPurchaseOrderDetails(purchaseOrder.poId));
    navigate(`/${ROUTES_STRING.purchaseOrders}/${purchaseOrder.poNumber}`, {
      state: purchaseOrder,
    });
  };

  const handleSorting = (sortBy, sortOrder) => {
    dispatch(
      setPOPagination({
        ...pagination,
        currentPage: 1,
        sortBy,
        sortOrder,
      }),
    );
  };

  /**
   * Change key "vendorid" to "vendor"
   * Before apply search in service layer.
   */
  const objDecorate = obj => {
    let newObj = _.mapKeys(obj, (value, key) => {
      if (key === 'vendorid') {
        return 'vendor';
      } else {
        return key;
      }
    });
    return newObj;
  };

  const handleContinueClick = (data = null) => {
    let inputData = _.cloneDeep(inputListData);
    if (!isEmptyVal(data)) {
      inputData = _.cloneDeep(data);
    }

    let selectedFilterData = {};
    let selectedFilterValue = {};
    let uniqueFilterValues = [];

    inputData.map((el, id) => {
      if (uniqueFilterValues.indexOf(el.FilterValue) === -1) {
        uniqueFilterValues.push(el.FilterValue);
      }
    });

    uniqueFilterValues.map(value => {
      let temp1 = [];
      inputData.map((val, ids) => {
        if (val.FilterValue == value) {
          temp1.push(val.selectFilterLabel);
        }
      });
      selectedFilterData[value] = temp1;
    });

    inputData.map((el, id) => {
      Object.keys(selectedFilterData).map(function (item) {
        if (
          item == el.FilterValue &&
          !isEmptyVal(el.FilterLabel) &&
          !isEmptyVal(selectedFilterData[item])
        ) {
          selectedFilterValue[el.FilterLabel] = selectedFilterData[item];
        }
      });
    });

    let pageNumber = 1;
    if (!isEmptyVal(data) && inputListData?.length === data?.length) {
      pageNumber = pagination.currentPage;
    }
    dispatch(
      fetchPurchaseOrders({ ...selectedFilterData, currentPage: pageNumber }),
    );
    let vendorSelectFilterLabel = [];
    if (selectedFilterValue.Vendor != undefined) {
      selectedFilterValue.Vendor.map((e, id) => {
        inputData.map((val, ind) => {
          if (val.selectFilterValue == e) {
            vendorSelectFilterLabel.push(val.selectFilterLabel);
          }
        });
        selectedFilterValue.Vendor = vendorSelectFilterLabel;
        return selectedFilterValue.Vendor;
      });
    }

    setChipFilterValues(selectedFilterValue);
    setModalShow(false);
    setFilterchip(true);
  };

  const handleFilterChip = (chipname, chipValue) => {
    let filterchipData = {};

    Object.keys(chipFilterValues).map(function (item, i) {
      let chipList = [...chipFilterValues[item]];
      chipList.map((val, ids) => {
        if (val == chipValue) {
          chipList.splice(ids, 1);
        }
      });
      chipFilterValues[item] = chipList;
    });

    inputListData.map((el, id) => {
      Object.keys(chipFilterValues).map(function (item) {
        if (item == el.FilterLabel) {
          filterchipData[el.FilterValue] = chipFilterValues[item];
        }
      });
    });

    let indextodelete;
    let newInputList1 = _.cloneDeep(inputListData);
    newInputList1.map((val, ids) => {
      if (val.FilterValue === 'vendor' || val.FilterValue === 'vendorid') {
        if (val.FilterLabel == chipname && val.selectFilterLabel == chipValue) {
          indextodelete = ids;
        }
      } else {
        if (val.FilterLabel == chipname && val.selectFilterValue == chipValue) {
          indextodelete = ids;
        }
      }
    });
    newInputList1.splice(indextodelete, 1);

    if (newInputList1.length == 0) {
      setContinueBtnDisable(true);
    }
    dispatch(setPOFilters(newInputList1));
    setChipFilterValues(chipFilterValues);

    let vendorSelectFilterValue = [];
    if (filterchipData.vendor != undefined) {
      filterchipData.vendor.map((e, id) => {
        inputListData.map((val, ind) => {
          if (val.selectFilterLabel == e) {
            vendorSelectFilterValue.push(val.selectFilterValue);
          }
        });
        filterchipData.vendor = vendorSelectFilterValue;
        return filterchipData.vendor;
      });
    }

    // Check is using "vendorid"
    const filterchipDataDecorated = objDecorate(filterchipData);
    dispatch(fetchPurchaseOrders(filterchipDataDecorated));
  };

  const handleFilterclose = type => {
    if (type == 'filterChange') {
      setContinueBtnDisable(false);
    } else if (type == 'deleteEmpty') {
      setContinueBtnDisable(true);
    }
  };

  const optionsTab = optionsPoListTab;

  useEffect(() => {
    if (location?.state?.from !== 'poDetail') {
      dispatch(resetFilters());
    }

    dispatch(setActivePage(Page.PURCHASE_ORDER));
    dispatch(resetPurchaseOrders());
    dispatch(
      setTimesheetPagination({
        currentPage: 1,
      }),
    );
    dispatch(
      setInvoicePagination({
        currentPage: 1,
      }),
    );
    const optionsAllowed = filterAllowedOptionsACL(
      user,
      ModuleACL.PURCHASEORDER,
      optionsTab,
    );
    setTabs(optionsAllowed);

    if (location?.state?.from === 'homePage') {
      handleTabSelect(location?.state?.status);
    }
    handleContinueClick(inputListData);
  }, [dispatch]);

  const handleTabSelect = key => {
    switch (key) {
      case PurchaseOrderStatus.APPROVED: {
        dispatch(
          setPOPagination({
            forceFetch: true,
            currentPage: 1,
            caastatus: `${getPOStatusByKey(PurchaseOrderStatus.APPROVED)}`,
          }),
        );
        break;
      }
      case PurchaseOrderStatus.SUBMIT_VENDOR_COMPLETION_FORM: {
        dispatch(
          setPOPagination({
            forceFetch: true,
            currentPage: 1,
            caastatus: `${getPOStatusByKey(
              PurchaseOrderStatus.SUBMIT_VENDOR_COMPLETION_FORM,
            )}`,
          }),
        );
        break;
      }
      case PurchaseOrderStatus.APPROVED_PENDING_RATES_REVIEW: {
        dispatch(
          setPOPagination({
            forceFetch: true,
            currentPage: 1,
            caastatus: `${getPOStatusByKey(
              PurchaseOrderStatus.APPROVED_PENDING_RATES_REVIEW,
            )}`,
          }),
        );
        break;
      }
      case PurchaseOrderStatus.PENDING_INITIAL_REVIEW: {
        dispatch(
          setPOPagination({
            forceFetch: true,
            currentPage: 1,
            caastatus: `${getPOStatusByKey(
              PurchaseOrderStatus.PENDING_INITIAL_REVIEW,
            )}`,
          }),
        );
        break;
      }
      case PurchaseOrderStatus.CANCELLED_PENDING_ORDER: {
        dispatch(
          setPOPagination({
            forceFetch: true,
            currentPage: 1,
            caastatus: `${getPOStatusByKey(
              PurchaseOrderStatus.CANCELLED_PENDING_ORDER,
            )}`,
          }),
        );
        break;
      }
      default: {
        dispatch(
          setPOPagination({
            forceFetch: true,
            currentPage: 1,
            caastatus: '',
          }),
        );
      }
    }
    setKey(key);
  };

  return (
    <>
      <div className="aepContainer aepHeader">Purchase Order</div>
      <div className="aepContainer">
        <Tabs id="poPage" activeKey={key} onSelect={k => handleTabSelect(k)}>
          {tabs.map((tab, index) => (
            <Tab
              eventKey={tab.key}
              title={tab.value}
              key={index}
              tabClassName="tab-item"
            >
              <div className="tableContainer">
                <Row>
                  <Col sm={11}>
                    <>
                      <div className="filterComponent">
                        <AEPFilterComponent
                          inputListData={inputListData}
                          filterValues={poFiltersValues}
                          setInputFilterData={setPOFilters}
                          selectedFilterRecordList={selectedFilterRecordList}
                          fetchSelectedList={fetchSelectedPurchaseOrderList}
                          handleContinueClick={handleContinueClick}
                          continueBtnDisable={continueBtnDisable}
                          handleFilterclose={handleFilterclose}
                          handleFilterChip={handleFilterChip}
                          filterChip={filterChip}
                          chipFilterValues={chipFilterValues}
                        />
                      </div>
                    </>
                  </Col>
                </Row>

                {!poListLoading && (
                  <Table hover responsive striped bordered={false}>
                    <thead>
                      <tr>
                        {headers.map((header, idx) => (
                          <th key={idx}>
                            {sortOrder === 'asc' && (
                              <div
                                id={header.key}
                                onClick={e => handleSorting(header.key, 'desc')}
                              >
                                {header.value}
                                <CaretUp style={{ marginLeft: '12px' }} />
                              </div>
                            )}
                            {sortOrder === 'desc' && (
                              <div
                                id={header.key}
                                onClick={e => handleSorting(header.key, 'asc')}
                              >
                                {header.value}
                                <CaretDown style={{ marginLeft: '12px' }} />
                              </div>
                            )}
                          </th>
                        ))}
                      </tr>
                    </thead>
                    <tbody>
                      {purchaseOrders.map((row, indx) => (
                        <tr
                          key={indx}
                          className={
                            selectedRow === row.poId
                              ? 'tRow table-selected-row'
                              : 'tRow table-hover-row'
                          }
                          onClick={e => handlePurchaseOrderListClick(row)}
                        >
                          <td>{row.poNumber}</td>
                          <td>{row.contractNumber}</td>
                          <td>{row.vendor}</td>
                          <td>{row.startDate}</td>
                          <td>{row.endDate}</td>
                          <td>{POStatusDisplayValue[row.status]}</td>
                        </tr>
                      ))}
                    </tbody>
                  </Table>
                )}

                {tab.key === key && (
                  <div id={`pagination_${tab.key}`}>
                    <AEPPagination
                      fetchItems={fetchPurchaseOrders}
                      setPagination={setPOPagination}
                      pagination={pagination}
                      currentPage={currentPage}
                      sortBy={sortBy}
                      sortOrder={sortOrder}
                    />
                  </div>
                )}
              </div>
            </Tab>
          ))}
        </Tabs>
      </div>

      <ClipLoader
        color="#009cde"
        loading={poListLoading}
        cssOverride={{
          top: '40vh',
          left: '50vw',
          position: 'absolute',
          zIndex: 1,
        }}
        size={50}
        aria-label="Loading Spinner"
        data-testid="loader"
      />
    </>
  );
};

export { PurchaseOrderList };
