import { useState, useEffect } from 'react';
import { Form, Modal } from 'react-bootstrap';
import ReactSelect, { components } from 'react-select';
import { ButtonArea } from 'components/common//Button/ButtonArea/ButtonArea';
import { Divider } from 'components/common/Divider/Divider';
import { Row, Col } from 'react-bootstrap/esm';
import { useSelector } from 'react-redux';
import _ from 'lodash';

import './PurchaseOrderDetails.scss';

const EditContactModal = ({
  show,
  setShowModal,
  selectedList,
  handleContinueClick,
}) => {
  const [value, setValue] = useState();
  const [values, setValues] = useState({});
  const [PORoles, setPORoles] = useState();
  const [error, setError] = useState('');

  useEffect(() => {
    setError('');
  }, []);
  const roles = [
    {
      label: 'Vendor PO Contact',
      value: 'vendorContact',
    },
    {
      label: 'Vendor Timesheet Contact',
      value: 'vendorTimesheetContact',
    },
    {
      label: 'Invoice Contact',
      value: 'invoiceContact',
    },
  ];

  const purchaseOrder = useSelector(state => state.purchaseOrderDetails);

  const updatedSelectedList = roles.filter(
    item => selectedList.role && selectedList.role.includes(item.value),
  );

  const handleClick = e => {
    if (e.nativeEvent) {
      const name = e.target.name;
      let value = e.target.value;
      if (name === 'name') {
        let regEx = /[^a-zA-Z\s]*$/;
        if (value !== '' && value.length > 100) {
          e.target.value = value.substring(0, 99);
        }
        e.target.value = e.target.value.replace(regEx, '');
      } else if (name === 'email') {
        if (value !== '' && value.length > 60) {
          e.target.value = value.substring(0, 59);
        }
      } else if (name === 'phonenum') {
        let regEx = /[^0-9]*$/;
        e.target.value = value.replace(regEx, '');
        if (e.target.value.length > 9) {
          e.target.value = value.substring(0, 10);
        }
      }
      if (e.target.value !== '') {
        setValues({ ...values, [name]: value });
      }
    } else {
      const lastIndex = e[e.length - 1];

      const foundIndex =
        selectedList.role &&
        selectedList.role.findIndex(item => item === lastIndex.value);

      if (foundIndex > -1 || foundIndex === 0) {
        if (foundIndex === null) {
          selectedList.role = [];
          selectedList.role.push(lastIndex.value);
        }
        selectedList.role.splice(foundIndex, 1);
      } else {
        selectedList.role.push(lastIndex.value);
      }

      setPORoles(selectedList.role);

      setValues({
        ...values,
        role: selectedList.role,
      });
    }
  };

  const submitClick = () => {
    if (!_.isEmpty(values) && selectedList.name !== values.name) {
      if (purchaseOrder.contacts.some(item => item.name === values.name)) {
        setError('Name is already Exist.');
      } else {
        handleContinueClick(values, selectedList);
        setError('');
      }
    } else {
      handleContinueClick(values, selectedList);
      setError('');
    }
    setValues({});
  };

  const submitHandler: FormEventHandler = event => {
    event.preventDefault();
    event.persist();
  };

  const getRoleOptions = () => {
    const options = [];
    roles.forEach((role, index) => {
      options.push({
        label: role.label,
        value: role.value,
        name: 'role',
        key: role.value,
      });
    });
    return options;
  };

  const Option = props => {
    setPORoles(selectedList.role);
    return (
      <div>
        <components.Option {...props}>
          <Form.Group style={{ display: 'flex' }}>
            <input
              style={{ width: '16px', height: '16px', marginRight: '5px' }}
              type="checkbox"
              key={props.data.value}
              name={props}
              checked={PORoles && PORoles.includes(props.data.value)}
              onChange={() => null}
            />

            <label>{props.label}</label>
          </Form.Group>
        </components.Option>
      </div>
    );
  };

  const formatOptionLabel = label => <div>{label}</div>;

  return (
    <Modal
      show={show}
      onHide={() => setShowModal(false)}
      size="lg"
      aria-labelledby="contained-modal-title-vcenter"
      centered
    >
      <Modal.Header closeButton="true">
        <Modal.Title>Edit Contact</Modal.Title>
      </Modal.Header>
      <Divider />
      <Modal.Body>
        <Form>
          {error && (
            <Row>
              <Col className="error" sm={12}>
                {error}
              </Col>
            </Row>
          )}
          <Row>
            <Col sm={6}>
              <Form.Group className="mb-3" controlId="editPoContactName">
                <Form.Label>Name</Form.Label>
                <Form.Control
                  type="text"
                  name="name"
                  defaultValue={selectedList.name}
                  onChange={event => handleClick(event)}
                  value={value}
                />
              </Form.Group>
            </Col>
            <Col sm={6}>
              <Form.Group className="mb-3" controlId="editPoContactEmail">
                <Form.Label>Email</Form.Label>
                <Form.Control
                  type="text"
                  name="email"
                  defaultValue={selectedList.email}
                  onChange={event => handleClick(event)}
                  value={value}
                />
              </Form.Group>
            </Col>
            <Col sm={6}>
              <Form.Group className="mb-3" controlId="editPoContactPhone">
                <Form.Label>Phone</Form.Label>
                <Form.Control
                  type="text"
                  name="phonenum"
                  defaultValue={selectedList.phonenum}
                  onChange={event => handleClick(event)}
                  value={value}
                />
              </Form.Group>
            </Col>
            <Col sm={6} style={{ width: '50px' }}>
              <Form.Group controlId="editPoContactRole">
                <Form.Label>Role</Form.Label>

                <ReactSelect
                  id="poReactContact"
                  classNamePrefix="poContact"
                  //defaultValue=""
                  isSearchable={true}
                  name="role"
                  placeholder="Select"
                  options={getRoleOptions()}
                  menuPosition="absolute"
                  maxMenuHeight={600}
                  onChange={e => handleClick(e)}
                  components={{ Option }}
                  isMulti
                  closeMenuOnSelect={false}
                  hideSelectedOptions={false}
                  value={
                    updatedSelectedList &&
                    updatedSelectedList.map(role => role.label)
                  }
                  formatOptionLabel={formatOptionLabel}
                />
              </Form.Group>
            </Col>
          </Row>
        </Form>
      </Modal.Body>
      <Modal.Footer>
        <ButtonArea
          primaryLabel="Continue"
          secondaryLabel="Cancel"
          type="submit"
          primaryOnClick={() => submitClick()}
          secondaryOnClick={() => setShowModal(false)}
        />
      </Modal.Footer>
    </Modal>
  );
};

export { EditContactModal };
