import { Form, Modal, Tabs, Tab } from 'react-bootstrap';
import { ButtonArea } from 'components/common//Button/ButtonArea/ButtonArea';
import { FormProvider } from 'react-hook-form';
import { Divider } from 'components/common/Divider/Divider';
import { AddIcon } from 'assets/images/AddIcon/AddIcon';
import { DeleteIcon } from 'assets/images/DeleteIcon/DeleteIcon';
import { Row, Col } from 'react-bootstrap/esm';
import { useDispatch, useSelector } from 'react-redux';
import { useState } from 'react';
import _ from 'lodash';
import './AdminWorkArea.scss';
import { useEffect } from 'react';

const AddNewWorkAreaModal = ({
  show,
  setShowModal,
  handleAddWorkArea,
  handleUpdateWorkArea,
  handleCancel,
  workAreaData,
  formValues,
  setFormValues,
  handleSubmit,
  getValues,
  setValue,
  watch,
  trigger,
  reset,
  methods,
}) => {
  const lookup = useSelector(state => state.lookup);
  const isUpdate = useSelector(state => state.workArea.isUpdate);
  const [values, setValues] = useState({});
  const {
    formState: { errors },
  } = methods;

  const allFormFields = watch();

  const onSubmit = data => {
    const payloadData = {};
    const operatingCompanyData = lookup.operatingCompany.find(
      element => element.ocNmbr == data.ocNmbr,
    );
    payloadData['orgDeptDesc'] = data.orgDeptDesc;
    payloadData['departmentId'] = data.departmentId;
    payloadData['orgDeptGroup'] = data.orgDeptGroup;
    payloadData['ocNmbr'] = data.ocNmbr;
    payloadData['status'] = data.ocStatus;
    payloadData['operatingCompany'] = {
      companyAbbr: operatingCompanyData.companyAbbr,
      stateCode: operatingCompanyData.stateCode,
      status: data.ocStatus,
    };
    const workArea = formValues.filter(item => {
      if (item.workAreaShortDesc != '' || item.workAreaDesc != '') {
        return item;
      }
    });
    if (workArea) {
      payloadData['workArea'] = workArea;
    }

    if (isUpdate) {
      handleUpdateWorkArea(payloadData);
    } else {
      handleAddWorkArea(payloadData);
    }
    reset();
    setFormValues([{ workAreaDesc: '', workAreaShortDesc: '', status: '1' }]);
  };
  const onError = data => {
    console.log('error', data);
  };

  let handleWorkAreaChange = (i, e) => {
    let newFormValues = _.cloneDeep(formValues);
    newFormValues[i][e.target.name] = e.target.value;
    setFormValues(newFormValues);
    setValues({ ...values, ...newFormValues });
  };

  let addFormFields = () => {
    setFormValues([
      ...formValues,
      { workAreaDesc: '', workAreaShortDesc: '', status: '1' },
    ]);
  };

  let removeFormFields = i => {
    let newFormValues = [...formValues];
    newFormValues.splice(i, 1);
    setFormValues(newFormValues);
    setValues({ ...values, ...newFormValues });
  };

  const TabKey = {
    DETAILS: 'Details',
  };

  const tabs = [{ key: TabKey.DETAILS, value: 'Details' }];

  return (
    <Modal
      show={show}
      onHide={() => setShowModal(false)}
      size="lg"
      aria-labelledby="contained-modal-title-vcenter"
      centered
    >
      <Tabs id="poPage">
        {tabs.map((tab, index) => (
          <Tab eventKey={tab.key} title={tab.value} key={index}>
            <div className="tableContainer">
              <div className="aepBreadcrumb actionPane"></div>
              <Modal.Body>
                <FormProvider {...methods}>
                  <Form>
                    <Row>
                      <Col sm={4}>
                        <Form.Group>
                          <Form.Label>OPCO/State</Form.Label>
                          <Form.Select
                            value={
                              getValues('ocNmbr') || workAreaData?.ocNmbr || ''
                            }
                            onChange={e => {
                              setValue('ocNmbr', e.target.value);
                              trigger('ocNmbr');
                            }}
                            name="ocNmbr"
                          >
                            <option value="">Select</option>
                            {lookup?.operatingCompany?.map((obj, index) => (
                              <option key={index} value={obj.ocNmbr}>
                                {`${obj.companyAbbr} / ${obj.stateCode}`}
                              </option>
                            ))}
                          </Form.Select>
                          <div className="errorMessage">
                            {errors?.['ocNmbr']?.['message']}
                          </div>
                        </Form.Group>
                      </Col>
                      <Col sm={4}>
                        <Form.Group>
                          <Form.Label>Org/Dept</Form.Label>
                          <Form.Control
                            onChange={e => {
                              setValue('orgDeptDesc', e.target.value);
                              trigger('orgDeptDesc');
                            }}
                            type="text"
                            value={
                              getValues('orgDeptDesc') ||
                              workAreaData?.orgDeptDesc ||
                              ''
                            }
                            name="orgDeptDesc"
                          />
                          <div className="errorMessage">
                            {errors?.['orgDeptDesc']?.['message']}
                          </div>
                        </Form.Group>
                      </Col>
                      <Col sm={4}>
                        <Form.Group>
                          <Form.Label>
                            Org Group: {getValues('orgDeptGroup')}
                          </Form.Label>
                          <Form.Select
                            value={
                              getValues('orgDeptGroup') ||
                              workAreaData?.orgDeptGroup ||
                              ''
                            }
                            onChange={e => {
                              setValue('orgDeptGroup', e.target.value);
                              trigger('orgDeptGroup');
                            }}
                            name="orgDeptGroup"
                          >
                            <option value="">Select</option>
                            <option value="Forestry">Forestry</option>
                            <option value="Line">Line</option>
                          </Form.Select>
                          <div className="errorMessage">
                            {errors?.['orgDeptGroup']?.['message']}
                          </div>
                        </Form.Group>
                      </Col>
                    </Row>
                    <Row>
                      <Col sm={4}>
                        <Form.Group>
                          <Form.Label>Department Id</Form.Label>
                          <Form.Control
                            onChange={e => {
                              setValue('departmentId', e.target.value);
                              trigger('departmentId');
                            }}
                            type="text"
                            value={
                              getValues('departmentId') ||
                              workAreaData?.departmentId ||
                              ''
                            }
                          />
                          <div className="errorMessage">
                            {errors?.['departmentId']?.['message']}
                          </div>
                        </Form.Group>
                      </Col>
                      <Col sm={4}>
                        <Form.Group>
                          <Form.Label>OPCO/State Status:</Form.Label>
                          <Form.Select
                            value={
                              getValues('ocStatus') ||
                              workAreaData?.status ||
                              ''
                            }
                            onChange={e => {
                              setValue('ocStatus', e.target.value);
                              trigger('ocStatus');
                            }}
                            name="ocStatus"
                          >
                            <option value="">Select</option>
                            <option value="1">Active</option>
                            <option value="0">Inactive</option>
                          </Form.Select>
                          <div className="errorMessage">
                            {errors?.['ocStatus']?.['message']}
                          </div>
                        </Form.Group>
                      </Col>
                    </Row>
                    <Divider style={{ margin: '40px 40px 40px 0' }} />
                    {formValues.map((element, index) => (
                      <Row>
                        <Col sm={3}>
                          <Form.Group>
                            <Form.Label>Work Area Description</Form.Label>
                            <Form.Control
                              onChange={e => handleWorkAreaChange(index, e)}
                              type="text"
                              name="workAreaDesc"
                              value={element.workAreaDesc || ''}
                            />
                          </Form.Group>
                        </Col>
                        <Col sm={3}>
                          <Form.Group>
                            <Form.Label>Work Area</Form.Label>
                            <Form.Control
                              onChange={e => handleWorkAreaChange(index, e)}
                              type="text"
                              name="workAreaShortDesc"
                              value={element.workAreaShortDesc || ''}
                            />
                            <div className="errorMessage">
                              {errors?.['workAreaShortDesc']?.['message']}
                            </div>
                          </Form.Group>
                        </Col>
                        <Col sm={3}>
                          <Form.Group>
                            <Form.Label>Work Area Status:</Form.Label>
                            <Form.Select
                              value={element.status || ''}
                              name="status"
                              onChange={e => handleWorkAreaChange(index, e)}
                            >
                              <option value="1">Active</option>
                              <option value="0">Inactive</option>
                            </Form.Select>
                          </Form.Group>
                        </Col>
                        <Col sm={2}>
                          <Form.Group>
                            <Form.Label></Form.Label>
                            <span
                              style={{ width: '100%' }}
                              onClick={e => addFormFields()}
                              title="Add Row"
                            >
                              <AddIcon />
                            </span>
                            {index > 0 && (
                              <span
                                style={{ width: '100%' }}
                                onClick={e => removeFormFields(index)}
                                title="Delete Row"
                              >
                                <DeleteIcon />
                              </span>
                            )}
                          </Form.Group>
                        </Col>
                      </Row>
                    ))}
                  </Form>
                </FormProvider>
              </Modal.Body>
            </div>
          </Tab>
        ))}
      </Tabs>
      <Modal.Footer>
        <ButtonArea
          primaryLabel="Save"
          secondaryLabel="Cancel"
          primaryOnClick={handleSubmit(onSubmit, onError)}
          secondaryOnClick={handleCancel}
        />
      </Modal.Footer>
    </Modal>
  );
};

export { AddNewWorkAreaModal };
