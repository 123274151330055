import { PODetailHeaders } from 'enums/poDetailHeaders.ts';

export const PODetailHeaderValues = {
  [PODetailHeaders.PO_DETAILS]: 'Purchase Order Details',
  [PODetailHeaders.TIMESHEET_RECORDS]: 'Timesheet Records',
  [PODetailHeaders.INVOICE_RECORDS]: 'Invoice Records',
  [PODetailHeaders.RATES]: 'Rates',
  [PODetailHeaders.PENDING_RATES_REVIEW]: 'Pending Rates Review',
  [PODetailHeaders.COMPLETION_FORM]: 'Completion Form',
};
