export enum PricingMethod {
  ALLOWANCE = 'ALW',
  COST_PLUS = 'CSP',
  EXTRA_WORK = 'EXT',
  FIXED_PRICE = 'FPI',
  LUMPSUM = 'LMP',
  HOURLY = 'HRS',
  OTHER = 'OTH',
  TIME_AND_MATERIAL = 'T&M',
  UNIT_PRICE = 'UPR',
}
