import { ButtonArea } from 'components/common/Button/ButtonArea/ButtonArea';
import { Divider } from 'components/common/Divider/Divider';
import { Form, Modal } from 'react-bootstrap';
import { Controller } from 'react-hook-form';

const ReviewerModal = ({
  control,
  showModal,
  setShowModal,
  reviewerComment,
  setReviewerComment,
  handleContinueClick,
  title,
}) => {
  return (
    <Modal
      show={showModal}
      onHide={() => setShowModal(false)}
      size="lg"
      aria-labelledby="contained-modal-title-vcenter"
      centered
    >
      <Modal.Header closeButton={false}>
        <Modal.Title>{title}</Modal.Title>
      </Modal.Header>
      <Divider />
      <Modal.Body>
        <div>
          <Form.Group controlId="form.reviewerComment">
            <Form.Label className="reviewerCommentLabel">
              Provide Feedback
            </Form.Label>
            <Controller
              control={control}
              name="reviewerComments"
              defaultValue=""
              render={({ field: { onChange, onBlur, value, ref } }) => (
                <Form.Control
                  style={{ width: '100%' }}
                  value={reviewerComment}
                  onChange={e => setReviewerComment(e.target.value)}
                  ref={ref}
                  type="text"
                />
              )}
            />
          </Form.Group>
        </div>
      </Modal.Body>
      <Modal.Footer>
        <ButtonArea
          primaryLabel="Continue"
          secondaryLabel="Cancel"
          primaryOnClick={() => handleContinueClick()}
          secondaryOnClick={() => setShowModal(false)}
        />
      </Modal.Footer>
    </Modal>
  );
};

export { ReviewerModal };
