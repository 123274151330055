import { Fragment } from 'react';
import { Button, Modal, Row, Col } from 'react-bootstrap';
import { CloseIcon } from 'assets/images/CloseIcon/CloseIcon';
import { ErrorIcon } from 'assets/images/ErrorIcon/ErrorIcon';
import { Divider } from 'components/common/Divider/Divider';
import _ from 'lodash';

const InvoiceErrorModal = props => {
  const {
    onShow,
    onHide,
    returnTimesheet,
    handleGenerateInvoiceModal,
    invoiceErrorMessage,
  } = props;

  return (
    <Modal
      show={onShow}
      onHide={onHide}
      size="lg"
      dialogClassName="invoicePreviewDialog"
      aria-labelledby="contained-modal-title-vcenter"
      centered
    >
      <Modal.Header closeButton={false}>
        <div
          style={{
            display: 'flex',
            justifyContent: 'flex-start',
            width: '100%',
            height: '26px',
          }}
        >
          <div className="errorIcon">
            <ErrorIcon styles={{ marginTop: '-4px' }} />
          </div>
          Invoice accounting validation failed!
          <div
            style={{ marginLeft: 'auto', cursor: 'pointer' }}
            onClick={onHide}
          >
            <CloseIcon />
          </div>
        </div>
      </Modal.Header>
      <Divider />
      <Modal.Body className="psValidationModalBody">
        <div style={{ fontSize: '20px', marginBottom: '24px' }}>
          <ul>
            {invoiceErrorMessage['fint:errorDetail'] ? (
              invoiceErrorMessage['fint:errorDetail'].map((error, index) => [
                <div style={{ padding: '5px' }}>
                  <Row>
                    <Col sm={3}>Work Order No:</Col>
                    <Col sm={9}>{invoiceErrorMessage.workorder}</Col>
                  </Row>
                  <Row>
                    <Col sm={3}>PS Message Set number:</Col>
                    <Col sm={9}>{error['val:peopleSoftMessageSet']}</Col>
                  </Row>
                  <Row>
                    <Col sm={3}>Message Number:</Col>
                    <Col sm={9}>{error['val:peopleSoftMessageNumber']}</Col>
                  </Row>
                  <Row>
                    <Col sm={3}>Error Message Description:</Col>
                    <Col sm={9}>{error['val:errorDescription']}</Col>
                  </Row>
                </div>,
                <Divider />,
              ])
            ) : (
              <li>Please select how you would like to proceed.</li>
            )}
          </ul>
        </div>
        <div className="btnArea">
          <Fragment>
            <Button variant="primary" onClick={() => returnTimesheet()}>
              Return Timesheets
            </Button>
            <Button
              variant="primary"
              onClick={() => handleGenerateInvoiceModal()}
            >
              Try Again
            </Button>
            <Button variant="secondary" onClick={onHide}>
              Cancel
            </Button>
          </Fragment>
        </div>
      </Modal.Body>
    </Modal>
  );
};

export { InvoiceErrorModal };
