import { Form, Modal } from 'react-bootstrap';
import { useState } from 'react';
import { ButtonArea } from 'components/common//Button/ButtonArea/ButtonArea';
import { Divider } from 'components/common/Divider/Divider';
import { Row, Col } from 'react-bootstrap/esm';
import { useDispatch, useSelector } from 'react-redux';
import './BidRateSheetEntry.scss';

const AddNewTabModal = ({
  show,
  setShowModal,
  tabList,
  templates,
  handleTemplateClick,
}) => {
  const dispatch = useDispatch();
  const [serviceValue, setServiceValue] = useState('selectatab');
  const [templateValue, setTemplateValues] = useState('blank');

  return (
    <Modal
      show={show}
      onHide={() => setShowModal(false)}
      size="lg"
      dialogClassName="bidRateSheetNewTab"
      aria-labelledby="contained-modal-title-vcenter"
      centered
    >
      <Modal.Header closeButton="true">
        <Modal.Title>Add New Tab</Modal.Title>
      </Modal.Header>
      <Divider />
      <Modal.Body>
        <Form>
          <Row>
            <Col sm={6}>
              <Form.Group controlId="form.tab">
                <Form.Label className="mb-1">Service Type:</Form.Label>
                <Form.Select
                  aria-label="tab"
                  onChange={e => setServiceValue(e.target.value)}
                >
                  {tabList != undefined &&
                    tabList.map((filter, index) => (
                      <option key={index} value={filter.value}>
                        {filter.label}
                      </option>
                    ))}
                </Form.Select>
              </Form.Group>
            </Col>
            <Col sm={6}>
              <Form.Group controlId="form.template">
                <Form.Label className="mb-1">Template:</Form.Label>
                <Form.Select
                  aria-label="template"
                  onChange={e => setTemplateValues(e.target.value)}
                  value={templateValue}
                >
                  {templates != undefined &&
                    templates.map((filter, index) => (
                      <option key={index} value={filter.value}>
                        {filter.label}
                      </option>
                    ))}
                </Form.Select>
              </Form.Group>
            </Col>
          </Row>
        </Form>
      </Modal.Body>
      <Modal.Footer>
        <ButtonArea
          primaryLabel="Continue"
          secondaryLabel="Cancel"
          primaryOnClick={() =>
            handleTemplateClick(serviceValue, templateValue)
          }
          secondaryOnClick={() => setShowModal(false)}
          primaryDisabled={
            templateValue === 'selectTemplate' || serviceValue === 'selectatab'
          }
        />
      </Modal.Footer>
    </Modal>
  );
};

export { AddNewTabModal };
