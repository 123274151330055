import { http } from '../services/helpers/BaseApi.ts';
import { displayError } from 'components/common/Alert/ToastAlert';

const excelDownloadInvoice = async params => {
  await http.downloadInvoiceFile({
    url: params.url,
    type: 'application/vnd.openxmlformats-officedocument.spreadsheetml.sheet',
    name: params.name,
    extension: 'xlsx',
  });
};

export { excelDownloadInvoice };
