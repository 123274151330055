import React from 'react';
import { useState, useEffect } from 'react';
import { useDispatch, useSelector } from 'react-redux';
import {
  Button,
  Col,
  Form,
  Modal,
  Row,
  Tab,
  Table,
  Tabs,
} from 'react-bootstrap';
import { TimesheetAddIcon } from 'assets/images/TimesheetAddIcon/TimesheetAddIcon';
import { TimesheetDeleteIcon } from 'assets/images/TimesheetDeleteIcon/TimesheetDeleteIcon';
import { MaximizeIcon } from 'assets/images/MaximizeIcon/MaximizeIcon';
import { MinimizeIcon } from 'assets/images/MinimizeIcon/MinimizeIcon';
import AccountingSection from './AcountingSection';
import './Accounting.scss';
import { setRdaData } from 'store/slices/Invoice/invoiceDetailSlice';
import _ from 'lodash';
import { BuPath } from 'constants/buPath';
import { InvoiceStatus } from 'enums/statuses.ts';

const initialData = {
  activityNum: '',
  workorder: '',
  abmsProject: '',
  accountNmbr: '',
  projectBusinessUnit: '',
  glbu: '',
  costComponent: '',
  abmsActivity: '',
  dept: '',
  linecost: 0.0,
  percentage: 0.0,
};

const AccountingHeader = props => {
  const dispatch = useDispatch();
  const { invoiceRow, canEditActivity } = props;
  const invoice = useSelector(state => state.invoiceDetails);
  const rdaData = invoice.allCostLine;
  const [expandActivities, setExpandActivities] = useState([]);
  const [selectedCheckboxes, setSelectedCheckboxes] = useState({});
  const [selectedActNum, setSelectedActivityNum] = useState('');
  const [isDeleteButtonEnabled, setDeleteButtonEnabled] = useState(false);
  const [isAddButtonDisbaled, setIsAddButtonDisbaled] = useState(true);
  const [isCheckboxChecked, setCheckboxChecked] = useState([]);
  const user = useSelector(state => state.auth);

  const invoiceCostsData =
    invoiceRow.activities &&
    invoiceRow.activities.length > 0 &&
    invoiceRow.activities.map(activity => {
      const findInvoiceCosts = invoice?.invoiceCosts?.filter(
        obj => obj.activitynum === activity.activitynum,
      );
      if (findInvoiceCosts && findInvoiceCosts.length) {
        return findInvoiceCosts.map((item, idx) => {
          const parts = item.gldebitacct.split('-');
          return {
            activityNum: activity.activitynum,
            workorder: parts[3],
            abmsProject: parts[5],
            accountNmbr: parts[2],
            projectBusinessUnit: parts[4],
            glbu: parts[0],
            costComponent: parts[7],
            abmsActivity: parts[6],
            dept: parts[1],
            linecost: Number(item.linecost).toFixed(2),
            percentage: Number(item.percentage).toFixed(2),
          };
        });
      } else {
        return {
          activityNum: activity.activitynum,
          workorder: '',
          abmsProject: '',
          accountNmbr: '',
          projectBusinessUnit: '',
          glbu: '',
          costComponent: '',
          abmsActivity: '',
          dept: '',
          linecost: Number(activity.amount).toFixed(2),
          percentage: Number(100).toFixed(2),
        };
      }
    });

  // Written For Expand And Collapse of sepcific activity
  const toggleActivity = activityNum => {
    setIsAddButtonDisbaled(false);

    //dispatch(setRdaData(finalCost));
    if (expandActivities.includes(activityNum)) {
      setExpandActivities(expandActivities.filter(id => id !== activityNum));
    } else {
      setExpandActivities([...expandActivities, activityNum]);
    }
    setSelectedActivityNum(activityNum);
  };

  const handleCheckboxChange = (activityNum, invoiceCostIndex, checked) => {
    let allActivities = { ...selectedCheckboxes };
    let costLineActivity = getCostLineByActivity(activityNum);
    if (!allActivities[activityNum]) {
      let activity = {};
      for (let i = 0; i < costLineActivity.length; i++) {
        activity[i] = false;
      }
      allActivities[activityNum] = activity;
    }
    allActivities[activityNum][invoiceCostIndex] = checked;
    setSelectedCheckboxes(allActivities);
    const activityCheckboxes = allActivities[activityNum];
    const anyCheckBoxChecked = Object.values(activityCheckboxes).some(
      value => value,
    );
    setDeleteButtonEnabled(anyCheckBoxChecked);
    setSelectedActivityNum(activityNum);

    if (checked) {
      setCheckboxChecked([...isCheckboxChecked, activityNum]);
    } else {
      isCheckboxChecked.splice(isCheckboxChecked.indexOf(activityNum), 1);
      setCheckboxChecked(isCheckboxChecked);
    }
  };

  const getCostLineByActivity = activitynum => {
    const lineData = rdaData.find(item => item.activity === activitynum);
    return lineData?.costline;
  };

  const handleDeleteRows = (selectedActivity, activityamount) => {
    let reqCostLine = getCostLineByActivity(selectedActivity);
    let tempArray = [];
    for (
      let i = 0;
      i < Object.values(selectedCheckboxes[selectedActivity]).length;
      i++
    ) {
      if (!selectedCheckboxes[selectedActivity][i])
        tempArray.push(reqCostLine[i]);
    }

    const reqIndex = getIndexByActivityId(selectedActNum);
    let tempData = _.cloneDeep(rdaData);
    tempData[reqIndex].costline = tempArray;
    const data = {
      allCostLine: tempData,
    };
    dispatch(setRdaData(data));
    setSelectedCheckboxes({});
    setDeleteButtonEnabled(false);
  };

  const getIndexByActivityId = activityId => {
    return rdaData.findIndex(item => item.activity === activityId);
  };

  const handleAddRow = (activity, activityamount) => {
    const updatedData = { ...initialData, activityNum: activity };

    setExpandActivities([...expandActivities, activity]);
    const reqIndex = getIndexByActivityId(activity);
    let tempData = _.cloneDeep(rdaData);

    tempData[reqIndex].costline.push(updatedData);
    const data = {
      allCostLine: tempData,
    };
    dispatch(setRdaData(data));
  };

  return (
    <>
      {invoiceRow.activities &&
        invoiceRow.activities.length > 0 &&
        invoiceRow.activities.map(activities => {
          return (
            <div className="accountingHeader" key={activities.activitynum}>
              <Row style={{ width: '100%' }}>
                <Col sm={2} style={{ marginLeft: '20px' }}>
                  <Form.Group>
                    <Form.Label>Activity / SOV No:</Form.Label>
                    <Form.Control
                      className="customSelect"
                      value={activities.activitynum}
                      disabled
                    />
                  </Form.Group>
                </Col>
                <Col sm={2}>
                  <Form.Group>
                    <Form.Label>Description:</Form.Label>
                    <Form.Control
                      className="customSelect"
                      value={activities.activityDescription}
                      disabled
                    />
                  </Form.Group>
                </Col>
                <Col sm={2}>
                  <Form.Group>
                    <Form.Label>Amount:</Form.Label>
                    <Form.Control
                      className="customSelect"
                      value={Number(activities.amount).toFixed(2)}
                      disabled
                    />
                  </Form.Group>
                </Col>

                <Col
                  sm={5}
                  style={{ display: 'flex', justifyContent: 'flex-start' }}
                >
                  {canEditActivity && (
                    <>
                      <div
                        className={
                          !isAddButtonDisbaled &&
                          expandActivities.includes(activities.activitynum)
                            ? 'deleteIconEnabled'
                            : 'deleteIconDisabled'
                        }
                        onClick={() =>
                          handleAddRow(
                            activities.activitynum,
                            activities.amount,
                          )
                        }
                      >
                        <TimesheetAddIcon />
                      </div>

                      <div
                        className={
                          isCheckboxChecked.includes(activities.activitynum)
                            ? 'deleteIconEnabled'
                            : 'deleteIconDisabled'
                        }
                        onClick={() =>
                          isCheckboxChecked.includes(activities.activitynum) &&
                          handleDeleteRows(
                            activities.activitynum,
                            activities.amount,
                          )
                        }
                        key={activities.activitynum}
                      >
                        <TimesheetDeleteIcon />
                      </div>
                    </>
                  )}

                  <div className="minMax">
                    {expandActivities.includes(activities.activitynum) ? (
                      <div
                        onClick={() => toggleActivity(activities.activitynum)}
                      >
                        <MinimizeIcon />
                      </div>
                    ) : (
                      <div
                        onClick={() => toggleActivity(activities.activitynum)}
                      >
                        <MaximizeIcon />
                      </div>
                    )}
                  </div>
                </Col>
              </Row>

              {expandActivities.includes(activities.activitynum) && (
                <AccountingSection
                  activityNum={activities.activitynum}
                  data={getCostLineByActivity(activities.activitynum)}
                  invoiceAmount={activities.amount}
                  selectedCheckboxes={
                    selectedCheckboxes[activities.activitynum]
                  }
                  handleCheckboxChange={handleCheckboxChange}
                  canEditActivity={canEditActivity}
                />
              )}
            </div>
          );
        })}
    </>
  );
};

export default AccountingHeader;
