// Imports
import ___CSS_LOADER_API_SOURCEMAP_IMPORT___ from "../../../node_modules/react-scripts/node_modules/css-loader/dist/runtime/sourceMaps.js";
import ___CSS_LOADER_API_IMPORT___ from "../../../node_modules/react-scripts/node_modules/css-loader/dist/runtime/api.js";
var ___CSS_LOADER_EXPORT___ = ___CSS_LOADER_API_IMPORT___(___CSS_LOADER_API_SOURCEMAP_IMPORT___);
// Module
___CSS_LOADER_EXPORT___.push([module.id, `.crossIcon {
  display: flex;
  justify-content: flex-end;
  margin-right: 24px;
}`, "",{"version":3,"sources":["webpack://./src/components/CrossButton/CrossButton.scss"],"names":[],"mappings":"AAAA;EACE,aAAA;EACA,yBAAA;EACA,kBAAA;AACF","sourcesContent":[".crossIcon {\n  display: flex;\n  justify-content: flex-end;\n  margin-right: 24px;\n}\n"],"sourceRoot":""}]);
// Exports
___CSS_LOADER_EXPORT___.locals = {};
export default ___CSS_LOADER_EXPORT___;
