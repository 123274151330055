import { useState } from 'react';
import { Form, Modal } from 'react-bootstrap';
import { ButtonArea } from 'components/common//Button/ButtonArea/ButtonArea';
import { Divider } from 'components/common/Divider/Divider';
import { Row, Col } from 'react-bootstrap/esm';

const CommentsModal = ({
  show,
  setShowModal,
  agreeDisagree,
  handleContinueClick,
}) => {
  const [value, setValue] = useState();
  const [values, setValues] = useState({});

  const handleClick = (e, updatedAt) => {
    const name = e.target.name;
    const value = e.target.value;
    setValues({ ...values, [name]: value });
  };

  const submitHandler: FormEventHandler = event => {
    event.preventDefault();
    event.persist();
  };

  return (
    <Modal
      show={show}
      onHide={() => setShowModal(false)}
      size="lg"
      aria-labelledby="contained-modal-title-vcenter"
      centered
    >
      <Modal.Header closeButton="true">
        <Modal.Title>Edit Disagree Comments</Modal.Title>
      </Modal.Header>
      <Divider />
      <Modal.Body>
        <Form onSubmit={submitHandler}>
          <Row>
            <Col sm={6}>
              <Form.Group className="mb-3" controlId="editPoContactName">
                <Form.Label>Comments</Form.Label>
                <Form.Control
                  type="text"
                  style={{ width: '1000px' }}
                  name="comment"
                  defaultValue={agreeDisagree.comments}
                  onChange={handleClick}
                  value={value}
                />
              </Form.Group>
            </Col>
          </Row>
        </Form>
      </Modal.Body>
      <Modal.Footer>
        <ButtonArea
          primaryLabel="Continue"
          secondaryLabel="Cancel"
          primaryOnClick={() => handleContinueClick(values, agreeDisagree)}
          secondaryOnClick={() => setShowModal(false)}
        />
      </Modal.Footer>
    </Modal>
  );
};

export { CommentsModal };
