import React from 'react';

export const CaretDown = ({ width = '10.5', height = '6', style = {} }) => {
  return (
    <svg
      width={width}
      height={height}
      viewBox={`0 0 ${width} ${height}`}
      style={style}
    >
      <path
        d="M8.5 0 4.25 4.857 0 0z"
        data-name="Actions / Navigation / caret--down / 16"
      />
    </svg>
  );
};
