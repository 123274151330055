import DatePicker from 'react-datepicker';
import { useState, useContext, useEffect } from 'react';
import 'react-datepicker/dist/react-datepicker.css';
import { Button, Form, Tab, Table, Tabs, Row, Col } from 'react-bootstrap';
import './AuditDetails.scss';
import { useDispatch, useSelector } from 'react-redux';
import { setAuditData } from 'store/slices/AuditModules/auditSlice';

const CrewSafetyAudits = () => {
  const [crewSafetyAudit, setCrewSafetyAudit] = useState({});
  const dispatch = useDispatch();

  const auditData = useSelector(state => state.audit.auditFormData);

  const handleRadioChange = e => {
    const field = e.target.name.split('-');
    setCrewSafetyAudit({ ...crewSafetyAudit, [field[0]]: field[1] });
    dispatch(setAuditData({ [field[0]]: field[1] }));
  };

  useEffect(() => {
    if (Object.values(crewSafetyAudit).includes('F')) {
      dispatch(setAuditData({ crewSafetyAudit: 'F' }));
    } else if (Object.values(crewSafetyAudit).includes('P')) {
      dispatch(setAuditData({ crewSafetyAudit: 'P' }));
    } else {
      dispatch(setAuditData({ crewSafetyAudit: null }));
    }
  }, [crewSafetyAudit]);

  return (
    <>
      <div className="aepBreadcrumb auditInnerContainer">
        <Form.Group>
          <Form.Label className="responseLabel">
            Forestry Contract Crew Safety Audit
          </Form.Label>
          <Form.Group controlId="kindOfSelection" className="responseForm">
            <Form.Check
              inline
              type="radio"
              aria-label="radio 1"
              label="Pass"
              className="input1"
              name="crewSafetyAudit-P"
              checked={auditData?.crewSafetyAudit == 'P'}
            />
            <Form.Check
              inline
              type="radio"
              aria-label="radio 2"
              label="Fail"
              className="input1"
              name="crewSafetyAudit-F"
              checked={auditData?.crewSafetyAudit == 'F'}
            />
          </Form.Group>
        </Form.Group>
      </div>
      <Form className="auditSection">
        <Row className="mbAudit">
          <Col sm={4}>
            <Form.Group>
              <Form.Label className="responseLabel">
                1. Personal Protective Equipment:
              </Form.Label>
              <Form.Group className="responseForm ml-4 mb-3">
                <Form.Check
                  inline
                  type="radio"
                  aria-label="radio 1"
                  label="Pass"
                  className="input1"
                  name="perProEquip-P"
                  onChange={e => handleRadioChange(e)}
                  checked={auditData?.perProEquip == 'P'}
                />
                <Form.Check
                  inline
                  type="radio"
                  aria-label="radio 2"
                  label="Fail"
                  className="input1"
                  name="perProEquip-F"
                  onChange={e => handleRadioChange(e)}
                  checked={auditData?.perProEquip == 'F'}
                />
              </Form.Group>
            </Form.Group>
          </Col>
          <Col sm={4}>
            <Form.Group controlId="form.remitAddress">
              <Form.Label className="responseLabel">
                4. Proper Fall Protection Procedure
              </Form.Label>
              <Form.Group
                controlId="kindOfSelection"
                className="responseForm ml-4 mb-3"
              >
                <Form.Check
                  inline
                  type="radio"
                  aria-label="radio 1"
                  label="Pass"
                  className="input1"
                  name="proFallProProc-P"
                  onChange={e => handleRadioChange(e)}
                  checked={auditData?.proFallProProc == 'P'}
                />
                <Form.Check
                  inline
                  type="radio"
                  aria-label="radio 2"
                  label="Fail"
                  className="input1"
                  name="proFallProProc-F"
                  onChange={e => handleRadioChange(e)}
                  checked={auditData?.proFallProProc == 'F'}
                />
              </Form.Group>
            </Form.Group>
          </Col>
          <Col sm={4}>
            <Form.Group>
              <Form.Label className="auditLabel">
                7. Follow Proper Approach Distances
              </Form.Label>
              <Form.Group
                controlId="kindOfSelection"
                className="responseForm ml-4 mb-3"
              >
                <Form.Check
                  inline
                  type="radio"
                  aria-label="radio 1"
                  label="Pass"
                  className="input1"
                  name="follPropApprDist-P"
                  onChange={e => handleRadioChange(e)}
                  checked={auditData?.follPropApprDist == 'P'}
                />
                <Form.Check
                  inline
                  type="radio"
                  aria-label="radio 2"
                  label="Fail"
                  className="input1"
                  name="follPropApprDist-F"
                  onChange={e => handleRadioChange(e)}
                  checked={auditData?.follPropApprDist == 'F'}
                />
              </Form.Group>
            </Form.Group>
          </Col>
        </Row>
        <Row className="mbAudit">
          <Col sm={4}>
            <Form.Group controlId="form.remitAddress">
              <Form.Label className="responseLabel">
                2. Properly Maintained Safety Equipment:
              </Form.Label>
              <Form.Group
                controlId="kindOfSelection"
                className="responseForm ml-4 mb-3"
              >
                <Form.Check
                  inline
                  type="radio"
                  aria-label="radio 1"
                  label="Pass"
                  className="input1"
                  name="proMainSafEquip-P"
                  onChange={e => handleRadioChange(e)}
                  checked={auditData?.proMainSafEquip == 'P'}
                />
                <Form.Check
                  inline
                  type="radio"
                  aria-label="radio 2"
                  label="Fail"
                  className="input1"
                  name="proMainSafEquip-F"
                  onChange={e => handleRadioChange(e)}
                  checked={auditData?.proMainSafEquip == 'F'}
                />
              </Form.Group>
            </Form.Group>
          </Col>
          <Col sm={4}>
            <Form.Group controlId="form.remitAddress">
              <Form.Label className="responseLabel">
                5. Properly Barricade Work Area
              </Form.Label>
              <Form.Group
                controlId="kindOfSelection"
                className="responseForm ml-4 mb-3"
              >
                <Form.Check
                  inline
                  type="radio"
                  aria-label="radio 1"
                  label="Pass"
                  className="input1"
                  name="proBarrWoArea-P"
                  onChange={e => handleRadioChange(e)}
                  checked={auditData?.proBarrWoArea == 'P'}
                />
                <Form.Check
                  inline
                  type="radio"
                  aria-label="radio 2"
                  label="Fail"
                  className="input1"
                  name="proBarrWoArea-F"
                  onChange={e => handleRadioChange(e)}
                  checked={auditData?.proBarrWoArea == 'F'}
                />
              </Form.Group>
            </Form.Group>
          </Col>
        </Row>
        <Row className="mbAudit">
          <Col sm={4}>
            <Form.Group controlId="form.remitAddress">
              <Form.Label className="responseLabel">
                3. Traffic Control Devices
              </Form.Label>
              <Form.Group
                controlId="kindOfSelection"
                className="responseForm ml-4 mb-3"
              >
                <Form.Check
                  inline
                  type="radio"
                  aria-label="radio 1"
                  label="Pass"
                  className="input1"
                  name="traContDev-P"
                  onChange={e => handleRadioChange(e)}
                  checked={auditData?.traContDev == 'P'}
                />
                <Form.Check
                  inline
                  type="radio"
                  aria-label="radio 2"
                  label="Fail"
                  className="input1"
                  name="traContDev-F"
                  onChange={e => handleRadioChange(e)}
                  checked={auditData?.traContDev == 'F'}
                />
              </Form.Group>
            </Form.Group>
          </Col>
          <Col sm={4}>
            <Form.Group controlId="form.remitAddress">
              <Form.Label className="responseLabel">
                6. Properly Maintain and Store Work tools
              </Form.Label>
              <Form.Group
                controlId="kindOfSelection"
                className="responseForm ml-4 mb-3"
              >
                <Form.Check
                  inline
                  type="radio"
                  aria-label="radio 1"
                  label="Pass"
                  className="input1"
                  name="proMainStoWoTools-P"
                  onChange={e => handleRadioChange(e)}
                  checked={auditData?.proMainStoWoTools == 'P'}
                />
                <Form.Check
                  inline
                  type="radio"
                  aria-label="radio 2"
                  label="Fail"
                  className="input1"
                  name="proMainStoWoTools-F"
                  onChange={e => handleRadioChange(e)}
                  checked={auditData?.proMainStoWoTools == 'F'}
                />
              </Form.Group>
            </Form.Group>
          </Col>
        </Row>
        <Row className="mbAudit">
          <Col sm={12}>
            <Form.Group controlId="form.remitAddress" className="ml-3">
              <Form.Label className="safetyAudits ml-1">Comments</Form.Label>
              <Form.Control
                as="textarea"
                value={auditData?.comments1}
                onChange={e =>
                  dispatch(setAuditData({ comments1: e.target.value }))
                }
              />
            </Form.Group>
          </Col>
        </Row>
      </Form>
    </>
  );
};
export { CrewSafetyAudits };
