import React, { useEffect } from 'react';
import { BackArrowIcon } from 'assets/images/BackArrowIcon/BackArrowIcon';
import './AEPDistributionStandard.scss';
import { PDFViewer } from 'components/common/PDFviewer/PDFViewer';
import { distributionStandardURL } from '../Constant/constant';
import { Page } from 'enums/page.ts';
import { setActivePage } from 'store/slices/pageSlice';
import { useDispatch } from 'react-redux';

const AEPDistributionStandard = () => {
  const dispatch = useDispatch();
  useEffect(() => {
    dispatch(setActivePage(Page.ADDITIONAL_LINKS));
  });
  return (
    <>
      <div className="aepContainer aepHeader">Additional Links</div>
      <div className="aepContainer aepBreadcrumb" style={{ display: 'flex' }}>
        <div className="backArrow" title="back">
          <BackArrowIcon />
        </div>
        AEP Distribution Standard
      </div>
      <PDFViewer
        pdfUrl={distributionStandardURL.url}
        name={distributionStandardURL.name}
      />
    </>
  );
};
export { AEPDistributionStandard };
