import { all, call, put, takeLatest } from 'redux-saga/effects';
import * as service from 'services/purchaseOrderService';
import { displayError } from 'components/common/Alert/ToastAlert';
import {
  fetchPurchaseOrderInvoice,
  setPurchaseOrderInvoice,
  setError,
} from 'store/slices/purchaseOrder/purchaseOrderInvoiceSlice';

function* getPurchaseOrderInvoice(action) {
  try {
    const response = yield call(
      f => service.retrievePurchasOrderInvoice(action.payload),
      action.payload,
    );
    const { data } = response;
    yield put(setPurchaseOrderInvoice(data));
  } catch (e) {
    put(setError(true));
    displayError(
      'We are experiencing technical difficulties. Please come back shortly to try again !',
    );
  }
}

function* watchFetchPurchaseOrderInvoice() {
  yield takeLatest(fetchPurchaseOrderInvoice.type, getPurchaseOrderInvoice);
}

export function* purchaseOrderInvoiceSaga() {
  yield all([watchFetchPurchaseOrderInvoice()]);
}
