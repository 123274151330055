import { SERVICE_TYPES_TABkEY } from 'enums/serviceTypes.ts';

const templates = [
  { label: 'Select Template', value: 'selectTemplate' },
  { label: 'Blank', value: 'blank' },
];

const newtabList = [
  { label: 'Select Service Type', value: 'selectatab', sortOrder: 0 },
  { label: 'Labor Rates', value: 'laborRates', sortOrder: 1 },
  { label: 'Equipment Rates', value: 'equipment', sortOrder: 2 },
  { label: 'Reimbursable Mark-ups', value: 'reimbursableMarkup', sortOrder: 3 },
  { label: 'Unit Price Rates', value: 'unitPriceRates', sortOrder: 4 },
  //{ label: 'Task Code Rates', value: 'taskCodeRates', sortOrder: 5 },//Not used anymore
  {
    label: 'Compatible Unit Groups (Overhead)',
    value: 'compatibleUnitGroups',
    sortOrder: 6,
  },
  {
    label: 'Underground Multiplier Rates',
    value: 'undergroundMultiplier',
    sortOrder: 7,
  },
  { label: 'Markups', value: 'markups', sortOrder: 8 },
];

const sortOrderMapping = {
  selectatab: 0,
  [SERVICE_TYPES_TABkEY.bidrateLaborRates]: 1,
  [SERVICE_TYPES_TABkEY.bidrateEquipmentRates]: 2,
  [SERVICE_TYPES_TABkEY.bidrateReimbursibleMarkups]: 3,
  [SERVICE_TYPES_TABkEY.bidrateUnitPrices]: 4,
  // [SERVICE_TYPES_TABkEY.bidrateTaskCodes]: 5, //Not used anymore
  [SERVICE_TYPES_TABkEY.bidrateCus]: 6,
  [SERVICE_TYPES_TABkEY.bidrateUndergrndMltplrRates]: 7,
  [SERVICE_TYPES_TABkEY.bidrateUnionRates]: 8,
};

export { newtabList, templates, sortOrderMapping };
