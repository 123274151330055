import {
  LabelFieldMapping,
  POCategoryFieldMapping,
  PORateKeyMapping,
} from 'constants/poRates.ts';
import { SERVICE_TYPES } from 'enums/serviceTypes.ts';
import { isEmptyVal } from 'utils/utils';

export const serviceTypes = [
  { label: 'Labor', value: SERVICE_TYPES.LABOR },
  { label: 'Equipment', value: SERVICE_TYPES.EQUIPMENT },
  { label: 'Reimbursable Markup', value: SERVICE_TYPES.REIMBURSABLE_MARKUP },
  { label: 'Unit Price Task', value: SERVICE_TYPES.UNIT_PRICE_TASK },
  { label: 'Lumpsum', value: SERVICE_TYPES.LUMPSUM },
  { label: 'CU', value: SERVICE_TYPES.CU },
];

export const serviceTypeObject = {
  [SERVICE_TYPES.LABOR]: 'Labor',
  [SERVICE_TYPES.EQUIPMENT]: 'Equipment',
  [SERVICE_TYPES.REIMBURSABLE_MARKUP]: 'Reimbursable Markup',
  [SERVICE_TYPES.UNIT_PRICE_TASK]: 'Unit Price Task',
  [SERVICE_TYPES.LUMPSUM]: 'Lumpsum',
  [SERVICE_TYPES.CU]: 'CU',
};

export const serviceTypeList = [
  SERVICE_TYPES.LABOR,
  SERVICE_TYPES.EQUIPMENT,
  SERVICE_TYPES.REIMBURSABLE_MARKUP,
  SERVICE_TYPES.UNIT_PRICE_TASK,
  SERVICE_TYPES.LUMPSUM,
  SERVICE_TYPES.CU,
];

// IMPORTANT NOTE: Do not change order of the objects in the lists below.
export const classificationList = {
  [SERVICE_TYPES.LABOR]: [
    {
      label: 'Rate Schedule',
      values: [''],
      value: '',
      name: 'rateschecode',
      required: true,
    },

    {
      label: 'Labor Code',
      values: [''],
      value: '',
      name: 'laborCode',
      required: true,
    },

    {
      label: 'Labor Category',
      values: [''],
      value: '',
      name: 'laborCategory',
      required: false,
    },

    {
      label: 'Activity',
      values: [''],
      value: '',
      name: 'activitynum',
      required: true,
    },

    {
      label: 'Work Description',
      values: [],
      value: '',
      name: 'workDesc',
      required: false,
    },

    {
      label: 'Employee Id',
      values: [],
      value: '',
      name: 'employeeId',
      required: false,
    },

    {
      label: 'Employee Name',
      values: [],
      value: '',
      name: 'employeeName',
      required: false,
    },
    {
      label: 'Shift',
      values: [''],
      value: '',
      name: 'shift',
      required: false,
    },
    {
      label: 'Straight Time (hrs)',
      values: [],
      value: '',
      name: 'regLaborHours',
      required: false,
    },
    {
      label: 'Over Time (hrs)',
      values: [],
      value: '',
      name: 'otLaborHours',
      required: false,
    },
    {
      label: 'Double Time (hrs)',
      values: [],
      value: '',
      name: 'dtLaborHours',
      required: false,
    },
    {
      label: 'Holiday Pay (hrs)',
      values: [],
      value: '',
      name: 'holidayPayHours',
      required: false,
    },
    {
      label: 'Travel (hrs)',
      values: [],
      value: '',
      name: 'travelHours',
      required: false,
    },
    {
      label: 'Peak Season (hrs)',
      values: [],
      value: '',
      name: 'peakSeasonHours',
      required: false,
    },
    {
      label: 'Emergency Call-out (hrs)',
      values: [],
      value: '',
      name: 'emerHours',
      required: false,
    },
    {
      label: 'Standby (hrs)',
      values: [],
      value: '',
      name: 'standbyHours',
      required: false,
    },
    {
      label: 'Mobilization (units)',
      values: [],
      value: '',
      name: 'mobUnits',
      required: false,
    },
    {
      label: 'Demobilization (units)',
      values: [],
      value: '',
      name: 'demobUnits',
      required: false,
    },
    {
      label: 'Per diem (units)',
      values: [],
      value: '',
      name: 'perDiemUnits',
      required: false,
    },
    {
      label: 'Daily (hrs)',
      values: [],
      value: '',
      name: 'dailyHours',
      required: false,
    },
    {
      label: 'Weekly (hrs)',
      values: [],
      value: '',
      name: 'weeklyHours',
      required: false,
    },
    {
      label: 'Monthly (hrs)',
      values: [],
      value: '',
      name: 'monthlyHours',
      required: false,
    },
    {
      label: 'RHX (hrs)',
      values: [],
      value: '',
      name: 'rhxHours',
      required: false,
    },
    {
      label: 'DTX (hrs)',
      values: [],
      value: '',
      name: 'dtxHours',
      required: false,
    },
  ],

  [SERVICE_TYPES.EQUIPMENT]: [
    {
      label: 'Rate Schedule',
      values: [''],
      value: '',
      name: 'rateschecode',
      required: true,
    },
    {
      label: 'Equipment Code',
      values: [''],
      value: '',
      name: 'equipCode',
      required: true,
    },

    {
      label: 'Equipment Category',
      values: [''],
      value: '',
      name: 'equipCategory',
      required: false,
    },

    {
      label: 'Activity',
      values: [''],
      value: '',
      name: 'activitynum',
      required: true,
    },

    {
      label: 'Work Description',
      values: [],
      value: '',
      name: 'workDesc',
      required: false,
    },
    {
      label: 'Unit of Measure',
      values: [],
      value: '',
      name: 'equipUnitmeasure',
      required: false,
    },
    {
      label: 'Owned or Leased',
      values: [],
      value: '',
      name: 'owned',
      required: false,
    },
    {
      label: 'Equipment Type',
      values: [],
      value: '',
      name: 'eqptype',
      required: false,
    },
    {
      label: 'Equipment Id',
      values: [{ value: '', label: 'Select' }],
      value: '',
      name: 'equipId',
      required: false,
    },
    {
      label: 'Equipment Rental Units',
      values: [],
      value: '',
      name: 'equipmentRentalUnits',
      required: false,
    },
    {
      label: 'Operating Time (hrs)',
      values: [],
      value: '',
      name: 'opHours',
      required: false,
    },
    {
      label: 'Standby Time (hrs)',
      values: [],
      value: '',
      name: 'stHours',
      required: false,
    },
    {
      label: 'Over Time (hrs)',
      values: [],
      value: '',
      name: 'otHours',
      required: false,
    },
    {
      label: 'Cost Per Mile',
      values: [],
      value: '',
      name: 'miles',
      required: false,
    },
    {
      label: 'Mobilization (units)',
      values: [],
      value: '',
      name: 'mobUnits',
      required: false,
    },
    {
      label: 'Demobilization (units)',
      values: [],
      value: '',
      name: 'demobUnits',
      required: false,
    },
  ],

  [SERVICE_TYPES.REIMBURSABLE_MARKUP]: [
    {
      label: 'Rate Schedule',
      values: [''],
      value: '',
      name: 'rateschecode',
      required: true,
    },

    {
      label: 'Reimbursable Code',
      values: [''],
      value: '',
      name: 'reimbCode',
      required: true,
    },

    {
      label: 'Activity',
      values: [''],
      value: '',
      name: 'activitynum',
      required: true,
    },
    {
      label: 'Work Description',
      values: [],
      value: '',
      name: 'workDesc',
      required: false,
    },
    {
      label: 'Unit of Measure',
      values: [],
      value: '',
      name: 'reimbUnitmeasure',
      required: false,
    },
    {
      label: 'Quantity',
      values: [],
      value: '',
      name: 'reimbQuantity',
      required: false,
    },
    {
      label: 'Price',
      values: [],
      value: '',
      name: 'reimbPrice',
      required: false,
    },
  ],

  [SERVICE_TYPES.UNIT_PRICE_TASK]: [
    {
      label: 'Rate Schedule',
      values: [''],
      value: '',
      name: 'rateschecode',
      required: true,
    },

    {
      label: 'Unit Price Code',
      values: [''],
      value: '',
      name: 'code',
      required: true,
    },

    {
      label: 'Activity',
      values: [''],
      value: '',
      name: 'activitynum',
      required: true,
    },
    {
      label: 'Work Description',
      values: [],
      value: '',
      name: 'workDesc',
      required: false,
    },
    {
      label: 'Unit of Measure',
      values: [],
      value: '',
      name: 'unitmeasure',
      required: false,
    },
    {
      label: 'Quantity',
      values: [],
      value: '',
      name: 'quantity',
      required: false,
    },
    {
      label: 'Equipment & Labor Unit Price',
      values: [],
      value: '',
      name: 'unitPriceEqptLabor',
      required: false,
    },
    {
      label: 'Labor Unit Price',
      values: [],
      value: '',
      name: 'unitPriceLabor',
      required: false,
    },
    {
      label: 'Equipment Unit Price',
      values: [],
      value: '',
      name: 'unitPriceEqpt',
      required: false,
    },
    {
      label: 'Material Unit Price',
      values: [],
      value: '',
      name: 'unitPriceMatl',
      required: false,
    },
    {
      label: 'Total Unit Price',
      values: [],
      value: '',
      name: 'totalPrice',
      required: false,
    },
    {
      label: 'Total Line Price',
      values: [],
      value: '',
      name: 'lineAmount',
      required: false,
    },
    {
      label: 'Hours',
      values: [],
      value: '',
      name: 'hours',
      required: false,
    },
  ],

  [SERVICE_TYPES.CU]: [
    {
      label: 'Rate Schedule',
      values: [''],
      value: '',
      name: 'rateschecode',
      required: true,
    },
    {
      label: 'CU Code',
      values: [''],
      value: '',
      name: 'cuGroupCode',
      required: true,
    },
    {
      label: 'Unit of Measure',
      values: [],
      value: '',
      name: 'cuUnitmeasure',
      required: false,
    },
    {
      label: 'Activity',
      values: [''],
      value: '',
      name: 'activitynum',
      required: true,
    },
    {
      label: 'Work Description',
      values: [],
      value: '',
      name: 'workDesc',
      required: false,
    },
    {
      label: 'Quantity',
      values: [],
      value: '',
      name: 'quantity',
      required: false,
    },
    {
      label: 'Install Price',
      values: [],
      value: '',
      name: 'installPrice',
      required: false,
    },
    {
      label: 'Remove Price',
      values: [],
      value: '',
      name: 'removePrice',
      required: false,
    },
    {
      label: 'Transfer Price',
      values: [],
      value: '',
      name: 'transferPrice',
      required: false,
    },
    {
      label: 'Small Job Markup',
      values: [],
      value: '',
      name: 'smallJobAmount',
      required: false,
    },
    {
      label: 'Travel Amount',
      values: [],
      value: '',
      name: 'travelAmount',
      required: false,
    },
    {
      label: 'De-Energized Amount',
      values: [],
      value: '',
      name: 'deEnergizedAmount',
      required: false,
    },
  ],

  [SERVICE_TYPES.LUMPSUM]: [
    {
      label: 'Activity',
      values: [''],
      value: '',
      name: 'activitynum',
      required: true,
    },
    {
      label: 'Amount',
      values: [],
      value: '',
      name: 'amount',
      required: false,
    },
    {
      label: 'Work Description',
      values: [],
      value: '',
      name: 'workDesc',
      required: false,
    },
  ],
};

export const classificationInvoice = {};
for (const itm in classificationList) {
  for (const item of classificationList[itm]) {
    classificationInvoice[item.name] = item.label;
  }
}
export function getClassificationLabel(name) {
  return classificationInvoice[name] || name;
}

export const getPORateObject = (
  purchaseOrderObject,
  classificationName,
  rateschValue,
  codeValue,
  categoryValue,
) => {
  let rateObject = null;

  if (
    !isEmptyVal(rateschValue) &&
    !isEmptyVal(codeValue) &&
    !isEmptyVal(categoryValue)
  ) {
    const rateSchField = LabelFieldMapping['rateSchLabel'];
    const codeField = LabelFieldMapping['codeLabel'];
    const categoryField = POCategoryFieldMapping[classificationName];

    rateObject = purchaseOrderObject[PORateKeyMapping[classificationName]].find(
      fObj =>
        fObj[rateSchField] === rateschValue &&
        fObj[codeField] === codeValue &&
        fObj[categoryField] === categoryValue,
    );
  } else if (!isEmptyVal(rateschValue) && !isEmptyVal(codeValue)) {
    const rateSchField = LabelFieldMapping['rateSchLabel'];
    const codeField = LabelFieldMapping['codeLabel'];

    rateObject = purchaseOrderObject[PORateKeyMapping[classificationName]].find(
      fObj =>
        fObj[rateSchField] === rateschValue && fObj[codeField] === codeValue,
    );
  }

  return rateObject;
};

export const setFilteredDropdownOptions = (
  tempClassifications,
  classificationName,
  classificationIndex,
  rowIndex,
  rateObj,
) => {
  // Rate Schedule
  tempClassifications[classificationIndex].values[rowIndex][0]['values'] = [
    { value: '', label: 'Select' },
    {
      value: rateObj[LabelFieldMapping['rateSchLabel']],
      label: rateObj[LabelFieldMapping['rateSchLabel']],
    },
  ];

  // Code
  tempClassifications[classificationIndex].values[rowIndex][1]['values'] = [
    { value: '', label: 'Select' },
    {
      value: rateObj[LabelFieldMapping['codeLabel']],
      label: rateObj[LabelFieldMapping['codeLabel']],
    },
  ];

  // Category
  if (
    [SERVICE_TYPES.LABOR, SERVICE_TYPES.EQUIPMENT].includes(classificationName)
  ) {
    tempClassifications[classificationIndex].values[rowIndex][2]['values'] = [
      { value: '', label: 'Select' },
      {
        value: rateObj[POCategoryFieldMapping[classificationName]],
        label: rateObj[POCategoryFieldMapping[classificationName]],
      },
    ];
  }
};

export const resetDropdownOptions = (
  lookup,
  tempClassifications,
  classificationName,
  classificationIndex,
  rowIndex,
  setValue,
) => {
  const fieldValue = '';
  const rateschFieldName =
    tempClassifications[classificationIndex].values[rowIndex][0]['name'];

  const codeFieldName =
    tempClassifications[classificationIndex].values[rowIndex][1]['name'];

  const categoryFieldName =
    tempClassifications[classificationIndex].values[rowIndex][2]['name'];

  // Rate schedule
  tempClassifications[classificationIndex].values[rowIndex][0]['value'] =
    fieldValue;
  tempClassifications[classificationIndex].values[rowIndex][0]['values'] =
    lookup?.rateScheduleOptions[classificationName];
  setValue(
    // @ts-ignore
    `${classificationName}.${rowIndex}.${rateschFieldName}`,
    fieldValue,
  );

  // Code
  tempClassifications[classificationIndex].values[rowIndex][1]['value'] =
    fieldValue;
  tempClassifications[classificationIndex].values[rowIndex][1]['values'] =
    lookup?.codeOptions[classificationName];
  setValue(
    // @ts-ignore
    `${classificationName}.${rowIndex}.${codeFieldName}`,
    fieldValue,
  );

  // Category
  if (
    [SERVICE_TYPES.LABOR, SERVICE_TYPES.EQUIPMENT].includes(classificationName)
  ) {
    tempClassifications[classificationIndex].values[rowIndex][2]['value'] =
      fieldValue;
    tempClassifications[classificationIndex].values[rowIndex][2]['values'] =
      lookup?.categoryOptions[classificationName];
    setValue(
      // @ts-ignore
      `${classificationName}.${rowIndex}.${categoryFieldName}`,
      fieldValue,
    );
  }
};

export const setAutoPopulatedFields = (
  purchaseOrderObject,
  tempClassifications,
  classificationName,
  classificationIndex,
  rowIndex,
  rateschValue,
  codeValue,
  categoryValue,
  setValue,
  getValues,
) => {
  const rateObject = getPORateObject(
    purchaseOrderObject,
    classificationName,
    rateschValue,
    codeValue,
    categoryValue,
  );

  if (!isEmptyVal(rateObject)) {
    if (classificationName === SERVICE_TYPES.EQUIPMENT) {
      // Auto populate `unit of measure` field
      tempClassifications[classificationIndex].values[rowIndex][5]['value'] =
        rateObject.unitmeasure;
      setValue(
        // @ts-ignore
        `${classificationName}.${rowIndex}.equipUnitmeasure`,
        rateObject.unitmeasure,
      );

      // Auto populate `owned or leased` field
      tempClassifications[classificationIndex].values[rowIndex][6]['value'] =
        rateObject.owned;
      setValue(
        // @ts-ignore
        `${classificationName}.${rowIndex}.owned`,
        rateObject.owned,
      );

      // Auto populate `equipment type` field
      tempClassifications[classificationIndex].values[rowIndex][7]['value'] =
        rateObject.eqpttype;
      setValue(
        // @ts-ignore
        `${classificationName}.${rowIndex}.eqptype`,
        rateObject.eqpttype,
      );
    } else if (classificationName === SERVICE_TYPES.REIMBURSABLE_MARKUP) {
      // Auto populate `unit of measure` field
      tempClassifications[classificationIndex].values[rowIndex][4]['value'] =
        rateObject.unitmeasure;
      setValue(
        // @ts-ignore
        `${classificationName}.${rowIndex}.reimbUnitmeasure`,
        rateObject.unitmeasure,
      );

      // Auto populate `price` field
      tempClassifications[classificationIndex].values[rowIndex][6]['value'] =
        rateObject.price;
      setValue(
        // @ts-ignore
        `${classificationName}.${rowIndex}.reimbPrice`,
        rateObject.price,
      );
    } else if (classificationName === SERVICE_TYPES.UNIT_PRICE_TASK) {
      // Autopopulate unit of measure field
      const unitMeasureList = tempClassifications[classificationIndex].values[
        rowIndex
      ].filter(obj => obj.name === 'unitmeasure');
      if (unitMeasureList.length) {
        unitMeasureList[0].value = rateObject.unitmeasure;
        setValue(
          // @ts-ignore
          `${classificationName}.${rowIndex}.unitmeasure`,
          rateObject.unitmeasure,
        );
      }

      // Autopopulate Equipment and Labor unit price field
      const upEqpLaborList = tempClassifications[classificationIndex].values[
        rowIndex
      ].filter(obj => obj.name === 'unitPriceEqptLabor');
      if (upEqpLaborList.length) {
        let parsedValue = null;
        try {
          parsedValue = parseFloat(rateObject.eqptlabunitprice);
        } catch (e) {}
        parsedValue = isNaN(parsedValue) ? null : parsedValue;

        upEqpLaborList[0].value = parsedValue;
        setValue(
          // @ts-ignore
          `${classificationName}.${rowIndex}.unitPriceEqptLabor`,
          parsedValue,
        );
      }

      // Autopopulate Labor unit price field
      const laborList = tempClassifications[classificationIndex].values[
        rowIndex
      ].filter(obj => obj.name === 'unitPriceLabor');
      if (laborList.length) {
        let parsedValue = null;
        try {
          parsedValue = parseFloat(rateObject.unplabor);
        } catch (e) {}
        parsedValue = isNaN(parsedValue) ? null : parsedValue;

        laborList[0].value = parsedValue;
        setValue(
          // @ts-ignore
          `${classificationName}.${rowIndex}.unitPriceLabor`,
          parsedValue,
        );
      }

      // Autopopulate Equipment unit price field
      const eqptList = tempClassifications[classificationIndex].values[
        rowIndex
      ].filter(obj => obj.name === 'unitPriceEqpt');
      if (eqptList.length) {
        let parsedValue = null;
        try {
          parsedValue = parseFloat(rateObject.eqpuntprice);
        } catch (e) {}
        parsedValue = isNaN(parsedValue) ? null : parsedValue;

        eqptList[0].value = parsedValue;
        setValue(
          // @ts-ignore
          `${classificationName}.${rowIndex}.unitPriceEqpt`,
          parsedValue,
        );
      }

      // Autopopulate Material unit price field
      const materialList = tempClassifications[classificationIndex].values[
        rowIndex
      ].filter(obj => obj.name === 'unitPriceMatl');
      if (materialList.length) {
        let parsedValue = null;
        try {
          parsedValue = parseFloat(rateObject.upmatl);
        } catch (e) {}
        parsedValue = isNaN(parsedValue) ? null : parsedValue;

        materialList[0].value = parsedValue;
        setValue(
          // @ts-ignore
          `${classificationName}.${rowIndex}.unitPriceMatl`,
          parsedValue,
        );
      }

      // Autopopulate Total unit price field
      const totalPriceList = tempClassifications[classificationIndex].values[
        rowIndex
      ].filter(obj => obj.name === 'totalPrice');
      if (totalPriceList.length) {
        let parsedValue = null;
        try {
          parsedValue = parseFloat(rateObject.totalunitprice);
        } catch (e) {}
        parsedValue = isNaN(parsedValue) ? null : parsedValue;

        totalPriceList[0].value = parsedValue;
        setValue(
          // @ts-ignore
          `${classificationName}.${rowIndex}.totalPrice`,
          parsedValue,
        );
      }

      // Autopopulate Total line price field
      const qty = getValues(
        // @ts-ignore
        `${classificationName}.${rowIndex}.quantity`,
      );
      const totlPrice = getValues(
        // @ts-ignore
        `${classificationName}.${rowIndex}.totalPrice`,
      );
      let linePrice = null;
      try {
        linePrice = (qty * totlPrice).toFixed(2);
      } catch (e) {}
      linePrice = isNaN(linePrice) ? null : linePrice;

      const linePriceList = tempClassifications[classificationIndex].values[
        rowIndex
      ].filter(obj => obj.name === 'lineAmount');
      if (linePriceList.length) {
        linePriceList[0].value = linePrice;
        setValue(
          // @ts-ignore
          `${classificationName}.${rowIndex}.lineAmount`,
          linePrice,
        );
      }
    } else if (classificationName === SERVICE_TYPES.CU) {
      // Autopopulate unit of measure field
      tempClassifications[classificationIndex].values[rowIndex][2]['value'] =
        rateObject.unitmeasure;
      setValue(
        // @ts-ignore
        `${classificationName}.${rowIndex}.cuUnitmeasure`,
        rateObject.unitmeasure,
      );

      // Autopopulate Install price field
      tempClassifications[classificationIndex].values[rowIndex][6]['value'] =
        rateObject.installrate;
      setValue(
        // @ts-ignore
        `${classificationName}.${rowIndex}.installPrice`,
        rateObject.installrate,
      );

      // Autopopulate Remove price field
      tempClassifications[classificationIndex].values[rowIndex][7]['value'] =
        rateObject.removerate;
      setValue(
        // @ts-ignore
        `${classificationName}.${rowIndex}.removePrice`,
        rateObject.removerate,
      );

      // Autopopulate Transfer price field
      tempClassifications[classificationIndex].values[rowIndex][8]['value'] =
        rateObject.transferrate;
      setValue(
        // @ts-ignore
        `${classificationName}.${rowIndex}.transferPrice`,
        rateObject.transferrate,
      );

      // Autopopulate Small Job Markup field
      tempClassifications[classificationIndex].values[rowIndex][9]['value'] =
        rateObject.smalljobmarkup;
      setValue(
        // @ts-ignore
        `${classificationName}.${rowIndex}.smallJobAmount`,
        rateObject.smalljobmarkup,
      );
    }
  }
};

export const resetAutoPopulatedFields = (
  tempClassifications,
  classificationName,
  classificationIndex,
  rowIndex,
  setValue,
) => {
  if (classificationName === SERVICE_TYPES.EQUIPMENT) {
    // Auto populate `unit of measure` on selection of code
    tempClassifications[classificationIndex].values[rowIndex][5]['value'] = '';
    setValue(
      // @ts-ignore
      `${classificationName}.${rowIndex}.equipUnitmeasure`,
      '',
    );

    // Auto populate `owned or leased` field on selection of code
    tempClassifications[classificationIndex].values[rowIndex][6]['value'] = '';
    setValue(
      // @ts-ignore
      `${classificationName}.${rowIndex}.owned`,
      '',
    );

    // Auto populate `equipment type` field on selection of code
    tempClassifications[classificationIndex].values[rowIndex][7]['value'] = '';
    setValue(
      // @ts-ignore
      `${classificationName}.${rowIndex}.eqptype`,
      '',
    );
  } else if (classificationName === SERVICE_TYPES.REIMBURSABLE_MARKUP) {
    // Auto populate unit of measure on selection of code
    tempClassifications[classificationIndex].values[rowIndex][4]['value'] = '';
    setValue(
      // @ts-ignore
      `${classificationName}.${rowIndex}.reimbUnitmeasure`,
      '',
    );

    // Auto populate `price` field on selection of code
    tempClassifications[classificationIndex].values[rowIndex][6]['value'] = '';
    setValue(
      // @ts-ignore
      `${classificationName}.${rowIndex}.reimbPrice`,
      '',
    );
  } else if (classificationName === SERVICE_TYPES.UNIT_PRICE_TASK) {
    // Autopopulate unit of measure field
    const unitMeasureList = tempClassifications[classificationIndex].values[
      rowIndex
    ].filter(obj => obj.name === 'unitmeasure');
    if (unitMeasureList.length) {
      unitMeasureList[0].value = '';
      setValue(
        // @ts-ignore
        `${classificationName}.${rowIndex}.unitmeasure`,
        '',
      );
    }

    // Autopopulate Equipment and Labor unit price field
    const upEqpLaborList = tempClassifications[classificationIndex].values[
      rowIndex
    ].filter(obj => obj.name === 'unitPriceEqptLabor');
    if (upEqpLaborList.length) {
      upEqpLaborList[0].value = null;
      setValue(
        // @ts-ignore
        `${classificationName}.${rowIndex}.unitPriceEqptLabor`,
        null,
      );
    }

    // Autopopulate Labor unit price field
    const laborList = tempClassifications[classificationIndex].values[
      rowIndex
    ].filter(obj => obj.name === 'unitPriceLabor');
    if (laborList.length) {
      let parsedValue = null;
      laborList[0].value = parsedValue;
      setValue(
        // @ts-ignore
        `${classificationName}.${rowIndex}.unitPriceLabor`,
        parsedValue,
      );
    }

    // Autopopulate Equipment unit price field
    const eqptList = tempClassifications[classificationIndex].values[
      rowIndex
    ].filter(obj => obj.name === 'unitPriceEqpt');
    if (eqptList.length) {
      let parsedValue = null;
      eqptList[0].value = parsedValue;
      setValue(
        // @ts-ignore
        `${classificationName}.${rowIndex}.unitPriceEqpt`,
        parsedValue,
      );
    }

    // Autopopulate Material unit price field
    const materialList = tempClassifications[classificationIndex].values[
      rowIndex
    ].filter(obj => obj.name === 'unitPriceMatl');
    if (materialList.length) {
      let parsedValue = null;
      materialList[0].value = parsedValue;
      setValue(
        // @ts-ignore
        `${classificationName}.${rowIndex}.unitPriceMatl`,
        parsedValue,
      );
    }

    // Autopopulate Total unit price field
    const totalPriceList = tempClassifications[classificationIndex].values[
      rowIndex
    ].filter(obj => obj.name === 'totalPrice');
    if (totalPriceList.length) {
      let parsedValue = null;
      totalPriceList[0].value = parsedValue;
      setValue(
        // @ts-ignore
        `${classificationName}.${rowIndex}.totalPrice`,
        parsedValue,
      );
    }

    // Autopopulate Total line price field
    const linePriceList = tempClassifications[classificationIndex].values[
      rowIndex
    ].filter(obj => obj.name === 'lineAmount');
    if (linePriceList.length) {
      linePriceList[0].value = null;
      setValue(
        // @ts-ignore
        `${classificationName}.${rowIndex}.lineAmount`,
        null,
      );
    }
  } else if (classificationName === SERVICE_TYPES.CU) {
    // Autopopulate unit of measure field
    tempClassifications[classificationIndex].values[rowIndex][2]['value'] = '';
    setValue(
      // @ts-ignore
      `${classificationName}.${rowIndex}.cuUnitmeasure`,
      '',
    );

    // Autopopulate Install price field
    tempClassifications[classificationIndex].values[rowIndex][6]['value'] = '';
    setValue(
      // @ts-ignore
      `${classificationName}.${rowIndex}.cuUnitmeasure`,
      '',
    );

    // Autopopulate Remove price field
    tempClassifications[classificationIndex].values[rowIndex][7]['value'] = '';
    setValue(
      // @ts-ignore
      `${classificationName}.${rowIndex}.cuUnitmeasure`,
      '',
    );

    // Autopopulate Transfer price field
    tempClassifications[classificationIndex].values[rowIndex][8]['value'] = '';
    setValue(
      // @ts-ignore
      `${classificationName}.${rowIndex}.cuUnitmeasure`,
      '',
    );

    // Autopopulate Small Job Markup field
    tempClassifications[classificationIndex].values[rowIndex][9]['value'] = '';
    setValue(
      // @ts-ignore
      `${classificationName}.${rowIndex}.cuUnitmeasure`,
      '',
    );
  }
};
