import React, { useState, useEffect } from 'react';
import moment from 'moment';
import { useIdleTimer } from 'react-idle-timer';
import { useDispatch, useSelector } from 'react-redux';
import {
  logoutUser,
  refreshAccessToken,
} from 'store/slices/authenticationSlice';

import { decodeToken } from 'utils/tokenHelper';

export const Timer = ({ children }) => {
  const [isIdle, setIsIdle] = useState(false);
  const dispatch = useDispatch();
  const isAuthenticated = useSelector(state => state.auth.isLoggedIn);

  const bearerToken = useSelector(state => {
    if (state.auth.accessToken) {
      return state.auth.accessToken;
    } else {
      return '';
    }
  });

  const refresh = useSelector(state => {
    return state.auth.refreshToken;
  });

  useEffect(() => {
    let timeout = null;
    if (isAuthenticated) {
      const now = moment();
      const expires = moment.unix(decodeToken(bearerToken)?.exp);
      let diffInMilliseconds = expires.diff(now, 'milliseconds');

      if (diffInMilliseconds > 0) {
        /* 1 minute */
        if (diffInMilliseconds < 1000 * 60 * 1) {
          dispatch(refreshAccessToken({ refreshToken: refresh }));
        } else {
          timeout = setTimeout(() => {
            dispatch(refreshAccessToken({ refreshToken: refresh }));
          }, diffInMilliseconds / 2);
        }
      } else {
        const refreshExpires = moment.unix(decodeToken(refresh)?.exp);
        diffInMilliseconds = refreshExpires.diff(now, 'milliseconds');

        if (diffInMilliseconds > 0) {
          dispatch(refreshAccessToken({ refreshToken: refresh }));
        }
      }
    }

    return () => {
      clearTimeout(timeout);
    };
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [isAuthenticated, refresh, bearerToken]);

  useEffect(() => {
    if (isIdle && isAuthenticated) {
      dispatch(logoutUser());
    }
  }, [isIdle, isAuthenticated, bearerToken, dispatch]);

  const handleOnIdle = () => {
    setIsIdle(() => {
      return true;
    });
  };

  const handleOnActive = () => {
    setIsIdle(() => {
      return false;
    });
  };

  const handleAction = () => {
    setIsIdle(() => {
      return false;
    });
  };

  // eslint-disable-next-line no-unused-vars, @typescript-eslint/no-unused-vars
  const { getRemainingTime, getLastActiveTime } = useIdleTimer({
    timeout: 1000 * 60 * 30,
    onIdle: handleOnIdle,
    onActive: handleOnActive,
    onAction: handleAction,
    debounce: 500,
  });

  return <>{children}</>;
};
