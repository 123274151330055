import { undergroundMultiRateFields } from 'enums/fields.ts';

const serviceType = 'bidrateUndergrndMltplrRates';

const serviceTypeName = 'Underground Multiplier Rates';
const downloadExcelServiceTypeName = serviceTypeName;
const options = [
  { value: 'Effective End Date', key: 'effEndDate' },
  { value: 'Multiplier', key: 'numberField' },
  { value: 'Base Fuel Rate($)', key: 'baseFuelRate' },
  { value: 'Fuel Tolerence($)', key: 'fuelTolerance' },
  { value: 'Fuel Factor($)', key: 'fuelFactor' },
];

const downloadExcelServiceTypeValue = 'undergrndMltplrRate';

const columnsArray = [
  {
    key: undergroundMultiRateFields.code,
    value: 'Code',
    required: true,
    showHeader: true,
  },
  {
    key: undergroundMultiRateFields.unitDescription,
    value: 'Unit Description',
    required: true,
    showHeader: true,
  },
  {
    key: undergroundMultiRateFields.workCategory,
    value: 'Work Category',
    required: true,
    showHeader: true,
  },
  {
    key: undergroundMultiRateFields.location,
    value: 'Location',
    required: true,
    showHeader: true,
  },
  {
    key: undergroundMultiRateFields.effStartDate,
    value: 'Effective Start Date',
    required: true,
    showHeader: true,
  },
  {
    value: 'Effective End Date',
    key: 'effEndDate',
    required: false,
    showHeader: false,
  },
  {
    value: 'Multiplier',
    key: 'numberField',
    required: false,
    showHeader: false,
  },
  {
    value: 'Base Fuel Rate($)',
    key: 'baseFuelRate',
    required: false,
    showHeader: false,
  },
  {
    value: 'Fuel Tolerance($)',
    key: 'fuelTolerance',
    required: false,
    showHeader: false,
  },
  {
    value: 'Fuel Factor($)',
    key: 'fuelFactor',
    required: false,
    showHeader: false,
  },
];

let autoFillColumnskeys = {
  code: 'code',
  description: 'unitDescription',
};

let referenceObject = {
  code: null,
  unitDescription: null,
  effStartDate: null,
  location: null,
  numberField: null,
  baseFuelRate: null,
  fuelTolerance: null,
  fuelFactor: null,
  effEndDate: null,
  bidrateUMNmbr: null,
  bidrateNmbr: null,
  updatedDate: null,
  updatedBy: null,
  enteredBy: null,
  enteredDate: null,
  workCategory: null,
};

const header = {};
columnsArray.map(item => {
  header[item.key] = item.value;
});
options.map(item => {
  header[item.key] = item.value;
});

const undergroundExcelHeaderRefObj = [header];

export {
  options,
  columnsArray,
  serviceType,
  serviceTypeName,
  referenceObject,
  downloadExcelServiceTypeValue,
  downloadExcelServiceTypeName,
  undergroundExcelHeaderRefObj,
  autoFillColumnskeys,
};
