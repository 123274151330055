import React from 'react';

export const ImportIcon = () => {
  return (
    <svg width="24" height="24" viewBox="0 0 24 24">
      <g data-name="Group 161111" transform="translate(-133 -450)">
        <rect
          data-name="🔲🎨 Container l Color"
          width="24"
          height="24"
          rx="2"
          transform="translate(133 450)"
          style={{ fill: '#4a4a4a' }}
        />
        <g data-name="Group 13136">
          <g data-name="Group 160452">
            <path
              data-name="Path 28487"
              d="m10.932 6.753-.554-.553-1.019 1.013V4h-.786v3.213L7.554 6.2 7 6.753l1.966 1.966z"
              transform="translate(136.033 459.281)"
              style={{ fill: '#f5f7f9' }}
            />
            <path
              d="M121.333-824a1.279 1.279 0 0 1-.933-.4 1.279 1.279 0 0 1-.4-.933v-13.333a1.279 1.279 0 0 1 .4-.933 1.279 1.279 0 0 1 .933-.4H131.6l4.4 4.4v10.267a1.279 1.279 0 0 1-.4.933 1.279 1.279 0 0 1-.933.4zm0-1.333h13.333v-9.524h-3.8v-3.81h-9.533zm2.2-10.533H128v-1.334h-4.467zm0 3.533h8.933v-1.333h-8.933zm-2.2-6.333z"
              transform="translate(17 1294)"
              style={{ fill: '#f5f7f9' }}
            />
          </g>
        </g>
      </g>
    </svg>
  );
};
