import React from 'react';

export const CaretLeft = ({ width = '9', height = '16', style = {} }) => {
  return (
    <svg width={width} height={height} viewBox={`0 0 ${width} ${height}`}>
      <path
        data-name="expand_more_FILL0_wght400_GRAD0_opsz48(1)"
        d="M20.551 26.211a1.021 1.021 0 0 1-.383-.07 1.06 1.06 0 0 1-.348-.243l-6.885-6.885a.957.957 0 0 1-.261-.748 1.07 1.07 0 0 1 .3-.748.872.872 0 0 1 .748-.3 1.307 1.307 0 0 1 .713.33l6.12 6.12 6.12-6.12a1.135 1.135 0 0 1 .748-.313.823.823 0 0 1 .748.313.766.766 0 0 1 .3.73 1.354 1.354 0 0 1-.33.765l-6.85 6.85a1.06 1.06 0 0 1-.348.243 1.021 1.021 0 0 1-.383.07z"
        transform="rotate(90 19.442 6.769)"
        style={{ fill: '#9e9fa1', ...style }}
      />
    </svg>
  );
};
