import { all, call, put, select, takeLatest } from 'redux-saga/effects';
import * as service from 'services/adminModuleService';
import { trackPromise } from 'react-promise-tracker';
import { PROMISE_TRACKER } from 'constants/promiseTrackers';
import {
  displaySuccess,
  displayError,
} from 'components/common/Alert/ToastAlert';
import {
  fetchfuelRatesList,
  updatefuelRates,
  fetchSelectedAdminFuelRateList,
  setSelectedAdminFuelRecords,
  setfuelRatesData,
  getFuelTypes,
  setFuelTypes,
  setError,
} from 'store/slices/AdminModules/fuelRatesSlice';
import _ from 'lodash';

const trackupdatefuelRatesDetail = (fn, ...args) =>
  trackPromise(fn(...args), PROMISE_TRACKER.updatefuelRates);

const fuelRatesPaginationSlector = state => state.fuelRates.pagination;

export function* getfuelRatesList(action) {
  const queryParams = _.cloneDeep(yield select(fuelRatesPaginationSlector));

  try {
    const response = yield call(
      f => service.retrievefuelRatesList(queryParams),
      action.payload,
    );
    const { data } = response;
    data.pagination = { totalItems: data.count };
    yield put(setfuelRatesData(data));
  } catch (e) {
    put(setError(true));
  }
}

function* getFuelTypesData(action) {
  try {
    const response = yield call(f => service.getfuelTypes());
    const { data } = response;
    yield put(setFuelTypes(data));
  } catch (e) {
    put(setError(true));
    displayError(
      'We are experiencing technical difficulties. Please come back shortly to try again !',
    );
  }
}

function* updatefuelRatesData(action) {
  try {
    const response = yield call(
      trackupdatefuelRatesDetail,
      f => service.updatefuelRates(action.payload),
      action.payload,
    );
    if (response.status === 200) {
      displaySuccess('Fuel Rate record updated!');
    } else {
      displayError('Something went wrong! Please try again');
    }
    if (action.payload?.callback) {
      action.payload.callback(response.data);
    }
    yield call(f => getfuelRatesList(action));
  } catch (e) {
    put(setError(true));
  }
}

function* getSelectedAdminFuelRateList(action) {
  try {
    const response = yield call(
      f => service.retrieveAdminFuelFilterList(action.payload),
      action.payload,
    );
    const { data } = response;
    yield put(setSelectedAdminFuelRecords(data));
  } catch (e) {
    put(setError(true));
  }
}

function* watchfuelRatesList() {
  yield takeLatest(fetchfuelRatesList.type, getfuelRatesList);
}

function* watchUpdatefuelRates() {
  yield takeLatest(updatefuelRates.type, updatefuelRatesData);
}

function* watchGetFuleTypes() {
  yield takeLatest(getFuelTypes.type, getFuelTypesData);
}

function* watchFetchSelectedAdminFuelRateList() {
  yield takeLatest(
    fetchSelectedAdminFuelRateList.type,
    getSelectedAdminFuelRateList,
  );
}

export function* fuelRatesSaga() {
  yield all([
    watchfuelRatesList(),
    watchUpdatefuelRates(),
    watchGetFuleTypes(),
    watchFetchSelectedAdminFuelRateList(),
  ]);
}
