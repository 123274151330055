export const adminConstants = {
  dutyCode: [
    { label: 'Administrative', value: 'administrative' },
    { label: 'Duty Description', value: 'dutydescription' },
    { label: 'Construction', value: 'construction' },
    { label: 'Cut Stubble Spray', value: 'cutStubbleSpray' },
    { label: 'Herbicide', value: 'herbicide' },
    { label: 'HVF', value: 'hvf' },
    { label: 'Low Volume Basel', value: 'lowVolumeBasel' },
    { label: 'LVF', value: 'lvf' },
    { label: 'Manual', value: 'manual' },
    { label: 'Mechanical', value: 'mechanical' },
    { label: 'Brush Mowing', value: 'brushMowing' },
  ],
  orgGroup: [
    { label: 'Forestry', value: 'forestry' },
    { label: 'Line', value: 'line' },
  ],
};
