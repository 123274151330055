import moment from 'moment';
import { combineReducers, configureStore } from '@reduxjs/toolkit';
import createSagaMiddleware from 'redux-saga';
import rootSaga from './sagas/rootSaga';
import { decodeToken } from 'utils/tokenHelper';
import authReducer from 'store/slices/authenticationSlice';
import errorReducer from 'store/slices/errorSlice';
import purchaseOrderReducer from 'store/slices/purchaseOrder/purchaseOrderSlice';
import purchaseOrderDetailsReducer from 'store/slices/purchaseOrder/purchaseOrderDetailsSlice';
import purchaseOrderInvoiceReducer from 'store/slices/purchaseOrder/purchaseOrderInvoiceSlice';
import timesheetReducer from 'store/slices/timesheet/timesheetSlice';
import timesheetDetailReducer from 'store/slices/timesheet/timesheetDetailSlice';
import bidRateSheetListReducer from 'store/slices/BidRateSheets/bidRateSheetListSlice';
import pageReducer from 'store/slices/pageSlice';
import invoiceReducer from 'store/slices/Invoice/invoiceSlice';
import invoiceDetailsReducer from 'store/slices/Invoice/invoiceDetailSlice';
import lookupReducer from 'store/slices/lookupSlice';
import adminWorkTypeReducer from 'store/slices/AdminModules/workTypeSlice';
import adminCrewTypeReducer from 'store/slices/AdminModules/crewTypeSlice';
import vendorReducer from 'store/slices/AdminModules/vendorSlice';
import siteReducer from 'store/slices/AdminModules/adminSiteSlice';
import auditReducer from 'store/slices/AuditModules/auditSlice';
import workAreaReducer from 'store/slices/AdminModules/workAreaSlice';
import fuelRatesReducer from 'store/slices/AdminModules/fuelRatesSlice';
import districtWorkAreaReducer from 'store/slices/AdminModules/districtWorkAreaSlice';
import cuAdditionalLinkReducer from 'store/slices/cuAdditionalLinkSlice';
import filterReducer from 'store/slices/filterSlice';

const sagaMiddleware = createSagaMiddleware();

const saveToLocalStorage = state => {
  try {
    // localStorage.setItem('state', JSON.stringify(state));
  } catch (e) {
    console.error(e);
  }
};

const deleteFromLocalStorage = () => {
  try {
    window.localStorage.removeItem('state');
  } catch (e) {
    console.error(e);
  }
};

const loadFromLocalStorage = () => {
  try {
    const stateStr = localStorage.getItem('state');
    const json = stateStr ? JSON.parse(stateStr) : undefined;

    if (json?.auth?.isLoggedIn) {
      const token = decodeToken(json?.auth?.accessToken);

      if (token?.exp) {
        const expires = moment.unix(token?.exp);

        if (expires.isAfter(moment())) {
          return json;
        }
      }
    }
    return undefined;
  } catch (e) {
    console.error(e);
    return undefined;
  }
};

const combinedReducers = combineReducers({
  page: pageReducer,
  auth: authReducer,
  error: errorReducer,
  purchaseOrder: purchaseOrderReducer,
  purchaseOrderDetails: purchaseOrderDetailsReducer,
  purchaseOrderInvoice: purchaseOrderInvoiceReducer,
  timesheet: timesheetReducer,
  timesheetDetail: timesheetDetailReducer,
  bidRateSheetList: bidRateSheetListReducer,
  invoice: invoiceReducer,
  invoiceDetails: invoiceDetailsReducer,
  lookup: lookupReducer,
  adminWorkType: adminWorkTypeReducer,
  adminCrewType: adminCrewTypeReducer,
  adminVendor: vendorReducer,
  adminSite: siteReducer,
  audit: auditReducer,
  workArea: workAreaReducer,
  fuelRates: fuelRatesReducer,
  districtWorkArea: districtWorkAreaReducer,
  cuAdditionalLink: cuAdditionalLinkReducer,
  filter: filterReducer,
});

const rootReducer = (state, action) => {
  if (action?.type === 'reset-redux-store') {
    state = undefined;
  }

  return combinedReducers(state, action);
};

const resetState = () => {
  try {
    store.dispatch({ type: 'reset-redux-store' });
    deleteFromLocalStorage();
  } catch (e) {
    console.error(e);
  }
};

const persistedStore = loadFromLocalStorage();

const store = configureStore({
  reducer: rootReducer,
  middleware: [sagaMiddleware],
  preloadedState: persistedStore,
});

sagaMiddleware.run(rootSaga);

store.subscribe(() => {
  saveToLocalStorage(store.getState());
});

export default store;
export { resetState };
