import { PurchaseOrderStatus } from 'enums/statuses.ts';
import { POStatusDisplayValue } from './statusValues';

export const getPOStatusByKey = keyName => {
  let status = '';
  optionsPoListTab.forEach(option => {
    if (option.key == keyName) {
      if (option.status) {
        status = option.status?.toString();
        return;
      }
    }
  });
  return status;
};

export const optionsPoListTab = [
  {
    key: 'all',
    value: 'All',
    permission: 'can_view_all_purchaseorder',
    status: [],
  },
  {
    key: PurchaseOrderStatus.PENDING_INITIAL_REVIEW,
    value: 'Pending Initial Review',
    permission: 'can_view_pend_ir_purchaseorder',
    status: [
      PurchaseOrderStatus.PENDING_INITIAL_REVIEW,
      PurchaseOrderStatus.PENDING_INITIAL_REVIEW_SUBMITTED,
      PurchaseOrderStatus.PENDING_INITIAL_REVIEW_DECLINED,
    ],
  },
  {
    key: PurchaseOrderStatus.APPROVED_PENDING_RATES_REVIEW,
    value: 'Approved - Pending Rates Review',
    permission: 'can_view_appv_prr_purchaseorder',
    status: [
      PurchaseOrderStatus.APPROVED_PENDING_RATES_REVIEW,
      PurchaseOrderStatus.APPROVED_PENDING_RATES_REVIEW_SUBMITTED,
    ],
  },
  {
    key: PurchaseOrderStatus.APPROVED,
    value: 'Approved',
    permission: 'can_view_appv_purchaseorder',
    status: [
      PurchaseOrderStatus.APPROVED,
      PurchaseOrderStatus.APPROVED_NO_RATES,
    ],
  },

  {
    key: PurchaseOrderStatus.SUBMIT_VENDOR_COMPLETION_FORM,
    value: 'Completion Form Process',
    permission: 'can_view_submitted_completion_form',
    status: [
      PurchaseOrderStatus.SUBMIT_VENDOR_COMPLETION_FORM,
      PurchaseOrderStatus.REQUEST_VENDOR_COMPLETION_FORM,
      PurchaseOrderStatus.APPROVE_VENDOR_COMPLETION_FORM,
    ],
  },

  {
    key: PurchaseOrderStatus.CANCELLED_PENDING_ORDER,
    value: 'Cancelled/Closed',
    permission: 'can_view_can_close_purchaseorder',
    status: [
      PurchaseOrderStatus.CANCELLED_PENDING_ORDER,
      PurchaseOrderStatus.CLOSED_PENDING_ORDER,
    ],
  },
];
