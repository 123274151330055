export enum Page {
  HOME = 'home',
  AUDITMODULES = 'audit',
  ADMIN_MODULES = 'admin',
  PURCHASE_ORDER = 'purchaseorder',
  TIMESHEET = 'timesheet',
  INVOICE = 'invoice',
  REPORT = 'report',
  CHANGE_REQUEST = 'changerequest',
  BID_RATE_SHEET = 'bidratesheet',
  ADDITIONAL_LINKS = 'additionallinks',
  COMPATIBLE_UNIT_MANUAL = 'compatibleunitmanual',
  STANDARD_REVISIONS = 'standardrevisions',
  AEP_DISTRIBUTIONS_STANDARD = 'aepdistributionsstandard',
  UNIT_REVISIONS = 'unitrevisions',
  DASH_BOARD = 'dashboard',
  GENERAL = 'general',
  CU_DOWNLOAD = 'cudownload',
}
