import React, { useEffect } from 'react';
import { useState } from 'react';
import { useSelector, useDispatch } from 'react-redux';
import {
  Button,
  Col,
  Form,
  Modal,
  Row,
  Tab,
  Table,
  Tabs,
} from 'react-bootstrap';

import { TimesheetAddIcon } from 'assets/images/TimesheetAddIcon/TimesheetAddIcon';
import { TimesheetDeleteIcon } from 'assets/images/TimesheetDeleteIcon/TimesheetDeleteIcon';
import { MaximizeIcon } from 'assets/images/MaximizeIcon/MaximizeIcon';
import { MinimizeIcon } from 'assets/images/MinimizeIcon/MinimizeIcon';
import AccountingSection from './AcountingSection';
import { setRdaData } from 'store/slices/Invoice/invoiceDetailSlice';
import _ from 'lodash';
import { IsAllowedACL } from 'utils/aclHelper';
import { isEmptyVal } from 'utils/utils';
import { ModuleACL } from 'enums/entitlements.ts';
import { InvoiceStatus } from 'enums/statuses.ts';

const initialData = {
  activityNum: '',
  workorder: '',
  abmsProject: '',
  accountNmbr: '',
  projectBusinessUnit: '',
  glbu: '',
  costComponent: '',
  abmsActivity: '',
  dept: '',
  linecost: 0.0,
  percentage: 0.0,
};

const DistributionHeader = props => {
  const dispatch = useDispatch();
  const { invoiceRow, setExpand, expand, canEditActivity } = props;

  const invoice = useSelector(state => state.invoiceDetails);
  const [selectedCheckboxes, setSelectedCheckboxes] = useState({});
  const rdaData = invoice.allCostLine;
  const user = useSelector(state => state.auth);

  const activityamount = invoice.invoiceAmount;

  // const [expand, setExpand] = useState(true);
  const [isDeleteButtonEnabled, setDeleteButtonEnabled] = useState(false);

  const [selectedCheckbox, setSelectedCheckbox] = useState([]);

  const handleCheckboxChange = (activityNum, invoiceCostIndex, checked) => {
    let allActivities = { ...selectedCheckboxes };
    let costLineActivity = rdaData[0].costline;
    if (!allActivities[activityNum]) {
      let activity = {};
      for (let i = 0; i < costLineActivity.length; i++) {
        activity[i] = false;
      }
      allActivities[activityNum] = activity;
    }
    allActivities[activityNum][invoiceCostIndex] = checked;
    setSelectedCheckboxes(allActivities);
    const activityCheckboxes = allActivities[activityNum];
    const anyCheckBoxChecked = Object.values(activityCheckboxes).some(
      value => value,
    );
    setDeleteButtonEnabled(anyCheckBoxChecked);
  };

  const handleAddRow = () => {
    const updatedData = {
      ...initialData,
      activityNum: invoice.invoiceCosts?.activityNum,
    };

    const reqIndex = 0;
    let tempData = _.cloneDeep(rdaData);

    tempData[reqIndex].costline?.push(updatedData);
    const data = {
      allCostLine: tempData,
    };
    dispatch(setRdaData(data));
  };

  const handleDeleteRows = (selectedActivity, activityamount) => {
    let reqCostLine = rdaData[0].costline;
    let tempArray = [];
    for (
      let i = 0;
      i < Object.values(selectedCheckboxes[selectedActivity]).length;
      i++
    ) {
      if (!selectedCheckboxes[selectedActivity][i])
        tempArray.push(reqCostLine[i]);
    }

    const reqIndex = 0;
    let tempData = _.cloneDeep(rdaData);
    tempData[reqIndex].costline = tempArray;
    const data = {
      allCostLine: tempData,
    };
    dispatch(setRdaData(data));
    setSelectedCheckboxes({});
    setDeleteButtonEnabled(false);
  };

  return (
    <div className="accountingHeader">
      <Row style={{ width: '100%' }}>
        <Col sm={2} style={{ marginLeft: '20px' }}>
          <Form.Group>
            <Form.Label>Purchase Order</Form.Label>
            <Form.Control
              className="customSelect"
              value={invoice.purchaseOrder.ponum}
              disabled
            />
          </Form.Group>
        </Col>
        <Col sm={2}>
          <Form.Group>
            <Form.Label>Description:</Form.Label>
            <Form.Control className="customSelect" disabled />
          </Form.Group>
        </Col>
        <Col sm={2}>
          <Form.Group>
            <Form.Label>Amount:</Form.Label>
            <Form.Control
              className="customSelect"
              value={Number(invoiceRow.invoiceAmount).toFixed(2)}
              disabled
            />
          </Form.Group>
        </Col>

        <Col
          sm={5}
          style={{
            display: 'flex',
            justifyContent: 'flex-start',
          }}
        >
          {canEditActivity && (
            <>
              <div className="addIcon" onClick={() => handleAddRow()}>
                <TimesheetAddIcon />
              </div>
              <div
                className={
                  isDeleteButtonEnabled
                    ? 'deleteIconEnabled'
                    : 'deleteIconDisabled'
                }
                onClick={() =>
                  isDeleteButtonEnabled &&
                  handleDeleteRows(invoice.purchaseOrder.ponum, activityamount)
                }
              >
                <TimesheetDeleteIcon />
              </div>
            </>
          )}

          <div className="minMax">
            {expand ? (
              <div onClick={() => setExpand(false)}>
                <MaximizeIcon />
              </div>
            ) : (
              <div onClick={() => setExpand(true)}>
                <MinimizeIcon />
              </div>
            )}
          </div>
        </Col>
      </Row>

      {!expand && (
        <AccountingSection
          data={rdaData[0]?.costline}
          selectedCheckboxes={selectedCheckboxes[invoice.purchaseOrder.ponum]}
          handleCheckboxChange={handleCheckboxChange}
          invoiceAmount={activityamount}
          activityNum={invoice.purchaseOrder.ponum}
          canEditActivity={canEditActivity}
        />
      )}
    </div>
  );
};

export default DistributionHeader;
