import { Form, Modal } from 'react-bootstrap';
import { useState, useEffect } from 'react';
import { ButtonArea } from 'components/common//Button/ButtonArea/ButtonArea';
import { Divider } from 'components/common/Divider/Divider';
import { CrossButton } from 'components/CrossButton/CrossButton';
import { Row, Col } from 'react-bootstrap/esm';
import './BidRateSheetEntry.scss';

const DeleteTabConfirmationModal = ({
  show,
  setShowModal,
  selectedTabs,
  selectedKey,
  handleTabDeleteContinueClick,
}) => {
  const [selectedTabValue, setSelectedTabValue] = useState();
  useEffect(() => {
    selectedTabs.map((el, id) => {
      if (selectedKey != undefined) {
        if (el.key == selectedKey) {
          setSelectedTabValue(el.value);
        }
      }
    });
  }, [selectedKey, selectedTabs]);

  return (
    <Modal
      show={show}
      onHide={() => setShowModal(false)}
      size="md"
      dialogClassName="bidRateSheetNewTab"
      aria-labelledby="contained-modal-title-vcenter"
      centered
    >
      <Modal.Header closeButton="true">
        <Modal.Title>Remove Tab</Modal.Title>
      </Modal.Header>
      <Divider />
      <Modal.Body>
        <p>Are you sure ,You want to delete the Tab?</p>
      </Modal.Body>
      <Modal.Footer>
        <ButtonArea
          primaryLabel="Continue"
          secondaryLabel="Cancel"
          primaryOnClick={() => handleTabDeleteContinueClick(selectedTabs)}
          secondaryOnClick={() => setShowModal(false)}
        />
      </Modal.Footer>
    </Modal>
  );
};

export { DeleteTabConfirmationModal };
