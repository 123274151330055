import { BackArrowIcon } from 'assets/images/BackArrowIcon/BackArrowIcon';
import { CloseIcon } from 'assets/images/CloseIcon/CloseIcon';
import { Divider } from 'components/common/Divider/Divider';
import { TimeSheetViewAttachment } from 'pages/Timesheet/TimesheetEntry/TimeSheetViewAttachment';
import { Modal } from 'react-bootstrap';

const PDFModal = props => {
  const { showModal, setShowModal, pdfDocumentUrl } = props;

  return (
    <Modal
      show={showModal}
      onHide={() => setShowModal(false)}
      size="lg"
      dialogClassName="invoicePreviewDialog"
      aria-labelledby="contained-modal-title-vcenter"
      centered
    >
      <Modal.Header closeButton={false}>
        <div
          style={{
            display: 'flex',
            justifyContent: 'flex-start',
            width: '100%',
            height: '26px',
          }}
        >
          <div className="backArrow">
            <BackArrowIcon styles={{ marginTop: '-4px' }} />
          </div>
          PDF View
          <div
            style={{ marginLeft: 'auto', cursor: 'pointer' }}
            onClick={() => setShowModal(false)}
          >
            <CloseIcon />
          </div>
        </div>
      </Modal.Header>
      <Divider />
      <Modal.Body className="invoicePreviewModalBody">
        <TimeSheetViewAttachment file={pdfDocumentUrl} />
      </Modal.Body>
    </Modal>
  );
};

export { PDFModal };
