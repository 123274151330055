import { createSlice } from '@reduxjs/toolkit';

const initialState = {
  isLoading: false,
  error: false,
  errorResponse: {},
  items: [],
  recordItems: [],
  pagination: {
    totalItems: 0,
    currentPage: 1,
    itemsPerPage: 10,
    sortBy: '',
    sortOrder: 'asc',
  },
  inputList: [
    {
      FilterValue: '',
      input_rank: null,
      selectFilterValue: '',
      selectFilterLabel: '',
      presentIcon: '',
      filerForm: false,
      FilterLabel: '',
      selectedFilterRecords: [],
    },
  ],
  vendorList: [],
  items: [],
  isStatus: false,
};

const vendorSlice = createSlice({
  name: 'vendorSlice',
  initialState,
  reducers: {
    fetchVendorcompanyList(state, action) {
      state.isLoading = false;
    },
    fetchFilteredVendorcompanyList(state, action) {
      state.isLoading = false;
    },
    fetchVendorList(state, action) {
      const stateValues = { ...state };
      stateValues.isLoading = false;
      stateValues.pagination = {
        ...stateValues.pagination,
        ...action.payload,
      };
      return { ...stateValues };
    },
    updateVendorCompany(state) {
      state.isLoading = false;
    },
    setAdminVendorData(state, action) {
      const vendorList = [];
      action.payload?.rows.forEach(obj => {
        vendorList.push({
          Roles: obj.userEntitlements,
          vendorCompanyDetailDefault: obj.vendorVendor,
          vendorCompanyDetail: obj.usersVendorsAccesses,
          vendorName: obj.name,
          userid: obj.userid,
        });
      });
      const stateValues = { ...state };
      stateValues.vendorList = [...vendorList];
      stateValues.pagination = {
        ...stateValues.pagination,
        ...action.payload?.pagination,
      };
      return { ...stateValues };
    },

    setVendorCompanyList(state, action) {
      const stateValues = { ...state };
      stateValues.items = action.payload;
      return { ...stateValues };
    },
    setAdminVendorPagination(state, action) {
      const stateValues = { ...state };
      stateValues.pagination = {
        ...stateValues.pagination,
        ...action.payload,
      };
      return { ...stateValues };
    },

    setAddNewInputFilterData(state, action) {
      const stateValues = { ...state };
      stateValues.inputList = [...stateValues.inputList, action.payload];
      return { ...stateValues };
    },
    fetchSelectedAdminVendortList(state) {
      state.isLoading = false;
    },

    setInputFilterData(state, action) {
      if (action.payload.length != 0) {
        state.inputList = action.payload;
      } else {
        const stateValues = { ...state };
        stateValues.inputList = [...initialState.inputList];
        return { ...stateValues };
      }
    },

    setSelectedAdminVendorRecords(state, action) {
      const selectedAdminVendorRecords = [];
      action.payload?.forEach(obj => {
        let objValues = Object.values(obj);
        selectedAdminVendorRecords.push({
          label: `${objValues[1]} - ${objValues[0]}`,
          value: objValues[0],
        });
      });
      const stateValues = { ...state };
      stateValues.recordItems = [...selectedAdminVendorRecords];
      return { ...stateValues };
    },

    resetAdminVendor() {
      return { ...initialState };
    },

    unsetError(state) {
      const stateValues = { ...state };
      stateValues.error = false;
      stateValues.errorResponse = {};
      return { ...stateValues };
    },

    setError(state, action) {
      const stateValues = { ...state };
      stateValues.error = true;
      stateValues.errorResponse = action.payload;
      return { ...stateValues };
    },
  },
});

export const {
  fetchVendorcompanyList,
  fetchVendorList,
  updateVendorCompany,
  setVendorCompanyList,
  setAdminVendorData,
  setAdminVendorPagination,
  resetAdminVendor,
  setError,
  unsetError,
  setInputFilterData,
  setAddNewInputFilterData,
  fetchSelectedAdminVendortList,
  setSelectedAdminVendorRecords,
  fetchFilteredVendorcompanyList,
} = vendorSlice.actions;

export default vendorSlice.reducer;
