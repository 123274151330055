import {
  displayError,
  displaySuccess,
} from 'components/common/Alert/ToastAlert';
import { PROMISE_TRACKER } from 'constants/promiseTrackers';
import { ModuleACL } from 'enums/entitlements.ts';
import { TimesheetStatus } from 'enums/statuses.ts';
import _ from 'lodash';
import { trackPromise } from 'react-promise-tracker';
import { all, call, put, select, takeLatest } from 'redux-saga/effects';
import * as service from 'services/timesheetService';
import { setTimesheetErrorObj } from 'store/slices/errorSlice';

import { setCreateInvoiceError } from 'store/slices/Invoice/invoiceDetailSlice';

import {
  fetchJobAuthorization,
  fetchTimesheetDetail,
  setJobAuthorization,
  setTimesheetDetail,
  setTimesheetDetailError,
  setTimesheetSuccess,
  updateTimesheet,
  setApproveTimesheetError,
  setApproveCircuitMileageError,
  getWorkOrderDetails,
  updatePOCostActualAndDollar,
} from 'store/slices/timesheet/timesheetDetailSlice';
import {
  fetchSelectedTimesheetList,
  fetchTimesheets,
  saveTimesheet,
  saveUploadTimesheet,
  setError,
  setSelectedTimesheetRecords,
  setTimesheets,
  returnTimesheets,
  setIsLoading,
} from 'store/slices/timesheet/timesheetSlice';
import { getStatusByACL } from 'utils/aclHelper';
import { isEmptyVal } from 'utils/utils';
import { TimesheetStatusDisplayValue } from 'constants/statusValues';
import { BuPath } from 'constants/buPath';

const timesheetPaginationSelector = state => state.timesheet.pagination;
const bupathSelector = state => state.purchaseOrderDetails.buPath;
const userSelector = state => state.auth;

/** Promise trackers */
const trackTimesheetList = (fn, ...args) =>
  trackPromise(fn(...args), PROMISE_TRACKER.getTimesheetList);

const trackTimesheetDetail = (fn, ...args) =>
  trackPromise(fn(...args), PROMISE_TRACKER.getTimesheetDetail);

const trackSaveTimesheet = (fn, ...args) =>
  trackPromise(fn(...args), PROMISE_TRACKER.saveTimesheet);

const trackmodifyTimesheet = (fn, ...args) =>
  trackPromise(fn(...args), PROMISE_TRACKER.modifyTimesheet);

/** Saga functions */
function* getTimesheets(action) {
  const queryParams = _.cloneDeep(yield select(timesheetPaginationSelector));

  try {
    // Add permissions by status of entity
    let user = _.cloneDeep(yield select(userSelector));
    if (_.isEmpty(queryParams['caastatus'])) {
      if (queryParams['showAssigned']) {
        queryParams['caastatus'] = '';
      } else {
        const status = getStatusByACL(user, ModuleACL.TIMESHEET);
        if (!_.isEmpty(status)) {
          queryParams['caastatus'] = status;
        }
      }
    } /*else {
      let tempcaastatus = [];
      for (let i = 0; i <= queryParams['caastatus'].length; i++) {
        for (const key in TimesheetStatusDisplayValue) {
          if (TimesheetStatusDisplayValue[key] == queryParams['caastatus'][i]) {
            tempcaastatus.push(key);
            break;
          }
        }
      }
      queryParams['caastatus'] = tempcaastatus;
    } */

    const response = yield call(
      trackTimesheetList,
      f => service.retrieveTimesheets(queryParams),
      action.payload,
    );
    if ([401, 403].includes(response.status)) {
      displayError(`You don't have permission to view this page.`);
    } else {
      const { data } = response;
      data.pagination = { totalItems: data.count };
      yield put(setTimesheets(data));
    }
  } catch (e) {
    displayError(
      'We are experiencing technical difficulties. Please come back shortly to try again !',
    );
    yield put(setError(true));
  } finally {
    yield put(setIsLoading(false));
  }
}

export function* getTimesheetDetail(action) {
  try {
    const response = yield call(
      trackTimesheetDetail,
      f => service.retrieveTimesheet(action.payload.recordNumber),
      action.payload.recordNumber,
    );
    const { data } = response;
    if (response.status === 200) {
      yield put(setTimesheetDetail(data));
      if (action.payload?.callback) {
        action.payload.callback(data);
      }
    }
  } catch (e) {
    displayError(
      'We are experiencing technical difficulties. Please come back shortly to try again !',
    );
    put(setError(true));
  }
}

function* getSelectedTimesheetList(action) {
  try {
    const response = yield call(
      f => service.retreiveSelectedTimesheetList(action.payload),
      action.payload,
    );
    const { data } = response;
    yield put(setSelectedTimesheetRecords(data));
  } catch (e) {
    displayError(
      'We are experiencing technical difficulties. Please come back shortly to try again !',
    );
    put(setError(true));
  }
}

function* addTimesheet(action) {
  try {
    const response = yield call(
      trackSaveTimesheet,
      f => service.postTimesheet(action.payload.data),
      action.payload.data,
    );
    const { data } = response;

    if (response.status === 200) {
      yield put(
        setTimesheetDetail({
          caastatus: data[0]['caastatus'],
          recordNmbr: data[0]['recordNmbr'],
        }),
      );

      if (action.payload.uvl) {
        yield put(setTimesheetErrorObj({ isUvlSuccess: true }));
      } else {
        yield put(setTimesheetErrorObj({ isSuccess: true }));
      }

      yield put(setTimesheetSuccess({ isSuccess: true }));

      if (action.payload?.callback) {
        action.payload.callback(data[0]);
      }
    }
  } catch (e) {
    displayError(
      'We are experiencing technical difficulties. Please come back shortly to try again !',
    );
    put(setTimesheetDetailError(true));
  }
}

function* addUploadTimesheet(action) {
  try {
    const formData = new FormData();
    formData.append('file', action.payload);
    const response = yield call(f => service.uploadPostTimesheet(formData));

    if (response.status === 200) {
      displaySuccess('Import Timesheet has been finished successfully');
    } else if (response.status === 400) {
      displayError('No data available');
    } else {
      displayError('Something went wrong! Please try again');
    }
  } catch (e) {
    // put(setTimesheetDetailError(true));
    displayError(
      'We are experiencing technical difficulties. Please come back shortly to try again !',
    );
  }
}

function* modifyTimesheet(action) {
  try {
    let circuitMileStatus = true;
    let shouldApproveTimesheet = true;
    if (action.payload.data.caastatus === TimesheetStatus.TIMESHEET_APPROVED) {
      const validateResponse = yield call(
        trackmodifyTimesheet,
        f => service.approveTimesheet(action.payload),
        action.payload,
      );

      if (validateResponse.status === 200) {
        if (BuPath.DIST.includes(yield select(bupathSelector))) {
          const approveCircuitMileageResponse = yield call(
            trackmodifyTimesheet,
            f => service.approveCircuitMileage(action.payload),
            action.payload,
          );
          shouldApproveTimesheet =
            approveCircuitMileageResponse.status !== 200 ? false : true;

          if (!shouldApproveTimesheet) {
            const errorDetails = approveCircuitMileageResponse?.data?.details[
              'fint:errorDetail'
            ].map(errordata => {
              return errordata['val:errorDescription'];
            });
            yield put(setApproveCircuitMileageError(errorDetails));
            circuitMileStatus = false;
          }
        }
        if (circuitMileStatus) {
          const updateActAndDlrResponse = yield call(
            trackmodifyTimesheet,
            f => service.updateActualCostAndDollar(action.payload),
            action.payload,
          );

          if (
            updateActAndDlrResponse.status === 200 &&
            ['500', '400', '401'].includes(
              updateActAndDlrResponse?.data?.error?.error?.STATUSCODE,
            )
          ) {
            shouldApproveTimesheet = false;
            const errorDetails =
              updateActAndDlrResponse?.data?.error?.error?.ERRORMESSAGE.map(
                errordata => {
                  return errordata;
                },
              );
            yield put(setApproveCircuitMileageError(errorDetails));
          } else if (updateActAndDlrResponse.status != 200) {
            shouldApproveTimesheet = false;
            const errorDetails = [
              'Webmethod / Maximo is not responding. Pleae try again later !',
            ];
            yield put(setApproveCircuitMileageError(errorDetails));
          }
        }

        if (shouldApproveTimesheet) {
          const response = yield call(
            trackmodifyTimesheet,
            f => service.patchTimesheet(action.payload),
            action.payload,
          );

          if (response.status === 200 && response.data.isUpdated) {
            yield put(
              setTimesheetDetail({
                caastatus: action.payload.data['caastatus'],
              }),
            );
            yield put(setTimesheetSuccess({ isSuccess: true }));
            if (action.payload?.callback) {
              action.payload.callback(action.payload.recordNmbr);
            }
          } else {
            put(setTimesheetDetailError(true));
          }
        }
      } else {
        if (
          validateResponse.status !== 200 &&
          validateResponse?.data?.details
        ) {
          yield put(setApproveTimesheetError(validateResponse?.data?.details));
        }
      }
    } else if (
      action.payload.data.caastatus === TimesheetStatus.TIMESHEET_CANCELLED
    ) {
      const response = yield call(
        trackmodifyTimesheet,
        f => service.patchTimesheet(action.payload),
        action.payload,
      );
      if (response.status === 200 && response.data.isUpdated) {
        yield put(
          setTimesheetDetail({
            caastatus: action.payload.data['caastatus'],
          }),
        );
        yield put(setTimesheetSuccess({ isSuccess: true }));
        if (BuPath.DIST.includes(yield select(bupathSelector))) {
          const circuitMileageResponse = yield call(
            trackmodifyTimesheet,
            f => service.approveCircuitMileage(action.payload),
            action.payload,
          );
          shouldApproveTimesheet =
            circuitMileageResponse.status !== 200 ? false : true;

          if (!shouldApproveTimesheet) {
            const errorDetails = circuitMileageResponse?.data?.details[
              'fint:errorDetail'
            ].map(errordata => {
              return errordata['val:errorDescription'];
            });
            yield put(setApproveCircuitMileageError(errorDetails));
            circuitMileStatus = false;
          }
        }
        if (action.payload?.callback) {
          action.payload.callback(action.payload.recordNmbr);
        }
      } else {
        put(setTimesheetDetailError(true));
      }
    } else {
      if (
        action.payload?.data.caastatus ==
          TimesheetStatus.TIMESHEET_SAVED_NOT_SUBMITTED &&
        action.payload?.caastatus == TimesheetStatus.TIMESHEET_APPROVED
      ) {
        const updateActAndDlrResponse = yield call(
          trackmodifyTimesheet,
          f => service.updateActualCostAndDollar(action.payload),
          action.payload,
        );

        if (
          updateActAndDlrResponse.status === 200 &&
          ['500', '400', '401'].includes(
            updateActAndDlrResponse?.data?.error?.error?.STATUSCODE,
          )
        ) {
          shouldApproveTimesheet = false;
          const errorDetails =
            updateActAndDlrResponse?.data?.error?.error?.ERRORMESSAGE.map(
              errordata => {
                return errordata;
              },
            );
          yield put(setApproveCircuitMileageError(errorDetails));
        } else if (
          updateActAndDlrResponse.status == 200 &&
          updateActAndDlrResponse?.data[0]?.STATUSCODE != 200
        ) {
          shouldApproveTimesheet = false;
          const errorDetails = [
            'Unable to submit hours and dollars to Maximo. Pleae try again later !',
          ];
          yield put(setApproveCircuitMileageError(errorDetails));
        } else if (updateActAndDlrResponse.status != 200) {
          shouldApproveTimesheet = false;
          const errorDetails = [
            'Unable to submit hours and dollars to Maximo. Pleae try again later !',
          ];
          yield put(setApproveCircuitMileageError(errorDetails));
        }
      }

      if (shouldApproveTimesheet) {
        const response = yield call(
          trackmodifyTimesheet,
          f => service.patchTimesheet(action.payload),
          action.payload,
        );

        if (response.status === 200 && response.data.isUpdated) {
          yield put(
            setTimesheetDetail({ caastatus: action.payload.data['caastatus'] }),
          );
          yield put(setTimesheetSuccess({ isSuccess: true }));
          if (action.payload?.callback) {
            action.payload.callback(action.payload.recordNmbr);
          }
        } else {
          put(setTimesheetDetailError(true));
        }
      }
    }
  } catch (e) {
    put(setTimesheetDetailError(true));
  }
}

function* getJobAuthorization(action) {
  try {
    // Mimic defalult response
    let response = { status: 200, data: [] };
    if (!isEmptyVal(action.payload.jobAuthorizationId)) {
      response = yield call(
        f =>
          service.retrieveJobAuthorization(action.payload.jobAuthorizationId),
        action.payload,
      );
    }

    if (response.status === 200) {
      yield put(setJobAuthorization(response.data));

      if (action.payload?.callback) {
        action.payload.callback(response.data);
      }
    }
  } catch (e) {
    put(setTimesheetDetailError(true));
    displayError(
      'We are experiencing technical difficulties. Please come back shortly to try again !',
    );
  }
}

function* returnTimesheet(action) {
  const response = yield call(
    f => service.returnTimesheets(action.payload),
    action.payload,
  );
  const returnTimesheetError = {};
  if (
    response.status === 200 &&
    ['500', '400', '401'].includes(response?.data?.error?.error?.STATUSCODE)
  ) {
    const errorDetails = response?.data?.error?.error?.ERRORMESSAGE.map(
      errordata => {
        return errordata;
      },
    );
    returnTimesheetError.showError = true;
    returnTimesheetError.returnTimesheetError = errorDetails;
    yield put(setCreateInvoiceError(returnTimesheetError));
  } else if (
    response.status == 200 &&
    response?.data[0]?.STATUSCODE != 200 &&
    response?.data?.length < 1
  ) {
    const errorDetails = [
      'Unable to submit hours and dollars to Maximo. Pleae try again later !',
    ];
    returnTimesheetError.showError = true;
    returnTimesheetError.returnTimesheetError = errorDetails;
    yield put(setCreateInvoiceError(errorDetails));
  } else if (response.status != 200) {
    const errorDetails = [
      'Unable to submit hours and dollars to Maximo. Pleae try again later !',
    ];
    returnTimesheetError.showError = true;
    returnTimesheetError.returnTimesheetError = errorDetails;
    yield put(setCreateInvoiceError(errorDetails));
  } else {
    returnTimesheetError.showError = false;
    if (response.status === 200) {
      yield put(setJobAuthorization(response.data));
      if (action.payload?.callback) {
        action.payload.callback(response.data);
      }
    }
  }
}

function* getWODetails(action) {
  try {
    const response = yield call(
      f => service.checkWO(action.payload.workOrder),
      action.payload,
    );
    const { data } = response;
    if (action.payload.callback) {
      action.payload.callback(data);
    }
  } catch (e) {
    put(setError(true));
    displayError(
      'We are experiencing technical difficulties. Please come back shortly to try again !',
    );
  }
}

function* updateActualCostAndDollar(action) {
  try {
    const response = yield call(
      f => service.updateActualCostAndDollar(action.payload),
      action.payload,
    );
    const { data } = response;
    if (action.payload.callback) {
      action.payload.callback(data);
    }
  } catch (e) {
    put(setError(true));
    displayError(
      'We are experiencing technical difficulties. Please come back shortly to try again !',
    );
  }
}

function* watchGetWODetails() {
  yield takeLatest(getWorkOrderDetails.type, getWODetails);
}

function* watchFetchTimesheets() {
  yield takeLatest(fetchTimesheets.type, getTimesheets);
}

function* watchUpdateActualTotalAndDollars() {
  yield takeLatest(updatePOCostActualAndDollar.type, updateActualCostAndDollar);
}

function* watchAddTimesheet() {
  yield takeLatest(saveTimesheet.type, addTimesheet);
}

function* watchUploadTimesheet() {
  yield takeLatest(saveUploadTimesheet.type, addUploadTimesheet);
}

function* watchFetchTimesheet() {
  yield takeLatest(fetchTimesheetDetail.type, getTimesheetDetail);
}

function* watchFetchSelectedTimesheetList() {
  yield takeLatest(fetchSelectedTimesheetList.type, getSelectedTimesheetList);
}

function* watchModifyTimesheet() {
  yield takeLatest(updateTimesheet.type, modifyTimesheet);
}
function* watchGetJobAuthorization() {
  yield takeLatest(fetchJobAuthorization.type, getJobAuthorization);
}
function* watchReturnTimesheet() {
  yield takeLatest(returnTimesheets.type, returnTimesheet);
}

export function* timesheetSaga() {
  yield all([
    watchFetchTimesheets(),
    watchAddTimesheet(),
    watchUploadTimesheet(),
    watchFetchTimesheet(),
    watchFetchSelectedTimesheetList(),
    watchModifyTimesheet(),
    watchGetJobAuthorization(),
    watchReturnTimesheet(),
    watchGetWODetails(),
    watchUpdateActualTotalAndDollars(),
  ]);
}
