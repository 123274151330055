import { BidRateSheetStatus } from 'enums/statuses.ts';

export const keyBidRateSheetTab = {
  ALL: 'all',
  SAVED: 'saved',
  CANCELED: 'cancelled',
};

export const getBidRateSheetStatusByKey = keyName => {
  let status = '';
  optionsBidRateSheetListTab.forEach(option => {
    if (option.key == keyName) {
      if (option.status) {
        status = option.status?.toString();
        return;
      }
    }
  });
  return status;
};

export const optionsBidRateSheetListTab = [
  {
    key: keyBidRateSheetTab.ALL,
    value: 'All',
    permission: 'can_view_all_bidratesheet',
    status: [],
  },
  {
    key: keyBidRateSheetTab.SAVED,
    value: 'Saved',
    permission: 'can_view_saved_bidratesheet',
    status: [BidRateSheetStatus.saved],
  },
  {
    key: keyBidRateSheetTab.CANCELED,
    value: 'Cancelled',
    permission: 'can_view_cancelled_bidratesheet',
    status: [BidRateSheetStatus.cancelled],
  },
];
