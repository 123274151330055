// import { AEPRole, VendorRole } from 'configs/entitlements';
// import { UserType } from 'enums/entitlements.ts';
import PropTypes from 'prop-types';
import { connect, useSelector } from 'react-redux';
import { IsAllowedACL } from 'utils/aclHelper';

/*
const ShowForPermissionComponent = props => {
  try {
    const { permission, permissionPath, userPermissions } = props;
    const { userRoles, userType } = userPermissions;

    for (const userRole of userRoles) {
      const config =
        userType === UserType.AEP ? AEPRole[userRole] : VendorRole[userRole];
      if (
        config[permissionPath] &&
        config[permissionPath].includes(permission)
      ) {
        return props.children;
      }
    }
    return null;
  } catch (error) {
    return null;
  }
};
*/

const ShowForPermissionComponent = props => {
  try {
    const user = useSelector(state => state.auth);
    const { permission, permissionModule } = props;
    const bReturn = IsAllowedACL(user, permissionModule, permission);
    if (bReturn) {
      return props.children;
    }
    return null;
  } catch (error) {
    return null;
  }
};

ShowForPermissionComponent.propTypes = {
  permission: PropTypes.string.isRequired,
  permissionModule: PropTypes.string,
};

const mapStateToProps = state => ({
  userPermissions: state.auth, //<--- here you will get permissions for your user from Redux store
});

export const ShowForPermission = connect(mapStateToProps)(
  ShowForPermissionComponent,
);
