import { CaretDown } from 'assets/images/CaretIcon/CaretDown/CaretDown';
import { CaretUp } from 'assets/images/CaretIcon/CaretUp/CaretUp';
import { displaySuccess } from 'components/common/Alert/ToastAlert';
import { AEPFilterComponent } from 'components/common/Filter/AEPFilterComponent';
import { AEPPagination } from 'components/common/Pagination/AEPPagination';
import { adminConstants } from 'constants/adminConstants';
import { Page } from 'enums/page.ts';
import _ from 'lodash';
import { useCallback, useEffect, useState } from 'react';
import { Col, Row, Table } from 'react-bootstrap';
import { useDispatch, useSelector } from 'react-redux';
import {
  fetchAdminCrewType,
  fetchCrewTypeDetails,
  fetchSelectedAdminCrewList,
  setAdminCrewtypePagination,
  updateCrewTypeData,
} from 'store/slices/AdminModules/crewTypeSlice';
import { setCrewtypeFilters } from 'store/slices/filterSlice';
import { setActivePage } from 'store/slices/pageSlice';
import { isEmptyVal } from 'utils/utils';
import './AdminCrewType.scss';
import { AddNeCrewTypeModel } from './AdminCrewTypeModel';

const AdminCrewType = props => {
  const dispatch = useDispatch();
  const [crewTypeDetails, setCrewTypeDetails] = useState(false);
  const pagination = useSelector(state => state.adminCrewType.pagination);
  const currentPage = useSelector(
    state => state.adminCrewType.pagination.currentPage,
  );

  const inputListData = useSelector(state => state.filter.crewtype);
  const adminCrewFiltersValues = [
    { label: 'Crew Type', value: 'crewType' },
    { label: 'Description', value: 'crewDesc' },
    { label: 'Duty Code', value: 'dutyCode' },
  ];
  const selectedFilterRecordList = useSelector(
    state => state.adminCrewType.recordItems,
  );
  const [chipFilterValues, setChipFilterValues] = useState({});
  const [filterChip, setFilterchip] = useState(false);
  const [continueBtnDisable, setContinueBtnDisable] = useState(true);

  const sortBy = useSelector(state => state.adminCrewType.pagination.sortBy);
  const sortOrder = useSelector(
    state => state.adminCrewType.pagination.sortOrder,
  );
  const crewTypeData = useSelector(state => state.adminCrewType.crewTypeData);
  const crewTypeDetailsData = useSelector(state => state.adminCrewType.items);
  const headersCrewTypes = [
    {
      value: 'Crew Type',
      sortkey: 'crewType',
    },
    {
      value: 'Description',
      sortkey: 'crewDesc',
    },
    {
      value: 'Duty Code',
      sortkey: 'dutyCode',
    },
  ];
  const [inputs, setInputs] = useState(crewTypeDetailsData);
  const onChangeHandler = useCallback(({ target: { name, value } }) =>
    setInputs(state => ({ ...state, [name]: value }), []),
  );

  const handleCrewTypeClick = (CrewTypeDetails, i) => {
    dispatch(fetchCrewTypeDetails(CrewTypeDetails.crewNmbr));
    setCrewTypeDetails(true);
  };

  useEffect(() => {
    dispatch(setActivePage(Page.ADMIN_MODULES));
    if (props.resetFilter) {
      props.setResetFilter(false);
      handleContinueClick([
        {
          FilterValue: '',
          input_rank: null,
          selectFilterValue: '',
          selectFilterLabel: '',
          presentIcon: '',
          filerForm: false,
          FilterLabel: '',
          selectedFilterRecords: [],
        },
      ]);
    } else {
      handleContinueClick(inputListData);
    }
  }, [dispatch]);

  const handleSorting = (sortBy, sortOrder) => {
    dispatch(
      setAdminCrewtypePagination({
        ...pagination,
        currentPage: 1,
        sortBy,
        sortOrder,
      }),
    );
  };

  const handleAdminCrewTypeCancel = () => {
    setCrewTypeDetails(false);
  };

  const handleAdminCrewTypeSave = () => {
    let newCrewTypeDetailsDataListCopy = _.cloneDeep(crewTypeDetailsData);
    Object.keys(newCrewTypeDetailsDataListCopy).map((el, id) => {
      Object.keys(inputs).map(function (item) {
        if (el == item) {
          return (newCrewTypeDetailsDataListCopy[el] = inputs[item]);
        }
      });
    });
    delete newCrewTypeDetailsDataListCopy.enteredBy;
    delete newCrewTypeDetailsDataListCopy.enteredDate;
    delete newCrewTypeDetailsDataListCopy.updatedBy;
    delete newCrewTypeDetailsDataListCopy.updatedDate;
    delete newCrewTypeDetailsDataListCopy.crewNmbr;

    dispatch(
      updateCrewTypeData({
        data: newCrewTypeDetailsDataListCopy,
        crewTypeId: crewTypeDetailsData.crewNmbr,
        callback: data => {
          setCrewTypeDetails(false);
          displaySuccess('Successfully Saved');
        },
      }),
    );
  };

  const objDecorate = obj => {
    let newObj = _.mapKeys(obj, (value, key) => {
      return key;
    });
    return newObj;
  };

  const handleContinueClick = (data = null) => {
    let inputData = _.cloneDeep(inputListData);
    if (!isEmptyVal(data)) {
      inputData = _.cloneDeep(data);
    }

    let selectedFilterData = {};
    let selectedFilterValue = {};
    let uniqueFilterValues = [];

    inputData.map((el, id) => {
      if (uniqueFilterValues.indexOf(el.FilterValue) === -1) {
        uniqueFilterValues.push(el.FilterValue);
      }
    });

    uniqueFilterValues.map(value => {
      let temp1 = [];
      inputData.map((val, ids) => {
        if (val.FilterValue == value) {
          temp1.push(val.selectFilterValue);
        }
      });
      selectedFilterData[value] = temp1;
    });

    inputData.map((el, id) => {
      Object.keys(selectedFilterData).map(function (item) {
        if (item == el.FilterValue) {
          selectedFilterValue[el.FilterLabel] = selectedFilterData[item];
        }
      });
    });
    const selectedFilterDataDecorated = objDecorate(selectedFilterData);

    if (props.resetFilter) {
      dispatch(
        fetchAdminCrewType({
          currentPage: 1,
          crewType: '',
          crewDesc: '',
          dutyCode: '',
        }),
      );
    } else {
      dispatch(fetchAdminCrewType(selectedFilterDataDecorated));
    }

    let vendorSelectFilterLabel = [];
    if (selectedFilterValue.Vendor != undefined) {
      selectedFilterValue.Vendor.map((e, id) => {
        inputData.map((val, ind) => {
          if (val.selectFilterValue == e) {
            vendorSelectFilterLabel.push(val.selectFilterLabel);
          }
        });
        selectedFilterValue.Vendor = vendorSelectFilterLabel;
        return selectedFilterValue.Vendor;
      });
    }

    setChipFilterValues(selectedFilterValue);
    setFilterchip(true);
  };

  const handleFilterclose = type => {
    if (type == 'filterChange') {
      setContinueBtnDisable(false);
    } else if (type == 'deleteEmpty') {
      setContinueBtnDisable(true);
    }
  };

  const handleFilterChip = (chipname, chipValue) => {
    let filterchipData = {};

    Object.keys(chipFilterValues).map(function (item, i) {
      let chipList = [...chipFilterValues[item]];
      chipList.map((val, ids) => {
        if (val == chipValue) {
          chipList.splice(ids, 1);
        }
      });
      chipFilterValues[item] = chipList;
    });

    inputListData.map((el, id) => {
      Object.keys(chipFilterValues).map(function (item) {
        if (item == el.FilterLabel) {
          filterchipData[el.FilterValue] = chipFilterValues[item];
        }
      });
    });

    let indextodelete;
    let newInputList1 = _.cloneDeep(inputListData);
    newInputList1.map((val, ids) => {
      if (val.FilterValue === 'vendor' || val.FilterValue === 'vendorid') {
        if (val.FilterLabel == chipname && val.selectFilterLabel == chipValue) {
          indextodelete = ids;
        }
      } else {
        if (val.FilterLabel == chipname && val.selectFilterValue == chipValue) {
          indextodelete = ids;
        }
      }
    });
    newInputList1.splice(indextodelete, 1);

    if (newInputList1.length == 0) {
      setContinueBtnDisable(true);
    }
    dispatch(setCrewtypeFilters(newInputList1));
    setChipFilterValues(chipFilterValues);

    let vendorSelectFilterValue = [];
    if (filterchipData.vendor != undefined) {
      filterchipData.vendor.map((e, id) => {
        inputListData.map((val, ind) => {
          if (val.selectFilterLabel == e) {
            vendorSelectFilterValue.push(val.selectFilterValue);
          }
        });
        filterchipData.vendor = vendorSelectFilterValue;
        return filterchipData.vendor;
      });
    }

    // Check is using "vendorid"
    const filterchipDataDecorated = objDecorate(filterchipData);
    dispatch(fetchAdminCrewType(filterchipDataDecorated));
  };
  return (
    <>
      <div className="aepContainer adminModuleCrewTypeContainer">
        <div className="tableContainer">
          <Row>
            <Col sm={11}>
              <>
                <div className="filterComponent">
                  <AEPFilterComponent
                    inputListData={inputListData}
                    filterValues={adminCrewFiltersValues}
                    setInputFilterData={setCrewtypeFilters}
                    selectedFilterRecordList={selectedFilterRecordList}
                    fetchSelectedList={fetchSelectedAdminCrewList}
                    handleContinueClick={handleContinueClick}
                    continueBtnDisable={continueBtnDisable}
                    handleFilterclose={handleFilterclose}
                    handleFilterChip={handleFilterChip}
                    filterChip={filterChip}
                    chipFilterValues={chipFilterValues}
                  />
                </div>
              </>
            </Col>
          </Row>
          <Table hover responsive striped bordered={false}>
            <thead>
              <tr>
                {headersCrewTypes.map((header, idx) => (
                  <th key={idx}>
                    {sortOrder === 'asc' && (
                      <div
                        id={header.sortkey}
                        onClick={e => handleSorting(header.sortkey, 'desc')}
                      >
                        {header.value}
                        <CaretUp style={{ marginLeft: '12px' }} />
                      </div>
                    )}
                    {sortOrder === 'desc' && (
                      <div
                        id={header.sortkey}
                        onClick={e => handleSorting(header.sortkey, 'asc')}
                      >
                        {header.value}
                        <CaretDown style={{ marginLeft: '12px' }} />
                      </div>
                    )}
                  </th>
                ))}
              </tr>
            </thead>
            <tbody>
              {crewTypeData !== 'undefined' &&
                !_.isEmpty(crewTypeData) &&
                crewTypeData.map((row, indx) => (
                  <tr
                    key={indx}
                    className="tRow table-hover-row"
                    onClick={e => handleCrewTypeClick(row, indx)}
                  >
                    <td>{row.crewType}</td>
                    <td>{row.crewDesc}</td>
                    <td>{row.dutyCode}</td>
                  </tr>
                ))}
            </tbody>
          </Table>
          <div className="float-right">
            <AEPPagination
              fetchItems={fetchAdminCrewType}
              setPagination={setAdminCrewtypePagination}
              pagination={pagination}
              currentPage={currentPage}
              sortBy={sortBy}
              sortOrder={sortOrder}
            />
          </div>
        </div>
        <AddNeCrewTypeModel
          show={crewTypeDetails}
          setShowModal={setCrewTypeDetails}
          handleAdminCrewTypeSave={handleAdminCrewTypeSave}
          handleAdminCrewTypeCancel={handleAdminCrewTypeCancel}
          onChangeHandler={onChangeHandler}
          crewTypeDetailsData={crewTypeDetailsData}
          inputs={inputs}
          dutyCode={adminConstants.dutyCode}
          orgGroup={adminConstants.orgGroup}
        />
      </div>
    </>
  );
};
export { AdminCrewType };
