import React, { useEffect } from 'react';
import { BackArrowIcon } from 'assets/images/BackArrowIcon/BackArrowIcon';
import './StandardRevisions.scss';
import { PDFViewer } from 'components/common/PDFviewer/PDFViewer';
import { standardRevisionsURL } from '../Constant/constant';
import { Page } from 'enums/page.ts';
import { setActivePage } from 'store/slices/pageSlice';
import { useDispatch } from 'react-redux';

const StandardRevisions = () => {
  const dispatch = useDispatch();
  useEffect(() => {
    dispatch(setActivePage(Page.ADDITIONAL_LINKS));
  });
  return (
    <>
      <div className="aepContainer aepHeader">Additional Links</div>
      <div className="aepContainer aepBreadcrumb" style={{ display: 'flex' }}>
        <div className="backArrow" title="Back">
          <BackArrowIcon />
        </div>
        Standard Revisions
      </div>
      <PDFViewer
        pdfUrl={standardRevisionsURL.url}
        name={standardRevisionsURL.name}
      />
    </>
  );
};
export { StandardRevisions };
