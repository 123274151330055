import React from 'react';

export const CollapseIcon = () => {
  return (
    <svg
      xmlns="http://www.w3.org/2000/svg"
      width="24"
      height="24"
      viewBox="0 0 24 24"
    >
      <circle
        id="Ellipse_719"
        data-name="Ellipse 719"
        cx="12"
        cy="12"
        r="12"
        fill="#025a95"
      />
      <g id="expand_more_black_24dp">
        <path
          id="Path_28577"
          data-name="Path 28577"
          d="M24,24H0V0H24Z"
          fill="none"
          opacity="0.87"
        />
        <path
          id="Path_28578"
          data-name="Path 28578"
          d="M15.88,9.29,12,13.17,8.12,9.29A1,1,0,0,0,6.71,10.7l4.59,4.59a1,1,0,0,0,1.41,0L17.3,10.7a1,1,0,0,0,0-1.41,1.017,1.017,0,0,0-1.42,0Z"
          fill="#fff"
        />
      </g>
    </svg>
  );
};
