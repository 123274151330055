import { all, call, put, select, takeLatest } from 'redux-saga/effects';
import * as service from 'services/adminModuleService';
import { trackPromise } from 'react-promise-tracker';
import { PROMISE_TRACKER } from 'constants/promiseTrackers';
import {
  displaySuccess,
  displayError,
} from 'components/common/Alert/ToastAlert';
import {
  fetchWorkAreaList,
  addWorkArea,
  getWorkArea,
  fetchSelectedAdminWorkAreaList,
  setSelectedAdminWorkAreaRecords,
  updateWorkArea,
  setWorkAreaData,
  setWorkArea,
  setError,
} from 'store/slices/AdminModules/workAreaSlice';
import _ from 'lodash';

const trackaddWorkAreaDetail = (fn, ...args) =>
  trackPromise(fn(...args), PROMISE_TRACKER.addWorkArea);

const trackupdateWorkAreaDetail = (fn, ...args) =>
  trackPromise(fn(...args), PROMISE_TRACKER.updateWorkArea);

const WorkAreaPaginationSlector = state => state.workArea.pagination;

export function* getWorkAreaList(action) {
  const queryParams = _.cloneDeep(yield select(WorkAreaPaginationSlector));

  try {
    const response = yield call(
      f => service.retrieveWorkAreaList(queryParams),
      action.payload,
    );
    const { data } = response;
    data.pagination = { totalItems: data.count };
    yield put(setWorkAreaData(data));
  } catch (e) {
    put(setError(true));
    displayError(
      'We are experiencing technical difficulties. Please come back shortly to try again !',
    );
  }
}

function* addWorkAreaDetail(action) {
  try {
    const response = yield call(
      trackaddWorkAreaDetail,
      f => service.addWorkArea(action.payload),
      action.payload,
    );
    if (response.status === 200) {
      displaySuccess('Work area record added!');
    } else {
      displayError('Something went wrong! Please try again');
    }
    yield call(f => getWorkAreaList(action));
  } catch (e) {
    put(setError(true));
  }
}

function* updateWorkAreaData(action) {
  try {
    const response = yield call(
      trackupdateWorkAreaDetail,
      f => service.updateWorkArea(action.payload),
      action.payload,
    );
    if (response.status === 200) {
      displaySuccess('Work area record updated!');
    } else {
      displayError('Something went wrong! Please try again');
    }
    yield call(f => getWorkAreaList(action));
  } catch (e) {
    put(setError(true));
  }
}

function* getWorkAreaDetails(action) {
  try {
    const response = yield call(f => service.getWorkArea(action.payload.id));
    const { data } = response;
    yield put(setWorkArea(data));
    if (action.payload?.callback) {
      action.payload.callback(response.data);
    }
  } catch (e) {
    put(setError(true));
  }
}

function* getSelectedAdminWorkAreaList(action) {
  try {
    const response = yield call(
      f => service.retrieveAdminWorkAreaFilterList(action.payload),
      action.payload,
    );
    const { data } = response;
    yield put(setSelectedAdminWorkAreaRecords(data));
  } catch (e) {
    put(setError(true));
  }
}

function* watchWorkAreaList() {
  yield takeLatest(fetchWorkAreaList.type, getWorkAreaList);
}
function* watchAddWorkArea() {
  yield takeLatest(addWorkArea.type, addWorkAreaDetail);
}

function* watchGetWorkAreaDetails() {
  yield takeLatest(getWorkArea.type, getWorkAreaDetails);
}
function* watchUpdateWorkArea() {
  yield takeLatest(updateWorkArea.type, updateWorkAreaData);
}

function* watchFetchSelectedAdminWorkAreaList() {
  yield takeLatest(
    fetchSelectedAdminWorkAreaList.type,
    getSelectedAdminWorkAreaList,
  );
}

export function* workAreaSaga() {
  yield all([
    watchWorkAreaList(),
    watchAddWorkArea(),
    watchGetWorkAreaDetails(),
    watchUpdateWorkArea(),
    watchFetchSelectedAdminWorkAreaList(),
  ]);
}
