import React from 'react';

export const FilterChipCloseIcon = () => {
  return (
    <svg
      data-name="📍Trailing Icon"
      xmlns="http://www.w3.org/2000/svg"
      width="18"
      height="18"
      viewBox="0 0 18 18"
    >
      <path style={{ fill: 'none', d: 'M0 0h18v18H0z' }} />
      <path
        data-name="↳Color"
        d="M7 14A7 7 0 0 1 2.048 2.048a7 7 0 0 1 9.9 9.9A6.956 6.956 0 0 1 7 14zm0-6.013L9.513 10.5l.987-.987L7.987 7 10.5 4.487 9.513 3.5 7 6.013 4.487 3.5l-.987.987L6.013 7 3.5 9.513l.987.987L7 7.988z"
        transform="translate(2 2)"
        style={{ fill: 'rgba(0,0,0,.6)' }}
      />
    </svg>
  );
};
