// Imports
import ___CSS_LOADER_API_SOURCEMAP_IMPORT___ from "../../../../node_modules/react-scripts/node_modules/css-loader/dist/runtime/sourceMaps.js";
import ___CSS_LOADER_API_IMPORT___ from "../../../../node_modules/react-scripts/node_modules/css-loader/dist/runtime/api.js";
var ___CSS_LOADER_EXPORT___ = ___CSS_LOADER_API_IMPORT___(___CSS_LOADER_API_SOURCEMAP_IMPORT___);
// Module
___CSS_LOADER_EXPORT___.push([module.id, `.pdf-wid-hei .react-pdf__Page__svg {
  width: 85% !important;
}`, "",{"version":3,"sources":["webpack://./src/pages/AdditionalLinks/CompatibleUnitManual/CompatibleUnitManual.scss"],"names":[],"mappings":"AACE;EACE,qBAAA;AAAJ","sourcesContent":[".pdf-wid-hei {\n  .react-pdf__Page__svg {\n    width: 85% !important;\n    //   height: auto !important;\n  }\n}\n"],"sourceRoot":""}]);
// Exports
___CSS_LOADER_EXPORT___.locals = {};
export default ___CSS_LOADER_EXPORT___;
