import { PendingInitialPurchaseOrderIcon } from 'assets/images/PendingInitialPurchaseOrderIcon/PendingInitialPurchaseOrderIcon';
import { PendingInvoiceIcon } from 'assets/images/PendingInvoiceIcon/PendingInvoiceIcon';
import { PendingPurchaseOrderRatesReviewIcon } from 'assets/images/PendingPurchaseOrderRatesReviewIcon/PendingPurchaseOrderRatesReviewIcon';
import { PendingTimesheetIcon } from 'assets/images/PendingTimesheetIcon/PendingTimesheetIcon';
import { keyInvoiceTab } from 'constants/invoiceTabs.ts';
import { ROUTES_STRING } from 'constants/routes';
import { keyTimeSheetTab } from 'constants/timeSheetTabs.ts';
import { ModuleACL } from 'enums/entitlements.ts';
import { Page } from 'enums/page.ts';
import { PurchaseOrderStatus } from 'enums/statuses.ts';
import _ from 'lodash';
import { Fragment, useEffect } from 'react';
import { Button, Card, Col, Row } from 'react-bootstrap';
import { useDispatch, useSelector } from 'react-redux';
import { useNavigate } from 'react-router-dom';
import { fetchDashboardCount } from 'store/slices/authenticationSlice';
import { setActivePage } from 'store/slices/pageSlice';
import { IsAllowedACL } from 'utils/aclHelper';
import './Dashboard.scss';

export default function Dashboard() {
  const dispatch = useDispatch();
  const navigate = useNavigate();
  const user = useSelector(state => state.auth);

  useEffect(() => {
    dispatch(fetchDashboardCount());
    dispatch(setActivePage(Page.HOME));
  }, [dispatch]);

  const createTimesheet = () => {
    navigate(`/${ROUTES_STRING.timesheets}`, {
      state: 'fromHomePage',
    });
  };

  const createInvoice = () => {
    navigate(`/${ROUTES_STRING.invoices}`, {
      state: 'fromHomePage',
    });
  };

  const handlePendingInitialPurchase = () => {
    navigate(`/${ROUTES_STRING.purchaseOrders}`, {
      state: {
        from: 'homePage',
        status: PurchaseOrderStatus.PENDING_INITIAL_REVIEW,
      },
    });
  };

  const handlePurchaseOrderRates = () => {
    navigate(`/${ROUTES_STRING.purchaseOrders}`, {
      state: {
        from: 'homePage',
        status: PurchaseOrderStatus.APPROVED_PENDING_RATES_REVIEW,
      },
    });
  };

  const handlePendingTimesheet = () => {
    navigate(`/${ROUTES_STRING.timesheets}`, {
      state: {
        from: 'homePage',
        status: keyTimeSheetTab.PENDING_FOR_APPROVAL,
      },
    });
  };

  const handlePendingInvoice = () => {
    navigate(`/${ROUTES_STRING.invoices}`, {
      state: {
        from: 'homePage',
        status: keyInvoiceTab.PENDING,
      },
    });
  };

  const getCardNumber = value => {
    if (_.isNil(value)) {
      value = 0;
    }
    return value;
  };

  const cardData = [
    //Temporary commenting 'Pending Initial Purchase Orders Review' count as per WAM-19015
    // {
    //   cardHeaderTitle: 'Pending Initial Purchase Orders Review',
    //   cardNumber: getCardNumber(user?.dashCount?.pendingInitialPOReviewCount),
    //   cardAction: 'Contracting Instrument requiring your review and approval',
    //   cardStatus: 'PendingInitialPurchaseOrderIcon',
    //   onClick: handlePendingInitialPurchase,
    //   permission: 'can_view_pend_ir_purchaseorder',
    // },
    {
      cardHeaderTitle: 'Pending Purchase Orders Rates Review',
      cardNumber: getCardNumber(user?.dashCount?.pendingPORatesReviewCount),
      cardAction: 'PO Union Rates needing your action',
      cardStatus: 'PendingPurchaseOrderRatesReviewIcon',
      onClick: handlePurchaseOrderRates,
      permission: 'can_view_pend_irs_purchaseorder',
    },
    {
      cardHeaderTitle: 'Pending Timesheets',
      cardNumber: getCardNumber(user?.dashCount?.pendingTimesheetCount),
      cardAction: 'Pending Timesheets',
      cardStatus: 'PendingTimesheetIcon',
      onClick: handlePendingTimesheet,
      permission: 'can_view_pend_timesheet',
    },
    {
      cardHeaderTitle: 'Pending Invoices',
      cardNumber: getCardNumber(user?.dashCount?.pendingInvoicesCount),
      cardAction: 'Pending actions for Invoices',
      cardStatus: 'PendingInvoiceIcon',
      onClick: handlePendingInvoice,
      permission: 'can_view_pend_invoice',
    },
  ];

  return (
    <>
      <div className="dashboardHeader">
        <Row>
          <Col sm={6}>Welcome {user.userDisplayname}</Col>
          <Col sm={6}>
            <Row className="float-right">
              {IsAllowedACL(
                user,
                ModuleACL.DASHBOARD,
                'can_submit_new_timesheet',
              ) && (
                <Fragment>
                  <Button variant="primary" onClick={createTimesheet}>
                    Create Timesheet
                  </Button>
                </Fragment>
              )}
              {IsAllowedACL(
                user,
                ModuleACL.DASHBOARD,
                'can_submit_new_invoice',
              ) && (
                <Fragment>
                  <Button variant="primary" onClick={createInvoice}>
                    Create Invoice
                  </Button>
                </Fragment>
              )}
            </Row>
          </Col>
        </Row>
      </div>
      <div className="dashboardContainer">
        <Row>
          {cardData.map(
            (item, index) =>
              IsAllowedACL(user, ModuleACL.DASHBOARD, item.permission) && (
                <Col key={index} sm={3} onClick={item.onClick}>
                  <Card
                    style={{
                      borderBottom:
                        item.cardStatus == 'PendingInitialPurchaseOrderIcon'
                          ? '0.5rem solid #5ba400'
                          : item.cardStatus ==
                            'PendingPurchaseOrderRatesReviewIcon'
                          ? '0.5rem solid #5ba400'
                          : item.cardStatus == 'PendingTimesheetIcon'
                          ? '0.5rem solid #af2218'
                          : item.cardStatus == 'PendingInvoiceIcon'
                          ? '0.5rem solid #df6d00'
                          : '',
                    }}
                  >
                    <Row className="cardHeader float-right">
                      <Col sm={3}>
                        {item.cardStatus ==
                        'PendingInitialPurchaseOrderIcon' ? (
                          <PendingInitialPurchaseOrderIcon />
                        ) : item.cardStatus ==
                          'PendingPurchaseOrderRatesReviewIcon' ? (
                          <PendingPurchaseOrderRatesReviewIcon />
                        ) : item.cardStatus == 'PendingTimesheetIcon' ? (
                          <PendingTimesheetIcon />
                        ) : item.cardStatus == 'PendingInvoiceIcon' ? (
                          <PendingInvoiceIcon />
                        ) : (
                          ''
                        )}
                      </Col>
                      <Col sm={9}>
                        <div>{item.cardHeaderTitle}</div>
                      </Col>
                    </Row>
                    <Card.Body>
                      <Card.Text>You have</Card.Text>
                      <Card.Title>{item.cardNumber}</Card.Title>
                      <Card.Text>{item.cardAction}</Card.Text>
                    </Card.Body>
                  </Card>
                </Col>
              ),
          )}
        </Row>
      </div>
    </>
  );
}
