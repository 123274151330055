import React from 'react';
import ReactDOM from 'react-dom/client';
import './index.scss';
import App from './App';
import { ThemeProvider } from 'react-bootstrap';
import { ProSidebarProvider } from 'react-pro-sidebar';
import { Provider } from 'react-redux';
import store from 'store/configureStore';
import { Timer } from 'components/common/Timer';

const root = ReactDOM.createRoot(document.getElementById('root'));

root.render(
  <Provider store={store}>
    <ThemeProvider
      breakpoints={['xxs', 'xs', 'sm', 'md', 'lg', 'xl', 'xxl']}
      minBreakpoint="xs"
    >
      <ProSidebarProvider>
        {/* <Timer> */}

        <App />

        {/* </Timer> */}
      </ProSidebarProvider>
    </ThemeProvider>
  </Provider>,
);
