import { all, call, put, select, takeLatest } from 'redux-saga/effects';
import * as service from 'services/adminModuleService';
import {
  fetchCrewTypeDetails,
  fetchAdminCrewType,
  fetchSelectedAdminCrewList,
  setSelectedAdminCrewRecords,
  updateCrewTypeData,
  setAdminCrewTypeData,
  setCrewTypeDetails,
  setError,
} from 'store/slices/AdminModules/crewTypeSlice';
import { displayError } from 'components/common/Alert/ToastAlert';
import _ from 'lodash';

const crewTypePaginationSelector = state => state.adminCrewType.pagination;

export function* getAdminCrewType(action) {
  const queryParams = _.cloneDeep(yield select(crewTypePaginationSelector));

  try {
    const response = yield call(
      f => service.retrieveAdminCrewTypeData(queryParams),
      action.payload,
    );
    const { data } = response;
    data.pagination = { totalItems: data.count };
    yield put(setAdminCrewTypeData(data));
  } catch (e) {
    put(setError(true));
    displayError(
      'We are experiencing technical difficulties. Please come back shortly to try again !',
    );
  }
}

export function* updateCrewTypeDetails(action) {
  try {
    const response = yield call(
      f => service.updateCrewTypeDataDetails(action.payload),
      action.payload,
    );
    const { data } = response;
    if (action.payload.callback) {
      action.payload.callback(data);
    }
  } catch (e) {
    put(setError(true));
  }
}

export function* getCrewTypeDetails(action) {
  try {
    const response = yield call(
      f => service.retrieveCrewTypeDetails(action.payload),
      action.payload,
    );
    const { data } = response;

    yield put(setCrewTypeDetails(data));
  } catch (e) {
    put(setError(true));
  }
}

function* getSelectedAdminCrewList(action) {
  try {
    const response = yield call(
      f => service.retrieveAdminFuelFilterList(action.payload),
      action.payload,
    );
    const { data } = response;
    yield put(setSelectedAdminCrewRecords(data));
  } catch (e) {
    put(setError(true));
  }
}

function* watchCrewTypeDetails() {
  yield takeLatest(fetchCrewTypeDetails.type, getCrewTypeDetails);
}

function* watchUpdateCrewTypeData() {
  yield takeLatest(updateCrewTypeData.type, updateCrewTypeDetails);
}

function* watchAdminCrewType() {
  yield takeLatest(fetchAdminCrewType.type, getAdminCrewType);
}
function* watchFetchSelectedAdminCrewList() {
  yield takeLatest(fetchSelectedAdminCrewList.type, getSelectedAdminCrewList);
}

export function* crewtypeSaga() {
  yield all([
    watchCrewTypeDetails(),
    watchAdminCrewType(),
    watchUpdateCrewTypeData(),
    watchFetchSelectedAdminCrewList(),
  ]);
}
