import React from 'react';

export const DrillDownIcon = () => {
  return (
    <svg
      xmlns="http://www.w3.org/2000/svg"
      width="13.116"
      height="12.862"
      viewBox="0 0 13.116 12.862"
    >
      <g data-name="Group 13707">
        <path
          data-name="Path 25984"
          d="M7.264 15.222a.856.856 0 0 0 1.213 0l5.7-5.7a.683.683 0 0 0 0-.966l-5.7-5.7a.858.858 0 0 0-1.213 1.222l4.962 4.969-4.969 4.969a.854.854 0 0 0 .007 1.206z"
          transform="translate(-7.011 -2.612)"
          style={{ fill: '#4a4a4a' }}
        />
        <path
          data-name="Path 25985"
          d="M7.264 15.222a.856.856 0 0 0 1.213 0l5.7-5.7a.683.683 0 0 0 0-.966l-5.7-5.7a.858.858 0 0 0-1.213 1.222l4.962 4.969-4.969 4.969a.854.854 0 0 0 .007 1.206z"
          transform="translate(-1.257 -2.612)"
          style={{ fill: '#4a4a4a' }}
        />
      </g>
    </svg>
  );
};
