import React from 'react';

export const AuditIcon = () => {
  return (
    <svg
      xmlns="http://www.w3.org/2000/svg"
      width="17"
      height="15.3"
      viewBox="0 0 17 15.3"
    >
      <path
        id="fact_check_FILL0_wght400_GRAD0_opsz24"
        d="M81.7,231.3a1.7,1.7,0,0,1-1.7-1.7V217.7a1.7,1.7,0,0,1,1.7-1.7H95.3a1.7,1.7,0,0,1,1.7,1.7v11.9a1.7,1.7,0,0,1-1.7,1.7Zm0-1.7H95.3V217.7H81.7Zm.85-1.7H86.8v-1.7H82.55Zm8.118-1.7,4.207-4.208-1.211-1.211-3,3.018-1.211-1.211-1.19,1.211Zm-8.118-1.7H86.8v-1.7H82.55Zm0-3.4H86.8v-1.7H82.55Zm-.85,8.5v0Z"
        transform="translate(-80 -216)"
        fill="#4a4a4a"
      />
    </svg>
  );
};
