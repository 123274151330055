import React from 'react';

export const PurchaseOrderDetailsIcon = () => {
  return (
    <svg
      xmlns="http://www.w3.org/2000/svg"
      width="20"
      height="16"
      viewBox="0 0 20 16"
    >
      <path
        d="M86.75 270.5H98.5v-3.425H86.75zm-5.25-9.575h3.75V257.5H81.5zm0 4.675h3.75v-3.175H81.5zm0 4.9h3.75v-3.425H81.5zm5.25-4.9H98.5v-3.175H86.75zm0-4.675H98.5V257.5H86.75zM81.5 272a1.538 1.538 0 0 1-1.5-1.5v-13a1.538 1.538 0 0 1 1.5-1.5h17a1.538 1.538 0 0 1 1.5 1.5v13a1.538 1.538 0 0 1-1.5 1.5z"
        transform="translate(-80 -256)"
        style={{ fill: '#4a4a4a' }}
      />
    </svg>
  );
};
