import React from 'react';

export const CrewTypeIcon = () => {
  return (
    <svg
      xmlns="http://www.w3.org/2000/svg"
      width="20"
      height="18.81"
      viewBox="0 0 20 18.81"
    >
      <g data-name="Group 17910">
        <path
          d="M42 234.16v-2.294a2.371 2.371 0 0 1 .4-1.409 2.872 2.872 0 0 1 1.122-.91 17.169 17.169 0 0 1 2.88-1.072 14.484 14.484 0 0 1 6.957 0 17.168 17.168 0 0 1 2.88 1.072 2.872 2.872 0 0 1 1.122.91 2.371 2.371 0 0 1 .4 1.409v2.294zm1.5-1.5h12.763v-.8a.882.882 0 0 0-.212-.611 1.4 1.4 0 0 0-.511-.362q-1.072-.474-2.394-.91a10.531 10.531 0 0 0-3.266-.436 10.531 10.531 0 0 0-3.266.436q-1.322.436-2.394.91a1.4 1.4 0 0 0-.511.362.882.882 0 0 0-.212.611zm6.383-6.109a3.6 3.6 0 0 1-2.693-1.072 3.667 3.667 0 0 1-1.047-2.668h-.253a.5.5 0 0 1 0-1h.249a3.317 3.317 0 0 1 .5-1.8 4.026 4.026 0 0 1 1.3-1.3v.972a.383.383 0 0 0 .374.374.348.348 0 0 0 .274-.112.382.382 0 0 0 .1-.262v-1.3a4.237 4.237 0 0 1 .549-.087 6.078 6.078 0 0 1 1.346 0 4.237 4.237 0 0 1 .549.087v1.3a.382.382 0 0 0 .1.262.348.348 0 0 0 .274.112.383.383 0 0 0 .374-.374v-.972a3.8 3.8 0 0 1 1.272 1.3 3.449 3.449 0 0 1 .474 1.8h.249a.5.5 0 0 1 0 1h-.254a3.667 3.667 0 0 1-1.047 2.668 3.6 3.6 0 0 1-2.694 1.076zm0-1.5a2.174 2.174 0 0 0 1.646-.623 2.243 2.243 0 0 0 .6-1.621h-4.494a2.243 2.243 0 0 0 .6 1.621 2.174 2.174 0 0 0 1.644.631zm8.405 3.136-.224-.873a2.805 2.805 0 0 1-.511-.274 2.186 2.186 0 0 1-.436-.374l-1.1.4-.5-.873.873-.7a2.13 2.13 0 0 1-.087-.274 1.215 1.215 0 0 1 0-.6 2.133 2.133 0 0 1 .087-.274l-.873-.7.5-.873 1.1.4a2.531 2.531 0 0 1 .436-.386 1.757 1.757 0 0 1 .511-.262l.224-.873h.948l.224.873a1.757 1.757 0 0 1 .511.262 2.531 2.531 0 0 1 .436.386l1.1-.4.5.873-.873.7a2.133 2.133 0 0 1 .087.274 1.215 1.215 0 0 1 0 .6 2.13 2.13 0 0 1-.087.274l.873.7-.5.873-1.1-.4a2.186 2.186 0 0 1-.436.374 2.805 2.805 0 0 1-.511.274l-.224.873zm.474-1.82a1.407 1.407 0 1 0-1.035-.411 1.4 1.4 0 0 0 1.031.418zM43.5 232.664z"
          transform="translate(-42 -215.35)"
          style={{ fill: '#4a4a4a' }}
        />
        <path
          data-name="engineering_FILL0_wght400_GRAD0_opsz48"
          d="M42 234.16v-2.294a2.371 2.371 0 0 1 .4-1.409 2.872 2.872 0 0 1 1.122-.91 17.169 17.169 0 0 1 2.88-1.072 14.484 14.484 0 0 1 6.957 0 17.168 17.168 0 0 1 2.88 1.072 2.872 2.872 0 0 1 1.122.91 2.371 2.371 0 0 1 .4 1.409v2.294zm1.5-1.5h12.763v-.8a.882.882 0 0 0-.212-.611 1.4 1.4 0 0 0-.511-.362q-1.072-.474-2.394-.91a10.531 10.531 0 0 0-3.266-.436 10.531 10.531 0 0 0-3.266.436q-1.322.436-2.394.91a1.4 1.4 0 0 0-.511.362.882.882 0 0 0-.212.611zm6.383-6.109a3.6 3.6 0 0 1-2.693-1.072 3.667 3.667 0 0 1-1.047-2.668h-.253a.5.5 0 0 1 0-1h.249a3.317 3.317 0 0 1 .5-1.8 4.026 4.026 0 0 1 1.3-1.3v.972a.383.383 0 0 0 .374.374.348.348 0 0 0 .274-.112.382.382 0 0 0 .1-.262v-1.3a4.237 4.237 0 0 1 .549-.087 6.078 6.078 0 0 1 1.346 0 4.237 4.237 0 0 1 .549.087v1.3a.382.382 0 0 0 .1.262.348.348 0 0 0 .274.112.383.383 0 0 0 .374-.374v-.972a3.8 3.8 0 0 1 1.272 1.3 3.449 3.449 0 0 1 .474 1.8h.249a.5.5 0 0 1 0 1h-.254a3.667 3.667 0 0 1-1.047 2.668 3.6 3.6 0 0 1-2.694 1.076zm0-1.5a2.174 2.174 0 0 0 1.646-.623 2.243 2.243 0 0 0 .6-1.621h-4.494a2.243 2.243 0 0 0 .6 1.621 2.174 2.174 0 0 0 1.644.631zm8.405 3.136-.224-.873a2.805 2.805 0 0 1-.511-.274 2.186 2.186 0 0 1-.436-.374l-1.1.4-.5-.873.873-.7a2.13 2.13 0 0 1-.087-.274 1.215 1.215 0 0 1 0-.6 2.133 2.133 0 0 1 .087-.274l-.873-.7.5-.873 1.1.4a2.531 2.531 0 0 1 .436-.386 1.757 1.757 0 0 1 .511-.262l.224-.873h.948l.224.873a1.757 1.757 0 0 1 .511.262 2.531 2.531 0 0 1 .436.386l1.1-.4.5.873-.873.7a2.133 2.133 0 0 1 .087.274 1.215 1.215 0 0 1 0 .6 2.13 2.13 0 0 1-.087.274l.873.7-.5.873-1.1-.4a2.186 2.186 0 0 1-.436.374 2.805 2.805 0 0 1-.511.274l-.224.873zm.474-1.82a1.407 1.407 0 1 0-1.035-.411 1.4 1.4 0 0 0 1.031.418zM43.5 232.664z"
          transform="translate(-42 -215.35)"
          style={{ fill: '#4a4a4a' }}
        />
        <path
          data-name="engineering_FILL0_wght400_GRAD0_opsz48"
          d="m58.633 228.322-.047-.677q-.163-.093-.339-.21a3.69 3.69 0 0 1-.315-.233l-.607.327-.514-.747.607-.444a.394.394 0 0 1-.047-.175v-.35a.394.394 0 0 1 .047-.175l-.607-.444.514-.747.607.327.327-.233a3.846 3.846 0 0 1 .327-.21l.047-.677h.934l.047.677a3.847 3.847 0 0 1 .327.21l.327.233.607-.327.514.747-.607.444a.394.394 0 0 1 .047.175v.35a.394.394 0 0 1-.047.175l.607.444-.514.747-.607-.327a3.692 3.692 0 0 1-.315.233q-.175.117-.339.21l-.047.677zm.467-1.448a.877.877 0 1 0-.63-.257.857.857 0 0 0 .63.258z"
          transform="translate(-44 -223.653)"
          style={{ fill: '#4a4a4a' }}
        />
      </g>
    </svg>
  );
};
