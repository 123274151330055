import { createSlice } from '@reduxjs/toolkit';
import { Page } from 'enums/page.ts';

const initialState = {
  activePage: Page.HOME,
};

const pageSlice = createSlice({
  name: 'pageSlice',
  initialState,
  reducers: {
    setActivePage(state, action) {
      state.activePage = action.payload;
    },
  },
});

export const { setActivePage } = pageSlice.actions;

export default pageSlice.reducer;
