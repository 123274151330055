import React from 'react';

export const TimesheetRecords = () => {
  return (
    <svg
      xmlns="http://www.w3.org/2000/svg"
      width="20"
      height="19.048"
      viewBox="0 0 20 19.048"
    >
      <path
        d="M88.571 155.048a8.42 8.42 0 0 1-3.345-.667 8.548 8.548 0 0 1-4.56-4.56 8.726 8.726 0 0 1 0-6.69 8.548 8.548 0 0 1 4.56-4.56 8.42 8.42 0 0 1 3.345-.667 7.582 7.582 0 0 1 .964.06 8.152 8.152 0 0 1 .94.179v1.957a6.952 6.952 0 0 0-.94-.214 6.335 6.335 0 0 0-.964-.071 6.7 6.7 0 1 0 6.667 6.667 4.337 4.337 0 0 0-.024-.476q-.024-.214-.071-.476H97.1a2.683 2.683 0 0 1 .048.476v.476a8.42 8.42 0 0 1-.667 3.345 8.548 8.548 0 0 1-4.56 4.56 8.42 8.42 0 0 1-3.35.661zm2.667-4.571-3.619-3.619v-5.143h1.9v4.385l3.048 3.048zm4-6.857v-2.857h-2.857v-1.9h2.857V136h1.9v2.857H100v1.9h-2.857v2.857z"
        transform="translate(-80 -136)"
        style={{ fill: '#4a4a4a' }}
      />
    </svg>
  );
};
