import { Form, Modal } from 'react-bootstrap';
import { ButtonArea } from 'components/common//Button/ButtonArea/ButtonArea';
import { Divider } from 'components/common/Divider/Divider';
import { Row, Col } from 'react-bootstrap/esm';
import { useDispatch, useSelector } from 'react-redux';
import Select from 'react-select';
import { Fragment, useEffect, useState } from 'react';
import _ from 'lodash';
//import 'bootstrap/dist/css/bootstrap.min.css';
import './AdminSite.scss';

const DeleteSiteModal = ({
  show,
  setShowModal,
  handleDeleteSiteContinueClick,
}) => {
  const dispatch = useDispatch();
  return (
    <Modal
      show={show}
      onHide={() => setShowModal(false)}
      size="lg"
      aria-labelledby="contained-modal-title-vcenter"
      centered
    >
      <Modal.Header closeButton="true">
        <Modal.Title>Delete Site</Modal.Title>
      </Modal.Header>
      <Divider />
      <Modal.Body>Are you sure You want to Delete the site</Modal.Body>
      <Modal.Footer>
        <ButtonArea
          primaryLabel="Continue"
          secondaryLabel="Cancel"
          primaryOnClick={() => handleDeleteSiteContinueClick()}
          secondaryOnClick={() => setShowModal(false)}
          //primaryDisabled={continueBtn}
        />
      </Modal.Footer>
    </Modal>
  );
};

export { DeleteSiteModal };
