import DatePicker from 'react-datepicker';
import { useState, useContext } from 'react';
import 'react-datepicker/dist/react-datepicker.css';
import { Button, Form, Tab, Table, Tabs, Row, Col } from 'react-bootstrap';
import './AuditDetails.scss';
import { useDispatch, useSelector } from 'react-redux';
import { setAuditData } from 'store/slices/AuditModules/auditSlice';

const CrewAcceptable = () => {
  const dispatch = useDispatch();

  const auditData = useSelector(state => state.audit.auditFormData);

  const handleRadioChange = e => {
    const field = e.target.name.split('-');
    dispatch(setAuditData({ [field[0]]: field[1] }));
  };
  return (
    <>
      <div className="aepBreadcrumb auditInnerContainer">
        Acceptable/Unacceptable/On items below
      </div>
      <Form className="auditSection">
        <div className="mbAudit">
          <Col sm={4}>
            <Form.Group controlId="form.remitAddress">
              <Form.Label className="responseLabel">
                1. Hazardous Material properly stored,labeled and documented and
                MSDS and Herbicide Label information available
              </Form.Label>
              <Form.Group
                controlId="kindOfSelection"
                className="responseForm ml-4 mb-3"
              >
                <Form.Check
                  inline
                  type="radio"
                  aria-label="radio 1"
                  label="Acceptable"
                  className="input1"
                  name="hazMatStore-A"
                  onChange={e => handleRadioChange(e)}
                  checked={auditData?.hazMatStore == 'A'}
                />
                <Form.Check
                  inline
                  type="radio"
                  aria-label="radio 2"
                  label="Unacceptable"
                  className="input1"
                  name="hazMatStore-U"
                  onChange={e => handleRadioChange(e)}
                  checked={auditData?.hazMatStore == 'U'}
                />
              </Form.Group>
            </Form.Group>
          </Col>
          <Col sm={4}>
            <Form.Group controlId="form.remitAddress">
              <Form.Label className="responseLabel">
                2. Job briefing complete/OSHA Compliant
              </Form.Label>
              <Form.Group
                controlId="kindOfSelection"
                className="responseForm ml-4 mb-3"
              >
                <Form.Check
                  inline
                  type="radio"
                  aria-label="radio 1"
                  label="Acceptable"
                  className="input1"
                  name="jobBriefInfo-A"
                  onChange={e => handleRadioChange(e)}
                  checked={auditData?.jobBriefInfo == 'A'}
                />
                <Form.Check
                  inline
                  type="radio"
                  aria-label="radio 2"
                  label="Unacceptable"
                  name="jobBriefInfo-U"
                  onChange={e => handleRadioChange(e)}
                  className="input1"
                  checked={auditData?.jobBriefInfo == 'U'}
                />
              </Form.Group>
            </Form.Group>
          </Col>
          <Col sm={4}>
            <Form.Group controlId="form.remitAddress">
              <Form.Label className="responseLabel">
                3. Jobsite Housekeeping
              </Form.Label>
              <Form.Group
                controlId="kindOfSelection"
                className="responseForm ml-4 mb-3"
              >
                <Form.Check
                  inline
                  type="radio"
                  aria-label="radio 1"
                  label="Acceptable"
                  name="jobsiteHousekeeping-A"
                  onChange={e => handleRadioChange(e)}
                  className="input1"
                  checked={auditData?.jobsiteHousekeeping == 'A'}
                />
                <Form.Check
                  inline
                  type="radio"
                  aria-label="radio 2"
                  label="Unacceptable"
                  className="input1"
                  name="jobsiteHousekeeping-U"
                  onChange={e => handleRadioChange(e)}
                  checked={auditData?.jobsiteHousekeeping == 'U'}
                />
              </Form.Group>
            </Form.Group>
          </Col>
        </div>
        <Row className="mbAudit">
          <Col sm={12}>
            <Form.Group controlId="form.remitAddress" className="ml-3">
              <Form.Label className="safetyAudits ml-1">Comments</Form.Label>
              <Form.Control
                as="textarea"
                value={auditData?.comments2}
                onChange={e =>
                  dispatch(setAuditData({ comments2: e.target.value }))
                }
              />
            </Form.Group>
          </Col>
        </Row>
      </Form>
    </>
  );
};
export { CrewAcceptable };
