import React from 'react';
import PropTypes from 'prop-types';
import { confirmable, createConfirmation } from 'react-confirm';
import { Modal } from 'react-bootstrap';
import { Divider } from 'components/common/Divider/Divider';
import { ButtonArea } from 'components/common/Button/ButtonArea/ButtonArea';

const dialog = ({ show, proceed, message, options, title }) => (
  <Modal
    show={show}
    onHide={() => proceed(false)}
    aria-labelledby="contained-modal-title-vcenter"
    centered
  >
    <Modal.Header closeButton={true}>
      <Modal.Title>{title || 'Confirm'}</Modal.Title>
    </Modal.Header>
    <Divider />
    <Modal.Body>
      <p>{message}</p>
    </Modal.Body>
    <Modal.Footer>
      <ButtonArea
        primaryLabel="Continue"
        secondaryLabel="Cancel"
        primaryOnClick={() => proceed(true)}
        secondaryOnClick={() => proceed(false)}
      />
    </Modal.Footer>
  </Modal>
);

dialog.propTypes = {
  show: PropTypes.bool, // from confirmable. indicates if the dialog is shown or not.
  proceed: PropTypes.func, // from confirmable. call to close the dialog with promise resolved.
  message: PropTypes.string, // arguments of your confirm function
  options: PropTypes.object,
  title: PropTypes.string, // arguments of your confirm function
  // arguments of your confirm function
};

// confirmable HOC pass props `show`, `dismiss`, `cancel` and `proceed` to your component.
const cDialog = confirmable(dialog);

// create confirm function
export const confirm = createConfirmation(cDialog);

// This is optional. But wrapping function makes it easy to use.
export function confirmWrapper(confirmation, options = {}) {
  return confirm({ confirmation, options });
}
