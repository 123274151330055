import React from 'react';

export const PreviewIcon = () => {
  return (
    <svg
      data-name="Component 2849 – 3"
      xmlns="http://www.w3.org/2000/svg"
      width="32"
      height="32"
      viewBox="0 0 32 32"
    >
      <rect
        data-name="🔲🎨 Container l Color"
        width="32"
        height="32"
        rx="4"
        style={{ fill: '#025a95' }}
      />
      <path
        data-name="Rectangle 8569"
        style={{ fill: 'none' }}
        d="M0 0h24v24H0z"
        transform="translate(4 4)"
      />
      <path
        data-name="Path 28515"
        d="M19 3H5a2 2 0 0 0-2 2v14a2 2 0 0 0 2 2h14a2.006 2.006 0 0 0 2-2V5a2 2 0 0 0-2-2zm0 16H5V7h14zm-7-8.5a4.927 4.927 0 0 1 4.34 2.5 5.017 5.017 0 0 1-8.68 0A4.927 4.927 0 0 1 12 10.5M12 9a6.445 6.445 0 0 0-6 4 6.5 6.5 0 0 0 12 0 6.445 6.445 0 0 0-6-4zm0 5.5a1.5 1.5 0 1 1 1.5-1.5 1.5 1.5 0 0 1-1.5 1.5z"
        style={{ fill: '#fff' }}
        transform="translate(4 4)"
      />
    </svg>
  );
};
