import React from 'react';

export const AttachmentsIcon = () => {
  return (
    <svg
      xmlns="http://www.w3.org/2000/svg"
      width="18"
      height="18"
      viewBox="0 0 18 18"
    >
      <g
        id="Group_14013"
        data-name="Group 14013"
        transform="translate(-279 -1238)"
      >
        <circle
          id="Ellipse_714"
          data-name="Ellipse 714"
          cx="9"
          cy="9"
          r="9"
          transform="translate(279 1238)"
          fill="#025a95"
        />
        <g
          id="Group_12473"
          data-name="Group 12473"
          transform="translate(274.549 1336.596)"
        >
          <path
            id="Path_25873"
            data-name="Path 25873"
            d="M17.2-88.9,14.191-85.9a2.642,2.642,0,0,1-3.736,0,2.642,2.642,0,0,1,0-3.737l3.954-3.954a1.647,1.647,0,0,1,2.33,0,1.647,1.647,0,0,1,0,2.33l-3.96,3.96a.659.659,0,0,1-.932,0,.659.659,0,0,1,0-.932l3.032-3.032-.466-.466-3.032,3.032a1.318,1.318,0,0,0,0,1.864,1.318,1.318,0,0,0,1.864,0l3.96-3.96a2.306,2.306,0,0,0,0-3.261,2.306,2.306,0,0,0-3.261,0L9.989-90.1a3.3,3.3,0,0,0,0,4.668,3.3,3.3,0,0,0,4.668,0l3.009-3.009Z"
            fill="#fff"
            stroke="#fff"
            stroke-width="0.25"
          />
        </g>
      </g>
    </svg>
  );
};
