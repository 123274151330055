import React from 'react';

export const TimesheetDeleteIcon = () => {
  return (
    <svg width="36" height="36" viewBox="0 0 36 36">
      <rect
        data-name="🔲🎨 Container l Color"
        width="36"
        height="36"
        rx="4"
        style={{ fill: '#025a95' }}
      />
      <g data-name="Group 12186">
        <path
          data-name="Path 25858"
          d="M11.393-97.429h-4.5a.643.643 0 0 0-.643.643.643.643 0 0 0 .643.643h1.286v12.729a3.925 3.925 0 0 0 3.857 3.986h6.429a3.925 3.925 0 0 0 3.857-3.986v-12.729h1.286a.643.643 0 0 0 .643-.643.643.643 0 0 0-.643-.643h-4.5v-1.286A1.286 1.286 0 0 0 17.821-100h-5.142a1.286 1.286 0 0 0-1.286 1.286zm9.643 1.286v12.729a2.639 2.639 0 0 1-2.571 2.7h-6.429a2.639 2.639 0 0 1-2.571-2.7v-12.729zm-6.429 2.571v10.286a.643.643 0 0 0 .643.643.643.643 0 0 0 .643-.643v-10.285a.643.643 0 0 0-.643-.643.643.643 0 0 0-.643.643zm3.214 0v10.286a.643.643 0 0 0 .643.643.643.643 0 0 0 .643-.643v-10.285a.643.643 0 0 0-.643-.643.643.643 0 0 0-.643.643zm-6.429 0v10.286a.643.643 0 0 0 .643.643.643.643 0 0 0 .643-.643v-10.285a.643.643 0 0 0-.643-.643.643.643 0 0 0-.642.643zm6.429-3.857h-5.142v-1.286h5.143z"
          style={{ fill: '#fff', fillRule: 'evenodd' }}
          transform="translate(2.75 108)"
        />
      </g>
    </svg>
  );
};
