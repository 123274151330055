import React from 'react';

export const BidRateSheetIcon = () => {
  return (
    <svg
      data-name="Bid Rate Sheet Default"
      width="16"
      height="20"
      viewBox="0 0 16 20"
    >
      <path
        data-name="Path 25901"
        d="M14 2H6a2.006 2.006 0 0 0-2 2v16a2.006 2.006 0 0 0 2 2h12a2.006 2.006 0 0 0 2-2V8zM6 20V4h7v4h5v12zm5-1h2v-1h1a1 1 0 0 0 1-1v-3a1 1 0 0 0-1-1h-3v-1h4v-2h-2V9h-2v1h-1a1 1 0 0 0-1 1v3a1 1 0 0 0 1 1h3v1H9v2h2z"
        transform="translate(-4 -2)"
        style={{ fill: '#4a4a4a' }}
      />
    </svg>
  );
};
