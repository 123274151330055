import { all } from 'redux-saga/effects';
import { authSaga } from './authSaga';
import { purchaseOrderSaga } from './purchaseOrder/purchaseOrderSaga';
import { purchaseOrderDetailsSaga } from './purchaseOrder/purchaseOrderDetailsSaga';
import { purchaseOrderInvoiceSaga } from './purchaseOrder/purchaseOrderInvoiceSaga';
import { timesheetSaga } from './timesheet/timesheetSaga';
import { bidRateSheetSaga } from './bidRateSheet/bidRateSheetSaga';
import { invoiceSaga } from './Invoice/invoiceSaga';
import { lookupSaga } from './lookupSaga';
import { worktypeSaga } from './AdminModules/worktypeSaga';
import { crewtypeSaga } from './AdminModules/crewtypeSaga';
import { vendorSaga } from './AdminModules/vendorSaga';
import { adminSiteSaga } from './AdminModules/adminSiteSaga';
import { auditSaga } from './AuditModules/auditSaga';
import { workAreaSaga } from './AdminModules/workAreaSaga';
import { fuelRatesSaga } from './AdminModules/fuelRatesSaga';
import { districtWorkAreaSaga } from './AdminModules/districtWorkAreaSaga';
import { cuAdditionalLinkSaga } from './cuAdditionalLinkSaga';

function* rootSaga() {
  yield all([
    authSaga(),
    purchaseOrderSaga(),
    purchaseOrderDetailsSaga(),
    purchaseOrderInvoiceSaga(),
    timesheetSaga(),
    bidRateSheetSaga(),
    invoiceSaga(),
    lookupSaga(),
    worktypeSaga(),
    crewtypeSaga(),
    vendorSaga(),
    adminSiteSaga(),
    auditSaga(),
    workAreaSaga(),
    fuelRatesSaga(),
    districtWorkAreaSaga(),
    cuAdditionalLinkSaga(),
  ]);
}

export default rootSaga;
