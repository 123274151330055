import { ModuleACL } from 'enums/entitlements.ts';
import _ from 'lodash';
import { all, call, put, select, takeLatest } from 'redux-saga/effects';
import * as service from 'services/bidRateSheetService';
import { retrieveConfig } from 'services/lookup';
import {
  fetchBidRateLocations,
  fetchBidRateSheetDetails,
  fetchBidRateSheetList,
  fetchBidRateWorkCategory,
  fetchEquipmentServiceTypeOptions,
  fetchLaborServiceTypeOptions,
  fetchOtherServiceTypeOptions,
  saveBidRateSheetDetailsData,
  saveLaborRatesData,
  setBidRateNumber,
  setBidRateSheet,
  setBidRateSheetDetails,
  setBidRateSheetLocation,
  setBidRateSheetSuccess,
  setBidRateSheetWorkCategory,
  setEquipmentServiceTypeOptions,
  setError,
  setLaborRatesPreviewData,
  setLaborServiceTypeOptions,
  setOtherServiceTypeOptions,
  fetchBidRateEquipmentTypes,
  fetchBidRateOwnershipTypes,
  setBidRateSheetOwnershipType,
  setBidRateSheetEquipmentType,
  setSelectedBidRateSheetRecords,
  fetchSelectedBidRateSheetList,
  fetchServiceTypeOptions,
  fetchServiceTypeOptionsLabor,
  fetchServiceTypeOptionsEqp,
  setSelectedServiceTypeRecords,
  resetFilters,
  fetchNewFiltersData,
} from 'store/slices/BidRateSheets/bidRateSheetListSlice';
import {
  displayError,
  displaySuccess,
} from 'components/common/Alert/ToastAlert';
import { getStatusByACL } from 'utils/aclHelper';
import { PROMISE_TRACKER } from 'constants/promiseTrackers';
import { trackPromise } from 'react-promise-tracker';

const trackBidRateDetails = (fn, ...args) =>
  trackPromise(fn(...args), PROMISE_TRACKER.getBidRateDetails);

const userSelector = state => state.auth;
const bidRateSheetPaginationSelector = state =>
  state.bidRateSheetList.pagination;

/** Saga functions */
function* getBidRateSheetList(action) {
  const queryParams = _.cloneDeep(yield select(bidRateSheetPaginationSelector));
  try {
    // Add permissions by status of entity
    let user = _.cloneDeep(yield select(userSelector));
    if (_.isEmpty(queryParams['caastatus'])) {
      const status = getStatusByACL(user, ModuleACL.BIDRATESHEET);
      if (!_.isEmpty(status)) {
        queryParams['caastatus'] = status;
      }
    }
    const response = yield call(
      f => service.retrieveBidRateSheet(queryParams),
      action.payload,
    );
    const { data } = response;
    data.pagination = { totalItems: data.count };
    yield put(setBidRateSheet(data));
  } catch (e) {
    put(setError(true));
    displayError(
      'We are experiencing technical difficulties. Please come back shortly to try again !',
    );
  }
}

function* getBidRateSheetWorkCategory(action) {
  try {
    const response = yield call(
      f => service.retrieveWorkCategory(),
      action.payload,
    );
    const { data } = response;
    yield put(setBidRateSheetWorkCategory(data));
  } catch (e) {
    put(setError(true));
  }
}

function* getBidRateSheetLocations(action) {
  try {
    const response = yield call(
      f => service.retrieveLocations(),
      action.payload,
    );
    const { data } = response;
    yield put(setBidRateSheetLocation(data));
  } catch (e) {
    put(setError(true));
  }
}

function* getBidRateEquipmentTypes() {
  try {
    const response = yield call(() => retrieveConfig('equipment_type'));
    const { data } = response;
    yield put(setBidRateSheetEquipmentType(data));
  } catch (e) {
    put(setError(true));
  }
}

function* getBidRateOwnershipTypes() {
  try {
    const response = yield call(() => retrieveConfig('ownership_type'));
    const { data } = response;
    yield put(setBidRateSheetOwnershipType(data));
  } catch (e) {
    put(setError(true));
  }
}

function* getLaborServiceTypeOptions(action) {
  const queryParams = _.cloneDeep(yield select(bidRateSheetPaginationSelector));

  try {
    const response = yield call(
      f => service.retrieveBidRateServiceTypeOptions('Labor', queryParams),
      action.payload,
    );
    const { data } = response;
    data.pagination = { totalItems: data.count };
    yield put(setLaborServiceTypeOptions(data));
  } catch (e) {
    put(setError(true));
  }
}

function* getEquipmentServiceTypeOptions(action) {
  const queryParams = _.cloneDeep(yield select(bidRateSheetPaginationSelector));

  try {
    const response = yield call(
      f => service.retrieveBidRateServiceTypeOptions('Equipment', queryParams),
      action.payload,
    );
    const { data } = response;
    data.pagination = { totalItems: data.count };
    yield put(setEquipmentServiceTypeOptions(data));
  } catch (e) {
    put(setError(true));
  }
}

function* getOtherServiceTypeOptions(action) {
  const queryParams = _.cloneDeep(yield select(bidRateSheetPaginationSelector));

  try {
    const response = yield call(
      f => service.retrieveBidRateServiceTypeOptions('others', queryParams),
      action.payload,
    );
    const { data } = response;
    data.pagination = { totalItems: data.count };
    yield put(setOtherServiceTypeOptions(data));
  } catch (e) {
    put(setError(true));
  }
}

function* getBidRateSheetDetails(action) {
  try {
    const response = yield call(
      trackBidRateDetails,
      f => service.retrieveBidRateSheetDetails(action.payload),
      action.payload,
    );
    const { data } = response;

    yield put(setBidRateSheetDetails(data));
  } catch (e) {
    put(setError(true));
  }
}

function* addLaborRatesData(action) {
  try {
    const response = yield call(
      f => service.postLaborRatesData(action.payload),
      action.payload,
    );
    if (action.payload.callback) {
      action.payload.callback();
    }
    if (response.status === 200) {
      displaySuccess('Bid Rate Sheet Successfully Saved');
    } else {
      displayError('Could not Save Bidrate sheet.');
    }
    yield put(setBidRateNumber(response.data[0].bidrateNmbr));
    yield put(setLaborRatesPreviewData(response.data));
  } catch (e) {
    put(setError(true));
  }
}

function* saveBidRateSheetDetails(action) {
  try {
    const response = yield call(
      f => service.saveBidRateSheetData(action.payload),
      action.payload,
    );
    if (response.status === 200) {
      displaySuccess('Bid Rate Sheet Successfully Updated');
    } else {
      displayError('Could not Update Bidrate sheet.');
    }
    // yield put(setBidRateSheetDetailsData(response));
    yield put(setBidRateSheetSuccess({ isSuccess: true }));
  } catch (e) {
    put(setError(true));
  }
}

function* getSelectedBidRateSheetList(action) {
  try {
    const columnName = action.payload;
    const response = yield call(
      f => service.retreiveSelectedBidRateSheetList(action.payload),
      action.payload,
    );
    const { data } = response;
    yield put(setSelectedBidRateSheetRecords(data));
  } catch (e) {
    put(setError(true));
  }
}

function* getServiceTypeOptions(action) {
  try {
    const queryParams = _.cloneDeep(
      yield select(bidRateSheetPaginationSelector),
    );
    action.payload.heirarchypath = queryParams.heirarchypath;
    const response = yield call(
      f => service.retreiveServiceTypes(action.payload, 'Other'),
      action.payload,
    );
    const { data } = response;
    yield put(setSelectedServiceTypeRecords(data));
  } catch (e) {
    put(setError(true));
  }
}

function* getServiceTypeOptionsLabor(action) {
  try {
    const columnName = action.payload;
    const response = yield call(
      f => service.retreiveServiceTypes(action.payload, 'Labor'),
      action.payload,
    );
    const { data } = response;
    yield put(setSelectedServiceTypeRecords(data));
  } catch (e) {
    put(setError(true));
  }
}

function* getNewFilters(action) {
  yield put(resetFilters());
  if (action.payload.callback) {
    action.payload.callback();
  }
}

function* getServiceTypeOptionsEqp(action) {
  try {
    const columnName = action.payload;
    const response = yield call(
      f => service.retreiveServiceTypes(action.payload, 'Equipment'),
      action.payload,
    );
    const { data } = response;
    yield put(setSelectedServiceTypeRecords(data));
  } catch (e) {
    put(setError(true));
  }
}

function* watchAddLaborRatesData() {
  yield takeLatest(saveLaborRatesData.type, addLaborRatesData);
}

function* watchSaveBidRateSheetDetails() {
  yield takeLatest(saveBidRateSheetDetailsData.type, saveBidRateSheetDetails);
}

function* watchFetchBidRateSheet() {
  yield takeLatest(fetchBidRateSheetList.type, getBidRateSheetList);
}

function* watchFetchBidRateWorkCategory() {
  yield takeLatest(fetchBidRateWorkCategory.type, getBidRateSheetWorkCategory);
}

function* watchFetchBidRateLocations() {
  yield takeLatest(fetchBidRateLocations.type, getBidRateSheetLocations);
}

function* watchFetchBidRateEquipmentTypes() {
  yield takeLatest(fetchBidRateEquipmentTypes.type, getBidRateEquipmentTypes);
}

function* watchFetchBidRateOwnershipTypes() {
  yield takeLatest(fetchBidRateOwnershipTypes.type, getBidRateOwnershipTypes);
}

function* watchFetchBidRateSheetDetails() {
  yield takeLatest(fetchBidRateSheetDetails.type, getBidRateSheetDetails);
}

function* watchFetchLaborServiceTypeOptions() {
  yield takeLatest(
    fetchLaborServiceTypeOptions.type,
    getLaborServiceTypeOptions,
  );
}

function* watchFetchEquipmentServiceTypeOptions() {
  yield takeLatest(
    fetchEquipmentServiceTypeOptions.type,
    getEquipmentServiceTypeOptions,
  );
}

function* watchFetchOtherServiceTypeOptions() {
  yield takeLatest(
    fetchOtherServiceTypeOptions.type,
    getOtherServiceTypeOptions,
  );
}

function* watchFetchSelectedBidRateSheetList() {
  yield takeLatest(
    fetchSelectedBidRateSheetList.type,
    getSelectedBidRateSheetList,
  );
}

function* watchFetchServiceTypeOptions() {
  yield takeLatest(fetchServiceTypeOptions.type, getServiceTypeOptions);
}

function* watchFetchServiceTypeOptionsLabor() {
  yield takeLatest(
    fetchServiceTypeOptionsLabor.type,
    getServiceTypeOptionsLabor,
  );
}

function* watchFetchServiceTypeOptionsEqp() {
  yield takeLatest(fetchServiceTypeOptionsEqp.type, getServiceTypeOptionsEqp);
}

function* watchFetchNewFiltersData() {
  yield takeLatest(fetchNewFiltersData.type, getNewFilters);
}

export function* bidRateSheetSaga() {
  yield all([
    watchFetchBidRateSheet(),
    watchAddLaborRatesData(),
    watchFetchBidRateSheetDetails(),
    watchSaveBidRateSheetDetails(),
    watchFetchBidRateWorkCategory(),
    watchFetchBidRateLocations(),
    watchFetchLaborServiceTypeOptions(),
    watchFetchEquipmentServiceTypeOptions(),
    watchFetchOtherServiceTypeOptions(),
    watchFetchBidRateEquipmentTypes(),
    watchFetchBidRateOwnershipTypes(),
    watchFetchSelectedBidRateSheetList(),
    watchFetchServiceTypeOptions(),
    watchFetchServiceTypeOptionsLabor(),
    watchFetchServiceTypeOptionsEqp(),
    watchFetchNewFiltersData(),
  ]);
}
