import React from 'react';

export const CaretRight = ({ width = '9', height = '16', style = {} }) => {
  return (
    <svg width={width} height={height} viewBox={`0 0 ${width} ${height}`}>
      <path
        data-name="expand_more_FILL0_wght400_GRAD0_opsz48(1)"
        d="M7.877 0a1.021 1.021 0 0 0-.383.07 1.06 1.06 0 0 0-.348.243L.262 7.2A.957.957 0 0 0 0 7.946a1.07 1.07 0 0 0 .3.748.873.873 0 0 0 .748.3 1.307 1.307 0 0 0 .713-.33l6.12-6.12L14 8.659a1.135 1.135 0 0 0 .748.313.823.823 0 0 0 .748-.313.766.766 0 0 0 .3-.73 1.354 1.354 0 0 0-.33-.765L8.608.313A1.06 1.06 0 0 0 8.26.07 1.021 1.021 0 0 0 7.877 0z"
        transform="rotate(90 4.498 4.498)"
        style={{ fill: '#4a4a4a', ...style }}
      />
    </svg>
  );
};
