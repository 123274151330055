import { all, call, put, select, takeLatest } from 'redux-saga/effects';
import * as service from 'services/auditModuleService';
import {
  fetchAuditList,
  fetchAuditDetail,
  fetchContractors,
  fetchAuditors,
  fetchCircuits,
  setAuditList,
  setError,
  setContractors,
  setAuditors,
  setCircuits,
  postAudit,
  setAuditData,
  setSavedAuditData,
  updateAudit,
} from 'store/slices/AuditModules/auditSlice';
import { displayError } from 'components/common/Alert/ToastAlert';
import _ from 'lodash';

const auditPaginationSelector = state => state.audit.pagination;

export function* getAuditList(action) {
  const queryParams = yield select(auditPaginationSelector);
  try {
    const response = yield call(
      f => service.retrieveAuditList(queryParams),
      action.payload,
    );
    const { data } = response;
    data.pagination = { totalItems: data.count };
    yield put(setAuditList(data));
  } catch (e) {
    put(setError(true));
    displayError(
      'We are experiencing technical difficulties. Please come back shortly to try again !',
    );
  }
}

function* getAuditDetail(action) {
  try {
    const response = yield call(
      f => service.retrieveAuditDetails(action.payload),
      action.payload,
    );
    const { data } = response;
    yield put(setAuditData(data));
  } catch (e) {
    put(setError(true));
  }
}

function* getContractors(action) {
  try {
    const response = yield call(
      f => service.retrieveContractors(),
      action.payload,
    );
    yield put(setContractors(response.data));
  } catch (err) {
    return err;
  }
}

function* getAuditors(action) {
  try {
    const response = yield call(
      f => service.retrieveAuditors(),
      action.payload,
    );
    yield put(setAuditors(response.data));
  } catch (err) {
    return err;
  }
}

function* getCircuits(action) {
  try {
    const response = yield call(
      f => service.retrieveCircuits(action.payload),
      action.payload,
    );
    const { data } = response.data.rows;
    yield put(setCircuits(response.data.rows));
    if (action.payload.callback) {
      action.payload.callback(data);
    }
  } catch (err) {
    return err;
  }
}

function* submitAudit(action) {
  try {
    const response = yield call(
      f => service.postAuditForm(action.payload.data),
      action.payload,
    );
    const { data } = response;
    yield put(setSavedAuditData(response.data));
    if (action.payload.callback) {
      action.payload.callback(data);
    }
  } catch (e) {
    put(setError(true));
  }
}

function* updateAuditForm(action) {
  try {
    const response = yield call(
      f => service.updateAuditForm(action.payload.data),
      action.payload,
    );
    const { data } = response;
    yield put(setSavedAuditData(response.data));
    if (action.payload.callback) {
      action.payload.callback(data);
    }
  } catch (e) {
    put(setError(true));
  }
}

function* watchUpdateAuditForm() {
  yield takeLatest(updateAudit.type, updateAuditForm);
}

function* watchAuditForm() {
  yield takeLatest(postAudit.type, submitAudit);
}

function* watchAuditList() {
  yield takeLatest(fetchAuditList.type, getAuditList);
}

function* watchFetchAuditDetail() {
  yield takeLatest(fetchAuditDetail.type, getAuditDetail);
}

function* watchFetchContractors() {
  yield takeLatest(fetchContractors.type, getContractors);
}

function* watchFetchAuditors() {
  yield takeLatest(fetchAuditors.type, getAuditors);
}

function* watchFetchCircuits() {
  yield takeLatest(fetchCircuits.type, getCircuits);
}

export function* auditSaga() {
  yield all([
    watchAuditList(),
    watchFetchAuditDetail(),
    watchFetchContractors(),
    watchFetchAuditors(),
    watchFetchCircuits(),
    watchAuditForm(),
    watchUpdateAuditForm(),
  ]);
}
