import React from 'react';

export const AdditionalLinkIcon = () => {
  return (
    <svg
      xmlns="http://www.w3.org/2000/svg"
      width="20"
      height="10"
      viewBox="0 0 20 10"
    >
      <g data-name="Group 12759">
        <g data-name="Group 12758">
          <path
            data-name="Path 25921"
            d="M17 7h-3a1 1 0 0 0 0 2h3a3 3 0 0 1 0 6h-3a1 1 0 0 0 0 2h3a5 5 0 0 0 0-10zm-9 5a1 1 0 0 0 1 1h6a1 1 0 0 0 0-2H9a1 1 0 0 0-1 1zm2 3H7a3 3 0 0 1 0-6h3a1 1 0 0 0 0-2H7a5 5 0 0 0 0 10h3a1 1 0 0 0 0-2z"
            style={{ fill: '#4a4a4a' }}
            transform="translate(-2 -7)"
          />
        </g>
      </g>
    </svg>
  );
};
