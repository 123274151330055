import { createSlice } from '@reduxjs/toolkit';
import { keys } from 'lodash';
import { InvoiceStatusDisplayValue } from 'constants/statusValues';
const initialState = {
  isLoading: false,
  error: false,
  errorResponse: {},
  pagination: {
    totalItems: 0,
    currentPage: 1,
    itemsPerPage: 10,
    sortBy: '',
    sortOrder: 'asc',
    ponum: '',
    siteid: '',
    vendor: '',
    invoiceDate: '',
    weekEndDate: '',
    workAreaId: '',
    caastatus: '',
    bupath: '',
    weekStartDate: '',
    maxInvNmbr: '',
    vendorInvNmbr: '',
    contractNmbr: '',
    authNmbr: '',
    analyticsTab: '',
  },
  inputList: [
    {
      FilterValue: '',
      input_rank: null,
      selectFilterValue: '',
      selectFilterLabel: '',
      presentIcon: '',
      filerForm: false,
      FilterLabel: '',
      invoiceRecords: [],
    },
  ],
  items: [],
  recordItems: [],
};

const invoiceSlice = createSlice({
  name: 'invoiceSlice',
  initialState,
  reducers: {
    fetchInvoices(state, action) {
      const stateValues = { ...state };
      stateValues.isLoading = true;
      stateValues.pagination = { ...stateValues.pagination, ...action.payload };
      stateValues.items = [];
      return { ...stateValues };
    },

    fetchInvoicesAnalytics(state, action) {
      const stateValues = { ...state };
      stateValues.isLoading = true;
      stateValues.pagination = { ...stateValues.pagination, ...action.payload };
      stateValues.items = [];
      return { ...stateValues };
    },

    setAnalyticsFilters(state, action) {
      const stateValues = { ...state };
      stateValues.isLoading = true;
      stateValues.pagination = {
        ...stateValues.pagination,
        ...action.payload,
      };
      stateValues.items = [];
      return { ...stateValues };
    },

    fetchSelectedInvoiceList(state) {
      state.isLoading = false;
    },

    createInvoice(state, action) {
      return { ...state };
    },

    setInvoicePagination(state, action) {
      const stateValues = { ...state };
      stateValues.pagination = { ...stateValues.pagination, ...action.payload };
      return { ...stateValues };
    },
    setInputFilterData(state, action) {
      if (action.payload.length != 0) {
        state.inputList = action.payload;
      } else {
        const stateValues = { ...state };
        stateValues.inputList = [...initialState.inputList];
        return { ...stateValues };
      }
    },

    setAddNewInputFilterData(state, action) {
      const stateValues = { ...state };
      stateValues.inputList = [...stateValues.inputList, action.payload];
      return { ...stateValues };
    },

    setInvoices(state, action) {
      const invoiceList = [];
      action.payload?.rows.forEach(obj => {
        invoiceList.push({
          vendorInvoiceNumber: obj.vendorInvNmbr,
          invoiceNumber: obj.invoiceNmbr,
          contractNumber: obj.contractNmbr,
          poNum: obj['po.ponum'],
          poid: obj.poid,
          vendor: obj['po.vendorVendor.vendor_name'],
          startDate: obj.weekStartDate,
          endDate: obj.weekEndDate,
          status: obj.caastatus,
        });
      });
      const stateValues = { ...state };
      stateValues.items = [...invoiceList];
      stateValues.pagination = {
        ...stateValues.pagination,
        ...action.payload?.pagination,
      };

      return { ...stateValues };
    },
    setAnalytics(state, action) {
      const analyticsList = [];
      let activitySum = 0;
      let laborSum = 0;
      let equipmentSum = 0;
      let laborHrsSum = 0;
      let equipHrsSum = 0;
      action.payload?.rows.forEach(obj => {
        analyticsList.push(obj);
        if (obj?.activityAmount)
          activitySum = activitySum + obj?.activityAmount;
        if (obj?.laborAmount) laborSum = laborSum + obj?.laborAmount;
        if (obj?.equipAmount) equipmentSum = equipmentSum + obj?.equipAmount;
        if (obj?.equipHours) equipHrsSum = equipHrsSum + obj?.equipHours;
        if (obj?.laborHours) laborHrsSum = laborHrsSum + obj?.laborHours;
      });
      const stateValues = { ...state };
      analyticsList.push({
        activityAmountSum: activitySum,
        laborAmountSum: laborSum,
        equipmentsAmountSum: equipmentSum,
        equipmentHoursSum: equipHrsSum,
        laborHoursSum: laborHrsSum,
      });
      stateValues.items = [...analyticsList];

      stateValues.pagination = {
        ...stateValues.pagination,
        ...action.payload?.pagination,
      };

      return { ...stateValues };
    },
    setSelectedInvoiceRecords(state, action) {
      const selectedInvoiceRecords = [];
      if (action.payload?.columnname === 'vendor') {
        action.payload?.data?.forEach(obj => {
          selectedInvoiceRecords.push({
            label: obj.vendor,
            value: obj.vendor,
          });
        });
      } else if (action.payload?.columnname === 'vendorName') {
        action.payload?.data?.forEach(obj => {
          selectedInvoiceRecords.push({
            label: obj.vendorName,
            value: obj.vendorName,
          });
        });
      } else if (action.payload?.columnname === 'caastatus') {
        action.payload?.data?.forEach(obj => {
          let objValues = Object.values(obj);
          selectedInvoiceRecords.push({
            label: InvoiceStatusDisplayValue[objValues[0]],
            value: InvoiceStatusDisplayValue[objValues[0]],
          });
        });
      } else {
        action.payload?.data?.forEach(obj => {
          let objValues = Object.values(obj);
          selectedInvoiceRecords.push({
            label: objValues[0],
            value: objValues[0],
          });
        });
      }

      const stateValues = { ...state };
      stateValues.recordItems = [...selectedInvoiceRecords];
      return { ...stateValues };
    },

    resetInvoices() {
      return { ...initialState };
    },

    unsetError(state) {
      const stateValues = { ...state };
      stateValues.error = false;
      stateValues.errorResponse = {};
      return { ...stateValues };
    },

    setError(state, action) {
      const stateValues = { ...state };
      stateValues.error = true;
      stateValues.errorResponse = action.payload;
      return { ...stateValues };
    },

    setIsLoading(state, action) {
      const stateValues = { ...state };
      stateValues.isLoading = action.payload;
      return { ...stateValues };
    },

    invoiceReducer(state = initialState, action) {
      switch (action.type) {
        case 'FETCH_INVOICES_REQUEST':
          return { ...state, isLoading: true, error: null };
        case 'FETCH_INVOICES_SUCCESS':
          return { ...state, isLoading: false, items: action.payload };
        case 'FETCH_INVOICES_FAILURE':
          return { ...state, isLoading: false, error: action.payload };
        case 'EXPORT_TIMESHEET_REQUEST':
          return { ...state, isDownloading: true, downloadError: null };
        case 'EXPORT_TIMESHEET_SUCCESS':
          return { ...state, isDownloading: false };
        case 'EXPORT_TIMESHEET_FAILURE':
          return {
            ...state,
            isDownloading: false,
            downloadError: action.payload,
          };
        default:
          return state;
      }
    },
  },
});

export const {
  fetchInvoices,
  fetchInvoicesAnalytics,
  fetchSelectedInvoiceList,
  setInvoicePagination,
  setInvoices,
  setAnalytics,
  setInputFilterData,
  setSelectedInvoiceRecords,
  setAddNewInputFilterData,
  resetInvoices,
  createInvoice,
  setError,
  unsetError,
  setIsLoading,
  setAnalyticsFilters,
} = invoiceSlice.actions;

export const exportInvoicesRequest = () => ({
  type: 'EXPORT_INVOICES_REQUEST',
  meta: { export: true },
});

export const exportInvoicesSuccess = () => ({
  type: 'EXPORT_INVOICES_SUCCESS',
});

export const exportInvoicesFailure = error => ({
  type: 'EXPORT_INVOICES_FAILURE',
  payload: error,
});
export default invoiceSlice.reducer;
