// Imports
import ___CSS_LOADER_API_SOURCEMAP_IMPORT___ from "../../../../node_modules/react-scripts/node_modules/css-loader/dist/runtime/sourceMaps.js";
import ___CSS_LOADER_API_IMPORT___ from "../../../../node_modules/react-scripts/node_modules/css-loader/dist/runtime/api.js";
var ___CSS_LOADER_EXPORT___ = ___CSS_LOADER_API_IMPORT___(___CSS_LOADER_API_SOURCEMAP_IMPORT___);
// Module
___CSS_LOADER_EXPORT___.push([module.id, `.filterDelete {
  margin: 3px;
}
@media all and (max-width: 575px) {
  .filterDelete {
    display: flex;
    flex-direction: row-reverse;
  }
}
.filterDelete svg {
  width: 25px;
  height: 25px;
  padding: 0px;
  border-radius: 4px;
  float: right;
  margin: 10px;
}
.filterDelete path {
  fill: #4a4a4a !important;
}

.AEPFilterContainer {
  background-color: #f5f7f9;
  padding: 24px;
  margin: 10px;
  border-radius: 4px;
}
.AEPFilterContainer .card {
  margin: 10px;
  box-shadow: 0 3px 6px 0 rgba(0, 0, 0, 0.1);
  font-size: 18px;
  padding: 10px;
}
@media all and (max-width: 575px) {
  .AEPFilterContainer .card {
    width: 100%;
  }
}
.AEPFilterContainer .card .cardHeader {
  min-height: 1px;
  padding: 0.5rem !important;
}
.AEPFilterContainer .card .cardHeader div {
  padding: 5px;
}
.AEPFilterContainer .card img {
  width: 40px;
  height: 40px;
}
.AEPFilterContainer form select {
  border-radius: 4px;
  border: solid 1px #4a4a4a;
  width: -moz-fit-content;
  width: fit-content;
  height: 50px;
  left: 0;
  margin-left: 45px;
}
@media all and (max-width: 575px) {
  .AEPFilterContainer form select {
    margin-left: 5px;
  }
}`, "",{"version":3,"sources":["webpack://./src/components/common/Filter/AEPFilterModal.scss"],"names":[],"mappings":"AAAA;EAME,WAAA;AAJF;AADE;EADF;IAEI,aAAA;IACA,2BAAA;EAIF;AACF;AADE;EACE,WAAA;EACA,YAAA;EACA,YAAA;EACA,kBAAA;EACA,YAAA;EACA,YAAA;AAGJ;AADE;EACE,wBAAA;AAGJ;;AACA;EACE,yBAAA;EACA,aAAA;EAEA,YAAA;EACA,kBAAA;AACF;AACE;EAIE,YAAA;EACA,0CAAA;EACA,eAAA;EACA,aAAA;AAFJ;AAJI;EADF;IAEI,WAAA;EAOJ;AACF;AAFI;EACE,eAAA;EACA,0BAAA;AAIN;AAHM;EACE,YAAA;AAKR;AAFI;EACE,WAAA;EACA,YAAA;AAIN;AADE;EAIE,kBAAA;EACA,yBAAA;EACA,uBAAA;EAAA,kBAAA;EACA,YAAA;EACA,OAAA;EACA,iBAAA;AAAJ;AARI;EADF;IAEI,gBAAA;EAWJ;AACF","sourcesContent":[".filterDelete {\n  @media all and (max-width: '575px') {\n    display: flex;\n    flex-direction: row-reverse;\n  }\n\n  margin: 3px;\n  svg {\n    width: 25px;\n    height: 25px;\n    padding: 0px;\n    border-radius: 4px;\n    float: right;\n    margin: 10px;\n  }\n  path {\n    fill: #4a4a4a !important;\n  }\n}\n\n.AEPFilterContainer {\n  background-color: #f5f7f9;\n  padding: 24px;\n  // margin: 24px 24px 0;\n  margin: 10px;\n  border-radius: 4px;\n\n  .card {\n    @media all and (max-width: '575px') {\n      width: 100%;\n    }\n    margin: 10px;\n    box-shadow: 0 3px 6px 0 rgba(0, 0, 0, 0.1);\n    font-size: 18px;\n    padding: 10px;\n    .cardHeader {\n      min-height: 1px;\n      padding: 0.5rem !important;\n      div {\n        padding: 5px;\n      }\n    }\n    img {\n      width: 40px;\n      height: 40px;\n    }\n  }\n  form select {\n    @media all and (max-width: '575px') {\n      margin-left: 5px;\n    }\n    border-radius: 4px;\n    border: solid 1px #4a4a4a;\n    width: fit-content;\n    height: 50px;\n    left: 0;\n    margin-left: 45px;\n  }\n}\n"],"sourceRoot":""}]);
// Exports
___CSS_LOADER_EXPORT___.locals = {};
export default ___CSS_LOADER_EXPORT___;
