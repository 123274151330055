import React from 'react';

export const PurchaseOrderIcon = () => {
  return (
    <svg data-name="PO Default" width="18" height="18" viewBox="0 0 18 18">
      <g data-name="Group 12488">
        <path
          data-name="Path 25889"
          d="M19 5v14H5V5h14m0-2H5a2.006 2.006 0 0 0-2 2v14a2.006 2.006 0 0 0 2 2h14a2.006 2.006 0 0 0 2-2V5a2.006 2.006 0 0 0-2-2z"
          transform="translate(-3 -3)"
          style={{ fill: '#4a4a4a' }}
        />
      </g>
      <path
        data-name="Path 25890"
        d="M13.992 16.989H7v-2h6.992zm3-4H7V11h9.989zm0-4H7V7h9.989z"
        transform="translate(-2.995 -2.995)"
        style={{ fill: '#4a4a4a' }}
      />
    </svg>
  );
};
