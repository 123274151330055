import { UnitPriceRatesFields } from 'enums/fields.ts';

const downloadExcelServiceTypeValue = 'unitPrice';
const serviceType = 'bidrateUnitPrices';

const serviceTypeName = 'Unit Price Rates';
const downloadExcelServiceTypeName = serviceTypeName;

const tabs = [{ key: 'unitPriceRates', value: 'Unit Price Rates' }];

const options = [
  { value: 'Effective End Date', key: 'effEndDate' },
  { value: 'Estimated Quantity(#)', key: 'estQnt' },
  { value: 'Total Quantity(#)', key: 'ttlQnt' },
  { value: 'Equipment & Labor Unit Price($)', key: 'equipmentLaborUnitprice' },
  { value: 'Labor Unit Price($)', key: 'laborUnitprice' },
  { value: 'Equipment Unit Price($)', key: 'equipmentUnitprice' },
  { value: 'Material Unit Price($)', key: 'materialUnitprice' },
  { value: 'Total Line Price($)', key: 'totalLinePrice' },
];

const columnsArray = [
  {
    key: UnitPriceRatesFields.unitCode,
    value: 'Unit Code',
    required: true,
    showHeader: true,
  },
  {
    key: UnitPriceRatesFields.unitDescription,
    value: 'Unit Description',
    required: true,
    showHeader: true,
  },
  {
    key: UnitPriceRatesFields.unitOfMeasure,
    value: 'Unit of Measure',
    required: true,
    showHeader: true,
  },
  {
    key: UnitPriceRatesFields.workCategory,
    value: 'Work Category',
    required: true,
    showHeader: true,
  },
  {
    key: UnitPriceRatesFields.location,
    value: 'Location',
    required: true,
    showHeader: true,
  },
  {
    key: UnitPriceRatesFields.effStartDate,
    value: 'Effective Start Date',
    required: true,
    showHeader: true,
  },
  {
    value: 'Effective End Date',
    key: 'effEndDate',
    required: false,
    showHeader: false,
  },
  {
    value: 'Estimated Quantity(#)',
    key: 'estQnt',
    required: false,
    showHeader: false,
  },
  {
    value: 'Total Quantity(#)',
    key: 'ttlQnt',
    required: false,
    showHeader: false,
  },
  {
    value: 'Equipment & Labor Unit Price($)',
    key: 'equipmentLaborUnitprice',
    required: false,
    showHeader: false,
  },
  {
    value: 'Labor Unit Price($)',
    key: 'laborUnitprice',
    required: false,
    showHeader: false,
  },
  {
    value: 'Equipment Unit Price($)',
    key: 'equipmentUnitprice',
    required: false,
    showHeader: false,
  },
  {
    value: 'Material Unit Price($)',
    key: 'materialUnitprice',
    required: false,
    showHeader: false,
  },
  {
    key: UnitPriceRatesFields.totalUnitprice,
    value: 'Total Unit Price($)',
    required: false,
    showHeader: false,
  },
  {
    value: 'Total Line Price($)',
    key: 'totalLinePrice',
    required: false,
    showHeader: false,
  },
];

let autoFillColumnskeys = {
  code: 'unitCode',
  description: 'unitDescription',
  unitOfMeasure: 'unitOfMeasure',
};

let referenceObject = {
  unitCode: null,
  unitDescription: null,
  unitOfMeasure: null,
  location: null,
  effStartDate: null,
  estQnt: null,
  ttlQnt: null,
  equipmentLaborUnitprice: null,
  laborUnitprice: null,
  equipmentUnitprice: null,
  materialUnitprice: null,
  totalLinePrice: null,
  effEndDate: null,
  bidrateUnitNmbr: null,
  bidrateNmbr: null,
  updatedDate: null,
  updatedBy: null,
  enteredBy: null,
  enteredDate: null,
  workCategory: null,
  totalUnitprice: null,
};

const header = {};
columnsArray.map(item => {
  header[item.key] = item.value;
});
options.map(item => {
  header[item.key] = item.value;
});

const unitPriceExcelHeaderRefObj = [header];
// const unitPriceExcelHeaderRefObj = [
//   {
//     unitCode: 'Unit Code',
//     unitDescription: 'Unit Description',
//     unitOfMeasure: 'Unit Of Measure',
//     location: 'Location',
//     effStartDate: 'Eff Start Date',
//     estQnt: 'Est Qnt',
//     ttlQnt: 'Ttl Qnt',
//     equipmentLaborUnitprice: 'Equipment Labor Unitprice',
//     laborUnitprice: 'Labor Unitprice',
//     equipmentUnitprice: 'Equipment Unitprice',
//     materialUnitprice: 'Material Unitprice',
//     totalUnitprice: 'Total Unitprice',
//     effEndDate: 'Eff End Date',
//     bidrateUnitNmbr: 'Bidrate Unit Nmbr',
//     bidrateNmbr: 'Bidrate Nmbr',
//     updatedDate: 'Updated Date',
//     updatedBy: 'Updated By',
//     enteredBy: 'Entered By',
//     enteredDate: 'Entered Date',
//     totalLinePrice: 'Total Line Price',
//     workCategory: 'Work Category',
//     comments: 'Comments',
//   },
// ];

export {
  tabs,
  columnsArray,
  options,
  referenceObject,
  serviceType,
  serviceTypeName,
  downloadExcelServiceTypeValue,
  unitPriceExcelHeaderRefObj,
  downloadExcelServiceTypeName,
  autoFillColumnskeys,
};
