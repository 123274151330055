import { InvoiceDisplayValue } from './statusValues';

export const keyInvoiceTab = {
  ALL: 'all',
  SUBMITTED: 'submitted',
  PENDING: 'pending',
  INVOICED: 'invoiced',
  RETURNED: 'returned',
  FAILED: 'failed',
};

export const getInvoiceStatusByKey = keyName => {
  let status = [];
  if (keyName.toLowerCase() !== 'all') {
    for (let keyOfstatus in InvoiceDisplayValue) {
      if (
        keyOfstatus &&
        InvoiceDisplayValue[keyOfstatus].toLowerCase() === keyName
      ) {
        if (keyOfstatus) {
          status.push(keyOfstatus);
        }
      }
    }
  } else {
    for (let keyOfstatus in InvoiceDisplayValue) {
      if (keyOfstatus) {
        status.push(keyOfstatus);
      }
    }
  }
  return status;
};

export const optionsInvoiceListTab = [
  {
    key: keyInvoiceTab.ALL,
    value: 'All',
    permission: 'can_view_all_invoice',
    status: getInvoiceStatusByKey(keyInvoiceTab.ALL),
  },
  {
    key: 'saved',
    value: 'Saved',
    permission: 'can_view_saved_invoice',
    status: getInvoiceStatusByKey('saved'),
  },
  {
    key: keyInvoiceTab.SUBMITTED,
    value: 'Submitted',
    permission: 'can_view_submitted_invoice',
    status: getInvoiceStatusByKey(keyInvoiceTab.SUBMITTED),
  },
  {
    key: keyInvoiceTab.PENDING,
    value: 'Pending',
    permission: 'can_view_pend_invoice',
    status: getInvoiceStatusByKey(keyInvoiceTab.PENDING),
  },
  {
    key: keyInvoiceTab.INVOICED,
    value: 'Invoiced',
    permission: 'can_view_invoiced_invoice',
    status: getInvoiceStatusByKey(keyInvoiceTab.INVOICED),
  },
  {
    key: keyInvoiceTab.RETURNED,
    value: 'Returned',
    permission: 'can_view_returned_invoice',
    status: getInvoiceStatusByKey(keyInvoiceTab.RETURNED),
  },
  {
    key: keyInvoiceTab.FAILED,
    value: 'Failed',
    permission: 'can_view_failed_invoice',
    status: getInvoiceStatusByKey(keyInvoiceTab.FAILED),
  },
];
