import {
  Form,
  Modal,
  Tab,
  Table,
  Tabs,
  Row,
  Col,
  FormGroup,
} from 'react-bootstrap';
import { ButtonArea } from 'components/common//Button/ButtonArea/ButtonArea';
import { Divider } from 'components/common/Divider/Divider';
import { AddIcon } from 'assets/images/AddIcon/AddIcon';
import { DeleteIcon } from 'assets/images/DeleteIcon/DeleteIcon';
import { useState } from 'react';
import { useSelector } from 'react-redux';
import _ from 'lodash';

const AdminWorkTypeModel = ({
  show,
  setShowModal,
  handleAdminWorkTypeSave,
  handleAdminWorkTypeCancel,
  formValues,
  setFormValues,
  onChangeHandler,
  workTypeDetailsData,
  inputs,
  reliabilityWork,
  status,
  kindOfSelection,
  isChecked,
  submitHandler,
  handleChange,
  handleAuthorization,
}) => {
  const tabs = [{ key: 'details', value: 'Details' }];
  const [key, setKey] = useState('details');
  const opstateList = useSelector(state => state.adminWorkType.updateFieldList);

  let handleWorkTypeChange = (i, e) => {
    let newFormValues = _.cloneDeep(formValues);
    newFormValues[i][e.target.name] = e.target.value;
    setFormValues(newFormValues);
  };

  let addFormFields = () => {
    setFormValues([...formValues, { opco: '', projectId: '', workOrder: '' }]);
  };

  let removeFormFields = i => {
    let newFormValues = [...formValues];
    newFormValues.splice(i, 1);
    setFormValues(newFormValues);
  };

  return (
    <Modal
      show={show}
      onHide={() => setShowModal(false)}
      size="lg"
      aria-labelledby="contained-modal-title-vcenter"
      centered
    >
      <Modal.Header closeButton="true">
        <Modal.Title>Edit Work Type</Modal.Title>
      </Modal.Header>
      <Divider />
      <div className="adminModuleWorkTypeDetailContainer">
        <Tabs id="poPage" activeKey={key} onSelect={k => setKey(k)}>
          {tabs.map((tab, index) => (
            <Tab eventKey={tab.key} title={tab.value} key={index}>
              <div className="poDetailsContainer">
                <Form onSubmit={submitHandler}>
                  <Row>
                    <Col sm={3}>
                      <Form.Group controlId="form.workTypeLists">
                        <Form.Label>Work Type Lists:</Form.Label>
                        <Form.Select
                          name="worktype"
                          onChange={onChangeHandler}
                          value={inputs.worktype}
                          style={{ width: '100%' }}
                        >
                          {opstateList?.WT?.map((type, index) => (
                            <option key={index} value={type.worktype}>
                              {type.worktype}
                            </option>
                          ))}
                        </Form.Select>
                      </Form.Group>
                    </Col>
                  </Row>
                  <br />
                  <Row>
                    <Col sm={3}>
                      <Form.Group controlId="form.workDesc">
                        <Form.Label>Work Description:</Form.Label>
                        <Form.Control
                          defaultValue={workTypeDetailsData.worktypeDesc}
                          onChange={onChangeHandler}
                          name="worktypeDesc"
                          value={inputs.worktypeDesc}
                          className="workListLabel"
                        />
                      </Form.Group>
                    </Col>
                    <Col sm={3}>
                      <Form.Group controlId="form.workDesc">
                        <Form.Label>Account Number:</Form.Label>
                        <Form.Control
                          defaultValue={workTypeDetailsData.accountNmbr}
                          onChange={onChangeHandler}
                          name="accountNmbr"
                          value={inputs.accountNmbr}
                          className="workListLabel"
                        />
                      </Form.Group>
                    </Col>
                    <Col sm={3}>
                      <Form.Group controlId="form.workDesc">
                        <Form.Label>ABMS Activity:</Form.Label>
                        <Form.Control
                          defaultValue={workTypeDetailsData.abmsActivity}
                          onChange={onChangeHandler}
                          name="abmsActivity"
                          value={inputs.abmsActivity}
                          className="workListLabel"
                        />
                      </Form.Group>
                    </Col>
                    <Col sm={3}>
                      <Form.Group controlId="form.workcategory">
                        <Form.Label>Worktype Category:</Form.Label>
                        <Form.Select
                          aria-label="workType Category"
                          value={inputs.worktypeCategory}
                          onChange={onChangeHandler}
                          name="worktypeCategory"
                          className="workListLabel"
                        >
                          <option value="">-Select-</option>
                          {opstateList?.CT?.map((type, index) => (
                            <option key={index} value={type.worktype_category}>
                              {type.worktype_category}
                            </option>
                          ))}
                        </Form.Select>
                      </Form.Group>
                    </Col>
                    <Col sm={3}>
                      <Form.Group controlId="form.orgGroup">
                        <Form.Label>Org Group:</Form.Label>
                        <Form.Select
                          aria-label="Org Group"
                          value={inputs.orgDeptGroup}
                          onChange={onChangeHandler}
                          name="orgDeptGroup"
                          className="workListLabel"
                        >
                          <option value="">-Select-</option>
                          {opstateList?.worktype_org_group?.map(
                            (type, index) => (
                              <option key={index} value={type.label}>
                                {type.label}
                              </option>
                            ),
                          )}
                        </Form.Select>
                      </Form.Group>
                    </Col>
                    <Col sm={3}>
                      <Form.Group controlId="form.status">
                        <Form.Label>Status:</Form.Label>
                        <Form.Select
                          aria-label="Status"
                          value={inputs.status}
                          onChange={onChangeHandler}
                          name="status"
                          className="workListLabel"
                        >
                          <option value="">-Select-</option>
                          {status.map((type, index) => (
                            <option key={index} value={type.value}>
                              {type.label}
                            </option>
                          ))}
                        </Form.Select>
                      </Form.Group>
                    </Col>
                    <Col sm={3}>
                      <Form.Group controlId="form.planningCategory">
                        <Form.Label>Planning Category:</Form.Label>
                        <Form.Select
                          aria-label="Planning Category"
                          value={inputs.planningCategory}
                          onChange={onChangeHandler}
                          name="planningCategory"
                          className="workListLabel"
                        >
                          <option value="">-Select-</option>
                          {opstateList?.worktype_planning_category?.map(
                            (type, index) => (
                              <option key={index} value={type.label}>
                                {type.label}
                              </option>
                            ),
                          )}
                        </Form.Select>
                      </Form.Group>
                    </Col>
                    <Col sm={3}>
                      <Form.Group controlId="form.costComponent">
                        <Form.Label>Cost Component:</Form.Label>
                        <Form.Control
                          defaultValue={workTypeDetailsData.costComponent}
                          onChange={onChangeHandler}
                          name="costComponent"
                          value={inputs.costComponent}
                          className="workListLabel"
                        />
                      </Form.Group>
                    </Col>
                    <Col sm={3}>
                      <Form.Group controlId="form.reliabilityWork">
                        <Form.Label>Reliability Work:</Form.Label>
                        <Form.Select
                          aria-label="Reliability Work"
                          onChange={onChangeHandler}
                          name="reliabilityWork"
                          value={inputs.reliabilityWork}
                          className="workListLabel"
                        >
                          {reliabilityWork.map((type, index) => (
                            <option key={index} value={type.value}>
                              {type.label}
                            </option>
                          ))}
                        </Form.Select>
                      </Form.Group>
                    </Col>
                  </Row>
                  <br />
                  <Row>
                    <Col sm={6}>
                      <Form className="stormIndicator">
                        <Form.Label className="responseLabel">
                          Storm Indicator:
                        </Form.Label>
                        <Form.Group
                          controlId="kindOfSelection"
                          className="responseForm"
                        >
                          <Form.Check
                            inline
                            value="NON"
                            type="radio"
                            aria-label="radio 1"
                            label="No Storm"
                            onChange={handleChange}
                            checked={kindOfSelection === 'NON'}
                            className="input1"
                            name="stormIndicator"
                          />
                          <Form.Check
                            inline
                            value="MIN"
                            type="radio"
                            aria-label="radio 2"
                            label="Minor Storm"
                            onChange={handleChange}
                            checked={kindOfSelection === 'MIN'}
                            className="input1"
                            name="stormIndicator"
                          />
                          <Form.Check
                            inline
                            value="MAJ"
                            type="radio"
                            aria-label="radio 2"
                            label="Major Storm"
                            onChange={handleChange}
                            checked={kindOfSelection === 'MAJ'}
                            className="input1"
                            name="stormIndicator"
                          />
                        </Form.Group>
                      </Form>
                    </Col>
                    <Col sm={6}>
                      <Form>
                        <Form.Label className="responseLabel">
                          Override Authorization:
                        </Form.Label>
                        <Form.Group
                          controlId="kindOfSelection"
                          className="responseForm"
                        >
                          <Form.Check
                            inline
                            value={isChecked}
                            type="Checkbox"
                            aria-label="authorization"
                            name="overrideAuthFlag"
                            onChange={handleAuthorization}
                            // checked={kindOfSelection === 'agree'}
                            className="input1"
                          />
                        </Form.Group>
                      </Form>
                    </Col>
                  </Row>
                  <br />
                  <br />
                  <Divider />
                  <br />
                  <Row>
                    <Col>OPCO Information</Col>
                  </Row>
                  <Row>
                    <Col>
                      {formValues.map((element, index) => (
                        <Row>
                          <Col sm={3}>
                            <Form.Group
                              style={{
                                width: '100%',
                                marginRight: '12px',
                              }}
                            >
                              <Form.Label>Operating Company State</Form.Label>
                              <Form.Select
                                value={element?.opco || ''}
                                onChange={e => handleWorkTypeChange(index, e)}
                                name="opco"
                                style={{ width: '95%' }}
                              >
                                <option value="">--Select--</option>
                                {opstateList?.OP?.map((type, index) => (
                                  <option key={index} value={type.opcostate}>
                                    {type.opcostate}
                                  </option>
                                ))}
                              </Form.Select>
                            </Form.Group>
                          </Col>
                          <Col sm={3}>
                            <Form.Group
                              style={{
                                width: '100%',
                                marginRight: '12px',
                              }}
                            >
                              <Form.Label>Project ID </Form.Label>
                              <Form.Select
                                value={element.projectId || ''}
                                onChange={e => handleWorkTypeChange(index, e)}
                                name="projectId"
                                style={{ width: '95%' }}
                              >
                                <option value="">--Select--</option>
                                {opstateList?.PR?.map((type, index) => (
                                  <option key={index} value={type.abmsProject}>
                                    {type.abmsProject}
                                  </option>
                                ))}
                              </Form.Select>
                            </Form.Group>
                          </Col>
                          <Col sm={3}>
                            <Form.Group
                              style={{
                                width: '100%',
                                marginRight: '12px',
                              }}
                            >
                              <Form.Label>Work Order:</Form.Label>
                              <Form.Select
                                value={element.workOrder || ''}
                                onChange={e => handleWorkTypeChange(index, e)}
                                name="workOrder"
                                style={{ width: '95%' }}
                              >
                                <option value="">--Select--</option>
                                {opstateList?.WO?.map((type, index) => (
                                  <option key={index} value={type.workorder}>
                                    {type.workorder}
                                  </option>
                                ))}
                              </Form.Select>
                            </Form.Group>
                          </Col>
                          <Col sm={2}>
                            <Form.Group
                              style={{
                                width: '100%',
                                marginRight: '12px',
                                marginTop: '12px',
                              }}
                            >
                              <Form.Label></Form.Label>
                              <span
                                style={{ width: '100%' }}
                                onClick={e => addFormFields()}
                                title="Add Row"
                              >
                                <AddIcon />
                              </span>
                              {index > 0 && (
                                <span
                                  style={{ width: '100%' }}
                                  onClick={e => removeFormFields(index)}
                                  title="Delete Row"
                                >
                                  <DeleteIcon />
                                </span>
                              )}
                            </Form.Group>
                          </Col>
                        </Row>
                      ))}
                    </Col>
                  </Row>
                </Form>
                <div></div>
              </div>
            </Tab>
          ))}
        </Tabs>
      </div>
      <Modal.Footer>
        <ButtonArea
          primaryLabel="Save"
          secondaryLabel="Cancel"
          primaryOnClick={() => handleAdminWorkTypeSave()}
          secondaryOnClick={() => handleAdminWorkTypeCancel()}
        />
      </Modal.Footer>
    </Modal>
  );
};

export { AdminWorkTypeModel };
