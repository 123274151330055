import {
  LabelFieldMapping,
  POCategoryFieldMapping,
  PORateKeyMapping,
  PORateObjectKeyMapping,
} from 'constants/poRates.ts';
import { ActionType } from 'enums/action.ts';
import { AuthType } from 'enums/jobAuth.ts';
import { SERVICE_TYPES } from 'enums/serviceTypes.ts';
import _ from 'lodash';
import { classificationList, serviceTypeList } from 'pages/Timesheet/utils';
import { isEmptyVal } from 'utils/utils';

export const generateClassificationList = (
  timesheetObj,
  lookup,
  jobAuthTasks = [],
) => {
  const tempClassifications = [];
  serviceTypeList.forEach(serviceType => {
    switch (serviceType) {
      case SERVICE_TYPES.LABOR: {
        tempClassifications.push({
          name: serviceType,
          values: [],
        });

        const classificationValues = [];

        timesheetObj[serviceType]?.map((obj, index) => {
          const valList = _.cloneDeep(classificationList[serviceType]);
          const rateIdKey = PORateObjectKeyMapping[serviceType];
          const rateObject = lookup?.purchaseOrderObject[
            PORateKeyMapping[serviceType]
          ]?.find(poObj => poObj[rateIdKey] === obj[rateIdKey]);
          if (!isEmptyVal(rateObject)) {
            _.range(24).forEach(indx => {
              ///// Set dropdown options
              if (indx === 0) {
                // Rate Schedule
                valList[indx]['values'] =
                  lookup?.rateScheduleOptions[serviceType];
                valList[indx]['value'] =
                  rateObject[LabelFieldMapping['rateSchLabel']];
              } else if (indx === 1) {
                // Code
                valList[indx]['values'] = lookup?.codeOptions[serviceType];
                valList[indx]['value'] =
                  rateObject[LabelFieldMapping['codeLabel']];
              } else if (indx === 2) {
                // Labor Category
                valList[indx]['values'] = lookup?.categoryOptions[serviceType];
                valList[indx]['value'] =
                  rateObject[POCategoryFieldMapping[serviceType]];
              } else if (indx === 3) {
                // Activity
                valList[indx]['values'] =
                  lookup?.costActivityBySTOptions[serviceType];
                valList[indx]['value'] =
                  obj[PORateObjectKeyMapping['activity']];
              } else if (indx === 7) {
                // Shift
                valList[7]['values'] = lookup?.shiftOptions;
              }

              // Set value
              if (![0, 1, 2, 3].includes(indx)) {
                valList[indx]['value'] = obj[valList[indx]['name']];
              }
            });
          }

          classificationValues.push(valList);
        });

        tempClassifications[tempClassifications.length - 1]['values'] =
          _.cloneDeep(classificationValues);
        break;
      }

      case SERVICE_TYPES.EQUIPMENT: {
        tempClassifications.push({
          name: serviceType,
          values: [],
        });

        const classificationValues = [];

        timesheetObj[serviceType]?.map((obj, index) => {
          const valList = _.cloneDeep(classificationList[serviceType]);
          const rateIdKey = PORateObjectKeyMapping[serviceType];
          const rateObject = lookup?.purchaseOrderObject[
            PORateKeyMapping[serviceType]
          ]?.find(poObj => poObj[rateIdKey] === obj[rateIdKey]);

          if (!isEmptyVal(rateObject)) {
            _.range(16).forEach(indx => {
              ///// Set dropdown options
              if (indx === 0) {
                // Rate Schedule
                valList[indx]['values'] =
                  lookup?.rateScheduleOptions[serviceType];
                valList[indx]['value'] =
                  rateObject[LabelFieldMapping['rateSchLabel']];
              } else if (indx === 1) {
                // Code
                valList[indx]['values'] = lookup?.codeOptions[serviceType];
                valList[indx]['value'] =
                  rateObject[LabelFieldMapping['codeLabel']];
              } else if (indx === 2) {
                // Equipment Category
                valList[indx]['values'] = lookup?.categoryOptions[serviceType];
                valList[indx]['value'] =
                  rateObject[POCategoryFieldMapping[serviceType]];
              } else if (indx === 3) {
                // Activity
                valList[indx]['values'] =
                  lookup?.costActivityBySTOptions[serviceType];
                valList[indx]['value'] =
                  obj[PORateObjectKeyMapping['activity']];
              } else if (indx === 8) {
                // Equipment Id
                valList[indx]['values'] = lookup?.equipmentIdOptions.length
                  ? lookup?.equipmentIdOptions
                  : [{ label: 'Select', value: '' }];
              }
              // Set value

              if (![0, 1, 2, 3].includes(indx)) {
                valList[indx]['value'] = obj[valList[indx]['name']];
              }
            });
          }

          classificationValues.push(valList);
        });

        tempClassifications[tempClassifications.length - 1]['values'] =
          _.cloneDeep(classificationValues);
        break;
      }

      case SERVICE_TYPES.REIMBURSABLE_MARKUP: {
        tempClassifications.push({
          name: serviceType,
          values: [],
        });

        const classificationValues = [];

        timesheetObj[serviceType]?.map((obj, index) => {
          const valList = _.cloneDeep(classificationList[serviceType]);
          const rateIdKey = PORateObjectKeyMapping[serviceType];
          const rateObject = lookup?.purchaseOrderObject[
            PORateKeyMapping[serviceType]
          ]?.find(poObj => poObj[rateIdKey] === obj[rateIdKey]);
          if (!isEmptyVal(rateObject)) {
            _.range(7).forEach(indx => {
              ///// Set dropdown options
              if (indx === 0) {
                // Rate Schedule
                valList[indx]['values'] =
                  lookup?.rateScheduleOptions[serviceType];
                valList[indx]['value'] =
                  rateObject[LabelFieldMapping['rateSchLabel']];
              } else if (indx === 1) {
                // Code
                valList[indx]['values'] = lookup?.codeOptions[serviceType];
                valList[indx]['value'] =
                  rateObject[LabelFieldMapping['codeLabel']];
              } else if (indx === 2) {
                // Activity
                valList[indx]['values'] =
                  lookup?.costActivityBySTOptions[serviceType];
                valList[indx]['value'] =
                  obj[PORateObjectKeyMapping['activity']];
              }

              // Set value
              if (![0, 1, 2].includes(indx)) {
                valList[indx]['value'] = obj[valList[indx]['name']];
              }
            });
          }

          classificationValues.push(valList);
        });

        tempClassifications[tempClassifications.length - 1]['values'] =
          _.cloneDeep(classificationValues);
        break;
      }

      case SERVICE_TYPES.UNIT_PRICE_TASK: {
        tempClassifications.push({
          name: serviceType,
          values: [],
        });

        const classificationValues = [];

        timesheetObj[serviceType]?.map((obj, index) => {
          const valList = _.cloneDeep(classificationList[serviceType]);
          const rateIdKey = PORateObjectKeyMapping[serviceType];
          const rateObject = lookup?.purchaseOrderObject[
            PORateKeyMapping[serviceType]
          ]?.find(poObj => poObj[rateIdKey] === obj[rateIdKey]);

          if (
            timesheetObj?.authType?.trim() === AuthType.UNIT_PRICE &&
            !isEmptyVal(timesheetObj?.authNmbr)
          ) {
            const codeOptions = [{ label: 'Select', value: '' }];
            jobAuthTasks.forEach(obj => {
              codeOptions.push({ label: obj?.codelabel, value: obj?.taskcode });
            });
            _.range(13).forEach(indx => {
              // Code
              if (indx === 1) {
                valList[indx]['values'] = codeOptions;
                valList[indx]['value'] = obj?.code;
              } else if (indx === 2) {
                valList[indx]['value'] = obj?.costactivityid;
              }

              // Set value
              if (![0, 1].includes(indx)) {
                valList[indx]['value'] = obj[valList[indx]['name']];
              }
            });
          } else if (!isEmptyVal(rateObject)) {
            _.range(13).forEach(indx => {
              ///// Set dropdown options
              if (indx === 0) {
                // Rate Schedule
                valList[indx]['values'] =
                  lookup?.rateScheduleOptions[serviceType];
                valList[indx]['value'] =
                  rateObject[LabelFieldMapping['rateSchLabel']];
              } else if (indx === 1) {
                // Code
                valList[indx]['values'] = lookup?.codeOptions[serviceType];
                valList[indx]['value'] =
                  rateObject[LabelFieldMapping['codeLabel']];
              } else if (indx === 2) {
                // Activity
                valList[indx]['values'] =
                  lookup?.costActivityBySTOptions[serviceType];
                valList[indx]['value'] =
                  obj[PORateObjectKeyMapping['activity']];
              }

              // Set value
              if (![0, 1, 2].includes(indx)) {
                valList[indx]['value'] = obj[valList[indx]['name']];
              }
            });
          }

          classificationValues.push(valList);
        });

        tempClassifications[tempClassifications.length - 1]['values'] =
          _.cloneDeep(classificationValues);
        break;
      }

      case SERVICE_TYPES.CU: {
        tempClassifications.push({
          name: serviceType,
          values: [],
        });

        const classificationValues = [];
        timesheetObj[serviceType]?.map((obj, index) => {
          const valList = _.cloneDeep(classificationList[serviceType]);
          const rateIdKey = PORateObjectKeyMapping[serviceType];
          const rateObject = lookup?.purchaseOrderObject[
            PORateKeyMapping[serviceType]
          ]?.find(poObj => poObj[rateIdKey] === obj[rateIdKey]);
          if (!isEmptyVal(rateObject)) {
            _.range(12).forEach(indx => {
              ///// Set dropdown options
              if (indx === 0) {
                // Rate Schedule
                valList[indx]['values'] =
                  lookup?.rateScheduleOptions[serviceType];
                valList[indx]['value'] =
                  rateObject[LabelFieldMapping['rateSchLabel']];
              } else if (indx === 1) {
                // Code
                valList[indx]['values'] = lookup?.codeOptions[serviceType];
                valList[indx]['value'] =
                  rateObject[LabelFieldMapping['codeLabel']];
              } else if (indx === 3) {
                // Activity
                valList[indx]['values'] =
                  lookup?.costActivityBySTOptions[serviceType];
                valList[indx]['value'] =
                  obj[PORateObjectKeyMapping['activity']];
              }

              // Set value
              if (![0, 1, 3].includes(indx)) {
                if (![2, 6, 7, 8].includes(indx)) {
                  valList[indx]['value'] = obj[valList[indx]['name']];
                } else {
                  if (indx === 2) {
                    valList[2]['value'] = rateObject.unitmeasure;
                  } else if (
                    indx === 6 &&
                    obj.action === ActionType.INSTALL_RATE
                  ) {
                    valList[6]['value'] = rateObject.installrate;
                    valList[7]['value'] = '';
                    valList[8]['value'] = '';
                  } else if (
                    indx === 7 &&
                    obj.action === ActionType.REMOVE_RATE
                  ) {
                    valList[6]['value'] = '';
                    valList[7]['value'] = rateObject.removerate;
                    valList[8]['value'] = '';
                  } else if (
                    indx === 8 &&
                    obj.action === ActionType.TRANSFER_RATE
                  ) {
                    valList[6]['value'] = '';
                    valList[7]['value'] = '';
                    valList[8]['value'] = rateObject.transferrate;
                  }
                }
              }
            });
          }

          classificationValues.push(valList);
        });

        tempClassifications[tempClassifications.length - 1]['values'] =
          _.cloneDeep(classificationValues);
        break;
      }

      case SERVICE_TYPES.LUMPSUM: {
        tempClassifications.push({
          name: SERVICE_TYPES.LUMPSUM,
          values: [],
        });

        const classificationValues = [];

        timesheetObj[SERVICE_TYPES.LUMPSUM]?.map((obj, index) => {
          const valList = _.cloneDeep(
            classificationList[SERVICE_TYPES.LUMPSUM],
          );

          _.range(3).forEach(indx => {
            if (indx === 0) {
              // Activity
              valList[indx]['values'] =
                lookup?.costActivityBySTOptions[serviceType];
              valList[indx]['value'] = obj[PORateObjectKeyMapping['activity']];
            } else {
              // Set value
              valList[indx]['value'] = obj[valList[indx]['name']];
            }
          });

          classificationValues.push(valList);
        });

        tempClassifications[tempClassifications.length - 1]['values'] =
          _.cloneDeep(classificationValues);
        break;
      }

      default:
        const classificationValues = [];
    }
  });

  return tempClassifications.filter(obj => obj.values.length);
};
