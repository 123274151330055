// @ts-nocheck
import { CaretDown } from 'assets/images/CaretIcon/CaretDown/CaretDown';
import { CaretUp } from 'assets/images/CaretIcon/CaretUp/CaretUp';
import { SearchIcon } from 'assets/images/SearchIcon/SearchIcon';
import { AEPFilterComponent } from 'components/common/Filter/AEPFilterComponent';
import { AEPFilterModal } from 'components/common/Filter/AEPFilterModal';
import { AEPPagination } from 'components/common/Pagination/AEPPagination';
import { BuPath } from 'constants/buPath';
import { PROMISE_TRACKER } from 'constants/promiseTrackers';
import { ROUTES_STRING } from 'constants/routes';
import {
  TimesheetDisplayValue,
  TimesheetStatusDisplayValue,
} from 'constants/statusValues';
import {
  getTimeSheetStatusByKey,
  keyTimeSheetTab,
  optionsTimeSheetListTab,
} from 'constants/timeSheetTabs.ts';
import { ModuleACL } from 'enums/entitlements.ts';
import { Page } from 'enums/page.ts';
import _ from 'lodash';
import { useEffect, useState } from 'react';
import {
  Accordion,
  Button,
  Col,
  Form,
  ListGroup,
  Row,
  Tab,
  Table,
  Tabs,
} from 'react-bootstrap';
import { usePromiseTracker } from 'react-promise-tracker';
import { useDispatch, useSelector } from 'react-redux';
import { useMediaQuery } from 'react-responsive';
import { useLocation, useNavigate } from 'react-router-dom';
import ReactSelect from 'react-select';
import ClipLoader from 'react-spinners/ClipLoader';
import { setTimesheetErrorObj } from 'store/slices/errorSlice';
import { resetFilters, setTimesheetFilters } from 'store/slices/filterSlice';
import {
  fetchCircuit,
  fetchCrewType,
  fetchEquipmentIdList,
  fetchJobAuthorizations,
  fetchOperatingCompany,
  fetchWorkArea,
  fetchWorkType,
} from 'store/slices/lookupSlice';
import { setActivePage } from 'store/slices/pageSlice';
import { fetchPurchaseOrderDetails } from 'store/slices/purchaseOrder/purchaseOrderDetailsSlice';
import {
  fetchPurchaseOrders,
  setPOPagination,
} from 'store/slices/purchaseOrder/purchaseOrderSlice';
import { resetTimesheetDetail } from 'store/slices/timesheet/timesheetDetailSlice';
import {
  fetchSelectedTimesheetList,
  fetchTimesheets,
  resetTimesheets,
  setTimesheetPagination,
} from 'store/slices/timesheet/timesheetSlice';
import { IsAllowedACL, filterAllowedOptionsACL } from 'utils/aclHelper';
import { isEmptyVal } from 'utils/utils';
import './TimesheetList.scss';

const TimesheetList = props => {
  const dispatch = useDispatch();
  const navigate = useNavigate();
  const location = useLocation();

  const type = 'TimeSheet';

  const timesheets = useSelector(state => state.timesheet.items);
  const purchaseOrder = useSelector(state => state.purchaseOrderDetails);

  const user = useSelector(state => state.auth);
  const pagination = useSelector(state => state.timesheet.pagination);

  const currentPage = useSelector(
    state => state.timesheet.pagination.currentPage,
  );
  const sortBy = useSelector(state => state.timesheet.pagination.sortBy);
  const sortOrder = useSelector(state => state.timesheet.pagination.sortOrder);

  const [chipFilterValues, setChipFilterValues] = useState({});

  const inputListData = useSelector(state => state.filter.timesheet);
  const selectedFilterRecordList = useSelector(
    state => state.timesheet.recordItems,
  );

  const [filterChip, setFilterchip] = useState(false);
  const [continueBtnDisable, setContinueBtnDisable] = useState(true);
  const [timesheetLoading, setTimesheetLoading] = useState(false);

  const [key, setKey] = useState('all');
  const [modalShow, setModalShow] = useState(false);
  const [submitPage, setSubmitPage] = useState(false);
  const [selectedPurchaseOrder, setSelectedPurchaseOrder] = useState({
    label: 'Select',
    value: '',
    contactNumber: '',
  });
  const [selectedContractor, setSelectedContractor] = useState({
    label: 'Select',
    value: '',
  });
  const [tabs, setTabs] = useState([]);
  const [disableCreateButton, setDisableCreateButton] = useState(true);
  const [pOOptionList, setPOOptionList] = useState([]);
  const [contractNumOptionList, setContractNumOptionList] = useState([]);
  const [showTimesheetButtons, setShowTimesheetButtons] = useState(true);

  const isMobile = useMediaQuery({ query: '(max-width: 575px)' });

  const TabKey = keyTimeSheetTab;

  const optionsTab = optionsTimeSheetListTab;

  const headers = [
    { key: 'recordNmbr', value: 'Timesheet No.' },
    { key: 'contractNmbr', value: 'Contract Number' },
    { key: 'ponum', value: 'Purchase Order' },
    { key: 'vendor', value: 'Vendor Name' },
    { key: 'workStartDate', value: 'Start Date' },
    { key: 'workEndDate', value: 'End Date' },
    { key: 'enteredBy', value: 'Entered By' },
    { key: 'updatedDate', value: 'Last Updated' },
    { key: 'caastatus', value: 'Status' },
  ];

  const timesheetFiltersValues = [
    { label: 'Timesheet No', value: 'recordNmbr' },
    { label: 'PO Number', value: 'ponum' },
    { label: 'Work Area', value: 'workAreaShortDesc' },
    { label: 'Status', value: 'caastatus' },
    { label: 'Work Order', value: 'workorder' },
    { label: 'Work Request', value: 'wr' },
    { label: 'Crew Type', value: 'crewType' },
    { label: 'BU Path', value: 'bupath' },
    { label: 'Circuit No', value: 'circuitnum' },
    { label: 'Auth No', value: 'authNmbr' },
    { label: 'Contract ID', value: 'contractNmbr' },
    { label: 'Week End Date', value: 'weekEndDate' },
    { label: 'Start Date', value: 'workStartDate' },
    { label: 'End Date', value: 'workEndDate' },
    { label: 'Vendor', value: 'vendor' },
    { label: 'Work Type', value: 'worktype' },
    { label: 'UVL', value: 'uvl' },
    { label: 'Entered By', value: 'enteredBy' },
    { label: 'Last Updated', value: 'updatedDate' },
  ];

  const { promiseInProgress: poLoading } = usePromiseTracker({
    area: PROMISE_TRACKER.getPODetail,
    delay: 0,
  });

  const { promiseInProgress: entitlementsLoading } = usePromiseTracker({
    area: PROMISE_TRACKER.getPODetail,
    delay: 0,
  });

  const { promiseInProgress: timesheetListLoading } = usePromiseTracker({
    area: PROMISE_TRACKER.getTimesheetList,
    delay: 0,
  });

  const handleInputChange = value => {
    if (!isEmptyVal(value)) {
      dispatch(
        fetchPurchaseOrders({
          ponum: value,
          callback: data => {
            getPOOptions(data);
            getContractNumbers(data);
            setSelectedContractor({ label: 'Select', value: '' });
          },
        }),
      );
    }
  };

  const handleContractInputChange = value => {
    if (!isEmptyVal(value)) {
      dispatch(
        fetchPurchaseOrders({
          contractrefnum: value,
          callback: data => {
            getPOOptions(data);
            getContractNumbers(data);
            setSelectedContractor({ label: 'Select', value: '' });
          },
        }),
      );
    }
  };
  const debouncedInputChange = _.debounce(handleInputChange, 500);
  const debouncedContractInputChange = _.debounce(
    handleContractInputChange,
    500,
  );

  const handleSubmit = () => {
    if (BuPath.DIST.includes(purchaseOrder?.buPath)) {
      dispatch(fetchCrewType());
      dispatch(fetchWorkType());
      dispatch(fetchWorkArea());
      dispatch(fetchOperatingCompany());
      dispatch(
        fetchJobAuthorizations({ ponum: purchaseOrder?.ponum, limit: 500 }),
      );
      dispatch(fetchCircuit({ limit: 100 }));
    }

    dispatch(
      fetchEquipmentIdList({
        poid: purchaseOrder.poid,
        callback: async eqIdList => {
          navigate(`/${ROUTES_STRING.timesheets}/new`);
        },
      }),
    );
  };

  const handleCreateTimesheet = () => {
    dispatch(setTimesheetPagination({ itemsPerPage: 10 }));
    // @ts-ignore
    dispatch(
      setPOPagination({
        itemsPerPage: 500,
        ponum: '',
        contractrefnum: '',
        caastatus: 'P,R,V,F,C,A,N',
      }),
    );
    dispatch(
      fetchPurchaseOrders({
        callback: data => {
          getPOOptions(data);
          getContractNumbers(data);
        },
      }),
    );
    dispatch(resetTimesheetDetail());
    dispatch(setTimesheetErrorObj({ isSuccess: false, isUvlSuccess: false }));
    setSubmitPage(true);

    if (props.type === 'potimesheet') {
      navigate(`/${ROUTES_STRING.timesheets}`);
      dispatch(fetchPurchaseOrderDetails(props.poId));
      handleSubmit();
    }
  };

  const handleImportTimesheet = () => {
    dispatch(setPOPagination({ itemsPerPage: 500 }));
    dispatch(
      fetchPurchaseOrders({
        callback: data => {
          navigate(`/${ROUTES_STRING.importTimeSheet}`);
        },
      }),
    );
  };

  const handleSecondaryClick = () => {};

  const handleTimesheetClick = timesheet => {
    setTimesheetLoading(true);
    dispatch(fetchEquipmentIdList(timesheet?.poid));

    dispatch(
      fetchPurchaseOrderDetails({
        poId: timesheet?.poid,
        callback: purchaseOrder => {
          navigate(`/${ROUTES_STRING.timesheets}/${timesheet?.recordNmbr}`);
        },
      }),
    );
    setTimesheetLoading(false);
  };

  const handleSorting = (sortBy, sortOrder) => {
    dispatch(
      setTimesheetPagination({
        ...pagination,
        currentPage: 1,
        sortBy,
        sortOrder,
      }),
    );
    // handleContinueClickTimeSheet(key, true);
  };

  const handleContinueClick = (data = null) => {
    let inputData = _.cloneDeep(inputListData);
    if (!isEmptyVal(data)) {
      inputData = _.cloneDeep(data);
    }
    let selectedFilterData = {};
    let selectedFilterValue = {};
    let uniqueFilterValues = [];

    inputData.map((el, id) => {
      if (uniqueFilterValues.indexOf(el.FilterValue) === -1) {
        uniqueFilterValues.push(el.FilterValue);
      }
    });
    uniqueFilterValues.map(value => {
      let temp1 = [];
      inputData.map((val, ids) => {
        if (val.FilterValue == value) {
          temp1.push(val.selectFilterValue);
        }
      });
      selectedFilterData[value] = temp1;
    });

    inputData.map((el, id) => {
      Object.keys(selectedFilterData).map(function (item) {
        if (
          item == el.FilterValue &&
          !isEmptyVal(el.FilterLabel) &&
          !isEmptyVal(selectedFilterData[item])
        ) {
          selectedFilterValue[el.FilterLabel] = selectedFilterData[item];
        }
      });
    });

    let pageNumber = 1;
    if (!isEmptyVal(data) && inputListData?.length === data?.length) {
      pageNumber = pagination.currentPage;
    }
    dispatch(
      fetchTimesheets({ ...selectedFilterData, currentPage: pageNumber }),
    );
    let vendorSelectFilterLabel = [];
    if (selectedFilterValue.Vendor != undefined) {
      selectedFilterValue.Vendor.map((e, id) => {
        inputData.map((val, ind) => {
          if (val.selectFilterValue == e) {
            vendorSelectFilterLabel.push(val.selectFilterLabel);
          }
        });
        selectedFilterValue.Vendor = vendorSelectFilterLabel;
        return selectedFilterValue.Vendor;
      });
    }

    setChipFilterValues(selectedFilterValue);
    setModalShow(false);
    setFilterchip(true);
  };

  const handleFilterChip = (chipname, chipValue) => {
    let filterchipData = {};

    Object.keys(chipFilterValues).map(function (item, i) {
      let chipList = [...chipFilterValues[item]];
      chipList.map((val, ids) => {
        if (val == chipValue) {
          chipList.splice(ids, 1);
        }
      });
      chipFilterValues[item] = chipList;
    });

    inputListData.map((el, id) => {
      Object.keys(chipFilterValues).map(function (item) {
        if (item == el.FilterLabel) {
          filterchipData[el.FilterValue] = chipFilterValues[item];
        }
      });
    });

    let indextodelete;
    let newInputList1 = _.cloneDeep(inputListData);
    newInputList1.map((val, ids) => {
      if (val.FilterValue == 'vendor') {
        if (val.FilterLabel == chipname && val.selectFilterLabel == chipValue) {
          indextodelete = ids;
        }
      } else {
        if (val.FilterLabel == chipname && val.selectFilterValue == chipValue) {
          indextodelete = ids;
        }
      }
    });
    newInputList1.splice(indextodelete, 1);

    if (newInputList1.length == 0) {
      setContinueBtnDisable(true);
    }
    dispatch(setTimesheetFilters(newInputList1));
    setChipFilterValues(chipFilterValues);

    let vendorSelectFilterValue = [];
    if (filterchipData.vendor != undefined) {
      filterchipData.vendor.map((e, id) => {
        inputListData.map((val, ind) => {
          if (val.selectFilterLabel == e) {
            vendorSelectFilterValue.push(val.selectFilterValue);
          }
        });
        filterchipData.vendor = vendorSelectFilterValue;
        return filterchipData.vendor;
      });
    }

    dispatch(fetchTimesheets(filterchipData));
  };

  const handleFilterclose = type => {
    if (type == 'filterChange') {
      setContinueBtnDisable(false);
    } else if (type == 'deleteEmpty') {
      setContinueBtnDisable(true);
    }
  };

  useEffect(() => {
    if (location?.state?.from !== 'timesheetDetail') {
      dispatch(resetFilters());
    }

    dispatch(
      setActivePage(
        props.type !== 'potimesheet' ? Page.TIMESHEET : Page.PURCHASE_ORDER,
      ),
    );
    dispatch(resetTimesheets());
    if (props.type === 'potimesheet') {
      dispatch(
        fetchPurchaseOrderDetails({
          poId: props.poId,
          callback: data => {
            let showButtons = true;
            data?.polines.forEach(obj => {
              if (
                obj.itemnum === 'EWO' &&
                ['COMP', 'PAID'].includes(obj.status)
              ) {
                showButtons = false;
              }
            });
            setShowTimesheetButtons(showButtons);
          },
        }),
      );
      dispatch(fetchTimesheets({ ponum: purchaseOrder.ponum }));
    } else {
      dispatch(fetchTimesheets({ currentPage: pagination?.currentPage }));
    }

    const optionsAllowed = filterAllowedOptionsACL(
      user,
      ModuleACL.TIMESHEET,
      optionsTab,
    );
    setTabs(optionsAllowed);

    if (location.state === 'fromHomePage') {
      handleCreateTimesheet();
    }

    if (location?.state?.from === 'homePage') {
      handleTabSelect(location?.state?.status);
    }
    handleContinueClick(inputListData);
  }, [dispatch]);

  const handleTabSelect = key => {
    switch (key) {
      case TabKey.SAVED: {
        dispatch(
          setTimesheetPagination({
            ponum: props.type === 'potimesheet' ? purchaseOrder.ponum : '',
            forceFetch: true,
            currentPage: 1,
            caastatus: `${getTimeSheetStatusByKey(TabKey.SAVED)}`,
            showAssigned: '',
            sortBy: '',
          }),
        );
        break;
      }
      case TabKey.SUBMITTED: {
        dispatch(
          setTimesheetPagination({
            ponum: props.type === 'potimesheet' ? purchaseOrder.ponum : '',
            forceFetch: true,
            currentPage: 1,
            caastatus: `${getTimeSheetStatusByKey(TabKey.SUBMITTED)}`,
            showAssigned: '',
            sortBy: '',
          }),
        );
        break;
      }
      case TabKey.PENDING_FOR_APPROVAL: {
        dispatch(
          setTimesheetPagination({
            ponum: props.type === 'potimesheet' ? purchaseOrder.ponum : '',
            currentPage: 1,
            caastatus: `${getTimeSheetStatusByKey(
              TabKey.PENDING_FOR_APPROVAL,
            )}`,
            showAssigned: true,
            sortBy: 'updatedDate',
            sortOrder: 'asc',
          }),
        );
        break;
      }
      case TabKey.APPROVED: {
        dispatch(
          setTimesheetPagination({
            ponum: props.type === 'potimesheet' ? purchaseOrder.ponum : '',
            forceFetch: true,
            currentPage: 1,
            caastatus: `${getTimeSheetStatusByKey(TabKey.APPROVED)}`,
            showAssigned: '',
            sortBy: '',
          }),
        );
        break;
      }
      case TabKey.INVOICED: {
        dispatch(
          setTimesheetPagination({
            ponum: props.type === 'potimesheet' ? purchaseOrder.ponum : '',
            forceFetch: true,
            currentPage: 1,
            caastatus: `${getTimeSheetStatusByKey(TabKey.INVOICED)}`,
            showAssigned: '',
            sortBy: '',
          }),
        );
        break;
      }
      case TabKey.RETURNED: {
        dispatch(
          setTimesheetPagination({
            ponum: props.type === 'potimesheet' ? purchaseOrder.ponum : '',
            forceFetch: true,
            currentPage: 1,
            caastatus: `${getTimeSheetStatusByKey(TabKey.RETURNED)}`,
            showAssigned: '',
            sortBy: '',
          }),
        );
        break;
      }
      case TabKey.CANCELED: {
        dispatch(
          setTimesheetPagination({
            ponum: props.type === 'potimesheet' ? purchaseOrder.ponum : '',
            forceFetch: true,
            currentPage: 1,
            caastatus: `${getTimeSheetStatusByKey(TabKey.CANCELED)}`,
            showAssigned: '',
            sortBy: '',
          }),
        );
        break;
      }

      default: {
        dispatch(
          setTimesheetPagination({
            ponum: props.type === 'potimesheet' ? purchaseOrder.ponum : '',
            forceFetch: true,
            currentPage: 1,
            caastatus: '',
            showAssigned: '',
            sortBy: '',
          }),
        );
      }
    }
    setKey(key);

    handleContinueClick();
  };

  const getMobileView = () => {
    return (
      <>
        <div
          className="btnArea"
          style={{
            display: 'flex',
            justifyContent: 'flex-end',
            padding: '12px 0',
          }}
        >
          {IsAllowedACL(user, ModuleACL.TIMESHEET, 'can_create_timesheet') && (
            <Button variant="primary" onClick={handleCreateTimesheet}>
              Create New Timesheet
            </Button>
          )}
          {IsAllowedACL(
            user,
            ModuleACL.TIMESHEET,
            'can_import_spreadsheet',
          ) && (
            <Button variant="primary" onClick={handleImportTimesheet}>
              Import Timesheet(s)
            </Button>
          )}
        </div>
        <div className={props.type !== 'potimesheet' ? 'aepContainer' : ''}>
          <Accordion activeKey={key} onSelect={k => handleTabSelect(k)}>
            {tabs?.map((tab, index) => (
              <Accordion.Item eventKey={tab.key} title={tab.value} key={index}>
                <Accordion.Header>{tab.value}</Accordion.Header>
                <Accordion.Body>
                  {props.type !== 'potimesheet' ? (
                    <Row>
                      <Col sm={11}>
                        <>
                          <div
                            className="filterComponent"
                            style={{ margin: '5px  5px 45px 24px' }}
                          >
                            <AEPFilterComponent
                              inputListData={inputListData}
                              filterValues={timesheetFiltersValues}
                              setInputFilterData={setTimesheetFilters}
                              selectedFilterRecordList={
                                selectedFilterRecordList
                              }
                              fetchSelectedList={fetchSelectedTimesheetList}
                              handleContinueClick={handleContinueClick}
                              continueBtnDisable={continueBtnDisable}
                              handleFilterclose={handleFilterclose}
                              handleFilterChip={handleFilterChip}
                              filterChip={filterChip}
                              chipFilterValues={chipFilterValues}
                            />
                          </div>
                        </>
                      </Col>
                    </Row>
                  ) : (
                    <div className="search">
                      <div style={{ display: 'flex' }}>
                        <Form.Group className="mb-3" controlId="searchPO">
                          <Form.Control type="text" placeholder="Search" />
                        </Form.Group>
                        <div className="searchIcon">
                          <SearchIcon />
                        </div>
                      </div>
                    </div>
                  )}
                  {timesheets.map((row, indx) => (
                    <ListGroup>
                      <ListGroup.Item
                        key={indx}
                        data-index={indx}
                        action
                        onClick={e => handleTimesheetClick(row)}
                      >
                        <p>
                          <b>Timesheet #:</b> {row.recordNmbr}
                        </p>
                        <p>
                          <b>Purchase Order:</b> {row.ponum}
                        </p>
                        <p>
                          <b>Start Date:</b> {row.workStartDate}
                        </p>
                        <p>
                          <b>End Date:</b> {row.workEndDate}
                        </p>
                        <p>
                          <b>Status:</b> {TimesheetDisplayValue[row.caastatus]}
                        </p>
                      </ListGroup.Item>
                    </ListGroup>
                  ))}
                  {/* </div> */}
                </Accordion.Body>
              </Accordion.Item>
            ))}
          </Accordion>
          <>
            <AEPPagination
              fetchItems={fetchTimesheets}
              setPagination={setTimesheetPagination}
              pagination={pagination}
              currentPage={currentPage}
              sortBy={sortBy}
              sortOrder={sortOrder}
            />
            {modalShow && (
              <AEPFilterModal
                show={modalShow}
                setShowModal={setModalShow}
                inputListData={inputListData}
                filterValues={timesheetFiltersValues}
                setInputFilterData={setTimesheetFilters}
                selectedFilterRecordList={selectedFilterRecordList}
                fetchSelectedList={fetchSelectedTimesheetList}
                handleContinueClick={handleContinueClick}
                continueBtnDisable={continueBtnDisable}
                handleFilterclose={handleFilterclose}
                type={type}
              />
            )}
          </>
        </div>
      </>
    );
  };

  const getContractNumbers = purchaseOrderList => {
    const options = [];
    purchaseOrderList.forEach((po, index) => {
      options.push({ label: po.contractNumber, value: po.poId });
    });
    const contractOptions = _.uniqBy(options, 'label');
    setContractNumOptionList(contractOptions);
    return contractOptions;
  };

  const getPOOptions = purchaseOrderList => {
    const options = [];

    purchaseOrderList.forEach(po => {
      options.push({
        label: po.poNumber,
        value: po.poId,
        contractNumber: po.contractNumber,
      });
    });

    setPOOptionList(options);
  };

  const handleSelectPO = e => {
    setDisableCreateButton(true);
    if (isEmptyVal(e)) {
      setSelectedPurchaseOrder({
        label: 'Select',
        value: '',
        contactNumber: '',
      });
      setSelectedContractor({ label: 'Select', value: '' });
      dispatch(
        setPOPagination({ itemsPerPage: 500, ponum: '', contractrefnum: '' }),
      );
      dispatch(
        fetchPurchaseOrders({
          callback: data => {
            getPOOptions(data);
            getContractNumbers(data);
            setDisableCreateButton(false);
          },
        }),
      );
    } else {
      setSelectedContractor({ label: e.contractNumber, value: e.value });
      setSelectedPurchaseOrder(pOOptionList.find(obj => obj.value === e.value));
      if (e.value) {
        dispatch(
          fetchPurchaseOrderDetails({
            poId: e.value,
            callback: () => {
              setDisableCreateButton(false);
            },
          }),
        );
      }
    }
  };

  const handleSelectContactor = e => {
    setSelectedPurchaseOrder({
      label: 'Select',
      value: '',
      contactNumber: '',
    });
    if (isEmptyVal(e)) {
      setSelectedContractor({ label: 'Select', value: '' });
      dispatch(
        setPOPagination({ itemsPerPage: 500, ponum: '', contractrefnum: '' }),
      );
      dispatch(
        fetchPurchaseOrders({
          callback: data => {
            getPOOptions(data);
            getContractNumbers(data);
          },
        }),
      );
    } else {
      setSelectedContractor({ label: e.label, value: e.value });
      dispatch(
        setPOPagination({
          itemsPerPage: 500,
          ponum: '',
          contractrefnum: e.value,
        }),
      );
      dispatch(
        fetchPurchaseOrders({
          contractrefnum: e.label,
          callback: data => {
            getPOOptions(data);
            getContractNumbers(data);
          },
        }),
      );
    }
  };

  return (
    <>
      {props.type !== 'potimesheet' && (
        <>
          <div className="aepContainer aepHeader">Timesheets</div>
          {/* <div
            className="aepContainer aepBreadcrumb"
            style={isMobile ? { display: 'none' } : { display: 'flex' }}
          >
            <div className="backArrow" onClick={e => setSubmitPage(false)}>
              <BackArrowIcon />
            </div>
            Create New Timesheet(s)
          </div> */}
        </>
      )}

      {submitPage ? (
        <>
          <div className="aepContainer uvlEstimate">
            <Form
              style={
                isMobile
                  ? { display: 'flex', flexDirection: 'column' }
                  : { display: 'flex' }
              }
            >
              <Form.Group controlId="form.poOptions" className="col-sm-2">
                <Form.Label>Contract Number: </Form.Label>
                <ReactSelect
                  id="poReactSelect"
                  style={
                    isMobile
                      ? { display: 'block', width: '100%' }
                      : { display: 'block' }
                  }
                  classNamePrefix="poSelect"
                  value={selectedContractor}
                  isClearable
                  isSearchable={true}
                  name="po"
                  options={contractNumOptionList}
                  menuPosition="absolute"
                  menuPortalTarget={document.body}
                  styles={{
                    menuPortal: base => ({
                      ...base,
                      zIndex: 9999,
                      fontSize: '16px',
                    }),
                  }}
                  maxMenuHeight={600}
                  onChange={e => handleSelectContactor(e)}
                  onInputChange={e => debouncedContractInputChange(e)}
                />
              </Form.Group>
              <Form.Group
                controlId="form.poOptions"
                style={{
                  marginRight: '0px',
                  marginLeft: isMobile ? '0px' : '20px',
                }}
                className="col-sm-2"
              >
                <Form.Label>Purchase Order Number:</Form.Label>
                <ReactSelect
                  id="poReactSelect"
                  style={
                    isMobile
                      ? { display: 'block', width: '100%' }
                      : { display: 'block' }
                  }
                  classNamePrefix="poSelect"
                  value={selectedPurchaseOrder}
                  isClearable
                  isSearchable={true}
                  name="po"
                  options={pOOptionList}
                  menuPosition="absolute"
                  menuPortalTarget={document.body}
                  styles={{
                    menuPortal: base => ({
                      ...base,
                      zIndex: 9999,
                      fontSize: '16px',
                    }),
                  }}
                  maxMenuHeight={600}
                  onChange={e => handleSelectPO(e)}
                  onInputChange={e => debouncedInputChange(e)}
                />
              </Form.Group>
            </Form>
          </div>
          <div
            className="aepContainer btnArea"
            style={{ backgroundColor: 'none' }}
          >
            {IsAllowedACL(user, ModuleACL.TIMESHEET, 'can_create_timesheet') &&
              (poLoading ? (
                <ClipLoader
                  color="#009cde"
                  loading={poLoading}
                  cssOverride={{
                    top: '24vh',
                    right: '7vw',
                    position: 'absolute',
                    zIndex: 1,
                  }}
                  size={40}
                  aria-label="Loading Spinner"
                  data-testid="loader"
                />
              ) : (
                <Button
                  variant="primary"
                  onClick={handleSubmit}
                  disabled={
                    selectedPurchaseOrder?.value === '' || disableCreateButton
                  }
                >
                  Create Timesheet
                </Button>
              ))}
          </div>
        </>
      ) : (
        <>
          {isMobile ? (
            getMobileView()
          ) : (
            <div>
              <div
                className={props.type !== 'potimesheet' ? 'aepContainer' : ''}
              >
                <Tabs
                  id="timesheetPage"
                  activeKey={key}
                  onSelect={k => handleTabSelect(k)}
                  tabClassName="tab-item"
                >
                  {tabs?.map((tab, index) => (
                    <Tab
                      eventKey={tab.key}
                      title={tab.value}
                      key={index}
                      tabClassName="tab-item"
                    >
                      <div className="tableContainer">
                        {props.type != 'potimesheet' ? (
                          <Row>
                            <Col sm={11}>
                              <>
                                <div className="filterComponent">
                                  <AEPFilterComponent
                                    inputListData={inputListData}
                                    filterValues={timesheetFiltersValues}
                                    setInputFilterData={setTimesheetFilters}
                                    selectedFilterRecordList={
                                      selectedFilterRecordList
                                    }
                                    fetchSelectedList={
                                      fetchSelectedTimesheetList
                                    }
                                    handleContinueClick={handleContinueClick}
                                    continueBtnDisable={continueBtnDisable}
                                    handleFilterclose={handleFilterclose}
                                    handleFilterChip={handleFilterChip}
                                    filterChip={filterChip}
                                    chipFilterValues={chipFilterValues}
                                  />
                                </div>
                              </>
                            </Col>
                          </Row>
                        ) : (
                          <></>
                          // <div className="search">
                          //   <div style={{ display: 'flex' }}>
                          //     <Form.Group className="mb-3" controlId="searchPO">
                          //       <Form.Control type="text" placeholder="Search" />
                          //     </Form.Group>
                          //     <div className="searchIcon">
                          //       <SearchIcon />
                          //     </div>
                          //   </div>
                          // </div>
                        )}

                        {!timesheetLoading &&
                          !timesheetListLoading &&
                          !entitlementsLoading && (
                            <Table hover responsive striped bordered={false}>
                              <thead>
                                <tr>
                                  {headers.map((header, idx) => (
                                    <th key={idx}>
                                      {sortOrder === 'asc' && (
                                        <div
                                          id={header.key}
                                          onClick={e =>
                                            handleSorting(header.key, 'desc')
                                          }
                                        >
                                          {header.value}
                                          <CaretUp
                                            style={{ marginLeft: '12px' }}
                                          />
                                        </div>
                                      )}

                                      {sortOrder === 'desc' && (
                                        <div
                                          id={header.key}
                                          onClick={e =>
                                            handleSorting(header.key, 'asc')
                                          }
                                        >
                                          {header.value}
                                          <CaretDown
                                            style={{ marginLeft: '12px' }}
                                          />
                                        </div>
                                      )}
                                    </th>
                                  ))}
                                </tr>
                              </thead>
                              <tbody>
                                {timesheets.map((row, indx) => (
                                  <tr
                                    key={indx}
                                    className="tRow table-hover-row"
                                    onClick={e => handleTimesheetClick(row)}
                                  >
                                    <td>{row.recordNmbr}</td>
                                    <td>{row?.po?.contractrefnum}</td>
                                    <td>{row?.po?.ponum}</td>
                                    <td>
                                      {row?.po?.vendorVendor?.vendor_name}
                                    </td>
                                    <td>{row.workStartDate}</td>
                                    <td>{row.workEndDate}</td>
                                    <td>{row.enteredBy}</td>
                                    <td>{row.lastUpdated}</td>
                                    <td>
                                      {
                                        TimesheetStatusDisplayValue[
                                          row.caastatus
                                        ]
                                      }
                                    </td>
                                  </tr>
                                ))}
                              </tbody>
                            </Table>
                          )}

                        <div id={`pagination_${tab.key}`}>
                          {tab.key === key && (
                            <AEPPagination
                              fetchItems={fetchTimesheets}
                              setPagination={setTimesheetPagination}
                              pagination={pagination}
                              currentPage={currentPage}
                              sortBy={sortBy}
                              sortOrder={sortOrder}
                            />
                          )}
                        </div>
                      </div>
                    </Tab>
                  ))}
                </Tabs>
              </div>
              <div
                className="btnArea"
                style={{
                  display: 'flex',
                  justifyContent: 'flex-end',
                  padding: '12px 18px',
                }}
              >
                {IsAllowedACL(
                  user,
                  ModuleACL.TIMESHEET,
                  'can_create_timesheet',
                ) && (
                  <Button variant="primary" onClick={handleCreateTimesheet}>
                    Create New Timesheet
                  </Button>
                )}
                {IsAllowedACL(
                  user,
                  ModuleACL.TIMESHEET,
                  'can_import_spreadsheet',
                ) && (
                  <Button variant="secondary" onClick={handleImportTimesheet}>
                    Import Timesheet(s)
                  </Button>
                )}
              </div>
            </div>
          )}
        </>
      )}

      <ClipLoader
        color="#009cde"
        loading={
          timesheetLoading ||
          timesheetListLoading ||
          (entitlementsLoading && !submitPage) ||
          (poLoading && !submitPage)
        }
        cssOverride={{
          top: '40vh',
          left: '50vw',
          position: 'absolute',
          zIndex: 1,
        }}
        size={50}
        aria-label="Loading Spinner"
        data-testid="loader"
      />
    </>
  );
};

export { TimesheetList };
