import React from 'react';

export const HomeIcon = () => {
  return (
    <svg data-name="Home Default" width="24" height="20" viewBox="0 0 24 20">
      <path
        d="m14 6.228 6 5.4V21h-2.4v-7.2h-7.2V21H8v-9.372l6-5.4M14 3 2 13.8h3.6v9.6h7.2v-7.2h2.4v7.2h7.2v-9.6H26"
        transform="translate(-2 -3)"
        style={{ fill: '#4a4a4a' }}
      />
    </svg>
  );
};
