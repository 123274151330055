import { createSlice } from '@reduxjs/toolkit';

const initialState = {
  isLoading: false,
  error: false,
  errorResponse: {},
  items: [],
  pagination: {
    totalItems: 0,
    currentPage: 1,
    itemsPerPage: 10,
    sortBy: '',
    sortOrder: 'asc',
  },
  recordItems: [],
  inputList: [
    {
      FilterValue: '',
      input_rank: null,
      selectFilterValue: '',
      selectFilterLabel: '',
      presentIcon: '',
      filerForm: false,
      FilterLabel: '',
      selectedFilterRecords: [],
    },
  ],
  workAreaList: [],
  workAreaData: {},
  isUpdate: false,
  isStatus: false,
  continueBtn: true,
};

const workAreaSlice = createSlice({
  name: 'workAreaSlice',
  initialState,
  reducers: {
    fetchWorkAreaList(state, action) {
      const stateValues = { ...state };
      stateValues.isLoading = false;
      stateValues.pagination = {
        ...stateValues.pagination,
        ...action.payload,
      };
      return { ...stateValues };
    },
    setInputFilterData(state, action) {
      if (action.payload.length != 0) {
        state.inputList = action.payload;
      } else {
        const stateValues = { ...state };
        stateValues.inputList = [...initialState.inputList];
        return { ...stateValues };
      }
    },
    setAddNewInputFilterData(state, action) {
      const stateValues = { ...state };
      stateValues.inputList = [...stateValues.inputList, action.payload];
      return { ...stateValues };
    },
    fetchSelectedAdminWorkAreaList(state) {
      state.isLoading = false;
    },
    setSelectedAdminWorkAreaRecords(state, action) {
      const selectedAdminFuelRecords = [];
      action.payload?.forEach(obj => {
        let objValues = Object.values(obj);
        selectedAdminFuelRecords.push({
          label: objValues[0],
          value: objValues[0],
        });
      });
      const stateValues = { ...state };
      stateValues.recordItems = [...selectedAdminFuelRecords];
      return { ...stateValues };
    },
    addWorkArea(state, action) {
      state.isLoading = false;
    },
    updateWorkArea(state, action) {
      state.isLoading = false;
    },
    getWorkArea(state, action) {
      state.isLoading = false;
    },
    setWorkArea(state, action) {
      const stateValues = { ...state };
      stateValues.workAreaData = action.payload;
      stateValues.isUpdate = true;
      return { ...stateValues };
    },
    setDefaultValues(state, action) {
      const stateValues = { ...state };
      stateValues.workAreaData = {};
      stateValues.isUpdate = false;
      return { ...stateValues };
    },
    setWorkAreaData(state, action) {
      const WorkAreaList = [];
      action.payload?.rows.forEach(obj => {
        WorkAreaList.push({
          opcostate: obj?.ocNmbrOperatingCompany
            ? `${obj.ocNmbrOperatingCompany.companyAbbr}/${obj?.ocNmbrOperatingCompany?.stateCode}`
            : '',
          description: obj.orgDeptDesc,
          orggroup: obj.orgDeptGroup,
          orgstatus: obj.status,
          orgDeptId: obj.orgDeptId,
        });
      });
      const stateValues = { ...state };
      stateValues.workAreaList = [...WorkAreaList];
      stateValues.workAreaData = {};
      stateValues.isUpdate = false;
      stateValues.pagination = {
        ...stateValues.pagination,
        ...action.payload?.pagination,
      };
      return { ...stateValues };
    },
    setWorkAreaPagination(state, action) {
      const stateValues = { ...state };
      stateValues.pagination = {
        ...stateValues.pagination,
        ...action.payload,
      };
      return { ...stateValues };
    },

    resetWorkArea() {
      return { ...initialState };
    },

    unsetError(state) {
      const stateValues = { ...state };
      stateValues.error = false;
      stateValues.errorResponse = {};
      return { ...stateValues };
    },

    setError(state, action) {
      const stateValues = { ...state };
      stateValues.error = true;
      stateValues.errorResponse = action.payload;
      return { ...stateValues };
    },
  },
});

export const {
  fetchWorkAreaList,
  setInputFilterData,
  setAddNewInputFilterData,
  fetchSelectedAdminWorkAreaList,
  setSelectedAdminWorkAreaRecords,
  addWorkArea,
  updateWorkArea,
  getWorkArea,
  setWorkArea,
  setWorkAreaData,
  setWorkAreaPagination,
  setDefaultValues,
  setError,
  unsetError,
} = workAreaSlice.actions;

export default workAreaSlice.reducer;
