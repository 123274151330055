import React from 'react';

export const Avatar = () => {
  return (
    <svg data-name="Group 14507" width="18" height="18" viewBox="0 0 18 18">
      <path
        data-name="Path 25940"
        d="M11 2a9 9 0 1 0 9 9 9 9 0 0 0-9-9zm0 3.6a3.15 3.15 0 1 1-3.15 3.15A3.153 3.153 0 0 1 11 5.6zm0 12.6a7.207 7.207 0 0 1-5.526-2.592 8.956 8.956 0 0 1 11.052 0A7.207 7.207 0 0 1 11 18.2z"
        transform="translate(-2 -2)"
        style={{ fill: '#1f74b9' }}
      />
    </svg>
  );
};
