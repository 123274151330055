import React from 'react';

export const SearchIcon = () => {
  return (
    <svg width="18" height="18" viewBox="0 0 18 18">
      <path
        data-name="Path 25911"
        d="M16.2 14.614h-.834l-.3-.285a6.882 6.882 0 1 0-.739.739l.285.3v.832l4.488 4.485a1.112 1.112 0 1 0 1.573-1.573zm-6.335 0a4.751 4.751 0 1 1 4.751-4.751 4.745 4.745 0 0 1-4.753 4.751z"
        transform="translate(-2.999 -2.999)"
        style={{ fill: '#fff' }}
      />
    </svg>
  );
};
