import { CaretDown } from 'assets/images/CaretIcon/CaretDown/CaretDown';
import { displaySuccess } from 'components/common/Alert/ToastAlert';
import { AEPFilterComponent } from 'components/common/Filter/AEPFilterComponent';
import { AEPPagination } from 'components/common/Pagination/AEPPagination';
import { PROMISE_TRACKER } from 'constants/promiseTrackers';
import { Page } from 'enums/page.ts';
import _ from 'lodash';
import { useCallback, useEffect, useState } from 'react';
import { Col, Row, Table } from 'react-bootstrap';
import { usePromiseTracker } from 'react-promise-tracker';
import { useDispatch, useSelector } from 'react-redux';
import ClipLoader from 'react-spinners/ClipLoader';
import {
  fetchAdminWorkType,
  fetchSelectedAdminWorkTypetList,
  fetchWorkTypeDetails,
  setAdminWorktypePagination,
  updateWorkTypeData,
} from 'store/slices/AdminModules/workTypeSlice';
import { setWorkTypeFilters } from 'store/slices/filterSlice';
import { setActivePage } from 'store/slices/pageSlice';
import { isEmptyVal } from 'utils/utils';
import './AdminWorkType.scss';
import { AdminWorkTypeModel } from './AdminWorkTypeModel';
const AdminWorkType = props => {
  const dispatch = useDispatch();
  const [item, setItem] = useState({ kindOfSelection: '', another: 'another' });
  const [isChecked, setIsChecked] = useState(false);
  const [workTypeDetails, setWorkTypeDetails] = useState(false);
  const [chipFilterValues, setChipFilterValues] = useState({});
  const [filterChip, setFilterchip] = useState(false);
  const [continueBtnDisable, setContinueBtnDisable] = useState(true);
  const [formValues, setFormValues] = useState([
    { opco: '', projectId: '', workOrder: '' },
  ]);
  const { kindOfSelection } = item;
  const pagination = useSelector(state => state.adminWorkType.pagination);
  const inputListData = useSelector(state => state.filter.worktype);

  const currentPage = useSelector(
    state => state.adminWorkType.pagination.currentPage,
  );
  const sortBy = useSelector(state => state.adminWorkType.pagination.sortBy);
  const sortOrder = useSelector(
    state => state.adminWorkType.pagination.sortOrder,
  );

  const { promiseInProgress: workTypeLoading } = usePromiseTracker({
    area: PROMISE_TRACKER.getWorkType,
    delay: 0,
  });

  const workTypeData = useSelector(state => state.adminWorkType.items);

  const workTypeDetailsData = useSelector(
    state => state.adminWorkType.workTypeData,
  );
  const workTypeSaveStatus = useSelector(state => state.adminWorkType.isStatus);
  const selectedFilterRecordList = useSelector(
    state => state.adminWorkType.recordItems,
  );
  const workTypeFiltersValues = [
    { label: 'Work type', value: 'worktype' },
    { label: 'Account Number', value: 'accountNmbr' },
    { label: 'ABMS Activity', value: 'abmsActivity' },
    { label: 'Work Category', value: 'worktypeCategory' },
    { label: 'Org Group', value: 'orgDeptGroup' },
  ];
  const headersWorkTypes = [
    'Work Type.',
    'Description',
    'Account Number',
    'ABMS Activity',
    'Work Category',
    'Org Group',
    'status',
  ];

  const handleWorkTypeClick = (workTypeDetails, i) => {
    let obj = {};
    let id = workTypeDetails.worktypeNmbr;
    obj[i] = true;
    dispatch(
      fetchWorkTypeDetails({
        id,
        callback: data => {
          setInputs(data);
          if (data.worktypeOcs.length) {
            setFormValues([...data.worktypeOcs]);
          } else {
            setFormValues([{ opco: '', projectId: '', workOrder: '' }]);
          }
          setWorkTypeDetails(true);
        },
      }),
    );
  };
  const handleSorting = (sortBy, sortOrder) => {
    dispatch(
      setAdminWorktypePagination({
        ...pagination,
        currentPage: 1,
        sortBy,
        sortOrder,
      }),
    );
  };

  const [inputs, setInputs] = useState([]);
  const onChangeHandler = useCallback(({ target: { name, value } }) =>
    setInputs(state => ({ ...state, [name]: value }), []),
  );

  const handleChange = e => {
    e.persist();
    setItem(prevState => ({
      ...prevState,
      kindOfSelection: e.target.value,
    }));
  };

  const handleAuthorization = (e, updatedAt) => {
    setIsChecked(true);
  };

  const submitHandler: FormEventHandler = event => {
    event.preventDefault();
    event.persist();
  };

  const handleAdminWorkTypeCancel = () => {
    setWorkTypeDetails(false);
  };
  const handleAdminWorkTypeSave = () => {
    let newworkTypeDetailsDataListCopy = _.cloneDeep(workTypeDetailsData);
    Object.keys(newworkTypeDetailsDataListCopy).map((el, id) => {
      Object.keys(inputs).map(function (item) {
        if (el == item) {
          return (newworkTypeDetailsDataListCopy[el] = inputs[item]);
        }
      });
    });
    newworkTypeDetailsDataListCopy.overrideAuthFlag = isChecked;
    newworkTypeDetailsDataListCopy.stormIndicator = kindOfSelection;
    newworkTypeDetailsDataListCopy.worktypeOcs = formValues;
    delete newworkTypeDetailsDataListCopy.enteredBy;
    delete newworkTypeDetailsDataListCopy.enteredDate;
    delete newworkTypeDetailsDataListCopy.updatedBy;
    delete newworkTypeDetailsDataListCopy.updatedDate;

    dispatch(
      updateWorkTypeData({
        data: newworkTypeDetailsDataListCopy,
        workTypeId: newworkTypeDetailsDataListCopy.worktypeNmbr,
        callback: async data => {
          if (data) {
            displaySuccess('Successfully updated!');
            setWorkTypeDetails(false);
          } else {
            displaySuccess('Failed to update record!');
          }
          dispatch(fetchAdminWorkType({}));
        },
      }),
    );
  };

  const objDecorate = obj => {
    let newObj = _.mapKeys(obj, (value, key) => {
      return key;
    });
    return newObj;
  };

  const handleContinueClick = (data = null) => {
    let inputData = _.cloneDeep(inputListData);
    if (!isEmptyVal(data)) {
      inputData = _.cloneDeep(data);
    }

    let selectedFilterData = {};
    let selectedFilterValue = {};
    let uniqueFilterValues = [];

    inputData.map((el, id) => {
      if (uniqueFilterValues.indexOf(el.FilterValue) === -1) {
        uniqueFilterValues.push(el.FilterValue);
      }
    });

    uniqueFilterValues.map(value => {
      let temp1 = [];
      inputData.map((val, ids) => {
        if (val.FilterValue == value) {
          temp1.push(val.selectFilterValue);
        }
      });
      selectedFilterData[value] = temp1;
    });

    inputData.map((el, id) => {
      Object.keys(selectedFilterData).map(function (item) {
        if (item == el.FilterValue) {
          selectedFilterValue[el.FilterLabel] = selectedFilterData[item];
        }
      });
    });

    const selectedFilterDataDecorated = objDecorate(selectedFilterData);

    if (props.resetFilter) {
      dispatch(
        fetchAdminWorkType({
          currentPage: 1,
          abmsActivity: '',
          accountNmbr: '',
          orgDeptGroup: '',
          worktype: '',
          worktypeCategory: '',
        }),
      );
    } else {
      dispatch(fetchAdminWorkType(selectedFilterDataDecorated));
    }
    console.group({ selectedFilterDataDecorated });
    let vendorSelectFilterLabel = [];
    if (selectedFilterValue.Vendor != undefined) {
      selectedFilterValue.Vendor.map((e, id) => {
        inputData.map((val, ind) => {
          if (val.selectFilterValue == e) {
            vendorSelectFilterLabel.push(val.selectFilterLabel);
          }
        });
        selectedFilterValue.Vendor = vendorSelectFilterLabel;
        return selectedFilterValue.Vendor;
      });
    }

    setChipFilterValues(selectedFilterValue);
    setFilterchip(true);
  };

  const handleFilterclose = type => {
    if (type == 'filterChange') {
      setContinueBtnDisable(false);
    } else if (type == 'deleteEmpty') {
      setContinueBtnDisable(true);
    }
  };

  const handleFilterChip = (chipname, chipValue) => {
    let filterchipData = {};

    Object.keys(chipFilterValues).map(function (item, i) {
      let chipList = [...chipFilterValues[item]];
      chipList.map((val, ids) => {
        if (val == chipValue) {
          chipList.splice(ids, 1);
        }
      });
      chipFilterValues[item] = chipList;
    });

    inputListData.map((el, id) => {
      Object.keys(chipFilterValues).map(function (item) {
        if (item == el.FilterLabel) {
          filterchipData[el.FilterValue] = chipFilterValues[item];
        }
      });
    });

    let indextodelete;
    let newInputList1 = _.cloneDeep(inputListData);
    newInputList1.map((val, ids) => {
      if (val.FilterValue === 'vendor' || val.FilterValue === 'vendorid') {
        if (val.FilterLabel == chipname && val.selectFilterLabel == chipValue) {
          indextodelete = ids;
        }
      } else {
        if (val.FilterLabel == chipname && val.selectFilterValue == chipValue) {
          indextodelete = ids;
        }
      }
    });
    newInputList1.splice(indextodelete, 1);

    if (newInputList1.length == 0) {
      setContinueBtnDisable(true);
    }
    dispatch(setWorkTypeFilters(newInputList1));
    setChipFilterValues(chipFilterValues);

    let vendorSelectFilterValue = [];
    if (filterchipData.vendor != undefined) {
      filterchipData.vendor.map((e, id) => {
        inputListData.map((val, ind) => {
          if (val.selectFilterLabel == e) {
            vendorSelectFilterValue.push(val.selectFilterValue);
          }
        });
        filterchipData.vendor = vendorSelectFilterValue;
        return filterchipData.vendor;
      });
    }

    // Check is using "vendorid"
    const filterchipDataDecorated = objDecorate(filterchipData);
    dispatch(fetchAdminWorkType(filterchipDataDecorated));
  };

  useEffect(() => {
    dispatch(setActivePage(Page.ADMIN_MODULES));
    if (props.resetFilter) {
      props.setResetFilter(false);
      handleContinueClick([
        {
          FilterValue: '',
          input_rank: null,
          selectFilterValue: '',
          selectFilterLabel: '',
          presentIcon: '',
          filerForm: false,
          FilterLabel: '',
          selectedFilterRecords: [],
        },
      ]);
    } else {
      handleContinueClick(inputListData);
    }
  }, [dispatch]);

  const status = [
    { label: 'Active', value: 'A' },
    { label: 'Inactive', value: 'I' },
  ];

  const reliabilityWork = [
    { label: 'Yes', value: 'yes' },
    { label: 'No', value: 'no' },
  ];

  return (
    <>
      <div className="aepContainer adminModuleWorkTypeContainer">
        <div className="tableContainer">
          <Row>
            <Col sm={11}>
              <>
                <div className="filterComponent">
                  <AEPFilterComponent
                    inputListData={inputListData}
                    filterValues={workTypeFiltersValues}
                    setInputFilterData={setWorkTypeFilters}
                    selectedFilterRecordList={selectedFilterRecordList}
                    fetchSelectedList={fetchSelectedAdminWorkTypetList}
                    handleContinueClick={handleContinueClick}
                    continueBtnDisable={continueBtnDisable}
                    handleFilterclose={handleFilterclose}
                    handleFilterChip={handleFilterChip}
                    filterChip={filterChip}
                    chipFilterValues={chipFilterValues}
                  />
                </div>
              </>
            </Col>
          </Row>
          <Table hover responsive striped bordered={false}>
            <thead>
              <tr>
                {headersWorkTypes.map((header, idx) => (
                  <th key={idx}>
                    {header} <CaretDown style={{ marginLeft: '12px' }} />
                  </th>
                ))}
              </tr>
            </thead>
            <tbody>
              {workTypeData !== 'undefined' &&
                !_.isEmpty(workTypeData) &&
                workTypeData.map((row, indx) => (
                  <tr
                    key={indx}
                    className="tRow table-hover-row"
                    onClick={e => handleWorkTypeClick(row, indx)}
                  >
                    <td>{row.worktype}</td>
                    <td>{row.worktypeDesc}</td>
                    <td>{row.accountNmbr}</td>
                    <td>{row.abmsActivity}</td>
                    <td>{row.worktypeCategory}</td>
                    <td>{row.orgDeptGroup}</td>
                    <td>{row.status}</td>
                  </tr>
                ))}
            </tbody>
          </Table>
          <div className="float-right">
            <AEPPagination
              fetchItems={fetchAdminWorkType}
              setPagination={setAdminWorktypePagination}
              pagination={pagination}
              currentPage={currentPage}
              sortBy={sortBy}
              sortOrder={sortOrder}
            />
          </div>
          <AdminWorkTypeModel
            show={workTypeDetails}
            setShowModal={setWorkTypeDetails}
            handleAdminWorkTypeSave={handleAdminWorkTypeSave}
            handleAdminWorkTypeCancel={handleAdminWorkTypeCancel}
            formValues={formValues}
            setFormValues={setFormValues}
            onChangeHandler={onChangeHandler}
            workTypeDetailsData={workTypeDetailsData}
            inputs={inputs}
            reliabilityWork={reliabilityWork}
            status={status}
            kindOfSelection={kindOfSelection}
            isChecked={isChecked}
            submitHandler={submitHandler}
            handleChange={handleChange}
            handleAuthorization={handleAuthorization}
          />
          <ClipLoader
            color="#009cde"
            loading={workTypeLoading}
            cssOverride={{
              top: '50vh',
              left: '50vw',
              position: 'absolute',
              zIndex: 1,
            }}
            size={50}
            aria-label="Loading Spinner"
            data-testid="loader"
          />
        </div>
      </div>
    </>
  );
};
export { AdminWorkType };
