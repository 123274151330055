import { createSlice } from '@reduxjs/toolkit';

const initialState = {
  error: false,
  errorResponse: {
    errorCode: 0,
    errorData: {},
    errorDetails: '',
    errorMessage: '',
  },
  timesheet: { isSuccess: false, isUvlSuccess: false },
};

export const errorSlice = createSlice({
  name: 'errorSlice',
  initialState,
  reducers: {
    setError(state, action) {
      // Set in BaseApi.ts
      if (action.payload?.overlookError) {
        return { ...initialState };
      }

      return {
        error: true,
        errorResponse: {
          errorCode: action.payload?.errorCode,
          errorData: action.payload?.errorData,
          errorDetails: action.payload?.errorDetails,
          errorMessage: action.payload?.errorMessage,
        },
      };
    },

    setErrorWithoutPayload(state, action) {
      state.error = action.payload;
    },

    setTimesheetErrorObj(state, action) {
      const stateValues = { ...state };
      const timesheetValues = { ...state.timesheet };
      stateValues.timesheet = { ...timesheetValues, ...action.payload };
      return { ...stateValues };
    },

    unSetError() {
      return {
        ...initialState,
      };
    },
  },
});

export const {
  setError,
  setErrorWithoutPayload,
  unSetError,
  setTimesheetErrorObj,
} = errorSlice.actions;

export default errorSlice.reducer;
