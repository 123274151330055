// @ts-ignore
import { BackArrowIcon } from 'assets/images/BackArrowIcon/BackArrowIcon';
import { ButtonArea } from 'components/common//Button/ButtonArea/ButtonArea';
import {
  displayError,
  displaySuccess,
} from 'components/common/Alert/ToastAlert';
import { ConfirmationModal } from 'components/common/modals/ConfirmatinModal';
import { ROUTES_STRING } from 'constants/routes';
import { useEffect, useState } from 'react';
import { Col, Row, Tab, Tabs } from 'react-bootstrap';
import 'react-datepicker/dist/react-datepicker.css';
import { useDispatch, useSelector } from 'react-redux';
import { useLocation, useNavigate } from 'react-router-dom';
import {
  fetchAuditors,
  fetchCircuits,
  fetchContractors,
  postAudit,
  setSavedAudit,
  setSavedAuditData,
  updateAudit,
  setAuditData,
} from 'store/slices/AuditModules/auditSlice';
import './AuditDetails.scss';
import { ClearanceAudits } from './ClearanceAudits';
import { CrewAcceptable } from './CrewAcceptable';
import { CrewAudits } from './CrewAudits';
import { CrewSafetyAudits } from './CrewSafetyAudits';
import { QualityAudits } from './QualityAudits';
import { CrewAuditItems } from './CrewAuditItems';
import { ReportingAudit } from './ReportingAudit';

const AuditDetails = () => {
  const [leavePage, setLeavePage] = useState(false);
  const [key, setKey] = useState('crewAudits');
  const dispatch = useDispatch();
  const navigate = useNavigate();
  const location = useLocation();

  const auditData = useSelector(state => state.audit.auditFormData);
  const savedAuditData = useSelector(state => state.audit.savedAuditData);
  const audit = useSelector(state => state.audit);

  const handleSaveAudit = async () => {
    if (!auditData?.auditNmbr) {
      displayError('Audit number is missing');
    } else if (isNaN(auditData?.auditNmbr)) {
      displayError('Audit number must be Numeric');
    } else if (parseInt(auditData?.auditNmbr?.length) > 10) {
      displayError('Audit number must be less than or equal to 10 chars');
    } else if (
      auditData?.crewNmbr &&
      parseInt(auditData?.crewNmbr?.length) > 3
    ) {
      displayError('Crew number must be less than or equal to three chars.');
    } else if (
      auditData?.crewType &&
      parseInt(auditData?.crewType?.length) > 4
    ) {
      displayError('Crew type must be less than or equal to four chars.');
    } else if (auditData?.recordNmbr && isNaN(auditData?.recordNmbr)) {
      displayError('Timesheet number must be Numeric');
    } else if (parseInt(auditData?.recordNmbr?.length) > 10) {
      displayError('Timesheet number must be less than or equal to 10 chars');
    } else {
      if (location.pathname.includes('new')) {
        try {
          let res = await dispatch(
            postAudit({
              data: auditData,
              callback: async data => {
                if (data?.error) {
                  displayError('Audit Creation Failed, Please try again.');
                } else {
                  navigate(`/${ROUTES_STRING.audit}/${data.seqAuditRecord}`);
                  displaySuccess('Successfully Saved the Audit Form');
                  dispatch(setAuditData(data));
                }
              },
            }),
          );
        } catch (err) {}
      } else {
        try {
          let res = await dispatch(
            updateAudit({
              data: auditData,
              callback: async data => {
                if (data?.error) {
                  displayError('Audit Creation Failed, Please try again.');
                } else {
                  navigate(`/${ROUTES_STRING.audit}/${data.seqAuditRecord}`);
                  displaySuccess('Successfully updated the Audit Form');
                  dispatch(setAuditData(data));
                }
              },
            }),
          );
        } catch (err) {}
        setTimeout(
          () =>
            navigate(`/${ROUTES_STRING.audit}/${auditData?.seqAuditRecord}`),
          100,
        );
      }
    }
  };

  const handleContinueClick = () => {
    navigate('/audit');
    setLeavePage(false);
  };

  useEffect(() => {
    dispatch(setSavedAudit(false));
    dispatch(setSavedAuditData({}));
    dispatch(fetchContractors());
    dispatch(fetchAuditors());
    dispatch(fetchCircuits());
  }, []);

  const tabs = [
    { key: 'crewAudits', value: 'Crew Audits' },
    { key: 'crewSafetyAudits', value: 'Crew Safety Audits' },
    { key: 'crewAcceptable', value: 'Acceptable/UnAcceptable' },
    { key: 'clearanceAudits', value: 'Clearance Audits' },
    { key: 'qualityAudits', value: 'Quality Audits' },
    { key: 'reportingAccuracyAudit', value: 'Reporting Accuracy Audit' },
    { key: 'crewAuditItems', value: 'Crew Audit Items' },
  ];

  const handleBackArrow = () => {
    navigate(`/${ROUTES_STRING.audit}`);
  };

  return (
    <>
      <div className="aepContainer aepHeader">Audit</div>
      <div className="aepContainer aepBreadcrumb">
        <Row>
          <Col sm={12} style={{ display: 'flex' }}>
            <div
              className="backArrow"
              onClick={() => handleBackArrow()}
              title="Back"
            >
              <BackArrowIcon />
            </div>
            Audit No
            <span>:{auditData?.auditNmbr}</span>
          </Col>
        </Row>
      </div>

      <div className="aepContainer">
        <Tabs id="auditDetailsPage" activeKey={key} onSelect={k => setKey(k)}>
          {tabs?.map((tab, index) => (
            <Tab
              eventKey={tab.key}
              title={tab.value}
              key={index}
              tabClassName="tab-item"
            >
              <div className="tableContainer">
                {tab.key === 'crewAudits' && <CrewAudits />}
                {tab.key === 'crewSafetyAudits' && <CrewSafetyAudits />}
                {tab.key === 'crewAcceptable' && <CrewAcceptable />}
                {tab.key === 'clearanceAudits' && <ClearanceAudits />}
                {tab.key === 'qualityAudits' && <QualityAudits />}
                {tab.key === 'reportingAccuracyAudit' && <ReportingAudit />}
                {tab.key === 'crewAuditItems' && <CrewAuditItems />}
              </div>
            </Tab>
          ))}
        </Tabs>
      </div>
      <div className="submitButtonDiv">
        <ButtonArea
          type="button"
          primaryLabel="Save"
          secondaryLabel="Cancel"
          primaryOnClick={handleSaveAudit}
          secondaryOnClick={() => setLeavePage(true)}
        />
      </div>
      <ConfirmationModal
        showModal={leavePage}
        setShowModal={setLeavePage}
        handleContinueClick={() => handleContinueClick()}
        title="Cancel Audit Form?"
        body="Do you want to cancel Audit Form updates?"
      />
    </>
  );
};

export { AuditDetails };
