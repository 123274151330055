import react from 'react';

export const PDFIcon = () => {
  return (
    <>
      <svg
        xmlns="http://www.w3.org/2000/svg"
        width="25.802"
        height="28.836"
        viewBox="0 0 25.802 28.836"
      >
        <path d="M22.949 0H9.672a.709.709 0 0 0-.481.21L.18 10.093a.611.611 0 0 0-.18.451v15.529a2.815 2.815 0 0 0 2.854 2.763h20.1a2.815 2.815 0 0 0 2.846-2.763V2.763A2.8 2.8 0 0 0 22.949 0zm1.532 26.1a1.478 1.478 0 0 1-1.532 1.412H2.854A1.478 1.478 0 0 1 1.322 26.1V10.813l8.621-9.462h13.006a1.478 1.478 0 0 1 1.532 1.412V26.1zM7.209 11.564H3.334a.661.661 0 0 1 0-1.322h3.875a2.327 2.327 0 0 0 2.373-2.313V3.274a.661.661 0 0 1 1.322 0V7.9a3.666 3.666 0 0 1-3.695 3.664zm11.384 6.218a27.483 27.483 0 0 1-4.626-5.437c.3-1.081.481-1.8.481-1.832 0-.06.03-.09.03-.15 0-2.043-1.2-2.163-1.442-2.163a1.4 1.4 0 0 0-1.141.631c-.511.781-.3 1.982.631 3.665a27.64 27.64 0 0 1-2.914 6.939 15.114 15.114 0 0 0-1.862.811c-1.322.631-2.553 1.352-2.7 2.283a1.2 1.2 0 0 0 .39 1.051 1.343 1.343 0 0 0 .931.36c1.141 0 2.4-1.322 3.574-2.733l.15-.18.36-.451a29.591 29.591 0 0 1 6.606-1.476 5.459 5.459 0 0 1 1.021 0c1.952 1.682 2.733 1.682 2.974 1.682a1.057 1.057 0 0 0 .841-.36 1.212 1.212 0 0 0 .3-1.352c-.119-.297-.45-1.108-3.604-1.288zM6.458 22.588a3.531 3.531 0 0 1 1.051-.721c-.54.511-.841.691-1.051.721zm6.518-13.006a1.46 1.46 0 0 1 .12.691 1.351 1.351 0 0 1-.06.24 1.727 1.727 0 0 1-.06-.931zm-1.442 9.252a32.727 32.727 0 0 0 1.922-4.806 31.124 31.124 0 0 0 3.214 3.785 26.354 26.354 0 0 0-5.136 1.02zm9.522 1.266v-.661z" />
      </svg>
    </>
  );
};
