import { useState } from 'react';
import { Button, Col, Row } from 'react-bootstrap';
import { useDispatch, useSelector } from 'react-redux';
import { useNavigate } from 'react-router-dom';
import {
  approveVendorSubmitionForm,
  setLoading,
  setApprovedButtonStatus,
} from 'store/slices/purchaseOrder/purchaseOrderDetailsSlice';
import { IsAllowedACL } from 'utils/aclHelper';
import { ModuleACL, UserType } from 'enums/entitlements.ts';
import { ConfirmationModal } from 'components/common/modals/ConfirmatinModal';
import './AffidavitForm.scss';
import ClipLoader from 'react-spinners/ClipLoader';
import { PurchaseOrderStatus } from 'enums/statuses.ts';

const AffidavitComponent = props => {
  const { onClickClose } = props;
  const user = useSelector(state => state.auth);

  const isLoading = useSelector(state => state.purchaseOrderDetails.isLoading);
  const caaStatus = useSelector(state => state.purchaseOrderDetails.status);
  const userRole = user.configObject.userrole;
  const dispatch = useDispatch();
  const navigate = useNavigate();

  const [showRequestModal, setShowRequestModal] = useState(false);
  const affidavitResponse = useSelector(state => state.purchaseOrderDetails);
  const affidavitData = affidavitResponse.completionFormData;

  const [formData, setFormData] = useState({
    state: '',
    county: '',
    affiantName: '',
    affiantOffice: '',
    legalNameOfContractor: '',
    legalNameOfOwner: '',
    contractDate: '',
    contractNo: '',
    projectName: '',
    city: '',
  });

  const handleChange = e => {
    setFormData({ ...formData, [e.target.name]: e.target.value });
  };

  const handleSubmitData = event => {
    event.preventDefault();
    const jsonObj = [formData];
    onClickClose && onClickClose(jsonObj);
  };

  const handleApproveVendorCompletion = () => {
    setShowRequestModal(false);
    dispatch(setLoading(true));
    dispatch(setApprovedButtonStatus('C'));
    try {
      dispatch(approveVendorSubmitionForm({ poId: affidavitResponse.poid }));
    } catch (err) {
      console.log('err', err);
    }
  };

  return (
    <>
      {isLoading ? (
        <ClipLoader
          color="#009cde"
          cssOverride={{
            top: '50vh',
            left: '50vw',
            position: 'absolute',
            zIndex: 1,
          }}
          size={50}
          aria-label="Loading Spinner"
          data-testid="loader"
        />
      ) : (
        <form onSubmit={handleSubmitData}>
          <div className="form ">
            <div className="tableContainer">
              <Row>
                <Col sm={12} style={{ textAlign: 'center' }}>
                  <div className="heading">AFFIDAVIT OF COMPLETION</div>
                </Col>
              </Row>

              <Row>
                <div className="static-text">State of</div>
                <div className="common">
                  <input
                    type="text"
                    className="text"
                    name="state"
                    value={
                      affidavitData === null
                        ? formData.state
                        : affidavitData.state
                    }
                    onChange={handleChange}
                  />
                </div>
              </Row>

              <Row>
                <div className="static-text">County of</div>
                <div className="common">
                  <input
                    type="text"
                    className="text"
                    name="county"
                    value={
                      affidavitData === null
                        ? formData.county
                        : affidavitData.county
                    }
                    onChange={handleChange}
                  />
                </div>
              </Row>

              <Row>
                <div
                  className="common"
                  style={{
                    width: '62%',
                  }}
                >
                  <input
                    type="text"
                    placeholder="(Name of Affiant)"
                    className="text"
                    name="affiantName"
                    value={
                      affidavitData === null
                        ? formData.affiantName
                        : affidavitData.nm_affiant
                    }
                    onChange={handleChange}
                  />
                </div>
                <div className="static-text">
                  being duly sworn, states that:
                </div>
              </Row>

              <Row>
                <div>
                  <div className="static-text">1. S/He is the</div>
                </div>
                <div
                  className="common"
                  style={{
                    width: '77%',
                  }}
                >
                  <input
                    type="text"
                    placeholder="(Office held by Affiant)"
                    className="text"
                    name="affiantOffice"
                    value={
                      affidavitData === null
                        ? formData.affiantOffice
                        : affidavitData.office_held_affiant
                    }
                    onChange={handleChange}
                  />
                </div>
                <div className="static-text">of</div>
              </Row>

              <Row>
                <div
                  className="common"
                  style={{
                    width: '81%',
                  }}
                >
                  <input
                    type="text"
                    placeholder="(Legal Name of Contractor)"
                    className="text"
                    name="legalNameOfContractor"
                    value={
                      affidavitData === null
                        ? formData.legalNameOfContractor
                        : affidavitData.legal_nm_contractor
                    }
                    onChange={handleChange}
                  />
                </div>
                <div className="static-text">(Contractor)</div>
              </Row>

              <Row>
                <div className="static-text">that has a contract with</div>
                <div
                  className="common"
                  style={{
                    width: '69%',
                  }}
                >
                  <input
                    type="text"
                    placeholder="(Legal Name of Owner)"
                    className="text"
                    name="legalNameOfOwner"
                    value={
                      affidavitData === null
                        ? formData.legalNameOfOwner
                        : affidavitData.legal_nm_owner
                    }
                    onChange={handleChange}
                  />
                </div>
              </Row>

              <Row>
                <div className="static-text">(Owner) dated</div>
                <div
                  className="common"
                  style={{
                    width: '25%',
                  }}
                >
                  <input
                    type="text"
                    placeholder="(Contract Date)"
                    className="text"
                    name="contractDate"
                    value={
                      affidavitData === null
                        ? formData.contractDate
                        : affidavitData.contract_date
                    }
                    onChange={handleChange}
                  />
                </div>
                <div className="static-text">(Owner’s) Contract No.</div>
                <div
                  className="common"
                  style={{
                    width: '25%',
                  }}
                >
                  <input
                    type="text"
                    placeholder="(Contract No.)"
                    className="text"
                    name="contractNo"
                    value={
                      affidavitData === null
                        ? formData.contractNo
                        : affidavitData.contract_no
                    }
                    onChange={handleChange}
                  />
                </div>
              </Row>

              <Row>
                <div className="static-text">
                  involving work on the Owner’s property at
                </div>
                <div
                  className="common"
                  style={{
                    width: '49%',
                  }}
                >
                  <input
                    type="text"
                    placeholder="(Project Name)"
                    className="text"
                    name="projectName"
                    value={
                      affidavitData === null
                        ? formData.projectName
                        : affidavitData.proj_nm
                    }
                    onChange={handleChange}
                  />
                </div>
              </Row>

              <Row>
                <div className="static-text">located near</div>
                <div
                  className="common"
                  style={{
                    width: '81%',
                  }}
                >
                  <input
                    type="text"
                    placeholder="(City, State)"
                    className="text"
                    name="city"
                    value={
                      affidavitData === null
                        ? formData.city
                        : affidavitData.city
                    }
                    onChange={handleChange}
                  />
                </div>
              </Row>

              <Row>
                <Col sm={12}>
                  <p className="static-text">
                    2. All of the Work required to be performed by the
                    Contractor under said Contract has been performed. All bills
                    and claims for material, labor and services to employees and
                    Subcontractors covering the Work required to be performed
                    under the Contract, have been paid in full by the
                    Contractor. There are no unpaid amounts on the basis of
                    which a lien has been filed, or can be filed, in connection
                    with the Work performed under the Contract.
                  </p>
                  <p className="static-text">
                    By signing, contractor/vendor is acknowledging this will
                    initiate the Purchase Order (PO) closing process and no
                    further invoices will be applied to this PO.
                  </p>
                </Col>
              </Row>
              <Row>
                <Col sm={6}></Col>
                <Col sm={6}>
                  <div
                    className="common"
                    style={{
                      width: '90%',
                      borderBottom: '1px solid #9e9fa1',
                    }}
                  >
                    {affidavitData !== null && (
                      <img
                        src={affidavitData.esign}
                        alt="signature"
                        style={{ marginBottom: '-25px' }}
                      />
                    )}
                  </div>
                </Col>
              </Row>
              <Row>
                <Col sm={6}></Col>
                <Col sm={6} style={{ textAlign: 'center', marginTop: '-23px' }}>
                  <div className="static-text">Signature of Affiant</div>
                </Col>
              </Row>
            </div>
            <Row>
              <Col sm={3}></Col>
              <Col sm={9} className="buttonForm">
                {user.userType === UserType.VENDOR &&
                  [
                    PurchaseOrderStatus.SUBMIT_VENDOR_COMPLETION_FORM,
                    PurchaseOrderStatus.APPROVE_VENDOR_COMPLETION_FORM,
                    PurchaseOrderStatus.REQUEST_VENDOR_COMPLETION_FORM,
                  ].includes(caaStatus) && (
                    <Button type="submit">Submit Completion Form</Button>
                  )}
                {IsAllowedACL(
                  user,
                  ModuleACL.PURCHASEORDER,
                  'can_approve_completion_form',
                ) &&
                  user.userType !== UserType.VENDOR &&
                  caaStatus ===
                    PurchaseOrderStatus.SUBMIT_VENDOR_COMPLETION_FORM && (
                    <Button
                      type="submit"
                      style={{ width: 'auto' }}
                      onClick={() => setShowRequestModal(true)}
                    >
                      Approve Completion Form
                    </Button>
                  )}
              </Col>
            </Row>
          </div>
        </form>
      )}
      <ConfirmationModal
        showModal={showRequestModal}
        setShowModal={setShowRequestModal}
        handleContinueClick={() => handleApproveVendorCompletion()}
        title="Approve Purchase Order Completion Form"
        body="Are you sure you want to approve Purchase Order Completion Form?"
      />
    </>
  );
};

export default AffidavitComponent;
