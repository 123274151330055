import { Form, Modal } from 'react-bootstrap';
import { ButtonArea } from 'components/common//Button/ButtonArea/ButtonArea';
import { FormProvider } from 'react-hook-form';
import { Row, Col } from 'react-bootstrap/esm';
import { useSelector } from 'react-redux';
import { useState } from 'react';
import _ from 'lodash';

const AddNewDistrictWorkAreaModel = ({
  show,
  setShowModal,
  handleAddWorkArea,
  handleUpdateWorkArea,
  handleCancel,
  workAreaData,
  handleSubmit,
  getValues,
  setValue,
  watch,
  trigger,
  reset,
  methods,
}) => {
  const workAreaList = useSelector(
    state => state.districtWorkArea.workAreaList,
  );
  const isUpdate = useSelector(state => state.districtWorkArea.isUpdate);

  const [values, setValues] = useState({});
  const {
    formState: { errors },
  } = methods;

  const allFormFields = watch();

  const onSubmit = data => {
    if (isUpdate) {
      handleUpdateWorkArea(data);
    } else {
      handleAddWorkArea(data);
    }
    reset();
  };
  const onError = data => {
    console.log('error', data);
  };

  return (
    <Modal
      show={show}
      onHide={() => setShowModal(false)}
      size="lg"
      aria-labelledby="contained-modal-title-vcenter"
      centered
    >
      <div className="tableContainer">
        <Modal.Body>
          <FormProvider {...methods}>
            <Form>
              <Row>
                <Col sm={6}>
                  <Form.Group>
                    <Form.Label>District Work Area Code:</Form.Label>
                    <Form.Control
                      onChange={e => {
                        setValue('cdDist', e.target.value);
                        trigger('cdDist');
                      }}
                      type="text"
                      value={getValues('cdDist') || workAreaData?.cdDist || ''}
                    />
                    <div className="errorMessage">
                      {errors?.['cdDist']?.['message']}
                    </div>
                  </Form.Group>
                </Col>
                <Col sm={6}>
                  <Form.Group>
                    <Form.Label>District Work Area Description:</Form.Label>
                    <Form.Control
                      onChange={e => {
                        setValue('dsDist', e.target.value);
                        trigger('dsDist');
                      }}
                      type="text"
                      value={getValues('dsDist') || workAreaData?.dsDist || ''}
                    />
                    <div className="errorMessage">
                      {errors?.['dsDist']?.['message']}
                    </div>
                  </Form.Group>
                </Col>
              </Row>
              <Row>
                <Col sm={6}>
                  <Form.Group>
                    <Form.Label>Work Area:</Form.Label>
                    <Form.Select
                      value={
                        getValues('workAreaId') ||
                        workAreaData?.workAreaId ||
                        ''
                      }
                      onChange={e => {
                        setValue('workAreaId', e.target.value);
                        trigger('workAreaId');
                      }}
                      name="workAreaId"
                    >
                      <option value="">Select</option>
                      {workAreaList?.map((obj, index) => (
                        <option key={index} value={obj.workAreaId}>
                          {obj.workAreaDesc}
                        </option>
                      ))}
                    </Form.Select>
                    <div className="errorMessage">
                      {errors?.['workAreaId']?.['message']}
                    </div>
                  </Form.Group>
                </Col>
                <Col sm={6}>
                  <Form.Group>
                    <Form.Label>Field Status:</Form.Label>
                    <Form.Select
                      value={
                        getValues('fgActive') || workAreaData?.fgActive || ''
                      }
                      onChange={e => {
                        setValue('fgActive', e.target.value);
                        trigger('fgActive');
                      }}
                      name="fgActive"
                    >
                      <option value="">Select</option>
                      <option value="Y">Yes</option>
                      <option value="N">No</option>
                    </Form.Select>
                    <div className="errorMessage">
                      {errors?.['fgActive']?.['message']}
                    </div>
                  </Form.Group>
                </Col>
              </Row>
            </Form>
          </FormProvider>
        </Modal.Body>
      </div>
      <Modal.Footer>
        <ButtonArea
          primaryLabel="Save"
          secondaryLabel="Cancel"
          primaryOnClick={handleSubmit(onSubmit, onError)}
          secondaryOnClick={handleCancel}
        />
      </Modal.Footer>
    </Modal>
  );
};

export { AddNewDistrictWorkAreaModel };
