import { createSlice } from '@reduxjs/toolkit';

const initialState = {
  cuList: [],
  contractorList: [],
  companyList: [],
  districtList: [],
  pagination: {
    totalItems: 0,
    currentPage: 1,
    itemsPerPage: 10,
    sortBy: '',
    sortOrder: 'asc',
    contractor: '',
    company: '',
    district: '',
    workOrder: '',
    workRequest: '',
  },
};

export const cuAdditionalLinkSlice = createSlice({
  name: 'cuAdditionalLinkSlice',
  initialState,
  reducers: {
    fetchCu(state, action) {
      const stateValues = { ...state };
      stateValues.pagination = {
        ...stateValues.pagination,
        ...action.payload?.data,
      };
      return { ...stateValues };
    },
    fetchContractors(state) {},
    fetchCompanies(state) {},
    fetchDistricts(state) {},
    downloadCu(state) {},

    setCu(state, action) {
      const stateValues = { ...state };
      stateValues.cuList = action.payload.rows;
      stateValues.pagination = {
        ...stateValues.pagination,
        ...action.payload?.pagination,
      };
      return { ...stateValues };
    },

    setContractors(state, action) {
      const stateValues = { ...state };
      stateValues.contractorList = action.payload;
      return { ...stateValues };
    },

    setCompanies(state, action) {
      const stateValues = { ...state };
      stateValues.companyList = action.payload;
      return { ...stateValues };
    },

    setDistricts(state, action) {
      const stateValues = { ...state };
      stateValues.districtList = action.payload;
      return { ...stateValues };
    },

    setCuPagination(state, action) {
      const stateValues = { ...state };
      stateValues.pagination = { ...stateValues.pagination, ...action.payload };
      return { ...stateValues };
    },

    resetCuDownload(state) {
      const stateValues = { ...state };
      stateValues.cuList = [];
      return { ...stateValues };
    },
  },
});

export const {
  fetchCu,
  fetchContractors,
  fetchCompanies,
  fetchDistricts,
  setCu,
  setContractors,
  setCompanies,
  setDistricts,
  setCuPagination,
  resetCuDownload,
} = cuAdditionalLinkSlice.actions;

export default cuAdditionalLinkSlice.reducer;
