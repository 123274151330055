import { Form, Modal } from 'react-bootstrap';
import { ButtonArea } from 'components/common//Button/ButtonArea/ButtonArea';
import { Divider } from 'components/common/Divider/Divider';
import { Row, Col } from 'react-bootstrap/esm';
import { useDispatch, useSelector } from 'react-redux';
import ReactSelect from 'react-select';
import { Fragment, useEffect, useState } from 'react';
import _ from 'lodash';
import './AdminVendor.scss';
import { fetchVendorcompanyList } from 'store/slices/AdminModules/vendorSlice';
import { isEmptyVal } from 'utils/utils';

const EditVendorModal = ({
  show,
  setShowModal,
  selectedList,
  companyList,
  defaultCompanyList,
  handleContinueClick,
}) => {
  const dispatch = useDispatch();
  const [value, setValue] = useState();
  const [values, setValues] = useState({});
  const [defaultVendorCompany, setDefaultvendorcompany] = useState();
  const [selectedOption, setSelectedOption] = useState('');
  const [selectedVendorOption, setSelectedVendorOption] = useState('');
  const [continueBtn, setContinueBtn] = useState(true);
  const [getCompanyList, setCompanyList] = useState(companyList);

  var handleChange = selectedOption => {
    let selectedFinal = [];
    selectedOption.map((el, id) => {
      selectedFinal.push(el.value);
    });
    let continueDisable = selectedOption.length >= 0 ? false : true;
    setContinueBtn(continueDisable);
    setSelectedVendorOption(selectedFinal);
    setSelectedOption(selectedOption.value);
  };

  const handleInputChange = value => {
    if (!isEmptyVal(value)) {
      dispatch(
        fetchVendorcompanyList({
          search: value,
          callback: respData => {
            let vendorKeyChange = [];
            respData?.map(item => {
              if (
                item.vendor !== selectedList.vendorCompanyDetailDefault.vendor
              ) {
                vendorKeyChange.push({
                  label: `${item.vendor} - ${item.vendorName}`,
                  value: `${item.vendor} - ${item.vendorName}`,
                });
              }
            });
            setCompanyList(vendorKeyChange);
          },
        }),
      );
    }
  };

  const debouncedInputChange = _.debounce(handleInputChange, 500);

  return (
    <Modal
      show={show}
      onHide={() => setShowModal(false)}
      size="lg"
      aria-labelledby="contained-modal-title-vcenter"
      centered
      className="editVendorModal"
    >
      <Modal.Header closeButton="true">
        <Modal.Title>Edit Vendor User</Modal.Title>
      </Modal.Header>
      <Divider />
      <Modal.Body>
        <Form className="editVendorModalBody">
          <Row>
            <Col sm={6}>
              <Form.Group className="mb-3" controlId="editPoContactName">
                <Form.Label>Vendor User</Form.Label>
                <Form.Control
                  type="text"
                  name="name"
                  defaultValue={selectedList.vendorName}
                  disabled={true}
                  value={value}
                />
              </Form.Group>
            </Col>
          </Row>
          <Row>
            <Col sm={6}>
              <Form.Group controlId="editPoContactName">
                <Form.Label>Vendor Company</Form.Label>
                <Form.Control
                  type="text"
                  name="name"
                  defaultValue={
                    selectedList.vendorCompanyDetailDefault.vendorName
                  }
                  //   onChange={event => handleClick(event)}
                  disabled={true}
                  value={value}
                />
              </Form.Group>
            </Col>
          </Row>
          <Row>
            <Col sm={6} style={{ marginTop: '15px' }}>
              <Form.Group controlId="editPoContactName">
                <Form.Label>Additional Vendor Company</Form.Label>
                <ReactSelect
                  defaultValue={defaultCompanyList}
                  isClearable={false}
                  isMulti
                  name="colors"
                  options={getCompanyList}
                  style={{ minHeight: '45px' }}
                  onChange={handleChange}
                  onInputChange={e => debouncedInputChange(e)}
                />
              </Form.Group>
            </Col>
          </Row>
        </Form>
      </Modal.Body>
      <Modal.Footer>
        <ButtonArea
          primaryLabel="Continue"
          secondaryLabel="Cancel"
          primaryOnClick={() => handleContinueClick(selectedVendorOption)}
          secondaryOnClick={() => setShowModal(false)}
          primaryDisabled={continueBtn}
        />
      </Modal.Footer>
    </Modal>
  );
};

export { EditVendorModal };
