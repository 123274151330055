import _ from 'lodash';
import * as Yup from 'yup';

export const generateSchema = () => {
  return Yup.object().shape({
    cdDist: Yup.string().required('District Work Area Code is required field'),
    dsDist: Yup.string().required(
      'District Work Area Description is required field',
    ),
    workAreaId: Yup.string().required('Work Area is required field'),
    fgActive: Yup.string().required('Field Status is required field'),
  });
};
