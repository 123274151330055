import React from 'react';

export const InvoiceIcon = () => {
  return (
    <svg
      data-name="Invoices Default"
      width="18"
      height="20"
      viewBox="0 0 18 20"
    >
      <g data-name="Group 12494">
        <path
          data-name="Path 25900"
          d="M19.5 3.5 18 2l-1.5 1.5L15 2l-1.5 1.5L12 2l-1.5 1.5L9 2 7.5 3.5 6 2v14H3v3a3 3 0 0 0 3 3h12a3 3 0 0 0 3-3V2zM15 20H6a1 1 0 0 1-1-1v-1h10zm4-1a1 1 0 0 1-2 0v-3H8V5h11z"
          style={{ fill: '#4a4a4a' }}
          transform="translate(-3 -2)"
        />
        <path
          data-name="Rectangle 5460"
          transform="translate(6 5)"
          style={{ fill: '#4a4a4a' }}
          d="M0 0h6v2H0z"
        />
        <path
          data-name="Rectangle 5461"
          transform="translate(13 5)"
          style={{ fill: '#4a4a4a' }}
          d="M0 0h2v2H0z"
        />
        <path
          data-name="Rectangle 5462"
          transform="translate(6 8)"
          style={{ fill: '#4a4a4a' }}
          d="M0 0h6v2H0z"
        />
        <path
          data-name="Rectangle 5463"
          transform="translate(13 8)"
          style={{ fill: '#4a4a4a' }}
          d="M0 0h2v2H0z"
        />
      </g>
    </svg>
  );
};
