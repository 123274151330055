import queryString from 'query-string';
import { http } from 'services/helpers/BaseApi.ts';

const retrieveUser = async () => {
  try {
    return await http.get('/users/entitlements');
  } catch (error) {
    return error;
  }
};

const retrieveUserEntitlements = async queryParams => {
  try {
    return await http.get(`/users?${queryString.stringify(queryParams)}`);
  } catch (error) {
    return error;
  }
};

const retrieveDashboardCount = async () => {
  try {
    return await http.get('/users/dashboard-counts');
  } catch (error) {
    return error;
  }
};

export { retrieveUser, retrieveUserEntitlements, retrieveDashboardCount };
