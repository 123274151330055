import { ModuleACL } from 'enums/entitlements.ts';
import _ from 'lodash';
import { all, call, put, select, takeLatest } from 'redux-saga/effects';
import * as service from 'services/purchaseOrderService';
import {
  fetchPurchaseOrders,
  fetchSelectedPurchaseOrderList,
  setPurchaseOrders,
  setSelectedPurchsaeOrderRecords,
  setError,
  setIsLoading,
} from 'store/slices/purchaseOrder/purchaseOrderSlice';
import { displayError } from 'components/common/Alert/ToastAlert';
import { getStatusByACL } from 'utils/aclHelper';
import { isEmptyVal } from 'utils/utils';

const userSelector = state => state.auth;
const poPaginationSelector = state => state.purchaseOrder.pagination;

function* getPurchaseOrders(action) {
  let queryParams = _.cloneDeep(yield select(poPaginationSelector));
  if (action?.payload?.hasOwnProperty('search')) {
    queryParams['search'] = action?.payload?.search;
  } else {
    try {
      delete queryParams['search'];
    } catch (e) {}
  }

  try {
    // Add permissions by status of entity
    let user = _.cloneDeep(yield select(userSelector));
    if (_.isEmpty(queryParams['caastatus'])) {
      const status = getStatusByACL(user, ModuleACL.PURCHASEORDER);
      if (!_.isEmpty(status)) {
        queryParams['caastatus'] = status;
      }
    }

    queryParams = checkStatusPermission(queryParams);
    const response = yield call(
      f => service.retrievePurchaseOrders(queryParams),
      action.payload,
    );
    const { data } = response;
    data.pagination = { totalItems: data?.count };

    yield put(setPurchaseOrders(data));

    if (!isEmptyVal(action?.payload?.callback)) {
      const poList = [];
      data?.rows.forEach(obj => {
        poList.push({
          poId: obj.poid,
          contractNumber: obj.contractrefnum,
          poNumber: obj.ponum,
          vendor: obj.vendorVendor?.vendor_name,
          startDate: obj.startdate,
          endDate: obj.enddate,
          status: obj.caastatus,
        });
      });
      action.payload.callback(poList);
    }
  } catch (e) {
    displayError(
      'We are experiencing technical difficulties. Please come back shortly to try again !',
    );
    yield put(setError(true));
  } finally {
    yield put(setError(true));
    yield put(setIsLoading(false));
  }
}

function* getSelectedPurchaseOrderList(action) {
  try {
    let user = _.cloneDeep(yield select(userSelector));
    const columnName = action.payload;

    // For retrieve vendor data is necessary add logic to get only allowed.
    if (
      columnName.columnname == 'vendorid' ||
      columnName.columnname == 'vendor'
    ) {
      const userVendors = user?.configObject?.allowedvendor;
      if (userVendors && !_.isEmpty(userVendors)) {
        action.payload.vendor = userVendors;
      }
    }

    const response = yield call(
      f => service.retreiveSelectedPurchaseOrderList(action.payload),
      action.payload,
    );
    const { data } = response;

    if (columnName.columnname == 'vendorid') {
      const dataDecorated = decorateDataByVendor(data);
      yield put(setSelectedPurchsaeOrderRecords(dataDecorated));
    } else {
      yield put(setSelectedPurchsaeOrderRecords(data));
    }
  } catch (e) {
    displayError(
      'We are experiencing technical difficulties. Please come back shortly to try again !',
    );
    put(setError(true));
  }
}

const checkStatusPermission = queryParams => {
  let queryParamsPermission = queryParams;
  if (_.isEmpty(queryParams['caastatus'])) {
  }
  return queryParamsPermission;
};

/**
 * Decorate data for vendorid
 * @param {*} data
 * @returns
 */
function decorateDataByVendor(data) {
  let dataDecorated = [];
  if (data.length > 0) {
    data.forEach(obj => {
      let objValues = Object.values(obj);
      dataDecorated.push({
        vendorid: objValues[1],
        vendorName: objValues[0],
      });
    });
  }
  // Return
  return dataDecorated;
}

function* watchFetchSelectedPurchaseOrderList() {
  yield takeLatest(
    fetchSelectedPurchaseOrderList.type,
    getSelectedPurchaseOrderList,
  );
}

function* watchFetchPurchaseOrders() {
  yield takeLatest(fetchPurchaseOrders.type, getPurchaseOrders);
}

export function* purchaseOrderSaga() {
  yield all([
    watchFetchPurchaseOrders(),
    watchFetchSelectedPurchaseOrderList(),
  ]);
}
