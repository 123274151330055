import React from 'react';

export const FuelRatesIcon = () => {
  return (
    <svg
      xmlns="http://www.w3.org/2000/svg"
      width="20"
      height="20"
      viewBox="0 0 20 20"
    >
      <path
        d="M50 156a9.036 9.036 0 0 1-4.909-1.5 13 13 0 0 1-3.727-3.432v3.114H40v-5.455h5.455v1.364h-3.114a11.213 11.213 0 0 0 3.307 3.148 7.906 7.906 0 0 0 4.352 1.4 8.368 8.368 0 0 0 3.352-.682 8.659 8.659 0 0 0 4.6-4.6 8.368 8.368 0 0 0 .684-3.357H60a9.709 9.709 0 0 1-.784 3.886 10.077 10.077 0 0 1-5.33 5.33A9.709 9.709 0 0 1 50 156zm-.659-3.477V151.3a4.235 4.235 0 0 1-1.716-.875 4.759 4.759 0 0 1-1.17-1.648l1.159-.386a2.592 2.592 0 0 0 .966 1.364 2.632 2.632 0 0 0 1.58.5 2.482 2.482 0 0 0 1.511-.443 1.385 1.385 0 0 0 .6-1.17 1.63 1.63 0 0 0-.568-1.261 7.893 7.893 0 0 0-2.091-1.148A6.606 6.606 0 0 1 47.568 145a2.557 2.557 0 0 1 0-3.477 3.026 3.026 0 0 1 1.818-.864V139.5h1.25v1.159a3.087 3.087 0 0 1 1.5.545 3.234 3.234 0 0 1 1.023 1.25l-1.091.523a2.489 2.489 0 0 0-.841-.955 2.154 2.154 0 0 0-1.182-.318 2.178 2.178 0 0 0-1.4.409 1.35 1.35 0 0 0-.511 1.114 1.364 1.364 0 0 0 .591 1.159 9.754 9.754 0 0 0 1.909.977 6.337 6.337 0 0 1 2.227 1.386 2.7 2.7 0 0 1 .659 1.886 2.626 2.626 0 0 1-.2 1.045 2.334 2.334 0 0 1-.58.818 2.985 2.985 0 0 1-.92.557 4.557 4.557 0 0 1-1.227.284v1.182zM40 146a9.709 9.709 0 0 1 .784-3.886 10.077 10.077 0 0 1 5.33-5.33A9.709 9.709 0 0 1 50 136a9.036 9.036 0 0 1 4.909 1.5 13 13 0 0 1 3.727 3.432v-3.114H60v5.455h-5.455v-1.364h3.114a11.256 11.256 0 0 0-3.3-3.148 7.885 7.885 0 0 0-4.364-1.4 8.368 8.368 0 0 0-3.352.682 8.659 8.659 0 0 0-4.6 4.6 8.368 8.368 0 0 0-.679 3.357z"
        transform="translate(-40 -136)"
        style={{ fill: '#4a4a4a' }}
      />
    </svg>
  );
};
