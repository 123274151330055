import { ReimbursableFields } from 'enums/fields.ts';

const serviceType = 'bidrateReimbursibleMarkups';

const serviceTypeName = 'Reimbursable';
const downloadExcelServiceTypeValue = 'reimbursibleMarkups';
const downloadExcelServiceTypeName = serviceTypeName;
const tabs = [{ key: 'reimbursable', value: 'Reimbursable Mark-ups' }];
// TODO: Remove this
const options = [
  { value: 'Effective End Date', key: 'effEndDate' },
  { value: 'Price($)', key: 'price' },
  { value: 'Base Price Markup(%)', key: 'basePriceMarkup' },
  { value: 'Miscellaneous Mark-Up(%)', key: 'mscMarkUp' },
  { value: 'Delivery Mark-Up(%)', key: 'dlvryMarkUp' },
];

const columnsArray = [
  {
    key: ReimbursableFields.rmbrslMarkUpCode,
    value: 'Reimbursable Mark-Up Code',
    required: true,
    showHeader: true,
  },
  {
    key: ReimbursableFields.description,
    value: 'Description',
    required: true,
    showHeader: true,
  },
  {
    key: ReimbursableFields.unitOfMeasure,
    value: 'Unit of Measure',
    required: true,
    showHeader: true,
  },
  {
    key: ReimbursableFields.workCategory,
    value: 'Work Category',
    required: true,
    showHeader: true,
  },
  {
    key: ReimbursableFields.location,
    value: 'Location',
    required: true,
    showHeader: true,
  },
  {
    key: ReimbursableFields.effStartDate,
    value: 'Effective Start Date',
    required: true,
    showHeader: true,
  },
  {
    value: 'Effective End Date',
    key: 'effEndDate',
    required: false,
    showHeader: false,
  },
  { value: 'Price($)', key: 'price', required: false, showHeader: false },
  {
    value: 'Base Price Markup(%)',
    key: 'basePriceMarkup',
    required: false,
    showHeader: false,
  },
  {
    value: 'Miscellaneous Mark-Up(%)',
    key: 'mscMarkUp',
    required: false,
    showHeader: false,
  },
  {
    value: 'Delivery Mark-Up(%)',
    key: 'dlvryMarkUp',
    required: false,
    showHeader: false,
  },
];

let autoFillColumnskeys = {
  code: 'rmbrslMarkUpCode',
  description: 'description',
  unitOfMeasure: 'unitOfMeasure',
};

let referenceObject = {
  rmbrslMarkUpCode: null,
  description: null,
  location: null,
  effStartDate: null,
  price: null,
  basePriceMarkup: null,
  mscMarkUp: null,
  dlvryMarkUp: null,
  effEndDate: null,
  bidrateReimMarkNmbr: null,
  bidrateNmbr: null,
  updatedDate: null,
  updatedBy: null,
  enteredBy: null,
  enteredDate: null,
  workCategory: null,
  unitOfMeasure: null,
};

const header = {};
options.map(item => {
  header[item.key] = item.value;
});
columnsArray.map(item => {
  header[item.key] = item.value;
});
const reimbursableExcelHeaderRefObj = [header];

// const reimbursableExcelHeaderRefObj = [
//   {
//     rmbrslMarkUpCode: 'Rmbrsl Mark Up Code',
//     description: 'Description',
//     location: 'Location',
//     effStartDate: 'Eff Start Date',
//     price: 'Price',
//     basePriceMarkup: 'Base Price Markup',
//     mscMarkUp: 'Msc Mark Up',
//     dlvryMarkUp: 'Dlvry Mark Up',
//     effEndDate: 'Eff End Date',
//     bidrateReimMarkNmbr: 'Bidrate Reim Mark Nmbr',
//     bidrateNmbr: 'Bidrate Nmbr',
//     updatedDate: 'Updated Date',
//     updatedBy: 'Updated By',
//     enteredBy: 'Entered By',
//     enteredDate: 'Entered Date',
//     workCategory: 'Work Category',
//     unitOfMeasure: 'Unit Of Measure',
//     rmbrslMarkUpDescription: 'Rmbrsl Mark Up Description',
//     comments: 'Comments',
//   },
// ];

export {
  tabs,
  columnsArray,
  options,
  referenceObject,
  serviceType,
  serviceTypeName,
  downloadExcelServiceTypeValue,
  reimbursableExcelHeaderRefObj,
  downloadExcelServiceTypeName,
  autoFillColumnskeys,
};
