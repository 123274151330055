import React from 'react';

export const DuplicateIcon = () => {
  return (
    <svg
      data-name="Component 2850 – 10"
      xmlns="http://www.w3.org/2000/svg"
      width="32"
      height="32"
      viewBox="0 0 32 32"
    >
      <rect
        data-name="🔲🎨 Container l Color"
        width="32"
        height="32"
        rx="4"
        style={{ fill: '#025a95' }}
      />
      <g data-name="content_copy_black_24dp (1)">
        <g data-name="Group 161502">
          <path
            data-name="Rectangle 8566"
            style={{ fill: 'none' }}
            d="M0 0h24v24H0z"
            transform="translate(4 4)"
          />
        </g>
        <g data-name="Group 161503">
          <path
            data-name="Path 28507"
            d="M15 20H5V7a1 1 0 0 0-1-1 1 1 0 0 0-1 1v13a2.006 2.006 0 0 0 2 2h10a1 1 0 0 0 1-1 1 1 0 0 0-1-1zm5-4V4a2.006 2.006 0 0 0-2-2H9a2.006 2.006 0 0 0-2 2v12a2.006 2.006 0 0 0 2 2h9a2.006 2.006 0 0 0 2-2zm-2 0H9V4h9z"
            style={{ fill: '#fff' }}
            transform="translate(4 4)"
          />
        </g>
      </g>
    </svg>
  );
};
