import React from 'react';

export const PreferenceIcon = () => {
  return (
    <svg
      xmlns="http://www.w3.org/2000/svg"
      width="36"
      height="36"
      viewBox="0 0 36 36"
    >
      <g data-name="Group 13140" transform="translate(6305 -8480)">
        <rect
          data-name="🔲🎨 Container l Color"
          width="36"
          height="36"
          rx="4"
          transform="translate(-6305 8480)"
          style={{ fill: '#025a95' }}
        />
        <g data-name="Group 13139">
          <path
            data-name="Path 25953"
            d="M22.458-61.615h-2.077a2.987 2.987 0 0 0-2.9-2.35 2.987 2.987 0 0 0-2.9 2.35H3.791a.672.672 0 0 0-.667.678.672.672 0 0 0 .667.678h10.787a2.987 2.987 0 0 0 2.9 2.35 2.987 2.987 0 0 0 2.9-2.35h2.077a.672.672 0 0 0 .667-.678.672.672 0 0 0-.664-.678zm-4.98 2.351a1.647 1.647 0 0 1-1.522-1.036 1.7 1.7 0 0 1 .357-1.824 1.628 1.628 0 0 1 1.794-.363 1.674 1.674 0 0 1 1.017 1.547 1.661 1.661 0 0 1-1.646 1.677z"
            transform="translate(-6300.124 8558.583)"
            style={{ fill: '#fff' }}
          />
          <path
            data-name="Path 25954"
            d="M3.792-91.509H5.6a2.987 2.987 0 0 0 2.9 2.35 2.987 2.987 0 0 0 2.9-2.35h11.058a.672.672 0 0 0 .667-.678.672.672 0 0 0-.667-.678H11.4a2.987 2.987 0 0 0-2.9-2.35 2.987 2.987 0 0 0-2.9 2.35H3.792a.672.672 0 0 0-.667.678.672.672 0 0 0 .667.678zM8.5-93.861a1.66 1.66 0 0 1 1.643 1.674 1.659 1.659 0 0 1-1.645 1.6 1.659 1.659 0 0 1-1.645-1.6A1.661 1.661 0 0 1 8.5-93.861z"
            transform="translate(-6300.125 8583.215)"
            style={{ fill: '#fff' }}
          />
          <path
            data-name="Path 25955"
            d="M22.457-30.365h-8.1a2.987 2.987 0 0 0-2.9-2.35 2.987 2.987 0 0 0-2.9 2.35H3.791a.672.672 0 0 0-.667.678.672.672 0 0 0 .667.678h4.766a2.987 2.987 0 0 0 2.9 2.35 2.987 2.987 0 0 0 2.9-2.35h8.1a.672.672 0 0 0 .667-.678.672.672 0 0 0-.667-.678zm-11 2.351a1.647 1.647 0 0 1-1.522-1.033 1.7 1.7 0 0 1 .357-1.824 1.628 1.628 0 0 1 1.794-.363 1.674 1.674 0 0 1 1.014 1.547 1.661 1.661 0 0 1-1.643 1.674z"
            transform="translate(-6300.124 8533.95)"
            style={{ fill: '#fff' }}
          />
        </g>
      </g>
    </svg>
  );
};
