import { PurchaseOrderStatus } from 'enums/statuses.ts';

export enum UserType {
  AEP = 'aep',
  VENDOR = 'vendor',
}

export enum ModuleACL {
  DASHBOARD = 'dashboard',
  PURCHASEORDER = 'purchaseorder',
  TIMESHEET = 'timesheet',
  INVOICE = 'invoice',
  BIDRATESHEET = 'bidratesheet',
  ADMIN = 'admin',
  ADDITIONALLINKS = 'additionallinks',
  AUDITS = 'audits',
  GENERAL = 'general',
}

/*
export enum AEP {
  timesheetApprover = 'CAA-AEP-Timesheet-Approvers',
  invoiceVerifier = 'CAA-AEP-Invoice-Verifier',
  timesheetUpdate = 'CAA-AEP-Timesheet-Update',
  invoiceGenerator = 'CAA-AEP-Invoice-Generation',
  readOnly = 'CAA-AEP-All-Read-Only',
  readOnlyTimesheet = 'CAA-AEP-Timesheet-ReadOnly',
  circuitsUpdate = 'CAA-AEP-Timesheet-Circuits-Update',
  workTypeUpdate = 'CAA-AEP-Work-Type-Update',
  cuDownload = 'CAA-AEP-CU-Download',
  admin = 'CAA-AEP-Admin',
}

export enum Vendor {
  poUnionRatesApprover = 'CAA-PO-Union-Rates-Approver',
  poSOWApprover = 'CAA-PO-Scope-of-Work-Approver',
  timesheetInvoiceEntry = 'CAA-Timesheet-Invoice-Entry',
  timesheetEntry = 'CAA-Timesheet-Entry',
  timesheetInvoiceReadOnly = 'CAA-Timesheet-Invoice-Read-Only',
  timesheetApprover = 'CAA-Timesheet-Approver',
  bidRateSheetDownload = 'CAA-Bid-Rate-Sheet-Download',
}

export enum POAction {
  acceptInitialPO = 'acceptInitialPO',
  disagreeOnInitialPO = 'disagreeOnInitialPO',
  acceptUnionRates = 'acceptUnionRates',
  disagreeOnUnionRates = 'disagreeOnUnionRates',
  editPO = 'E',
}

export enum TimesheetAction {
  submitNewTimesheet = 'N',
  importSpreadsheet = 'I',
  acceptSubmittedTimesheet = 'A',
  disagreeOnSubmittedTimesheet = 'D',
  previewInvoice = 'P',
  duplicateTimesheet = 'DT',
  generateInvoice = 'G',
  updateTimesheet = 'U',
  cancelUpdateTimesheet = 'CU',
  recallTimesheet = 'RT',
  exportTimesheet = 'ET',
  accessInvoice = 'AI',
}

export enum InvoiceAction {
  submitNewInvoice = 'S',
  editInvoice = 'E',
  acceptSubmittedInvoice = 'A',
  agreeInvoice = 'AI',
  disagreeInvoice = 'DS',
  editFileInvoice = 'EF',
}

export enum BidRateSheetAction {
  createBidRateSheet = 'createBidRateSheet',
}

export enum UserRole {
  'CAA-AEP-Timesheet-Approvers' = '	AEP Timesheet Approver',
  'CAA-AEP-Invoice-Verifier' = 'AEP Invoice Verifier',
  'CAA-AEP-Timesheet-Update' = 'AEP Timesheet Update',
  'CAA-AEP-Invoice-Generation' = 'AEP Invoice Generation',
  'CAA-AEP-All-ReadOnly' = 'AEP All ReadOnly',
  'CAA-AEP-Timesheet-ReadOnly' = 'AEP Timesheet ReadOnly',
  'CAA-AEP-Timesheet-Circuits-Update' = 'AEP Circuits Update',
  'CAA-AEP-Work-Type-Update' = 'AEP WorkType Update',
  'CAA-AEP-CU-Download' = 'AEP CU Download',
  'CAA-AEP-Admin' = 'AEP CAA Admin',
  'CAA-PO-Union-Rates-Approver' = 'PO Union Rates Approver',
  'CAA-PO-Scope-of-Work-Approver' = 'PO Scope of Work Approver',
  'CAA-Timesheet-Invoice-Entry' = 'Timesheet-Invoice Entry',
  'CAA-Timesheet-Entry' = 'Timesheet Entry',
  'CAA-Timesheet-Invoice-Read-Only' = 'Timesheet-Invoice Read Only',
  'CAA-Timesheet-Approver' = 'Timesheet Approver',
  'CAA-Bid-Rate-Sheet-Download' = 'Bid-Rate Sheet Download',
}
*/
