import React from 'react';

export const EditIcon = () => {
  return (
    <svg width="18" height="18" viewBox="0 0 18 18">
      <g data-name="Group 12183">
        <path
          data-name="Path 25855"
          d="M26.286-78.98a.434.434 0 0 0-.432.432v6.118a.867.867 0 0 1-.865.865H10.773a.867.867 0 0 1-.865-.865v-13.965a.867.867 0 0 1 .865-.865h5.942a.434.434 0 0 0 .432-.432.434.434 0 0 0-.432-.432h-5.942a1.729 1.729 0 0 0-1.73 1.73v13.97a1.729 1.729 0 0 0 1.73 1.73h14.215a1.729 1.729 0 0 0 1.73-1.73v-6.122a.434.434 0 0 0-.432-.432z"
          transform="translate(-9.043 88.695)"
          style={{ fill: '#fff' }}
        />
        <path
          data-name="Path 25856"
          d="m44.512-52.7-2-1.927-.774 2.67z"
          transform="translate(-34.541 62.55)"
          style={{ fill: '#fff' }}
        />
        <path
          data-name="Path 25857"
          d="m54.292-90.125-7.28 6.987 2.426 2.338 7.195-6.9a1.664 1.664 0 0 0 .513-1.211 1.682 1.682 0 0 0-.513-1.211 1.677 1.677 0 0 0-2.341 0z"
          transform="translate(-38.985 90.601)"
          style={{ fill: '#fff' }}
        />
      </g>
    </svg>
  );
};
