import { createSlice } from '@reduxjs/toolkit';

const initialState = {
  isLoading: false,
  error: false,
  errorResponse: {},
  items: [],
  pagination: {
    totalItems: 0,
    currentPage: 1,
    itemsPerPage: 10,
    sortBy: '',
    sortOrder: 'asc',
  },
  recordItems: [],
  inputList: [
    {
      FilterValue: '',
      input_rank: null,
      selectFilterValue: '',
      selectFilterLabel: '',
      presentIcon: '',
      filerForm: false,
      FilterLabel: '',
      selectedFilterRecords: [],
    },
  ],
  adminSiteList: [],
  isStatus: false,
  continueBtn: true,
};

const adminSiteSlice = createSlice({
  name: 'adminSiteSlice',
  initialState,
  reducers: {
    fetchAdminSiteList(state, action) {
      const stateValues = { ...state };
      stateValues.isLoading = false;
      stateValues.pagination = {
        ...stateValues.pagination,
        ...action.payload,
      };
      return { ...stateValues };
    },
    fetchSelectedAdminSiteList(state) {
      state.isLoading = false;
    },
    addAdminSite(state, action) {
      state.isLoading = false;
    },
    deleteAdminSite(state, action) {
      state.isLoading = false;
    },
    setContinueBtn(state, action) {
      const stateValues = { ...state };
      state.continueBtn = action.payload;
      return { ...stateValues };
    },
    setInputFilterData(state, action) {
      if (action.payload.length != 0) {
        state.inputList = action.payload;
      } else {
        const stateValues = { ...state };
        stateValues.inputList = [...initialState.inputList];
        return { ...stateValues };
      }
    },
    setAddNewInputFilterData(state, action) {
      const stateValues = { ...state };
      stateValues.inputList = [...stateValues.inputList, action.payload];
      return { ...stateValues };
    },
    setAdminSiteData(state, action) {
      const adminSiteList = [];
      action.payload?.rows.forEach(obj => {
        adminSiteList.push({
          siteId: obj.siteId,
          description: obj.description,
          entitlement: obj.entitlement,
          orgId: obj.orgId,
          regulated: obj.regulated,
          unregulated: obj.unregulated,
          authorized: obj.authorized,
          active: obj.active,
        });
      });
      const stateValues = { ...state };
      stateValues.adminSiteList = [...adminSiteList];
      stateValues.pagination = {
        ...stateValues.pagination,
        ...action.payload?.pagination,
      };
      return { ...stateValues };
    },
    setSelectedAdminSiteRecords(state, action) {
      const selectedAdminVendorRecords = [];
      action.payload?.forEach(obj => {
        let objValues = Object.values(obj);
        selectedAdminVendorRecords.push({
          label: objValues[0],
          value: objValues[0],
        });
      });
      const stateValues = { ...state };
      stateValues.recordItems = [...selectedAdminVendorRecords];
      return { ...stateValues };
    },
    setAdminSitePagination(state, action) {
      const stateValues = { ...state };
      stateValues.pagination = {
        ...stateValues.pagination,
        ...action.payload,
      };
      return { ...stateValues };
    },

    resetAdminSite() {
      return { ...initialState };
    },

    unsetError(state) {
      const stateValues = { ...state };
      stateValues.error = false;
      stateValues.errorResponse = {};
      return { ...stateValues };
    },

    setError(state, action) {
      const stateValues = { ...state };
      stateValues.error = true;
      stateValues.errorResponse = action.payload;
      return { ...stateValues };
    },
  },
});

export const {
  fetchAdminSiteList,
  fetchSelectedAdminSiteList,
  setSelectedAdminSiteRecords,
  addAdminSite,
  deleteAdminSite,
  setInputFilterData,
  setAddNewInputFilterData,
  setAdminSiteData,
  setAdminSitePagination,
  setContinueBtn,
  setError,
  unsetError,
} = adminSiteSlice.actions;

export default adminSiteSlice.reducer;
