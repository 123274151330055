import { CrewTypeIcon } from 'assets/images/CrewTypeIcon/CrewTypeIcon';
import { FuelRatesIcon } from 'assets/images/FuelRatesIcon/FuelRatesIcon';
import { SiteID } from 'assets/images/SiteID/SiteID';
import { VendorCompanyID } from 'assets/images/VendorCompanyID/VendorCompanyID';
import { WorkTypeIcon } from 'assets/images/WorkTypeIcon/WorkTypeIcon';
import { ModuleACL } from 'enums/entitlements.ts';
import { Page } from 'enums/page.ts';
import { useEffect, useState } from 'react';
import { Card, Col, Row } from 'react-bootstrap';
import 'react-datepicker/dist/react-datepicker.css';
import { useDispatch, useSelector } from 'react-redux';
import { useLocation, useNavigate } from 'react-router-dom';
import { resetFilters } from 'store/slices/filterSlice';
import { setActivePage } from 'store/slices/pageSlice';
import { filterAllowedOptionsACL } from 'utils/aclHelper';
import { AdminCrewType } from '../AdminCrewType/AdminCrewType';
import { AdminDistrictWorkArea } from '../AdminDistrictWorkArea/AdminDistrictWorkArea';
import { AdminFuelRates } from '../AdminFuelRates/AdminFuelRates';
import { AdminModuleContainer } from '../AdminModuleContainer/AdminModuleContainer';
import { AdminSite } from '../AdminSite/AdminSite';
import { AdminVendor } from '../AdminVendor/AdminVendor';
import { AdminWorkArea } from '../AdminWorkArea/AdminWorkArea';
import { AdminWorkType } from '../AdminWorkType/AdminWorkType';
import './AdminModulesLayout.scss';

const AdminModulesLayout = () => {
  const dispatch = useDispatch();
  const location = useLocation();
  const navigate = useNavigate();
  const user = useSelector(state => state.auth);

  const [currentView, setCurrentView] = useState('');
  const [selected, setSelected] = useState(false);
  const [cardHeaders, setCardHeaders] = useState([]);
  const [active, setActive] = useState(false);
  const [resetFilter, setResetFilter] = useState(false);

  const cardHeadersList = [
    {
      cardHeaderTitle: 'Vendor Company ID Admin',
      cardHeaderIcon: 'vendorID',
      permission: 'can_view_vendorcompany_admin',
    },
    {
      cardHeaderTitle: 'Site ID Maintenance',
      cardHeaderIcon: 'siteID',
      permission: 'can_view_aepsites_admin',
    },
    {
      cardHeaderTitle: 'Work Type',
      cardHeaderIcon: 'workType',
      permission: 'can_view_worktype_admin',
    },
    {
      cardHeaderTitle: 'Work Area Maintenance',
      cardHeaderIcon: 'workArea',
      permission: 'can_view_workarea_admin',
    },
    {
      cardHeaderTitle: 'Crew Type',
      cardHeaderIcon: 'crewType',
      permission: 'can_view_crewtype_admin',
    },
    {
      cardHeaderTitle: 'Fuel Rates',
      cardHeaderIcon: 'fuelRates',
      permission: 'can_view_fuelrates_admin',
    },
    {
      cardHeaderTitle: 'District Work Area',
      cardHeaderIcon: 'districtworkarea',
      permission: 'can_view_district_workarea_admin',
    },
  ];

  useEffect(() => {
    setResetFilter(true);
    dispatch(resetFilters());
    dispatch(setActivePage(Page.ADMIN_MODULES));
    const optionsAllowed = filterAllowedOptionsACL(
      user,
      ModuleACL.ADMIN,
      cardHeadersList,
    );
    setCardHeaders(optionsAllowed);
    if (optionsAllowed.length > 0) {
      handleCardClick(optionsAllowed[0]);
      handleAdminModuleCardClick();
    }
  }, []);

  const handleAdminModuleCardClick = () => {
    const selectedType = currentView.cardHeaderIcon;
    switch (selectedType) {
      case 'workType':
        return (
          <AdminWorkType
            resetFilter={resetFilter}
            setResetFilter={setResetFilter}
          />
        );
      case 'crewType':
        return (
          <AdminCrewType
            resetFilter={resetFilter}
            setResetFilter={setResetFilter}
          />
        );
      case 'fuelRates':
        return (
          <AdminFuelRates
            resetFilter={resetFilter}
            setResetFilter={setResetFilter}
          />
        );
      case 'vendorID':
        return (
          <AdminVendor
            resetFilter={resetFilter}
            setResetFilter={setResetFilter}
          />
        );
      case 'siteID':
        return (
          <AdminSite
            resetFilter={resetFilter}
            setResetFilter={setResetFilter}
          />
        );
      case 'workArea':
        return (
          <AdminWorkArea
            resetFilter={resetFilter}
            setResetFilter={setResetFilter}
          />
        );
      case 'districtworkarea':
        return (
          <AdminDistrictWorkArea
            resetFilter={resetFilter}
            setResetFilter={setResetFilter}
          />
        );
      default:
        return (
          <AdminModuleContainer
            resetFilter={resetFilter}
            setResetFilter={setResetFilter}
          />
        );
    }
  };
  const handleCardClick = item => {
    setCurrentView(item);
    setActive(true);
  };

  return (
    <>
      <div className="aepContainer aepHeader">Admin</div>

      <div className="POCardContainer adminCards">
        <Row>
          {cardHeaders?.map((item, index) => (
            <Col key={index} sm={3}>
              <Card
                className={
                  currentView.cardHeaderIcon === item.cardHeaderIcon &&
                  active === true
                    ? 'activeCard column-item'
                    : 'column-item'
                }
                onClick={() => handleCardClick(item)}
              >
                <Row className="cardHeader float-right">
                  <Col sm={9}>
                    <div>{item.cardHeaderTitle}</div>
                  </Col>
                  <Col sm={3}>
                    {item.cardHeaderIcon == 'siteID' ? (
                      <SiteID />
                    ) : item.cardHeaderIcon == 'vendorID' ? (
                      <VendorCompanyID />
                    ) : item.cardHeaderIcon == 'workType' ? (
                      <WorkTypeIcon />
                    ) : item.cardHeaderIcon == 'crewType' ? (
                      <CrewTypeIcon />
                    ) : item.cardHeaderIcon == 'fuelRates' ? (
                      <FuelRatesIcon />
                    ) : item.cardHeaderIcon == 'districtworkarea' ? (
                      <FuelRatesIcon />
                    ) : null}
                  </Col>
                </Row>
              </Card>
            </Col>
          ))}
        </Row>
      </div>
      <div>{handleAdminModuleCardClick()}</div>
    </>
  );
};
export { AdminModulesLayout };
