import { http } from 'services/helpers/BaseApi.ts';
import queryString from 'query-string';
import _ from 'lodash';

export const retrieveAuditList = async qParams => {
  const queryParams = _.cloneDeep(qParams);
  queryParams.page = qParams.currentPage;
  queryParams.limit = qParams.itemsPerPage;
  queryParams.fetchAll = true;

  try {
    return await http.get(
      `/auditrecords/?${queryString.stringify(queryParams)}`,
    );
  } catch (error) {
    return error;
  }
};

export const retrieveAuditDetails = async auditno => {
  try {
    return await http.get(`/auditrecords/${auditno}`);
  } catch (error) {
    return error;
  }
};

export const retrieveContractors = async () => {
  try {
    return await http.get(`/auditrecords/contractors`);
  } catch (err) {
    return err;
  }
};

export const retrieveAuditors = async () => {
  try {
    return await http.get(`/auditrecords/auditors`);
  } catch (err) {
    return err;
  }
};

export const retrieveCircuits = async qParams => {
  try {
    return await http.get(
      `/circuit?limit=500&${queryString.stringify(qParams)}`,
    );
  } catch (err) {
    return err;
  }
};

export const postAuditForm = async payload => {
  try {
    return await http.post('/auditrecords', payload);
  } catch (error) {
    return error;
  }
};

export const updateAuditForm = async payload => {
  const data = _.cloneDeep(payload);
  delete data.seqAuditRecord;
  try {
    return await http.put(`/auditrecords/${payload.seqAuditRecord}`, data);
  } catch (error) {
    return error;
  }
};
