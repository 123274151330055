import { BackArrowIcon } from 'assets/images/BackArrowIcon/BackArrowIcon';
import { ExcelIcon } from 'assets/images/ExcelIcon/ExcelIcon';
import { ImportIcon } from 'assets/images/ImportIcon/ImportIcon';
import { ROUTES_STRING } from 'constants/routes';
import _ from 'lodash';
import { useEffect, useState } from 'react';
import { Button, Col, Form, Row } from 'react-bootstrap';
import { useDispatch, useSelector } from 'react-redux';
import { useNavigate } from 'react-router-dom';
import ReactSelect, { components } from 'react-select';
import { useMediaQuery } from 'react-responsive';
import { TimeSheetTemplate } from 'services/TimeSheetTemplate';
import { fetchPurchaseOrders } from 'store/slices/purchaseOrder/purchaseOrderSlice';
import { isEmptyVal } from 'utils/utils';
import '../TimesheetEntry/TimesheetEntry.scss';
import './TimesheetImport.scss';
import { UploadTimesheet } from './UploadTimesheet';

const TimesheetImport = () => {
  const dispatch = useDispatch();
  const navigate = useNavigate();

  const accessToken = useSelector(state => state.auth.accessToken);
  const purchaseOrders = useSelector(state => state.purchaseOrder.items);

  const [uploadModal, setUploadModal] = useState(false);
  const [excelData, setExcelData] = useState({});
  const [showDownloadButton, setShowDownloadButton] = useState(true);
  const [pOOptionList, setPOOptionList] = useState([]);
  const [selectedPOs, setSelectedPOs] = useState([]);
  const [selectedPOList, setselectedPOList] = useState([]);
  const isMobile = useMediaQuery({ query: '(max-width: 1024px)' });

  const handleInputChange = value => {
    if (!isEmptyVal(value)) {
      dispatch(
        fetchPurchaseOrders({
          search: value,
          callback: data => {
            getPOOptions(data);
          },
        }),
      );
    }
  };
  const debouncedInputChange = _.debounce(handleInputChange, 500);

  const getPOOptions = purchaseOrderList => {
    const options = [...selectedPOList];
    purchaseOrderList.forEach((po, index) => {
      let check = selectedPOList.find(ele => ele.label === po.poNumber);
      if (!check) {
        options.push({
          label: po.poNumber,
          value: po.poId,
          name: 'downloadTimesheets',
          key: po.poId,
        });
      }
    });

    setPOOptionList(options);
  };

  useEffect(() => {
    getPOOptions(purchaseOrders);
  }, []);

  const Menu = props => {
    return (
      <>
        <components.Menu {...props}>
          <div>
            <div>{props.children}</div>
            {isMobile ? (
              <button
                style={{
                  height: '30px',
                  width: '150px',
                  marginLeft: '15px',
                  color: 'white',
                  backgroundColor: '#025a95',
                  fontFamily: 'Arial',
                  fontWeight: 'bold',
                }}
                onClick={handleDownloadTemplate}
                onTouchStart={handleDownloadTemplate}
              >
                Download
              </button>
            ) : (
              <Button
                className="downloadButton"
                onClick={handleDownloadTemplate}
              >
                Download
              </Button>
            )}
          </div>
        </components.Menu>
      </>
    );
  };

  const Option = props => {
    return (
      <div>
        <components.Option {...props}>
          <Form.Group style={{ display: 'flex' }}>
            <input
              style={{ width: '16px', height: '16px', marginRight: '5px' }}
              type="checkbox"
              key={props.data.value}
              name={props}
              checked={
                selectedPOs && selectedPOs.includes(parseInt(props.data.value))
              }
              onChange={e => null}
            />

            <label>{props.label}</label>
          </Form.Group>
        </components.Option>
      </div>
    );
  };

  const formatOptionLabel = po => <div>{po.label}</div>;

  const handleClick = (e, actionObject) => {
    let pos = [];
    let preSelectePo = [];
    if (selectedPOs.length) {
      pos = [...selectedPOs];
      preSelectePo = [...selectedPOList];
    }
    if (e?.nativeEvent) {
      const val = parseInt(e.target.value);
      if (e.target.checked) {
        pos = [...selectedPOs, val];
      } else {
        const index = selectedPOs.indexOf(val);
        if (index >= 0) {
          pos.splice(index, 1);
        }
      }
    } else {
      if (e.length && actionObject.action === 'select-option') {
        const obj = e[e.length - 1];
        const index = selectedPOs.indexOf(obj.value);
        if (index >= 0) {
          pos.splice(index, 1);
        } else {
          pos.push(obj.value);
        }
        preSelectePo = [...preSelectePo, obj];
      } else {
        if (actionObject.action === 'clear') {
          pos = [];
          preSelectePo = [];
        } else if (actionObject.action === 'remove-value') {
          const valueToRemove = actionObject.removedValue.value;
          const index = selectedPOs.indexOf(valueToRemove);
          const selectedIndex = preSelectePo.findIndex(function (ele) {
            return ele.value == valueToRemove;
          });
          if (index >= 0) {
            pos.splice(index, 1);
            preSelectePo.splice(selectedIndex, 1);
          }
        }
      }
    }
    setSelectedPOs(pos);
    setselectedPOList(preSelectePo);
  };

  const handleBack = () => {
    navigate(`/${ROUTES_STRING.timesheets}`);
  };

  const handleDownloadTemplate = () => {
    let poIds = '';
    selectedPOs.forEach(poid => {
      poIds += poid + ',';
    });
    poIds = poIds.slice(0, -1);
    let url = {
      name: 'Timesheet_Template',
      url: `/timesheets/import/template?poid=${poIds}`,
    };
    TimeSheetTemplate(url, accessToken);
  };

  return (
    <>
      <div className="aepContainer aepHeader">Timesheets</div>
      <div className="aepContainer aepBreadcrumb">
        <Row>
          <Col
            sm={2}
            style={{
              display: 'flex',
              marginTop: '5px',
              marginBottom: '12px',
            }}
          >
            <div onClick={handleBack} title="Back" className="backArrow">
              <BackArrowIcon />
            </div>
            Import Timesheet(s)
          </Col>
        </Row>
      </div>
      <div className="aepContainer importButtons">
        <Form.Group
          style={
            isMobile
              ? { width: '100%', marginRight: '12px' }
              : { width: '328px', marginRight: '12px' }
          }
        >
          {!showDownloadButton ? (
            <ReactSelect
              id="downloadTsheets"
              classNamePrefix="downTimesheets"
              isSearchable={true}
              name="downloadTimesheets"
              placeholder="Select"
              options={pOOptionList}
              menuPosition="absolute"
              menuPortalTarget={document.body}
              styles={{
                menuPortal: base => ({
                  ...base,
                  zIndex: 9999,
                  fontSize: '16px',
                }),
              }}
              maxMenuHeight={500}
              onChange={(e, actionObject) => handleClick(e, actionObject)}
              onInputChange={e => debouncedInputChange(e)}
              components={{ Menu, Option }}
              isMulti
              closeMenuOnSelect={false}
              hideSelectedOptions={false}
              value={pOOptionList.filter(po => selectedPOs.includes(po.value))}
              formatOptionLabel={formatOptionLabel}
            />
          ) : (
            <Button
              onClick={e => setShowDownloadButton(false)}
              variant="outlined"
              className="import-button"
            >
              <ExcelIcon />
              <span className="btnIcon">Download Template</span>
            </Button>
          )}
        </Form.Group>

        <Button
          onClick={() => setUploadModal(true)}
          variant="outlined"
          className="import-button"
        >
          <ImportIcon />
          <span className="btnIcon">Import Timesheet(s)</span>
        </Button>
      </div>

      <UploadTimesheet
        viewModal={uploadModal}
        setViewModal={setUploadModal}
        setData={setExcelData}
      />
    </>
  );
};

export default TimesheetImport;
