import React from 'react';

export const AddClassificationIcon = () => {
  return (
    <svg width="18" height="18" viewBox="0 0 18 18">
      <g data-name="Group 12416">
        <path
          data-name="Path 25869"
          d="M23.818-88.815a9.006 9.006 0 0 0-12.73 0 9.006 9.006 0 0 0 0 12.73 9.006 9.006 0 0 0 12.73 0 9.006 9.006 0 0 0 0-12.73zm-2.277 6.875a.756.756 0 0 1-.52.217H18.2v2.823a.755.755 0 0 1-.217.52.756.756 0 0 1-.52.217.731.731 0 0 1-.737-.737v-2.819h-2.818a.731.731 0 0 1-.737-.737.731.731 0 0 1 .737-.737h2.819v-2.819a.731.731 0 0 1 .737-.737.731.731 0 0 1 .737.737v2.812h2.819a.731.731 0 0 1 .737.737.874.874 0 0 1-.216.522z"
          transform="translate(-8.453 91.45)"
          style={{ fill: '#025a95' }}
        />
      </g>
    </svg>
  );
};
