import { PROMISE_TRACKER } from 'constants/promiseTrackers';
import _ from 'lodash';
import { trackPromise } from 'react-promise-tracker';
import { all, call, put, select, takeLatest } from 'redux-saga/effects';
import * as service from 'services/cuAdditionalService';
import {
  fetchCompanies,
  fetchContractors,
  fetchCu,
  fetchDistricts,
  setCompanies,
  setContractors,
  setCu,
  setDistricts,
} from 'store/slices/cuAdditionalLinkSlice';
import { displayError } from 'components/common/Alert/ToastAlert';

const cuPaginationSelector = state => state.cuAdditionalLink.pagination;

/** Promise trackers */
const trackCuList = (fn, ...args) =>
  trackPromise(fn(...args), PROMISE_TRACKER.getCuList);

const trackContractorList = (fn, ...args) =>
  trackPromise(fn(...args), PROMISE_TRACKER.getContractorList);

const trackCompanyList = (fn, ...args) =>
  trackPromise(fn(...args), PROMISE_TRACKER.getCompanyList);

const trackDistrictList = (fn, ...args) =>
  trackPromise(fn(...args), PROMISE_TRACKER.getDistrictList);

/** Saga functions */
function* getCu(action) {
  try {
    const queryParams = _.cloneDeep(yield select(cuPaginationSelector));
    const response = yield call(trackCuList, f =>
      service.retrieveCu(queryParams),
    );
    const { data } = response;
    data.pagination = { totalItems: data.count };
    if (response.status === 200) {
      yield put(setCu(data));
      if (action.payload.callback) {
        action.payload.callback();
      }
    }
  } catch (e) {
    displayError(
      'We are experiencing technical difficulties. Please come back shortly to try again !!',
    );
  }
}

function* getContractors(action) {
  try {
    const response = yield call(trackContractorList, f =>
      service.retrieveContractors(),
    );
    const { data } = response;
    if (response.status === 200) {
      yield put(setContractors(data));
      if (action.payload.callback) {
        action.payload.callback();
      }
    }
  } catch (e) {}
}

function* getCompanies(action) {
  try {
    const response = yield call(trackCompanyList, f =>
      service.retrieveCompanies(),
    );
    const { data } = response;
    if (response.status === 200) {
      yield put(setCompanies(data));
      if (action.payload.callback) {
        action.payload.callback();
      }
    }
  } catch (e) {}
}

function* getDistricts(action) {
  try {
    const response = yield call(trackDistrictList, f =>
      service.retrieveDistricts(),
    );
    const { data } = response;
    if (response.status === 200) {
      yield put(setDistricts(data));
      if (action.payload.callback) {
        action.payload.callback();
      }
    }
  } catch (e) {}
}

function* watchGetCu() {
  yield takeLatest(fetchCu.type, getCu);
}

function* watchGetContractors() {
  yield takeLatest(fetchContractors.type, getContractors);
}

function* watchGetCompanies() {
  yield takeLatest(fetchCompanies.type, getCompanies);
}

function* watchGetDistricts() {
  yield takeLatest(fetchDistricts.type, getDistricts);
}

export function* cuAdditionalLinkSaga() {
  yield all([
    watchGetCu(),
    watchGetContractors(),
    watchGetCompanies(),
    watchGetDistricts(),
  ]);
}
