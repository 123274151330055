import DatePicker from 'react-datepicker';
import { useState, useEffect } from 'react';
import 'react-datepicker/dist/react-datepicker.css';
import { Button, Form, Tab, Table, Tabs, Row, Col } from 'react-bootstrap';
import './AuditDetails.scss';
import { useDispatch, useSelector } from 'react-redux';
import { setAuditData } from 'store/slices/AuditModules/auditSlice';

const QualityAudits = () => {
  const [workQualityAudit, setWorkQualityAudit] = useState({});
  const dispatch = useDispatch();

  const auditData = useSelector(state => state.audit.auditFormData);

  const handleRadioChange = e => {
    const field = e.target.name.split('-');
    setWorkQualityAudit({ ...workQualityAudit, [field[0]]: field[1] });
    dispatch(setAuditData({ [field[0]]: field[1] }));
  };

  useEffect(() => {
    if (Object.values(workQualityAudit).includes('F')) {
      dispatch(setAuditData({ workQualityAudit: 'F' }));
    } else if (Object.values(workQualityAudit).includes('P')) {
      dispatch(setAuditData({ workQualityAudit: 'P' }));
    } else {
      dispatch(setAuditData({ workQualityAudit: null }));
    }
  }, [workQualityAudit]);

  return (
    <>
      <div className="aepBreadcrumb auditInnerContainer">
        <Form.Label className="responseLabel">
          Forestry Work Quality Audit
        </Form.Label>
        <Form.Group controlId="kindOfSelection" className="responseForm">
          <Form.Check
            inline
            type="radio"
            aria-label="radio 1"
            label="Pass"
            className="input1"
            name="workQualityAudit-P"
            checked={auditData?.workQualityAudit == 'P'}
          />
          <Form.Check
            inline
            type="radio"
            aria-label="radio 2"
            label="Fail"
            className="input1"
            name="clrncAudit-F"
            checked={auditData?.workQualityAudit == 'F'}
          />
        </Form.Group>
      </div>
      <Form className="auditSection">
        <Row className="mbAudit">
          <Col sm={4}>
            <Form.Group controlId="form.remitAddress">
              <Form.Label className="responseLabel">1. Collar Cuts</Form.Label>
              <Form.Group
                controlId="kindOfSelection"
                className="responseForm ml-4 mb-3"
              >
                <Form.Check
                  inline
                  type="radio"
                  aria-label="radio 1"
                  label="Pass"
                  onChange={e => handleRadioChange(e)}
                  name="qowCollarCuts-P"
                  checked={auditData?.qowCollarCuts == 'P'}
                />
                <Form.Check
                  inline
                  type="radio"
                  aria-label="radio 2"
                  label="Fail"
                  onChange={e => handleRadioChange(e)}
                  name="qowCollarCuts-F"
                  checked={auditData?.qowCollarCuts == 'F'}
                />
              </Form.Group>
            </Form.Group>
          </Col>
          <Col sm={4}>
            <Form.Group controlId="form.remitAddress">
              <Form.Label className="responseLabel">4.Stump Height</Form.Label>
              <Form.Group
                controlId="kindOfSelection"
                className="responseForm ml-4 mb-3"
              >
                <Form.Check
                  inline
                  type="radio"
                  aria-label="radio 1"
                  label="Pass"
                  onChange={e => handleRadioChange(e)}
                  name="qowStumpHt-P"
                  checked={auditData?.qowStumpHt == 'P'}
                />
                <Form.Check
                  inline
                  type="radio"
                  aria-label="radio 2"
                  label="Fail"
                  onChange={e => handleRadioChange(e)}
                  name="qowStumpHt-F"
                  checked={auditData?.qowStumpHt == 'F'}
                />
              </Form.Group>
            </Form.Group>
          </Col>
          <Col sm={4}>
            <Form.Group controlId="form.remitAddress">
              <Form.Label className="responseLabel">7. Peels/Tears</Form.Label>
              <Form.Group
                controlId="kindOfSelection"
                className="responseForm ml-4 mb-3"
              >
                <Form.Check
                  inline
                  type="radio"
                  aria-label="radio 1"
                  label="Pass"
                  onChange={e => handleRadioChange(e)}
                  name="qowPeelsTears-P"
                  checked={auditData?.qowPeelsTears == 'P'}
                />
                <Form.Check
                  inline
                  type="radio"
                  aria-label="radio 2"
                  label="Fail"
                  onChange={e => handleRadioChange(e)}
                  name="qowPeelsTears-F"
                  checked={auditData?.qowPeelsTears == 'F'}
                />
              </Form.Group>
            </Form.Group>
          </Col>
        </Row>
        <Row className="mbAudit">
          <Col sm={4}>
            <Form.Group controlId="form.remitAddress">
              <Form.Label className="responseLabel">
                2. Directional Pruning
              </Form.Label>
              <Form.Group
                controlId="kindOfSelection"
                className="responseForm ml-4 mb-3"
              >
                <Form.Check
                  inline
                  type="radio"
                  aria-label="radio 1"
                  label="Pass"
                  onChange={e => handleRadioChange(e)}
                  name="qowDirectPrun-P"
                  checked={auditData?.qowDirectPrun == 'P'}
                />
                <Form.Check
                  inline
                  type="radio"
                  aria-label="radio 2"
                  label="Fail"
                  onChange={e => handleRadioChange(e)}
                  name="qowDirectPrun-F"
                  checked={auditData?.qowDirectPrun == 'F'}
                />
              </Form.Group>
            </Form.Group>
          </Col>
          <Col sm={4}>
            <Form.Group controlId="form.remitAddress">
              <Form.Label className="responseLabel">5. Hangers</Form.Label>
              <Form.Group
                controlId="kindOfSelection"
                className="responseForm ml-4 mb-3"
              >
                <Form.Check
                  inline
                  value="NON"
                  type="radio"
                  aria-label="radio 1"
                  label="Pass"
                  onChange={e => handleRadioChange(e)}
                  name="qowHangers-P"
                  checked={auditData?.qowHangers == 'P'}
                />
                <Form.Check
                  inline
                  type="radio"
                  aria-label="radio 2"
                  label="Fail"
                  onChange={e => handleRadioChange(e)}
                  name="qowHangers-F"
                  checked={auditData?.qowHangers == 'F'}
                />
              </Form.Group>
            </Form.Group>
          </Col>
          <Col sm={4}>
            <Form.Group controlId="form.remitAddress">
              <Form.Label className="responseLabel">
                8. Cleanup/Brush Disposal
              </Form.Label>
              <Form.Group
                controlId="kindOfSelection"
                className="responseForm ml-4 mb-3"
              >
                <Form.Check
                  inline
                  type="radio"
                  aria-label="radio 1"
                  label="Pass"
                  onChange={e => handleRadioChange(e)}
                  name="qowBrushDisposal-P"
                  checked={auditData?.qowBrushDisposal == 'P'}
                />
                <Form.Check
                  inline
                  type="radio"
                  aria-label="radio 2"
                  label="Fail"
                  onChange={e => handleRadioChange(e)}
                  name="qowBrushDisposal-F"
                  checked={auditData?.qowBrushDisposal == 'F'}
                />
              </Form.Group>
            </Form.Group>
          </Col>
        </Row>
        <Row className="mbAudit">
          <Col sm={4}>
            <Form.Group controlId="form.remitAddress">
              <Form.Label className="responseLabel">
                3. Drop Crotch Selection
              </Form.Label>
              <Form.Group
                controlId="kindOfSelection"
                className="responseForm ml-4 mb-3"
              >
                <Form.Check
                  inline
                  type="radio"
                  aria-label="radio 1"
                  label="Pass"
                  onChange={e => handleRadioChange(e)}
                  name="qowDrpCrotchSel-P"
                  checked={auditData?.qowDrpCrotchSel == 'P'}
                />
                <Form.Check
                  inline
                  type="radio"
                  aria-label="radio 2"
                  label="Fail"
                  onChange={e => handleRadioChange(e)}
                  name="qowDrpCrotchSel-F"
                  checked={auditData?.qowDrpCrotchSel == 'F'}
                />
              </Form.Group>
            </Form.Group>
          </Col>
          <Col sm={4}>
            <Form.Group controlId="form.remitAddress">
              <Form.Label className="responseLabel">
                6. Clearing around poles
              </Form.Label>
              <Form.Group
                controlId="kindOfSelection"
                className="responseForm ml-4 mb-3"
              >
                <Form.Check
                  inline
                  type="radio"
                  aria-label="radio 1"
                  label="Pass"
                  onChange={e => handleRadioChange(e)}
                  name="qowClrPoles-P"
                  checked={auditData?.qowClrPoles == 'P'}
                />
                <Form.Check
                  inline
                  type="radio"
                  aria-label="radio 2"
                  label="Fail"
                  onChange={e => handleRadioChange(e)}
                  name="qowClrPoles-F"
                  checked={auditData?.qowClrPoles == 'F'}
                />
              </Form.Group>
            </Form.Group>
          </Col>
          <Col sm={4}>
            <Form.Group controlId="form.remitAddress">
              <Form.Label className="responseLabel">
                9. Regard for Property
              </Form.Label>
              <Form.Group
                controlId="kindOfSelection"
                className="responseForm ml-4 mb-3"
              >
                <Form.Check
                  inline
                  type="radio"
                  aria-label="radio 1"
                  label="Pass"
                  onChange={e => handleRadioChange(e)}
                  name="qowRegardPrpt-P"
                  checked={auditData?.qowRegardPrpt == 'P'}
                />
                <Form.Check
                  inline
                  value="MIN"
                  type="radio"
                  aria-label="radio 2"
                  label="Fail"
                  onChange={e => handleRadioChange(e)}
                  name="qowRegardPrpt-F"
                  checked={auditData?.qowRegardPrpt == 'F'}
                />
              </Form.Group>
            </Form.Group>
          </Col>
        </Row>
        <Row className="mbAudit">
          <Col sm={12}>
            <Form.Group controlId="form.remitAddress" className="ml-3">
              <Form.Label className="safetyAudits ml-1">Comments :</Form.Label>
              <Form.Control
                as="textarea"
                value={auditData?.comments4}
                onChange={e =>
                  dispatch(setAuditData({ comments4: e.target.value }))
                }
              />
            </Form.Group>
          </Col>
        </Row>
      </Form>
    </>
  );
};
export { QualityAudits };
