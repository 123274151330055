import './CrossButton.scss';

const CrossButton = props => {
  return (
    <div className="crossIcon">
      <span>x</span>
    </div>
  );
};

export { CrossButton };
