import { createSlice } from '@reduxjs/toolkit';
import _ from 'lodash';

const initialState = {
  po: [
    {
      FilterValue: '',
      input_rank: null,
      selectFilterValue: '',
      selectFilterLabel: '',
      presentIcon: '',
      filerForm: false,
      FilterLabel: '',
      selectedFilterRecords: [],
    },
  ],
  timesheet: [
    {
      FilterValue: '',
      input_rank: null,
      selectFilterValue: '',
      selectFilterLabel: '',
      presentIcon: '',
      filerForm: false,
      FilterLabel: '',
      selectedFilterRecords: [],
    },
  ],
  invoice: [
    {
      FilterValue: '',
      input_rank: null,
      selectFilterValue: '',
      selectFilterLabel: '',
      presentIcon: '',
      filerForm: false,
      FilterLabel: '',
      selectedFilterRecords: [],
    },
  ],
  bidratesheet: [
    {
      FilterValue: '',
      input_rank: null,
      selectFilterValue: '',
      selectFilterLabel: '',
      presentIcon: '',
      filerForm: false,
      FilterLabel: '',
      selectedFilterRecords: [],
    },
  ],
  crewtype: [
    {
      FilterValue: '',
      input_rank: null,
      selectFilterValue: '',
      selectFilterLabel: '',
      presentIcon: '',
      filerForm: false,
      FilterLabel: '',
      selectedFilterRecords: [],
    },
  ],
  districtworkarea: [
    {
      FilterValue: '',
      input_rank: null,
      selectFilterValue: '',
      selectFilterLabel: '',
      presentIcon: '',
      filerForm: false,
      FilterLabel: '',
      selectedFilterRecords: [],
    },
  ],
  fuelrates: [
    {
      FilterValue: '',
      input_rank: null,
      selectFilterValue: '',
      selectFilterLabel: '',
      presentIcon: '',
      filerForm: false,
      FilterLabel: '',
      selectedFilterRecords: [],
    },
  ],
  sites: [
    {
      FilterValue: '',
      input_rank: null,
      selectFilterValue: '',
      selectFilterLabel: '',
      presentIcon: '',
      filerForm: false,
      FilterLabel: '',
      selectedFilterRecords: [],
    },
  ],
  vendor: [
    {
      FilterValue: '',
      input_rank: null,
      selectFilterValue: '',
      selectFilterLabel: '',
      presentIcon: '',
      filerForm: false,
      FilterLabel: '',
      selectedFilterRecords: [],
    },
  ],
  workarea: [
    {
      FilterValue: '',
      input_rank: null,
      selectFilterValue: '',
      selectFilterLabel: '',
      presentIcon: '',
      filerForm: false,
      FilterLabel: '',
      selectedFilterRecords: [],
    },
  ],
  worktype: [
    {
      FilterValue: '',
      input_rank: null,
      selectFilterValue: '',
      selectFilterLabel: '',
      presentIcon: '',
      filerForm: false,
      FilterLabel: '',
      selectedFilterRecords: [],
    },
  ],
  newcodemodal: [
    {
      FilterValue: '',
      input_rank: null,
      selectFilterValue: '',
      selectFilterLabel: '',
      presentIcon: '',
      filerForm: false,
      FilterLabel: '',
      selectedFilterRecords: [],
    },
  ],
};

export const filterSlice = createSlice({
  name: 'filterSlice',
  initialState,
  reducers: {
    resetFilters(state, action) {
      return _.cloneDeep(initialState);
    },

    setPOFilters(state, action) {
      const stateValues = { ...state };

      if (Array.isArray(action?.payload)) {
        if (action?.payload?.length > 0) {
          stateValues.po = action.payload;
        }
      } else {
        stateValues.po = [...stateValues.po, action.payload];
      }

      return { ...stateValues };
    },

    setTimesheetFilters(state, action) {
      const stateValues = { ...state };

      if (Array.isArray(action?.payload)) {
        if (action?.payload?.length > 0) {
          stateValues.timesheet = action.payload;
        }
      } else {
        stateValues.timesheet = [...stateValues.timesheet, action.payload];
      }

      return { ...stateValues };
    },

    setInvoiceFilters(state, action) {
      const stateValues = { ...state };

      if (Array.isArray(action?.payload)) {
        if (action?.payload?.length > 0) {
          stateValues.invoice = action.payload;
        }
      } else {
        stateValues.invoice = [...stateValues.invoice, action.payload];
      }

      return { ...stateValues };
    },

    setBidratesheetFilters(state, action) {
      const stateValues = { ...state };
      if (Array.isArray(action?.payload)) {
        if (action?.payload?.length > 0) {
          stateValues.bidratesheet = action.payload;
        }
      } else {
        stateValues.bidratesheet = [
          ...stateValues.bidratesheet,
          action.payload,
        ];
      }

      return { ...stateValues };
    },

    setCrewtypeFilters(state, action) {
      const stateValues = { ...state };

      if (Array.isArray(action?.payload)) {
        if (action?.payload?.length > 0) {
          stateValues.crewtype = action.payload;
        }
      } else {
        stateValues.crewtype = [...stateValues.crewtype, action.payload];
      }

      return { ...stateValues };
    },

    setDistrictWorkAreaFilters(state, action) {
      const stateValues = { ...state };

      if (Array.isArray(action?.payload)) {
        if (action?.payload?.length > 0) {
          stateValues.districtworkarea = action.payload;
        }
      } else {
        stateValues.districtworkarea = [
          ...stateValues.districtworkarea,
          action.payload,
        ];
      }

      return { ...stateValues };
    },

    setFuelRatesFilters(state, action) {
      const stateValues = { ...state };

      if (Array.isArray(action?.payload)) {
        if (action?.payload?.length > 0) {
          stateValues.fuelrates = action.payload;
        }
      } else {
        stateValues.fuelrates = [...stateValues.fuelrates, action.payload];
      }

      return { ...stateValues };
    },

    setSitesFilters(state, action) {
      const stateValues = { ...state };

      if (Array.isArray(action?.payload)) {
        if (action?.payload?.length > 0) {
          stateValues.sites = action.payload;
        }
      } else {
        stateValues.sites = [...stateValues.sites, action.payload];
      }

      return { ...stateValues };
    },

    setVendorFilters(state, action) {
      const stateValues = { ...state };

      if (Array.isArray(action?.payload)) {
        if (action?.payload?.length > 0) {
          stateValues.vendor = action.payload;
        }
      } else {
        stateValues.vendor = [...stateValues.vendor, action.payload];
      }

      return { ...stateValues };
    },

    setWorkAreaFilters(state, action) {
      const stateValues = { ...state };

      if (Array.isArray(action?.payload)) {
        if (action?.payload?.length > 0) {
          stateValues.workarea = action.payload;
        }
      } else {
        stateValues.workarea = [...stateValues.workarea, action.payload];
      }

      return { ...stateValues };
    },

    setWorkTypeFilters(state, action) {
      const stateValues = { ...state };

      if (Array.isArray(action?.payload)) {
        if (action?.payload?.length > 0) {
          stateValues.worktype = action.payload;
        }
      } else {
        stateValues.worktype = [...stateValues.worktype, action.payload];
      }

      return { ...stateValues };
    },

    setNewCodeModalFilters(state, action) {
      const stateValues = { ...state };

      if (Array.isArray(action?.payload)) {
        if (action?.payload?.length > 0) {
          stateValues.newcodemodal = action.payload;
        }
      } else {
        stateValues.newcodemodal = [
          ...stateValues.newcodemodal,
          action.payload,
        ];
      }

      return { ...stateValues };
    },
  },
});

export const {
  resetFilters,
  setPOFilters,
  setTimesheetFilters,
  setInvoiceFilters,
  setBidratesheetFilters,
  setCrewtypeFilters,
  setDistrictWorkAreaFilters,
  setFuelRatesFilters,
  setSitesFilters,
  setVendorFilters,
  setWorkAreaFilters,
  setWorkTypeFilters,
  setNewCodeModalFilters,
} = filterSlice.actions;

export default filterSlice.reducer;
