import { AdditionalLinkIcon } from 'assets/images/AdditionalLinkIcon/AdditionalLinkIcon';
import { BidRateSheetIcon } from 'assets/images/BidRateSheetIcon/BidRateSheetIcon';
import { HomeIcon } from 'assets/images/HomeIcon/HomeIcon';
import { AuditIcon } from 'assets/images/AuditIcon/AuditIcon';
import { InvoiceIcon } from 'assets/images/InvoiceIcon/InvoiceIcon';
import { LogoutIcon } from 'assets/images/LogoutIcon/LogoutIcon';
import { PurchaseOrderIcon } from 'assets/images/PurchaseOrderIcon/PurchaseOrderIcon';
import { TimesheetIcon } from 'assets/images/TimesheetIcon/TimesheetIcon';
import { AdminIcon } from 'assets/images/AdminIcon/AdminIcon';
import { ShowForPermission } from 'components/common/ShowPermission/ShowPermission';
import { ROUTES } from 'constants/routes';
import { Page } from 'enums/page.ts';
import { useEffect } from 'react';
import {
  Menu,
  MenuItem,
  Sidebar,
  SubMenu,
  useProSidebar,
} from 'react-pro-sidebar';
import { useMediaQuery } from 'react-responsive';
import { useDispatch, useSelector } from 'react-redux';
import { useNavigate } from 'react-router-dom';
import { logoutUser } from 'store/slices/authenticationSlice';
import './Sidenav.scss';

export default function Sidenav() {
  const dispatch = useDispatch();
  const activePage = useSelector(state => state.page.activePage);
  const navigate = useNavigate();
  const { collapseSidebar } = useProSidebar();
  const isMobile = useMediaQuery({ query: '(max-width: 575px)' });

  useEffect(() => {
    const activeLink = document.getElementById(activePage);
    const anchors = document.getElementsByTagName('a');

    Array.from(anchors).forEach(anchor => {
      anchor.classList.remove('activePage');
    });
    activeLink?.classList.add('activePage');
  }, [activePage]);

  const handleClick = route => {
    navigate(route);
  };

  const logout = () => {
    dispatch(logoutUser());
  };

  return (
    <div className="sidenav">
      {isMobile ? null : (
        <Sidebar
          backgroundColor="white"
          defaultCollapsed={true}
          onMouseEnter={() => collapseSidebar(false)}
          onMouseLeave={() => collapseSidebar(true)}
          transitionDuration={0}
        >
          <Menu>
            <MenuItem
              id={Page.HOME}
              icon={<HomeIcon />}
              onClick={e => {
                handleClick('/');
                collapseSidebar(true);
              }}
            >
              Home
            </MenuItem>

            <ShowForPermission
              permission="can_view_purchaseorder"
              permissionModule={Page.GENERAL}
            >
              <MenuItem
                id={Page.PURCHASE_ORDER}
                icon={<PurchaseOrderIcon />}
                onClick={e => {
                  handleClick(ROUTES.purchaseOrders);
                  collapseSidebar(true);
                }}
              >
                POs
              </MenuItem>
            </ShowForPermission>

            <ShowForPermission
              permission="can_view_timesheet"
              permissionModule={Page.GENERAL}
            >
              <MenuItem
                id={Page.TIMESHEET}
                icon={<TimesheetIcon />}
                onClick={e => {
                  handleClick(ROUTES.timesheets);
                  collapseSidebar(true);
                }}
              >
                Timesheets
              </MenuItem>
            </ShowForPermission>

            <ShowForPermission
              permission="can_view_invoice"
              permissionModule={Page.GENERAL}
            >
              <MenuItem
                id={Page.INVOICE}
                icon={<InvoiceIcon />}
                onClick={e => {
                  handleClick(ROUTES.invoices);
                  collapseSidebar(true);
                }}
              >
                Invoices
              </MenuItem>
            </ShowForPermission>

            <ShowForPermission
              permission="can_view_bidratesheet"
              permissionModule={Page.GENERAL}
            >
              <MenuItem
                id={Page.BID_RATE_SHEET}
                icon={<BidRateSheetIcon />}
                onClick={e => {
                  handleClick(ROUTES.bidRateSheets);
                  collapseSidebar(true);
                }}
              >
                Bid Rate Sheets
              </MenuItem>
            </ShowForPermission>

            <ShowForPermission
              permission="can_view_additionallinks"
              permissionModule={Page.GENERAL}
            >
              <SubMenu
                label="Additional Links"
                icon={<AdditionalLinkIcon />}
                id={Page.ADDITIONAL_LINKS}
                className="additionalLink"
              >
                <MenuItem
                  id={Page.COMPATIBLE_UNIT_MANUAL}
                  onClick={e => {
                    handleClick(ROUTES.compatibleUnitManual);
                    collapseSidebar(true);
                  }}
                  className="subLink"
                >
                  Compatible Unit Manual
                </MenuItem>
                <MenuItem
                  id={Page.UNIT_REVISIONS}
                  onClick={e => {
                    handleClick(ROUTES.unitRevisions);
                    collapseSidebar(true);
                  }}
                  className="subLink"
                >
                  Unit Revisions
                </MenuItem>
                <MenuItem
                  id={Page.STANDARD_REVISIONS}
                  onClick={e => {
                    handleClick(ROUTES.standardRevisions);
                    collapseSidebar(true);
                  }}
                  className="subLink"
                >
                  Standard Revisions
                </MenuItem>
                <MenuItem
                  id={Page.AEP_DISTRIBUTIONS_STANDARD}
                  onClick={e => {
                    handleClick(ROUTES.aepDistributionsStandard);
                    collapseSidebar(true);
                  }}
                  className="subLink"
                >
                  AEP Distributions Standard
                </MenuItem>
                <MenuItem
                  id={Page.CU_DOWNLOAD}
                  onClick={e => {
                    handleClick(ROUTES.cuDownload);
                    collapseSidebar(true);
                  }}
                  className="subLink"
                >
                  CU Downloads
                </MenuItem>
              </SubMenu>
            </ShowForPermission>

            <ShowForPermission
              permission="can_view_audits"
              permissionModule={Page.GENERAL}
            >
              <MenuItem
                id={Page.AUDITMODULES}
                icon={<AuditIcon />}
                onClick={e => {
                  handleClick(ROUTES.audit);
                  collapseSidebar(true);
                }}
              >
                Audit
              </MenuItem>
            </ShowForPermission>

            <ShowForPermission
              permission="can_view_admin"
              permissionModule={Page.GENERAL}
            >
              <MenuItem
                id={Page.ADMIN_MODULES}
                icon={<AdminIcon />}
                onClick={e => {
                  handleClick(ROUTES.admin);
                  collapseSidebar(true);
                }}
              >
                Admin
              </MenuItem>
            </ShowForPermission>

            <MenuItem icon={<LogoutIcon />} onClick={logout}>
              Logout
            </MenuItem>
          </Menu>
        </Sidebar>
      )}
    </div>
  );
}
