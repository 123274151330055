import { createSlice } from '@reduxjs/toolkit';
import { CookieService } from 'services/cookieService';
import { decodeToken } from 'utils/tokenHelper';

const initialState = {
  accessExpired: false,
  isLoggedIn: false,
  accessToken: '',
  refreshToken: '',
  userId: '',
  userType: '',
  userRoles: [],
  configObject: {},
  dashCount: {
    pendingInvoicesCount: 0,
    pendingTimesheetCount: 0,
    pendingInitialPOReviewCount: 0,
    pendingPORatesReviewCount: 0,
  },
  userDisplayname: '',
};

const authSlice = createSlice({
  name: 'authSlice',
  initialState,
  reducers: {
    loginUser(state, action) {},

    getCookie(state) {
      const result = CookieService.authenticateViaCookie();
      return { ...state, ...result };
    },

    setAccessToken(state, action) {
      const { accessToken } = action.payload;
      CookieService.setAccessToken(accessToken);
      const stateValues = { ...state };
      stateValues.accessToken = accessToken;
      stateValues.accessExpired = false;
      return { ...stateValues };
    },

    setRefreshToken(state, action) {
      const { refreshToken } = action.payload;
      CookieService.setRefreshToken(refreshToken);
      const stateValues = { ...state };
      stateValues.refreshToken = refreshToken;
      return { ...stateValues };
    },

    refreshAccessToken(state, action) {},

    fetchDashboardCount(state, action) {},

    setLoggedInUser(state, action) {
      const { userid } = decodeToken(action.payload.accessToken);
      // CookieService.setCookies(action);
      return {
        ...state,
        accessExpired: false,
        isLoggedIn: true,
        accessToken: action.payload.accessToken,
        refreshToken: action.payload.refreshToken,
        userId: userid,
      };
    },

    setUserData(state, action) {
      const { userType, userRoles, userDisplayname, userId, configObject } =
        action.payload;
      return {
        ...state,
        userType,
        userRoles,
        userDisplayname,
        userId,
        configObject,
      };
    },

    logoutUser(state) {},

    setLoggedOut() {
      CookieService.clearCookies();
      return {
        accessExpired: false,
        isLoggedIn: false,
        accessToken: '',
        refreshToken: '',
        userId: '',
      };
    },

    setDashboardCount(state, action) {
      return {
        ...state,
        dashCount: action.payload,
      };
    },
  },
});

export const {
  getCookie,
  setAccessToken,
  setRefreshToken,
  refreshAccessToken,
  loginUser,
  setLoggedInUser,
  logoutUser,
  fetchDashboardCount,
  setLoggedOut,
  setUserData,
  setDashboardCount,
} = authSlice.actions;

export default authSlice.reducer;
