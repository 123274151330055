import { optionsBidRateSheetListTab } from 'constants/BidRateSheetTabs.ts';
import { optionsInvoiceListTab } from 'constants/invoiceTabs.ts';
import { optionsPoListTab } from 'constants/poTabs.ts';
import { optionsTimeSheetListTab } from 'constants/timeSheetTabs.ts';
import { ModuleACL } from 'enums/entitlements.ts';
import _ from 'lodash';

export function getModulesACL() {
  const modules = Object.values(ModuleACL);
  return modules;
}

/**
 * Check if user got module/permission by ACL
 * @param {*} user
 * @param {*} permissionModule
 * @param {*} permission
 * @returns
 */
export const IsAllowedACL = (user, permissionModule = null, permission) => {
  let bFound = false;
  try {
    let modules = new Array();
    if (!_.isEmpty(permissionModule)) {
      modules.push(permissionModule);
    } else {
      modules = getModulesACL();
    }
    modules.forEach(module => {
      if (bFound) return bFound;
      if (user.configObject && user.configObject[module]) {
        const permissionList = user.configObject[module].permissions;
        for (const permissionRole of permissionList) {
          if (permissionRole === permission) {
            bFound = true;
            return bFound;
          }
        }
      }
    });
    return bFound;
  } catch (error) {
    return bFound;
  }
};

/**
 * Return the list of options allowed by ACL based on roles of current user.
 * @returns
 */
export const filterAllowedOptionsACL = (user, module, options2Check) => {
  let allowedOptions = [];
  if (!_.isEmpty(options2Check)) {
    options2Check.forEach(option2Check => {
      let isAllowed = false;
      if (_.isEmpty(option2Check) || _.isNull(option2Check.permission)) {
        isAllowed = true;
      } else {
        isAllowed = IsAllowedACL(user, module, option2Check.permission);
      }
      if (isAllowed && !_.isEmpty(option2Check)) {
        allowedOptions.push(option2Check);
      }
    });
  }
  return allowedOptions;
};

/**
 * Return collection of status by module allowed based on roles of current user.
 * @param {*} user
 * @param {*} permissionModule
 * @returns
 */
export function getStatusByACL(user, permissionModule) {
  let status = new Array();
  try {
    let modules = new Array();
    if (!_.isEmpty(permissionModule)) {
      modules.push(permissionModule);
    } else {
      modules = getModulesACL();
    }
    modules.forEach(module => {
      if (user.configObject && user.configObject[module]) {
        const permissionList = user.configObject[module].permissions;

        let optionsTab = [];
        if (module === ModuleACL.PURCHASEORDER) {
          optionsTab = optionsPoListTab;
        } else if (module == ModuleACL.TIMESHEET) {
          optionsTab = optionsTimeSheetListTab;
        } else if (module == ModuleACL.INVOICE) {
          optionsTab = optionsInvoiceListTab;
        } else if (module === ModuleACL.BIDRATESHEET) {
          optionsTab = optionsBidRateSheetListTab;
        }

        for (const permissionRole of permissionList) {
          optionsTab.forEach(optionTab => {
            if (optionTab.permission === permissionRole) {
              if (
                !_.isEmpty(optionTab.key) &&
                optionTab.key.toLocaleLowerCase() !== 'all'
              ) {
                if (!_.isEmpty(optionTab) && !_.isEmpty(optionTab.status)) {
                  status.push(...optionTab.status);
                }
              } else {
                status.push(...optionTab.status);
              }
            }
          });
        }
      }
    });
    return status;
  } catch (error) {
    return status;
  }
}
