export function currencyFormat(number) {
  let num = isNaN(number) ? 0 : Number(number);
  const isnegtive = num < 0;
  if (isnegtive) {
    num = -1 * num;
  }
  let data = '$' + num.toFixed(2).replace(/(\d)(?=(\d{3})+(?!\d))/g, '$1,');
  if (isnegtive) {
    data = `(${data})`;
  }
  return data;
}
