import { Fragment } from 'react';
import { Button, Modal } from 'react-bootstrap';
import { useSelector } from 'react-redux';
import { BackArrowIcon } from 'assets/images/BackArrowIcon/BackArrowIcon';
import { CloseIcon } from 'assets/images/CloseIcon/CloseIcon';
import { Divider } from 'components/common/Divider/Divider';
import DragAndDrop from 'components/common/DragAndDrop/DragAndDrop';
import { IsAllowedACL } from 'utils/aclHelper';
import { ModuleACL } from 'enums/entitlements.ts';

const AttachmentModal = props => {
  const user = useSelector(state => state.auth);

  const {
    showModal,
    setShowModal,
    setFileUploadInprogress,
    uploadedFilesArray,
    handleFileUpload,
    handleDeleteFileFromArray,
    fileUploadInprogress,
  } = props;

  return (
    <Modal
      show={showModal}
      onHide={() => setShowModal(false)}
      size="default"
      dialogClassName="timesheetPreviewDialog"
      aria-labelledby="contained-modal-title-vcenter"
      centered
    >
      <Modal.Header closeButton={false}>
        <div
          style={{
            display: 'flex',
            justifyContent: 'flex-start',
            width: '100%',
            height: '26px',
          }}
        >
          Upload Documents
          <div
            style={{ marginLeft: 'auto', cursor: 'pointer' }}
            onClick={e => setShowModal(false)}
          >
            <CloseIcon />
          </div>
        </div>
      </Modal.Header>
      <Divider />
      <Modal.Body className="invoicePreviewModalBody">
        <div className="uploadSection">
          <DragAndDrop
            setFileUploadInprogress={setFileUploadInprogress}
            uploadedFilesArray={uploadedFilesArray}
            handleFileUpload={handleFileUpload}
            handleDeleteFileFromArray={handleDeleteFileFromArray}
            allowedMimeTypes={['application/pdf']}
          />
        </div>
        <div className="btnArea">
          {IsAllowedACL(user, ModuleACL.TIMESHEET, 'can_export_timesheet') && (
            <Fragment>
              <Button
                variant="primary"
                disabled={
                  uploadedFilesArray.length < 0 ||
                  uploadedFilesArray.length > 12 ||
                  fileUploadInprogress
                }
                onClick={e => setShowModal(false)}
              >
                Upload
              </Button>
              <Button variant="secondary" onClick={e => setShowModal(false)}>
                Cancel
              </Button>
            </Fragment>
          )}
        </div>
      </Modal.Body>
    </Modal>
  );
};

export { AttachmentModal };
