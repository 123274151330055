import { createSlice } from '@reduxjs/toolkit';

const initialState = {
  isLoading: false,
  error: false,
  errorResponse: {},
  items: [],
  pagination: {
    totalItems: 0,
    currentPage: 1,
    itemsPerPage: 10,
    sortBy: '',
    sortOrder: 'asc',
  },
  recordItems: [],
  inputList: [
    {
      FilterValue: '',
      input_rank: null,
      selectFilterValue: '',
      selectFilterLabel: '',
      presentIcon: '',
      filerForm: false,
      FilterLabel: '',
      selectedFilterRecords: [],
    },
  ],
  crewTypeData: [],
};

const crewTypeSlice = createSlice({
  name: 'crewTypeSlice',
  initialState,
  reducers: {
    fetchCrewTypeDetails(state, action) {
      state.isLoading = false;
    },
    setInputFilterData(state, action) {
      if (action.payload.length != 0) {
        state.inputList = action.payload;
      } else {
        const stateValues = { ...state };
        stateValues.inputList = [...initialState.inputList];
        return { ...stateValues };
      }
    },
    setAddNewInputFilterData(state, action) {
      const stateValues = { ...state };
      stateValues.inputList = [...stateValues.inputList, action.payload];
      return { ...stateValues };
    },
    fetchAdminCrewType(state, action) {
      const stateValues = { ...state };
      stateValues.isLoading = false;
      stateValues.pagination = {
        ...stateValues.pagination,
        ...action.payload,
      };
      return { ...stateValues };
    },
    updateCrewTypeData(state, action) {
      state.isLoading = false;
    },
    fetchSelectedAdminCrewList(state) {
      state.isLoading = false;
    },
    setAdminCrewTypeData(state, action) {
      const crewTypeList = [];

      action.payload?.rows.forEach(obj => {
        crewTypeList.push({
          crewType: obj.crewType,
          crewDesc: obj.crewDesc,
          dutyCode: obj.dutyCode,
          crewNmbr: obj.crewNmbr,
        });
      });
      const stateValues = { ...state };
      stateValues.crewTypeData = [...crewTypeList];
      stateValues.pagination = {
        ...stateValues.pagination,
        ...action.payload?.pagination,
      };
      return { ...stateValues };
    },
    setSelectedAdminCrewRecords(state, action) {
      const selectedAdminCrewRecords = [];
      action.payload?.forEach(obj => {
        let objValues = Object.values(obj);
        selectedAdminCrewRecords.push({
          label: objValues[0],
          value: objValues[0],
        });
      });
      const stateValues = { ...state };
      stateValues.recordItems = [...selectedAdminCrewRecords];
      return { ...stateValues };
    },
    setCrewTypeDetails(state, action) {
      const stateValues = { ...state };
      stateValues.items = action.payload;
      return { ...stateValues };
    },
    setAdminCrewtypePagination(state, action) {
      const stateValues = { ...state };
      stateValues.pagination = {
        ...stateValues.pagination,
        ...action.payload,
      };
      return { ...stateValues };
    },

    resetCrewType() {
      return { ...initialState };
    },

    unsetError(state) {
      const stateValues = { ...state };
      stateValues.error = false;
      stateValues.errorResponse = {};
      return { ...stateValues };
    },

    setError(state, action) {
      const stateValues = { ...state };
      stateValues.error = true;
      stateValues.errorResponse = action.payload;
      return { ...stateValues };
    },
  },
});

export const {
  fetchCrewTypeDetails,
  setInputFilterData,
  setAddNewInputFilterData,
  fetchSelectedAdminCrewList,
  setSelectedAdminCrewRecords,
  fetchAdminCrewType,
  updateCrewTypeData,
  setCrewTypeDetails,
  setAdminCrewTypeData,
  setAdminCrewtypePagination,
  resetCrewType,
  setError,
  unsetError,
} = crewTypeSlice.actions;

export default crewTypeSlice.reducer;
