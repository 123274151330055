import react from 'react';

export const CloseIcon = () => {
  return (
    <svg
      xmlns="http://www.w3.org/2000/svg"
      width="26"
      height="26"
      viewBox="0 0 26 26"
    >
      <path
        data-name="Path 3894"
        d="m847.083-4665.855-1.145-1.145-5.4 5.4-5.4-5.4-1.138 1.145 5.4 5.4-5.4 5.4 1.145 1.145 5.4-5.4 5.4 5.4 1.145-1.145-5.4-5.4z"
        transform="translate(-827.458 4673.542)"
        style={{ fill: '#161616' }}
      />
    </svg>
  );
};
