import react from 'react';

export const UploadPDFIcon = () => {
  return (
    <svg
      xmlns="http://www.w3.org/2000/svg"
      width="80"
      height="80"
      viewBox="0 0 80 80"
    >
      <g data-name="Group 12981">
        <path
          data-name="Rectangle 5539"
          style={{ fill: 'none' }}
          d="M0 0h80v80H0z"
        />
      </g>
      <g data-name="Group 12982">
        <path
          data-name="Path 25941"
          d="M51.415 41.255v10.16H10.774v-10.16H4v10.16a6.794 6.794 0 0 0 6.774 6.774h40.641a6.794 6.794 0 0 0 6.774-6.774v-10.16zM14.16 20.934l4.775 4.775 8.772-8.738v27.67h6.774v-27.67l8.772 8.738 4.775-4.775L31.094 4z"
          transform="translate(8.905 8.906)"
          style={{ fill: '#025a95' }}
        />
      </g>
    </svg>
  );
};
