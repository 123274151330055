import React, { useEffect } from 'react';
import { BackArrowIcon } from 'assets/images/BackArrowIcon/BackArrowIcon';
import './UnitRevisions.scss';
import { PDFViewer } from 'components/common/PDFviewer/PDFViewer';
import { unitRevisionURL } from '../Constant/constant';
import { Page } from 'enums/page.ts';
import { setActivePage } from 'store/slices/pageSlice';
import { useDispatch } from 'react-redux';

const UnitRevisions = () => {
  const dispatch = useDispatch();
  useEffect(() => {
    dispatch(setActivePage(Page.ADDITIONAL_LINKS));
  });
  return (
    <>
      <div className="aepContainer aepHeader">Additional Links</div>
      <div className="aepContainer aepBreadcrumb" style={{ display: 'flex' }}>
        <div className="backArrow" title="Back">
          <BackArrowIcon />
        </div>
        Unit Revisions
      </div>
      <PDFViewer pdfUrl={unitRevisionURL.url} name={unitRevisionURL.name} />
    </>
  );
};
export { UnitRevisions };
