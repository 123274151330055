import React, { useState } from 'react';
import PropTypes from 'prop-types';
import { confirmable, createConfirmation } from 'react-confirm';
import { Modal } from 'react-bootstrap';
import { Divider } from 'components/common/Divider/Divider';
import { PDFViewer } from 'components/common/PDFviewer/PDFViewer';
import { ButtonArea } from 'components/common/Button/ButtonArea/ButtonArea';
import { Provider } from 'react-redux';
import store from 'store/configureStore';

const FileViewer = ({ show, proceed, options, file, url, filename }) => (
  <Provider store={store}>
    <Modal
      show={show}
      onHide={() => proceed(false)}
      aria-labelledby="contained-modal-title-vcenter"
      centered
    >
      <Modal.Header closeButton={true}>
        <Modal.Title>{filename || 'File'}</Modal.Title>
      </Modal.Header>
      <Divider />
      <Modal.Body>
        <PDFViewer pdfUrl={url} name={filename} file={file} /> : <></>
      </Modal.Body>
      <Modal.Footer>
        <ButtonArea
          variant="secondary"
          secondaryLabel="Close"
          secondaryOnClick={() => proceed(false)}
        ></ButtonArea>
      </Modal.Footer>
    </Modal>
  </Provider>
);

FileViewer.propTypes = {
  show: PropTypes.bool, // from confirmable. indicates if the dialog is shown or not.
  proceed: PropTypes.func, // from confirmable. call to close the dialog with promise resolved.
  options: PropTypes.object, // arguments of your confirm function
  file: PropTypes.object,
  key: PropTypes.string,
  filename: PropTypes.string,
};

// confirmable HOC pass props `show`, `dismiss`, `cancel` and `proceed` to your component.
const fDialog = confirmable(FileViewer);

// create confirm function
export const fileViewer = createConfirmation(fDialog);

// This is optional. But wrapping function makes it easy to use.
export function fileViewerWrapper(url, file, filename, options = {}) {
  return fileViewer({ url, file, filename, options });
}
