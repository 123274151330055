const compatibleUnitManualURL = {
  url: `timesheets/helpDocs/compatibleUnitManual`,
  name: 'compatibleUnitManual',
};
const standardRevisionsURL = {
  url: `timesheets/helpDocs/standardRevisions`,
  name: 'standardRevisions',
};
const unitRevisionURL = {
  url: `timesheets/helpDocs/unitRevisions`,
  name: 'unitRevision',
};
const distributionStandardURL = {
  url: `timesheets/helpDocs/distributionStandard`,
  name: 'distributionStandard',
};
export {
  compatibleUnitManualURL,
  standardRevisionsURL,
  unitRevisionURL,
  distributionStandardURL,
};
