import queryString from 'query-string';
import { http } from 'services/helpers/BaseApi.ts';

const rootURI = '/caa-cu';
const recordURI = rootURI + '/distinct/records';

export const retrieveCsvFile = async qParams => {
  const queryParams = {
    export: 'csv',
    vendorName: qParams.contractor,
    company: qParams.company,
    cdOrgUnit: qParams.district,
    workorder: qParams.workOrder,
    wr: qParams.workRequest,
  };
  if (qParams.checkedRows.length) {
    queryParams['md5'] = qParams.checkedRows.join(',');
  } else {
    queryParams['limit'] = -1;
  }

  await http.downloadFile({
    url: `${rootURI}/export/csv?${queryString.stringify(queryParams)}`,
    type: 'application/csv',
    name: '',
    extension: 'csv',
  });
};

export const retrieveCu = async qParams => {
  try {
    const queryParams = {
      sortBy: qParams.sortBy,
      sortOrder: qParams.sortOrder,
      page: qParams.currentPage,
      limit: qParams.itemsPerPage,
      vendorName: qParams.contractor,
      company: qParams.company,
      cdOrgUnit: qParams.district,
      workorder: qParams.workOrder,
      wr: qParams.workRequest,
    };

    return await http.get(`${rootURI}/?${queryString.stringify(queryParams)}`);
  } catch (error) {
    return error;
  }
};

export const retrieveContractors = async () => {
  try {
    return await http.get(`${recordURI}/vendorName`, { params: { limit: -1 } });
  } catch (error) {
    return error;
  }
};

export const retrieveCompanies = async () => {
  try {
    return await http.get(`${recordURI}/company`, { params: { limit: -1 } });
  } catch (error) {
    return error;
  }
};

export const retrieveDistricts = async () => {
  try {
    return await http.get(`${recordURI}/cdOrgUnit`, { params: { limit: -1 } });
  } catch (error) {
    return error;
  }
};
