import { compatibleUnitGroupFields } from 'enums/fields.ts';

const downloadExcelServiceTypeValue = 'compatibleUnits';
const serviceType = 'bidrateCus';

const serviceTypeName = 'Compatible Unit Groups (Overhead)';
const downloadExcelServiceTypeName = serviceTypeName;
const options = [
  { value: 'Effective End Date', key: 'effEndDate' },
  { value: 'Install Price($)', key: 'installPrice' },
  { value: 'Remove Price($)', key: 'removePrice' },
  { value: 'Transfer Price($)', key: 'transferPrice' },
  { value: 'Base Fuel Rate($)', key: 'baseFuelRate' },
  { value: 'Fuel Tolerance($)', key: 'fuelTolerance' },
  { value: 'Fuel Factor($)', key: 'fuelFactor' },
  { value: 'De-energized Mark Down(%)', key: 'deEnergizedMarkDownPct' },
  { value: 'Small Job Mark up(%)', key: 'smallJobMarkUpPct' },
];

const columnsArray = [
  {
    key: compatibleUnitGroupFields.unitCode,
    value: 'Unit Code',
    required: true,
    showHeader: true,
  },
  {
    key: compatibleUnitGroupFields.unitDescription,
    value: 'Unit Description',
    required: true,
    showHeader: true,
  },
  {
    key: compatibleUnitGroupFields.unitOfMeasure,
    value: 'Unit of Measure',
    required: true,
    showHeader: true,
  },
  {
    key: compatibleUnitGroupFields.workCategory,
    value: 'Work Category',
    required: true,
    showHeader: true,
  },
  {
    key: compatibleUnitGroupFields.location,
    value: 'Location',
    required: true,
    showHeader: true,
  },
  {
    key: compatibleUnitGroupFields.effStartDate,
    value: 'Effective Start Date',
    required: true,
    showHeader: true,
  },
  {
    value: 'Effective End Date',
    key: 'effEndDate',
    required: false,
    showHeader: false,
  },
  {
    value: 'Install Price($)',
    key: 'installPrice',
    required: false,
    showHeader: false,
  },
  {
    value: 'Remove Price($)',
    key: 'removePrice',
    required: false,
    showHeader: false,
  },
  {
    value: 'Transfer Price($)',
    key: 'transferPrice',
    required: false,
    showHeader: false,
  },
  {
    value: 'Base Fuel Rate($)',
    key: 'baseFuelRate',
    required: false,
    showHeader: false,
  },
  {
    value: 'Fuel Tolerance($)',
    key: 'fuelTolerance',
    required: false,
    showHeader: false,
  },
  {
    value: 'Fuel Factor($)',
    key: 'fuelFactor',
    required: false,
    showHeader: false,
  },
  {
    value: 'De-energized Mark Down(%)',
    key: 'deEnergizedMarkDownPct',
    required: false,
    showHeader: false,
  },
  {
    value: 'Small Job Mark up(%)',
    key: 'smallJobMarkUpPct',
    required: false,
    showHeader: false,
  },
];

let autoFillColumnskeys = {
  code: 'unitCode',
  description: 'unitDescription',
  unitOfMeasure: 'unitOfMeasure',
};

let referenceObject = {
  unitCode: null,
  unitDescription: null,
  unitOfMeasure: null,
  location: null,
  effStartDate: null,
  installPrice: null,
  removePrice: null,
  transferPrice: null,
  baseFuelRate: null,
  fuelTolerance: null,
  fuelFactor: null,
  deEnergizedMarkDownPct: null,
  smallJobMarkUpPct: null,
  bidrateNmbr: null,
  updatedDate: null,
  updatedBy: null,
  enteredBy: null,
  enteredDate: null,
  workCategory: null,
  effEndDate: null,
  bidrateCuNmbr: null,
};

const header = {};
columnsArray.map(item => {
  header[item.key] = item.value;
});
options.map(item => {
  header[item.key] = item.value;
});

const compatibleExcelHeaderRefObj = [header];

// const compatibleExcelHeaderRefObj = [
//   {
//     unitCode: 'Unit Code',
//     unitDescription: 'Unit Description',
//     unitOfMeasure: 'Unit Of Measure',
//     location: 'Location',
//     effStartDate: 'Eff Start Date',
//     installPrice: 'Install Price',
//     removePrice: 'Remove Price',
//     transferPrice: 'Transfer Price',
//     baseFuelRate: 'Base Fuel Rate',
//     fuelTolerance: 'Fuel Tolerance',
//     fuelFactor: 'Fuel Factor',
//     deEnergizedMarkDownPct: 'De Energized Mark Down Pct',
//     smallJobMarkUpPct: 'Small Job Mark Up Pct',
//     bidrateNmbr: 'Bidrate Nmbr',
//     updatedDate: 'Updated Date',
//     updatedBy: 'Updated By',
//     enteredBy: 'Entered By',
//     enteredDate: 'Entered Date',
//     workCategory: 'Work Category',
//     effEndDate: 'Eff End Date',
//     bidrateCuNmbr: 'Bidrate Cu Nmbr',
//   },
// ];
export {
  options,
  columnsArray,
  referenceObject,
  serviceType,
  serviceTypeName,
  downloadExcelServiceTypeValue,
  compatibleExcelHeaderRefObj,
  downloadExcelServiceTypeName,
  autoFillColumnskeys,
};
