import { BackArrowIcon } from 'assets/images/BackArrowIcon/BackArrowIcon';
import { PurchaseOrderMenuIcon } from 'assets/images/PurchaseOrderMenuIcon/PurchaseOrderMenuIcon';
import { InvoiceRecords } from 'assets/images/InvoiceRecords/InvoiceRecords';
import { TimesheetRecords } from 'assets/images/TimesheetRecords/TimesheetRecords';
import { Rates } from 'assets/images/Rates/Rates';
import { PendingRates } from 'assets/images/PendingRates/PendingRates';
import { PurchaseOrderDetailsIcon } from 'assets/images/PurchseOrderDetailsIcon/PurchaseOrderDetailsIcon';
import { PODetailHeaderValues } from 'constants/poHeaderValues';
import { ROUTES_STRING } from 'constants/routes';
import { POStatusDisplayValue } from 'constants/statusValues';
import { ModuleACL } from 'enums/entitlements.ts';
import { PODetailHeaders } from 'enums/poDetailHeaders.ts';
import { PurchaseOrderStatus } from 'enums/statuses.ts';
import { InvoiceList } from 'pages/Invoice/InvoiceList/InvoiceList';
import { displayError } from 'components/common/Alert/ToastAlert';
import { useEffect, useState } from 'react';
import { Card, Col, Row } from 'react-bootstrap';
import 'react-datepicker/dist/react-datepicker.css';
import { useDispatch, useSelector } from 'react-redux';
import { useLocation, useNavigate } from 'react-router-dom';
import { filterAllowedOptionsACL } from 'utils/aclHelper';
import { TimesheetList } from '../../Timesheet/TimesheetList/TimesheetList';
import { PurchaseOrderDetails } from '../PurchaseOrderDetails/PruchaseOrderDetails';
import { PurchaseOrderRates } from '../PurchaseOrderRates/PurchaseOrderRates';
import { PurchaseOrderPendingRates } from '../PurchsaseOrderPendingRates/PurchaseOrderPendingRates';
import AffidavitForm from '../../CompletionForm/AffidavitForm/AffidavitForm';
import './PurchaseOrderDetails.scss';
import _ from 'lodash';

const PurchaseOrderLayout = () => {
  const location = useLocation();
  const navigate = useNavigate();
  const dispatch = useDispatch();
  const user = useSelector(state => state.auth);
  const userRole = user.configObject.userrole;

  const [currentView, setCurrentView] = useState('poDetails');

  const [cardHeaders, setCardHeaders] = useState([]);
  const responseData = useSelector(state => state.purchaseOrderDetails);

  const headers = ['Timesheet ID', 'Vendor Name', 'Status'];

  const poTimesheetTabs = [
    { key: 'all', value: 'ALL' },
    { key: 'pending', value: 'Pending' },
  ];

  const optionsByStatus = {
    [PurchaseOrderStatus.APPROVED]: {
      headers: [
        {
          key: PODetailHeaders.PO_DETAILS,
          permission: 'can_view_appv_details_purchaseorder',
        },
        {
          key: PODetailHeaders.TIMESHEET_RECORDS,
          permission: 'can_view_appv_timesheet_purchaseorder',
        },
        {
          key: PODetailHeaders.INVOICE_RECORDS,
          permission: 'can_view_appv_invoice_purchaseorder',
        },
        {
          key: PODetailHeaders.RATES,
          permission: 'can_view_appv_rates_purchaseorder',
        },
      ],
    },
    [PurchaseOrderStatus.REQUEST_VENDOR_COMPLETION_FORM]: {
      headers: [
        {
          key: PODetailHeaders.PO_DETAILS,
          permission: 'can_view_appv_details_purchaseorder',
        },
        {
          key: PODetailHeaders.TIMESHEET_RECORDS,
          permission: 'can_view_appv_timesheet_purchaseorder',
        },
        {
          key: PODetailHeaders.INVOICE_RECORDS,
          permission: 'can_view_appv_invoice_purchaseorder',
        },
        {
          key: PODetailHeaders.RATES,
          permission: 'can_view_appv_rates_purchaseorder',
        },
      ],
    },
    [PurchaseOrderStatus.SUBMIT_VENDOR_COMPLETION_FORM]: {
      headers: [
        {
          key: PODetailHeaders.PO_DETAILS,
          permission: 'can_view_appv_details_purchaseorder',
        },
        {
          key: PODetailHeaders.TIMESHEET_RECORDS,
          permission: 'can_view_appv_timesheet_purchaseorder',
        },
        {
          key: PODetailHeaders.INVOICE_RECORDS,
          permission: 'can_view_appv_invoice_purchaseorder',
        },
        {
          key: PODetailHeaders.RATES,
          permission: 'can_view_appv_rates_purchaseorder',
        },
        {
          key: PODetailHeaders.COMPLETION_FORM,
          permission: 'can_submit_completion_form',
        },
      ],
    },
    [PurchaseOrderStatus.APPROVE_VENDOR_COMPLETION_FORM]: {
      headers: [
        {
          key: PODetailHeaders.PO_DETAILS,
          permission: 'can_view_appv_details_purchaseorder',
        },
        {
          key: PODetailHeaders.TIMESHEET_RECORDS,
          permission: 'can_view_appv_timesheet_purchaseorder',
        },
        {
          key: PODetailHeaders.INVOICE_RECORDS,
          permission: 'can_view_appv_invoice_purchaseorder',
        },
        {
          key: PODetailHeaders.RATES,
          permission: 'can_view_appv_rates_purchaseorder',
        },
        {
          key: PODetailHeaders.COMPLETION_FORM,
          permission: 'can_submit_completion_form',
        },
      ],
    },
    [PurchaseOrderStatus.APPROVED_PENDING_RATES_REVIEW]: {
      headers: [
        {
          key: PODetailHeaders.PO_DETAILS,
          permission: 'can_view_appv_prr_details_purchaseorder',
        },
        {
          key: PODetailHeaders.TIMESHEET_RECORDS,
          permission: 'can_view_appv_prr_timesheet_purchaseorder',
        },
        {
          key: PODetailHeaders.INVOICE_RECORDS,
          permission: 'can_view_appv_prr_invoice_purchaseorder',
        },
        {
          key: PODetailHeaders.RATES,
          permission: 'can_view_appv_prr_rates_purchaseorder',
        },
        {
          key: PODetailHeaders.PENDING_RATES_REVIEW,
          permission: 'can_view_appv_prr_rates_review_purchaseorder',
        },
      ],
    },
    [PurchaseOrderStatus.APPROVED_PENDING_RATES_REVIEW_SUBMITTED]: {
      headers: [
        {
          key: PODetailHeaders.PO_DETAILS,
          permission: 'can_view_appv_prrs_details_purchaseorder',
        },
        {
          key: PODetailHeaders.TIMESHEET_RECORDS,
          permission: 'can_view_appv_prrs_timesheet_purchaseorder',
        },
        {
          key: PODetailHeaders.INVOICE_RECORDS,
          permission: 'can_view_appv_prrs_invoice_purchaseorder',
        },
        {
          key: PODetailHeaders.RATES,
          permission: 'can_view_appv_prrs_rates_purchaseorder',
        },
        {
          key: PODetailHeaders.PENDING_RATES_REVIEW,
          permission: 'can_view_appv_prrs_rates_review_purchaseorder',
        },
      ],
    },
    [PurchaseOrderStatus.APPROVED_NO_RATES]: {
      headers: [
        {
          key: PODetailHeaders.PO_DETAILS,
          permission: 'can_view_appv_no_rates',
        },
        {
          key: PODetailHeaders.TIMESHEET_RECORDS,
          permission: 'can_view_appv_timesheet_purchaseorder',
        },
        {
          key: PODetailHeaders.INVOICE_RECORDS,
          permission: 'can_view_appv_invoice_purchaseorder',
        },
      ],
    },
    [PurchaseOrderStatus.PENDING_INITIAL_REVIEW_SUBMITTED]: {
      headers: [
        {
          key: PODetailHeaders.PO_DETAILS,
          permission: 'can_view_appv_no_rates',
        },
      ],
    },
    [PurchaseOrderStatus.PENDING_INITIAL_REVIEW]: {
      headers: [
        {
          key: PODetailHeaders.PO_DETAILS,
          permission: 'can_view_appv_no_rates',
        },
      ],
    },
    [PurchaseOrderStatus.PENDING_INITIAL_REVIEW_DECLINED]: {
      headers: [
        {
          key: PODetailHeaders.PO_DETAILS,
          permission: 'can_view_appv_no_rates',
        },
      ],
    },
    [PurchaseOrderStatus.CANCELLED_PENDING_ORDER]: {
      headers: [
        {
          key: PODetailHeaders.PO_DETAILS,
          permission: 'can_view_pend_ir_purchaseorder',
        },
      ],
    },
    [PurchaseOrderStatus.CLOSED_PENDING_ORDER]: {
      headers: [
        {
          key: PODetailHeaders.PO_DETAILS,
          permission: 'can_view_pend_ir_purchaseorder',
        },
      ],
    },
  };

  function getOptionsTab() {
    let tabs = [];
    const objByStatus = optionsByStatus[location.state.status];
    if (!_.isNil(objByStatus) && !_.isNil(objByStatus?.headers)) {
      tabs = objByStatus.headers;
    }
    return tabs;
  }

  useEffect(() => {
    /*
    const statuses = user?.configObject[Page.PURCHASE_ORDER]?.statuses;
    const headers = _.isEmpty(statuses)
      ? []
      : statuses[location.state.status]?.headers;
    */
    const optionsTab = getOptionsTab();
    const optionsAllowed = filterAllowedOptionsACL(
      user,
      ModuleACL.PURCHASEORDER,
      optionsTab,
    );

    setCardHeaders(optionsAllowed);
  }, []);

  const onError = data => {
    displayError('Something went wrong!');
  };

  const getMenuIcon = menu => {
    switch (menu) {
      case PODetailHeaderValues[PODetailHeaders.PO_DETAILS]:
        return <PurchaseOrderDetailsIcon />;
      case PODetailHeaderValues[PODetailHeaders.TIMESHEET_RECORDS]:
        return <TimesheetRecords />;
      case PODetailHeaderValues[PODetailHeaders.RATES]:
        return <Rates />;
      case PODetailHeaderValues[PODetailHeaders.INVOICE_RECORDS]:
        return <InvoiceRecords />;
      case PODetailHeaderValues[PODetailHeaders.PENDING_RATES_REVIEW]:
        return <PendingRates />;
      default:
        return <PurchaseOrderMenuIcon />;
    }
  };

  const handlePurchaseOrderCardClick = () => {
    switch (currentView) {
      case PODetailHeaders.RATES:
        return <PurchaseOrderRates />;
      case PODetailHeaders.PO_DETAILS:
        return <PurchaseOrderDetails poId={location.state.poId} />;
      case PODetailHeaders.TIMESHEET_RECORDS:
        return (
          <TimesheetList
            type="potimesheet"
            poId={location.state?.poId}
            potimesheetHeaders={headers}
            poTimesheetTabs={poTimesheetTabs}
          />
        );
      case PODetailHeaders.INVOICE_RECORDS:
        return <InvoiceList type="poinvoice" poId={location.state?.poId} />;
      case PODetailHeaders.PENDING_RATES_REVIEW:
        return <PurchaseOrderPendingRates />;
      case PODetailHeaders.COMPLETION_FORM:
        return <AffidavitForm />;
      default:
        return <PurchaseOrderDetails poId={location.state.poId} />;
    }
  };
  const handlebackbtnClick = () => {
    navigate(`/${ROUTES_STRING.purchaseOrders}`, {
      state: { from: 'poDetail' },
    });
  };

  return (
    <>
      <div className="aepContainer aepHeader">Purchase Order</div>
      <div className="aepContainer aepBreadcrumb">
        <Row>
          <Col sm={6} style={{ display: 'flex' }}>
            <div
              className="backArrow"
              // onClick={navigate(ROUTES.purchaseOrders)}
              onClick={() => handlebackbtnClick()}
            >
              <BackArrowIcon />
            </div>
            PO:{location.state.poNumber}
          </Col>
          <Col sm={6}>
            <Row className="float-right">
              <div
                className={
                  responseData.status === 'A' ? 'greenIcon' : 'statusIcon'
                }
              ></div>
              Status:&nbsp;
              {POStatusDisplayValue[responseData.status]}
            </Row>
          </Col>
        </Row>
      </div>
      <div className="POCardContainer">
        <Row>
          {location.state.status === 'F'
            ? cardHeaders
                .filter(option => option.key !== 'completionForm')
                ?.map((item, index) => (
                  <Col key={index} sm={3}>
                    <Card
                      className={
                        currentView === item.key
                          ? 'activeCard column-item'
                          : 'column-item'
                      }
                      onClick={e => setCurrentView(item.key)}
                    >
                      <Row className="cardHeader float-right">
                        <Col sm={9}>
                          <div>{PODetailHeaderValues[item.key]}</div>
                        </Col>
                        <Col sm={3}>
                          <PurchaseOrderMenuIcon />
                        </Col>
                      </Row>
                    </Card>
                  </Col>
                ))
            : cardHeaders?.map((item, index) => (
                <Col key={index} sm={3}>
                  <Card
                    className={
                      currentView === item.key
                        ? 'activeCard column-item'
                        : 'column-item'
                    }
                    onClick={e => setCurrentView(item.key)}
                  >
                    <Row className="cardHeader float-right">
                      <Col sm={9}>
                        <div>{PODetailHeaderValues[item.key]}</div>
                      </Col>
                      <Col sm={3}>
                        {getMenuIcon(PODetailHeaderValues[item.key])}
                      </Col>
                    </Row>
                  </Card>
                </Col>
              ))}
        </Row>
      </div>
      <div className="poDetailsRates">{handlePurchaseOrderCardClick()}</div>
    </>
  );
};
export { PurchaseOrderLayout };
