import React, { useEffect, useState } from 'react';
import { useSelector } from 'react-redux';
import {
  options,
  columnsArray,
  referenceObject,
  serviceType,
  serviceTypeName,
  downloadExcelServiceTypeValue,
  downloadExcelServiceTypeName,
  autoFillColumnskeys,
} from './Constant/LaborConstant';
import './BidRateSheetEntry.scss';
import BidRateTable from '../Common/BidRateTable/BidRateTable';
const LaborRates = React.memo(props => {
  const bidRateSheetDetailsList = useSelector(
    state => state.bidRateSheetList.bidRateSheetDetailsList,
  );

  const [laborRatesDetailList, setlaborRatesDetailList] = useState([]);

  useEffect(() => {
    let laborDetailsList = [];
    Object.keys(bidRateSheetDetailsList).map(function (item) {
      if (item === 'bidrateLaborRates') {
        if (item.length > 0) {
          laborDetailsList = bidRateSheetDetailsList[item];
        }
      }
    });
    setlaborRatesDetailList(laborDetailsList);
  }, []);

  // TODO: Check if data exist in store

  return (
    <>
      <BidRateTable
        serviceType={serviceType}
        serviceTypeName={serviceTypeName}
        downloadExcelServiceTypeName={downloadExcelServiceTypeName}
        downloadExcelServiceTypeValue={downloadExcelServiceTypeValue}
        options={options}
        columnsArray={columnsArray}
        referenceObject={referenceObject}
        bidRateRowPage={props.bidRateRowPage}
        bidRatesDetailList={laborRatesDetailList}
        setBidRatesDetailList={setlaborRatesDetailList}
        defaultValues={props.defaultValues}
        setValue={props.setValue}
        trigger={props.trigger}
        setError={props.setError}
        methods={props.methods}
        watch={props.watch}
        errors={props.errors}
        autoFillColumnskeys={autoFillColumnskeys}
        handleBidRateSave={props.handleBidRateSave}
      />
    </>
  );
});

export { LaborRates };
