import { Button } from 'react-bootstrap';
import './ButtonArea.scss';

const ButtonArea = ({
  primaryLabel,
  secondaryLabel,
  primaryDisabled = false,
  secondaryDisabled = false,
  primaryOnClick = () => {},
  secondaryOnClick = () => {},
  type,
  style = {},
}) => {
  return (
    <div className="btnArea" style={style}>
      {primaryLabel ? (
        <Button
          variant="primary"
          disabled={primaryDisabled}
          type={type}
          onClick={() => primaryOnClick()}
        >
          {primaryLabel}
        </Button>
      ) : (
        <></>
      )}
      {secondaryLabel ? (
        <Button
          variant="secondary"
          disabled={secondaryDisabled}
          onClick={() => secondaryOnClick()}
        >
          {secondaryLabel}
        </Button>
      ) : (
        <></>
      )}
    </div>
  );
};

export { ButtonArea };
