import { createSlice } from '@reduxjs/toolkit';

const initialState = {
  isLoading: false,
  error: false,
  errorResponse: {},
  items: [],
};

const purchaseOrderInvoiceSlice = createSlice({
  name: 'purchaseOrderInvoiceSlice',
  initialState,
  reducers: {
    fetchPurchaseOrderInvoice(state, action) {
      state.isLoading = false;
    },

    setPurchaseOrderInvoice(state, action) {
      const poInvoiceList = [];
      action.payload?.rows.forEach(obj => {
        poInvoiceList.push({
          invoiceID: obj.invoiceNmbr,
          vendor: obj['po.vendorVendor.vendor_name'],
          status: obj.caastatus,
        });
      });
      const stateValues = { ...state };
      stateValues.items = [...poInvoiceList];
      return { ...stateValues };
    },

    resetPurchaseOrderInvoice() {
      return { ...initialState };
    },

    unsetError(state) {
      const stateValues = { ...state };
      stateValues.error = false;
      stateValues.errorResponse = {};
      return { ...stateValues };
    },

    setError(state, action) {
      const stateValues = { ...state };
      stateValues.error = true;
      stateValues.errorResponse = action.payload;
      return { ...stateValues };
    },
  },
});

export const {
  fetchPurchaseOrderInvoice,
  setPurchaseOrderInvoice,
  resetPurchaseOrderInvoice,
  setError,
  unsetError,
} = purchaseOrderInvoiceSlice.actions;

export default purchaseOrderInvoiceSlice.reducer;
