import React from 'react';

export const PurchaseOrderMenuIcon = () => {
  return (
    <svg
      xmlns="http://www.w3.org/2000/svg"
      width="20.5"
      height="20.5"
      viewBox="0 0 20.5 20.5"
    >
      <g data-name="Group 12112">
        <path
          data-name="Path 25825"
          d="M28.5-60.836a10.012 10.012 0 0 1-10-10 10.011 10.011 0 0 1 10-10 10.012 10.012 0 0 1 10 10 10.013 10.013 0 0 1-10 10zm0-19.036a9.047 9.047 0 0 0-9.037 9.036A9.047 9.047 0 0 0 28.5-61.8a9.046 9.046 0 0 0 9.036-9.037 9.047 9.047 0 0 0-9.036-9.035z"
          transform="translate(-18.25 81.086)"
          style={{ fill: '#025a95', stroke: '#025a95', strokeWidth: '.5px' }}
        />
        <path
          data-name="Path 25826"
          d="M44.5-58.449h1.282V-65.5H44.5z"
          transform="translate(-35.896 70.683)"
          style={{ fill: '#025a95', stroke: '#025a95', strokeWidth: '.5px' }}
        />
        <path
          data-name="Path 25827"
          d="M44.5-46.218h6.731V-47.5H44.5z"
          transform="translate(-35.887 58.461)"
          style={{ fill: '#025a95', stroke: '#025a95', strokeWidth: '.5px' }}
        />
      </g>
    </svg>
  );
};
