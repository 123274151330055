import React from 'react';

export const TimesheetListViewIconDefault = () => {
  return (
    <svg
      xmlns="http://www.w3.org/2000/svg"
      width="36"
      height="36"
      viewBox="0 0 32 32"
    >
      <g data-name="Group 161645">
        <g
          data-name="🔲🎨 Container l Color"
          style={{ fill: '#fff', stroke: '#4a4a4a', strokeMiterlimit: '10' }}
        >
          <path
            d="M4 0h28v32H4a4 4 0 0 1-4-4V4a4 4 0 0 1 4-4z"
            style={{ stroke: 'none' }}
          />
          <path
            d="M4 .5h27.5v31H4A3.5 3.5 0 0 1 .5 28V4A3.5 3.5 0 0 1 4 .5z"
            style={{ fill: 'none' }}
          />
        </g>
        <path
          data-name="Path 28516"
          d="M0 0h24v24H0z"
          style={{ fill: 'none' }}
          transform="translate(4 4)"
        />
        <path
          data-name="Path 28517"
          d="M4 18h4a1 1 0 0 0 0-2H4a1 1 0 0 0 0 2zM3 7a1 1 0 0 0 1 1h16a1 1 0 0 0 0-2H4a1 1 0 0 0-1 1zm1 6h10a1 1 0 0 0 0-2H4a1 1 0 0 0 0 2z"
          style={{ fill: '#4a4a4a' }}
          transform="translate(4 4)"
        />
      </g>
    </svg>
  );
};

export const VendorCompanyID = () => {
  return (
    <svg
      xmlns="http://www.w3.org/2000/svg"
      width="20"
      height="20"
      viewBox="0 0 20 25.256"
    >
      <path
        id="shield_person_FILL0_wght300_GRAD0_opsz24"
        d="M190-844.845a4.186,4.186,0,0,1-3.079-1.254,4.186,4.186,0,0,1-1.254-3.079,4.187,4.187,0,0,1,1.254-3.08A4.186,4.186,0,0,1,190-853.511a4.186,4.186,0,0,1,3.079,1.254,4.187,4.187,0,0,1,1.254,3.08,4.187,4.187,0,0,1-1.254,3.079A4.186,4.186,0,0,1,190-844.845Zm0-2a2.259,2.259,0,0,0,1.664-.669,2.259,2.259,0,0,0,.669-1.664,2.259,2.259,0,0,0-.669-1.664,2.259,2.259,0,0,0-1.664-.669,2.259,2.259,0,0,0-1.664.669,2.259,2.259,0,0,0-.669,1.664,2.259,2.259,0,0,0,.669,1.664A2.259,2.259,0,0,0,190-846.845Zm0,13.641a12.878,12.878,0,0,1-7.163-5.092A14.549,14.549,0,0,1,180-847.045v-7.672l10-3.744,10,3.744v7.672a14.549,14.549,0,0,1-2.837,8.749A12.878,12.878,0,0,1,190-833.2ZM190-845.832Zm0-10.5-8,2.987v6.3a12.481,12.481,0,0,0,.545,3.68,13.754,13.754,0,0,0,1.514,3.315,14.065,14.065,0,0,1,2.824-1.074,12.235,12.235,0,0,1,3.117-.387,12.235,12.235,0,0,1,3.117.387,14.065,14.065,0,0,1,2.824,1.074,13.754,13.754,0,0,0,1.514-3.315,12.481,12.481,0,0,0,.545-3.68v-6.3Zm0,16.82a10.824,10.824,0,0,0-2.481.28,11.1,11.1,0,0,0-2.25.785,11.853,11.853,0,0,0,2.183,1.906A9.75,9.75,0,0,0,190-835.311a9.646,9.646,0,0,0,2.541-1.229,11.955,11.955,0,0,0,2.177-1.906,11.125,11.125,0,0,0-2.244-.785A10.741,10.741,0,0,0,190-839.511Z"
        transform="translate(-180.001 858.46)"
        fill="#4a4a4a"
      ></path>
    </svg>
  );
};
