import { Col, Row } from 'react-bootstrap';

export default function SiteFooter() {
  return (
    <Row style={{ height: 'auto' }}>
      <Col sm={12} className="footer">
        &copy; {new Date().getFullYear()} American Electric Power
      </Col>
    </Row>
  );
}
