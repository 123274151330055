import React from 'react';

export const PendingIcon = () => {
  return (
    <svg width="18" height="18" viewBox="0 0 18 18">
      <g data-name="Group 14535">
        <path
          data-name="Path 26062"
          d="M11 2a9 9 0 1 0 9 9 9 9 0 0 0-9-9zM6.5 12.35A1.35 1.35 0 1 1 7.85 11a1.348 1.348 0 0 1-1.35 1.35zm4.5 0A1.35 1.35 0 1 1 12.35 11 1.348 1.348 0 0 1 11 12.35zm4.5 0A1.35 1.35 0 1 1 16.85 11a1.348 1.348 0 0 1-1.35 1.35z"
          style={{ fill: '#9e9fa1' }}
          transform="translate(-2 -2)"
        />
      </g>
    </svg>
  );
};
