// Imports
import ___CSS_LOADER_API_SOURCEMAP_IMPORT___ from "../../../../node_modules/react-scripts/node_modules/css-loader/dist/runtime/sourceMaps.js";
import ___CSS_LOADER_API_IMPORT___ from "../../../../node_modules/react-scripts/node_modules/css-loader/dist/runtime/api.js";
var ___CSS_LOADER_EXPORT___ = ___CSS_LOADER_API_IMPORT___(___CSS_LOADER_API_SOURCEMAP_IMPORT___);
// Module
___CSS_LOADER_EXPORT___.push([module.id, `.warning-toast {
  background-color: #fdf2e0 !important;
  color: #a95500 !important;
}`, "",{"version":3,"sources":["webpack://./src/components/common/Alert/ToastAlert.scss"],"names":[],"mappings":"AAAA;EACE,oCAAA;EACA,yBAAA;AACF","sourcesContent":[".warning-toast {\n  background-color: #fdf2e0 !important;\n  color: #a95500 !important;\n}\n"],"sourceRoot":""}]);
// Exports
___CSS_LOADER_EXPORT___.locals = {};
export default ___CSS_LOADER_EXPORT___;
