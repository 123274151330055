import { AEPLogoIcon } from 'assets/images/AEPLogoIcon/AEPLogoIcon';
import { AdditionalLinkIcon } from 'assets/images/AdditionalLinkIcon/AdditionalLinkIcon';
import { BidRateSheetIcon } from 'assets/images/BidRateSheetIcon/BidRateSheetIcon';
import { HomeIcon } from 'assets/images/HomeIcon/HomeIcon';
import { InvoiceIcon } from 'assets/images/InvoiceIcon/InvoiceIcon';
import { LogoutIcon } from 'assets/images/LogoutIcon/LogoutIcon';
import { PurchaseOrderIcon } from 'assets/images/PurchaseOrderIcon/PurchaseOrderIcon';
import { TimesheetIcon } from 'assets/images/TimesheetIcon/TimesheetIcon';
import UserIcon from 'assets/images/UserIcon.png';
import { ShowForPermission } from 'components/common/ShowPermission/ShowPermission';
import { ROUTES } from 'constants/routes';
import { Page } from 'enums/page.ts';
import { useEffect, useState } from 'react';
import Button from 'react-bootstrap/Button';
import Col from 'react-bootstrap/Col';
import Image from 'react-bootstrap/Image';
import Offcanvas from 'react-bootstrap/Offcanvas';
import Row from 'react-bootstrap/Row';
import { Menu, MenuItem, Sidebar, SubMenu } from 'react-pro-sidebar';
import { useDispatch, useSelector } from 'react-redux';
import { useMediaQuery } from 'react-responsive';
import { useNavigate } from 'react-router-dom';
import { ToastContainer } from 'react-toastify';
import { logoutUser } from 'store/slices/authenticationSlice';
import './SiteHeader.scss';

import { ReactComponent as Burger } from '../../assets/images/hamburger.v2.svg';

export default function SiteHeader() {
  const user = useSelector(state => state.auth);
  const dispatch = useDispatch();
  const activePage = useSelector(state => state.page.activePage);
  const navigate = useNavigate();
  const isMobile = useMediaQuery({ query: '(max-width: 575px)' });

  const [toggled, setToggled] = useState(false);

  const handleClose = () => setToggled(false);
  const handleShow = () => setToggled(true);

  useEffect(() => {
    const activeLink = document.getElementById(activePage);
    const anchors = document.getElementsByTagName('a');

    Array.from(anchors).forEach(anchor => {
      anchor.classList.remove('activePage');
    });
    activeLink?.classList.add('activePage');
  }, [activePage]);

  const handleClick = route => {
    navigate(route);
  };

  const logout = () => {
    dispatch(logoutUser());
  };

  return (
    <div className="siteHeader">
      <Row>
        <Col sm={9} className="logoSection">
          {/* <Image src={AEPLogo} />
           */}
          <span>
            <AEPLogoIcon />
          </span>
          {!isMobile && <span>Contract Administration Application</span>}
        </Col>
        <Col sm={3} className="userProfile">
          <Row className={'float-right'}>
            {isMobile ? (
              <>
                <Col sm={12}>
                  <Button
                    style={{ width: '50px' }}
                    className=""
                    onClick={() => setToggled(!toggled)}
                  >
                    {!toggled ? <Burger /> : <span>X</span>}
                  </Button>
                </Col>
                <div style={{ position: 'fixed', top: 0 }}>
                  <Offcanvas
                    placement="end"
                    show={toggled}
                    onHide={handleClose}
                    backdrop="false"
                  >
                    <Offcanvas.Header></Offcanvas.Header>
                    <Offcanvas.Body>
                      <Sidebar
                        //style={{ width: '100%' }} wait until i chat with design team to implement
                        backgroundColor="white"
                        defaultCollapsed={false}
                        toggled={toggled}
                      >
                        <Menu onClick={() => setToggled(!toggled)}>
                          <MenuItem
                            icon={<Image src={UserIcon} roundedCircle />}
                          >
                            {user.userDisplayname}
                          </MenuItem>
                          <MenuItem
                            id={Page.HOME}
                            icon={<HomeIcon />}
                            onClick={e => handleClick('/')}
                          >
                            Home
                          </MenuItem>

                          <ShowForPermission
                            permission="can_view_purchaseorder"
                            permissionModule={Page.GENERAL}
                          >
                            <MenuItem
                              id={Page.PURCHASE_ORDER}
                              icon={<PurchaseOrderIcon />}
                              onClick={e => handleClick(ROUTES.purchaseOrders)}
                            >
                              POs
                            </MenuItem>
                          </ShowForPermission>

                          <ShowForPermission
                            permission="can_view_timesheet"
                            permissionModule={Page.GENERAL}
                          >
                            <MenuItem
                              id={Page.TIMESHEET}
                              icon={<TimesheetIcon />}
                              onClick={e => handleClick(ROUTES.timesheets)}
                            >
                              Timesheets
                            </MenuItem>
                          </ShowForPermission>

                          <ShowForPermission
                            permission="can_view_invoice"
                            permissionModule={Page.GENERAL}
                          >
                            <MenuItem
                              id={Page.INVOICE}
                              icon={<InvoiceIcon />}
                              onClick={e => handleClick(ROUTES.invoices)}
                            >
                              Invoices
                            </MenuItem>
                          </ShowForPermission>

                          <ShowForPermission
                            permission="can_view_bidratesheet"
                            permissionModule={Page.GENERAL}
                          >
                            <MenuItem
                              id={Page.BID_RATE_SHEET}
                              icon={<BidRateSheetIcon />}
                              onClick={e => handleClick(ROUTES.bidRateSheets)}
                            >
                              Bid Rate Sheets
                            </MenuItem>
                          </ShowForPermission>

                          <ShowForPermission
                            permission="can_view_additionallinks"
                            permissionModule={Page.GENERAL}
                          >
                            <SubMenu
                              label="Additional Links"
                              icon={<AdditionalLinkIcon />}
                              id={Page.ADDITIONAL_LINKS}
                              defaultOpen={true}
                            >
                              <MenuItem
                                id={Page.COMPATIBLE_UNIT_MANUAL}
                                onClick={e =>
                                  handleClick(ROUTES.compatibleUnitManual)
                                }
                              >
                                Compatible Unit Manual
                              </MenuItem>
                              <MenuItem
                                id={Page.UNIT_REVISIONS}
                                onClick={e => handleClick(ROUTES.unitRevisions)}
                              >
                                Unit Revisions
                              </MenuItem>
                              <MenuItem
                                id={Page.STANDARD_REVISIONS}
                                onClick={e =>
                                  handleClick(ROUTES.standardRevisions)
                                }
                              >
                                Standard Revisions
                              </MenuItem>
                              <MenuItem
                                id={Page.AEP_DISTRIBUTIONS_STANDARD}
                                onClick={e =>
                                  handleClick(ROUTES.aepDistributionsStandard)
                                }
                              >
                                AEP Distributions Standard
                              </MenuItem>
                              <MenuItem
                                id={Page.CU_DOWNLOAD}
                                onClick={e => handleClick(ROUTES.cuDownload)}
                                disabled={false}
                              >
                                CU Downloads
                              </MenuItem>
                            </SubMenu>
                          </ShowForPermission>

                          <MenuItem icon={<LogoutIcon />} onClick={logout}>
                            Logout
                          </MenuItem>
                        </Menu>
                      </Sidebar>
                    </Offcanvas.Body>
                  </Offcanvas>
                </div>
              </>
            ) : (
              <>
                <Col sm={3}>
                  <Image src={UserIcon} roundedCircle />
                </Col>
                <Col sm={9}>
                  <div>{user.userDisplayname}</div>
                </Col>
              </>
            )}
          </Row>
        </Col>
      </Row>
      <Row>
        <Col sm={12}>
          <ToastContainer
            position="top-center"
            hideProgressBar={true}
            closeOnClick={true}
            limit={1}
            autoClose={5000}
            style={{
              width: '100%',
              fontSize: '16px',
              borderRadius: '4px',
              padding: '0 0 24px 75px',
              position: 'absolute',
            }}
          />
        </Col>
      </Row>
    </div>
  );
}
