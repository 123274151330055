import React from 'react';

export const MinimizeIcon = () => {
  return (
    <svg width="28" height="4" viewBox="0 0 28 4">
      <path
        data-name="Subtraction 2"
        d="M26 4H2a2 2 0 0 1 0-4h24a2 2 0 0 1 0 4z"
        style={{ fill: '#4a4a4a' }}
      />
    </svg>
  );
};
