import { useEffect, useState } from 'react';
import { useSelector } from 'react-redux';
import {
  options,
  columnsArray,
  referenceObject,
  serviceType,
  serviceTypeName,
  downloadExcelServiceTypeValue,
  downloadExcelServiceTypeName,
  autoFillColumnskeys,
} from './Constant/MarkupsConstant';
import './BidRateSheetEntry.scss';
import BidRateTable from '../Common/BidRateTable/BidRateTable';

const Markups = props => {
  const bidRateSheetDetailsList = useSelector(
    state => state.bidRateSheetList.bidRateSheetDetailsList,
  );

  const [markupsDetailList, setMarkupsDetailList] = useState([]);

  useEffect(() => {
    let markupDetailsList = [];
    Object.keys(bidRateSheetDetailsList).map(function (item) {
      if (item === 'bidrateUnionRates') {
        if (item.length > 0) {
          markupDetailsList = bidRateSheetDetailsList[item];
        }
      }
    });
    setMarkupsDetailList(markupDetailsList);
  }, []);

  // TODO: Check if data exist in store
  return (
    <>
      <BidRateTable
        serviceType={serviceType}
        serviceTypeName={serviceTypeName}
        downloadExcelServiceTypeName={downloadExcelServiceTypeName}
        downloadExcelServiceTypeValue={downloadExcelServiceTypeValue}
        options={options}
        columnsArray={columnsArray}
        referenceObject={referenceObject}
        bidRateRowPage={props.bidRateRowPage}
        bidRatesDetailList={markupsDetailList}
        setBidRatesDetailList={setMarkupsDetailList}
        setValue={props.setValue}
        trigger={props.trigger}
        setError={props.setError}
        methods={props.methods}
        watch={props.watch}
        errors={props.errors}
        autoFillColumnskeys={autoFillColumnskeys}
        handleBidRateSave={props.handleBidRateSave}
      />
    </>
  );
};

export { Markups };
