import { Form, Modal, Row, Col, Container } from 'react-bootstrap';
import { ButtonArea } from 'components/common//Button/ButtonArea/ButtonArea';
import { Divider } from 'components/common/Divider/Divider';
import './PurchaseOrderPendingRates.scss';
import { Fragment } from 'react';
import _ from 'lodash';

const ResourceDetailsModal = ({ show, setShowModal, resourceDetails }) => {
  return (
    <Modal
      size="xl"
      show={show}
      dialogClassName="resourceWidth"
      aria-labelledby="contained-modal-title-vcenter"
      centered
    >
      <Modal.Header>
        <Modal.Title id="containedResourceDetails">
          Resource Details
        </Modal.Title>
      </Modal.Header>
      <Divider />
      <Modal.Body>
        {!_.isEmpty(resourceDetails) && (
          <Fragment>
            <div className="resourceInnerContainer">
              <Form>
                <Row>
                  <Col sm={4}>
                    <Form.Group controlId="form.resource">
                      <Form.Label>Labor Code</Form.Label>
                      <Form.Control
                        disabled
                        defaultValue={resourceDetails.code}
                      />
                    </Form.Group>
                  </Col>
                  <Col sm={4}>
                    <Form.Group controlId="form.description">
                      <Form.Label>Labor Description</Form.Label>
                      <Form.Control
                        disabled
                        defaultValue={resourceDetails.description}
                      />
                    </Form.Group>
                  </Col>
                  <Col sm={4}>
                    <Form.Group controlId="form.labourType">
                      <Form.Label>Labor Type</Form.Label>
                      <Form.Control
                        disabled
                        defaultValue={resourceDetails.LaborType}
                      />
                    </Form.Group>
                  </Col>
                  <Col sm={4}>
                    <Form.Group controlId="form.local">
                      <Form.Label>Local</Form.Label>
                      <Form.Control
                        disabled
                        defaultValue={resourceDetails.local}
                      />
                    </Form.Group>
                  </Col>
                  <Col sm={4}>
                    <Form.Group controlId="form.agmtSeqNo">
                      <Form.Label>Agmt Seq No</Form.Label>
                      <Form.Control
                        disabled
                        defaultValue={resourceDetails.agmtSeqNo}
                      />
                    </Form.Group>
                  </Col>
                  <Col sm={4}>
                    <Form.Group controlId="form.effectiveSmallToolAddar">
                      <Form.Label>Small Tools Adders Amount</Form.Label>
                      <Form.Control
                        disabled
                        defaultValue={resourceDetails.smallToolsadder}
                      />
                    </Form.Group>
                  </Col>

                  <Col sm={4}>
                    <Form.Group controlId="form.haurityAddar">
                      <Form.Label>Craft Hourly Adder Amount</Form.Label>
                      <Form.Control
                        disabled
                        defaultValue={resourceDetails.craftHourlyAdderAmount}
                      />
                    </Form.Group>
                  </Col>
                  <Col sm={4}>
                    <Form.Group controlId="form.baseHoulyWage">
                      <Form.Label>Base Hourly wage</Form.Label>
                      <Form.Control
                        disabled
                        defaultValue={resourceDetails.baseHourlyWage}
                      />
                    </Form.Group>
                  </Col>
                  <Col sm={4}>
                    <Form.Group controlId="form.ppeadder">
                      <Form.Label>PPE Adder Amount</Form.Label>
                      <Form.Control
                        disabled
                        defaultValue={resourceDetails.ppeadder}
                      />
                    </Form.Group>
                  </Col>
                  <Col sm={4}>
                    <Form.Group controlId="form.effectiveFrom">
                      <Form.Label>Effective Start Date</Form.Label>
                      <Form.Control
                        disabled
                        defaultValue={resourceDetails.effectiveFrom}
                      />
                    </Form.Group>
                  </Col>
                  <Col sm={4}>
                    <Form.Group controlId="form.effectiveTo">
                      <Form.Label>Effective End Date</Form.Label>
                      <Form.Control
                        disabled
                        defaultValue={resourceDetails.effectiveTo}
                      />
                    </Form.Group>
                  </Col>
                </Row>
              </Form>
            </div>
            <div>
              <Fragment>
                {resourceDetails.shifts.map((items, index) => (
                  <div className="shiftContainer">
                    {index == 0 ? (
                      <div className="headerContainer">1st shift</div>
                    ) : index == 1 ? (
                      <div className="headerContainer">2nd shift</div>
                    ) : (
                      <div className="headerContainer">3rd shift</div>
                    )}
                    <Form>
                      <Row>
                        <Col sm={4}>
                          <Form.Group controlId="form.st">
                            <Form.Label>ST</Form.Label>
                            <Form.Control disabled defaultValue={items.ST} />
                          </Form.Group>
                        </Col>
                        <Col sm={4}>
                          <Form.Group controlId="form.pt">
                            <Form.Label>PT</Form.Label>
                            <Form.Control disabled defaultValue={items.PT} />
                          </Form.Group>
                        </Col>
                        <Col sm={4}>
                          <Form.Group controlId="form.ot">
                            <Form.Label>OT</Form.Label>
                            <Form.Control disabled defaultValue={items.OT} />
                          </Form.Group>
                        </Col>
                        <Col sm={4}>
                          <Form.Group controlId="form.dt">
                            <Form.Label>DT</Form.Label>
                            <Form.Control disabled defaultValue={items.DT} />
                          </Form.Group>
                        </Col>
                        <Col sm={4}>
                          <Form.Group controlId="form.adjustHourly">
                            <Form.Label>Adjust Hourly</Form.Label>
                            <Form.Control
                              disabled
                              defaultValue={items.adjustHourly}
                            />
                          </Form.Group>
                        </Col>
                        <Col sm={4}>
                          <Form.Group controlId="form.stTaxableFradges">
                            <Form.Label>ST Taxable Fringes</Form.Label>
                            <Form.Control
                              disabled
                              defaultValue={items.stTaxableFradges}
                            />
                          </Form.Group>
                        </Col>
                        <Col sm={4}>
                          <Form.Group controlId="form.stNonTaxableFradges">
                            <Form.Label>ST Non Taxable Fringes</Form.Label>
                            <Form.Control
                              disabled
                              defaultValue={items.stNonTaxableFradges}
                            />
                          </Form.Group>
                        </Col>
                        <Col sm={4}>
                          <Form.Group controlId="form.ptTaxableFrdges">
                            <Form.Label>PT Taxable Fringes</Form.Label>
                            <Form.Control
                              disabled
                              defaultValue={items.ptTaxableFradges}
                            />
                          </Form.Group>
                        </Col>
                        <Col sm={4}>
                          <Form.Group controlId="form.ptNonTaxableFradges">
                            <Form.Label>PT Non Taxable Fradges</Form.Label>
                            <Form.Control
                              disabled
                              defaultValue={items.ptNonTaxableFradges}
                            />
                          </Form.Group>
                        </Col>
                        <Col sm={4}>
                          <Form.Group controlId="form.modifierCode">
                            <Form.Label>Modifier Code</Form.Label>
                            <Form.Control
                              disabled
                              defaultValue={items.modifierCode}
                            />
                          </Form.Group>
                        </Col>
                        <Col sm={4}>
                          <Form.Group controlId="form.cap">
                            <Form.Label>Cap</Form.Label>
                            <Form.Control disabled defaultValue={items.cap} />
                          </Form.Group>
                        </Col>
                        <Col sm={4}>
                          <Form.Group controlId="form.copied">
                            <Form.Label>Copied</Form.Label>
                            <Form.Control
                              disabled
                              defaultValue={items.copied}
                            />
                          </Form.Group>
                        </Col>
                      </Row>
                    </Form>
                  </div>
                ))}
              </Fragment>
            </div>
          </Fragment>
        )}
      </Modal.Body>
      <Modal.Footer>
        <ButtonArea
          primaryLabel="Ok"
          secondaryLabel="Cancel"
          primaryOnClick={() => setShowModal(false)}
          secondaryOnClick={() => setShowModal(false)}
        />
      </Modal.Footer>
    </Modal>
  );
};

export { ResourceDetailsModal };
