import react, { useState, useRef } from 'react';
import { dateExcelToJs } from 'date-excel-to-js-and-reverse';
import { useSelector } from 'react-redux';
import { Button, Modal, Row, Col, ProgressBar } from 'react-bootstrap';
import { PDFIcon } from 'assets/images/PDFIcon/PDFIcon';
import { CheckIcon } from 'assets/images/CheckIcon/CheckIcon';
import { UploadPDFIcon } from 'assets/images/UploadPDFIcon/UploadPDFIcon';
import { Divider } from 'components/common/Divider/Divider';
import { CloseIcon } from 'assets/images/CloseIcon/CloseIcon';
import { BackArrowIcon } from 'assets/images/BackArrowIcon/BackArrowIcon';
import { useMediaQuery } from 'react-responsive';
import * as xlsx from 'xlsx';
import { isEmptyVal } from 'utils/utils';
import {
  exelRefObj,
  serviceTypeName as LaborService,
} from 'pages/BidRateSheets/BidRateSheetEntry/Constant/LaborConstant';
import {
  equipmentExcelHeaderRefObj,
  serviceTypeName as EquipmentService,
} from 'pages/BidRateSheets/BidRateSheetEntry/Constant/EquipmentConstant';
import {
  compatibleExcelHeaderRefObj,
  serviceTypeName as CompatibleService,
} from 'pages/BidRateSheets/BidRateSheetEntry/Constant/CompatibleUnitGroupConstant';
import {
  reimbursableExcelHeaderRefObj,
  serviceTypeName as ReimbursableService,
} from 'pages/BidRateSheets/BidRateSheetEntry/Constant/ReimbursableConstant';
import {
  taskCodeExcelHeaderRefObj,
  serviceTypeName as TaskcodeService,
} from 'pages/BidRateSheets/BidRateSheetEntry/Constant/TaskCodeRatesConstant';
import {
  undergroundExcelHeaderRefObj,
  serviceTypeName as UndergroundService,
} from 'pages/BidRateSheets/BidRateSheetEntry/Constant/UndergroundMultiRateConstant';
import {
  markupExcelHeaderRefObj,
  serviceTypeName as MarkupService,
} from 'pages/BidRateSheets/BidRateSheetEntry/Constant/MarkupsConstant';
import {
  unitPriceExcelHeaderRefObj,
  serviceTypeName as UnitPriceService,
} from 'pages/BidRateSheets/BidRateSheetEntry/Constant/UnitPriceRatesConstant';
import moment from 'moment';
import { ErrorIcon } from 'assets/images/ErrorIcon/ErrorIcon';
import { getMidnightDate } from 'utils/utils';

const UploadExcel = props => {
  let tempIndex;
  const {
    rows,
    setRows,
    handleAddRow,
    onSubmit,
    downloadExcelServiceTypeName,
    serviceTypeName,
    isInputDate,
    isDropDown,
    contractorName,
  } = props;
  const tabValue = props.downloadExcelServiceTypeName;
  const [count, setCount] = useState(0);
  const [errorFlag, setErrorFlag] = useState(false);
  const [dataFromExcel, setDataFromExcel] = useState([]);
  const [isContractorSame, setIsContractorSame] = useState(true);
  const [contractorNameFromSheet, setContractorNameFromSheet] = useState();
  const [invalidDateError, setInvalidDateError] = useState(false);

  const workCategoryList = useSelector(
    state => state.bidRateSheetList.workCategoryList.rows,
  );

  const locationList = useSelector(
    state => state.bidRateSheetList.locationList.rows,
  );

  const equipmentType = useSelector(
    state => state.bidRateSheetList.equipmentType,
  );

  const ownershipType = useSelector(
    state => state.bidRateSheetList.ownershipType,
  );

  //loader while excel is uploading
  const loader = counter => {
    if (counter <= 100) {
      setCount(counter);
      setTimeout(() => {
        loader(counter + 1);
      }, 1000);
    }
  };

  //not in use
  const isValidDate = dateString => {
    var regEx = /^\d{4}-\d{2}-\d{2}$/;
    if (dateString !== undefined) {
      return dateString.match(regEx) != null;
    }
    return false;
  };

  //function to check date field coming from excel is valid or invalid
  /* firstObj - all rows coming from excel
   key- field name carrying date value, 
   obj - single row , 
   mergedObj- js object created to display data on screen*/
  const checkDateField = (firstObj, key, obj, mergedObj) => {
    const newKey = firstObj[key];
    if (moment(obj[key], 'MM/DD/YYYY', true).isValid()) {
      mergedObj[newKey] = getMidnightDate(obj[key]);
    } else {
      if (obj[key]) {
        switch (typeof obj[key]) {
          case 'number':
            let baseDate = '1900-01-01'; //Excel Base Date
            let targetDate = moment(baseDate)
              .clone()
              .add(obj[key] - 2, 'days');
            let excelDate = targetDate.toISOString();
            mergedObj[newKey] = excelDate;
            break;
          case 'string':
            setInvalidDateError(true);
            mergedObj[newKey] = null;
            break;
          default:
            mergedObj[newKey] = null;
        }
      }
    }
  };

  //function to check field coming from excel should be displayed as dropdown on application
  /* firstObj - all rows coming from excel
   key- field name carrying date value, 
   obj - single row , 
   mergedObj- js object created to display data on screen*/
  const checkDropdownField = (firstObj, key, obj, mergedObj) => {
    const newKey = firstObj[key];
    switch (newKey) {
      case 'Work Category':
        let workCategory = workCategoryList.find(
          element =>
            element.description.toLowerCase() ==
            (obj[key] ? obj[key].toLowerCase() : obj[key]),
        );
        mergedObj[newKey] = workCategory ? workCategory.description : null;
        break;
      case 'Location':
        let location = locationList.find(
          element =>
            element.description.toLowerCase() ==
            (obj[key] ? obj[key].toLowerCase() : obj[key]),
        );
        mergedObj[newKey] = location ? location.description : null;
        break;
      case 'Equipment Type (Standard, Specialty)':
        let equipmentType1 = equipmentType.find(
          //find in master data and assign to correct case
          element =>
            element.toLowerCase() ==
            (obj[key] ? obj[key].toLowerCase() : obj[key]),
        );
        if (equipmentType1) {
          mergedObj[newKey] = equipmentType1;
        } else {
          //not found in the master data list, assign it to whatever user has entered
          mergedObj[newKey] = obj[key];
        }
        break;
      case 'Owned or Leased (Owned, Rental)':
        let ownershipType1 = ownershipType.find(
          //find in master data and assign to correct case
          element =>
            element.toLowerCase() ==
            (obj[key] ? obj[key].toLowerCase() : obj[key]),
        );
        if (ownershipType1) {
          mergedObj[newKey] = ownershipType1;
        } else {
          //not found in the master data list, assign it to whatever user has entered
          mergedObj[newKey] = obj[key];
        }
        break;
      default:
        break;
    }
  };

  const [dragActive, setDragActive] = useState(false);

  //function to upload file by selecting from file explorer
  const handleUploadFile = e => {
    const target = e.target;
    let selectedFile;
    selectedFile = e.target.files[0];
    if (target.files && target.files[0]) {
      /*Maximum allowed size in bytes 12MB */
      const maxAllowedSize = 12 * 1024 * 1024;
      if (target.files[0].size < maxAllowedSize) {
        //   props.handleFileUpload(target);
        setUploadedFile(target.value.substring(12));

        let data = [
          {
            name: 'jayanth',
            data: 'scd',
            abc: 'sdef',
          },
        ];

        xlsx.utils.json_to_sheet(data, 'out.xlsx');
        if (selectedFile) {
          let fileReader = new FileReader();
          fileReader.readAsBinaryString(selectedFile);
          fileReader.onload = event => {
            let data = event.target.result;
            let workbook = xlsx.read(data, { type: 'binary' });

            workbook.SheetNames.forEach(sheet => {
              let rowObject = xlsx.utils.sheet_to_row_object_array(
                workbook.Sheets[sheet],
              );
              let serviceTypeNameFromExcel = rowObject[3];
              if (
                Object.values(serviceTypeNameFromExcel).join(' ') === tabValue
              ) {
                const firstObj = rowObject[4];
                const result = rowObject.slice(5).map(obj => {
                  const mergedObj = {};
                  Object.keys(firstObj).forEach(key => {
                    const sheetKey = firstObj[key];
                    if (!isInputDate(sheetKey)) {
                      checkDateField(firstObj, key, obj, mergedObj);
                    } else if (!isDropDown(sheetKey)) {
                      checkDropdownField(firstObj, key, obj, mergedObj);
                    } else {
                      mergedObj[sheetKey] = obj[key];
                    }
                  });
                  return mergedObj;
                });

                //convert undefined values in excel to null so that its field is present in object
                const convertedUndefinedToNullData = result.map(obj => {
                  const converedObj = {};
                  for (let key in obj) {
                    if (obj[key] === undefined || obj[key] === '') {
                      converedObj[key] = null;
                    } else {
                      converedObj[key] = obj[key];
                    }
                  }
                  return converedObj;
                });

                //set object keys to create js object to display on appllication
                let headerObj;
                if (tabValue === LaborService) {
                  headerObj = exelRefObj[0];
                } else if (tabValue === EquipmentService) {
                  headerObj = equipmentExcelHeaderRefObj[0];
                } else if (tabValue === CompatibleService) {
                  headerObj = compatibleExcelHeaderRefObj[0];
                } else if (tabValue === ReimbursableService) {
                  headerObj = reimbursableExcelHeaderRefObj[0];
                } else if (tabValue === TaskcodeService) {
                  headerObj = taskCodeExcelHeaderRefObj[0];
                } else if (tabValue === UndergroundService) {
                  headerObj = undergroundExcelHeaderRefObj[0];
                } else if (tabValue === MarkupService) {
                  headerObj = markupExcelHeaderRefObj[0];
                } else if (tabValue === UnitPriceService) {
                  headerObj = unitPriceExcelHeaderRefObj[0];
                }

                //final array of object with values coming from excel sheet
                const finalData = convertedUndefinedToNullData.map(obj => {
                  const mergedObj = {};
                  Object.keys(headerObj).forEach(key => {
                    const newKey = headerObj[key];
                    mergedObj[key] = obj[newKey];
                  });
                  return mergedObj;
                });

                setDataFromExcel(finalData);
                setErrorFlag(false);
              } else {
                setErrorFlag(true);
                setCount(0);
                setDataFromExcel();
              }

              //check contractor name
              if (!isEmptyVal(contractorName)) {
                let contractorNameFromSheet = Object.values(rowObject[0])[0];
                if (contractorNameFromSheet === contractorName) {
                  setIsContractorSame(true);
                } else {
                  setIsContractorSame(false);
                }
              } else {
                setContractorNameFromSheet(Object.values(rowObject[0])[0]);
              }

              JSON.stringify(rowObject, undefined, 4);
            });
          };
        }
        loader(100);
      }
    }
  };

  const [fileUploadInprogress, setFileUploadInprogress] = useState(false);
  const [uploadedFile, setUploadedFile] = useState();
  const [showAttachment, setShowAttachment] = useState(false);
  const isMobile = useMediaQuery({ query: '(max-width: 575px)' });

  //function to cancel upload on 'Cancel' button click
  const handleCancelUpload = () => {
    setUploadedFile();
    props.setViewModal(false);
  };

  //function to handle drop  drop event
  const handleDrop = function (e) {
    e.preventDefault();
    e.stopPropagation();
    setDragActive(false);
    if (e.dataTransfer.files && e.dataTransfer.files[0]) {
      handleDropUpload(e);
    }
  };

  const handleDropUpload = e => {
    const target = e.dataTransfer;
    let selectedFile;
    selectedFile = e.dataTransfer.files[0];

    if (target.files && target.files[0]) {
      /*Maximum allowed size in bytes 12MB */
      const maxAllowedSize = 12 * 1024 * 1024;
      if (target.files[0].size < maxAllowedSize) {
        //   props.handleFileUpload(target);
        setUploadedFile(selectedFile.name);

        let data = [
          {
            name: 'jayanth',
            data: 'scd',
            abc: 'sdef',
          },
        ];

        xlsx.utils.json_to_sheet(data, 'out.xlsx');
        if (selectedFile) {
          let fileReader = new FileReader();
          fileReader.readAsBinaryString(selectedFile);
          fileReader.onload = event => {
            let data = event.target.result;
            let workbook = xlsx.read(data, { type: 'binary' });

            workbook.SheetNames.forEach(sheet => {
              let rowObject = xlsx.utils.sheet_to_row_object_array(
                workbook.Sheets[sheet],
              );

              let serviceTypeNameFromExcel = rowObject[3];
              if (
                Object.values(serviceTypeNameFromExcel).join(' ') === tabValue
              ) {
                const firstObj = rowObject[4];
                const result = rowObject.slice(5).map(obj => {
                  const mergedObj = {};
                  Object.keys(firstObj).forEach(key => {
                    const sheetKey = firstObj[key];
                    if (!isInputDate(sheetKey)) {
                      checkDateField(firstObj, key, obj, mergedObj);
                    } else if (!isDropDown(sheetKey)) {
                      checkDropdownField(firstObj, key, obj, mergedObj);
                    } else {
                      mergedObj[sheetKey] = obj[key];
                    }
                  });
                  return mergedObj;
                });
                const convertedUndefinedToNullData = result.map(obj => {
                  const converedObj = {};
                  for (let key in obj) {
                    if (obj[key] === undefined || obj[key] === '') {
                      converedObj[key] = null;
                    } else {
                      converedObj[key] = obj[key];
                    }
                  }
                  return converedObj;
                });

                let headerObj;
                if (tabValue === LaborService) {
                  headerObj = exelRefObj[0];
                } else if (tabValue === EquipmentService) {
                  headerObj = equipmentExcelHeaderRefObj[0];
                } else if (tabValue === CompatibleService) {
                  headerObj = compatibleExcelHeaderRefObj[0];
                } else if (tabValue === ReimbursableService) {
                  headerObj = reimbursableExcelHeaderRefObj[0];
                } else if (tabValue === TaskcodeService) {
                  headerObj = taskCodeExcelHeaderRefObj[0];
                } else if (tabValue === UndergroundService) {
                  headerObj = undergroundExcelHeaderRefObj[0];
                } else if (tabValue === MarkupService) {
                  headerObj = markupExcelHeaderRefObj[0];
                } else if (tabValue === UnitPriceService) {
                  headerObj = unitPriceExcelHeaderRefObj[0];
                }
                const finalData = convertedUndefinedToNullData.map(obj => {
                  const mergedObj = {};
                  Object.keys(headerObj).forEach(key => {
                    const newKey = headerObj[key];
                    mergedObj[key] = obj[newKey];
                  });
                  return mergedObj;
                });
                setDataFromExcel(finalData);
                setErrorFlag(false);
              } else {
                setErrorFlag(true);
                setCount(0);
                setDataFromExcel();
              }

              //check contractor name
              if (!isEmptyVal(contractorName)) {
                let contractorNameFromSheet = Object.values(rowObject[0])[0];
                if (contractorNameFromSheet === contractorName) {
                  setIsContractorSame(true);
                } else {
                  setIsContractorSame(false);
                }
              } else {
                setContractorNameFromSheet(Object.values(rowObject[0])[0]);
              }

              JSON.stringify(rowObject, undefined, 4);
            });
          };
        }
        loader(100);
      }
    }
  };

  const setData = e => {
    // e.preventDefault();
    //INFO adding new Row in Bidrate Sheet table
    // onSubmit();
    props.handleAddExcelData(dataFromExcel, tabValue, contractorNameFromSheet);

    setDataFromExcel();
    setUploadedFile();
    setCount(0);
    props.setViewModal(false);
  };

  //function to delete uploaded file from upload file pop up on click of 'x' button
  const handleDeleteFileFromArray = () => {
    setUploadedFile();
    setCount(0);
  };

  const inputRef = useRef(null);

  return (
    <Modal
      show={props.viewModal}
      onHide={() => props.setViewModal(false)}
      size="md"
      dialogClassName="invoicePreviewDialog"
      aria-labelledby="contained-modal-title-vcenter"
      centered
    >
      <Modal.Header closeButton={false}>
        <div
          style={{
            display: 'flex',
            justifyContent: 'flex-start',
            width: '100%',
            height: '26px',
          }}
        >
          <div className="backArrow">
            <BackArrowIcon styles={{ marginTop: '-4px' }} />
          </div>
          Upload Documents
          <div
            style={{ marginLeft: 'auto', cursor: 'pointer' }}
            onClick={e => props.setViewModal(false)}
          >
            <CloseIcon />
          </div>
        </div>
      </Modal.Header>
      <Divider />
      <Modal.Body className="uploadExcelModalBody">
        <div className="uploadSection">
          <form
            id="form-file-upload"
            onDragEnter={e => e.preventDefault()}
            onDragOver={e => e.preventDefault()}
            onDrop={handleDrop}
            onSubmit={e => e.preventDefault()}
          >
            <input
              type="file"
              id="input-file-upload"
              ref={inputRef}
              onChange={e => handleUploadFile(e)}
              multiple={true}
            />
            <label id="label-file-upload" htmlFor="input-file-upload">
              <div className="uploadContent">
                <Row>
                  <Col sm={3}>
                    <div className="uploadIconWrapper">
                      <UploadPDFIcon />
                    </div>
                  </Col>
                  <Col sm={9}>
                    <div className="uploadHeadline">
                      Select a file to upload
                    </div>
                    <div className="uploadSubheading">
                      {isMobile ? '' : 'or drag and drop your files here'}{' '}
                    </div>
                  </Col>
                </Row>
                <button className="upload-button"></button>
              </div>
            </label>
          </form>
          <div className="uploadedFiles">
            {uploadedFile !== undefined ? (
              <div className="file">
                <Row>
                  <Col sm={1}>
                    <div className="pdfIcon">
                      <PDFIcon />
                    </div>
                  </Col>
                  <Col sm={2}>
                    {errorFlag ? (
                      <p style={{ color: '#AF2218' }}>Selected Wrong file</p>
                    ) : (
                      <div>
                        <p> {uploadedFile}</p>
                      </div>
                    )}
                  </Col>
                  <Col sm={7}>
                    <ProgressBar now={count} />
                    <span>{count}% done</span>
                  </Col>
                  <Col sm={1}>
                    <div className="completed">
                      {errorFlag ? '' : <CheckIcon />}
                    </div>
                  </Col>
                  <Col sm={1}>
                    <div
                      className="cancel"
                      onClick={() => handleDeleteFileFromArray()}
                    >
                      x
                    </div>
                  </Col>
                </Row>
                {!errorFlag && !isContractorSame && (
                  <div style={{ color: '#AF2218' }} className="row">
                    <p className="ml-4">
                      <span className="mr-3">
                        <ErrorIcon />
                      </span>
                      Contractor Name on import file does not match, do you want
                      to proceed ?
                    </p>
                  </div>
                )}
                {invalidDateError && (
                  <div style={{ color: '#AF2218' }} className="row">
                    <p className="ml-4">
                      <span className="mr-3">
                        <ErrorIcon />
                      </span>
                      Invalid date format in uploaded file. Should match
                      'MM/DD/YYYY'.
                    </p>
                  </div>
                )}
              </div>
            ) : null}
          </div>
        </div>
        <div className="btnArea">
          {count === 100 ? (
            <Button variant="primary" onClick={setData}>
              Upload
            </Button>
          ) : (
            <Button
              variant="primary"
              style={{ cursor: 'not-allowed' }}
              disabled
              onClick={setData}
            >
              Upload
            </Button>
          )}
          <Button variant="secondary" onClick={handleCancelUpload}>
            Cancel
          </Button>
        </div>
      </Modal.Body>
    </Modal>
  );
};

export { UploadExcel };
