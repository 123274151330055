import React from 'react';

export const CaretUp = ({ width = '10.5', height = '6', style = {} }) => {
  return (
    <svg
      data-name="Actions / Navigation / caret--down / 16"
      width={width}
      height={height}
      viewBox={`0 0 ${width} ${height}`}
      style={style}
    >
      <path d="M8.5 4.857 4.25 0 0 4.857z" />
    </svg>
  );
};
