// @ts-ignore
import DatePicker from 'react-datepicker';
import { useState, useEffect } from 'react';
import 'react-datepicker/dist/react-datepicker.css';
import { Form, Row, Col } from 'react-bootstrap';
import './AuditDetails.scss';
import { useDispatch, useSelector } from 'react-redux';
import { setAuditData } from 'store/slices/AuditModules/auditSlice';

const ReportingAudit = () => {
  const dispatch = useDispatch();
  const [auditWeekEndingtDate, setAudiWeekEndingtDate] = useState(new Date());
  const audit = useSelector(state => state.audit);
  const auditData = useSelector(state => state.audit.auditFormData);

  const handleWeekEndDate = date => {
    setAudiWeekEndingtDate(date);
    dispatch(setAuditData({ weekEndDate: date.toLocaleDateString('en-US') }));
  };

  const handleRadioChange = e => {
    const field = e.target.name.split('-');
    dispatch(setAuditData({ [field[0]]: field[1] }));
  };

  const calculateVariance = (cap, oAndm) => {
    return cap - oAndm;
  };

  return (
    <>
      <div className="aepBreadcrumb auditInnerContainer">
        <Form.Label className="responseLabel">
          Reporting Accuracy Audit
        </Form.Label>
      </div>
      <div className="pt-4 pl-1">
        <Form.Label>Week ending date:</Form.Label>
        <DatePicker
          selected={auditWeekEndingtDate}
          value={audit.auditFormData?.weekEndDate}
          onChange={date => handleWeekEndDate(date)}
          className="date-picker-icon-top"
        />
      </div>
      <div className="mt-4 reportingAuditFormSection">
        <Form className="auditSection">
          <Row className="mbAudit reportingHeader">
            <Col sm={3}></Col>
            <Col sm={3}>
              <p>Timesheet Data</p>
            </Col>
            <Col sm={3}>
              <p>Audit Data</p>
            </Col>
            <Col sm={3}>
              <p>Variance</p>
            </Col>
          </Row>
          <Row className="mbAudit reportinhRow">
            <Col sm={3}>
              <Form.Label className="mt-4">Numbers of trees trimmed</Form.Label>
            </Col>
            <Col sm={3}>
              <div className="reportinInputSection">
                <div>
                  <Form.Label>Cap:</Form.Label>
                  <input
                    className="mr-1"
                    type="number"
                    onChange={e =>
                      dispatch(
                        setAuditData({ tsTtCap: Number(e.target.value) }),
                      )
                    }
                    value={audit.auditFormData?.tsTtCap}
                  />
                </div>
                <div>
                  <Form.Label>O&M:</Form.Label>
                  <input
                    type="number"
                    onChange={e =>
                      dispatch(setAuditData({ tsTtOm: Number(e.target.value) }))
                    }
                    value={audit.auditFormData?.tsTtOm}
                  />
                </div>
              </div>
            </Col>
            <Col sm={3}>
              <div className="reportinInputSection">
                <div>
                  <Form.Label>Cap:</Form.Label>
                  <input
                    className="mr-1"
                    type="number"
                    onChange={e =>
                      dispatch(setAuditData({ auditTtCap: e.target.value }))
                    }
                    value={audit.auditFormData?.auditTtCap}
                  />
                </div>
                <div>
                  <Form.Label>O&M:</Form.Label>
                  <input
                    type="number"
                    onChange={e =>
                      dispatch(setAuditData({ auditTtOm: e.target.value }))
                    }
                    value={audit.auditFormData?.auditTtOm}
                  />
                </div>
              </div>
            </Col>
            <Col sm={3}>
              <div className="reportinInputSection">
                <div>
                  <Form.Label>Cap:</Form.Label>
                  <input
                    className="mr-1"
                    disabled
                    type="number"
                    value={calculateVariance(
                      audit.auditFormData?.tsTtCap,
                      audit.auditFormData?.auditTtCap,
                    )}
                  />
                </div>
                <div>
                  <Form.Label>O&M:</Form.Label>
                  <input
                    disabled
                    type="number"
                    value={calculateVariance(
                      audit.auditFormData?.tsTtOm,
                      audit.auditFormData?.auditTtOm,
                    )}
                  />
                </div>
              </div>
            </Col>
          </Row>
          <Row className="mbAudit reportinhRow">
            <Col sm={3}>
              <Form.Label>Numbers of trees removed</Form.Label>
            </Col>
            <Col sm={3}>
              <div className="reportinInputSection">
                <div>
                  <input
                    className="mr-1"
                    type="number"
                    onChange={e =>
                      dispatch(setAuditData({ tsTrCap: e.target.value }))
                    }
                    value={audit.auditFormData?.tsTrCap}
                  />
                </div>
                <div>
                  <input
                    type="number"
                    onChange={e =>
                      dispatch(setAuditData({ tsTrOm: e.target.value }))
                    }
                    value={audit.auditFormData?.tsTrOm}
                  />
                </div>
              </div>
            </Col>
            <Col sm={3}>
              <div className="reportinInputSection">
                <div>
                  <input
                    className="mr-1"
                    type="number"
                    onChange={e =>
                      dispatch(setAuditData({ auditTrCap: e.target.value }))
                    }
                    value={audit.auditFormData?.auditTrCap}
                  />
                </div>
                <div>
                  <input
                    type="number"
                    onChange={e =>
                      dispatch(setAuditData({ auditTrOm: e.target.value }))
                    }
                    value={audit.auditFormData?.auditTrOm}
                  />
                </div>
              </div>
            </Col>
            <Col sm={3}>
              <div className="reportinInputSection">
                <div>
                  <input
                    className="mr-1"
                    type="number"
                    disabled
                    value={calculateVariance(
                      audit.auditFormData?.tsTrCap,
                      audit.auditFormData?.auditTrCap,
                    )}
                  />
                </div>
                <div>
                  <input
                    disabled
                    type="number"
                    value={calculateVariance(
                      audit.auditFormData?.tsTrOm,
                      audit.auditFormData?.auditTrOm,
                    )}
                  />
                </div>
              </div>
            </Col>
          </Row>
          <Row className="mbAudit reportinhRow">
            <Col sm={3}>
              <Form.Label>Footage/Units/Acres Re-Cleared</Form.Label>
            </Col>
            <Col sm={3}>
              <div className="reportinInputSection">
                <div>
                  <input
                    className="mr-1"
                    type="number"
                    onChange={e =>
                      dispatch(setAuditData({ tsRcCap: e.target.value }))
                    }
                    value={audit.auditFormData?.tsRcCap}
                  />
                </div>
                <div>
                  <input
                    type="number"
                    onChange={e =>
                      dispatch(setAuditData({ tsRcOm: e.target.value }))
                    }
                    value={audit.auditFormData?.tsRcOm}
                  />
                </div>
              </div>
            </Col>
            <Col sm={3}>
              <div className="reportinInputSection">
                <div>
                  <input
                    className="mr-1"
                    type="number"
                    onChange={e =>
                      dispatch(setAuditData({ auditRcCap: e.target.value }))
                    }
                    value={audit.auditFormData?.auditRcCap}
                  />
                </div>
                <div>
                  <input
                    type="number"
                    onChange={e =>
                      dispatch(setAuditData({ auditRcOm: e.target.value }))
                    }
                    value={audit.auditFormData?.auditRcOm}
                  />
                </div>
              </div>
            </Col>
            <Col sm={3}>
              <div className="reportinInputSection">
                <div>
                  <input
                    className="mr-1"
                    type="number"
                    disabled
                    value={calculateVariance(
                      audit.auditFormData?.tsRcCap,
                      audit.auditFormData?.auditRcCap,
                    )}
                  />
                </div>
                <div>
                  <input
                    type="number"
                    disabled
                    value={calculateVariance(
                      audit.auditFormData?.tsRcOm,
                      audit.auditFormData?.auditRcOm,
                    )}
                  />
                </div>
              </div>
            </Col>
          </Row>
          <Row className="mbAudit reportinhRow">
            <Col sm={3}>
              <Form.Label>Footage/Units/Acres Ground Sprayed</Form.Label>
            </Col>
            <Col sm={3}>
              <div className="reportinInputSection">
                <div>
                  <input
                    className="mr-1"
                    type="number"
                    onChange={e =>
                      dispatch(setAuditData({ tsGpCap: e.target.value }))
                    }
                    value={audit.auditFormData?.tsGpCap}
                  />
                </div>
                <div>
                  <input
                    type="number"
                    onChange={e =>
                      dispatch(setAuditData({ tsGpOm: e.target.value }))
                    }
                    value={audit.auditFormData?.tsGpOm}
                  />
                </div>
              </div>
            </Col>
            <Col sm={3}>
              <div className="reportinInputSection">
                <div>
                  <input
                    className="mr-1"
                    type="number"
                    onChange={e =>
                      dispatch(setAuditData({ auditGpCap: e.target.value }))
                    }
                    value={audit.auditFormData?.auditGpCap}
                  />
                </div>
                <div>
                  <input
                    type="number"
                    onChange={e =>
                      dispatch(setAuditData({ auditGpOm: e.target.value }))
                    }
                    value={audit.auditFormData?.auditGpOm}
                  />
                </div>
              </div>
            </Col>
            <Col sm={3}>
              <div className="reportinInputSection">
                <div>
                  <input
                    className="mr-1"
                    type="number"
                    disabled
                    value={calculateVariance(
                      audit.auditFormData?.tsGpCap,
                      audit.auditFormData?.auditGpCap,
                    )}
                  />
                </div>
                <div>
                  <input
                    type="number"
                    disabled
                    value={calculateVariance(
                      audit.auditFormData?.tsGpOm,
                      audit.auditFormData?.auditGpOm,
                    )}
                  />
                </div>
              </div>
            </Col>
          </Row>
        </Form>
      </div>
      <div className="mt-3 pl-0">
        <Form>
          <Form.Group controlId="form.remitAddress">
            <Form.Label className="responseLabel">Widening</Form.Label>
            <Form.Group
              controlId="kindOfSelection"
              className="responseForm ml-4 mb-3"
            >
              <Form.Check
                inline
                type="radio"
                aria-label="radio 1"
                label="Yes"
                onChange={e => handleRadioChange(e)}
                name="widening-Y"
                checked={auditData?.widening === 'Y'}
              />
              <Form.Check
                inline
                type="radio"
                aria-label="radio 2"
                label="No"
                onChange={e => handleRadioChange(e)}
                name="widening-N"
                checked={auditData?.widening === 'N'}
              />
            </Form.Group>
          </Form.Group>
        </Form>
      </div>
    </>
  );
};

export { ReportingAudit };
