import SiteFooter from 'components/Footer/SiteFooter';
import SiteHeader from 'components/Header/SiteHeader';
import Sidenav from 'components/Sidenav/Sidenav';
import { useEffect } from 'react';
import { useDispatch, useSelector } from 'react-redux';
import { useMediaQuery } from 'react-responsive';

import {
  getCookie,
  refreshAccessToken,
} from 'store/slices/authenticationSlice';
import './Layout.scss';

export const Layout = ({ children }) => {
  const dispatch = useDispatch();

  const isAuthenticated = useSelector(state => state.auth.isLoggedIn);
  const isMobile = useMediaQuery({ query: '(max-width: 575px)' });
  const isMobileOrTablet = useMediaQuery({ query: '(max-width: 1024px)' });

  const needsNewAccessToken = useSelector(state => {
    return state.auth.accessExpired;
  });

  const refreshToken = useSelector(state => {
    return state.auth.refreshToken;
  });

  // useEffect(() => {
  //   dispatch(refreshAccessToken({ refreshToken }));
  //   // eslint-disable-next-line react-hooks/exhaustive-deps
  // }, []);

  // useEffect(() => {
  //   dispatch(getCookie());
  //   if (needsNewAccessToken) {
  //     dispatch(refreshAccessToken({ refreshToken }));
  //   }
  //   // eslint-disable-next-line react-hooks/exhaustive-deps
  // }, [needsNewAccessToken]);

  return (
    <>
      {isAuthenticated ? (
        <>
          <div
            className="appContainer"
            style={{ overflowX: isMobileOrTablet ? 'auto' : 'hidden' }}
          >
            <SiteHeader />

            <div className="layoutRow">
              <div className="layoutColumn">
                <Sidenav />
              </div>
              <div className="layoutColumn">
                <div className={isMobile ? 'pageContentMobile' : 'pageContent'}>
                  {children}
                </div>
              </div>
            </div>

            <SiteFooter />
          </div>
        </>
      ) : (
        <>{children}</>
      )}
    </>
  );
};
