import { http } from 'services/helpers/BaseApi.ts';

const pdfDownloaderRequest = async params => {
  if (params.url && params.url.indexOf('data:') > -1) {
    var link = document.createElement('a');
    link.href = params.url;
    link.download = `${params.name}`;
    link.click();
    return;
  }
  await http.downloadFile({
    url: params.url,
    type: 'application/vnd.openxmlformats-officedocument.spreadsheetml.sheet',
    name: params.name,
    extension: 'pdf',
  });
};

export { pdfDownloaderRequest };
