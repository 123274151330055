import React from 'react';
import { DownloadIcon } from 'assets/images/DownloadIcon/DownloadIcon';
import { pdfDownloaderRequest } from 'services/pdfDownloader';

const PDFdownloader = url => {
  return (
    <>
      <span
        className="downloadBtn"
        onClick={() => pdfDownloaderRequest(url)}
        title="Download PDF"
      >
        <DownloadIcon />
      </span>
    </>
  );
};

export { PDFdownloader };
