export enum PurchaseOrder {
  poNumber = 'ponum',
  contractNumber = 'contractrefnum',
  vendor = 'vendor',
  startDate = 'startdate',
  endDate = 'enddate',
  status = 'caastatus',
}

export enum Timesheet {
  timesheetNumber = 'recordNmbr',
  poNumber = 'ponum',
  vendor = 'vendorTimesheet',
  endDate = 'workEndDate',
  startDate = 'workStartDate',
  status = 'status',
}

export enum InvoiceDetailList {
  vendorInvoiceNumber = 'vendorInvNmbr',
  invoiceNumber = 'invoiceNmbr',
  contractNumber = 'contractNmbr',
  poNumber = `po.ponum`,
  vendor = '"po->vendorVendor".vendor_name',
  startDate = 'weekStartDate',
  endDate = 'weekEndDate',
  status = 'caastatus',
}

export enum InvoiceActivitiesList {
  invoiceNumber = 'invoiceNmbr',
  contractNumber = 'contractNmbr',
  activitynum = `activitynum`,
  activityDescription = 'activityDescription',
  startDate = 'weekStartDate',
  endDate = 'weekEndDate',
  status = 'caastatus',
  laborHrs = 'laborHours',
  equipHours = 'equipHours',
  amount = 'activityAmount',
}

export enum InvoiceLaborList {
  invoiceNumber = 'invoiceNmbr',
  code = 'laborCode',
  name = 'employeeName',
  type = 'laborType',
  shift = 'shift',
  rate = 'laborRate',
  hours = 'laborHours',
  amount = 'laborAmount',
}

export enum InvoiceEquipmentList {
  invoiceNumber = 'invoiceNmbr',
  equipDescription = 'equipDescription',
  rate = 'equipRate',
  hours = 'equipHours',
  amount = 'equipAmount',
}

export enum Invoice {
  activityNumber = 'recordNmbr',
  activityDescription = 'ponum',
  laborHours = 'vendorTimesheet',
  equipmentHours = 'workEndDate',
  amount = 'workStartDate',
}

export enum BidRateSheet {
  bidRateSheetNo = 'bidrateNmbr',
  bidRateSheet = 'name',
  lastModifiedDate = 'updatedDate',
  status = 'caastatus',
}

export enum LaborRatesFields {
  laborCode = 'laborCode',
  laborDescription = 'laborDescription',
  workCategory = 'workCategory',
  effStartDate = 'effStartDate',
  location = 'location',
}

export enum EquipmentFields {
  eqpmntCode = 'eqpmntCode',
  eqpmntDescription = 'eqpmntDescription',
  unitOfMeasure = 'unitOfMeasure',
  workCategory = 'workCategory',
  effStartDate = 'effStartDate',
  location = 'location',
}

export enum ReimbursableFields {
  rmbrslMarkUpCode = 'rmbrslMarkUpCode',
  description = 'description',
  unitOfMeasure = 'unitOfMeasure',
  workCategory = 'workCategory',
  effStartDate = 'effStartDate',
  location = 'location',
}

export enum UnitPriceRatesFields {
  unitCode = 'unitCode',
  unitDescription = 'unitDescription',
  unitOfMeasure = 'unitOfMeasure',
  workCategory = 'workCategory',
  effStartDate = 'effStartDate',
  location = 'location',
  totalUnitprice = 'totalUnitprice',
}
export enum taskCodeRatesFields {
  taskCode = 'taskCode',
  taskDescription = 'taskDescription',
  unitOfMeasure = 'unitOfMeasure',
  workCategory = 'workCategory',
  effStartDate = 'effStartDate',
  location = 'location',
}

export enum compatibleUnitGroupFields {
  unitCode = 'unitCode',
  unitDescription = 'unitDescription',
  unitOfMeasure = 'unitOfMeasure',
  workCategory = 'workCategory',
  effStartDate = 'effStartDate',
  location = 'location',
}
export enum undergroundMultiRateFields {
  code = 'code',
  unitDescription = 'unitDescription',
  UnitofMeasure = 'UnitofMeasure',
  workCategory = 'workCategory',
  effStartDate = 'effStartDate',
  location = 'location',
}
export enum markupFields {
  billingCode = 'billingCode',
  billingDescription = 'billingDescription',
  workCategory = 'workCategory',
  effEndDate = 'effEndDate',
  location = 'location',
  effStartDate = 'effStartDate',
  unionindicator = 'unionindicator',
}

export enum Audit {
  auditNumber = 'auditNmbr',
  auditDate = 'auditDate',
  auditorName = 'auditorName',
  crewType = 'crewType',
  crewNumber = 'crewNmbr',
  circuit = 'circuitNmbr',
}

export enum adminsid {
  site = 'Site',
  description = 'Description',
  organization = 'Organization',
  entitlement = 'entitlement',
  regulated = 'regulated',
  unregulated = 'unregulated',
  active = 'active',
  authorized = 'authorized',
}

export enum workarea {
  opcostate = 'OPCO/State',
  orgdepartment = 'Org/Department',
  orggroup = 'Org Group',
  opcostatestatus = 'Org Group',
}

export enum fuelrates {
  monthyearopco = 'Month/Year OPCO',
  opco = 'OPCO/State',
  state = 'State',
  fueltype = 'EIA U.S. Fuel Type',
  fulerates = 'EIA U.S. Fuel Rates',
  enteredby = 'Entered By',
  entereddate = 'Entered Date',
  updateddate = 'Updated Date',
  updatedby = 'Updated By',
}

export enum districtworkarea {
  workareacode = 'District Work Area Code',
  workareadescription = 'District Work Area Description',
  workarea = 'Work Area',
  fieldstatus = 'Field Status',
}
