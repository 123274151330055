import { OperationEnum } from 'enums/operation.ts';
import _ from 'lodash';
import { getLocalDateTime } from 'utils/utils';

class Tracking {
  public constructor() {}

  private getTrackingOperation(data, pkFieldName) {
    let operation = OperationEnum.INSERT;
    if (_.isNil(data[pkFieldName])) {
      operation = OperationEnum.INSERT;
    } else {
      operation = OperationEnum.UPDATE;
    }
    return operation;
  }

  private setTrackingDate(data, opt) {
    /*
    if (opt === OperationEnum.INSERT) {
      data.enteredDate = getLocalDateTime(new Date());
      data.updatedDate = getLocalDateTime(new Date());
    } else {
      data.updatedDate = getLocalDateTime(new Date());
    }
    */
  }

  public setTrackingData = (data, pkFieldName, opt = null) => {
    let operation;
    if (!_.isNil(opt)) {
      operation = opt;
    }
    if (_.isArray(data)) {
      data.forEach(o => {
        if (_.isNil(operation)) {
          operation = this.getTrackingOperation(o, pkFieldName);
        }
        this.setTrackingDate(o, operation);
      });
    } else {
      if (_.isNil(operation)) {
        operation = this.getTrackingOperation(data, pkFieldName);
      }
      this.setTrackingDate(data, operation);
    }
  };
}

export const tracking = new Tracking();
