// Imports
import ___CSS_LOADER_API_SOURCEMAP_IMPORT___ from "../../../../../node_modules/react-scripts/node_modules/css-loader/dist/runtime/sourceMaps.js";
import ___CSS_LOADER_API_IMPORT___ from "../../../../../node_modules/react-scripts/node_modules/css-loader/dist/runtime/api.js";
var ___CSS_LOADER_EXPORT___ = ___CSS_LOADER_API_IMPORT___(___CSS_LOADER_API_SOURCEMAP_IMPORT___);
// Module
___CSS_LOADER_EXPORT___.push([module.id, `.form-control.is-invalid {
  background-image: none;
}

.bidratesheetForm {
  overflow-y: auto;
  overflow-x: auto;
  width: 100%;
  max-height: 400px;
}
.bidratesheetForm .table-responsive {
  overflow-x: visible;
}

.select-box {
  padding: 0 10px;
  margin: 0;
  height: 35px;
  width: 210px;
}

.errorMessage svg {
  margin-right: 4px;
  width: 13px;
}`, "",{"version":3,"sources":["webpack://./src/pages/BidRateSheets/Common/BidRateTable/BidRateTable.scss"],"names":[],"mappings":"AAAA;EACE,sBAAA;AACF;;AAEA;EACE,gBAAA;EACA,gBAAA;EACA,WAAA;EACA,iBAAA;AACF;AAAE;EACE,mBAAA;AAEJ;;AACA;EACE,eAAA;EACA,SAAA;EACA,YAAA;EACA,YAAA;AAEF;;AACE;EACE,iBAAA;EACA,WAAA;AAEJ","sourcesContent":[".form-control.is-invalid {\n  background-image: none;\n}\n\n.bidratesheetForm {\n  overflow-y: auto;\n  overflow-x: auto;\n  width: 100%;\n  max-height: 400px;\n  .table-responsive {\n    overflow-x: visible;\n  }\n}\n.select-box {\n  padding: 0 10px;\n  margin: 0;\n  height: 35px;\n  width: 210px;\n}\n.errorMessage {\n  svg {\n    margin-right: 4px;\n    width: 13px;\n  }\n}\n"],"sourceRoot":""}]);
// Exports
___CSS_LOADER_EXPORT___.locals = {};
export default ___CSS_LOADER_EXPORT___;
