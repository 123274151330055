import { all, call, put, select, takeLatest } from 'redux-saga/effects';
import * as service from 'services/adminModuleService';
import {
  fetchVendorcompanyList,
  fetchFilteredVendorcompanyList,
  fetchVendorList,
  updateVendorCompany,
  setAdminVendorData,
  setVendorCompanyList,
  fetchSelectedAdminVendortList,
  setSelectedAdminVendorRecords,
  setError,
} from 'store/slices/AdminModules/vendorSlice';
import { displayError } from 'components/common/Alert/ToastAlert';
import _ from 'lodash';

const vendorPaginationSlector = state => state.adminVendor.pagination;

export function* getVendorList(action) {
  const queryParams = _.cloneDeep(yield select(vendorPaginationSlector));

  try {
    const response = yield call(
      f => service.retrieveVendorList(queryParams),
      action.payload,
    );
    const { data } = response;
    data.pagination = { totalItems: data.count };
    yield put(setAdminVendorData(data));
  } catch (e) {
    put(setError(true));
    displayError(
      'We are experiencing technical difficulties. Please come back shortly to try again !',
    );
  }
}

export function* updateVendorCompanyDetails(action) {
  try {
    const response = yield call(
      f => service.updateVendorCompanyDetails(action.payload),
      action.payload,
    );
    // const { data } = response;
    yield call(f => getVendorList(action));
  } catch (e) {
    put(setError(true));
  }
}

export function* getSelectedAdminVendorList(action) {
  try {
    const response = yield call(
      f => service.retrieveVendorCompanyFilterList(action.payload),
      action.payload,
    );
    const { data } = response;
    yield put(setSelectedAdminVendorRecords(data));
  } catch (e) {
    put(setError(true));
  }
}

export function* getVendorCompanyList(action) {
  try {
    const response = yield call(
      f => service.retrieveVendorCompanyList(action.payload),
      action.payload,
    );
    const { data } = response;
    yield put(setVendorCompanyList(data));
    if (action.payload?.callback) {
      action.payload.callback(response.data);
    }
  } catch (e) {
    put(setError(true));
    displayError(
      'We are experiencing technical difficulties. Please come back shortly to try again !',
    );
  }
}

export function* getFilterVendorCompanyList(action) {
  try {
    const response = yield call(
      f => service.retrieveVendorCompanyList(action.payload),
      action.payload,
    );
    const { data } = response;
    yield put(setVendorCompanyList(data));
  } catch (e) {
    put(setError(true));
  }
}

function* watchVendorCompanyList() {
  yield takeLatest(fetchVendorcompanyList.type, getVendorCompanyList);
}

function* watchFilterVendorCompanyList() {
  yield takeLatest(
    fetchFilteredVendorcompanyList.type,
    getFilterVendorCompanyList,
  );
}

function* watchUpdateVendorCompanyData() {
  yield takeLatest(updateVendorCompany.type, updateVendorCompanyDetails);
}

function* watchVendorList() {
  yield takeLatest(fetchVendorList.type, getVendorList);
}

function* watchFetchSelectedAdminVendorList() {
  yield takeLatest(
    fetchSelectedAdminVendortList.type,
    getSelectedAdminVendorList,
  );
}

export function* vendorSaga() {
  yield all([
    watchVendorCompanyList(),
    watchVendorList(),
    watchUpdateVendorCompanyData(),
    watchFetchSelectedAdminVendorList(),
    watchFilterVendorCompanyList(),
  ]);
}
