import { Modal } from 'react-bootstrap';
import { ButtonArea } from 'components/common//Button/ButtonArea/ButtonArea';
import { Divider } from 'components/common/Divider/Divider';

const DeleteContactModal = ({
  show,
  setShowModal,
  handleDeleteContinueClick,
}) => {
  return (
    <Modal
      show={show}
      onHide={() => setShowModal(false)}
      size="lg"
      aria-labelledby="contained-modal-title-vcenter"
      centered
    >
      <Modal.Header closeButton="true">
        <Modal.Title>Delete Contact</Modal.Title>
      </Modal.Header>
      <Divider />
      <Modal.Body>Are you sure do you want to delete this contact?</Modal.Body>
      <Modal.Footer>
        <ButtonArea
          primaryLabel="Continue"
          secondaryLabel="Cancel"
          primaryOnClick={() => handleDeleteContinueClick()}
          secondaryOnClick={() => setShowModal(false)}
        />
      </Modal.Footer>
    </Modal>
  );
};

export { DeleteContactModal };
