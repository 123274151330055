import axios, { AxiosInstance, AxiosRequestConfig, AxiosResponse } from 'axios';
import { StatusCode } from 'enums/StatusCode.ts';
import { refreshAccessToken } from 'services/authentication';
import { CookieService } from 'services/cookieService';
import { httpSuccess } from 'utils/serviceHelper';
import { resetState } from 'store/configureStore';
import { displayError } from 'components/common/Alert/ToastAlert';

const baseURL = `${process.env.REACT_APP_API_BASE_URL}/api`;

const headers: Readonly<Record<string, string | boolean>> = {
  accept: 'application/json',
  'content-type': 'application/json; charset=utf-8',
};

const headersInjected = () => {
  var headersTemp = {
    accept: 'application/json',
    'content-type': 'application/json; charset=utf-8',
  };
  return headersTemp;
};

interface IConfigObject extends AxiosRequestConfig {
  additional?: { useRefreshToken?: boolean };
}

interface fileInfo {
  url: string;
  type: string;
  name: string;
  extension: string;
}

// We can use the following function to inject the JWT token through an interceptor
// We get the `accessToken` from the localStorage that we set when we authenticate
const injectToken = (config: IConfigObject): AxiosRequestConfig => {
  try {
    const { accessToken } = CookieService.authenticateViaCookie();
    if (accessToken !== null && accessToken !== '') {
      config!.headers!.Authorization = `Bearer ${accessToken}`;
    }
    return config;
  } catch (error: any) {
    throw new Error(error);
  }
};

class Http {
  private instance: AxiosInstance | null = null;
  private get http(): AxiosInstance {
    return this.instance != null ? this.instance : this.initHttp();
  }
  public constructor() {
    this.initHttp();
  }
  initHttp() {
    const http = axios.create({
      baseURL,
      headers,
      withCredentials: true,
    });
    axios.interceptors.request.use(
      async config => {
        // const refreshToken = store.getState()?.auth?.refreshToken;
        return config;
      },
      error => Promise.reject(error),
    );

    http.interceptors.request.use(injectToken, error => Promise.reject(error));
    http.interceptors.response.use(
      response => {
        return response;
      },
      async error => {
        if (error.response.status === 401) {
          // error.config._retry = true;
          const { refreshToken } = CookieService.authenticateViaCookie();

          try {
            const resp = await refreshAccessToken({ refreshToken });

            if (resp.status !== 200) {
              this.handleForceLogout();
            }

            const { accessToken } = CookieService.authenticateViaCookie();
            error.config.headers['Authorization'] = `Bearer ${accessToken}`;
            return axios.request(error.config);
          } catch (error) {
            this.handleForceLogout();
          }
        }
        return this.handleError(error.response);
      },
    );
    this.instance = http;
    return http;
  }
  async handleForceLogout() {
    resetState();
    CookieService.clearCookies();
  }
  request<T = any, R = AxiosResponse<T>>(
    config: AxiosRequestConfig,
  ): Promise<R> {
    return this.http.request(config);
  }

  get<T = any>(url: string, config?: IConfigObject): Promise<any> {
    return this.http.get<T>(url, config);
  }

  async getFetch(url: string): Promise<any> {
    try {
      const headers = headersInjected();
      var response = await this.http.get(url, { headers });
      if (response && httpSuccess(response?.status)) {
        return await response.data.blob();
      } else {
        return response;
      }
    } catch (error: any) {
      throw new Error(error);
    }
  }

  async getFetchFile(url: string): Promise<any> {
    try {
      const headers = headersInjected();
      var response = await fetch(url, {
        method: 'GET',
        headers,
      });
      if (response && httpSuccess(response?.status)) {
        return await response.blob();
      } else {
        return await response;
      }
    } catch (error: any) {
      throw new Error(error);
    }
  }

  async getWithoutAuthorization(url: string): Promise<any> {
    try {
      const headers = headersInjected();
      var response = await this.http.get(url, { headers });
      if (response && httpSuccess(response?.status)) {
        return await response.data;
      } else {
        return response;
      }
    } catch (error: any) {
      throw new Error(error);
    }
  }

  patch<T = any>(url: string, data?: T, config?: IConfigObject): Promise<any> {
    return this.http.patch<T>(url, data, config);
  }

  post<T = any>(url: string, data?: T, config?: IConfigObject): Promise<any> {
    return this.http.post<T>(url, data, config);
  }

  postUpload<T = any>(url: string, data: any): Promise<any> {
    const formData = new FormData();
    formData.append(data?.name, data, data?.name);
    return this.http.post<T>(url, formData, { headers });
  }

  put<T = any>(url: string, data?: T, config?: IConfigObject): Promise<any> {
    return this.http.put<T>(url, data, config);
  }

  delete<T = any>(url: string, config?: IConfigObject): Promise<any> {
    return this.http.delete<T>(url, config);
  }
  // Handle global app errors
  // We can handle generic app errors depending on the status code
  private handleError(error) {
    const { status } = error || {};
    switch (status) {
      case StatusCode.InternalServerError: {
        // Handle InternalServerError
        if (
          error?.data?.message === 'WM error' &&
          !error?.data?.error?.message
        ) {
          displayError('PeopleSoft Validation is currently down.');
        } else if (error?.data?.message) {
          displayError(error?.data?.error?.message || error?.data?.message);
        } else if (error.request.responseType !== 'arraybuffer') {
          displayError('Something went wrong!');
        }
        break;
      }
      case StatusCode.Forbidden: {
        // Handle Forbidden
        break;
      }
      case StatusCode.Unauthorized: {
        // Handle Unauthorized
        break;
      }
      case StatusCode.TooManyRequests: {
        // Handle TooManyRequests
        break;
      }
      case StatusCode.BadRequest: {
        // Handle BadRequest
        break;
      }
    }
    return Promise.reject(error);
  }

  /**
   * Get object urls from a file
   */
  async getFile(file: fileInfo) {
    const reponseBlob = await this.get(file.url, {
      responseType: 'arraybuffer',
    });
    const blob = new Blob([reponseBlob.data], {
      type: file.type,
    });
    return window.URL.createObjectURL(blob);
  }
  /**
   * Download a file from server,
   * Assuming the API will retrun a file
   * @param file
   */
  async downloadFile(file: fileInfo) {
    var link = document.createElement('a');
    link.href = await this.getFile(file);
    const timeData = new Date();
    link.download = `${file.name}_${timeData.getTime()}.${file.extension}`;
    link.click();
  }
  async downloadInvoiceFilePDF(file: fileInfo) {
    var link = document.createElement('a');
    link.href = await this.getFile(file);
    const timeData = new Date();
    link.download = `${file.name}_${timeData.getTime()}.${file.extension}`;
    link.click();
  }

  async downloadInvoiceFile(file: fileInfo) {
    var link = document.createElement('a');
    link.href = await this.getFile(file);
    link.download = `${file.name}.${file.extension}`;
    link.click();
  }
  async downloadTimeSheetTemplate(file: fileInfo) {
    var link = document.createElement('a');
    link.href = await this.getFile(file);
    link.download = `${file.name}.${file.extension}`;
    link.click();
  }
  async downloadTimeSheet(file: fileInfo) {
    var link = document.createElement('a');
    link.href = await this.getFile(file);
    const timeData = new Date();
    link.download = `${file.name}_${timeData.getTime()}.${file.extension}`;
    link.click();
  }
}

export const http = new Http();
