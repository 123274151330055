import { http } from 'services/helpers/BaseApi.ts';
import queryString from 'query-string';
import _ from 'lodash';
import { tracking } from './helpers/Tracking.ts';
import { OperationEnum } from 'enums/operation.ts';

const retrieveTimesheets = async qParams => {
  const queryParams = _.cloneDeep(qParams);
  queryParams.page = qParams.currentPage;
  queryParams.limit = qParams.itemsPerPage;
  queryParams.ponum = qParams.ponum.toString();
  queryParams.weekEndDate = qParams.weekEndDate.toString();
  queryParams.workAreaId = qParams.workAreaId.toString();
  queryParams.caastatus = qParams.caastatus.toString();
  queryParams.transWorkorder = qParams.transWorkorder.toString();
  queryParams.crewType = qParams.crewType.toString();
  queryParams.bupath = qParams.bupath.toString();
  queryParams.circuitnum = qParams?.circuitnum?.toString();
  queryParams.authNmbr = qParams.authNmbr.toString();
  queryParams.contractNmbr = qParams.contractNmbr.toString();
  queryParams.workStartDate = qParams.workStartDate.toString();
  queryParams.workEndDate = qParams.workEndDate.toString();
  queryParams.vendor = qParams.vendor.toString();
  queryParams.worktype = qParams.worktype.toString();
  queryParams.estimate = qParams.estimate.toString();
  try {
    return await http.get(`/timesheets?${queryString.stringify(queryParams)}`);
  } catch (error) {
    return error;
  }
};

const retrieveTimesheet = async (recordNum = undefined) => {
  let url = `/timesheets`;
  if (recordNum !== undefined || recordNum !== null) {
    url = `/timesheets/${recordNum}`;
  }
  try {
    return await http.get(url);
  } catch (error) {
    return error;
  }
};

const postTimesheet = async payload => {
  try {
    tracking.setTrackingData(payload, 'recordNmbr');
    return await http.post('/timesheets', payload);
  } catch (error) {
    return error;
  }
};

const uploadPostTimesheet = async payload => {
  try {
    tracking.setTrackingData(payload, 'recordNmbr');
    return await http.post('/timesheets/import', payload);
  } catch (error) {
    return error;
  }
};

const patchTimesheet = async payload => {
  try {
    // @ts-ignore
    tracking.setTrackingData(payload.data, 'recordNmbr', OperationEnum.UPDATE);
    return await http.patch(`/timesheets/${payload.recordNmbr}`, payload.data);
  } catch (error) {
    return error;
  }
};

const retreiveSelectedTimesheetList = async data => {
  const queryParams = {
    limit: 500,
  };
  if (data.hasOwnProperty('search')) {
    queryParams.search = data.search;
  }

  let url;
  if (data.columnname == 'workorder') {
    url = `timesheets/accounts/distinct/records/workorder?${queryString.stringify(
      queryParams,
    )}`;
  } else if (data.columnname != 'vendor') {
    url = `timesheets/distinct/records/${
      data.columnname
    }?${queryString.stringify(queryParams)}`;
  } else {
    url = `/vendors/list?${queryString.stringify(queryParams)}`;
  }
  try {
    return await http.get(url);
  } catch (error) {
    return error;
  }
};

const approveTimesheet = async payload => {
  try {
    return await http.get(
      `/timesheets/${payload.recordNmbr}/approve-ps-validation`,
      payload,
    );
  } catch (error) {
    return error;
  }
};

const retrieveJobAuthorization = async jobAuthorizationId => {
  try {
    return await http.get(`/jobauthorization/${jobAuthorizationId}`);
  } catch (error) {
    return error;
  }
};

const returnTimesheets = async payload => {
  try {
    // @ts-ignore
    tracking.setTrackingData(payload, 'recordNmbr', OperationEnum.UPDATE);
    return await http.patch(`/timesheets/returnTimesheets`, payload);
  } catch (error) {
    return error;
  }
};

const approveCircuitMileage = async payload => {
  try {
    return await http.get(
      `/timesheets/${payload.recordNmbr}/approve-circuit-mileage`,
    );
  } catch (error) {
    return error;
  }
};

const updateActualCostAndDollar = async payload => {
  try {
    return await http.get(
      `/timesheets/${payload.recordNmbr}/update-actual-hrs-doller/${payload?.data?.caastatus}`,
    );
  } catch (error) {
    return error;
  }
};

const checkWO = async workorder => {
  try {
    return await http.get(`/timesheets/findwoproj/${workorder}`);
  } catch (error) {
    return error;
  }
};

export {
  retrieveTimesheets,
  retrieveTimesheet,
  postTimesheet,
  uploadPostTimesheet,
  patchTimesheet,
  retreiveSelectedTimesheetList,
  approveTimesheet,
  retrieveJobAuthorization,
  returnTimesheets,
  approveCircuitMileage,
  checkWO,
  updateActualCostAndDollar,
};
