import React from 'react';

export const Rates = () => {
  return (
    <svg
      xmlns="http://www.w3.org/2000/svg"
      width="20"
      height="20"
      viewBox="0 0 20 20"
    >
      <path
        d="M89.1 193h1.75v-1.25a4.551 4.551 0 0 0 2.15-.975 2.731 2.731 0 0 0 .9-2.225 3.331 3.331 0 0 0-.6-1.925 4.841 4.841 0 0 0-2.4-1.525 10 10 0 0 1-2.075-.875 1.165 1.165 0 0 1-.575-1.025 1.249 1.249 0 0 1 .463-1.025 2.079 2.079 0 0 1 1.338-.375 1.855 1.855 0 0 1 1.25.387 2.2 2.2 0 0 1 .65.963l1.6-.65a3.349 3.349 0 0 0-1.013-1.525 2.783 2.783 0 0 0-1.637-.725V179H89.15v1.25a3.432 3.432 0 0 0-1.95 1.1 2.787 2.787 0 0 0-.7 1.85 2.648 2.648 0 0 0 .688 1.9 5.5 5.5 0 0 0 2.162 1.25 9.563 9.563 0 0 1 2.188 1.025 1.393 1.393 0 0 1 .613 1.175 1.349 1.349 0 0 1-.588 1.213 2.507 2.507 0 0 1-1.412.387 2.269 2.269 0 0 1-1.463-.512 2.976 2.976 0 0 1-.937-1.538l-1.65.65a4.435 4.435 0 0 0 1.088 1.938A3.888 3.888 0 0 0 89.1 191.7zm.9 3a9.738 9.738 0 0 1-3.9-.787 9.984 9.984 0 0 1-5.312-5.312 10.051 10.051 0 0 1 0-7.8 9.984 9.984 0 0 1 5.313-5.312 10.051 10.051 0 0 1 7.8 0 9.984 9.984 0 0 1 5.313 5.313 10.051 10.051 0 0 1 0 7.8 9.984 9.984 0 0 1-5.312 5.313A9.738 9.738 0 0 1 90 196zm0-2a7.939 7.939 0 1 0-5.675-2.325A7.721 7.721 0 0 0 90 194zm0-8z"
        transform="translate(-80 -176)"
        style={{ fill: '#4a4a4a' }}
      />
    </svg>
  );
};
