import { ButtonArea } from 'components/common//Button/ButtonArea/ButtonArea';
import { Divider } from 'components/common/Divider/Divider';
import { AEPFilterComponent } from 'components/common/Filter/AEPFilterComponent';
import { AEPPagination } from 'components/common/Pagination/AEPPagination';
import _ from 'lodash';
import { useState } from 'react';
import { Col, Modal, Row, Table } from 'react-bootstrap';
import { useDispatch, useSelector } from 'react-redux';
import {
  fetchEquipmentServiceTypeOptions,
  fetchLaborServiceTypeOptions,
  fetchOtherServiceTypeOptions,
  fetchServiceTypeOptions,
  fetchServiceTypeOptionsEqp,
  fetchServiceTypeOptionsLabor,
  setAddNewCodePagination,
} from 'store/slices/BidRateSheets/bidRateSheetListSlice';
import { setNewCodeModalFilters } from 'store/slices/filterSlice';
import './BidRateSheetEntry.scss';
import { isEmptyVal } from 'utils/utils';

const AddNewCodeModal = ({
  show,
  setShowModal,
  addNewCodeHeaders,
  handleAddNewCodeContinueClick,
  serviceType,
  autoFillColumnskeys,
  newcodeDetailsArray,
}) => {
  const dispatch = useDispatch();
  const [laborDetails, setLaborDetails] = useState([]);
  const [addNewCodeSelection, setaddNewCodeSelection] = useState(false);
  const [expandState, setExpandState] = useState({});
  const [ctnBtnEnable, setCtnBtnEnabled] = useState(true);

  const laborServiceTypeOptions = useSelector(
    state => state.bidRateSheetList.laborServiceTypeOptions,
  );

  const equipmentServiceTypeOptions = useSelector(
    state => state.bidRateSheetList.equipmentServiceTypeOptions,
  );

  const otherServiceTypeOptions = useSelector(
    state => state.bidRateSheetList.otherServiceTypeOptions,
  );

  const pagination = useSelector(state => state.bidRateSheetList.pagination);

  const currentPage = useSelector(
    state => state.bidRateSheetList.pagination.currentPage,
  );
  const sortBy = useSelector(state => state.bidRateSheetList.pagination.sortBy);
  const sortOrder = useSelector(
    state => state.bidRateSheetList.pagination.sortOrder,
  );

  const inputListData = useSelector(state => state.filter.newcodemodal);
  const selectedFilterRecordList = useSelector(
    state => state.bidRateSheetList.recordItems,
  );

  const [chipFilterValues, setChipFilterValues] = useState({});
  const [filterChip, setFilterchip] = useState(false);
  const [continueBtnDisable, setContinueBtnDisable] = useState(true);
  let bidRateFiltersValues = [];
  if (serviceType == 'bidrateLaborRates') {
    bidRateFiltersValues = [
      { label: 'Code', value: 'craft' },
      { label: 'Description', value: 'description' },
      { label: 'Category', value: 'rescattype' },
    ];
  } else if (serviceType == 'bidrateEquipmentRates') {
    bidRateFiltersValues = [
      { label: 'Code', value: 'itemnum' },
      { label: 'Description', value: 'description' },
      { label: 'Category', value: 'eqpcategory' },
    ];
  } else {
    bidRateFiltersValues = [
      { label: 'Code', value: 'itemnum' },
      { label: 'Description', value: 'description' },
    ];
  }

  let handlelaborDetailsClick = (row, i) => {
    setCtnBtnEnabled(false);
    let obj = {};
    obj[i] = true;
    let sample = { ...row };
    if (
      serviceType != 'bidrateLaborRates' &&
      serviceType != 'bidrateEquipmentRates'
    ) {
      handleObjectkeyChange(sample, autoFillColumnskeys);
    }

    setExpandState(obj);
    setaddNewCodeSelection(true);
    setLaborDetails(
      serviceType != 'bidrateLaborRates' &&
        serviceType != 'bidrateEquipmentRates'
        ? sample
        : row,
    );
  };

  const objDecorate = obj => {
    let newObj = _.mapKeys(obj, (value, key) => {
      return key;
    });
    return newObj;
  };

  const handleContinueClick = (data = null) => {
    let inputData = _.cloneDeep(inputListData);
    if (!isEmptyVal(data)) {
      inputData = _.cloneDeep(data);
    }

    let selectedFilterData = {};
    let selectedFilterValue = {};
    let uniqueFilterValues = [];

    inputData.map((el, id) => {
      if (uniqueFilterValues.indexOf(el.FilterValue) === -1) {
        uniqueFilterValues.push(el.FilterValue);
      }
    });

    uniqueFilterValues.map(value => {
      let temp1 = [];
      inputData.map((val, ids) => {
        if (val.FilterValue == value) {
          temp1.push(val.selectFilterValue);
        }
      });
      selectedFilterData[value] = temp1;
    });

    inputData.map((el, id) => {
      Object.keys(selectedFilterData).map(function (item) {
        if (item == el.FilterValue) {
          selectedFilterValue[el.FilterLabel] = selectedFilterData[item];
        }
      });
    });

    const selectedFilterDataDecorated = objDecorate(selectedFilterData);

    // dispatch(fetchBidRateSheetList(selectedFilterDataDecorated));
    if (serviceType == 'bidrateLaborRates') {
      dispatch(fetchLaborServiceTypeOptions(selectedFilterDataDecorated));
    } else if (serviceType == 'bidrateEquipmentRates') {
      dispatch(fetchEquipmentServiceTypeOptions(selectedFilterDataDecorated));
    } else {
      dispatch(fetchOtherServiceTypeOptions(selectedFilterDataDecorated));
    }
    let vendorSelectFilterLabel = [];
    if (selectedFilterValue.Vendor != undefined) {
      selectedFilterValue.Vendor.map((e, id) => {
        inputData.map((val, ind) => {
          if (val.selectFilterValue == e) {
            vendorSelectFilterLabel.push(val.selectFilterLabel);
          }
        });
        selectedFilterValue.Vendor = vendorSelectFilterLabel;
        return selectedFilterValue.Vendor;
      });
    }

    setChipFilterValues(selectedFilterValue);
    setFilterchip(true);
  };

  const handleFilterChip = (chipname, chipValue) => {
    let filterchipData = {};

    Object.keys(chipFilterValues).map(function (item, i) {
      let chipList = [...chipFilterValues[item]];
      chipList.map((val, ids) => {
        if (val == chipValue) {
          chipList.splice(ids, 1);
        }
      });
      chipFilterValues[item] = chipList;
    });

    inputListData.map((el, id) => {
      Object.keys(chipFilterValues).map(function (item) {
        if (item == el.FilterLabel) {
          filterchipData[el.FilterValue] = chipFilterValues[item];
        }
      });
    });

    let indextodelete;
    let newInputList1 = _.cloneDeep(inputListData);
    newInputList1.map((val, ids) => {
      if (val.FilterValue === 'vendor' || val.FilterValue === 'vendorid') {
        if (val.FilterLabel == chipname && val.selectFilterLabel == chipValue) {
          indextodelete = ids;
        }
      } else {
        if (val.FilterLabel == chipname && val.selectFilterValue == chipValue) {
          indextodelete = ids;
        }
      }
    });
    newInputList1.splice(indextodelete, 1);

    if (newInputList1.length == 0) {
      setContinueBtnDisable(true);
    }
    dispatch(setNewCodeModalFilters(newInputList1));
    setChipFilterValues(chipFilterValues);

    let vendorSelectFilterValue = [];
    if (filterchipData.vendor != undefined) {
      filterchipData.vendor.map((e, id) => {
        inputListData.map((val, ind) => {
          if (val.selectFilterLabel == e) {
            vendorSelectFilterValue.push(val.selectFilterValue);
          }
        });
        filterchipData.vendor = vendorSelectFilterValue;
        return filterchipData.vendor;
      });
    }

    const filterchipDataDecorated = objDecorate(filterchipData);
    if (serviceType == 'bidrateLaborRates') {
      dispatch(fetchLaborServiceTypeOptions(filterchipDataDecorated));
    } else if (serviceType == 'bidrateEquipmentRates') {
      dispatch(fetchEquipmentServiceTypeOptions(filterchipDataDecorated));
    } else {
      dispatch(fetchOtherServiceTypeOptions(filterchipDataDecorated));
    }
  };

  const handleFilterclose = type => {
    if (type == 'filterChange') {
      setContinueBtnDisable(false);
    } else if (type == 'deleteEmpty') {
      setContinueBtnDisable(true);
    }
  };

  const handleContinueBtnClick = () => {
    setCtnBtnEnabled(true);
    setChipFilterValues({});
    handleAddNewCodeContinueClick(laborDetails);
  };
  const handleShowMoal = () => {
    setChipFilterValues({});
    setShowModal(false);
  };

  let handleObjectkeyChange = (object, autoFillColumnskeys) => {
    Object.keys(autoFillColumnskeys).map(function (item) {
      let newkey = autoFillColumnskeys[item];
      if (newkey != item) {
        object[newkey] = object[item];
        delete object[item];
      }
    });

    return object;
  };

  return (
    <Modal
      show={show}
      onHide={handleShowMoal}
      dialogClassName="bidRateSheetNewCode"
      aria-labelledby="contained-modal-title-vcenter"
      centered
    >
      <Modal.Header closeButton="true">
        <Modal.Title>Add New Code</Modal.Title>
      </Modal.Header>
      <Divider />
      <Modal.Body>
        <div className="aepContainer addNewCodeTable">
          <Row>
            <Col sm={12}>
              <>
                <div className="filterComponent">
                  <AEPFilterComponent
                    inputListData={inputListData}
                    filterValues={bidRateFiltersValues}
                    setInputFilterData={setNewCodeModalFilters}
                    selectedFilterRecordList={selectedFilterRecordList}
                    fetchSelectedList={
                      serviceType == 'bidrateLaborRates'
                        ? fetchServiceTypeOptionsLabor
                        : serviceType == 'bidrateEquipmentRates'
                        ? fetchServiceTypeOptionsEqp
                        : fetchServiceTypeOptions
                    }
                    handleContinueClick={handleContinueClick}
                    continueBtnDisable={continueBtnDisable}
                    handleFilterclose={handleFilterclose}
                    handleFilterChip={handleFilterChip}
                    filterChip={filterChip}
                    chipFilterValues={chipFilterValues}
                  />
                </div>
              </>
            </Col>
          </Row>
          <Table hover responsive striped bordered={false}>
            <thead>
              <tr>
                {bidRateFiltersValues.map((header, idx) => (
                  <>{<th key={idx}>{header.label}</th>}</>
                ))}
              </tr>
            </thead>
            <tbody>
              {serviceType == 'bidrateLaborRates'
                ? laborServiceTypeOptions != undefined &&
                  laborServiceTypeOptions.map((row, indx) => (
                    <>
                      <tr
                        key={indx}
                        className={
                          expandState[indx]
                            ? 'table-hover-row addNewCodeSelection'
                            : 'table-hover-row'
                        }
                        onClick={e => handlelaborDetailsClick(row, indx)}
                      >
                        <td>{row.laborCode}</td>
                        <td>{row.laborDescription}</td>
                        <td>{row.laborCategory}</td>
                      </tr>
                    </>
                  ))
                : serviceType == 'bidrateEquipmentRates'
                ? equipmentServiceTypeOptions != undefined &&
                  equipmentServiceTypeOptions.map((row, indx) => (
                    <>
                      <tr
                        key={indx}
                        className={
                          expandState[indx]
                            ? 'addNewCodeSelection table-hover-row'
                            : 'table-hover-row'
                        }
                        onClick={e => handlelaborDetailsClick(row, indx)}
                      >
                        <td>{row.eqpmntCode}</td>
                        <td>{row.eqpmntDescription}</td>
                        <td>{row.eqpmntCategory}</td>
                      </tr>
                    </>
                  ))
                : otherServiceTypeOptions != undefined &&
                  otherServiceTypeOptions.map((row, indx) => (
                    <>
                      <tr
                        key={indx}
                        className={
                          expandState[indx]
                            ? 'addNewCodeSelection table-hover-row'
                            : 'table-hover-row'
                        }
                        onClick={e => handlelaborDetailsClick(row, indx)}
                      >
                        <td>{row.code}</td>
                        <td>{row.description}</td>
                        {/* <td>{row.equipmentCatergory}</td>
                      <td>{row.unitofMeasure}</td> */}
                      </tr>
                    </>
                  ))}
            </tbody>
          </Table>
        </div>
        <div className="float-right">
          <AEPPagination
            fetchItems={
              serviceType == 'bidrateLaborRates'
                ? fetchLaborServiceTypeOptions
                : serviceType == 'bidrateEquipmentRates'
                ? fetchEquipmentServiceTypeOptions
                : fetchOtherServiceTypeOptions
            }
            setPagination={setAddNewCodePagination}
            pagination={pagination}
            currentPage={currentPage}
            sortBy={sortBy}
            sortOrder={sortOrder}
          />
        </div>
      </Modal.Body>
      <Modal.Footer>
        <ButtonArea
          primaryLabel="Continue"
          primaryDisabled={ctnBtnEnable}
          secondaryLabel="Cancel"
          primaryOnClick={handleContinueBtnClick}
          secondaryOnClick={handleShowMoal}
        />
      </Modal.Footer>
    </Modal>
  );
};

export { AddNewCodeModal };
