import { TimesheetStatus } from 'enums/statuses.ts';

export const keyTimeSheetTab = {
  ALL: 'all',
  SAVED: 'saved',
  SUBMITTED: 'submitted',
  PENDING_FOR_APPROVAL: 'pending',
  APPROVED: 'approved',
  INVOICED: 'invoiced',
  RETURNED: 'returned',
  CANCELED: 'canceled',
};

export const getTimeSheetStatusByKey = keyName => {
  let status = '';
  optionsTimeSheetListTab.forEach(option => {
    if (option.key == keyName) {
      if (option.status) {
        status = option.status?.toString();
        return;
      }
    }
  });
  return status;
};

export const optionsTimeSheetListTab = [
  {
    key: keyTimeSheetTab.ALL,
    value: 'All',
    permission: 'can_view_all_timesheet',
  },
  {
    key: keyTimeSheetTab.SAVED,
    value: 'Saved',
    permission: 'can_view_saved_timesheet',
    status: [
      TimesheetStatus.TIMESHEET_SAVED_NOT_SUBMITTED,
      TimesheetStatus.UVL_TIMESHEET_SAVED_NOT_SUBMITTED,
    ],
  },
  {
    key: keyTimeSheetTab.SUBMITTED,
    value: 'Submitted',
    permission: 'can_view_submitted_timesheet',
    status: [
      TimesheetStatus.TIMESHEET_SUBMITTED_NOT_APPROVED,
      TimesheetStatus.TIMESHEET_SUBMITTED_NOT_REVIEWED,
      TimesheetStatus.UVL_TIMESHEET_SUBMITTED,
    ],
  },
  {
    key: keyTimeSheetTab.PENDING_FOR_APPROVAL,
    value: 'Pending for Approval',
    permission: 'can_view_pend_timesheet',
    status: [],
  },
  {
    key: keyTimeSheetTab.APPROVED,
    value: 'Approved',
    permission: 'can_view_approved_timesheet',
    status: [TimesheetStatus.TIMESHEET_APPROVED],
  },
  {
    key: keyTimeSheetTab.INVOICED,
    value: 'Invoiced',
    permission: 'can_view_approved_timesheet',
    status: [TimesheetStatus.TIMESHEET_INVOICED],
  },
  {
    key: keyTimeSheetTab.RETURNED,
    value: 'Returned',
    permission: 'can_view_returned_timeshhet',
    status: [
      TimesheetStatus.TIMESHEET_RETURNED_NOT_APPROVED,
      TimesheetStatus.TIMESHEET_RETURNED_NOT_SUBMITTED,
    ],
  },
  {
    key: keyTimeSheetTab.CANCELED,
    value: 'Cancelled',
    permission: 'can_view_cancelled_timesheet',
    status: [
      TimesheetStatus.TIMESHEET_CANCELLED,
      TimesheetStatus.UVL_TIMESHEET_CANCELLED,
    ],
  },
];
