import queryString from 'query-string';
import { http } from './helpers/BaseApi.ts';

const retrieveCrewType = async () => {
  try {
    return await http.get(`/crewtype?limit=-1&fetchAll=true`);
  } catch (error) {
    return error;
  }
};

const retrieveWorkType = async () => {
  try {
    return await http.get(`/worktype?limit=-1&fetchAll=true`);
  } catch (error) {
    return error;
  }
};

const retrieveWorkArea = async queryParams => {
  try {
    return await http.get(
      `/workarea?${queryString.stringify(queryParams)}&limit=-1&fetchAll=true`,
    );
  } catch (error) {
    return error;
  }
};

const retrieveJobAuthorizations = async queryParams => {
  try {
    return await http.get(
      `/jobauthorization?${queryString.stringify(queryParams)}`,
    );
  } catch (error) {
    return error;
  }
};

const retrieveOperatingCompany = async queryParams => {
  try {
    return await http.get(
      `/operating-company?${queryString.stringify(
        queryParams,
      )}&limit=-1&fetchAll=true`,
    );
  } catch (error) {
    return error;
  }
};

const retrieveCircuit = async queryParams => {
  try {
    return await http.get(`/circuit?${queryString.stringify(queryParams)}`);
  } catch (error) {
    return error;
  }
};

const retrieveConfig = async key => {
  try {
    return await http.get(`/configValue/${key}`);
  } catch (error) {
    return error;
  }
};

export {
  retrieveCrewType,
  retrieveWorkType,
  retrieveWorkArea,
  retrieveJobAuthorizations,
  retrieveOperatingCompany,
  retrieveCircuit,
  retrieveConfig,
};
