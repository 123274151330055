import React from 'react';

export const TimesheetListViewIconDefault = () => {
  return (
    <svg
      xmlns="http://www.w3.org/2000/svg"
      width="36"
      height="36"
      viewBox="0 0 32 32"
    >
      <g data-name="Group 161645">
        <g
          data-name="🔲🎨 Container l Color"
          style={{ fill: '#fff', stroke: '#4a4a4a', strokeMiterlimit: '10' }}
        >
          <path
            d="M4 0h28v32H4a4 4 0 0 1-4-4V4a4 4 0 0 1 4-4z"
            style={{ stroke: 'none' }}
          />
          <path
            d="M4 .5h27.5v31H4A3.5 3.5 0 0 1 .5 28V4A3.5 3.5 0 0 1 4 .5z"
            style={{ fill: 'none' }}
          />
        </g>
        <path
          data-name="Path 28516"
          d="M0 0h24v24H0z"
          style={{ fill: 'none' }}
          transform="translate(4 4)"
        />
        <path
          data-name="Path 28517"
          d="M4 18h4a1 1 0 0 0 0-2H4a1 1 0 0 0 0 2zM3 7a1 1 0 0 0 1 1h16a1 1 0 0 0 0-2H4a1 1 0 0 0-1 1zm1 6h10a1 1 0 0 0 0-2H4a1 1 0 0 0 0 2z"
          style={{ fill: '#4a4a4a' }}
          transform="translate(4 4)"
        />
      </g>
    </svg>
  );
};

export const TimesheetListViewIconSelected = () => {
  return (
    <svg
      xmlns="http://www.w3.org/2000/svg"
      width="36"
      height="36"
      viewBox="0 0 32 32"
    >
      <g data-name="Group 161653">
        <g
          data-name="🔲🎨 Container l Color"
          style={{ fill: '#4a4a4a', stroke: '#4a4a4a', strokeMiterlimit: '10' }}
        >
          <path
            d="M4 0h28v32H4a4 4 0 0 1-4-4V4a4 4 0 0 1 4-4z"
            style={{ stroke: 'none' }}
          />
          <path
            d="M4 .5h27.5v31H4A3.5 3.5 0 0 1 .5 28V4A3.5 3.5 0 0 1 4 .5z"
            style={{ fill: 'none' }}
          />
        </g>
        <path
          data-name="Path 28516"
          d="M0 0h24v24H0z"
          style={{ fill: 'none' }}
          transform="translate(4 4)"
        />
        <path
          data-name="Path 28517"
          d="M4 18h4a1 1 0 0 0 0-2H4a1 1 0 0 0 0 2zM3 7a1 1 0 0 0 1 1h16a1 1 0 0 0 0-2H4a1 1 0 0 0-1 1zm1 6h10a1 1 0 0 0 0-2H4a1 1 0 0 0 0 2z"
          style={{ fill: '#fff' }}
          transform="translate(4 4)"
        />
      </g>
    </svg>
  );
};
