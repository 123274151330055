import React from 'react';
import { PDFViewer } from 'components/common/PDFviewer/PDFViewer';

const WorkAuthPDF = props => {
  const baseURL = `${process.env.REACT_APP_API_BASE_URL}/api`;
  return (
    <>
      <PDFViewer
        pdfUrl={`${baseURL}/purchase-orders/${props.poId}/attachments/work-auth.pdf`}
        name={'workauth'}
        refresh={props.refresh}
        isStyleSet={props.isSetStyle}
      />
    </>
  );
};

export { WorkAuthPDF };
