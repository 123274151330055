import { ROUTES } from 'constants/routes';
import { Cookie } from 'enums/cookie.ts';
import { useEffect } from 'react';
import { useDispatch } from 'react-redux';
import { useNavigate } from 'react-router-dom';
import { loginUser } from 'store/slices/authenticationSlice';

const OAuthCallback = () => {
  const navigate = useNavigate();
  const dispatch = useDispatch();

  useEffect(() => {
    const cookie = document.cookie;

    const accessTokenParts = cookie.split(`${Cookie.ACCESS_TOKEN}=`);
    const accessToken = accessTokenParts[1]?.split(';').shift();
    const refreshTokenParts = cookie.split(`${Cookie.REFRESH_TOKEN}=`);
    const refreshToken = refreshTokenParts[1]?.split(';').shift();

    if (
      accessToken !== undefined &&
      accessToken !== '' &&
      refreshToken !== undefined &&
      refreshToken !== ''
    ) {
      dispatch(
        loginUser({
          response: { data: { accessToken, refreshToken } },
        }),
      );
    }

    navigate(`${ROUTES.home}`);
  }, []);

  return <>Redirecting to homepage...</>;
};

export { OAuthCallback };
