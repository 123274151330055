import { http } from 'services/helpers/BaseApi.ts';

const uploadFiles = async (payload, recordNmbr, serviceType = 'timesheets') => {
  try {
    let formData = new FormData();
    formData.append('file', payload);
    return await http.post(
      `${serviceType}/${recordNmbr}/attachments`,
      formData,
    );
  } catch (error) {
    return error;
  }
};

const deleteFile = async (fileId, recordNmbr, serviceType = 'timesheets') => {
  try {
    return await http.delete(
      `${serviceType}/${recordNmbr}/attachments/${fileId}`,
    );
  } catch (error) {
    return error;
  }
};

export { uploadFiles, deleteFile };
