import React from 'react';

export const ErrorIcon = () => {
  return (
    <svg
      data-name="error_black_24dp (1)"
      xmlns="http://www.w3.org/2000/svg"
      width="18"
      height="18"
      viewBox="0 0 18 18"
    >
      <path
        data-name="error_black_24dp (1)"
        d="M11 2a9 9 0 1 0 9 9 9 9 0 0 0-9-9zm0 9.9a.9.9 0 0 1-.9-.9V7.4a.9.9 0 1 1 1.8 0V11a.9.9 0 0 1-.9.9zm.9 3.6h-1.8v-1.8h1.8z"
        transform="translate(-2 -2)"
        style={{ fill: '#7d0c17' }}
      />
    </svg>
  );
};
