import { Form, Modal, Tab, Table, Tabs, Row, Col } from 'react-bootstrap';
import { ButtonArea } from 'components/common//Button/ButtonArea/ButtonArea';
import { Divider } from 'components/common/Divider/Divider';
import { useState } from 'react';
import _ from 'lodash';

const AddNeCrewTypeModel = ({
  show,
  setShowModal,
  handleAdminCrewTypeSave,
  handleAdminCrewTypeCancel,
  onChangeHandler,
  crewTypeDetailsData,
  inputs,
  dutyCode,
  orgGroup,
}) => {
  const tabs = [{ key: 'details', value: 'Details' }];
  const [key, setKey] = useState('details');

  return (
    <Modal
      show={show}
      onHide={() => setShowModal(false)}
      size="lg"
      aria-labelledby="contained-modal-title-vcenter"
      centered
    >
      <Modal.Header closeButton="true">
        <Modal.Title>Edit Crew Type</Modal.Title>
      </Modal.Header>
      <Divider />
      <div className="aepContainer adminModuleCrewTypeDetailContainer">
        <Tabs id="poPage" activeKey={key} onSelect={k => setKey(k)}>
          {tabs.map((tab, index) => (
            <Tab eventKey={tab.key} title={tab.value} key={index}>
              <div className="poDetailsContainer">
                <Form>
                  <Row>
                    <Col sm={3}>
                      <Form.Group controlId="form.workTypeLists">
                        <Form.Label>Crew Type Lists:</Form.Label>
                        <Form.Select
                          aria-label="CrewTypeLists"
                          className=""
                          onChange={onChangeHandler}
                          style={{ display: 'block' }}
                        >
                          <option value="selectPO">
                            {crewTypeDetailsData.crewType}
                          </option>
                        </Form.Select>
                      </Form.Group>
                    </Col>
                  </Row>
                  <Divider style={{ margin: '20px 0' }} />
                  <Row>
                    <Col sm={3} style={{ paddingRight: '10px' }}>
                      <Form.Group controlId="form.workDesc">
                        <Form.Label>Crew Description:</Form.Label>
                        <Form.Control
                          defaultValue={crewTypeDetailsData.crewDesc}
                          onChange={onChangeHandler}
                          name="crewDesc"
                          value={inputs.crewDesc}
                          style={{
                            width: '100%',
                            fontSize: '16px',
                            padding: '15px',
                            color: '#000',
                          }}
                        />
                      </Form.Group>
                    </Col>
                    <Col sm={3} style={{ paddingRight: '10px' }}>
                      <Form.Group controlId="form.dutyCode">
                        <Form.Label>Duty Code:</Form.Label>
                        <Form.Select
                          aria-label="dutyCode"
                          value={inputs.dutyCode}
                          onChange={onChangeHandler}
                          name="dutyCode"
                          style={{ width: '100%' }}
                        >
                          {dutyCode.map((type, index) => (
                            <option key={index} value={type.value}>
                              {type.label}
                            </option>
                          ))}
                        </Form.Select>
                      </Form.Group>
                    </Col>
                    <Col sm={3} style={{ paddingRight: '10px' }}>
                      <Form.Group controlId="form.orgGroup">
                        <Form.Label>Org Group:</Form.Label>
                        <Form.Select
                          aria-label="Org Group"
                          value={inputs.orgDeptGroup}
                          onChange={onChangeHandler}
                          name="orgDeptGroup"
                          style={{ width: '100%' }}
                        >
                          {orgGroup.map((type, index) => (
                            <option key={index} value={type.value}>
                              {type.label}
                            </option>
                          ))}
                        </Form.Select>
                      </Form.Group>
                    </Col>
                  </Row>
                </Form>
              </div>
            </Tab>
          ))}
        </Tabs>
      </div>
      <Modal.Footer>
        <ButtonArea
          primaryLabel="Save"
          secondaryLabel="Cancel"
          primaryOnClick={() => handleAdminCrewTypeSave()}
          secondaryOnClick={() => handleAdminCrewTypeCancel()}
        />
      </Modal.Footer>
    </Modal>
  );
};

export { AddNeCrewTypeModel };
