// Imports
import ___CSS_LOADER_API_SOURCEMAP_IMPORT___ from "../../../node_modules/react-scripts/node_modules/css-loader/dist/runtime/sourceMaps.js";
import ___CSS_LOADER_API_IMPORT___ from "../../../node_modules/react-scripts/node_modules/css-loader/dist/runtime/api.js";
var ___CSS_LOADER_EXPORT___ = ___CSS_LOADER_API_IMPORT___(___CSS_LOADER_API_SOURCEMAP_IMPORT___);
// Module
___CSS_LOADER_EXPORT___.push([module.id, `.appContainer {
  display: flex;
  flex-direction: column;
}

.pageContent {
  margin-left: 75px;
  max-height: calc(100vh - 148px);
  overflow-y: scroll;
}

.pageContentMobile {
  max-height: calc(100vh - 220px);
  overflow-y: scroll;
  overflow-x: hidden;
}

.layoutRow {
  flex-direction: row;
  flex-wrap: wrap;
}

.layoutColumn {
  display: flex;
  flex-direction: column;
  flex-basis: 100%;
}`, "",{"version":3,"sources":["webpack://./src/components/Layout/Layout.scss"],"names":[],"mappings":"AAAA;EACE,aAAA;EACA,sBAAA;AACF;;AAEA;EACE,iBAAA;EACA,+BAAA;EACA,kBAAA;AACF;;AAEA;EACE,+BAAA;EACA,kBAAA;EACA,kBAAA;AACF;;AAEA;EAEE,mBAAA;EACA,eAAA;AAAF;;AAGA;EACE,aAAA;EACA,sBAAA;EACA,gBAAA;AAAF","sourcesContent":[".appContainer {\n  display: flex;\n  flex-direction: column;\n}\n\n.pageContent {\n  margin-left: 75px;\n  max-height: calc(100vh - 148px);\n  overflow-y: scroll;\n}\n\n.pageContentMobile {\n  max-height: calc(100vh - 220px);\n  overflow-y: scroll;\n  overflow-x: hidden;\n}\n\n.layoutRow {\n  // display: flex;\n  flex-direction: row;\n  flex-wrap: wrap;\n}\n\n.layoutColumn {\n  display: flex;\n  flex-direction: column;\n  flex-basis: 100%;\n}\n"],"sourceRoot":""}]);
// Exports
___CSS_LOADER_EXPORT___.locals = {};
export default ___CSS_LOADER_EXPORT___;
