import React from 'react';

export const MaximizeIcon = () => {
  return (
    <svg width="28" height="28" viewBox="0 0 28 28">
      <path
        data-name="Path 26004"
        d="M31 21H21v10a2 2 0 1 1-4 0V21H7a2 2 0 0 1 0-4h10V7a2 2 0 0 1 4 0v10h10a2 2 0 1 1 0 4z"
        transform="translate(-5 -5)"
        style={{ fill: '#4a4a4a' }}
      />
    </svg>
  );
};
