import DatePicker from 'react-datepicker';
import { useState, useEffect } from 'react';
import 'react-datepicker/dist/react-datepicker.css';
import { Button, Form, Tab, Table, Tabs, Row, Col } from 'react-bootstrap';
import './AuditDetails.scss';
import { useDispatch, useSelector } from 'react-redux';
import { setAuditData } from 'store/slices/AuditModules/auditSlice';

const ClearanceAudits = () => {
  const [clrncAudit, setClrncAudit] = useState({});
  const dispatch = useDispatch();

  const auditData = useSelector(state => state.audit.auditFormData);

  const handleRadioChange = e => {
    const field = e.target.name.split('-');
    setClrncAudit({ ...clrncAudit, [field[0]]: field[1] });
    dispatch(setAuditData({ [field[0]]: field[1] }));
  };

  useEffect(() => {
    if (Object.values(clrncAudit).includes('F')) {
      dispatch(setAuditData({ clrncAudit: 'F' }));
    } else if (Object.values(clrncAudit).includes('P')) {
      dispatch(setAuditData({ clrncAudit: 'P' }));
    } else {
      dispatch(setAuditData({ clrncAudit: null }));
    }
  }, [clrncAudit]);

  return (
    <>
      <div className="aepBreadcrumb auditInnerContainer">
        <Form.Label className="responseLabel">
          Forestry Clearance Audit
        </Form.Label>
        <Form.Group controlId="kindOfSelection" className="responseForm">
          <Form.Check
            inline
            type="radio"
            aria-label="radio 1"
            label="Pass"
            className="input1"
            name="clrncAudit-P"
            checked={auditData?.clrncAudit == 'P'}
          />
          <Form.Check
            inline
            type="radio"
            aria-label="radio 2"
            label="Fail"
            className="input1"
            name="clrncAudit-F"
            checked={auditData?.clrncAudit == 'F'}
          />
        </Form.Group>
      </div>
      <Form className="auditSection">
        <Row className="mbAudit">
          <Col sm={4}>
            <Form.Group controlId="form.remitAddress">
              <Form.Label className="responseLabel">
                1. Clearance for Species
              </Form.Label>
              <Form.Group
                controlId="kindOfSelection"
                className="responseForm ml-4 mb-3"
              >
                <Form.Check
                  inline
                  type="radio"
                  aria-label="radio 1"
                  label="Pass"
                  className="input1"
                  name="clrncSpecies-P"
                  onChange={e => handleRadioChange(e)}
                  checked={auditData?.clrncSpecies == 'P'}
                />
                <Form.Check
                  inline
                  type="radio"
                  aria-label="radio 2"
                  label="Fail"
                  className="input1"
                  name="clrncSpecies-F"
                  onChange={e => handleRadioChange(e)}
                  checked={auditData?.clrncSpecies == 'F'}
                />
              </Form.Group>
            </Form.Group>
          </Col>
          <Col sm={4}>
            <Form.Group controlId="form.remitAddress">
              <Form.Label className="responseLabel">2. Row Width</Form.Label>
              <Form.Group
                controlId="kindOfSelection"
                className="responseForm ml-4 mb-3"
              >
                <Form.Check
                  inline
                  type="radio"
                  aria-label="radio 1"
                  label="Pass"
                  name="rowWidth-P"
                  onChange={e => handleRadioChange(e)}
                  checked={auditData?.rowWidth == 'P'}
                />
                <Form.Check
                  inline
                  value="MIN"
                  type="radio"
                  aria-label="radio 2"
                  label="Fail"
                  name="rowWidth-F"
                  onChange={e => handleRadioChange(e)}
                  checked={auditData?.rowWidth == 'F'}
                />
              </Form.Group>
            </Form.Group>
          </Col>
          <Col sm={4}>
            <Form.Group controlId="form.remitAddress">
              <Form.Label className="responseLabel">3.Danger Trees</Form.Label>
              <Form.Group
                controlId="kindOfSelection"
                className="responseForm ml-4 mb-3"
              >
                <Form.Check
                  inline
                  type="radio"
                  aria-label="radio 1"
                  label="Pass"
                  name="dangerTrees-P"
                  onChange={e => handleRadioChange(e)}
                  checked={auditData?.dangerTrees == 'P'}
                />
                <Form.Check
                  inline
                  value="MIN"
                  type="radio"
                  aria-label="radio 2"
                  label="Fail"
                  name="dangerTrees-F"
                  onChange={e => handleRadioChange(e)}
                  checked={auditData?.dangerTrees == 'F'}
                />
              </Form.Group>
            </Form.Group>
          </Col>
        </Row>
        <Row className="mbAudit">
          <Col sm={12}>
            <Form.Group controlId="form.remitAddress" className="ml-3">
              <Form.Label className="safetyAudits ml-1">Comments :</Form.Label>
              <Form.Control
                as="textarea"
                value={auditData?.comments3}
                onChange={e =>
                  dispatch(setAuditData({ comments3: e.target.value }))
                }
              />
            </Form.Group>
          </Col>
        </Row>
      </Form>
    </>
  );
};
export { ClearanceAudits };
