import { Tab, Tabs } from 'react-bootstrap';
import './AffidavitForm.scss';
import AffidavitComponent from './AffidavitComponent';

const AffidavitForm = props => {
  return (
    <>
      <Tabs id="timesheetPage" defaultActiveKey={1}>
        <Tab eventKey="1" title="Affidavit of completion" key={1}>
          <div>
            <AffidavitComponent onClickClose={props.closeParentModal} />
          </div>
        </Tab>
      </Tabs>
    </>
  );
};

export default AffidavitForm;
