import {
  add,
  addWeeks,
  getISODay,
  parseISO,
  set,
  setISODay,
  startOfWeek,
  sub,
} from 'date-fns';
import { format, utcToZonedTime } from 'date-fns-tz';
import { Day } from 'enums/dates.ts';

export const isEmptyVal = val => {
  return val === null || val === undefined || val === '';
};

// Replaces hyphens in a string with space.
export const formatValue = value => {
  return !isEmptyVal(value) ? value.replace(/-/g, ' ') : value;
};

export const getLocalDateTime = utcDate => {
  let date2Ret = null;
  if (!isEmptyVal(utcDate)) {
    const localTimezone = Intl.DateTimeFormat().resolvedOptions();
    const localDateTime = utcToZonedTime(utcDate, localTimezone.timeZone);
    const formattedDate = format(localDateTime, 'yyyy-MM-dd HH:mm:ss zzz', {
      timeZone: localTimezone.timeZone,
    });
    date2Ret = formattedDate;
  }
  return date2Ret;
};

export const getMidnightDate = (dateVar, noOfDays = 0, operation) => {
  if (!isEmptyVal(dateVar)) {
    let inputDate = dateVar;
    if (typeof dateVar === 'string') {
      inputDate = parseISO(dateVar);
    }

    let midnightDate = set(inputDate, {
      hours: 0,
      minutes: 0,
      seconds: 0,
      milliseconds: 0,
    });

    if (noOfDays) {
      if (operation === 'add') {
        midnightDate = add(midnightDate, { days: noOfDays });
      } else if (operation === 'sub') {
        midnightDate = sub(midnightDate, { days: noOfDays });
      }
    }

    return midnightDate;
  }
};

export const getNextDayOccurrence = dayINeed => {
  const today = getISODay(new Date());

  // if we haven't yet passed the day of the week that I need:
  if (today <= dayINeed) {
    // then just give me this week's instance of that day
    const nextOccurrenceDate = setISODay(
      startOfWeek(new Date(), { weekStartsOn: dayINeed }),
      dayINeed,
    );
    return getMidnightDate(nextOccurrenceDate);
  } else {
    // otherwise, give me *next week's* instance of that same day
    const nextOccurrenceDate = setISODay(
      startOfWeek(addWeeks(new Date(), 1), { weekStartsOn: Day.MONDAY }),
      dayINeed,
    );
    return getMidnightDate(nextOccurrenceDate);
  }
};
