import React, { useRef, useState, useEffect, useCallback } from 'react';
import { Form, Modal, Button } from 'react-bootstrap';
import { ButtonArea } from 'components/common//Button/ButtonArea/ButtonArea';
import { Divider } from 'components/common/Divider/Divider';
import { Row, Col } from 'react-bootstrap/esm';
import SignatureCanvas from 'react-signature-canvas';

const AddSignatureModal = props => {
  const padRef = useRef(null);

  // @ts-ignore
  const [canvas, setCanvas] = useState(String);
  const [canvasVisibility, setCanvasVisibility] = useState(false);
  const [continueBtnEnable, setContinueBtnEnable] = useState(true);

  const clearSignatureCanvas = useCallback(() => {
    padRef?.current?.clear();
    setCanvas(undefined);
    setCanvasVisibility(false);
  }, []);

  const handleGetCanvas = useCallback(() => {
    const canvas = padRef?.current?.toDataURL();
    setCanvas(canvas);
    setCanvasVisibility(true);
    setContinueBtnEnable(false);
    props.setSignatureBase64(canvas);
  }, []);

  const handleSubmit = () => {
    props.setSignatureBase64(canvas);
    props.setShowModal(false);
    props.handleContinueClick && props.handleContinueClick();
  };

  return (
    <>
      <Modal
        show={props.show}
        onHide={() => props.setShowModal(false)}
        size="lg"
        aria-labelledby="contained-modal-title-vcenter"
        centered
      >
        <Modal.Header closeButton="true">
          <Modal.Title>
            {props.title ? props.title : 'Accept Purchase Order'}
          </Modal.Title>
        </Modal.Header>
        <Divider />
        <Modal.Body>
          {props.subTitle ? (
            <div className="titleText">{props.subTitle}</div>
          ) : (
            <div className="titleText">Can you sign in the Box below</div>
          )}
          <div className="signatureBox">
            {canvasVisibility && canvas ? (
              <img src={canvas} alt="signature" />
            ) : (
              <SignatureCanvas
                ref={padRef}
                penColor="black"
                canvasProps={{
                  className: 'sigCanvas',
                }}
              />
            )}
            <div className="sigOptions">
              <Button
                onClick={handleGetCanvas}
                variant="link"
                className="sigBtn"
              >
                Accept Signature
              </Button>
              <Button
                variant="link"
                className="sigBtn"
                onClick={clearSignatureCanvas}
              >
                Clear
              </Button>
            </div>
          </div>
        </Modal.Body>
        <Modal.Footer>
          <ButtonArea
            primaryLabel="Continue"
            primaryDisabled={continueBtnEnable}
            secondaryLabel="Cancel"
            type="submit"
            primaryOnClick={() => handleSubmit()}
            secondaryOnClick={() => props.setShowModal(false)}
          />
        </Modal.Footer>
      </Modal>
    </>
  );
};

export { AddSignatureModal };
