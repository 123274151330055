import React from 'react';

export const AddIcon = () => {
  return (
    <svg width="18" height="18" viewBox="0 0 18 18">
      <g data-name="Group 12282">
        <path
          data-name="Path 25862"
          d="M21-88a1.184 1.184 0 0 0-1.184 1.184v6.632h-6.632A1.184 1.184 0 0 0 12-79a1.184 1.184 0 0 0 1.184 1.184h6.632v6.632A1.184 1.184 0 0 0 21-70a1.184 1.184 0 0 0 1.184-1.184v-6.632h6.632A1.184 1.184 0 0 0 30-79a1.184 1.184 0 0 0-1.184-1.184h-6.632v-6.632A1.184 1.184 0 0 0 21-88z"
          style={{ fill: '#fff' }}
          transform="translate(-12 88)"
        />
      </g>
    </svg>
  );
};
