import React from 'react';
import { Form, Row, Col } from 'react-bootstrap';
import './PurchaseOrderRates.scss';

const PORatesHeader = ({ POHeaderDetails }) => {
  return (
    <div className="poRateContainer">
      <Row className="row">
        <Col sm={3}>
          <Form.Group className="mb-3" controlId="PORateNumber">
            <Form.Label>PO Rate Number</Form.Label>
            <Form.Control
              type="text"
              value={POHeaderDetails[0].poratenum}
              disabled
            />
          </Form.Group>
        </Col>
        <Col sm={3}>
          <Form.Group className="mb-3" controlId="PORevisionNumber">
            <Form.Label>PO Rate Revision</Form.Label>
            <Form.Control
              type="text"
              value={POHeaderDetails[0].poraterev}
              disabled
            />
          </Form.Group>
        </Col>
        <Col sm={3}>
          <Form.Group className="mb-3" controlId="EffFromDate">
            <Form.Label>Effective From Date</Form.Label>
            <Form.Control
              type="text"
              value={POHeaderDetails[0].effectivefromdt}
              disabled
            />
          </Form.Group>
        </Col>
        <Col sm={3}>
          <Form.Group className="mb-3" controlId="EffToDate">
            <Form.Label>Effective To Date</Form.Label>
            <Form.Control
              type="text"
              value={POHeaderDetails[0].effectivetodt}
              disabled
            />
          </Form.Group>
        </Col>
      </Row>
    </div>
  );
};

export default PORatesHeader;
