import React from 'react';

export const UploadIcon = () => {
  return (
    <svg
      xmlns="http://www.w3.org/2000/svg"
      width="36"
      height="36"
      viewBox="0 0 36 36"
    >
      <defs>
        <clipPath id="17jbkxz1fa">
          <path
            data-name="Rectangle 5562"
            transform="translate(0 .095)"
            style={{ fill: '#fff' }}
            d="M0 0h20v25H0z"
          />
        </clipPath>
      </defs>
      <g data-name="Group 13578" transform="translate(4642 -8478)">
        <rect
          data-name="🔲🎨 Container l Color"
          width="36"
          height="36"
          rx="4"
          transform="translate(-4642 8478)"
          style={{ fill: '#025a95' }}
        />
        <g data-name="Group 13365">
          <g
            data-name="Group 13364"
            transform="translate(-4634 8483.905)"
            style={{ clipPath: 'url(#17jbkxz1fa)' }}
          >
            <path
              data-name="Path 25963"
              d="M1.917 25.559a1.839 1.839 0 0 1-1.342-.575A1.839 1.839 0 0 1 0 23.642V1.917A1.839 1.839 0 0 1 .575.575 1.839 1.839 0 0 1 1.917 0h10.735a1.862 1.862 0 0 1 .748.16 2 2 0 0 1 .623.415l5.847 5.847a2 2 0 0 1 .415.623 1.862 1.862 0 0 1 .16.751v15.846a1.965 1.965 0 0 1-1.917 1.917zM12.492 6.9V1.917H1.917v21.725H18.53V7.859h-5.08a.929.929 0 0 1-.958-.958M1.917 1.917z"
              transform="translate(0 -.464)"
              style={{ fill: '#fff' }}
            />
            <path
              data-name="Path 25964"
              d="M14.832 19.428a.974.974 0 0 1 .319-.224 1 1 0 0 1 .7 0 .974.974 0 0 1 .319.224l3.578 3.578a.949.949 0 1 1-1.342 1.342L16.4 22.335V27.8a.959.959 0 1 1-1.917 0v-5.465"
              transform="translate(-5.228 -7.375)"
              style={{ fill: '#fff' }}
            />
            <path
              data-name="Path 25965"
              d="M14.378 22.335V27.8a.959.959 0 1 1-1.917 0v-5.465l-2.013 2.013a.949.949 0 1 1-1.342-1.342l3.578-3.578A.974.974 0 0 1 13 19.2a1 1 0 0 1 .7 0 .974.974 0 0 1 .319.224"
              transform="translate(-3.184 -7.375)"
              style={{ fill: '#fff' }}
            />
          </g>
        </g>
      </g>
    </svg>
  );
};
