import { Form, Modal } from 'react-bootstrap';
import { ButtonArea } from 'components/common//Button/ButtonArea/ButtonArea';
import { Divider } from 'components/common/Divider/Divider';
import { Row, Col } from 'react-bootstrap/esm';
import { useDispatch, useSelector } from 'react-redux';
import { useState } from 'react';
import _ from 'lodash';
import Switch from 'react-switch';
import './AdminSite.scss';
import { useEffect } from 'react';

const AddNewSiteModal = ({
  show,
  setShowModal,
  handleAddSiteContinueClick,
}) => {
  const dispatch = useDispatch();
  const [errorValue, seterrorValue] = useState(false);
  const [value, setValue] = useState();
  const [values, setValues] = useState({});
  const [item, setItem] = useState({
    regulated: false,
    unregulated: false,
    active: false,
    authorized: false,
  });

  const handleClick = e => {
    const name = e.target.name;
    let value = e.target.value;
    if (value == '') {
      let updatedValues = _.cloneDeep(values);
      delete updatedValues[name];
      setValues(updatedValues);
    } else {
      setValues({ ...values, [name]: value });
    }
  };

  const handleChange = (checked, e, id) => {
    const name = id;
    let itemValue = checked;
    if (
      (name == 'regulated' && checked == true && item.unregulated == true) ||
      (name == 'unregulated' && checked == true && item.regulated == true)
    ) {
      seterrorValue(true);
    } else {
      seterrorValue(false);
      setItem({ ...item, [name]: itemValue });
    }
  };

  const handleCloseModel = () => {
    setValues({});
    setItem({
      regulated: false,
      unregulated: false,
      active: false,
      authorized: false,
    });
    setShowModal(false);
  };

  const handleAddSite = () => {
    handleAddSiteContinueClick({ ...values }, { ...item });
    setValues({});
    setItem({
      regulated: false,
      unregulated: false,
      active: false,
      authorized: false,
    });
  };

  const submitHandler: FormEventHandler = event => {
    event.preventDefault();
    event.persist();
  };
  return (
    <Modal
      show={show}
      onHide={() => handleCloseModel()}
      size="lg"
      aria-labelledby="contained-modal-title-vcenter"
      centered
    >
      <Modal.Header closeButton="true">
        <Modal.Title>Add New Site</Modal.Title>
      </Modal.Header>
      <Divider />
      <Modal.Body>
        <Form onSubmit={submitHandler}>
          <Row>
            <Col sm={6}>
              <Form.Group className="mb-3" controlId="editPoContactName">
                <Form.Label>Site:</Form.Label>
                <Form.Control
                  type="text"
                  name="siteId"
                  onChange={event => handleClick(event)}
                  value={value}
                />
              </Form.Group>
            </Col>
          </Row>
          <Row>
            <Form.Group className="mb-3" controlId="editPoContactName">
              <Form.Label>Description:</Form.Label>
              <Form.Control
                type="text"
                name="description"
                onChange={event => handleClick(event)}
                value={value}
              />
            </Form.Group>
          </Row>
          <Row>
            <Col sm={6}>
              <Form.Group className="mb-3" controlId="form.orgGroup">
                <Form.Label>Organization</Form.Label>
                <Form.Control
                  type="text"
                  name="organization"
                  disabled={true}
                  defaultValue="AEP"
                  value={value}
                />
              </Form.Group>
            </Col>
          </Row>
          <Row>
            <Col sm={6}>
              <Form.Group>
                <Form.Label>Entitlements:</Form.Label>
                <Form.Control
                  type="text"
                  name="entitlement"
                  onChange={event => handleClick(event)}
                  value={value}
                />
              </Form.Group>
            </Col>
          </Row>
          <Row>
            <Col>
              <Form.Group className="mb-3" controlId="editPoContactName">
                <Form.Label>Regulated:</Form.Label>

                <Switch
                  id="regulated"
                  checked={item.regulated}
                  type="switch"
                  aria-label="radio 1"
                  onChange={(checked, event, id) =>
                    handleChange(checked, event, id)
                  }
                  className="switch"
                  checkedIcon={false}
                  uncheckedIcon={false}
                  onColor="#025a95"
                  onHandleColor="#fff"
                  handleDiameter={30}
                  boxShadow="0px 1px 5px rgba(0, 0, 0, 0.6)"
                />
              </Form.Group>
            </Col>
            <Col>
              <Form.Group className="mb-3" controlId="editPoContactName">
                <Form.Label>Unregulated:</Form.Label>
                <Switch
                  id="unregulated"
                  checked={item.unregulated}
                  type="switch"
                  aria-label="radio 1"
                  className="switch"
                  onChange={(checked, event, id) =>
                    handleChange(checked, event, id)
                  }
                  checkedIcon={false}
                  uncheckedIcon={false}
                  onColor="#025a95"
                  onHandleColor="#fff"
                  handleDiameter={30}
                  boxShadow="0px 1px 5px rgba(0, 0, 0, 0.6)"
                />
              </Form.Group>
            </Col>
            <Col>
              <Form.Group className="mb-3" controlId="editPoContactName">
                <Form.Label>Active:</Form.Label>
                <Switch
                  id="active"
                  checked={item.active}
                  type="switch"
                  aria-label="radio 1"
                  onChange={(checked, event, id) =>
                    handleChange(checked, event, id)
                  }
                  className="switch"
                  checkedIcon={false}
                  uncheckedIcon={false}
                  onColor="#025a95"
                  onHandleColor="#fff"
                  handleDiameter={30}
                  boxShadow="0px 1px 5px rgba(0, 0, 0, 0.6)"
                />
              </Form.Group>
            </Col>
            <Col>
              <Form.Group className="mb-3" controlId="editPoContactName">
                <Form.Label>Authorized:</Form.Label>
                <Switch
                  id="authorized"
                  checked={item.authorized}
                  type="switch"
                  onChange={(checked, event, id) =>
                    handleChange(checked, event, id)
                  }
                  className="switch"
                  checkedIcon={false}
                  uncheckedIcon={false}
                  onColor="#025a95"
                  onHandleColor="#fff"
                  handleDiameter={30}
                  boxShadow="0px 1px 5px rgba(0, 0, 0, 0.6)"
                />
              </Form.Group>
            </Col>
          </Row>
          {errorValue && (
            <Row>
              <Col>
                <span class="errorMessage">
                  A given Site cannot be both Reg and Unreg.
                </span>
              </Col>
            </Row>
          )}
        </Form>
      </Modal.Body>
      <Modal.Footer>
        <ButtonArea
          primaryLabel="Continue"
          secondaryLabel="Cancel"
          primaryOnClick={() => handleAddSite()}
          secondaryOnClick={() => handleCloseModel()}
          primaryDisabled={_.size(values) < 3 ? true : false}
        />
      </Modal.Footer>
    </Modal>
  );
};

export { AddNewSiteModal };
