import { createSlice } from '@reduxjs/toolkit';

const initialState = {
  isLoading: false,
  error: false,
  errorResponse: {},
  items: [],
  recordItems: [],
  pagination: {
    totalItems: 0,
    currentPage: 1,
    itemsPerPage: 10,
    sortBy: '',
    sortOrder: 'asc',
  },
  inputList: [
    {
      FilterValue: '',
      input_rank: null,
      selectFilterValue: '',
      selectFilterLabel: '',
      presentIcon: '',
      filerForm: false,
      FilterLabel: '',
      selectedFilterRecords: [],
    },
  ],
  workTypeData: [],
  items: [],
  isStatus: false,
  updateFieldList: {},
};

const workTypeSlice = createSlice({
  name: 'workTypeSlice',
  initialState,
  reducers: {
    fetchWorkTypeDetails(state, action) {
      state.isLoading = false;
    },
    setInputFilterData(state, action) {
      if (action.payload.length != 0) {
        state.inputList = action.payload;
      } else {
        const stateValues = { ...state };
        stateValues.inputList = [...initialState.inputList];
        return { ...stateValues };
      }
    },
    setAddNewInputFilterData(state, action) {
      const stateValues = { ...state };
      stateValues.inputList = [...stateValues.inputList, action.payload];
      return { ...stateValues };
    },
    fetchSelectedAdminWorkTypetList(state) {
      state.isLoading = false;
    },
    fetchAdminWorkType(state, action) {
      const stateValues = { ...state };
      stateValues.isLoading = false;
      stateValues.pagination = {
        ...stateValues.pagination,
        ...action.payload,
      };
      return { ...stateValues };
    },
    updateWorkTypeData(state, action) {
      state.isLoading = false;
    },
    setWorkTypeSaveStatus(state, action) {
      const stateValues = { ...state };
      stateValues.isStatus = action.payload;
      return { ...stateValues };
    },

    setAdminWorkTypeData(state, action) {
      const workTypeList = [];

      action.payload?.rows.forEach(obj => {
        workTypeList.push({
          worktype: obj.worktype,
          worktypeDesc: obj.worktypeDesc,
          accountNmbr: obj.accountNmbr,
          abmsActivity: obj.abmsActivity,
          worktypeCategory: obj.worktypeCategory,
          orgDeptGroup: obj.orgDeptGroup,
          status: obj.status,
          worktypeNmbr: obj.worktypeNmbr,
        });
      });
      const stateValues = { ...state };
      stateValues.items = [...workTypeList];
      stateValues.pagination = {
        ...stateValues.pagination,
        ...action.payload?.pagination,
      };
      return { ...stateValues };
    },
    setSelectedAdminWorkTypeRecords(state, action) {
      const selectedAdminVendorRecords = [];
      action.payload?.forEach(obj => {
        let objValues = Object.values(obj);
        selectedAdminVendorRecords.push({
          label: objValues[0],
          value: objValues[0],
        });
      });
      const stateValues = { ...state };
      stateValues.recordItems = [...selectedAdminVendorRecords];
      return { ...stateValues };
    },
    setWorkTypeDetails(state, action) {
      const ocDetails = [];
      action?.payload?.worktypeOcs?.forEach(obj => {
        ocDetails.push({
          opco: `${obj?.ocNmbrOperatingCompany?.companyAbbr}/${obj?.ocNmbrOperatingCompany?.stateCode}`,
          projectId: obj?.abmsProject,
          workOrder: obj?.workOrder,
        });
      });
      action.payload.worktypeOcs = ocDetails;
      const stateValues = { ...state };
      stateValues.workTypeData = action.payload;
      return { ...stateValues };
    },
    setAdminWorktypePagination(state, action) {
      const stateValues = { ...state };
      stateValues.pagination = {
        ...stateValues.pagination,
        ...action.payload,
      };
      return { ...stateValues };
    },

    resetWorkType() {
      return { ...initialState };
    },

    setUpdateFieldsList(state, action) {
      const stateValues = { ...state };
      stateValues.updateFieldList = action.payload.data;
      return { ...stateValues };
    },

    unsetError(state) {
      const stateValues = { ...state };
      stateValues.error = false;
      stateValues.errorResponse = {};
      return { ...stateValues };
    },

    setError(state, action) {
      const stateValues = { ...state };
      stateValues.error = true;
      stateValues.errorResponse = action.payload;
      return { ...stateValues };
    },
  },
});

export const {
  fetchWorkTypeDetails,
  setInputFilterData,
  setAddNewInputFilterData,
  fetchSelectedAdminWorkTypetList,
  setSelectedAdminWorkTypeRecords,
  fetchAdminWorkType,
  updateWorkTypeData,
  setWorkTypeDetails,
  setWorkTypeSaveStatus,
  setAdminWorkTypeData,
  setAdminWorktypePagination,
  resetWorkType,
  setError,
  unsetError,
  setUpdateFieldsList,
} = workTypeSlice.actions;

export default workTypeSlice.reducer;
