import { all, call, put, select, takeLatest } from 'redux-saga/effects';
import * as service from 'services/adminModuleService';
import {
  fetchAdminSiteList,
  fetchSelectedAdminSiteList,
  setSelectedAdminSiteRecords,
  addAdminSite,
  deleteAdminSite,
  setAdminSiteData,
  setError,
} from 'store/slices/AdminModules/adminSiteSlice';
import { displayError } from 'components/common/Alert/ToastAlert';
import _ from 'lodash';

const adminSitePaginationSlector = state => state.adminSite.pagination;

export function* getAdminSiteList(action) {
  const queryParams = _.cloneDeep(yield select(adminSitePaginationSlector));

  try {
    const response = yield call(
      f => service.retrieveAdminSiteList(queryParams),
      action.payload,
    );
    const { data } = response;
    data.pagination = { totalItems: data.count };
    yield put(setAdminSiteData(data));
  } catch (e) {
    put(setError(true));
    displayError(
      'We are experiencing technical difficulties. Please come back shortly to try again !',
    );
  }
}

function* addAdminSiteDetail(action) {
  try {
    const response = yield call(
      f => service.addAdminSiteList(action.payload),
      action.payload,
    );
    const { data } = response;
    yield call(f => getAdminSiteList(action));
  } catch (e) {
    put(setError(true));
  }
}

function* deleteAdminSiteDetail(action) {
  try {
    const response = yield call(
      f => service.deleteAdminSiteList(action.payload),
      action.payload,
    );
    const { data } = response;
    yield call(f => getAdminSiteList(action));
  } catch (e) {
    put(setError(true));
  }
}
function* getSelectedAdminSiteList(action) {
  try {
    const response = yield call(
      f => service.retrieveAdminSiteFilterList(action.payload),
      action.payload,
    );
    const { data } = response;
    yield put(setSelectedAdminSiteRecords(data));
  } catch (e) {
    put(setError(true));
  }
}

function* watchAdminSiteList() {
  yield takeLatest(fetchAdminSiteList.type, getAdminSiteList);
}
function* watchAddadminSite() {
  yield takeLatest(addAdminSite.type, addAdminSiteDetail);
}
function* watchDeleteadminSite() {
  yield takeLatest(deleteAdminSite.type, deleteAdminSiteDetail);
}
function* watchFetchSelectedAdminTypeList() {
  yield takeLatest(fetchSelectedAdminSiteList.type, getSelectedAdminSiteList);
}

export function* adminSiteSaga() {
  yield all([
    watchAdminSiteList(),
    watchAddadminSite(),
    watchDeleteadminSite(),
    watchFetchSelectedAdminTypeList(),
  ]);
}
