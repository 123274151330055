import React from 'react';

export const ApprovedIcon = () => {
  return (
    <svg
      id="Check_Filled"
      data-name="Check Filled"
      xmlns="http://www.w3.org/2000/svg"
      width="22"
      height="22"
      viewBox="0 0 22 22"
    >
      <path
        id="Path_25906"
        data-name="Path 25906"
        d="M0,0H22V22H0Z"
        fill="none"
      />
      <path
        id="Path_25907"
        data-name="Path 25907"
        d="M10.668,2a8.668,8.668,0,1,0,8.668,8.668A8.672,8.672,0,0,0,10.668,2ZM8.935,15,4.6,10.668,5.823,9.446l3.112,3.1L15.514,5.97,16.736,7.2Z"
        transform="translate(0.332 0.332)"
        fill="#367600"
      />
    </svg>
  );
};
