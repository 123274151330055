import { AddClassificationIcon } from 'assets/images/AddClassificationIcon/AddClassificationIcon';
import { CloseIcon } from 'assets/images/CloseIcon/CloseIcon';
import { PDFIcon } from 'assets/images/PDFIcon/PDFIcon';
import { ModuleACL } from 'enums/entitlements.ts';
import { Button } from 'react-bootstrap';
import 'react-datepicker/dist/react-datepicker.css';
import { useSelector } from 'react-redux';
import { IsAllowedACL } from 'utils/aclHelper';

const AttachmentArea = props => {
  const user = useSelector(state => state.auth);
  const timesheet = useSelector(state => state.timesheetDetail);

  const {
    disableFields,
    setShowAttachment,
    uploadedFilesArray,
    handleDeleteFileFromArray,
    viewFileTimesheet,
    viewFile,
    handleDeleteFileFromServer,
  } = props;

  return (
    <>
      {IsAllowedACL(user, ModuleACL.TIMESHEET, 'can_create_timesheet') ||
      IsAllowedACL(user, ModuleACL.TIMESHEET, 'can_update_timesheet') ? (
        <div className="aepContainer">
          <div className="addBtn addClassification">
            <Button
              active={false}
              variant="link"
              onClick={e => setShowAttachment(true)}
              disabled={disableFields(null)}
            >
              <div>
                <AddClassificationIcon />
                <span className="ml-2">Add Attachment</span>
              </div>
            </Button>

            {uploadedFilesArray?.map((item, index) => (
              <>
                <Button
                  active={false}
                  variant="link"
                  className="pdfBtn"
                  onClick={() => viewFile(null, item.name, item)}
                >
                  {item.name ? item?.name : item?.files?.name}
                </Button>
                <Button
                  active={false}
                  variant="link"
                  className="removeFile"
                  onClick={() => handleDeleteFileFromArray(index)}
                >
                  <CloseIcon />
                </Button>
              </>
            ))}

            {timesheet?.timesheetAttachments?.map((item, index) => (
              <>
                <PDFIcon />
                <Button
                  active={false}
                  variant="link"
                  className="pdfBtn"
                  onClick={() => viewFileTimesheet(item)}
                >
                  {item.fileName}
                </Button>
                <Button
                  active={false}
                  variant="link"
                  className="removeFile"
                  onClick={() => handleDeleteFileFromServer(item, index)}
                >
                  <CloseIcon />
                </Button>
              </>
            ))}
          </div>
        </div>
      ) : (
        timesheet?.timesheetAttachments?.length > 0 && (
          <div className="aepContainer">
            <div className="addBtn addClassification">
              {timesheet?.timesheetAttachments?.map((item, index) => (
                <>
                  <PDFIcon />
                  <Button
                    active={false}
                    variant="link"
                    className="pdfBtn"
                    onClick={() => viewFileTimesheet(item)}
                  >
                    {item.fileName}
                  </Button>
                </>
              ))}
            </div>
          </div>
        )
      )}
    </>
  );
};

export { AttachmentArea };
