import { Form, Modal } from 'react-bootstrap';
import _ from 'lodash';
import { useSelector } from 'react-redux';
import ReactSelect, { components } from 'react-select';
import { ButtonArea } from 'components/common//Button/ButtonArea/ButtonArea';
import { Divider } from 'components/common/Divider/Divider';
import { Row, Col } from 'react-bootstrap/esm';
import { useEffect, useState, FormEventHandler } from 'react';

import './PurchaseOrderDetails.scss';

const AddNewContactModal = ({ show, setShowModal, handleAddClick }) => {
  const purchaseOrder = useSelector(state => state.purchaseOrderDetails);

  const NewContactList = [
    {
      POLabel: 'Name',
      name: 'name',
      POLabelValue: 'Example',
    },
    {
      POLabel: 'Email',
      name: 'email',
      POLabelValue: 'example@abc.com',
    },
    {
      POLabel: 'Phone',
      name: 'phonenum',
      POLabelValue: '555-345-234',
    },
    {
      POLabel: 'Roles',
      name: 'role',
      POLabelValue: 'Selection',
    },
  ];
  const [value, setValue] = useState();
  const [values, setValues] = useState({});
  const [error, setError] = useState('');

  const addRoles = [
    {
      label: 'Vendor PO Contact',
      value: 'vendorContact',
    },
    {
      label: 'Vendor Timesheet Contact',
      value: 'vendorTimesheetContact',
    },
    {
      label: 'Invoice Contact',
      value: 'invoiceContact',
    },
  ];

  const handleClick = e => {
    if (e.nativeEvent) {
      const name = e.target.name;
      let value = e.target.value;
      if (name === 'name') {
        setError('');
        let regEx = /[^a-zA-Z\s]*$/;
        if (value !== '' && value.length > 100) {
          e.target.value = value.substring(0, 99);
        }
        e.target.value = e.target.value.replace(regEx, '');
      } else if (name === 'email') {
        if (value !== '' && value.length > 60) {
          e.target.value = value.substring(0, 59);
        }
      } else if (name === 'phonenum') {
        let regEx = /[^0-9]*$/;
        e.target.value = value.replace(regEx, '');
        if (e.target.value.length > 9) {
          e.target.value = value.substring(0, 10);
        }
      }
      if (e.target.value !== '') {
        setValues({ ...values, [name]: value });
      }
    } else {
      let updatedArr = e;
      const newArr = updatedArr.map(role => {
        return role.value;
      });
      setValues({ ...values, role: newArr });
    }
  };

  const submitHandler: FormEventHandler = event => {
    event.preventDefault();
    event.persist();
  };

  const submitClick = () => {
    if (!_.isEmpty(values)) {
      if (purchaseOrder.contacts.some(item => item.name === values.name)) {
        setError('Name is already Exist.');
      } else {
        setError('');
        handleAddClick(NewContactList, values);
        setValues({});
      }
    } else {
      setError('Please Enter Name');
    }
  };

  const getRoleOptions = () => {
    const options = [];
    addRoles.forEach((role, index) => {
      options.push({ label: role.label, value: role.value, name: 'role' });
    });

    return options;
  };

  const Option = props => {
    return (
      <div>
        <components.Option {...props}>
          <Form.Group style={{ display: 'flex' }}>
            <input
              style={{ width: '16px', height: '16px', marginRight: '5px' }}
              type="checkbox"
              name={props.value}
              checked={props.isSelected}
              onChange={() => null}
            />

            <label>{props.label}</label>
          </Form.Group>
        </components.Option>
      </div>
    );
  };

  return (
    <Modal
      show={show}
      onHide={() => setShowModal(false)}
      size="lg"
      aria-labelledby="contained-modal-title-vcenter"
      centered
    >
      <Modal.Header closeButton="true">
        <Modal.Title>Add Contact</Modal.Title>
      </Modal.Header>
      <Divider />
      <Modal.Body>
        <Form>
          {error && (
            <Row>
              <Col className="error" sm={12}>
                {error}
              </Col>
            </Row>
          )}
          <Row>
            {NewContactList.map(col =>
              col.POLabel !== 'Roles' ? (
                <Col sm={6}>
                  <Form.Group controlId="addPoContactList">
                    <Form.Label>{col.POLabel}</Form.Label>
                    <Form.Control
                      type="text"
                      name={col.name}
                      onChange={event => handleClick(event)}
                      value={value}
                    />
                  </Form.Group>
                </Col>
              ) : (
                <Col sm={5} className="mr-10">
                  <Form.Group controlId="addRole">
                    <Form.Label>Role</Form.Label>
                    <ReactSelect
                      id="poReactContact"
                      classNamePrefix="poContact"
                      defaultValue=""
                      isClearable
                      isSearchable={true}
                      name="role"
                      placeholder="Select"
                      options={getRoleOptions()}
                      menuPosition="absolute"
                      maxMenuHeight={600}
                      onChange={e => handleClick(e)}
                      components={{ Option }}
                      isMulti
                      closeMenuOnSelect={false}
                      hideSelectedOptions={false}
                    />
                  </Form.Group>
                </Col>
              ),
            )}
          </Row>
        </Form>
      </Modal.Body>
      <Modal.Footer>
        <ButtonArea
          primaryLabel="Continue"
          secondaryLabel="Cancel"
          type="submit"
          primaryOnClick={() => submitClick()}
          secondaryOnClick={() => setShowModal(false)}
        />
      </Modal.Footer>
    </Modal>
  );
};

export { AddNewContactModal };
