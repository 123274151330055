import React from 'react';

export const PendingInitialPurchaseOrderIcon = () => {
  return (
    <svg
      xmlns="http://www.w3.org/2000/svg"
      width="36"
      height="38.25"
      viewBox="0 0 36 38.25"
    >
      <g data-name="Group 12147">
        <g data-name="Group 3921">
          <path
            data-name="Path 2837"
            d="M36.933-73.74v-16.071a3.14 3.14 0 0 0-3.154-3.118H13.2a3.14 3.14 0 0 0-3.154 3.118v26.432A3.14 3.14 0 0 0 13.2-60.26h14.429a9.664 9.664 0 0 0 8.771 5.581 9.61 9.61 0 0 0 9.653-9.545 9.6 9.6 0 0 0-9.118-9.515zm-9.924 11.7H13.2a1.347 1.347 0 0 1-1.352-1.336v-26.435a1.347 1.347 0 0 1 1.352-1.336h20.579a1.347 1.347 0 0 1 1.352 1.336v16.136a9.654 9.654 0 0 0-3.905 1.408v-.762H16.207v1.782h13.676a9.466 9.466 0 0 0-3.14 7.026 9.486 9.486 0 0 0 .266 2.18zm9.391 5.579a7.817 7.817 0 0 1-7.851-7.763 7.816 7.816 0 0 1 7.851-7.763 7.816 7.816 0 0 1 7.851 7.763 7.817 7.817 0 0 1-7.851 7.763zm3.6-8.652h-3v-4.081a.9.9 0 0 0-.9-.891.9.9 0 0 0-.9.891v4.972a.9.9 0 0 0 .9.891H40a.9.9 0 0 0 .9-.891.9.9 0 0 0-.9-.891zm-8.774-21.282H16.207v1.782h15.017zm0 4.455H16.207v1.782h15.017zm0 4.455H16.207v1.785h15.017zM16.207-66.793h9.611v-1.782h-9.611z"
            transform="translate(-10.051 92.929)"
            style={{ fill: '#6ba539' }}
          />
        </g>
      </g>
    </svg>
  );
};
