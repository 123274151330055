import React from 'react';

export const PendingPurchaseOrderRatesReviewIcon = () => {
  return (
    <svg
      xmlns="http://www.w3.org/2000/svg"
      width="35.673"
      height="36.199"
      viewBox="0 0 35.673 36.199"
    >
      <g data-name="Group 12032">
        <g data-name="Group 12030">
          <path
            data-name="Path 11235"
            d="M11.588-95A6.6 6.6 0 0 0 5-88.412a6.6 6.6 0 0 0 6.588 6.588 6.6 6.6 0 0 0 6.588-6.588A6.6 6.6 0 0 0 11.588-95zm0 .878a5.7 5.7 0 0 1 5.71 5.71 5.7 5.7 0 0 1-5.71 5.71 5.7 5.7 0 0 1-5.71-5.71 5.7 5.7 0 0 1 5.71-5.71zm-.3 1.6a.44.44 0 0 0-.311.133.443.443 0 0 0-.124.315v3.953a.439.439 0 0 0 .439.439h3.953a.439.439 0 0 0 .315-.126.437.437 0 0 0 .131-.313.437.437 0 0 0-.131-.313.439.439 0 0 0-.315-.126h-3.51v-3.514a.439.439 0 0 0-.127-.318.439.439 0 0 0-.317-.13z"
            transform="translate(17.246 95.901)"
            style={{ strokeWidth: '.5px', fill: '#6ba539', stroke: '#6ba539' }}
          />
        </g>
        <g data-name="Group 12031">
          <path
            data-name="Path 11236"
            d="M50.381-56.463c-1.089-5.267-3.546-13.564-8.466-15.928a6.978 6.978 0 0 0 3.245-6.036 2.885 2.885 0 0 0-.779-2.287c-.766-.621-1.783-.4-2.959-.151a11.18 11.18 0 0 1-2.331.325 11.051 11.051 0 0 1-2.331-.325c-1.179-.253-2.194-.472-2.959.151a2.885 2.885 0 0 0-.779 2.287 6.979 6.979 0 0 0 3.245 6.036c-4.924 2.363-7.38 10.661-8.467 15.928a11.826 11.826 0 0 1-2.326 5.012 3.76 3.76 0 0 0-1.067 3.177 2.642 2.642 0 0 0 1.228 1.756 4.2 4.2 0 0 0 3.314.088 3.511 3.511 0 0 1 2.382.165 20 20 0 0 0 7.758 1.148 20.046 20.046 0 0 0 7.758-1.148 3.485 3.485 0 0 1 2.382-.165 4.187 4.187 0 0 0 3.314-.088 2.642 2.642 0 0 0 1.228-1.756 3.758 3.758 0 0 0-1.067-3.177 11.838 11.838 0 0 1-2.326-5.012zM34.454-78.427c0-.895.191-1.121.249-1.168a.613.613 0 0 1 .368-.084 7.783 7.783 0 0 1 1.392.221 12.254 12.254 0 0 0 2.629.357 12.253 12.253 0 0 0 2.629-.357c.716-.154 1.527-.325 1.76-.138.058.047.249.271.249 1.168 0 2.969-2.082 5.385-4.639 5.385s-4.637-2.414-4.637-5.384zm17.93 29.81a1.246 1.246 0 0 1-.552.85 2.965 2.965 0 0 1-2.194-.045 4.957 4.957 0 0 0-3.348.219 18.737 18.737 0 0 1-7.2 1.035 18.744 18.744 0 0 1-7.2-1.035 5.116 5.116 0 0 0-2-.413 4.672 4.672 0 0 0-1.347.194 2.985 2.985 0 0 1-2.2.047 1.257 1.257 0 0 1-.552-.85 2.56 2.56 0 0 1 .79-1.93 13.263 13.263 0 0 0 2.619-5.623c.891-4.318 3.451-13.957 8.674-15.277l-2.54 5.3a.721.721 0 0 0 .335.96.731.731 0 0 0 .31.072.715.715 0 0 0 .644-.407l2.466-5.145 2.463 5.143a.715.715 0 0 0 .644.407.685.685 0 0 0 .31-.072.723.723 0 0 0 .335-.96l-2.54-5.3c5.223 1.32 7.783 10.959 8.677 15.277a13.288 13.288 0 0 0 2.614 5.624 2.563 2.563 0 0 1 .79 1.93z"
            transform="translate(-24.214 81.191)"
            style={{ strokeWidth: '.5px', fill: '#6ba539', stroke: '#6ba539' }}
          />
          <path
            data-name="Path 11237"
            d="M46.2-46.582h4.356a.745.745 0 0 0 .745-.745.745.745 0 0 0-.745-.745H48.07v-1.141a.745.745 0 0 0-.745-.745.745.745 0 0 0-.745.745v1.141h-.38a2.859 2.859 0 0 0-2.855 2.855 2.858 2.858 0 0 0 2.855 2.855h2.243A1.367 1.367 0 0 1 49.811-41a1.369 1.369 0 0 1-1.366 1.368h-4.353a.745.745 0 0 0-.745.745.745.745 0 0 0 .745.745h2.488V-37a.745.745 0 0 0 .745.745.745.745 0 0 0 .745-.745v-1.141h.378a2.859 2.859 0 0 0 2.852-2.853 2.858 2.858 0 0 0-2.855-2.855H46.2a1.368 1.368 0 0 1-1.366-1.368 1.362 1.362 0 0 1 1.366-1.365z"
            transform="translate(-32.447 67.461)"
            style={{ strokeWidth: '.5px', fill: '#6ba539', stroke: '#6ba539' }}
          />
        </g>
      </g>
    </svg>
  );
};
