import { AddIcon } from 'assets/images/AddIcon/AddIcon';
import { CaretDown } from 'assets/images/CaretIcon/CaretDown/CaretDown';
import { CaretUp } from 'assets/images/CaretIcon/CaretUp/CaretUp';
import { DeleteIcon } from 'assets/images/DeleteIcon/DeleteIcon';
import { AEPFilterComponent } from 'components/common/Filter/AEPFilterComponent';
import { AEPPagination } from 'components/common/Pagination/AEPPagination';
import { adminsid } from 'enums/fields.ts';
import { Page } from 'enums/page.ts';
import _ from 'lodash';
import { useEffect, useState } from 'react';
import { Col, Row, Tab, Table, Tabs } from 'react-bootstrap';
import { useDispatch, useSelector } from 'react-redux';
import Switch from 'react-switch';
import {
  addAdminSite,
  deleteAdminSite,
  fetchAdminSiteList,
  fetchSelectedAdminSiteList,
  setAdminSitePagination,
} from 'store/slices/AdminModules/adminSiteSlice';
import { setSitesFilters } from 'store/slices/filterSlice';
import { setActivePage } from 'store/slices/pageSlice';
import { isEmptyVal } from 'utils/utils';
import { AddNewSiteModal } from './AddNewSiteModal';
import './AdminSite.scss';
import { DeleteSiteModal } from './DeleteSiteModal';

const AdminSite = props => {
  const dispatch = useDispatch();
  const [addNewSiteModal, setAddNewSiteModal] = useState(false);
  const [deleteSiteModal, setDeleteSiteModal] = useState(false);
  const [siteID, setSiteID] = useState();
  const [rows, setRows] = useState([]);
  const adminSiteList = useSelector(state => state.adminSite.adminSiteList);
  const inputListData = useSelector(state => state.filter.sites);
  const currentPage = useSelector(
    state => state.adminSite.pagination.currentPage,
  );
  const selectedFilterRecordList = useSelector(
    state => state.adminSite.recordItems,
  );
  const pagination = useSelector(state => state.adminSite.pagination);
  const sortBy = useSelector(state => state.adminSite.pagination.sortBy);
  const sortOrder = useSelector(state => state.adminSite.pagination.sortOrder);
  const adminSiteFiltersValues = [
    { label: 'Site', value: 'siteId' },
    { label: 'Organization', value: 'orgId' },
    { label: 'Entitlement', value: 'entitlement' },
  ];
  const [chipFilterValues, setChipFilterValues] = useState({});
  const [filterChip, setFilterchip] = useState(false);
  const [continueBtnDisable, setContinueBtnDisable] = useState(true);

  useEffect(() => {
    dispatch(setActivePage(Page.ADMIN_MODULES));
    setRows(adminSiteList);
    if (props.resetFilter) {
      props.setResetFilter(false);
      handleContinueClick([
        {
          FilterValue: '',
          input_rank: null,
          selectFilterValue: '',
          selectFilterLabel: '',
          presentIcon: '',
          filerForm: false,
          FilterLabel: '',
          selectedFilterRecords: [],
        },
      ]);
    } else {
      handleContinueClick(inputListData);
    }
  }, [dispatch]);

  const handleAddSiteContinueClick = (siteValues, toggleValues) => {
    let orgValue = {};
    orgValue['orgId'] = 'AEP';
    dispatch(
      addAdminSite({
        data: { ...siteValues, ...toggleValues, ...orgValue },
      }),
    );
    dispatch(fetchAdminSiteList());
    setAddNewSiteModal(false);
  };

  const handleClick = () => {
    setAddNewSiteModal(true);
  };
  const [key, setKey] = useState('shared');
  const TabKey = {
    SHARED: 'shared',
  };

  const tabs = [{ key: TabKey.SHARED, value: 'Shared' }];

  const headers = [
    { key: adminsid.site, value: 'Site', sortkey: 'site_id' },
    { key: adminsid.description, value: 'Description', sortkey: 'description' },
    { key: adminsid.organization, value: 'Organization', sortkey: 'orgId' },
    { key: adminsid.entitlement, value: 'Entitlement', sortkey: 'entitlement' },
    { key: adminsid.regulated, value: 'Regulated', sortkey: 'regulated' },
    { key: adminsid.unregulated, value: 'Unregulated', sortkey: 'unregulated' },
    { key: adminsid.active, value: 'Active', sortkey: 'active' },
    { key: adminsid.authorized, value: 'Authorized', sortkey: 'authorized' },
  ];

  const handleSorting = (sortBy, sortOrder) => {
    dispatch(
      setAdminSitePagination({
        ...pagination,
        currentPage: 1,
        sortBy,
        sortOrder,
      }),
    );
  };
  const [checked, setChecked] = useState(false);

  const handleChange = nextChecked => {
    setChecked(nextChecked);
  };
  const handleDeleteSiteContinueClick = (editKey, rowDetail) => {
    dispatch(
      deleteAdminSite({
        siteId: siteID,
      }),
    );
    dispatch(fetchAdminSiteList());
    setDeleteSiteModal(false);
  };
  const handleDeleteClick = (editKey, rowDetail) => {
    setSiteID(rowDetail.siteId);
    setDeleteSiteModal(true);
  };

  const handleTabSelect = key => {
    switch (key) {
      case TabKey.SHARED: {
        dispatch(
          setAdminSitePagination({
            forceFetch: true,
            currentPage: 1,
            caastatus: '',
          }),
        );
        break;
      }
    }
    setKey(key);
  };

  const objDecorate = obj => {
    let newObj = _.mapKeys(obj, (value, key) => {
      return key;
    });
    return newObj;
  };

  const handleContinueClick = (data = null) => {
    let inputData = _.cloneDeep(inputListData);
    if (!isEmptyVal(data)) {
      inputData = _.cloneDeep(data);
    }

    let selectedFilterData = {};
    let selectedFilterValue = {};
    let uniqueFilterValues = [];

    inputData.map((el, id) => {
      if (uniqueFilterValues.indexOf(el.FilterValue) === -1) {
        uniqueFilterValues.push(el.FilterValue);
      }
    });

    uniqueFilterValues.map(value => {
      let temp1 = [];
      inputData.map((val, ids) => {
        if (val.FilterValue == value) {
          temp1.push(val.selectFilterValue);
        }
      });
      selectedFilterData[value] = temp1;
    });

    inputData.map((el, id) => {
      Object.keys(selectedFilterData).map(function (item) {
        if (item == el.FilterValue) {
          selectedFilterValue[el.FilterLabel] = selectedFilterData[item];
        }
      });
    });
    const selectedFilterDataDecorated = objDecorate(selectedFilterData);
    if (props.resetFilter) {
      dispatch(
        fetchAdminSiteList({
          currentPage: 1,
          siteId: '',
          orgId: '',
          entitlement: '',
        }),
      );
    } else {
      dispatch(fetchAdminSiteList(selectedFilterDataDecorated));
    }
    let vendorSelectFilterLabel = [];
    if (selectedFilterValue.Vendor != undefined) {
      selectedFilterValue.Vendor.map((e, id) => {
        inputData.map((val, ind) => {
          if (val.selectFilterValue == e) {
            vendorSelectFilterLabel.push(val.selectFilterLabel);
          }
        });
        selectedFilterValue.Vendor = vendorSelectFilterLabel;
        return selectedFilterValue.Vendor;
      });
    }

    setChipFilterValues(selectedFilterValue);
    setFilterchip(true);
  };

  const handleFilterclose = type => {
    if (type == 'filterChange') {
      setContinueBtnDisable(false);
    } else if (type == 'deleteEmpty') {
      setContinueBtnDisable(true);
    }
  };

  const handleFilterChip = (chipname, chipValue) => {
    let filterchipData = {};

    Object.keys(chipFilterValues).map(function (item, i) {
      let chipList = [...chipFilterValues[item]];
      chipList.map((val, ids) => {
        if (val == chipValue) {
          chipList.splice(ids, 1);
        }
      });
      chipFilterValues[item] = chipList;
    });

    inputListData.map((el, id) => {
      Object.keys(chipFilterValues).map(function (item) {
        if (item == el.FilterLabel) {
          filterchipData[el.FilterValue] = chipFilterValues[item];
        }
      });
    });

    let indextodelete;
    let newInputList1 = _.cloneDeep(inputListData);
    newInputList1.map((val, ids) => {
      if (val.FilterValue === 'vendor' || val.FilterValue === 'vendorid') {
        if (val.FilterLabel == chipname && val.selectFilterLabel == chipValue) {
          indextodelete = ids;
        }
      } else {
        if (val.FilterLabel == chipname && val.selectFilterValue == chipValue) {
          indextodelete = ids;
        }
      }
    });
    newInputList1.splice(indextodelete, 1);

    if (newInputList1.length == 0) {
      setContinueBtnDisable(true);
    }
    dispatch(setSitesFilters(newInputList1));
    setChipFilterValues(chipFilterValues);

    let vendorSelectFilterValue = [];
    if (filterchipData.vendor != undefined) {
      filterchipData.vendor.map((e, id) => {
        inputListData.map((val, ind) => {
          if (val.selectFilterLabel == e) {
            vendorSelectFilterValue.push(val.selectFilterValue);
          }
        });
        filterchipData.vendor = vendorSelectFilterValue;
        return filterchipData.vendor;
      });
    }

    // Check is using "vendorid"
    const filterchipDataDecorated = objDecorate(filterchipData);
    dispatch(fetchAdminSiteList(filterchipDataDecorated));
  };

  return (
    <>
      <div className="aepContainer adminModuledistrictWorkAreaContainer">
        <Tabs id="poPage" activeKey={key} onSelect={k => handleTabSelect(k)}>
          {tabs.map((tab, index) => (
            <Tab eventKey={tab.key} title={tab.value} key={index}>
              <div className="tableContainer">
                <div className="aepBreadcrumb actionPane">
                  {/* <div style={{ float: 'left' }}>
                  <span className="actionbtn">
                    <AddIcon />
                  </span>
                  <span className="actionbtn">
                    <DeleteIcon />
                  </span>
                </div> */}
                  <Row>
                    <Col sm={10}>
                      <>
                        <div className="filterComponent">
                          <AEPFilterComponent
                            inputListData={inputListData}
                            filterValues={adminSiteFiltersValues}
                            setInputFilterData={setSitesFilters}
                            selectedFilterRecordList={selectedFilterRecordList}
                            fetchSelectedList={fetchSelectedAdminSiteList}
                            handleContinueClick={handleContinueClick}
                            continueBtnDisable={continueBtnDisable}
                            handleFilterclose={handleFilterclose}
                            handleFilterChip={handleFilterChip}
                            filterChip={filterChip}
                            chipFilterValues={chipFilterValues}
                          />
                        </div>
                      </>
                    </Col>
                    <Col sm={2}>
                      <div style={{ float: 'right' }}>
                        <span onClick={e => handleClick()} title="Add Row">
                          <AddIcon />
                        </span>
                      </div>
                    </Col>
                  </Row>
                </div>
                <Table
                  hover
                  responsive
                  striped
                  bordered={false}
                  className="adminSiteTbl"
                >
                  <thead>
                    <tr>
                      {headers.map((header, idx) => (
                        <th key={idx}>
                          {sortOrder === 'asc' && (
                            <div
                              id={header.sortkey}
                              onClick={e =>
                                handleSorting(header.sortkey, 'desc')
                              }
                            >
                              {header.value}
                              <CaretUp style={{ marginLeft: '12px' }} />
                            </div>
                          )}
                          {sortOrder === 'desc' && (
                            <div
                              id={header.sortkey}
                              onClick={e =>
                                handleSorting(header.sortkey, 'asc')
                              }
                            >
                              {header.value}
                              <CaretDown style={{ marginLeft: '12px' }} />
                            </div>
                          )}
                        </th>
                      ))}
                    </tr>
                  </thead>
                  <tbody>
                    {adminSiteList.map((row, indx) => (
                      <tr key={indx} className="tRow table-hover-row">
                        <td>{row.siteId}</td>
                        <td>{row.description}</td>
                        <td>{row.orgId}</td>
                        <td>{row.entitlement}</td>
                        <td>
                          <Switch
                            onChange={handleChange}
                            checked={
                              row.regulated == null ? false : row.regulated
                            }
                            className="react-switch"
                            checkedIcon={false}
                            uncheckedIcon={false}
                            onColor="#025a95"
                            onHandleColor="#fff"
                            boxShadow="0px 1px 5px rgba(0, 0, 0, 0.6)"
                            disabled={true}
                          />
                        </td>
                        <td>
                          <Switch
                            onChange={handleChange}
                            checked={
                              row.unregulated == null ? false : row.unregulated
                            }
                            className="react-switch"
                            checkedIcon={false}
                            uncheckedIcon={false}
                            onColor="#025a95"
                            onHandleColor="#fff"
                            boxShadow="0px 1px 5px rgba(0, 0, 0, 0.6)"
                            disabled={true}
                          />
                        </td>
                        <td>
                          <Switch
                            onChange={handleChange}
                            checked={row.active == null ? false : row.active}
                            className="react-switch"
                            checkedIcon={false}
                            uncheckedIcon={false}
                            onColor="#025a95"
                            onHandleColor="#fff"
                            boxShadow="0px 1px 5px rgba(0, 0, 0, 0.6)"
                            disabled={true}
                          />
                        </td>
                        <td>
                          <Switch
                            onChange={handleChange}
                            checked={
                              row.authorized == null ? false : row.authorized
                            }
                            className="react-switch"
                            checkedIcon={false}
                            uncheckedIcon={false}
                            onColor="#025a95"
                            onHandleColor="#fff"
                            boxShadow="0px 1px 5px rgba(0, 0, 0, 0.6)"
                            disabled={true}
                          />
                        </td>
                        <td className="addNewbtn">
                          <div
                            className="editIcon"
                            onClick={e => handleDeleteClick(indx, row)}
                            title="Delete Row"
                          >
                            <DeleteIcon />
                          </div>
                        </td>
                      </tr>
                    ))}
                  </tbody>
                </Table>
                <AEPPagination
                  fetchItems={fetchAdminSiteList}
                  setPagination={setAdminSitePagination}
                  pagination={pagination}
                  currentPage={currentPage}
                  sortBy={sortBy}
                  sortOrder={sortOrder}
                />
              </div>
            </Tab>
          ))}
        </Tabs>
        <AddNewSiteModal
          show={addNewSiteModal}
          setShowModal={setAddNewSiteModal}
          handleAddSiteContinueClick={handleAddSiteContinueClick}
        />
        <DeleteSiteModal
          show={deleteSiteModal}
          setShowModal={setDeleteSiteModal}
          handleDeleteSiteContinueClick={handleDeleteSiteContinueClick}
        />
      </div>
    </>
  );
};
export { AdminSite };
