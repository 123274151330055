import excelJs from 'exceljs';
import moment from 'moment';

export const exportBidrateSheet = async data => {
  const workbook = new excelJs.Workbook();

  const worksheet = workbook.addWorksheet(data.worksheet, {
    pageSetup: {
      showGridLines: true,
      fitToPage: true,
      fitToWidth: 1,
      fitToHeight: 0,
      orientation: 'landscape',
      paperSize: 5, //legal size
    },
  });

  //Adding bidrate number
  worksheet.getCell('A2').value = 'Bid Rate Sheet Record No.:';
  worksheet.getCell('A2').font = { bold: true };
  worksheet.getCell('B2').value = data.bidrateNmbr || 'new';

  //Adding contractor name
  worksheet.getCell('A3').value = 'Contractor Name:';
  worksheet.getCell('A3').font = { bold: true };
  worksheet.getCell('B3').value = data.contractorname;

  ////Adding bidrate Name:
  worksheet.getCell('A4').value = 'Name:';
  worksheet.getCell('A4').font = { bold: true };
  worksheet.getCell('B4').value = data.formName;

  //Adding Type:
  worksheet.getCell('A5').value = 'Type:';
  worksheet.getCell('A5').font = { bold: true };
  worksheet.getCell('B5').value = data.type;
  //Title
  worksheet.getCell('A8').value = data.title;
  worksheet.getCell('A8').font = { bold: true };
  generateExcelRecords(worksheet, data.headersValues, data.records);

  const buffer = await workbook.xlsx.writeBuffer();
  const blob = new Blob([buffer], {
    type: 'application/vnd.openxmlformats-officedocument.spreadsheetml.sheet',
  });
  const link = document.createElement('a');
  link.href = window.URL.createObjectURL(blob);
  const timeData = new Date();
  link.download = `${data.bidratename} ${data.title}.xlsx`;
  link.click();

  //   return generateExcelRecords(worksheet, data.headersValues, data.records);
};

const generateExcelRecords = (worksheet, headersValues, recordsoriginal) => {
  if (!recordsoriginal) {
    worksheet.getRow(9).values = ['No records found'];
    return worksheet;
  }
  const records = JSON.parse(JSON.stringify(recordsoriginal));
  const headers = [];
  const columns = [];
  for (const header of headersValues) {
    headers.push(header.value);
    columns.push({ key: header.key, width: 20 });
  }
  //Add Headers
  worksheet.getRow(9).values = headers;

  worksheet.getRow(9).font = {
    family: 2,
    bold: true,
    width: 20,
  };
  worksheet.getRow(9).alignment = {
    wrapText: true,
  };
  //Print Changes - Repeat row 9 on every page (header)
  worksheet.pageSetup.printTitlesRow = '2:9';
  worksheet.columns = columns;

  //load data
  for (const record of records) {
    // Checking dates
    for (const dataRecord in record) {
      if (record[dataRecord] !== null) {
        if (
          !isNaN(record[dataRecord]) &&
          !dataRecord.toLowerCase().includes('code')
        ) {
          record[dataRecord] = Number(record[dataRecord]);
        } else if (
          dataRecord.toLowerCase().includes('date') &&
          record[dataRecord] &&
          !isNaN(Date.parse(record[dataRecord]))
        ) {
          const isDate = moment(record[dataRecord]);
          if (isDate.isValid()) {
            record[dataRecord] = new Date(
              new Date(record[dataRecord]).toISOString(),
            );
          }
        }
      }
    }
    worksheet.addRow(record);
  }
  return worksheet;
};
