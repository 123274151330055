import React from 'react';

export const DownloadIcon = () => {
  return (
    <svg
      data-name="Component 2844 – 8"
      xmlns="http://www.w3.org/2000/svg"
      width="32"
      height="32"
      viewBox="0 0 32 32"
    >
      <rect
        data-name="🔲🎨 Container l Color"
        width="32"
        height="32"
        rx="4"
        style={{ fill: '#025a95' }}
      />
      <g data-name="Group 161505">
        <path
          data-name="Rectangle 8567"
          style={{ fill: 'none' }}
          d="M0 0h24v24H0z"
          transform="translate(4 4)"
        />
      </g>
      <g data-name="Group 161507">
        <g data-name="Group 161506">
          <path
            data-name="Path 28508"
            d="M14 2H6a2 2 0 0 0-1.99 2L4 20a2 2 0 0 0 1.99 2H18a2.006 2.006 0 0 0 2-2V8zm4 18H6V4h7v5h5z"
            style={{ fill: '#fff' }}
            transform="translate(4 4)"
          />
          <path
            d="m6.839 4.26-4.622.023 1.871 1.871a.648.648 0 0 1-.916.916L.19 4.088a.647.647 0 0 1 0-.916L3.172.19a.648.648 0 1 1 .916.916L2.217 2.983l4.622-.023a.65.65 0 0 1 0 1.3z"
            transform="rotate(-90 17.699 5.329)"
            style={{ fill: '#fff' }}
          />
        </g>
      </g>
    </svg>
  );
};
