import {
  PurchaseOrderStatus,
  TimesheetStatus,
  InvoiceStatus,
} from 'enums/statuses.ts';

export const POStatusDisplayValue = {
  [PurchaseOrderStatus.APPROVED]: 'Approved',
  [PurchaseOrderStatus.APPROVED_PENDING_RATES_REVIEW]:
    'Approved - Pending Rates Review',
  [PurchaseOrderStatus.APPROVED_PENDING_RATES_REVIEW_SUBMITTED]:
    'Approved - Rates Rejected',

  [PurchaseOrderStatus.PENDING_INITIAL_REVIEW]: 'Pending Initial Review',
  [PurchaseOrderStatus.PENDING_INITIAL_REVIEW_SUBMITTED]:
    'Pending Initial Review Accepted',
  [PurchaseOrderStatus.PENDING_INITIAL_REVIEW_DECLINED]:
    'Pending Initial Review Declined',
  [PurchaseOrderStatus.REQUEST_VENDOR_COMPLETION_FORM]:
    'Completion Form Requested',
  [PurchaseOrderStatus.SUBMIT_VENDOR_COMPLETION_FORM]:
    'Completion Form Submitted',
  [PurchaseOrderStatus.APPROVE_VENDOR_COMPLETION_FORM]:
    'Completion Form Approved',
  [PurchaseOrderStatus.APPROVED_NO_RATES]: 'Approved - No Rates',
  [PurchaseOrderStatus.CANCELLED_PENDING_ORDER]: 'Cancelled',
  [PurchaseOrderStatus.CLOSED_PENDING_ORDER]: 'Closed',
};

export const TimesheetStatusDisplayValue = {
  [TimesheetStatus.TIMESHEET_ENTERED_NOT_SAVED]: 'Timesheet Entered Not Saved',
  [TimesheetStatus.TIMESHEET_SAVED_NOT_SUBMITTED]:
    'Timesheet Saved Not Submitted',
  [TimesheetStatus.TIMESHEET_SUBMITTED_NOT_APPROVED]:
    'Timesheet Submitted Not Approved',
  [TimesheetStatus.TIMESHEET_SUBMITTED_NOT_REVIEWED]:
    'Timesheet Submitted Not Reviewed',
  [TimesheetStatus.TIMESHEET_APPROVED]: 'Timesheet Approved',
  [TimesheetStatus.TIMESHEET_RETURNED_NOT_APPROVED]:
    'Timesheet Returned Not Approved',
  [TimesheetStatus.TIMESHEET_RETURNED_NOT_SUBMITTED]:
    'Timesheet Returned Not Submitted',
  [TimesheetStatus.TIMESHEET_INVOICED]: 'Timesheet Invoiced',
  [TimesheetStatus.TIMESHEET_CANCELLED]: 'Timesheet Cancelled',
  [TimesheetStatus.UVL_TIMESHEET_SAVED_NOT_SUBMITTED]:
    'UVL Timesheet Saved Not Submitted',
  [TimesheetStatus.UVL_TIMESHEET_SUBMITTED]: 'UVL Timesheet Submitted',
  [TimesheetStatus.UVL_TIMESHEET_CANCELLED]: 'UVL Timesheet Cancelled',
};

export const TimesheetDisplayValue = {
  [TimesheetStatus.TIMESHEET_ENTERED_NOT_SAVED]: 'Entered Not Saved',
  [TimesheetStatus.TIMESHEET_SAVED_NOT_SUBMITTED]: 'Saved',
  [TimesheetStatus.TIMESHEET_SUBMITTED_NOT_APPROVED]: 'Submitted',
  [TimesheetStatus.TIMESHEET_SUBMITTED_NOT_REVIEWED]: 'Submitted',
  [TimesheetStatus.TIMESHEET_APPROVED]: 'Approved',
  [TimesheetStatus.TIMESHEET_RETURNED_NOT_APPROVED]: 'Returned',
  [TimesheetStatus.TIMESHEET_RETURNED_NOT_SUBMITTED]: 'Returned',
  [TimesheetStatus.TIMESHEET_CANCELLED]: 'Cancelled',
  [TimesheetStatus.UVL_TIMESHEET_SAVED_NOT_SUBMITTED]: 'Saved',
  [TimesheetStatus.TIMESHEET_INVOICED]: 'Invoiced',
  [TimesheetStatus.UVL_TIMESHEET_SUBMITTED]: 'Submitted',
  [TimesheetStatus.UVL_TIMESHEET_CANCELLED]: 'Cancelled',
  [TimesheetStatus.TIMESHEET_ENTERED_NOT_SAVED +
  TimesheetStatus.TIMESHEET_SAVED_NOT_SUBMITTED +
  TimesheetStatus.UVL_TIMESHEET_SAVED_NOT_SUBMITTED]: 'Saved',
  [TimesheetStatus.TIMESHEET_SUBMITTED_NOT_APPROVED +
  TimesheetStatus.TIMESHEET_SUBMITTED_NOT_REVIEWED +
  TimesheetStatus.UVL_TIMESHEET_SUBMITTED]: 'Submitted',
  [TimesheetStatus.TIMESHEET_RETURNED_NOT_APPROVED +
  TimesheetStatus.TIMESHEET_RETURNED_NOT_SUBMITTED]: 'Returned',
  [TimesheetStatus.TIMESHEET_CANCELLED +
  TimesheetStatus.UVL_TIMESHEET_CANCELLED]: 'Cancelled',
};

export const InvoiceStatusDisplayValue = {
  [InvoiceStatus.C_INVOICE_ENTERED_NOT_SAVED]: 'C-Invoice Entered Not Saved',
  [InvoiceStatus.C_INVOICE_SAVED_NOT_SUBMITTED]:
    'C-Invoice Saved Not Submitted',
  [InvoiceStatus.C_INVOICE_SAVED_NOT_SUBMITTED_RETURNED]:
    'C-Invoice Saved Not Submitted - Returned',
  [InvoiceStatus.C_INVOICE_SUBMITTED_NOT_REVIEWED]:
    'C-Invoice Submitted Not Reviewed',
  [InvoiceStatus.C_PENDING]: 'C-Pending',
  [InvoiceStatus.M_INVOICE_ENTERED]: 'M-Invoice Entered',
  [InvoiceStatus.M_MISMATCH]: 'M-Mismatch',
  [InvoiceStatus.M_APPROVED_NOT_PAID]: 'M-Approved Not Paid',
  [InvoiceStatus.M_PAID]: 'M-Paid',
  [InvoiceStatus.M_PAID_AP]: 'M-PaidAP',
  [InvoiceStatus.C_RETURNED_TO_TIMESHEET]: 'C-Returned to Timesheet',
  [InvoiceStatus.C_INVOICE_SUBMITTED_NOT_REVIEWED_RETURNED]:
    'C-Invoice Submitted Not Reviewed-Returned',
  [InvoiceStatus.C_M_REVERSED_INVOICE_RETURNED_FROM_PEOPLESOFT]:
    'C-MREVERSED-Invoice Returned from PeopleSoft',
  [InvoiceStatus.M_FINAPPR]: 'M-FINAPPR',
  [InvoiceStatus.M_FINERROR]: 'M-FINERROR',
  [InvoiceStatus.M_WAPPR]: 'M-WAPPR',
  [InvoiceStatus.C_INVOICE_REVIEWED_FAILED_TO_CREATE]:
    'C-Invoice Reviewed-Failed To Create',
  [InvoiceStatus.C_INVOICE_REVIEWED_FAILED_TO_CREATE_PO_ISSUE]:
    'C-Invoice Reviewed-Failed To Create-PO Issue',
  [InvoiceStatus.C_INVOICE_REVIEWED_FAILED_TO_CREATE_CAA_ISSUE]:
    'C-Invoice Reviewed-Failed To Create-CAA Issue',
  [InvoiceStatus.C_INVOICE_REVIEWED_FAILED_TO_CREATE_DUPLICATE]:
    'C-Invoice Reviewed-Failed To Create-Duplicate',
  [InvoiceStatus.C_INVOICE_REVIEWED_FAILED_TO_CREATE_WOISSUE]:
    'C-Invoice Reviewed-Failed To Create-WO Issue',
};

export const InvoiceDisplayValue = {
  [InvoiceStatus.C_INVOICE_ENTERED_NOT_SAVED]: 'C-Invoice Entered Not Saved',
  [InvoiceStatus.C_INVOICE_SAVED_NOT_SUBMITTED]: 'Saved',
  [InvoiceStatus.C_INVOICE_SUBMITTED_NOT_REVIEWED]: 'Submitted',
  [InvoiceStatus.C_PENDING]: 'Pending',
  [InvoiceStatus.M_INVOICE_ENTERED]: 'M-Invoice Entered',
  [InvoiceStatus.M_MISMATCH]: 'M-Mismatch',
  [InvoiceStatus.M_APPROVED_NOT_PAID]: 'M-Approved Not Paid',
  [InvoiceStatus.M_PAID]: 'Invoiced',
  [InvoiceStatus.M_PAID_AP]: 'M-Paid',
  [InvoiceStatus.C_RETURNED_TO_TIMESHEET]: 'Returned',
  [InvoiceStatus.C_INVOICE_SAVED_NOT_SUBMITTED_RETURNED]: 'Returned',
  [InvoiceStatus.C_INVOICE_SUBMITTED_NOT_REVIEWED_RETURNED]: 'Returned',
  [InvoiceStatus.C_M_REVERSED_INVOICE_RETURNED_FROM_PEOPLESOFT]: 'Returned',
  [InvoiceStatus.M_FINAPPR]: 'M-FINAPPR',
  [InvoiceStatus.M_FINERROR]: 'M-FINERROR',
  [InvoiceStatus.M_WAPPR]: 'M-WAPPR',
  [InvoiceStatus.C_INVOICE_REVIEWED_FAILED_TO_CREATE]: 'Failed',
  [InvoiceStatus.C_INVOICE_REVIEWED_FAILED_TO_CREATE_PO_ISSUE]: 'Failed',
  [InvoiceStatus.C_INVOICE_REVIEWED_FAILED_TO_CREATE_CAA_ISSUE]: 'Failed',
  [InvoiceStatus.C_INVOICE_REVIEWED_FAILED_TO_CREATE_DUPLICATE]: 'Failed',
  [InvoiceStatus.C_INVOICE_REVIEWED_FAILED_TO_CREATE_WOISSUE]: 'Failed',
};
