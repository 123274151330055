// Imports
import ___CSS_LOADER_API_SOURCEMAP_IMPORT___ from "../../../../node_modules/react-scripts/node_modules/css-loader/dist/runtime/sourceMaps.js";
import ___CSS_LOADER_API_IMPORT___ from "../../../../node_modules/react-scripts/node_modules/css-loader/dist/runtime/api.js";
var ___CSS_LOADER_EXPORT___ = ___CSS_LOADER_API_IMPORT___(___CSS_LOADER_API_SOURCEMAP_IMPORT___);
// Module
___CSS_LOADER_EXPORT___.push([module.id, `.Form-Dropdown-standard {
  width: 290px;
  height: 80px;
  margin: 0 0 0 20px;
}

.firstRow {
  margin-bottom: 24px !important;
}

.submitDiv {
  display: flex;
  align-items: right;
  justify-content: right;
  margin: 40px 40px 32px 0;
}
.submitDiv .btn {
  width: 128px;
  height: 40px;
}

.downloadBtn {
  cursor: pointer;
  display: flex;
  justify-content: right;
  align-items: right;
  margin-bottom: 20px;
}

.checkDiv input {
  width: 15px;
  height: 15px;
}

#pagination_download {
  display: flex;
  justify-content: right;
}`, "",{"version":3,"sources":["webpack://./src/pages/AdditionalLinks/CuDownload/CuDownload.scss"],"names":[],"mappings":"AAAA;EACE,YAAA;EACA,YAAA;EACA,kBAAA;AACF;;AAEA;EACE,8BAAA;AACF;;AAEA;EACE,aAAA;EACA,kBAAA;EACA,sBAAA;EACA,wBAAA;AACF;AACE;EACE,YAAA;EACA,YAAA;AACJ;;AAGA;EACE,eAAA;EACA,aAAA;EACA,sBAAA;EACA,kBAAA;EACA,mBAAA;AAAF;;AAIE;EACE,WAAA;EACA,YAAA;AADJ;;AAKA;EACE,aAAA;EACA,sBAAA;AAFF","sourcesContent":[".Form-Dropdown-standard {\n  width: 290px;\n  height: 80px;\n  margin: 0 0 0 20px;\n}\n\n.firstRow {\n  margin-bottom: 24px !important;\n}\n\n.submitDiv {\n  display: flex;\n  align-items: right;\n  justify-content: right;\n  margin: 40px 40px 32px 0;\n\n  .btn {\n    width: 128px;\n    height: 40px;\n  }\n}\n\n.downloadBtn {\n  cursor: pointer;\n  display: flex;\n  justify-content: right;\n  align-items: right;\n  margin-bottom: 20px;\n}\n\n.checkDiv {\n  input {\n    width: 15px;\n    height: 15px;\n  }\n}\n\n#pagination_download {\n  display: flex;\n  justify-content: right;\n}\n"],"sourceRoot":""}]);
// Exports
___CSS_LOADER_EXPORT___.locals = {};
export default ___CSS_LOADER_EXPORT___;
