import { createSlice } from '@reduxjs/toolkit';

const initialState = {
  isLoading: false,
  error: false,
  errorResponse: {},
  pagination: {
    totalItems: 0,
    currentPage: 1,
    itemsPerPage: 10,
    sortBy: '',
    sortOrder: 'asc',
  },
  recordItems: [],
  inputList: [
    {
      FilterValue: '',
      input_rank: null,
      selectFilterValue: '',
      selectFilterLabel: '',
      presentIcon: '',
      filerForm: false,
      FilterLabel: '',
      selectedFilterRecords: [],
    },
  ],
  fuelRatesList: [],
  fuelTypeList: [],
  fuelRatesData: {},
};

const fuelRatesSlice = createSlice({
  name: 'fuelRatesSlice',
  initialState,
  reducers: {
    fetchfuelRatesList(state, action) {
      const stateValues = { ...state };
      stateValues.isLoading = false;
      stateValues.pagination = {
        ...stateValues.pagination,
        ...action.payload,
      };
      return { ...stateValues };
    },
    updatefuelRates(state, action) {
      state.isLoading = false;
    },
    getFuelTypes(state, action) {
      state.isLoading = false;
    },
    setInputFilterData(state, action) {
      if (action.payload.length != 0) {
        state.inputList = action.payload;
      } else {
        const stateValues = { ...state };
        stateValues.inputList = [...initialState.inputList];
        return { ...stateValues };
      }
    },
    setAddNewInputFilterData(state, action) {
      const stateValues = { ...state };
      stateValues.inputList = [...stateValues.inputList, action.payload];
      return { ...stateValues };
    },
    fetchSelectedAdminFuelRateList(state) {
      state.isLoading = false;
    },
    setSelectedAdminFuelRecords(state, action) {
      const selectedAdminFuelRecords = [];
      action.payload?.forEach(obj => {
        let objValues = Object.values(obj);
        selectedAdminFuelRecords.push({
          label: objValues[0],
          value: objValues[0],
        });
      });
      const stateValues = { ...state };
      stateValues.recordItems = [...selectedAdminFuelRecords];
      return { ...stateValues };
    },
    setFuelTypes(state, action) {
      const fuelTypes = action.payload?.fuelTypes;
      const stateValues = { ...state };
      stateValues.fuelTypeList = [...fuelTypes];
      return { ...stateValues };
    },
    setfuelRatesData(state, action) {
      const fuelRatesList = [];
      action.payload?.rows.forEach(obj => {
        fuelRatesList.push({
          monthyearopcp: obj.weekEndDate,
          opco: obj.opCo,
          fuelType: obj.fuelType,
          state: obj.state,
          fuelRate: obj.fuelRate,
          enteredBy: obj.enteredBy,
          enteredDate: obj.enteredDate,
          updatedBy: obj.updatedBy,
          updatedDate: obj.updatedDate,
          fuelRateNum: obj.fuelRateNum,
        });
      });
      const stateValues = { ...state };
      stateValues.fuelRatesList = [...fuelRatesList];
      stateValues.fuelRatesData = {};
      stateValues.isUpdate = false;
      stateValues.pagination = {
        ...stateValues.pagination,
        ...action.payload?.pagination,
      };
      return { ...stateValues };
    },
    setfuelRatesPagination(state, action) {
      const stateValues = { ...state };
      stateValues.pagination = {
        ...stateValues.pagination,
        ...action.payload,
      };
      return { ...stateValues };
    },

    unsetError(state) {
      const stateValues = { ...state };
      stateValues.error = false;
      stateValues.errorResponse = {};
      return { ...stateValues };
    },

    setError(state, action) {
      const stateValues = { ...state };
      stateValues.error = true;
      stateValues.errorResponse = action.payload;
      return { ...stateValues };
    },
  },
});

export const {
  fetchfuelRatesList,
  updatefuelRates,
  setInputFilterData,
  setAddNewInputFilterData,
  fetchSelectedAdminFuelRateList,
  setSelectedAdminFuelRecords,
  getFuelTypes,
  setFuelTypes,
  setfuelRatesData,
  setfuelRatesPagination,
  setError,
  unsetError,
} = fuelRatesSlice.actions;

export default fuelRatesSlice.reducer;
