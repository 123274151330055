import { Fragment } from 'react';
import { CloseIcon } from 'assets/images/CloseIcon/CloseIcon';
import { ErrorIcon } from 'assets/images/ErrorIcon/ErrorIcon';
import { Divider } from 'components/common/Divider/Divider';
import { Button, Modal, Row, Col } from 'react-bootstrap';
import _ from 'lodash';

const TimesheetErrorModal = props => {
  const {
    onShow,
    onHide,
    handleTimesheetStatus,
    approveTimesheetError,
    approveCircuitMileageError,
  } = props;

  return (
    <Modal
      show={onShow}
      onHide={onHide}
      size="lg"
      dialogClassName="timesheetErrorDialog"
      aria-labelledby="contained-modal-title-vcenter"
      centered
    >
      <Modal.Header closeButton={false}>
        <div
          style={{
            display: 'flex',
            justifyContent: 'flex-start',
            width: '100%',
            height: '26px',
          }}
        >
          <div className="errorIcon">
            <ErrorIcon styles={{ marginTop: '-4px' }} />
          </div>
          {approveTimesheetError
            ? 'Timesheet accounting validation failed!'
            : 'Can not approve Timesheet!'}
          <div
            style={{ marginLeft: 'auto', cursor: 'pointer' }}
            onClick={onHide}
          >
            <CloseIcon />
          </div>
        </div>
      </Modal.Header>
      <Divider />
      <Modal.Body className="psValidationModalBody">
        <div style={{ fontSize: '20px', marginBottom: '24px' }}>
          {approveTimesheetError ? (
            <ul>
              {approveTimesheetError['fint:errorDetail'] &&
              _.isArray(approveTimesheetError['fint:errorDetail']) ? (
                approveTimesheetError['fint:errorDetail'].map(
                  (error, index) => [
                    <div style={{ padding: '5px' }}>
                      <Row>
                        <Col sm={3}>Work Order No:</Col>
                        <Col sm={9}>{approveTimesheetError.workorder}</Col>
                      </Row>
                      <Row>
                        <Col sm={3}>PS Message Set number:</Col>
                        <Col sm={9}>{error['val:peopleSoftMessageSet']}</Col>
                      </Row>
                      <Row>
                        <Col sm={3}>Message Number:</Col>
                        <Col sm={9}>{error['val:peopleSoftMessageNumber']}</Col>
                      </Row>
                      <Row>
                        <Col sm={3}>Error Message Description:</Col>
                        <Col sm={9}>{error['val:errorDescription']}</Col>
                      </Row>
                    </div>,
                    <Divider />,
                  ],
                )
              ) : (
                <li>'Please update the accounting string.'</li>
              )}
            </ul>
          ) : (
            <ul>
              {_.isArray(approveCircuitMileageError) &&
                approveCircuitMileageError?.map(error => {
                  return <li>{error}</li>;
                })}
            </ul>
          )}
        </div>
        <div className="btnArea">
          <Fragment>
            {approveTimesheetError && (
              <Button
                variant="primary"
                onClick={() => handleTimesheetStatus('handleAccept')}
              >
                Try Again
              </Button>
            )}
            <Button variant="secondary" onClick={onHide}>
              Okay
            </Button>
          </Fragment>
        </div>
      </Modal.Body>
    </Modal>
  );
};

export { TimesheetErrorModal };
