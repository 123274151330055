import React from 'react';

export const BackArrowIcon = ({ styles = {} }) => {
  return (
    <svg width="14" height="12" viewBox="0 0 14 12" style={{ ...styles }}>
      <path
        d="M17 10.2H6.721l3.146-3.779a.868.868 0 1 0-1.334-1.109l-4.333 5.2a1.031 1.031 0 0 0-.078.13.11.11 0 0 1-.061.113.833.833 0 0 0 0 .624.11.11 0 0 0 .061.113 1.031 1.031 0 0 0 .078.13l4.333 5.2a.868.868 0 1 0 1.335-1.109l-3.147-3.78H17a.867.867 0 1 0 0-1.733z"
        transform="translate(-4 -4.999)"
        style={{ fill: '#fff' }}
      />
    </svg>
  );
};
