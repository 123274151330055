import LoginAEPLogo from 'assets/images/LoginAEP_Logo.png';
import LoginImage from 'assets/images/UpdatedLandingImage.png';
import { ROUTES } from 'constants/routes';
import { useEffect } from 'react';
import { Button, Form, Stack } from 'react-bootstrap';
import { useSelector } from 'react-redux';
import { useNavigate } from 'react-router-dom';
import LoginImageMobile from '../../assets/images/LoginImageMobile.svg';
import { useMediaQuery } from 'react-responsive';
import './Login.scss';

const Login = () => {
  const navigate = useNavigate();
  const isAuthenticated = useSelector(state => state.auth.isLoggedIn);
  const isMobile = useMediaQuery({ query: '(max-width: 575px)' });

  const handleSubmit = async event => {
    event.preventDefault();
    const encodedUri = encodeURIComponent(
      `${process.env.REACT_APP_REDIRECT_URL}`,
    );
    window.location.href = `${process.env.REACT_APP_API_BASE_URL}/api/oauth/login?redirectUrl=${encodedUri}`;
  };

  useEffect(() => {
    if (isAuthenticated) {
      navigate(`${ROUTES.home}`);
    }
  });

  return (
    <>
      <div className="landing-container">
        <div className="image-div">
          <img src={LoginImage} />
        </div>
        <div className="content-div">
          <img src={LoginAEPLogo} className="logo" />
          <span className="heading">Contract Administration Application</span>
          <Form onSubmit={handleSubmit}>
            <Button type="submit" className="btnLogin">
              Login
            </Button>
          </Form>
        </div>
      </div>
      <div className="footer">
        <span className="footer-section1">
          This system is for the use of <b>AUTHORIZED USERS ONLY.</b>
          Unauthorized users should not attempt to access this system.
          Individuals using this system are subject to having their activities
          on this system monitored and recorded by system personnel. Anyone
          using this system expressly consents to such monitoring and is advised
          that if such monitoring reveals possible evidence of criminal
          activity, system personnel may provide the evidence of such activity
          to law enforcement officials
        </span>
        <span className="footer-section2">© 2022 American Electric Power</span>
      </div>
    </>
  );
};

export { Login };
