import { AddNewFilterIcon } from 'assets/images/AddNewFilterIcon/AddNewFilterIcon';
import { FilterChipCloseIcon } from 'assets/images/FilterChipCloseIcon/FilterChipCloseIcon';
import _ from 'lodash';
import moment from 'moment';
import { useEffect, useState } from 'react';
import { Badge, Col, Form, FormLabel, Row } from 'react-bootstrap';
import DatePicker from 'react-datepicker';
import 'react-datepicker/dist/react-datepicker.css';
import { useDispatch } from 'react-redux';
import { useMediaQuery } from 'react-responsive';
import ReactSelect from 'react-select';
import { isEmptyVal } from 'utils/utils';
import './AEPFilterModal.scss';

const AEPFilterComponent = props => {
  const dispatch = useDispatch();

  const {
    inputListData,
    filterValues,
    selectedFilterRecordList,
    handleContinueClick,
    handleFilterclose,
    handleFilterChip,
    filterChip,
    chipFilterValues,
  } = props;

  const [index, setIndex] = useState(-1);
  const [selectedFilterValue, setSelectedFilterValue] = useState();
  const [disableOptions, setDisabledOptions] = useState(true);
  const [disableApplyBtn, setDisableApplyButton] = useState(true);
  const isMobile = useMediaQuery({ query: '(max-width: 575px)' });

  const handleSelectChange = (e, index) => {
    setIndex(index);
    let newInputList = _.cloneDeep(inputListData);
    newInputList[index].selectFilterLabel = '';
    newInputList[index].selectFilterValue = '';
    dispatch(props.setInputFilterData(newInputList));
    setDisableApplyButton(true);
    setDisabledOptions(false);
    setSelectedFilterValue(e.target.value);
    dispatch(props.fetchSelectedList({ columnname: e.target.value }));
  };

  const handleInputChange = value => {
    if (!isEmptyVal(value)) {
      dispatch(
        props.fetchSelectedList({
          columnname: selectedFilterValue,
          search: value,
        }),
      );
    }
  };
  const debouncedInputChange = _.debounce(handleInputChange, 500);

  const handleDateFilter = (e, index) => {
    let newInputList = _.cloneDeep(inputListData);
    for (let key in newInputList) {
      if (key == index) {
        newInputList[key].selectFilterValue = moment(e, 'YYYY-MM-DD').format(
          'MM/DD/YYYY',
        );
        newInputList[key].selectFilterLabel = e;
        newInputList[key].input_rank = index + 1;
      }
    }
    handleFilterclose('filterChange');
    if (e !== '') {
      setDisableApplyButton(false);
    } else {
      setDisableApplyButton(true);
    }
    dispatch(props.setInputFilterData(newInputList));
  };

  const getPOOptions = data => {
    const options = [];
    if (data && !isEmptyVal(data)) {
      data.forEach((val, index) => {
        options.push({ label: val.label, value: val.value });
      });
    }
    let sortedValue = options
      ?.slice()
      .sort((option1, option2) =>
        String(option1.label)
          .trim()
          .localeCompare(String(option2.label).trim()),
      );
    return sortedValue;
  };

  const handleSelectFilterChange = (e, index) => {
    let newInputList = _.cloneDeep(inputListData);
    for (let key in newInputList) {
      if (key == index) {
        newInputList[key].selectFilterValue = e?.value;
        newInputList[key].selectFilterLabel = e?.label;
        newInputList[key].input_rank = index + 1;
      }
    }
    handleFilterclose('filterChange');
    if (e?.value !== '') {
      setDisableApplyButton(false);
    } else {
      setDisableApplyButton(true);
    }
    dispatch(props.setInputFilterData(newInputList));
  };

  const handleFilterContinueClick = e => {
    handleContinueClick();
    let newInputList = {};
    newInputList.FilterValue = '';
    newInputList.selectFilterValue = '';
    newInputList.selectFilterLabel = '';
    newInputList.filerForm = false;
    newInputList.FilterLabel = '';
    newInputList.input_rank = null;
    newInputList.selectedFilterRecords = [];
    dispatch(props.setInputFilterData(newInputList));
    setDisableApplyButton(true);
    setDisabledOptions(true);
  };

  useEffect(() => {
    let newInputList = _.cloneDeep(inputListData);
    if (index >= 0 && selectedFilterValue) {
      for (let key in newInputList) {
        if (key == index) {
          newInputList[index].FilterValue = selectedFilterValue;
          newInputList[index].input_rank = index + 1;
          newInputList[index].filerForm = true;
          filterValues.map((el, id) => {
            if (el.value == selectedFilterValue) {
              newInputList[index].FilterLabel = el.label;
            }
          });
          newInputList[index].selectedFilterRecords = selectedFilterRecordList;
        }
      }
      dispatch(props.setInputFilterData(newInputList));
    }
  }, [selectedFilterRecordList, index, dispatch]);

  return (
    <div>
      {inputListData.map(
        (input, index) =>
          index === inputListData.length - 1 && (
            <Form key={index} onSubmit={e => e.preventDefault()}>
              <Row>
                <Col sm={isMobile ? 4 : 3}>
                  <Form.Group controlId="form.filters">
                    <FormLabel>Filter By:</FormLabel>
                    <Form.Select
                      aria-label="filterValues"
                      onChange={e => handleSelectChange(e, index)}
                      defaultValue={input.FilterValue}
                      size="md"
                      name={input.FilterValue}
                    >
                      <option value="" disabled>
                        Choose an Option
                      </option>
                      {filterValues
                        .slice()
                        .sort((a, b) => a.label.localeCompare(b.label))
                        ?.map((filter, index) => (
                          <option
                            style={{ fontSize: '16px' }}
                            key={index}
                            value={filter.value}
                          >
                            {filter.label}
                          </option>
                        ))}
                    </Form.Select>
                  </Form.Group>
                </Col>
                <Col sm={isMobile ? 4 : 3}>
                  <FormLabel></FormLabel>
                  <Form.Group>
                    {input.filerForm == true &&
                    input.FilterValue &&
                    input.FilterValue.includes('Date') ? (
                      <DatePicker
                        selected={
                          input?.selectFilterValue
                            ? new Date(input?.selectFilterValue)
                            : ''
                        }
                        className="date-picker-icon"
                        onChange={e => handleDateFilter(e, index)}
                      />
                    ) : (
                      <ReactSelect
                        id={index}
                        isDisabled={disableOptions}
                        classNamePrefix="poSelect"
                        value={
                          input.selectFilterValue
                            ? {
                                label: input.selectFilterValue,
                                value: input.selectFilterValue,
                              }
                            : {
                                label: 'Select',
                                value: '',
                              }
                        }
                        isClearable={false}
                        isSearchable={true}
                        name={input?.FilterValue}
                        options={getPOOptions(input?.selectedFilterRecords)}
                        menuPosition="absolute"
                        styles={{
                          menuPortal: base => ({
                            ...base,
                            zIndex: 9999,
                            fontSize: '16px',
                          }),
                        }}
                        maxMenuHeight={500}
                        onChange={e => handleSelectFilterChange(e, index)}
                        onInputChange={e => debouncedInputChange(e)}
                      />
                    )}
                  </Form.Group>
                </Col>
                <Col sm={isMobile ? 3 : 2}>
                  <div
                    className={
                      disableApplyBtn || inputListData.length >= 6
                        ? 'addnewFilterBtnDisable'
                        : 'addNewFilterBtn'
                    }
                    onClick={() =>
                      !disableApplyBtn && handleFilterContinueClick()
                    }
                  >
                    <AddNewFilterIcon />
                    <span> Apply Filter </span>
                  </div>
                </Col>
              </Row>
              <Row style={{ float: 'left' }}>
                <Col sm={12}>
                  <div className="filterSection">
                    <>
                      {filterChip == true ? (
                        Object.keys(chipFilterValues)
                          .filter(name => !isEmptyVal(name))
                          .map((chipName, chipNameIndex) => {
                            return chipFilterValues[chipName].map(
                              (chipValue, chipValueIndex) => {
                                return (
                                  <div className="filterChipIcon">
                                    <Badge
                                      bg="light"
                                      text="dark"
                                      className="filterNames"
                                    >
                                      <div>
                                        <span>{chipName}:</span>{' '}
                                        <span>{chipValue}</span>
                                      </div>
                                      <span
                                        className="filtercloseIcon"
                                        onClick={() =>
                                          handleFilterChip(chipName, chipValue)
                                        }
                                      >
                                        <FilterChipCloseIcon />
                                      </span>
                                    </Badge>
                                  </div>
                                );
                              },
                            );
                          })
                      ) : (
                        <></>
                      )}
                    </>
                  </div>
                </Col>
              </Row>
            </Form>
          ),
      )}
    </div>
  );
};

export { AEPFilterComponent };
