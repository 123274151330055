import React from 'react';

export const TimesheetGridViewIconDefault = () => {
  return (
    <svg
      xmlns="http://www.w3.org/2000/svg"
      width="36"
      height="36"
      viewBox="0 0 32 32"
    >
      <g data-name="Group 161647">
        <g
          data-name="🔲🎨 Container l Color"
          style={{ fill: '#fff', stroke: '#4a4a4a', strokeMiterlimit: '10' }}
        >
          <path
            d="M0 0h28a4 4 0 0 1 4 4v24a4 4 0 0 1-4 4H0V0z"
            style={{ stroke: 'none' }}
          />
          <path
            d="M.5.5H28A3.5 3.5 0 0 1 31.5 4v24a3.5 3.5 0 0 1-3.5 3.5H.5V.5z"
            style={{ fill: 'none' }}
          />
        </g>
        <g data-name="Group 161522">
          <path
            data-name="Path 28518"
            d="M0 0h24v24H0z"
            style={{ fill: 'none' }}
            transform="translate(4 4)"
          />
        </g>
        <g data-name="Group 161523">
          <path
            data-name="Path 28519"
            d="M20 4H4a2.006 2.006 0 0 0-2 2v12a2.006 2.006 0 0 0 2 2h16a2.006 2.006 0 0 0 2-2V6a2.006 2.006 0 0 0-2-2zM8 11H4V6h4zm6 0h-4V6h4zm6 0h-4V6h4zM8 18H4v-5h4zm6 0h-4v-5h4zm6 0h-4v-5h4z"
            style={{ fill: '#4a4a4a' }}
            transform="translate(4 4)"
          />
        </g>
      </g>
    </svg>
  );
};

export const TimesheetGridViewIconSelected = () => {
  return (
    <svg
      xmlns="http://www.w3.org/2000/svg"
      width="36"
      height="36"
      viewBox="0 0 32 32"
    >
      <g data-name="Group 161654">
        <g
          data-name="🔲🎨 Container l Color"
          style={{ fill: '#4a4a4a', stroke: '#4a4a4a', strokeMiterlimit: '10' }}
        >
          <path
            d="M0 0h28a4 4 0 0 1 4 4v24a4 4 0 0 1-4 4H0V0z"
            style={{ stroke: 'none' }}
          />
          <path
            d="M.5.5H28A3.5 3.5 0 0 1 31.5 4v24a3.5 3.5 0 0 1-3.5 3.5H.5V.5z"
            style={{ fill: 'none' }}
          />
        </g>
        <g data-name="Group 161522">
          <path
            data-name="Path 28518"
            d="M0 0h24v24H0z"
            style={{ fill: 'none' }}
            transform="translate(4 4)"
          />
        </g>
        <g data-name="Group 161523">
          <path
            data-name="Path 28519"
            d="M20 4H4a2.006 2.006 0 0 0-2 2v12a2.006 2.006 0 0 0 2 2h16a2.006 2.006 0 0 0 2-2V6a2.006 2.006 0 0 0-2-2zM8 11H4V6h4zm6 0h-4V6h4zm6 0h-4V6h4zM8 18H4v-5h4zm6 0h-4v-5h4zm6 0h-4v-5h4z"
            style={{ fill: '#fff' }}
            transform="translate(4 4)"
          />
        </g>
      </g>
    </svg>
  );
};
