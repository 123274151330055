import { Cookie } from 'enums/cookie.ts';
import { decodeToken } from 'utils/tokenHelper';

const cookieNames = [Cookie.ACCESS_TOKEN, Cookie.REFRESH_TOKEN];

class CookieService {
  static authenticateViaCookie = () => {
    const cookie = document.cookie;

    const accessTokenParts = cookie.split(`${Cookie.ACCESS_TOKEN}=`);
    const accessTokenFromCookie = accessTokenParts[1]?.split(';').shift();

    const refreshTokenParts = cookie.split(`${Cookie.REFRESH_TOKEN}=`);
    const refreshTokenFromCookie = refreshTokenParts[1]?.split(';').shift();

    if (accessTokenFromCookie) {
      try {
        const parsedCookie = decodeToken(accessTokenFromCookie);
        const cookieNotExpired = new Date(parsedCookie.exp * 1000) > new Date();

        if (cookieNotExpired) {
          return {
            accessExpired: false,
            isLoggedIn: true,
            accessToken: accessTokenFromCookie,
            refreshToken: refreshTokenFromCookie ?? '',
            userId: parsedCookie.userid,
          };
        }
      } catch (err) {}
    }

    return {
      accessExpired: true,
      isLoggedIn: false,
      accessToken: accessTokenFromCookie,
      refreshToken: refreshTokenFromCookie,
      userId: '',
    };
  };

  static setCookies = action => {
    document.cookie = `${Cookie.ACCESS_TOKEN}=${action.payload.accessToken}; path=/`;
    document.cookie = `${Cookie.REFRESH_TOKEN}=${action.payload.refreshToken}; path=/`;
  };

  static setAccessToken = accessToken => {
    document.cookie = `${Cookie.ACCESS_TOKEN}=${accessToken}; path=/`;
  };

  static clearCookies = () => {
    cookieNames.forEach(name => {
      document.cookie = `${name}=; path=/; expires=Thu, 01 Jan 1970 00:00:01 GMT;`;
    });
  };
}

export { CookieService };
