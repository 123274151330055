import React from 'react';
import { Row } from 'react-bootstrap';
import { useSelector } from 'react-redux';
import { DownloadIcon } from 'assets/images/DownloadIcon/DownloadIcon';
import { DuplicateIcon } from 'assets/images/DuplicateIcon/DuplicateIcon';
import { PreviewIcon } from 'assets/images/PreviewIcon/PreviewIcon';
import { IsAllowedACL } from 'utils/aclHelper';
import { ModuleACL } from 'enums/entitlements.ts';
import { isEmptyVal } from 'utils/utils';
import { TimesheetStatus } from 'enums/statuses.ts';
import './../TimesheetEntry.scss';

const HeaderButtons = props => {
  const user = useSelector(state => state.auth);
  const timesheet = useSelector(state => state.timesheetDetail);

  const {
    setShowPreviewInvoiceModal,
    setShowDuplicateModal,
    handleTimeSheetDownload,
    newTimesheet,
  } = props;

  return (
    <Row className="float-right">
      {IsAllowedACL(user, ModuleACL.TIMESHEET, 'can_preview_timesheet') && (
        <>
          {!newTimesheet && isEmptyVal(timesheet?.invoiceNmbr) && (
            <>
              {timesheet?.classificationList.length > 0 ? (
                <span
                  onClick={() => setShowPreviewInvoiceModal(true)}
                  className="icons"
                  title="Preview Invoice"
                >
                  <PreviewIcon />
                </span>
              ) : (
                <span
                  className="icons"
                  style={{ opacity: '0.5' }}
                  title="Preview Invoice"
                >
                  <PreviewIcon />
                </span>
              )}
            </>
          )}
        </>
      )}

      {IsAllowedACL(user, ModuleACL.TIMESHEET, 'can_duplicate_timesheet') && (
        <>
          {!newTimesheet && (
            <span
              title="Duplicate Timesheet"
              className="icons"
              onClick={() => setShowDuplicateModal(true)}
            >
              <DuplicateIcon />
            </span>
          )}
        </>
      )}

      {IsAllowedACL(user, ModuleACL.TIMESHEET, 'can_export_timesheet') && (
        <>
          {[
            TimesheetStatus?.TIMESHEET_APPROVED,
            TimesheetStatus?.TIMESHEET_SAVED_NOT_SUBMITTED,
            TimesheetStatus?.TIMESHEET_SUBMITTED_NOT_APPROVED,
            TimesheetStatus?.TIMESHEET_RETURNED_NOT_APPROVED,
          ].includes(timesheet?.caastatus) && (
            <span
              className="icons"
              title="Export Timesheet"
              onClick={() => handleTimeSheetDownload(timesheet?.recordNmbr)}
            >
              <DownloadIcon />
            </span>
          )}
        </>
      )}
    </Row>
  );
};

export { HeaderButtons };
