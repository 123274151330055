import React from 'react';

export const ExcelIcon = () => {
  return (
    <svg width="24" height="24" viewBox="0 0 24 24">
      <g data-name="Group 161112" transform="translate(-133 -450)">
        <rect
          data-name="🔲🎨 Container l Color"
          width="24"
          height="24"
          rx="2"
          transform="translate(133 450)"
          style={{ fill: '#4a4a4a' }}
        />
        <g data-name="Group 13136">
          <path
            data-name="Path 25944"
            d="M20.581-80v3.733H18v9.6h2.581V-64H34v-16zm0 6.4h1.368l.7 1.067.7-1.067h1.361l-1.368 2.133 1.368 2.133h-1.368l-.7-1.067-.7 1.067h-1.361l1.368-2.133zm12.387 8.533H21.613v-1.6h5.677v-3.2h4.129v-1.067H27.29V-72h4.129v-1.067H27.29v-1.067h4.129V-75.2H27.29v-1.067h-5.677v-2.667h11.355z"
            transform="translate(119 534)"
            style={{ fill: '#f5f7f9' }}
          />
        </g>
      </g>
    </svg>
  );
};
