import './AdminModuleContainer.scss';

const AdminModuleContainer = () => {
  return (
    <>
      <div className="aepContainer adminModuleContainer">
        <div className="add">
          <div className=" aepHeader">No Items yet</div>
          <div className=" aepBreadcrumb">
            To get started,please select any one card (Work type or Crew
            type)above
          </div>
        </div>
      </div>
    </>
  );
};
export { AdminModuleContainer };
