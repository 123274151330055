// import { AEPRole, VendorRole } from 'configs/entitlements';
import { UserType, AEP, Vendor } from 'enums/entitlements.ts';
import { all, call, put, takeEvery, takeLatest } from 'redux-saga/effects';
import * as service from 'services/authentication';
import { CookieService } from 'services/cookieService';
import * as userService from 'services/user';
import { resetState } from 'store/configureStore';
import { displayError } from 'components/common/Alert/ToastAlert';
import {
  fetchDashboardCount,
  loginUser,
  logoutUser,
  refreshAccessToken,
  setAccessToken,
  setDashboardCount,
  setLoggedInUser,
  setLoggedOut,
  setRefreshToken,
  setUserData,
} from 'store/slices/authenticationSlice';
import { setError, unSetError } from 'store/slices/errorSlice';
import { getModulesACL } from 'utils/aclHelper';

export const token = state => state.auth?.accessToken;

function* login(action) {
  try {
    const { response } = action.payload;
    yield put(setLoggedInUser(response.data));
    yield put(unSetError());

    const userResponse = yield call(
      f => userService.retrieveUser(),
      action.payload,
    );

    // Set user type and user roles for a user
    const userRoles = [];
    let userType = '';
    userResponse.data.scope_data.forEach(obj => userRoles.push(obj.id));

    userType = ['aep-user', 'admin'].includes(userResponse?.data?.acl?.userrole)
      ? UserType.AEP
      : UserType.VENDOR;

    const configObject = userResponse.data.acl;
    const userId = userResponse.data.uid;
    const userDisplayname = userResponse.data.displayName;

    yield put(
      setUserData({
        userType,
        userRoles,
        configObject,
        userId,
        userDisplayname,
      }),
    );
  } catch (error) {
    yield put(setError(error.data));
  }
}

function* logout(action) {
  try {
    resetState();
    yield put(setLoggedOut());
  } catch (e) {
    console.error(e);
  }
}

function* refreshAccessTokenSaga(action) {
  try {
    if (action.payload.refreshToken !== '') {
      const response = yield call(service.refreshAccessToken, action.payload);
      // if (response.status === 200) {
      //   const { accessToken, refreshToken } =
      //     CookieService.authenticateViaCookie();
      //   yield put(setAccessToken({ accessToken }));
      //   yield put(setRefreshToken({ refreshToken }));
      // }
    }
  } catch (error) {
    console.error({ error });
  }
}

function* getDashboardCountSaga(action) {
  try {
    const response = yield call(
      f => userService.retrieveDashboardCount(),
      action.payload,
    );

    if (response.status === 200) {
      yield put(setDashboardCount(response.data));
    }
  } catch (e) {
    // yield put(setError(true));
    displayError(
      'We are experiencing technical difficulties. Please come back shortly to try again !',
    );
  }
}

function* watchLogin() {
  yield takeEvery(loginUser.type, login);
}

function* watchLogout() {
  yield takeEvery(logoutUser.type, logout);
}

function* watchRefreshAccessToken() {
  yield takeLatest(refreshAccessToken.type, refreshAccessTokenSaga);
}

function* watchDashboardCount() {
  yield takeLatest(fetchDashboardCount.type, getDashboardCountSaga);
}

export function* authSaga() {
  yield all([
    watchLogin(),
    watchLogout(),
    watchRefreshAccessToken(),
    watchDashboardCount(),
  ]);
}
