import { CaretUp } from 'assets/images/CaretIcon/CaretUp/CaretUp';
import { CaretDown } from 'assets/images/CaretIcon/CaretDown/CaretDown';
import { SearchIcon } from 'assets/images/SearchIcon/SearchIcon';
import { ROUTES_STRING } from 'constants/routes';
import { Audit } from 'enums/fields.ts';
import moment from 'moment';
import { useEffect, useState } from 'react';
import { Button, Form, Tab, Table, Tabs, Row, Col } from 'react-bootstrap';
import { useDispatch, useSelector } from 'react-redux';
import { useNavigate } from 'react-router-dom';
import { setActivePage } from 'store/slices/pageSlice';
import { AEPPagination } from 'components/common/Pagination/AEPPagination';
import {
  fetchAuditList,
  fetchAuditDetail,
  setAuditList,
  setNewAudit,
  resetAudit,
  setAuditPagination,
  fetchAuditors,
} from 'store/slices/AuditModules/auditSlice';
import { Page } from 'enums/page.ts';
import './AuditList.scss';

const AuditList = () => {
  const dispatch = useDispatch();
  const navigate = useNavigate();

  const auditList = useSelector(state => state.audit.auditList);
  const audit = useSelector(state => state.audit);

  const pagination = useSelector(state => state.audit.pagination);

  const currentPage = useSelector(state => state.audit.pagination.currentPage);
  const sortBy = useSelector(state => state.audit.pagination.sortBy);
  const sortOrder = useSelector(state => state.audit.pagination.sortOrder);

  const [key, setKey] = useState('all');
  const TabKey = {
    ALL: 'all',
  };

  const tabs = [{ key: TabKey.ALL, value: 'All' }];

  useEffect(() => {
    dispatch(setActivePage(Page.AUDITMODULES));
    dispatch(fetchAuditors());
  }, []);

  const headers = [
    { key: Audit.auditNumber, value: 'Audit Number' },
    { key: Audit.auditDate, value: 'Audit Date' },
    { key: Audit.auditorName, value: 'Auditor Name' },
    { key: Audit.crewType, value: 'Crew Type' },
    { key: Audit.crewNumber, value: 'Crew Number' },
    { key: Audit.circuit, value: 'Circuit' },
  ];
  //Create a New Audit Form
  const handlenewAuditClick = () => {
    dispatch(resetAudit());
    dispatch(setNewAudit({ newAudit: true }));
    navigate(`/${ROUTES_STRING.audit}/new`);
  };
  //Navigate a Created Audit Form
  const handleAuditRowClick = row => {
    dispatch(fetchAuditDetail(row.seqAuditRecord));
    navigate(`/${ROUTES_STRING.audit}/${row.seqAuditRecord}`);
  };

  //handle sorting of audit list based on header
  const handleSorting = (sortBy, sortOrder) => {
    dispatch(
      setAuditPagination({
        ...pagination,
        currentPage: 1,
        sortBy,
        sortOrder,
      }),
    );
  };

  return (
    <>
      <div className="aepContainer aepHeader">Audit</div>
      <div className="aepContainer">
        <Tabs id="auditPage" activeKey={key}>
          {tabs.map((tab, index) => (
            <Tab
              eventKey={tab.key}
              title={tab.value}
              key={index}
              tabClassName="tab-item"
            >
              <div className="tableContainer">
                {/* <div className="search">
                  <div style={{ display: 'flex' }}>
                    <Form.Group className="mb-3" controlId="searchPO">
                      <Form.Control type="text" placeholder="Search" />
                    </Form.Group>
                    <div className="searchIcon">
                      <SearchIcon />
                    </div>
                  </div>
                </div> */}
                <Table hover responsive striped bordered={false}>
                  <thead>
                    <tr>
                      {headers.map((header, idx) => (
                        <th key={idx}>
                          {sortOrder === 'asc' && (
                            <div
                              id={header.key}
                              onClick={e => handleSorting(header.key, 'desc')}
                            >
                              {header.value}
                              <CaretUp style={{ marginLeft: '12px' }} />
                            </div>
                          )}
                          {sortOrder === 'desc' && (
                            <div
                              id={header.key}
                              onClick={e => handleSorting(header.key, 'asc')}
                            >
                              {header.value}
                              <CaretDown style={{ marginLeft: '12px' }} />
                            </div>
                          )}
                        </th>
                      ))}
                    </tr>
                  </thead>
                  <tbody>
                    {auditList.map((row, indx) => (
                      <tr
                        key={indx}
                        className="tRow table-hover-row"
                        onClick={e => handleAuditRowClick(row)}
                      >
                        <td>{row.auditNumber}</td>
                        <td>{moment(row.auditDate).format('MMM D,YYYY')}</td>
                        <td>
                          {' '}
                          {audit?.auditors?.find(
                            audit =>
                              audit.seqAuditorNmbr === row.seqAuditorNmbr,
                          )
                            ? audit?.auditors?.find(
                                audit =>
                                  audit.seqAuditorNmbr === row.seqAuditorNmbr,
                              ).auditorName
                            : ''}
                        </td>
                        <td>{row.crewType}</td>
                        <td>{row.crewNumber}</td>
                        <td>{row.circuitNmbr}</td>
                      </tr>
                    ))}
                  </tbody>
                </Table>
                <AEPPagination
                  fetchItems={fetchAuditList}
                  setPagination={setAuditPagination}
                  pagination={pagination}
                  currentPage={currentPage}
                  sortBy={sortBy}
                  sortOrder={sortOrder}
                />
              </div>
            </Tab>
          ))}
        </Tabs>
      </div>
      <div className="submitButtonDiv">
        <Button variant="primary" onClick={e => handlenewAuditClick()}>
          Create New Audit Form
        </Button>
      </div>
    </>
  );
};

export { AuditList };
