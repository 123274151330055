export const PROMISE_TRACKER = {
  getTimesheetList: 'get-timesheet-list',
  getTimesheetDetail: 'get-timesheet-detail',
  saveTimesheet: 'save-timesheet',
  getInvoiceData: 'get-invoice-data',
  sendToMaximo: 'send-to-maximo',
  modifyTimesheet: 'modify-timesheet',
  addWorkArea: 'add-work-area',
  updateWorkArea: 'update-work-area',
  updatefuelrates: 'update-fuel-rates',
  getAnalyticsData: 'get-analytics-data',
  updatedistrictWorkArea: 'update-district-work-area',
  adddistrictWorkArea: 'add-district-work-area',
  getPODetail: 'get-po-detail',
  getWorkType: 'get-work-type',
  getUserEntitlements: 'get-user-entitlements',
  getCuList: 'get-cu-list',
  getContractorList: 'get-contractor-list',
  getCompanyList: 'get-company-list',
  getDistrictList: 'get-district-list',
  getBidRateDetails: 'get-bidrate-details',
};
