import { AddNewFilterIcon } from 'assets/images/AddNewFilterIcon/AddNewFilterIcon';
import { DeleteIcon } from 'assets/images/DeleteIcon/DeleteIcon';
import { ButtonArea } from 'components/common//Button/ButtonArea/ButtonArea';
import { Divider } from 'components/common/Divider/Divider';
import _ from 'lodash';
import moment from 'moment';
import { Fragment, useEffect, useState } from 'react';
import { Button, Card, Col, Form, Modal, Row } from 'react-bootstrap';
import DatePicker from 'react-datepicker';
import 'react-datepicker/dist/react-datepicker.css';
import { useDispatch } from 'react-redux';
import ReactSelect from 'react-select';
import { isEmptyVal } from 'utils/utils';
import './AEPFilterModal.scss';

const AEPFilterModal = props => {
  const dispatch = useDispatch();

  const {
    show,
    setShowModal,
    inputListData,
    filterValues,
    selectedFilterRecordList,
    handleContinueClick,
    continueBtnDisable,
    handleFilterclose,
    handleShowDetailsClick,
    type,
  } = props;

  const [index, setIndex] = useState(-1);
  const [selectedFilterValue, setSelectedFilterValue] = useState();

  const handleSelectChange = (e, index) => {
    setIndex(index);
    setSelectedFilterValue(e.target.value);
    dispatch(props.fetchSelectedList({ columnname: e.target.value }));
  };

  const handleInputChange = value => {
    if (!isEmptyVal(value)) {
      dispatch(
        props.fetchSelectedList({
          columnname: selectedFilterValue,
          search: value,
        }),
      );
    }
  };
  const debouncedInputChange = _.debounce(handleInputChange, 500);

  const handleDateFilter = (e, index) => {
    let newInputList1 = _.cloneDeep(inputListData);
    for (let key in newInputList1) {
      if (key == index) {
        newInputList1[key].selectFilterValue = moment(e, 'YYYY-MM-DD').format(
          'MM/DD/YYYY',
        );
        newInputList1[key].selectFilterLabel = e;
        newInputList1[key].input_rank = index + 1;
      }
    }
    handleFilterclose('filterChange');
    dispatch(props.setInputFilterData(newInputList1));
  };

  const getPOOptions = data => {
    const options = [{ label: 'Select', value: '' }];
    if (data) {
      data.forEach((val, index) => {
        options.push({ label: val.value, value: val.value });
      });
    }
    return options;
  };

  const handleSelectFilterChange = (e, index) => {
    let newInputList1 = _.cloneDeep(inputListData);
    for (let key in newInputList1) {
      if (key == index) {
        newInputList1[key].selectFilterValue = e?.value;
        newInputList1[key].selectFilterLabel = e?.label;
        newInputList1[key].input_rank = index + 1;
      }
    }
    handleFilterclose('filterChange');
    dispatch(props.setInputFilterData(newInputList1));
  };

  const handleNewFilterclick = e => {
    let newInputList1 = {};
    newInputList1.FilterValue = '';
    newInputList1.selectFilterValue = '';
    newInputList1.selectFilterLabel = '';
    newInputList1.filerForm = false;
    newInputList1.FilterLabel = '';
    newInputList1.input_rank = null;
    newInputList1.selectedFilterRecords = [];
    dispatch(props.setInputFilterData(newInputList1));
  };

  const handleRemoveItem = index => {
    let newInputList1 = _.cloneDeep(inputListData);
    newInputList1.splice(index, 1);
    if (newInputList1.length == 0) handleFilterclose('deleteEmpty');
    dispatch(props.setInputFilterData(newInputList1));
  };

  useEffect(() => {
    let newInputList1 = _.cloneDeep(inputListData);
    if (index >= 0)
      for (let key in newInputList1) {
        if (key == index) {
          newInputList1[index].FilterValue = selectedFilterValue;
          newInputList1[index].input_rank = index + 1;
          newInputList1[index].filerForm = true;
          filterValues.map((el, id) => {
            if (el.value == selectedFilterValue) {
              newInputList1[index].FilterLabel = el.label;
            }
          });

          newInputList1[index].selectedFilterRecords = selectedFilterRecordList;
        }
      }
    dispatch(props.setInputFilterData(newInputList1));
  }, [selectedFilterRecordList, index, dispatch]);

  const checkDisable = (key, input) => {
    for (const iLd of inputListData) {
      if (
        iLd.FilterValue === input.FilterValue &&
        key === iLd.selectFilterValue
      ) {
        return true;
      }
    }
    return false;
  };

  return (
    <Modal
      size="md"
      show={show}
      aria-labelledby="contained-modal-title-vcenter"
      centered
    >
      <Modal.Header>
        <Modal.Title id="contained-modal-title-vcenter">
          Filter {type} Records
        </Modal.Title>
      </Modal.Header>
      <Divider />
      <Modal.Body className="AEPFilterContainer">
        {inputListData.map((input, index) => (
          <Card key={index}>
            <Form>
              <Row>
                <Col sm={5} className="mr-10">
                  <Form.Group controlId="form.filters">
                    <Form.Select
                      aria-label="filterValues"
                      onChange={e => handleSelectChange(e, index)}
                      defaultValue={input.FilterValue}
                      size="md"
                      name={input.FilterValue}
                      style={{ height: '70px', fontSize: '16px' }}
                    >
                      <option value="select">Choose an Option</option>
                      {filterValues?.map((filter, index) => (
                        <option
                          style={{ fontSize: '16px' }}
                          key={index}
                          value={filter.value}
                        >
                          {filter.label}
                        </option>
                      ))}
                    </Form.Select>
                  </Form.Group>
                </Col>
                <Col sm={5} className="mr-10">
                  <Form.Group>
                    {input.filerForm == true &&
                    input.FilterValue &&
                    input.FilterValue.includes('Date') ? (
                      <DatePicker
                        selected={
                          input?.selectFilterValue
                            ? new Date(input?.selectFilterValue)
                            : ''
                        }
                        className="date-picker-icon"
                        onChange={e => handleDateFilter(e, index)}
                      />
                    ) : (
                      <ReactSelect
                        id={index}
                        classNamePrefix="poSelect"
                        defaultValue={
                          input.selectFilterValue
                            ? {
                                label: input.selectFilterValue,
                                value: input.selectFilterValue,
                              }
                            : {
                                label: 'Select',
                                value: '',
                              }
                        }
                        placeholder="Select"
                        isClearable
                        isSearchable={true}
                        name={input?.FilterValue}
                        options={getPOOptions(input?.selectedFilterRecords)}
                        menuPosition="absolute"
                        menuPortalTarget={document.body}
                        styles={{
                          menuPortal: base => ({
                            ...base,
                            zIndex: 9999,
                            fontSize: '16px',
                          }),
                        }}
                        maxMenuHeight={500}
                        onChange={e => handleSelectFilterChange(e, index)}
                        onInputChange={e => debouncedInputChange(e)}
                      />
                    )}
                  </Form.Group>
                </Col>
                <Col
                  className="filterDelete"
                  name={input.presentIcon}
                  onClick={() => handleRemoveItem(index)}
                  title="Delete"
                >
                  <DeleteIcon />
                </Col>
              </Row>
            </Form>
          </Card>
        ))}

        <br />
        <div
          className={
            inputListData.length >= 5
              ? 'addnewFilterBtnDisable'
              : 'addNewFilterBtn'
          }
          onClick={() => handleNewFilterclick()}
        >
          <AddNewFilterIcon />
          <span> Add New Filter </span>
        </div>
      </Modal.Body>
      <Modal.Footer>
        {type == 'Invoice' ? (
          <div
            className="aepContainer btnArea"
            style={{ backgroundColor: 'none' }}
          >
            <Fragment>
              <Button
                variant="primary"
                onClick={() => handleContinueClick()}
                disabled={continueBtnDisable}
              >
                Continue
              </Button>
            </Fragment>
            <Fragment>
              <Button
                variant="primary"
                onClick={() => handleShowDetailsClick()}
                disabled={continueBtnDisable}
              >
                Show Details
              </Button>
            </Fragment>
            <Fragment>
              <Button variant="secondary" onClick={() => setShowModal(false)}>
                Cancel
              </Button>
            </Fragment>
          </div>
        ) : (
          <ButtonArea
            primaryLabel="Continue"
            secondaryLabel="Cancel"
            primaryOnClick={() => handleContinueClick()}
            secondaryOnClick={() => setShowModal(false)}
            primaryDisabled={continueBtnDisable}
          />
        )}
      </Modal.Footer>
    </Modal>
  );
};

export { AEPFilterModal };
