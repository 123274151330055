import _ from 'lodash';
import * as Yup from 'yup';

export const generateSchema = () => {
  return Yup.object().shape({
    ocNmbr: Yup.string().required(),
    orgDeptDesc: Yup.string().required(),
    orgDeptGroup: Yup.string().required(),
    departmentId: Yup.number()
      .required()
      .typeError('Must be a number')
      .transform((_, val) => (val === '' ? null : Number(val))),
    ocStatus: Yup.string().required(),
  });
};
