import React from 'react';

export const WorkTypeIcon = () => {
  return (
    <svg
      xmlns="http://www.w3.org/2000/svg"
      width="20"
      height="20"
      viewBox="0 0 20 20"
    >
      <path
        d="M121.667 236a1.709 1.709 0 0 1-1.667-1.667v-16.666a1.709 1.709 0 0 1 1.667-1.667h10.944v1.667h-10.944v16.667h16.667v-10.945H140v10.944a1.709 1.709 0 0 1-1.667 1.667zm3.917-4.361v-1.667h8.861v1.667zm0-3.528v-1.667h8.861v1.667zm0-3.528v-1.667h8.861v1.667zm10.306-2.028v-2.444h-2.444v-1.667h2.444V216h1.667v2.444H140v1.667h-2.444v2.444z"
        transform="translate(-120 -216)"
        style={{ fill: '#4a4a4a' }}
      />
    </svg>
  );
};
