import React, { useEffect } from 'react';
import { BackArrowIcon } from 'assets/images/BackArrowIcon/BackArrowIcon';
import './CompatibleUnitManual.scss';
import { PDFViewer } from 'components/common/PDFviewer/PDFViewer';
import { compatibleUnitManualURL } from '../Constant/constant';
import { Page } from 'enums/page.ts';
import { setActivePage } from 'store/slices/pageSlice';
import { useDispatch } from 'react-redux';

const CompatibleUnitManual = () => {
  const dispatch = useDispatch();
  useEffect(() => {
    dispatch(setActivePage(Page.ADDITIONAL_LINKS));
  });

  return (
    <>
      <div className="aepContainer aepHeader">Additional Links</div>
      <div className="aepContainer aepBreadcrumb" style={{ display: 'flex' }}>
        <span className="backArrow" title="back">
          <BackArrowIcon />
        </span>
        Compatible Unit Manual
      </div>

      <PDFViewer
        pdfUrl={compatibleUnitManualURL.url}
        name={compatibleUnitManualURL.name}
      />
    </>
  );
};

export { CompatibleUnitManual };
