import { createSlice } from '@reduxjs/toolkit';

const initialState = {
  isLoading: false,
  error: false,
  errorResponse: {},
  pagination: {
    totalItems: 0,
    currentPage: 1,
    itemsPerPage: 10,
    sortBy: '',
    sortOrder: 'asc',
  },
  recordItems: [],
  inputList: [
    {
      FilterValue: '',
      input_rank: null,
      selectFilterValue: '',
      selectFilterLabel: '',
      presentIcon: '',
      filerForm: false,
      FilterLabel: '',
      selectedFilterRecords: [],
    },
  ],
  isUpdate: false,
  districtWorkAreaList: [],
  districtWorkAreaData: {},
  workAreaList: [],
};

const districtWorkAreaSlice = createSlice({
  name: 'districtWorkAreaSlice',
  initialState,
  reducers: {
    fetchdistrictWorkAreaList(state, action) {
      const stateValues = { ...state };
      stateValues.isLoading = false;
      stateValues.pagination = {
        ...stateValues.pagination,
        ...action.payload,
      };
      return { ...stateValues };
    },
    setInputFilterData(state, action) {
      if (action.payload.length != 0) {
        state.inputList = action.payload;
      } else {
        const stateValues = { ...state };
        stateValues.inputList = [...initialState.inputList];
        return { ...stateValues };
      }
    },
    setAddNewInputFilterData(state, action) {
      const stateValues = { ...state };
      stateValues.inputList = [...stateValues.inputList, action.payload];
      return { ...stateValues };
    },
    fetchSelecteddistrictWorkAreaList(state) {
      state.isLoading = false;
    },
    setSelecteddistrictWorkAreaRecords(state, action) {
      const selectedAdminFuelRecords = [];
      action.payload?.forEach(obj => {
        let objValues = Object.values(obj);
        selectedAdminFuelRecords.push({
          label: objValues[0],
          value: objValues[0],
        });
      });
      const stateValues = { ...state };
      stateValues.recordItems = [...selectedAdminFuelRecords];
      return { ...stateValues };
    },
    updatedistrictWorkArea(state, action) {
      state.isLoading = false;
    },
    deletdistrictWorkArea(state, action) {
      state.isLoading = false;
    },
    adddistrictWorkArea(state, action) {
      state.isLoading = false;
    },
    getdistrictWorkArea(state, action) {
      state.isLoading = false;
    },
    setDefaultValues(state, action) {
      const stateValues = { ...state };
      stateValues.districtWorkAreaData = {};
      stateValues.isUpdate = false;
      return { ...stateValues };
    },
    getworkArea(state, action) {
      state.isLoading = false;
    },
    setworkArea(state, action) {
      const workAreas = action.payload?.rows.map(val => {
        return { workAreaId: val.workAreaId, workAreaDesc: val.workAreaDesc };
      });
      const stateValues = { ...state };
      stateValues.workAreaList = [...workAreas];
      return { ...stateValues };
    },
    setdistrictWorkAreaData(state, action) {
      const districtWorkAreaList = [];
      action.payload?.rows.forEach(obj => {
        districtWorkAreaList.push(obj);
      });
      const stateValues = { ...state };
      stateValues.districtWorkAreaList = [...districtWorkAreaList];
      stateValues.districtWorkAreaData = {};
      stateValues.isUpdate = false;
      stateValues.pagination = {
        ...stateValues.pagination,
        ...action.payload?.pagination,
      };
      return { ...stateValues };
    },
    setdistrictWorkArea(state, action) {
      const stateValues = { ...state };
      stateValues.districtWorkAreaData = action.payload;
      stateValues.isUpdate = true;
      return { ...stateValues };
    },
    setdistrictWorkAreaPagination(state, action) {
      const stateValues = { ...state };
      stateValues.pagination = {
        ...stateValues.pagination,
        ...action.payload,
      };
      return { ...stateValues };
    },

    unsetError(state) {
      const stateValues = { ...state };
      stateValues.error = false;
      stateValues.errorResponse = {};
      return { ...stateValues };
    },

    setError(state, action) {
      const stateValues = { ...state };
      stateValues.error = true;
      stateValues.errorResponse = action.payload;
      return { ...stateValues };
    },
  },
});

export const {
  fetchdistrictWorkAreaList,
  updatedistrictWorkArea,
  deletdistrictWorkArea,
  setdistrictWorkAreaData,
  setdistrictWorkAreaPagination,
  setdistrictWorkArea,
  setInputFilterData,
  setAddNewInputFilterData,
  fetchSelecteddistrictWorkAreaList,
  setSelecteddistrictWorkAreaRecords,
  setError,
  unsetError,
  setDefaultValues,
  adddistrictWorkArea,
  getworkArea,
  setworkArea,
  getdistrictWorkArea,
} = districtWorkAreaSlice.actions;

export default districtWorkAreaSlice.reducer;
