import React from 'react';

export const PendingInvoiceIcon = () => {
  return (
    <svg
      xmlns="http://www.w3.org/2000/svg"
      width="33.754"
      height="36.25"
      viewBox="0 0 33.754 36.25"
    >
      <g data-name="Group 12036">
        <g data-name="Group 3921">
          <path
            data-name="Path 2837"
            d="M35.069-74.868v-15.126a2.939 2.939 0 0 0-2.935-2.935H12.986a2.939 2.939 0 0 0-2.935 2.935v24.877a2.939 2.939 0 0 0 2.935 2.935H26.41a8.988 8.988 0 0 0 8.162 5.252 8.994 8.994 0 0 0 8.984-8.984 8.989 8.989 0 0 0-8.486-8.956zm-9.236 11.01H12.986a1.26 1.26 0 0 1-1.258-1.258v-24.878a1.26 1.26 0 0 1 1.258-1.258h19.148a1.26 1.26 0 0 1 1.258 1.258v15.186a8.921 8.921 0 0 0-3.634 1.325v-.717H15.78v1.677h12.728a8.954 8.954 0 0 0-2.922 6.612 9.024 9.024 0 0 0 .247 2.052zm8.736 5.252a7.315 7.315 0 0 1-7.307-7.307 7.314 7.314 0 0 1 7.307-7.307 7.314 7.314 0 0 1 7.307 7.307 7.315 7.315 0 0 1-7.306 7.307zm3.351-8.144h-2.8v-3.84a.838.838 0 0 0-.839-.839.838.838 0 0 0-.839.839v4.679a.838.838 0 0 0 .839.839h3.634a.838.838 0 0 0 .839-.839.838.838 0 0 0-.839-.839zm-8.162-11.643H15.78v1.677h13.976zM15.78-68.33h8.944v-1.677H15.78z"
            transform="translate(-9.926 93.054)"
            style={{ fill: '#df6d00', stroke: '#df6d00', strokeWidth: '.25px' }}
          />
        </g>
        <path
          data-name="Path 11247"
          d="M46.03-74.218v.582a.549.549 0 0 0 .549.533.549.549 0 0 0 .549-.533v-.594c1.6-.214 2.194-1.209 2.194-2.149 0-1.419-1.31-1.939-2.194-2.191v-2.16a2.593 2.593 0 0 1 1.275.615.55.55 0 0 0 .777-.035.553.553 0 0 0 .142-.4.545.545 0 0 0-.177-.38 3.738 3.738 0 0 0-2.017-.917v-.573a.557.557 0 0 0-.155-.4.553.553 0 0 0-.394-.165.547.547 0 0 0-.393.165.552.552 0 0 0-.156.4v.613a2 2 0 0 0-1.866 2.022c0 1.258 1.121 1.8 1.866 2.043v2.4a2.252 2.252 0 0 1-1.221-.69.55.55 0 0 0-.773-.075.547.547 0 0 0-.075.771 3.4 3.4 0 0 0 2.069 1.112zm2.194-2.161c0 .672-.546.938-1.1 1.039v-2.073c.888.311 1.1.636 1.1 1.034zm-2.962-3.4a.893.893 0 0 1 .768-.9v1.757c-.754-.338-.768-.703-.768-.86z"
          transform="translate(-33.882 86.271)"
          style={{ fill: '#df6d00', stroke: '#df6d00', strokeWidth: '.25px' }}
        />
      </g>
    </svg>
  );
};
