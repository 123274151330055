import React from 'react';

export const AEPLogoIcon = () => {
  return (
    <svg
      xmlns="http://www.w3.org/2000/svg"
      width="72"
      height="36.905"
      viewBox="0 0 72 36.905"
    >
      <g data-name="Group 12140">
        <path
          data-name="Path 25829"
          d="M-351.673 198.619h-2.793l3.5-7.26h2.919l1.614 7.26h-2.793l-.119-.9h-1.994zm2.1-2.629-.136-1.281c-.036-.308-.041-.626-.048-.934h-.1l-.78 2.215z"
          transform="translate(369.185 -185.352)"
          style={{ fill: '#fff' }}
        />
        <path
          data-name="Path 25830"
          d="M-316.37 191.359h2.716l.488 3.813h.067a9 9 0 0 1 .313-.963l1.2-2.85h2.687l.217 7.26h-2.552l.179-3.977h-.048a6.745 6.745 0 0 1-.27.722l-1.488 3.255h-1.539l-.531-3.072a4.748 4.748 0 0 1-.042-.905h-.087c-.058.289-.118.588-.185.876l-.725 3.1h-2.552z"
          transform="translate(341.138 -185.352)"
          style={{ fill: '#fff' }}
        />
        <path
          data-name="Path 25831"
          d="M-269.634 191.359h4.883l-.267 1.984h-2.225l-.093.693h2.032l-.254 1.887h-2.032l-.1.713h2.3l-.267 1.984h-4.96z"
          transform="translate(303.754 -185.352)"
          style={{ fill: '#fff' }}
        />
        <path
          data-name="Path 25832"
          d="M-243.51 191.359h3.188c1.637 0 2.931.693 2.685 2.523a2.021 2.021 0 0 1-1.66 1.887l-.008.058a1.515 1.515 0 0 1 .385.433l1.262 2.359h-3.042l-.842-2.33h-.048l-.314 2.33h-2.581zm2.149 3.216h.183c.434 0 .876-.067.949-.607.075-.558-.311-.626-.764-.626h-.2z"
          transform="translate(283.37 -185.352)"
          style={{ fill: '#fff' }}
        />
        <path
          data-name="Path 25833"
          d="M-210.327 191.359h2.639l-.977 7.26h-2.635z"
          transform="translate(257.478 -185.352)"
          style={{ fill: '#fff' }}
        />
        <path
          data-name="Path 25834"
          d="M-188.641 193.894a2.024 2.024 0 0 0-1.452-.732 1.679 1.679 0 0 0-1.626 1.415 1.238 1.238 0 0 0 1.283 1.416 2.418 2.418 0 0 0 1.6-.674l-.443 2.715a6.209 6.209 0 0 1-1.917.289 3.113 3.113 0 0 1-3.194-3.678 4.455 4.455 0 0 1 4.308-3.813 5.163 5.163 0 0 1 1.732.3z"
          transform="translate(244.305 -184.941)"
          style={{ fill: '#fff' }}
        />
        <path
          data-name="Path 25835"
          d="M-167.132 198.619h-2.793l3.5-7.26h2.919l1.614 7.26h-2.793l-.119-.9h-1.996zm2.1-2.629-.136-1.281c-.035-.308-.041-.626-.047-.934h-.1l-.78 2.215z"
          transform="translate(225.191 -185.352)"
          style={{ fill: '#fff' }}
        />
        <path
          data-name="Path 25836"
          d="M-130.372 191.359h2.533l1.828 4.025h.077a10.664 10.664 0 0 1 .027-1.849l.293-2.176h2.523l-.977 7.26h-2.523l-1.808-3.813h-.077a8.356 8.356 0 0 1-.032 1.454l-.318 2.359h-2.523z"
          transform="translate(195.091 -185.352)"
          style={{ fill: '#fff' }}
        />
        <path
          data-name="Path 25837"
          d="M-357.565 231.479h4.883l-.267 1.984h-2.225l-.093.693h2.032l-.254 1.887h-2.032l-.1.713h2.3l-.267 1.984h-4.96z"
          transform="translate(372.366 -216.657)"
          style={{ fill: '#fff' }}
        />
        <path
          data-name="Path 25838"
          d="M-331.747 231.479h2.639l-.663 4.93h2.215l-.314 2.33h-4.854z"
          transform="translate(352.22 -216.657)"
          style={{ fill: '#fff' }}
        />
        <path
          data-name="Path 25839"
          d="M-306.5 231.479h4.883l-.267 1.984h-2.225l-.093.693h2.032l-.254 1.887h-2.032l-.1.713h2.3l-.267 1.984h-4.96z"
          transform="translate(332.519 -216.657)"
          style={{ fill: '#fff' }}
        />
        <path
          data-name="Path 25840"
          d="M-275.521 234.015a2.025 2.025 0 0 0-1.452-.732 1.679 1.679 0 0 0-1.627 1.417 1.238 1.238 0 0 0 1.283 1.416 2.418 2.418 0 0 0 1.6-.674l-.442 2.716a6.211 6.211 0 0 1-1.917.289 3.113 3.113 0 0 1-3.194-3.678 4.455 4.455 0 0 1 4.308-3.813 5.168 5.168 0 0 1 1.732.3z"
          transform="translate(312.096 -216.247)"
          style={{ fill: '#fff' }}
        />
        <path
          data-name="Path 25841"
          d="M-251.906 231.479h5.548l-.318 2.359-1.445-.067-.669 4.969h-2.639l.669-4.969-1.464.067z"
          transform="translate(289.407 -216.657)"
          style={{ fill: '#fff' }}
        />
        <path
          data-name="Path 25842"
          d="M-226.327 231.478h3.188c1.637 0 2.931.693 2.685 2.523a2.021 2.021 0 0 1-1.66 1.887l-.008.058a1.516 1.516 0 0 1 .385.433l1.262 2.359h-3.044l-.842-2.33h-.048l-.314 2.33h-2.577zm2.149 3.216h.178c.433 0 .876-.067.949-.607.075-.559-.311-.626-.764-.626h-.2z"
          transform="translate(269.962 -216.657)"
          style={{ fill: '#fff' }}
        />
        <path
          data-name="Path 25843"
          d="M-193.143 231.479h2.639l-.977 7.26h-2.639z"
          transform="translate(244.07 -216.657)"
          style={{ fill: '#fff' }}
        />
        <path
          data-name="Path 25844"
          d="M-171.458 234.015a2.025 2.025 0 0 0-1.452-.732 1.679 1.679 0 0 0-1.626 1.415 1.238 1.238 0 0 0 1.283 1.416 2.418 2.418 0 0 0 1.6-.674l-.443 2.716a6.209 6.209 0 0 1-1.917.289 3.113 3.113 0 0 1-3.194-3.678 4.455 4.455 0 0 1 4.308-3.813 5.168 5.168 0 0 1 1.732.3z"
          transform="translate(230.897 -216.247)"
          style={{ fill: '#fff' }}
        />
        <path
          data-name="Path 25845"
          d="M-362.967 271.608h3.342c1.7 0 2.831.867 2.589 2.667a2.619 2.619 0 0 1-2.722 2.407h-1.349l-.294 2.186h-2.543zm2.106 3.245h.26c.462 0 .973-.077 1.053-.664.076-.568-.459-.6-.893-.6h-.25z"
          transform="translate(376.58 -247.969)"
          style={{ fill: '#fff' }}
        />
        <path
          data-name="Path 25846"
          d="M-323.986 274.848a4.365 4.365 0 0 1-4.518 3.726c-2.215 0-3.829-1.4-3.515-3.726a4.446 4.446 0 0 1 4.523-3.765c2.206 0 3.817 1.483 3.51 3.765m-5.38.038a1.13 1.13 0 0 0 1.17 1.4 1.546 1.546 0 0 0 1.546-1.4 1.151 1.151 0 0 0-1.168-1.415 1.578 1.578 0 0 0-1.549 1.415"
          transform="translate(351.701 -247.56)"
          style={{ fill: '#fff' }}
        />
        <path
          data-name="Path 25847"
          d="m-292.763 271.608.332 4.333h.058a9.879 9.879 0 0 1 .4-1.338l1.174-2.995h2.023l.409 4.333h.058a10.306 10.306 0 0 1 .355-1.281l1.143-3.053h2.745l-3.539 7.261h-2.3l-.3-3.948h-.087a7.366 7.366 0 0 1-.283 1.1l-1.116 2.85h-2.244l-1.566-7.261z"
          transform="translate(323.174 -247.969)"
          style={{ fill: '#fff' }}
        />
        <path
          data-name="Path 25848"
          d="M-244.964 271.608h4.883l-.267 1.984h-2.225l-.093.693h2.032l-.254 1.887h-2.032l-.1.713h2.3l-.267 1.984h-4.96z"
          transform="translate(284.505 -247.969)"
          style={{ fill: '#fff' }}
        />
        <path
          data-name="Path 25849"
          d="M-218.84 271.608h3.188c1.638 0 2.931.693 2.685 2.523a2.021 2.021 0 0 1-1.66 1.887l-.008.058a1.513 1.513 0 0 1 .385.433l1.262 2.359h-3.044l-.842-2.331h-.048l-.314 2.331h-2.581zm2.149 3.216h.183c.434 0 .876-.067.949-.607.075-.558-.311-.626-.764-.626h-.2z"
          transform="translate(264.12 -247.969)"
          style={{ fill: '#fff' }}
        />
        <path
          data-name="Path 25850"
          d="M-186.9 271.821a.173.173 0 0 0-.159-.092.15.15 0 0 0-.16.139c0 .076.078.117.14.14l.071.027c.14.054.246.131.25.294a.293.293 0 0 1-.306.3.314.314 0 0 1-.309-.274l.123-.026a.188.188 0 0 0 .194.185.179.179 0 0 0 .176-.191c0-.1-.088-.15-.173-.185l-.068-.029c-.112-.048-.215-.111-.218-.248a.259.259 0 0 1 .282-.253.287.287 0 0 1 .255.148z"
          transform="translate(238.803 -247.975)"
          style={{ fill: '#fff' }}
        />
        <path
          data-name="Path 25851"
          d="m-183.7 271.893-.1.646h-.126l.176-1.02.354.771.318-.771.224 1.02h-.126l-.129-.646-.277.667z"
          transform="translate(236.118 -247.9)"
          style={{ fill: '#fff' }}
        />
        <path
          data-name="Path 25852"
          d="m-384.827 196.822-.3 2.224H-419.3l4.459-33.141h34.178l-.3 2.224h1.894l.552-4.106h-37.97l-4.965 36.905h37.977l.552-4.106z"
          transform="translate(421.454 -164.023)"
          style={{ fill: '#fff' }}
        />
      </g>
    </svg>
  );
};
