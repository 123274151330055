import React from 'react';

export const PendingRates = () => {
  return (
    <svg
      xmlns="http://www.w3.org/2000/svg"
      width="20"
      height="20"
      viewBox="0 0 20 20"
    >
      <path
        d="M84.653 187.25a1.2 1.2 0 0 0 .885-.365A1.213 1.213 0 0 0 85.9 186a1.2 1.2 0 0 0-.365-.885 1.213 1.213 0 0 0-.887-.363 1.2 1.2 0 0 0-.885.365 1.213 1.213 0 0 0-.363.883 1.2 1.2 0 0 0 .365.885 1.213 1.213 0 0 0 .887.362zm5.35 0a1.2 1.2 0 0 0 .885-.365 1.213 1.213 0 0 0 .362-.885 1.2 1.2 0 0 0-.365-.885 1.213 1.213 0 0 0-.885-.365 1.2 1.2 0 0 0-.885.365 1.213 1.213 0 0 0-.363.888 1.2 1.2 0 0 0 .365.885 1.213 1.213 0 0 0 .883.362zm5.325 0a1.2 1.2 0 0 0 .885-.365 1.213 1.213 0 0 0 .362-.887 1.2 1.2 0 0 0-.365-.885 1.213 1.213 0 0 0-.887-.363 1.2 1.2 0 0 0-.885.365 1.213 1.213 0 0 0-.363.888 1.2 1.2 0 0 0 .365.885 1.213 1.213 0 0 0 .888.362zM90.007 196a9.68 9.68 0 0 1-3.888-.787 10.1 10.1 0 0 1-5.332-5.334 10.009 10.009 0 0 1 0-7.783 10.006 10.006 0 0 1 2.15-3.171 10.264 10.264 0 0 1 3.184-2.137 10.009 10.009 0 0 1 7.783 0 9.995 9.995 0 0 1 5.309 5.313 10 10 0 0 1 0 7.781 10.213 10.213 0 0 1-2.138 3.177 10.1 10.1 0 0 1-3.175 2.15 9.672 9.672 0 0 1-3.893.791zm.006-1.5a8.493 8.493 0 1 0-6.025-2.487 8.165 8.165 0 0 0 6.024 2.487zM90 186z"
        transform="translate(-80 -176)"
        style={{ fill: '#4a4a4a' }}
      />
    </svg>
  );
};
