import { CaretDown } from 'assets/images/CaretIcon/CaretDown/CaretDown';
import { CaretUp } from 'assets/images/CaretIcon/CaretUp/CaretUp';
import { CollapseIcon } from 'assets/images/CollapseIcon/CollapseIcon';
import { ExpandIcon } from 'assets/images/ExpandIcon/ExpandIcon';
import { ViewIcon } from 'assets/images/ViewIcon/ViewIcon';
import ClipLoader from 'react-spinners/ClipLoader';
import {
  displayError,
  displaySuccess,
} from 'components/common/Alert/ToastAlert';
import { ROUTES_STRING } from 'constants/routes';
import { ModuleACL } from 'enums/entitlements.ts';
import { SERVICE_TYPES } from 'enums/serviceTypes.ts';
import { PurchaseOrderStatus } from 'enums/statuses.ts';
import _ from 'lodash';
import { Fragment, useEffect, useState } from 'react';
import { Button, Form, Tab, Table, Tabs } from 'react-bootstrap';
import { useDispatch, useSelector } from 'react-redux';
import { useNavigate } from 'react-router-dom';
import {
  updatePurchaseOrder,
  pendingRatesResponsePurchaseOrder,
  setResponseState,
} from 'store/slices/purchaseOrder/purchaseOrderDetailsSlice';
import { setPOPagination } from 'store/slices/purchaseOrder/purchaseOrderSlice';

import { IsAllowedACL } from 'utils/aclHelper';
import { ResourceDetailsModal } from './PendingRatesResource';
import './PurchaseOrderPendingRates.scss';

const PurchaseOrderPendingRates = () => {
  const navigate = useNavigate();
  const purchaseOrderDetail = useSelector(state => state.purchaseOrderDetails);

  const dispatch = useDispatch();
  const [expandedRows, setExpandedRows] = useState([]);
  const [key, setKey] = useState('unionRates');
  const tabs = [{ key: 'unionRates', value: 'Union Rates' }];
  const [expandState, setExpandState] = useState({});
  const [resourceDetails, setResourceDetails] = useState({});
  const [modalShow, setModalShow] = useState(false);
  const [saveInProgress, setSaveInProgress] = useState(false);
  const [item, setItem] = useState({ kindOfSelection: '', another: 'another' });
  // const { kindOfSelection } = item;
  const [isUpdate, setIsUpdate] = useState(false);
  const [rejectComments, setRejectComments] = useState({});
  const [loader, setLoader] = useState(false);
  const user = useSelector(state => state.auth);
  const [ratesPendingReviewList, setRatesPendingReviewList] = useState([]);
  const pagination = useSelector(state => state.purchaseOrder.pagination);
  const sortOrder = useSelector(
    state => state.purchaseOrder.pagination.sortOrder,
  );

  const headers = [
    { key: '', value: '' },
    { key: 'ratesSch', value: 'Rates Sch' },
    { key: 'ratesSchRev', value: 'Rates Sch Rev' },
    { key: 'ratesSchCode', value: 'Rates Sch Code' },
    { key: 'ratesSchStatus', value: 'Rates Sch Status' },
    { key: 'shift', value: 'Shift' },
    { key: 'description', value: 'Description' },
    { key: 'effectiveStartDate', value: 'Effective From' },
    { key: 'effectiveToDate', value: 'Effective To' },
    { key: 'markupNo', value: 'Markup/No' },
  ];

  const nestedHeader = [
    'Code',
    'Description',
    'Local',
    'Agmt Seq No',
    /*
    'Small Tools Adder',
    'Effective Start Date',
    'Effective End Date',
    */
    '',
    '',
    '',
    '',
  ];

  interface IShift {
    ST: string;
    PT: string;
    OT: string;
    DT: string;
    adjustHourly: string;
    stTaxableFradges: string;
    stNonTaxableFradges: string;
    ptTaxableFradges: string;
    ptNonTaxableFradges: string;
    modifierCode: string;
    cap: string;
    copied: string;
  }
  interface INestedRatesPendingReview {
    id: string;
    ponum: string;
    poid: string;
    porateid: string;
    code: string;
    description: string;
    local: string;
    amtseqNo: string;
    smallToolsadder: string;
    effectiveStartDate: string;
    effectiveEndDate: string;
    // Details
    LaborType: string;
    effectiveFrom: string;
    effectiveTo: string;
    craftHourlyAdderAmount: string;
    baseHourlyWage: string;
    shifts: IShift[];
  }
  interface IRatesPendingReview {
    id: string;
    ponum: string;
    poid: string;
    ratesSch: string;
    ratesSchRev: string;
    ratesSchCode: string;
    ratesSchStatus: string;
    shift: string;
    description: string;
    effectiveStartDate: string;
    effectiveToDate: string;
    markupNo: string;
    vndrrejt: string;
    vndrrejcomments: string;
    vndracpt: string;
    agree: string;
    nestedRatesPendingReview: INestedRatesPendingReview[];
  }

  const isNull = field => {
    if (field === '' || field === null || field === undefined) {
      return true;
    } else {
      return false;
    }
  };

  const porates = purchaseOrderDetail[SERVICE_TYPES.RATES];
  const polaborrates = purchaseOrderDetail[SERVICE_TYPES.LABOR];

  useEffect(() => {
    if (porates != null) {
      // level-1 (porates)
      let finalRateList = [];
      porates.map(prLevel1 => {
        if (!isNull(prLevel1.local) && prLevel1.vndracpt != 1) {
          // set: first-level
          let ratesPendingReview: IRatesPendingReview = {};
          ratesPendingReview.id = prLevel1.porateid;
          ratesPendingReview.ponum = prLevel1.ponum;
          ratesPendingReview.poid = prLevel1.poid;
          ratesPendingReview.ratesSch = prLevel1.ratesch;
          ratesPendingReview.ratesSchRev = prLevel1.rateschrevno;
          ratesPendingReview.ratesSchCode = prLevel1.rateschecode;
          ratesPendingReview.ratesSchStatus = prLevel1.statuscode;
          ratesPendingReview.shift = prLevel1.shift;
          ratesPendingReview.description = prLevel1.description;
          ratesPendingReview.effectiveStartDate = prLevel1.effectivefromdt;
          ratesPendingReview.effectiveToDate = prLevel1.effectivetodt;
          ratesPendingReview.markupNo = prLevel1.mkupno;

          ratesPendingReview.vndrrejt = prLevel1.vndrrejt;
          ratesPendingReview.vndrrejcomments = prLevel1.vndrrejcomments;
          ratesPendingReview.vndracpt = prLevel1.vndracpt;
          ratesPendingReview.agree = null;

          // Load (agree/disagree)
          if (isNull(ratesPendingReview.agree)) {
            if (ratesPendingReview.vndracpt !== ratesPendingReview.vndrrejt) {
              if (!isNull(ratesPendingReview.vndracpt)) {
                ratesPendingReview.agree =
                  ratesPendingReview.vndracpt === '1' ? '1' : '0';
              }
              if (isNull(ratesPendingReview.agree)) {
                if (!isNull(ratesPendingReview.vndrrejt)) {
                  ratesPendingReview.agree =
                    ratesPendingReview.vndrrejt === '1' ? '0' : '1';
                }
              }
              if (ratesPendingReview.agree === '0') {
                if (ratesPendingReview.vndrrejcomments !== '') {
                  updateRejectComments(
                    ratesPendingReview.id,
                    ratesPendingReview.vndrrejcomments,
                  );
                }
              }
            }
          }

          // level-2 (polaborrates key: poratenum for relationship)
          ratesPendingReview.nestedRatesPendingReview = [];
          const level2List = polaborrates.filter(
            poLaborRate =>
              poLaborRate.poid === prLevel1.poid &&
              poLaborRate.rateschedule === prLevel1.ratesch &&
              poLaborRate.ponum === prLevel1.ponum &&
              Math.round(poLaborRate.poratenum) ===
                Math.round(prLevel1.poratenum) &&
              Math.round(poLaborRate.poraterev) ===
                Math.round(prLevel1.poraterev) &&
              !isNull(poLaborRate.local),
          );
          level2List.map(prLevel2 => {
            // second-table-header
            const nestedRatesPendingReview: INestedRatesPendingReview = {};
            nestedRatesPendingReview.id = prLevel2.polaborrateid;
            nestedRatesPendingReview.ponum = prLevel2.ponum;
            nestedRatesPendingReview.poid = prLevel2.poid;
            nestedRatesPendingReview.porateid = prLevel1.porateid;
            nestedRatesPendingReview.code = prLevel2.restype;
            nestedRatesPendingReview.description = prLevel2.description;
            nestedRatesPendingReview.local = prLevel2.local;
            nestedRatesPendingReview.amtseqNo = prLevel2.agmtseqno;
            nestedRatesPendingReview.smallToolsadder = prLevel2.smalltoolsadder;
            nestedRatesPendingReview.effectiveStartDate =
              prLevel2.effectivefromdt;
            nestedRatesPendingReview.effectiveEndDate = prLevel2.effectivetodt;

            // Details
            nestedRatesPendingReview.LaborType = prLevel2.labortype;
            nestedRatesPendingReview.effectiveFrom = prLevel2.effectivefromdt;
            nestedRatesPendingReview.effectiveTo = prLevel2.expiredate;
            nestedRatesPendingReview.craftHourlyAdderAmount =
              prLevel2.hrlyadder;
            nestedRatesPendingReview.baseHourlyWage = prLevel2.basehrlywage;
            nestedRatesPendingReview.shifts = [];

            // shift-1
            const shift1 = {};
            shift1.ST = prLevel2.stloadedrate;
            shift1.PT = prLevel2.ptloadedrate;
            shift1.OT = prLevel2.thloadedrate;
            shift1.DT = prLevel2.dtloadedrate;
            shift1.adjustHourly = prLevel2.adjustedhrlywage;
            shift1.stTaxableFradges = prLevel2.sttaxablefringes;
            shift1.stNonTaxableFradges = prLevel2.stnontaxfringes;
            shift1.ptTaxableFradges = prLevel2.pttaxablefringes;
            shift1.ptNonTaxableFradges = prLevel2.ptnontaxfringes;
            shift1.modifierCode = 'TBD';
            shift1.cap = 'TBD';
            shift1.copied = 'TBD';
            nestedRatesPendingReview.shifts.push(shift1);

            // shift-2
            const shift2 = {};
            shift2.ST = prLevel2.scndshftstloadedrate;
            shift2.PT = prLevel2.scndshftptloadedrate;
            shift2.OT = prLevel2.scndshftthloadedrate;
            shift2.DT = prLevel2.scndshftdtloadedrate;
            shift2.adjustHourly = prLevel2.scndshftadjstdhrlywage;
            shift2.stTaxableFradges = prLevel2.scndshftsttaxablefrngs;
            shift2.stNonTaxableFradges = prLevel2.scndshftstnontaxfrngs;
            shift2.ptTaxableFradges = prLevel2.scndshftpttaxablefrngs;
            shift2.ptNonTaxableFradges = prLevel2.scndshftptnontaxfrngs;
            shift2.modifierCode = 'TBD';
            shift2.cap = 'TBD';
            shift2.copied = 'TBD';
            nestedRatesPendingReview.shifts.push(shift2);

            // shift-3
            const shift3 = {};
            shift3.ST = prLevel2.thrdshftstloadedrate;
            shift3.PT = prLevel2.thrdshftptloadedrate;
            shift3.OT = prLevel2.thrdshftthloadedrate;
            shift3.DT = prLevel2.thrdshftdtloadedrate;
            shift3.adjustHourly = prLevel2.thrdshftadjstdhrlywage;
            shift3.stTaxableFradges = prLevel2.thrdshftsttaxablefrngs;
            shift3.stNonTaxableFradges = prLevel2.thrdshftstnontaxfrngs;
            shift3.ptTaxableFradges = prLevel2.thrdshftpttaxablefrngs;
            shift3.ptNonTaxableFradges = prLevel2.thrdshftptnontaxfrngs;
            shift3.modifierCode = 'TBD';
            shift3.cap = 'TBD';
            shift3.copied = 'TBD';
            nestedRatesPendingReview.shifts.push(shift3);

            // Add level-2 in Level-1
            ratesPendingReview.nestedRatesPendingReview.push(
              nestedRatesPendingReview,
            );
          });
          finalRateList.push(ratesPendingReview);
        }
      });
      setRatesPendingReviewList(finalRateList);
    }
  }, [purchaseOrderDetail.poid]);

  const handleChangeAgreeDisagree = (event, userId) => {
    event.persist();
    setItem(prevState => ({
      ...prevState,
      kindOfSelection: event.target.value,
    }));
    const isAgree = event.target.value === '1' ? true : false;
    const aux = ratesPendingReviewList.findIndex(item => item.id === userId);
    if (aux !== -1) {
      if (isAgree) {
        ratesPendingReviewList[aux].agree = '1';
        ratesPendingReviewList[aux].vndracpt = '1';
        ratesPendingReviewList[aux].vndrrejt = '0';
        //ratesPendingReviewList[aux].vndrrejcomments = '';
      } else {
        ratesPendingReviewList[aux].agree = '0';
        ratesPendingReviewList[aux].vndracpt = '0';
        ratesPendingReviewList[aux].vndrrejt = '1';
      }
    }

    setRatesPendingReviewList(ratesPendingReviewList);
    const iUpd = ratesPendingReviewList.filter(item => isNull(item.agree));
    iUpd?.length > 0 ? setIsUpdate(false) : setIsUpdate(true);
    dispatch(setResponseState(false));
  };

  const handleExpandRow = (event, userId) => {
    const currentExpandedRows = expandedRows;
    const isRowExpanded = currentExpandedRows.includes(userId);

    let obj = {};
    isRowExpanded ? (obj[userId] = false) : (obj[userId] = true);
    setExpandState(obj);

    // If the row is expanded, we are here to hide it. Hence remove
    // it from the state variable. Otherwise add to it.
    const newExpandedRows = isRowExpanded
      ? currentExpandedRows.filter(id => id !== userId)
      : currentExpandedRows.concat(userId);
    setExpandedRows(newExpandedRows);
  };

  const handleRejectComments = (event, id) => {
    const name = event.target.name;
    const value = event.target.value;
    updateRejectComments(name, value);
  };

  const updateRejectComments = (poId, comments) => {
    setRejectComments({ ...rejectComments, [poId]: comments });
  };

  const handleUpdate = event => {
    setLoader(true);
    try {
      event.preventDefault();
      event.persist();
      const payLoadData = _.cloneDeep(purchaseOrderDetail);
      const originalPayLoad = payLoadData.originalData;
      const currentDateTime = new Date();

      // Update: porates accept/reject in original object
      ratesPendingReviewList.map(rateItem => {
        let aux = payLoadData[SERVICE_TYPES.RATES].findIndex(
          porate => porate.porateid === rateItem.id,
        );
        if (aux !== -1) {
          let isUpdated = false;

          if (
            payLoadData[SERVICE_TYPES.RATES][aux].vndracpt !== rateItem.vndracpt
          ) {
            payLoadData[SERVICE_TYPES.RATES][aux].vndracpt = rateItem.vndracpt;
            isUpdated = true;
          }

          if (
            payLoadData[SERVICE_TYPES.RATES][aux].vndrrejt !== rateItem.vndrrejt
          ) {
            payLoadData[SERVICE_TYPES.RATES][aux].vndrrejt = rateItem.vndrrejt;
            isUpdated = true;
          }

          // Is disagree?
          if (rateItem.vndrrejt === '1') {
            let rjtComments = rejectComments[rateItem.id];
            if (
              payLoadData[SERVICE_TYPES.RATES][aux].vndrrejcomments !==
              rjtComments
            ) {
              payLoadData[SERVICE_TYPES.RATES][aux].vndrrejcomments =
                rjtComments;
              isUpdated = true;
            }
          } else {
            if (
              !_.isEmpty(payLoadData[SERVICE_TYPES.RATES][aux].vndrrejcomments)
            ) {
              payLoadData[SERVICE_TYPES.RATES][aux].vndrrejcomments = '';
              isUpdated = true;
            }
          }

          // The original object was updated?
          if (isUpdated) {
            payLoadData[SERVICE_TYPES.RATES][aux].updatedDate = currentDateTime;
            payLoadData[SERVICE_TYPES.RATES][aux].updatedBy = user.userId;
          }
        }
      });

      // Update: original object
      originalPayLoad.porates = payLoadData[SERVICE_TYPES.RATES];

      // Check: need to update PO status?
      let iReject = 0;
      let iAccept = 0;
      let rjtComments = '';
      let iCount = 0;
      let bStatusChange = false;
      originalPayLoad.porates.forEach(porate => {
        if (!isNull(porate.local)) {
          if (porate.vndrrejt === '1') {
            ++iReject;
            rjtComments = porate.vndrrejcomments;
          } else if (porate.vndracpt === '1') {
            ++iAccept;
          }
          ++iCount;
        }
      });

      // Is There any reject record?
      let worklog = {};
      if (iReject > 0) {
        worklog = {
          ponum: originalPayLoad.ponum,
          logtype: 'TYUI',
          createdBy: user.userId,
          createdDate: currentDateTime,
          clientviewable: 'YES',
          description: 'Reject Rate ' + rjtComments,
          siteid: originalPayLoad.siteid,
          orgid: originalPayLoad.organization,
          descriptionLongdescription: 'Reject Rate ' + rjtComments,
          recordkey: originalPayLoad.ponum,
          class: 'PO',
          revisionnum: originalPayLoad.revisionnum,
          poid: originalPayLoad.poid,
          enteredBy: user.userId,
          enteredDate: currentDateTime,
        };
        if (isNull(originalPayLoad.poworklogs)) {
          originalPayLoad.poworklogs = [];
        }
        originalPayLoad.poworklogs.push(worklog);
        originalPayLoad.caastatus =
          PurchaseOrderStatus.APPROVED_PENDING_RATES_REVIEW_SUBMITTED;
        bStatusChange = true;

        // All records were accept?
      } else {
        if (iAccept > 0 && iCount > 0 && iAccept === iCount) {
          worklog = {
            ponum: originalPayLoad.ponum,
            logtype: 'TYUI',
            createdBy: user.userId,
            createdDate: currentDateTime,
            clientviewable: 'YES',
            description: 'Accept Rate',
            siteid: originalPayLoad.siteid,
            orgid: originalPayLoad.organization,
            descriptionLongdescription: 'Accept Rate',
            recordkey: originalPayLoad.ponum,
            class: 'PO',
            revisionnum: originalPayLoad.revisionnum,
            poid: originalPayLoad.poid,
            enteredBy: user.userId,
            enteredDate: currentDateTime,
          };
          if (isNull(originalPayLoad.poworklogs)) {
            originalPayLoad.poworklogs = [];
          }
          originalPayLoad.poworklogs.push(worklog);
          originalPayLoad.caastatus = PurchaseOrderStatus.APPROVED;
        }
        bStatusChange = true;
      }

      // Updating db/integration
      setIsUpdate(false);
      setSaveInProgress(true);
      if (bStatusChange) {
        dispatch(
          pendingRatesResponsePurchaseOrder({
            poId: payLoadData.poid,
            data: originalPayLoad,
            callback: async data => {
              if (data?.data?.error) {
                setLoader(false);
              } else {
                setLoader(false);
                displaySuccess('Successfully saved the Purchase Order');
                navigate(`/${ROUTES_STRING.purchaseOrders}`);
              }
            },
          }),
        );
      }
      setSaveInProgress(false);
    } catch (err) {
      onError(err);
    }
  };

  const onError = data => {
    displayError('Something went wrong!');
  };

  const filters = [
    { label: 'Choose an option', value: 'chooseAnOption' },
    { label: 'Timesheet #', value: 'timesheetNo' },
    { label: 'Contractor', value: 'contractor' },
    { label: 'Contractor Type', value: 'contractorType' },
    { label: 'Week end date', value: 'weekEndDate' },
    { label: 'OPCO', value: 'opco' },
    { label: 'State', value: 'state' },
    { label: 'Work Area', value: 'workArea' },
    { label: 'PO #', value: 'poNumber' },
    { label: 'Status', value: 'status' },
    { label: 'Work Order', value: 'workOrder' },
    { label: 'Crew Type', value: 'crewType' },
  ];

  const sortData = (data, sortBy, sortOrder) => {
    if (sortOrder === 'asc') {
      let sortedValue = data
        ?.slice()
        .sort((option1, option2) =>
          String(option1[sortBy])
            .trim()
            .localeCompare(String(option2[sortBy]).trim()),
        );
      return sortedValue;
    } else {
      let sortedValue = data
        ?.slice()
        .sort((option1, option2) =>
          String(option2[sortBy])
            .trim()
            .localeCompare(String(option1[sortBy]).trim()),
        );
      return sortedValue;
    }
  };

  const handleSorting = (tab, sortBy, sortOrder) => {
    dispatch(
      setPOPagination({
        ...pagination,
        currentPage: 1,
        sortBy,
        sortOrder,
      }),
    );
    const sortedRates = sortData(ratesPendingReviewList, sortBy, sortOrder);
    setRatesPendingReviewList(sortedRates);
  };

  return (
    <>
      {loader ? (
        <ClipLoader
          color="#009cde"
          cssOverride={{
            top: '50vh',
            left: '50vw',
            position: 'absolute',
            zIndex: 1,
          }}
          size={50}
          aria-label="Loading Spinner"
          data-testid="loader"
        />
      ) : (
        <Tabs id="poPendingRatesPage" activeKey={key} onSelect={k => setKey(k)}>
          {tabs.map((tab, index) => (
            <Tab
              eventKey={tab.key}
              title={tab.value}
              key={index}
              tabClassName="tab-item"
            >
              <Form onSubmit={handleUpdate}>
                <div className="tableContainer">
                  <Table hover responsive striped bordered={false}>
                    <thead>
                      <tr>
                        {headers.map((header, idx) => (
                          <th key={idx}>
                            {sortOrder === 'asc' && (
                              <div
                                id={header.key}
                                onClick={e =>
                                  handleSorting(tab.key, header.key, 'desc')
                                }
                              >
                                {header.value}
                                <CaretUp style={{ marginLeft: '12px' }} />
                              </div>
                            )}

                            {sortOrder === 'desc' && (
                              <div
                                id={header.key}
                                onClick={e =>
                                  handleSorting(tab.key, header.key, 'asc')
                                }
                              >
                                {header.value}
                                <CaretDown style={{ marginLeft: '12px' }} />
                              </div>
                            )}
                          </th>
                        ))}
                      </tr>
                    </thead>
                    <tbody>
                      {ratesPendingReviewList.map(
                        (row: IRatesPendingReview, indx) => (
                          <Fragment>
                            <tr key={indx} className="tRow table-hover-row">
                              <td>
                                <span
                                  className="expand"
                                  onClick={e => handleExpandRow(e, row.id)}
                                >
                                  {expandState[row.id] ? (
                                    <ExpandIcon />
                                  ) : (
                                    <CollapseIcon />
                                  )}
                                </span>
                              </td>
                              <td>{row.ratesSch}</td>
                              <td>{row.ratesSchRev}</td>
                              <td>{row.ratesSchCode}</td>
                              <td>{row.ratesSchStatus}</td>
                              <td>{row.shift}</td>
                              <td>{row.description}</td>
                              <td>{row.effectiveStartDate}</td>
                              <td>{row.effectiveToDate}</td>
                              <td>{row.markupNo}</td>
                            </tr>
                            <>
                              {expandedRows.includes(row.id) ? (
                                <tr className="expandTableBg">
                                  <td></td>
                                  <td colSpan={9}>
                                    <div>
                                      <Table
                                        responsive
                                        striped
                                        bordered={false}
                                        className="expandTable"
                                      >
                                        <thead>
                                          <tr>
                                            {nestedHeader.map((header, idx) => (
                                              <th key={idx}>{header}</th>
                                            ))}
                                          </tr>
                                        </thead>
                                        <tbody>
                                          {row.nestedRatesPendingReview.map(
                                            user => (
                                              <>
                                                <tr key={user.id}>
                                                  <td>{user.code}</td>
                                                  <td>{user.description}</td>
                                                  <td>{user.local}</td>
                                                  <td>{user.amtseqNo}</td>

                                                  <td></td>
                                                  <td></td>
                                                  <td></td>

                                                  <td
                                                    className="reviewDetails"
                                                    onClick={() => {
                                                      setModalShow(true);
                                                      setResourceDetails(user);
                                                    }}
                                                  >
                                                    <span className="expand">
                                                      {' '}
                                                      <ViewIcon />
                                                    </span>
                                                    Details
                                                  </td>
                                                </tr>
                                              </>
                                            ),
                                          )}
                                        </tbody>
                                      </Table>
                                      <div>
                                        {IsAllowedACL(
                                          user,
                                          ModuleACL.PURCHASEORDER,
                                          'can_submit_pend_rates_purchaseorder',
                                        ) && (
                                          <Fragment>
                                            <Form.Group
                                              controlId="kindOfSelection"
                                              className="responseForm"
                                            >
                                              <Form.Label className="responseLabel">
                                                Response<span>*</span>:
                                              </Form.Label>
                                              <div>
                                                <Form.Check
                                                  inline
                                                  value="1"
                                                  disabled={
                                                    !isUpdate &&
                                                    !saveInProgress &&
                                                    purchaseOrderDetail.status ===
                                                      PurchaseOrderStatus.APPROVED_PENDING_RATES_REVIEW_SUBMITTED
                                                  }
                                                  type="radio"
                                                  aria-label="radio 1"
                                                  label="Agree"
                                                  onChange={e =>
                                                    handleChangeAgreeDisagree(
                                                      e,
                                                      row.id,
                                                    )
                                                  }
                                                  checked={row.agree === '1'}
                                                  className="input1"
                                                />
                                                <Form.Check
                                                  inline
                                                  value="0"
                                                  disabled={
                                                    !isUpdate &&
                                                    !saveInProgress &&
                                                    purchaseOrderDetail.status ===
                                                      PurchaseOrderStatus.APPROVED_PENDING_RATES_REVIEW_SUBMITTED
                                                  }
                                                  type="radio"
                                                  aria-label="radio 2"
                                                  label="Disagree"
                                                  onChange={e =>
                                                    handleChangeAgreeDisagree(
                                                      e,
                                                      row.id,
                                                    )
                                                  }
                                                  checked={row.agree === '0'}
                                                  className="input1"
                                                />
                                                {row.agree === '0' && (
                                                  <Form.Control
                                                    name={row.id}
                                                    disabled={
                                                      !isUpdate &&
                                                      !saveInProgress &&
                                                      purchaseOrderDetail.status ===
                                                        PurchaseOrderStatus.APPROVED_PENDING_RATES_REVIEW_SUBMITTED
                                                    }
                                                    maxLength={100}
                                                    type="text"
                                                    value={
                                                      purchaseOrderDetail.status ===
                                                      PurchaseOrderStatus.PENDING_INITIAL_REVIEW_DECLINED
                                                        ? row.vndrrejcomments
                                                        : rejectComments[row.id]
                                                    }
                                                    placeholder="Please Enter disagree comments"
                                                    onChange={e =>
                                                      handleRejectComments(
                                                        e,
                                                        row.id,
                                                      )
                                                    }
                                                  />
                                                )}
                                              </div>
                                            </Form.Group>
                                          </Fragment>
                                        )}
                                      </div>
                                    </div>
                                  </td>
                                </tr>
                              ) : null}
                            </>
                          </Fragment>
                        ),
                      )}
                    </tbody>
                  </Table>
                </div>
                {purchaseOrderDetail.status !==
                  PurchaseOrderStatus.APPROVED_PENDING_RATES_REVIEW_SUBMITTED && (
                  <div className="submitButtonDiv">
                    {IsAllowedACL(
                      user,
                      ModuleACL.PURCHASEORDER,
                      'can_submit_pend_rates_purchaseorder',
                    ) && (
                      <Fragment>
                        <Button
                          variant="primary"
                          disabled={
                            (!isUpdate && !saveInProgress) ||
                            purchaseOrderDetail.status ===
                              PurchaseOrderStatus.APPROVED_PENDING_RATES_REVIEW_SUBMITTED
                          }
                          type="submit"
                        >
                          Submit
                        </Button>
                      </Fragment>
                    )}
                  </div>
                )}
              </Form>
            </Tab>
          ))}
        </Tabs>
      )}

      <ResourceDetailsModal
        show={modalShow}
        setShowModal={setModalShow}
        resourceDetails={resourceDetails}
      />
    </>
  );
};

export { PurchaseOrderPendingRates };
