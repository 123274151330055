import _ from 'lodash';
import queryString from 'query-string';
import { http } from 'services/helpers/BaseApi.ts';
import { tracking } from './helpers/Tracking.ts';
import { OperationEnum } from 'enums/operation.ts';

const rootURI = '/purchase-orders';

export const retrievePurchaseOrders = async qParams => {
  const queryParams = {
    page: qParams.currentPage,
    limit: qParams.itemsPerPage,
    sortBy: _.isEmpty(qParams.sortBy) ? 'ponum' : qParams.sortBy,
    sortOrder: qParams.sortOrder,
    ponum: qParams.ponum.toString(),
    siteid: qParams.siteid.toString(),
    vendor: qParams?.vendor.toString(),
    vendorid: qParams?.vendorid.toString(),
    caastatus: qParams.caastatus.toString(),
    search: qParams?.search,
    contractrefnum: qParams?.contractrefnum,
    forInvoice: qParams?.forInvoice,
  };

  try {
    return await http.get(`${rootURI}?${queryString.stringify(queryParams)}`);
  } catch (error) {
    return error;
  }
};

export const retrievePurchaseOrderDetails = async poId => {
  try {
    return await http.get(`${rootURI}/${poId}`);
  } catch (error) {
    return error;
  }
};

export const retrievePurchasOrderInvoice = async poId => {
  try {
    return await http.get(`/invoices/?poid=${poId}`);
  } catch (error) {
    return error;
  }
};

export const addInvoiceSignature = async payload => {
  try {
    return await http.post(`/purchase-orders/${payload.poId}/work-auth-doc`, {
      esign: payload.esign,
    });
  } catch (error) {
    return error;
  }
};

export const addPOContacts = async payload => {
  try {
    // @ts-ignore
    tracking.setTrackingData(payload.data, 'poId', OperationEnum.UPDATE);
    return await http.post(
      `/purchase-orders/${payload.poId}/pocontacts`,
      payload.data,
    );
  } catch (error) {
    return error;
  }
};

export const requestVendorCompletionForm = async payload => {
  try {
    return await http.get(
      `/purchase-orders/${payload.poId}/completion-form-request`,
      payload.data,
    );
  } catch (error) {
    return error;
  }
};

export const completionFormSubmitRequest = async payload => {
  try {
    // @ts-ignore
    tracking.setTrackingData(payload.data, 'poId', OperationEnum.UPDATE);
    return await http.post(
      `/purchase-orders/${payload.poId}/completion-form-submit`,
      payload,
    );
  } catch (error) {
    return error;
  }
};

export const approveVendorSubmitionForm = async payload => {
  try {
    return await http.get(
      `/purchase-orders/${payload.poId}/completion-form-approve`,
      payload,
    );
  } catch (error) {
    return error;
  }
};

export const updatePOContacts = async payload => {
  try {
    // @ts-ignore
    tracking.setTrackingData(payload.data, 'poId', OperationEnum.UPDATE);
    return await http.put(
      `/purchase-orders/${payload.poId}/pocontacts/${payload.poContactID}`,
      payload.data,
    );
  } catch (error) {
    return error;
  }
};

export const deletePOContacts = async payload => {
  try {
    return await http.delete(
      `/purchase-orders/${payload.poId}/pocontacts/${payload.poContactID}`,
    );
  } catch (error) {
    return error;
  }
};

export const retreiveSelectedPurchaseOrderList = async data => {
  let queryParams = {
    limit: 500,
  };
  if (data.hasOwnProperty('search')) {
    queryParams.search = data.search;
  }

  let url;
  if (data.columnname !== 'vendor' && data.columnname !== 'vendorid') {
    url = `${rootURI}/distinct/records/${
      data.columnname
    }?${queryString.stringify(queryParams)}`;
  } else {
    if (data?.vendor && !_.isEmpty(data.vendor)) {
      queryParams.vendor = data.vendor.join(',');
    }
    url = `/vendors/list?${queryString.stringify(queryParams)}`;
  }

  try {
    return await http.get(url);
  } catch (error) {
    return error;
  }
};

export const retrievePORates = async data => {
  const queryParams = {
    effectivefromdt: data.workStartDate,
    effectivetodt: data.workEndDate,
  };

  if (data.poId !== '' || data.poId !== null || data.poId !== undefined) {
    try {
      return await http.get(
        `${rootURI}/${data.poId}/porates?${queryString.stringify(queryParams)}`,
      );
    } catch (error) {
      return error;
    }
  }
};

export const retrieveTimesheetDates = async poId => {
  if (poId !== '' || poId !== null || poId !== undefined) {
    try {
      return await http.get(`${rootURI}/${poId}/timesheets/dates`);
    } catch (error) {
      return error;
    }
  }
};

export const retrieveInvoiceDates = async poId => {
  if (poId !== '' || poId !== null || poId !== undefined) {
    try {
      return await http.get(`${rootURI}/${poId}/invoice/dates`);
    } catch (error) {
      return error;
    }
  }
};

export const retrieveInvoiceData = async data => {
  const poId = data.poId;
  const queryParams = {
    timesheetIds: data.timesheetIds,
  };
  if (data?.validateAccounts) {
    queryParams.validateAccounts = true;
  }
  if (poId) {
    try {
      return await http.get(
        `${rootURI}/${poId}/calculateInvoice?${queryString.stringify(
          queryParams,
        )}`,
      );
    } catch (error) {
      return error;
    }
  }
};

export const retrieveEquipmentIdList = async poId => {
  if (poId !== '' || poId !== null || poId !== undefined) {
    try {
      return await http.get(`${rootURI}/${poId}/equipmentids`);
    } catch (error) {
      return error;
    }
  }
};

export const updatePurchaseOrder = async payload => {
  try {
    // @ts-ignore
    tracking.setTrackingData(payload.data, 'poId', OperationEnum.UPDATE);
    return await http.put(`${rootURI}/${payload.poId}`, payload.data);
  } catch (error) {
    return error;
  }
};

export const modifyPurchaseOrder = async payload => {
  try {
    // @ts-ignore
    tracking.setTrackingData(payload.data, 'poId', OperationEnum.UPDATE);
    return await http.patch(`${rootURI}/${payload.poId}`, payload.data);
  } catch (error) {
    return error;
  }
};

export const initialReviewResponsePurchaseOrder = async payload => {
  try {
    // @ts-ignore
    tracking.setTrackingData(payload.data, 'poId', OperationEnum.UPDATE);
    return await http.put(
      `${rootURI}/${payload.poId}/initial-review-response`,
      payload.data,
    );
  } catch (error) {
    return error;
  }
};

export const pendingRatesResponsePurchaseOrder = async payload => {
  try {
    // @ts-ignore
    tracking.setTrackingData(payload.data, 'poId', OperationEnum.UPDATE);
    return await http.put(
      `${rootURI}/${payload.poId}/pending-rates-response`,
      payload.data,
    );
  } catch (error) {
    return error;
  }
};
