import { CheckIcon } from 'assets/images/CheckIcon/CheckIcon';
import { toast } from 'react-toastify';
import './ToastAlert.scss';

const Msg = ({ closeToast, toastProps, message }) => <div>{message}</div>;

export const displaySuccess = message => {
  toast.success(<Msg message={message} />, { icon: CheckIcon });
};

export const displayCompletionFormStatus = message => {
  toast.warning(<Msg message={message} />, { className: 'warning-toast' });
};

export const displayError = message => {
  toast.error(<Msg message={message} />);
};
