import { createSlice } from '@reduxjs/toolkit';

const initialState = {
  isLoading: false,
  error: false,
  errorResponse: {},
  items: [],
  newAudit: false,
  pagination: {
    totalItems: 0,
    currentPage: 1,
    itemsPerPage: 10,
    sortBy: '',
    sortOrder: 'asc',
  },
  auditList: [],
  contractors: [],
  auditors: [],
  circuits: [],
  circuitList: [],
  auditFormData: {},
  savedAuditData: {},
  auditSaved: false,
};

const auditSlice = createSlice({
  name: 'auditSlice',
  initialState,
  reducers: {
    fetchAuditList(state, action) {
      state.isLoading = false;
    },

    fetchAuditDetail(state, action) {
      state.isLoading = false;
    },

    fetchContractors(state) {
      state.isLoading = false;
    },

    fetchAuditors(state) {
      state.isLoading = false;
    },

    fetchCircuits(state) {
      state.isLoading = false;
    },

    postAudit(state, action) {
      state.isLoading = false;
      state.auditFormData = {};
    },

    updateAudit(state, action) {
      state.isLoading = false;
    },

    setNewAudit(state, action) {
      const stateValues = { ...state };
      stateValues.newAudit = action.payload.newAudit;
      return { ...stateValues };
    },

    setAuditData(state, action) {
      const auditFormData = { ...state.auditFormData, ...action.payload };
      return { ...state, auditFormData };
    },

    setSavedAuditData(state, action) {
      return { ...state, savedAuditData: action.payload, auditSaved: true };
    },

    setSavedAudit(state, action) {
      return { ...state, auditSaved: action.payload };
    },

    setAuditList(state, action) {
      const auditList = [];
      action.payload?.rows.forEach(obj => {
        auditList.push({
          auditNumber: obj.auditNmbr,
          auditName: obj.auditorName,
          auditDate: obj.auditDate,
          crewType: obj.crewType,
          crewNumber: obj.crewNmbr,
          circuitNmbr: obj.circuitNmbr,
          seqAuditRecord: obj.seqAuditRecord,
          seqAuditorNmbr: obj.seqAuditorNmbr,
        });
      });
      const stateValues = { ...state };
      stateValues.auditList = [...auditList];
      stateValues.pagination = {
        ...stateValues.pagination,
        ...action.payload?.pagination,
      };
      return { ...stateValues };
    },

    setContractors(state, action) {
      return { ...state, contractors: action.payload };
    },

    setAuditors(state, action) {
      return { ...state, auditors: action.payload };
    },

    setAuditPagination(state, action) {
      const stateValues = { ...state };
      stateValues.pagination = {
        ...stateValues.pagination,
        ...action.payload,
      };
      return { ...stateValues };
    },

    resetAudit() {
      return { ...initialState };
    },

    unsetError(state) {
      const stateValues = { ...state };
      stateValues.error = false;
      stateValues.errorResponse = {};
      return { ...stateValues };
    },

    setCircuits(state, action) {
      const circuitData = [];
      action.payload.map(item => {
        circuitData.push({ label: item.circuitnum, value: item.circuitnum });
      });
      return { ...state, circuits: action.payload, circuitList: circuitData };
    },

    setError(state, action) {
      const stateValues = { ...state };
      stateValues.error = true;
      stateValues.errorResponse = action.payload;
      return { ...stateValues };
    },
  },
});

export const {
  fetchAuditList,
  fetchAuditDetail,
  fetchContractors,
  fetchAuditors,
  fetchCircuits,
  setAuditPagination,
  setAuditList,
  setContractors,
  setAuditors,
  setCircuits,
  setNewAudit,
  setAuditData,
  resetAudit,
  setError,
  unsetError,
  postAudit,
  updateAudit,
  setSavedAuditData,
  setSavedAudit,
} = auditSlice.actions;

export default auditSlice.reducer;
