import { all, call, put, select, takeLatest } from 'redux-saga/effects';
import * as service from 'services/adminModuleService';
import { trackPromise } from 'react-promise-tracker';
import { PROMISE_TRACKER } from 'constants/promiseTrackers';
import {
  displaySuccess,
  displayError,
} from 'components/common/Alert/ToastAlert';
import {
  fetchdistrictWorkAreaList,
  updatedistrictWorkArea,
  setdistrictWorkAreaData,
  adddistrictWorkArea,
  setError,
  getworkArea,
  setworkArea,
  getdistrictWorkArea,
  setdistrictWorkArea,
  deletdistrictWorkArea,
  fetchSelecteddistrictWorkAreaList,
  setSelecteddistrictWorkAreaRecords,
} from 'store/slices/AdminModules/districtWorkAreaSlice';
import _ from 'lodash';

const trackupdatedistrictWorkAreaDetail = (fn, ...args) =>
  trackPromise(fn(...args), PROMISE_TRACKER.updatedistrictWorkArea);

const trackadddistrictWorkAreaDetail = (fn, ...args) =>
  trackPromise(fn(...args), PROMISE_TRACKER.adddistrictWorkArea);

const districtWorkAreaPaginationSlector = state =>
  state.districtWorkArea.pagination;

export function* getdistrictWorkAreaList(action) {
  const queryParams = _.cloneDeep(
    yield select(districtWorkAreaPaginationSlector),
  );

  try {
    const response = yield call(
      f => service.retrievedistrictWorkAreaList(queryParams),
      action.payload,
    );
    const { data } = response;
    data.pagination = { totalItems: data.count };
    yield put(setdistrictWorkAreaData(data));
  } catch (e) {
    put(setError(true));
  }
}

function* getWorkAreaData(action) {
  try {
    const queryParams = _.cloneDeep(
      yield select(districtWorkAreaPaginationSlector),
    );
    queryParams.limit = -1;
    const response = yield call(f => service.retriveAllWorkAreas(queryParams));
    const { data } = response;
    yield put(setworkArea(data));
  } catch (e) {
    put(setError(true));
  }
}

function* updatedistrictWorkAreaData(action) {
  try {
    const response = yield call(
      trackupdatedistrictWorkAreaDetail,
      f => service.updatedistrictWorkArea(action.payload),
      action.payload,
    );
    if (response.status === 200) {
      displaySuccess('District Work Area record updated!');
    } else {
      displayError('Something went wrong! Please try again');
    }
    if (action.payload?.callback) {
      action.payload.callback(response.data);
    }
    yield call(f => getdistrictWorkAreaList(action));
  } catch (e) {
    put(setError(true));
  }
}

function* adddistrictWorkAreaData(action) {
  try {
    const response = yield call(
      trackadddistrictWorkAreaDetail,
      f => service.adddistrictWorkArea(action.payload),
      action.payload,
    );
    if (response.status === 200) {
      displaySuccess('District work area record added!');
    } else {
      displayError('Something went wrong! Please try again');
    }
    yield call(f => getdistrictWorkAreaList(action));
  } catch (e) {
    put(setError(true));
  }
}

function* getdistrictWorkAreaData(action) {
  try {
    const response = yield call(f =>
      service.retriveWorkArea(action.payload.id),
    );
    const { data } = response;
    yield put(setdistrictWorkArea(data));
    if (action.payload?.callback) {
      action.payload.callback(response.data);
    }
  } catch (e) {
    put(setError(true));
  }
}

function* deletDistrictWorkArea(action) {
  try {
    const response = yield call(f =>
      service.deleteDistrictWorkArea(action.payload),
    );
    const { data } = response;

    if (response.status === 200) {
      displaySuccess('District work area record deleted!');
      yield call(f => getdistrictWorkAreaList(action));
      if (action.payload?.callback) {
        action.payload.callback(response.data);
      }
    } else {
      displayError('Something went wrong! Please try again');
    }
  } catch (e) {
    put(setError(true));
  }
}

function* watchdistrictWorkAreaList() {
  yield takeLatest(fetchdistrictWorkAreaList.type, getdistrictWorkAreaList);
}

function* watchUpdatedistrictWorkArea() {
  yield takeLatest(updatedistrictWorkArea.type, updatedistrictWorkAreaData);
}

function* watchAdddistrictWorkArea() {
  yield takeLatest(adddistrictWorkArea.type, adddistrictWorkAreaData);
}

function* watchGetWorkAreaList() {
  yield takeLatest(getworkArea.type, getWorkAreaData);
}

function* watchGetdistrictWorkArea() {
  yield takeLatest(getdistrictWorkArea.type, getdistrictWorkAreaData);
}

function* watchDeletedistrictWorkArea() {
  yield takeLatest(deletdistrictWorkArea.type, deletDistrictWorkArea);
}

function* getSelecteddistrictWorkAreaList(action) {
  try {
    const response = yield call(
      f => service.retrievedistrictWorkAreaFilterList(action.payload),
      action.payload,
    );
    const { data } = response;
    yield put(setSelecteddistrictWorkAreaRecords(data));
  } catch (e) {
    put(setError(true));
  }
}

function* watchFetchSelecteddistrictWorkAreaList() {
  yield takeLatest(
    fetchSelecteddistrictWorkAreaList.type,
    getSelecteddistrictWorkAreaList,
  );
}

export function* districtWorkAreaSaga() {
  yield all([
    watchdistrictWorkAreaList(),
    watchUpdatedistrictWorkArea(),
    watchGetWorkAreaList(),
    watchAdddistrictWorkArea(),
    watchGetdistrictWorkArea(),
    watchDeletedistrictWorkArea(),
    watchFetchSelecteddistrictWorkAreaList(),
  ]);
}
