// Imports
import ___CSS_LOADER_API_SOURCEMAP_IMPORT___ from "../../../../node_modules/react-scripts/node_modules/css-loader/dist/runtime/sourceMaps.js";
import ___CSS_LOADER_API_IMPORT___ from "../../../../node_modules/react-scripts/node_modules/css-loader/dist/runtime/api.js";
var ___CSS_LOADER_EXPORT___ = ___CSS_LOADER_API_IMPORT___(___CSS_LOADER_API_SOURCEMAP_IMPORT___);
// Module
___CSS_LOADER_EXPORT___.push([module.id, `.form {
  line-height: 2;
  letter-spacing: 0.13px;
}
.form .row {
  margin: 18px 0px !important;
}
.form .heading {
  font-size: 32px;
  color: #4a4a4a;
  font-weight: bold;
}
.form .static-text {
  font-size: 24px;
  color: 0;
  font-weight: normal;
}
.form .common {
  border-bottom: 1px solid #9e9fa1;
  text-align: center;
  padding: 7px;
  margin: 0px 10px;
  font-weight: bold;
  width: 40%;
}
.form .common input {
  border: none;
  width: 100%;
  text-align: center;
  height: 8px;
  font-size: 24px;
  color: 0;
  font-weight: normal;
}

.buttonForm {
  text-align: center;
  display: flex;
  justify-content: end;
  width: auto important;
}`, "",{"version":3,"sources":["webpack://./src/pages/CompletionForm/AffidavitForm/AffidavitForm.scss"],"names":[],"mappings":"AAAA;EACE,cAAA;EACA,sBAAA;AACF;AAAE;EACE,2BAAA;AAEJ;AAAE;EACE,eAAA;EACA,cAAA;EACA,iBAAA;AAEJ;AAAE;EACE,eAAA;EACA,QAAA;EACA,mBAAA;AAEJ;AAAE;EACE,gCAAA;EACA,kBAAA;EACA,YAAA;EACA,gBAAA;EACA,iBAAA;EACA,UAAA;AAEJ;AADI;EACE,YAAA;EACA,WAAA;EACA,kBAAA;EACA,WAAA;EACA,eAAA;EACA,QAAA;EACA,mBAAA;AAGN;;AAQA;EACE,kBAAA;EACA,aAAA;EACA,oBAAA;EACA,qBAAA;AALF","sourcesContent":[".form {\n  line-height: 2;\n  letter-spacing: 0.13px;\n  .row {\n    margin: 18px 0px !important;\n  }\n  .heading {\n    font-size: 32px;\n    color: #4a4a4a;\n    font-weight: bold;\n  }\n  .static-text {\n    font-size: 24px;\n    color: 000000;\n    font-weight: normal;\n  }\n  .common {\n    border-bottom: 1px solid #9e9fa1;\n    text-align: center;\n    padding: 7px;\n    margin: 0px 10px;\n    font-weight: bold;\n    width: 40%;\n    input {\n      border: none;\n      width: 100%;\n      text-align: center;\n      height: 8px;\n      font-size: 24px;\n      color: 000000;\n      font-weight: normal;\n    }\n  }\n}\n// .nav-tabs {\n//   margin: 0px 24px;\n// }\n// .tab-content {\n//   margin: 0px 24px;\n// }\n\n.buttonForm {\n  text-align: center;\n  display: flex;\n  justify-content: end;\n  width: auto important;\n}\n"],"sourceRoot":""}]);
// Exports
___CSS_LOADER_EXPORT___.locals = {};
export default ___CSS_LOADER_EXPORT___;
