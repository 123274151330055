import DatePicker from 'react-datepicker';
import { useState, useEffect } from 'react';
import 'react-datepicker/dist/react-datepicker.css';
import { Form, Row, Col } from 'react-bootstrap';
import './AuditDetails.scss';
import { useDispatch, useSelector } from 'react-redux';
import { setAuditData } from 'store/slices/AuditModules/auditSlice';

const CrewAuditItems = () => {
  const [crewAudit, setCrewAudit] = useState({});
  const dispatch = useDispatch();

  const auditData = useSelector(state => state.audit.auditFormData);

  const handleRadioChange = e => {
    const field = e.target.name.split('-');
    setCrewAudit({ ...crewAudit, [field[0]]: field[1] });
    dispatch(setAuditData({ [field[0]]: field[1] }));
  };

  useEffect(() => {
    if (Object.values(crewAudit).includes('F')) {
      dispatch(setAuditData({ crewAudit: 'F' }));
    } else if (Object.values(crewAudit).includes('P')) {
      dispatch(setAuditData({ crewAudit: 'P' }));
    } else {
      dispatch(setAuditData({ crewAudit: null }));
    }
  }, [crewAudit]);

  return (
    <>
      <div className="aepBreadcrumb auditInnerContainer">
        <Form.Label className="responseLabel">Crew Audit Items</Form.Label>
        <Form.Group controlId="kindOfSelection" className="responseForm">
          <Form.Check
            inline
            type="radio"
            aria-label="radio 1"
            label="Pass"
            className="input1"
            name="crewAudit-P"
            checked={auditData?.crewAudit == 'P'}
          />
          <Form.Check
            inline
            type="radio"
            aria-label="radio 2"
            label="Fail"
            className="input1"
            name="crewAudit-F"
            checked={auditData?.crewAudit == 'F'}
          />
        </Form.Group>
      </div>
      <Form className="auditSection">
        <Row className="mbAudit">
          <Col sm={3}>
            <Form.Group controlId="form.remitAddress">
              <Form.Label className="responseLabel">
                1. Truck Appearance/ID
              </Form.Label>
              <Form.Group
                controlId="kindOfSelection"
                className="responseForm ml-4 mb-3"
              >
                <Form.Check
                  inline
                  type="radio"
                  aria-label="radio 1"
                  label="Pass"
                  onChange={e => handleRadioChange(e)}
                  name="truckAppearence-P"
                  checked={auditData?.truckAppearence === 'P'}
                />
                <Form.Check
                  inline
                  type="radio"
                  aria-label="radio 2"
                  label="Fail"
                  onChange={e => handleRadioChange(e)}
                  name="truckAppearence-F"
                  checked={auditData?.truckAppearence === 'F'}
                />
              </Form.Group>
            </Form.Group>
          </Col>
          <Col sm={3}>
            <Form.Group controlId="form.remitAddress">
              <Form.Label className="responseLabel">
                4.Proffessional Appearance
              </Form.Label>
              <Form.Group
                controlId="kindOfSelection"
                className="responseForm ml-4 mb-3"
              >
                <Form.Check
                  inline
                  type="radio"
                  aria-label="radio 1"
                  label="Pass"
                  onChange={e => handleRadioChange(e)}
                  name="professionalAppearance-P"
                  checked={auditData?.professionalAppearance == 'P'}
                />
                <Form.Check
                  inline
                  type="radio"
                  aria-label="radio 2"
                  label="Fail"
                  onChange={e => handleRadioChange(e)}
                  name="professionalAppearance-F"
                  checked={auditData?.professionalAppearance == 'F'}
                />
              </Form.Group>
            </Form.Group>
          </Col>
          <Col sm={3}>
            <Form.Group controlId="form.remitAddress">
              <Form.Label className="responseLabel">
                7.Crew Properly Equiped
              </Form.Label>
              <Form.Group
                controlId="kindOfSelection"
                className="responseForm ml-4 mb-3"
              >
                <Form.Check
                  inline
                  type="radio"
                  aria-label="radio 1"
                  label="Pass"
                  onChange={e => handleRadioChange(e)}
                  name="crewProperlyEquipped-P"
                  checked={auditData?.crewProperlyEquipped == 'P'}
                />
                <Form.Check
                  inline
                  type="radio"
                  aria-label="radio 2"
                  label="Fail"
                  onChange={e => handleRadioChange(e)}
                  name="crewProperlyEquipped-F"
                  checked={auditData?.crewProperlyEquipped == 'F'}
                />
              </Form.Group>
            </Form.Group>
          </Col>
          <Col sm={3}>
            <Form.Group controlId="form.remitAddress">
              <Form.Label className="responseLabel">
                10. Tree Knowledge
              </Form.Label>
              <Form.Group
                controlId="kindOfSelection"
                className="responseForm ml-4 mb-3"
              >
                <Form.Check
                  inline
                  type="radio"
                  aria-label="radio 1"
                  label="Pass"
                  onChange={e => handleRadioChange(e)}
                  name="treeKnowledge-P"
                  checked={auditData?.treeKnowledge == 'P'}
                />
                <Form.Check
                  inline
                  type="radio"
                  aria-label="radio 2"
                  label="Fail"
                  onChange={e => handleRadioChange(e)}
                  name="treeKnowledge-F"
                  checked={auditData?.treeKnowledge == 'F'}
                />
              </Form.Group>
            </Form.Group>
          </Col>
        </Row>
        <Row className="mbAudit">
          <Col sm={3}>
            <Form.Group controlId="form.remitAddress">
              <Form.Label className="responseLabel">
                2. Number of Working Saws
              </Form.Label>
              <Form.Group
                controlId="kindOfSelection"
                className="responseForm ml-4 mb-3"
              >
                <Form.Check
                  inline
                  type="radio"
                  aria-label="radio 1"
                  label="Pass"
                  onChange={e => handleRadioChange(e)}
                  name="workingSaws-P"
                  checked={auditData?.workingSaws == 'P'}
                />
                <Form.Check
                  inline
                  type="radio"
                  aria-label="radio 2"
                  label="Fail"
                  onChange={e => handleRadioChange(e)}
                  name="workingSaws-F"
                  checked={auditData?.workingSaws == 'F'}
                />
              </Form.Group>
            </Form.Group>
          </Col>
          <Col sm={3}>
            <Form.Group controlId="form.remitAddress">
              <Form.Label className="responseLabel">
                5. Manpower Utilization
              </Form.Label>
              <Form.Group
                controlId="kindOfSelection"
                className="responseForm ml-4 mb-3"
              >
                <Form.Check
                  inline
                  type="radio"
                  aria-label="radio 1"
                  label="Pass"
                  onChange={e => handleRadioChange(e)}
                  name="manpowerUtilization-P"
                  checked={auditData?.manpowerUtilization == 'P'}
                />
                <Form.Check
                  inline
                  type="radio"
                  aria-label="radio 2"
                  label="Fail"
                  onChange={e => handleRadioChange(e)}
                  name="manpowerUtilization-F"
                  checked={auditData?.manpowerUtilization == 'F'}
                />
              </Form.Group>
            </Form.Group>
          </Col>
          <Col sm={3}>
            <Form.Group controlId="form.remitAddress">
              <Form.Label className="responseLabel">
                8. Herbicide Equipment
              </Form.Label>
              <Form.Group
                controlId="kindOfSelection"
                className="responseForm ml-4 mb-3"
              >
                <Form.Check
                  inline
                  type="radio"
                  aria-label="radio 1"
                  label="Pass"
                  onChange={e => handleRadioChange(e)}
                  name="herbicideEquipment-P"
                  checked={auditData?.herbicideEquipment == 'P'}
                />
                <Form.Check
                  inline
                  type="radio"
                  aria-label="radio 2"
                  label="Fail"
                  onChange={e => handleRadioChange(e)}
                  name="herbicideEquipment-F"
                  checked={auditData?.herbicideEquipment == 'F'}
                />
              </Form.Group>
            </Form.Group>
          </Col>
          <Col sm={3}>
            <Form.Group controlId="form.remitAddress">
              <Form.Label className="responseLabel">11. Planning</Form.Label>
              <Form.Group
                controlId="kindOfSelection"
                className="responseForm ml-4 mb-3"
              >
                <Form.Check
                  inline
                  type="radio"
                  aria-label="radio 1"
                  label="Pass"
                  onChange={e => handleRadioChange(e)}
                  name="planning-P"
                  checked={auditData?.planning == 'P'}
                />
                <Form.Check
                  inline
                  type="radio"
                  aria-label="radio 2"
                  label="Fail"
                  onChange={e => handleRadioChange(e)}
                  name="planning-F"
                  checked={auditData?.planning == 'F'}
                />
              </Form.Group>
            </Form.Group>
          </Col>
        </Row>
        <Row className="mbAudit">
          <Col sm={3}>
            <Form.Group controlId="form.remitAddress">
              <Form.Label className="responseLabel">
                3. ROW Equipment/Chipper
              </Form.Label>
              <Form.Group
                controlId="kindOfSelection"
                className="responseForm ml-4 mb-3"
              >
                <Form.Check
                  inline
                  type="radio"
                  aria-label="radio 1"
                  label="Pass"
                  onChange={e => handleRadioChange(e)}
                  name="rowEquipment-P"
                  checked={auditData?.rowEquipment == 'P'}
                />
                <Form.Check
                  inline
                  type="radio"
                  aria-label="radio 2"
                  label="Fail"
                  onChange={e => handleRadioChange(e)}
                  name="rowEquipment-F"
                  checked={auditData?.rowEquipment == 'F'}
                />
              </Form.Group>
            </Form.Group>
          </Col>
          <Col sm={3}>
            <Form.Group controlId="form.remitAddress">
              <Form.Label className="responseLabel">
                6. Customer Relations
              </Form.Label>
              <Form.Group
                controlId="kindOfSelection"
                className="responseForm ml-4 mb-3"
              >
                <Form.Check
                  inline
                  type="radio"
                  aria-label="radio 1"
                  label="Pass"
                  onChange={e => handleRadioChange(e)}
                  name="customerRelations-P"
                  checked={auditData?.customerRelations == 'P'}
                />
                <Form.Check
                  inline
                  type="radio"
                  aria-label="radio 2"
                  label="Fail"
                  onChange={e => handleRadioChange(e)}
                  name="customerRelations-F"
                  checked={auditData?.customerRelations == 'F'}
                />
              </Form.Group>
            </Form.Group>
          </Col>
          <Col sm={3}>
            <Form.Group controlId="form.remitAddress">
              <Form.Label className="responseLabel">
                9. AEP Guidlines/Permits
              </Form.Label>
              <Form.Group
                controlId="kindOfSelection"
                className="responseForm ml-4 mb-3"
              >
                <Form.Check
                  inline
                  type="radio"
                  aria-label="radio 1"
                  label="Pass"
                  onChange={e => handleRadioChange(e)}
                  name="aepGuidelines-P"
                  checked={auditData?.aepGuidelines == 'P'}
                />
                <Form.Check
                  inline
                  type="radio"
                  aria-label="radio 2"
                  label="Fail"
                  onChange={e => handleRadioChange(e)}
                  name="aepGuidelines-F"
                  checked={auditData?.aepGuidelines == 'F'}
                />
              </Form.Group>
            </Form.Group>
          </Col>
          <Col sm={3}>
            <Form.Group controlId="form.remitAddress">
              <Form.Label className="responseLabel">
                12. Use of Forms
              </Form.Label>
              <Form.Group
                controlId="kindOfSelection"
                className="responseForm ml-4 mb-3"
              >
                <Form.Check
                  inline
                  type="radio"
                  aria-label="radio 1"
                  label="Pass"
                  onChange={e => handleRadioChange(e)}
                  name="useOfForms-P"
                  checked={auditData?.useOfForms == 'P'}
                />
                <Form.Check
                  inline
                  type="radio"
                  aria-label="radio 2"
                  label="Fail"
                  onChange={e => handleRadioChange(e)}
                  name="useOfForms-F"
                  checked={auditData?.useOfForms == 'F'}
                />
              </Form.Group>
            </Form.Group>
          </Col>
        </Row>
        <Row className="mbAudit">
          <Col sm={12}>
            <Form.Group controlId="form.remitAddress" className="ml-3">
              <Form.Label className="safetyAudits ml-1">Comments :</Form.Label>
              <Form.Control
                as="textarea"
                value={auditData?.comments5}
                onChange={e =>
                  dispatch(setAuditData({ comments5: e.target.value }))
                }
              />
            </Form.Group>
          </Col>
        </Row>
      </Form>
    </>
  );
};
export { CrewAuditItems };
