import { taskCodeRatesFields } from 'enums/fields.ts';

const downloadExcelServiceTypeValue = 'taskCode';
const serviceType = 'bidrateTaskCodes';

const serviceTypeName = 'Task Code Rates';
const downloadExcelServiceTypeName = serviceTypeName;

//TODO : Remove this
const options = [
  { value: 'Effective End Date', key: 'effEndDate' },
  { value: 'Price($)', key: 'price' },
];

const columnsArray = [
  {
    key: taskCodeRatesFields.taskCode,
    value: 'Code',
    required: true,
    showHeader: true,
  },
  {
    key: taskCodeRatesFields.taskDescription,
    value: 'Description',
    required: true,
    showHeader: true,
  },
  {
    key: taskCodeRatesFields.unitOfMeasure,
    value: 'Unit of Measure',
    required: true,
    showHeader: true,
  },
  {
    key: taskCodeRatesFields.workCategory,
    value: 'Work Category',
    required: true,
    showHeader: true,
  },
  {
    key: taskCodeRatesFields.location,
    value: 'Location',
    required: true,
    showHeader: true,
  },
  {
    key: taskCodeRatesFields.effStartDate,
    value: 'Effective Start Date',
    required: true,
    showHeader: true,
  },
  {
    value: 'Effective End Date',
    key: 'effEndDate',
    required: false,
    showHeader: false,
  },
  { value: 'Price($)', key: 'price', required: false, showHeader: false },
];

let autoFillColumnskeys = {
  code: 'taskCode',
  description: 'taskDescription',
  unitOfMeasure: 'unitOfMeasure',
};

let referenceObject = {
  taskCode: null,
  taskDescription: null,
  unitOfMeasure: null,
  effStartDate: null,
  location: null,
  price: null,
  effEndDate: null,
  bidrateTaskNmbr: null,
  bidrateNmbr: null,
  updatedDate: null,
  updatedBy: null,
  enteredBy: null,
  enteredDate: null,
  workCategory: null,
};

const header = {};
columnsArray.map(item => {
  header[item.key] = item.value;
});
options.map(item => {
  header[item.key] = item.value;
});

const taskCodeExcelHeaderRefObj = [header];

// const taskCodeExcelHeaderRefObj = [
//   {
//     taskCode: 'Task Code',
//     taskDescription: 'Task Description',
//     unitOfMeasure: 'Unit Of Measure',
//     effStartDate: 'Eff Start Date',
//     location: 'Location',
//     price: 'Price',
//     effEndDate: 'Eff End Date',
//     bidrateTaskNmbr: 'Bidrate Task Nmbr',
//     bidrateNmbr: 'Bidrate Nmbr',
//     updatedDate: 'Updated Date',
//     updatedBy: 'Updated By',
//     enteredBy: 'Entered By',
//     enteredDate: 'Entered Date',
//     comments: 'Comments',
//     workCategory: 'Work Category',
//   },
// ];

export {
  options,
  columnsArray,
  referenceObject,
  serviceType,
  serviceTypeName,
  downloadExcelServiceTypeValue,
  downloadExcelServiceTypeName,
  taskCodeExcelHeaderRefObj,
  autoFillColumnskeys,
};
