import React from 'react';

export const LogoutIcon = () => {
  return (
    <svg data-name="Logout Default" width="18" height="18" viewBox="0 0 18 18">
      <path
        data-name="Path 25895"
        d="m17 8-1.41 1.41L17.17 11H9v2h8.17l-1.58 1.58L17 16l4-4zM5 5h7V3H5a2.006 2.006 0 0 0-2 2v14a2.006 2.006 0 0 0 2 2h7v-2H5z"
        transform="translate(-3 -3)"
        style={{ fill: '#4a4a4a' }}
      />
    </svg>
  );
};
