import { CaretDown } from 'assets/images/CaretIcon/CaretDown/CaretDown';
import { CaretLeft } from 'assets/images/CaretIcon/CaretLeft/CaretLeft';
import { CaretRight } from 'assets/images/CaretIcon/CaretRight/CaretRight';
import { CaretUp } from 'assets/images/CaretIcon/CaretUp/CaretUp';
import { useEffect, useState } from 'react';
import { Form } from 'react-bootstrap';
import { useDispatch } from 'react-redux';
import { useMediaQuery } from 'react-responsive';
import './AEPPagination.scss';

const AEPPagination = props => {
  const dispatch = useDispatch();

  const { currentPage, sortBy, sortOrder } = props;
  const { totalItems, itemsPerPage } = props.pagination;
  const isMobile = useMediaQuery({ query: '(max-width: 575px)' });

  const totalPages = Math.ceil((totalItems ?? 0) / itemsPerPage);
  const [selectedPageNumber, setSelectedPageNumber] = useState(1);

  const handlePageChange = pageNumber => {
    dispatch(
      props.setPagination({ ...props.pagination, currentPage: pageNumber }),
    );
    dispatch(props.fetchItems());
  };

  const handlePageNumberChange = e => {
    const page = Number(e?.target?.value ?? 1);
    if (page >= 1 && page <= totalPages) {
      setSelectedPageNumber(page);
      handlePageChange(page);
    }
  };

  const setCurrentPage = operation => {
    const pageNumber = parseInt(currentPage);

    if (
      operation === 'increment' &&
      pageNumber >= 1 &&
      pageNumber < totalPages
    ) {
      handlePageChange(pageNumber + 1);
      setSelectedPageNumber(pageNumber + 1);
    } else if (
      operation === 'decrement' &&
      pageNumber > 1 &&
      pageNumber <= totalPages
    ) {
      handlePageChange(pageNumber - 1);
      setSelectedPageNumber(pageNumber - 1);
    }
  };

  const getDisplayItems = () => {
    return currentPage * itemsPerPage > totalItems
      ? totalItems
      : currentPage * itemsPerPage;
  };

  useEffect(() => {
    dispatch(props.fetchItems());
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [sortBy, sortOrder]);

  useEffect(() => {
    setSelectedPageNumber(currentPage);
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [currentPage]);

  // useEffect(() => {
  //   if (props.pagination.forceFetch) {
  //     dispatch(props.setPagination({ ...props.pagination, forceFetch: false }));
  //     dispatch(props.fetchItems());
  //   }
  // }, [props.pagination.forceFetch]);

  return (
    <div className="aepPagination">
      {isMobile ? (
        <>
          <div
            style={{
              marginLeft: '0px',
              marginRight: '0px',
              border: 'none',
              alignItems: 'center',
              width: 'auto',
            }}
          >
            <div style={{ margin: '0 16px 0 0' }} />
            <div
              style={{ cursor: 'pointer' }}
              onClick={e => setCurrentPage('decrement')}
            >
              <CaretLeft
                style={{ fill: currentPage > 1 ? '#4a4a4a' : '#9e9fa1' }}
              />
            </div>
            <div style={{ margin: '0 16px 0 0' }} />
            <div
              style={{ cursor: 'pointer' }}
              onClick={e => setCurrentPage('increment')}
            >
              <CaretRight
                style={{
                  fill: currentPage < totalPages ? '#4a4a4a' : '#9e9fa1',
                }}
              />
            </div>
          </div>
          <div>
            <Form.Control
              id="paginationSelect"
              value={selectedPageNumber}
              type="number"
              min="1"
              max={totalPages}
              step="1"
              onChange={e => handlePageNumberChange(e)}
            />
            <div className="caretStyle">
              <div
                onClick={e => setCurrentPage('increment')}
                style={{ margin: '-8px 2px -6px' }}
              >
                <CaretUp width="8.5" height="5" />
              </div>
              <div
                onClick={e => setCurrentPage('decrement')}
                style={{ margin: '0 2px -6px' }}
              >
                <CaretDown width="8.5" height="5" />
              </div>
            </div>
          </div>

          <span>
            Displaying {getDisplayItems()} of {totalItems}
          </span>
        </>
      ) : (
        <>
          <span>
            Displaying {getDisplayItems()} of {totalItems}
          </span>
          <div>
            <Form.Control
              id="paginationSelect"
              value={selectedPageNumber}
              type="number"
              min="1"
              max={totalPages}
              onChange={e => handlePageNumberChange(e)}
            />
          </div>
          <div className="caretStyle">
            <div
              style={{ cursor: 'pointer' }}
              onClick={e => setCurrentPage('decrement')}
            >
              <CaretLeft
                style={{ fill: currentPage > 1 ? '#4a4a4a' : '#9e9fa1' }}
              />
            </div>
            <div style={{ margin: '0 16px 0 0' }} />
            <div
              style={{ cursor: 'pointer' }}
              onClick={e => setCurrentPage('increment')}
            >
              <CaretRight
                style={{
                  fill: currentPage < totalPages ? '#4a4a4a' : '#9e9fa1',
                }}
              />
            </div>
          </div>
        </>
      )}
    </div>
  );
};

export { AEPPagination };
