import React from 'react';

export const CheckIcon = ({ width = 28, height = 28 }) => {
  return (
    <svg
      data-name="Check Filled"
      width={width}
      height={height}
      viewBox="0 0 28 28"
    >
      <path data-name="Path 25906" d="M0 0h28v28H0z" style={{ fill: 'none' }} />
      <path
        data-name="Path 25907"
        d="M13.668 2a11.668 11.668 0 1 0 11.669 11.668A11.673 11.673 0 0 0 13.668 2zm-2.333 17.5L5.5 13.668l1.645-1.645 4.19 4.177 8.856-8.856L21.836 9z"
        transform="translate(.332 .332)"
        style={{ fill: '#367600' }}
      />
    </svg>
  );
};
