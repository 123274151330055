import { CaretDown } from 'assets/images/CaretIcon/CaretDown/CaretDown';
import { CaretUp } from 'assets/images/CaretIcon/CaretUp/CaretUp';
import { AEPFilterComponent } from 'components/common/Filter/AEPFilterComponent';
import { AEPPagination } from 'components/common/Pagination/AEPPagination';
import { PROMISE_TRACKER } from 'constants/promiseTrackers';
import { ModuleACL } from 'enums/entitlements.ts';
import { fuelrates } from 'enums/fields.ts';
import { Page } from 'enums/page.ts';
import _ from 'lodash';
import moment from 'moment';
import { useEffect, useRef, useState } from 'react';
import { Col, Form, Row, Table } from 'react-bootstrap';
import { usePromiseTracker } from 'react-promise-tracker';
import { useDispatch, useSelector } from 'react-redux';
import { ClipLoader } from 'react-spinners';
import {
  fetchSelectedAdminFuelRateList,
  fetchfuelRatesList,
  getFuelTypes,
  setfuelRatesPagination,
  updatefuelRates,
} from 'store/slices/AdminModules/fuelRatesSlice';
import { setFuelRatesFilters } from 'store/slices/filterSlice';
import { setActivePage } from 'store/slices/pageSlice';
import { IsAllowedACL } from 'utils/aclHelper';
import './AdminFuelRates.scss';
import { isEmptyVal } from 'utils/utils';

const AdminFuelRates = props => {
  const dispatch = useDispatch();

  const [values, setValues] = useState({});
  const fuelRatesList = useSelector(state => state.fuelRates.fuelRatesList);
  const fuelTypeList = useSelector(state => state.fuelRates.fuelTypeList);
  const currentPage = useSelector(
    state => state.fuelRates.pagination.currentPage,
  );
  const pagination = useSelector(state => state.fuelRates.pagination);
  const sortBy = useSelector(state => state.fuelRates.pagination.sortBy);
  const sortOrder = useSelector(state => state.fuelRates.pagination.sortOrder);
  const user = useSelector(state => state.auth);

  const { promiseInProgress: updateFuelRatesLoading } = usePromiseTracker({
    area: PROMISE_TRACKER.updateFuelRates,
    delay: 0,
  });

  const inputListData = useSelector(state => state.filter.fuelrates);
  const adminCrewFiltersValues = [
    { label: 'EIA U.S. Fuel Type', value: 'fuelType' },
    { label: 'EIA U.S. Fuel Rates', value: 'fuelRate' },
  ];
  const selectedFilterRecordList = useSelector(
    state => state.fuelRates.recordItems,
  );
  const [chipFilterValues, setChipFilterValues] = useState({});
  const [filterChip, setFilterchip] = useState(false);
  const [continueBtnDisable, setContinueBtnDisable] = useState(true);

  const [inEditMode, setInEditMode] = useState({
    status: false,
    rowKey: null,
  });

  const handleDoubleClick = (id, fuelRateNum, e) => {
    setValues({ ...values, ['fuelRateNum']: fuelRateNum });
    if (e.detail == 2) {
      setInEditMode({ status: true, rowKey: id });
    }
  };

  const headers = [
    {
      key: fuelrates.monthyearopco,
      value: 'Month/Year',
      sortkey: 'weekEndDate',
    },
    {
      key: fuelrates.opco,
      value: 'OPCO/State',
      sortkey: 'weekEndDate',
    },
    { key: fuelrates.state, value: 'State', sortkey: 'state' },
    {
      key: fuelrates.fueltype,
      value: 'EIA U.S. Fuel Type',
      sortkey: 'fuelType',
    },
    {
      key: fuelrates.fulerates,
      value: 'EIA U.S. Fuel Rates',
      sortkey: 'fuelRate',
    },
    { key: fuelrates.enteredby, value: 'Entered By', sortkey: 'enteredBy' },
    {
      key: fuelrates.entereddate,
      value: 'Entered Date',
      sortkey: 'enteredDate',
    },
    {
      key: fuelrates.updateddate,
      value: 'Updated Date',
      sortkey: 'updatedDate',
    },
    { key: fuelrates.updatedby, value: 'Updated By', sortkey: 'updatedBy' },
  ];

  const handleSorting = (sortBy, sortOrder) => {
    dispatch(
      setfuelRatesPagination({
        ...pagination,
        currentPage: 1,
        sortBy,
        sortOrder,
      }),
    );
  };

  const ref = useRef();

  const handleChange = (field, value) => {
    setValues({ ...values, [field]: value });
  };

  const objDecorate = obj => {
    let newObj = _.mapKeys(obj, (value, key) => {
      return key;
    });
    return newObj;
  };

  const handleContinueClick = (data = null) => {
    let inputData = _.cloneDeep(inputListData);
    if (!isEmptyVal(data)) {
      inputData = _.cloneDeep(data);
    }

    let selectedFilterData = {};
    let selectedFilterValue = {};
    let uniqueFilterValues = [];

    inputData.map((el, id) => {
      if (uniqueFilterValues.indexOf(el.FilterValue) === -1) {
        uniqueFilterValues.push(el.FilterValue);
      }
    });

    uniqueFilterValues.map(value => {
      let temp1 = [];
      inputData.map((val, ids) => {
        if (val.FilterValue == value) {
          temp1.push(val.selectFilterValue);
        }
      });
      selectedFilterData[value] = temp1;
    });

    inputData.map((el, id) => {
      Object.keys(selectedFilterData).map(function (item) {
        if (item == el.FilterValue) {
          selectedFilterValue[el.FilterLabel] = selectedFilterData[item];
        }
      });
    });
    const selectedFilterDataDecorated = objDecorate(selectedFilterData);
    if (props.resetFilter) {
      dispatch(
        fetchfuelRatesList({ currentPage: 1, fuelType: '', fuelRate: '' }),
      );
    } else {
      dispatch(fetchfuelRatesList(selectedFilterDataDecorated));
    }
    let vendorSelectFilterLabel = [];
    if (selectedFilterValue.Vendor != undefined) {
      selectedFilterValue.Vendor.map((e, id) => {
        inputData.map((val, ind) => {
          if (val.selectFilterValue == e) {
            vendorSelectFilterLabel.push(val.selectFilterLabel);
          }
        });
        selectedFilterValue.Vendor = vendorSelectFilterLabel;
        return selectedFilterValue.Vendor;
      });
    }

    setChipFilterValues(selectedFilterValue);
    setFilterchip(true);
  };

  const handleFilterclose = type => {
    if (type == 'filterChange') {
      setContinueBtnDisable(false);
    } else if (type == 'deleteEmpty') {
      setContinueBtnDisable(true);
    }
  };

  const handleFilterChip = (chipname, chipValue) => {
    let filterchipData = {};

    Object.keys(chipFilterValues).map(function (item, i) {
      let chipList = [...chipFilterValues[item]];
      chipList.map((val, ids) => {
        if (val == chipValue) {
          chipList.splice(ids, 1);
        }
      });
      chipFilterValues[item] = chipList;
    });

    inputListData.map((el, id) => {
      Object.keys(chipFilterValues).map(function (item) {
        if (item == el.FilterLabel) {
          filterchipData[el.FilterValue] = chipFilterValues[item];
        }
      });
    });

    let indextodelete;
    let newInputList1 = _.cloneDeep(inputListData);
    newInputList1.map((val, ids) => {
      if (val.FilterValue === 'vendor' || val.FilterValue === 'vendorid') {
        if (val.FilterLabel == chipname && val.selectFilterLabel == chipValue) {
          indextodelete = ids;
        }
      } else {
        if (val.FilterLabel == chipname && val.selectFilterValue == chipValue) {
          indextodelete = ids;
        }
      }
    });
    newInputList1.splice(indextodelete, 1);

    if (newInputList1.length == 0) {
      setContinueBtnDisable(true);
    }
    dispatch(setFuelRatesFilters(newInputList1));
    setChipFilterValues(chipFilterValues);

    let vendorSelectFilterValue = [];
    if (filterchipData.vendor != undefined) {
      filterchipData.vendor.map((e, id) => {
        inputListData.map((val, ind) => {
          if (val.selectFilterLabel == e) {
            vendorSelectFilterValue.push(val.selectFilterValue);
          }
        });
        filterchipData.vendor = vendorSelectFilterValue;
        return filterchipData.vendor;
      });
    }

    // Check is using "vendorid"
    const filterchipDataDecorated = objDecorate(filterchipData);
    dispatch(fetchfuelRatesList(filterchipDataDecorated));
  };

  useEffect(() => {
    dispatch(setActivePage(Page.ADMIN_MODULES));
    dispatch(getFuelTypes());
    if (props.resetFilter) {
      props.setResetFilter(false);
      handleContinueClick([
        {
          FilterValue: '',
          input_rank: null,
          selectFilterValue: '',
          selectFilterLabel: '',
          presentIcon: '',
          filerForm: false,
          FilterLabel: '',
          selectedFilterRecords: [],
        },
      ]);
    } else {
      handleContinueClick(inputListData);
    }
  }, [dispatch]);

  useEffect(() => {
    const checkIfClickedOutside = e => {
      if (inEditMode.status && ref.current && !ref.current.contains(e.target)) {
        if (values.fuelType || values.fuelRate) {
          dispatch(
            updatefuelRates({
              data: { ...values },
              callback: responseData => {
                if (responseData) {
                  setInEditMode({ status: false, rowKey: null });
                  setValues({});
                }
              },
            }),
          );
        } else {
          setInEditMode({ status: false, rowKey: null });
          setValues({});
        }
      }
    };

    document.addEventListener('mousedown', checkIfClickedOutside);

    return () => {
      document.removeEventListener('mousedown', checkIfClickedOutside);
    };
  }, [inEditMode.status, values]);

  return (
    <>
      <div className="aepContainer adminModuleFuelRatesContainer">
        <div className="tableContainer" ref={ref}>
          <div className="aepBreadcrumb actionPane"></div>
          <Row>
            <Col sm={11}>
              <>
                <div className="filterComponent">
                  <AEPFilterComponent
                    inputListData={inputListData}
                    filterValues={adminCrewFiltersValues}
                    setInputFilterData={setFuelRatesFilters}
                    selectedFilterRecordList={selectedFilterRecordList}
                    fetchSelectedList={fetchSelectedAdminFuelRateList}
                    handleContinueClick={handleContinueClick}
                    continueBtnDisable={continueBtnDisable}
                    handleFilterclose={handleFilterclose}
                    handleFilterChip={handleFilterChip}
                    filterChip={filterChip}
                    chipFilterValues={chipFilterValues}
                  />
                </div>
              </>
            </Col>
          </Row>
          <Table
            hover
            responsive
            striped
            bordered={false}
            className="fuelRatesTbl"
          >
            <thead>
              <tr>
                {headers.map((header, idx) => (
                  <th key={idx}>
                    {sortOrder === 'asc' && (
                      <div
                        id={header.sortkey}
                        onClick={e => handleSorting(header.sortkey, 'desc')}
                      >
                        {header.value}
                        <CaretUp style={{ marginLeft: '12px' }} />
                      </div>
                    )}
                    {sortOrder === 'desc' && (
                      <div
                        id={header.sortkey}
                        onClick={e => handleSorting(header.sortkey, 'asc')}
                      >
                        {header.value}
                        <CaretDown style={{ marginLeft: '12px' }} />
                      </div>
                    )}
                  </th>
                ))}
              </tr>
            </thead>
            <tbody>
              {fuelRatesList &&
                fuelRatesList.map((row, indx) => (
                  <tr
                    key={indx}
                    className="tRow table-hover-row"
                    onClick={e => handleDoubleClick(indx, row.fuelRateNum, e)}
                  >
                    <td>
                      {inEditMode.status && inEditMode.rowKey === indx ? (
                        <Form.Control
                          style={{ width: '150px' }}
                          type="text"
                          readOnly
                          value={
                            moment(row.monthyearopcp).format('MMM-YYYY') || ''
                          }
                          name="monthyearopcp"
                        />
                      ) : (
                        moment(row.monthyearopcp).format('MMM-YYYY')
                      )}
                    </td>
                    <td>
                      {inEditMode.status && inEditMode.rowKey === indx ? (
                        <Form.Control
                          style={{ width: '150px' }}
                          type="text"
                          readOnly
                          value={row.opco}
                          name="state"
                        />
                      ) : (
                        row.opco
                      )}
                    </td>
                    <td>
                      {inEditMode.status && inEditMode.rowKey === indx ? (
                        <Form.Control
                          style={{ width: '150px' }}
                          type="text"
                          readOnly
                          value={row.state}
                          name="state"
                        />
                      ) : (
                        row.state
                      )}
                    </td>
                    <td>
                      {inEditMode.status && inEditMode.rowKey === indx ? (
                        <Form.Select
                          style={{ width: '150px', height: '55px' }}
                          value={
                            values.fuelType ? values.fuelType : row.fuelType
                          }
                          name="fuelType"
                          disabled={
                            !IsAllowedACL(
                              user,
                              ModuleACL.ADMIN,
                              'can_edit_fuelrates_admin',
                            )
                          }
                          onChange={e => {
                            handleChange('fuelType', e.target.value);
                          }}
                        >
                          {fuelTypeList?.map((obj, index) => (
                            <option key={index} value={obj}>
                              {`${obj}`}
                            </option>
                          ))}
                        </Form.Select>
                      ) : (
                        row.fuelType
                      )}
                    </td>
                    <td>
                      {inEditMode.status && inEditMode.rowKey === indx ? (
                        <Form.Control
                          style={{ width: '150px' }}
                          type="text"
                          value={
                            values.fuelRate || values.fuelRate == ''
                              ? values.fuelRate
                              : row.fuelRate
                          }
                          name="fuelRate"
                          disabled={
                            !IsAllowedACL(
                              user,
                              ModuleACL.ADMIN,
                              'can_edit_fuelrates_admin',
                            )
                          }
                          onChange={e => {
                            handleChange('fuelRate', e.target.value);
                          }}
                        />
                      ) : (
                        row.fuelRate
                      )}
                    </td>
                    <td>
                      {inEditMode.status && inEditMode.rowKey === indx ? (
                        <Form.Control
                          style={{ width: '150px' }}
                          readOnly
                          type="text"
                          value={row.enteredBy}
                          name="enteredBy"
                        />
                      ) : (
                        row.enteredBy
                      )}
                    </td>
                    <td>
                      {inEditMode.status && inEditMode.rowKey === indx ? (
                        <Form.Control
                          style={{ width: '150px' }}
                          readOnly
                          type="text"
                          value={moment(row.enteredDate).format('MM/DD/YYYY')}
                          name="enteredDate"
                        />
                      ) : (
                        moment(row.enteredDate).format('MM/DD/YYYY')
                      )}
                    </td>
                    <td>
                      {inEditMode.status && inEditMode.rowKey === indx ? (
                        <Form.Control
                          style={{ width: '150px' }}
                          readOnly
                          type="text"
                          value={moment(row.updatedDate).format('MM/DD/YYYY')}
                          name="updatedDate"
                        />
                      ) : (
                        moment(row.updatedDate).format('MM/DD/YYYY')
                      )}
                    </td>
                    <td>
                      {inEditMode.status && inEditMode.rowKey === indx ? (
                        <Form.Control
                          style={{ width: '150px' }}
                          readOnly
                          type="text"
                          value={row.updatedBy}
                          name="updatedBy"
                        />
                      ) : (
                        row.updatedBy
                      )}
                    </td>
                  </tr>
                ))}
            </tbody>
          </Table>
          <AEPPagination
            fetchItems={fetchfuelRatesList}
            setPagination={setfuelRatesPagination}
            pagination={pagination}
            currentPage={currentPage}
            sortBy={sortBy}
            sortOrder={sortOrder}
          />
        </div>
      </div>
      <ClipLoader
        color="#009cde"
        loading={updateFuelRatesLoading}
        cssOverride={{
          top: '50vh',
          left: '50vw',
          position: 'absolute',
          zIndex: 1,
        }}
        size={50}
        aria-label="Loading Spinner"
        data-testid="loader"
      />
    </>
  );
};
export { AdminFuelRates };
