import React from 'react';

export const AdminIcon = () => {
  return (
    <svg
      xmlns="http://www.w3.org/2000/svg"
      width="18.29"
      height="20.25"
      viewBox="0 0 18.29 20.25"
    >
      <path
        d="M167.353 188.233a1.456 1.456 0 0 0 1.095-.471 1.56 1.56 0 0 0 .45-1.114 1.54 1.54 0 0 0-1.541-1.541 1.557 1.557 0 0 0-1.117.45 1.457 1.457 0 0 0-.469 1.092 1.605 1.605 0 0 0 1.582 1.585zm-.031 3.1a3.213 3.213 0 0 0 1.5-.344 3.314 3.314 0 0 0 1.145-.99 6.278 6.278 0 0 0-1.3-.524 5.036 5.036 0 0 0-1.336-.177 5.215 5.215 0 0 0-1.353.177 5.549 5.549 0 0 0-1.278.525 3.277 3.277 0 0 0 1.128.99 3.172 3.172 0 0 0 1.494.345zm.063 1.613a4.681 4.681 0 0 1-4.653-4.64 4.532 4.532 0 0 1 1.365-3.318 4.656 4.656 0 1 1 3.288 7.959zm-5.273.074h-.2a.731.731 0 0 1-.218-.056 10.461 10.461 0 0 1-5.426-4.026 11.467 11.467 0 0 1-2.241-6.957v-4.847a1.678 1.678 0 0 1 .3-.977 1.719 1.719 0 0 1 .8-.633l6.4-2.389a1.634 1.634 0 0 1 1.185 0l6.4 2.389a1.729 1.729 0 0 1 .8.632 1.668 1.668 0 0 1 .306.978v5.705a5.935 5.935 0 0 0-.844-.382 4.076 4.076 0 0 0-.853-.215v-5.113l-6.4-2.359-6.4 2.358v4.851a9.569 9.569 0 0 0 .611 3.48 10.53 10.53 0 0 0 1.551 2.765 9.624 9.624 0 0 0 2.064 1.962 8.308 8.308 0 0 0 2.149 1.106 3.512 3.512 0 0 0 .5.8 4.278 4.278 0 0 0 .624.66 4.052 4.052 0 0 1-.592.2 2.088 2.088 0 0 1-.517.07zm-.001-9.991z"
        transform="translate(-153.897 -172.897)"
        style={{ fill: '#025a95;stroke:#025a95;stroke-width:.25px' }}
      />
    </svg>
  );
};
