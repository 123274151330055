import React from 'react';

export const TimesheetRightArrowEnableIcon = () => {
  return (
    <svg
      xmlns="http://www.w3.org/2000/svg"
      data-name="Component 999 – 9"
      width="36"
      height="36"
      viewBox="0 0 26 32"
    >
      <rect
        data-name="Rectangle 8463"
        width="26"
        height="32"
        rx="4"
        style={{ fill: '#4a4a4a' }}
      />
      <g data-name="arrow_forward_ios_black_24dp (1)">
        <path
          data-name="Path 28520"
          d="M24 24H0V0h24z"
          style={{ fill: 'none', opacity: '.87' }}
          transform="translate(1 4)"
        />
        <path
          data-name="Path 28521"
          d="M7.38 21.01a1.249 1.249 0 0 0 1.77 0l8.31-8.31a1 1 0 0 0 0-1.41L9.15 2.98a1.252 1.252 0 0 0-1.77 1.77L14.62 12l-7.25 7.25a1.246 1.246 0 0 0 .01 1.76z"
          style={{ fill: '#fff' }}
          transform="translate(1 4)"
        />
      </g>
    </svg>
  );
};

export const TimesheetRightArrowDisableIcon = () => {
  return (
    <svg
      xmlns="http://www.w3.org/2000/svg"
      data-name="Component 999 – 11"
      width="36"
      height="36"
      viewBox="0 0 26 32"
    >
      <rect
        data-name="Rectangle 8463"
        width="26"
        height="32"
        rx="4"
        style={{ fill: '#9e9fa1' }}
      />
      <g data-name="arrow_forward_ios_black_24dp (1)">
        <path
          data-name="Path 28520"
          d="M24 24H0V0h24z"
          style={{ fill: 'none', opacity: '.87' }}
          transform="translate(1 4)"
        />
        <path
          data-name="Path 28521"
          d="M7.38 21.01a1.249 1.249 0 0 0 1.77 0l8.31-8.31a1 1 0 0 0 0-1.41L9.15 2.98a1.252 1.252 0 0 0-1.77 1.77L14.62 12l-7.25 7.25a1.246 1.246 0 0 0 .01 1.76z"
          style={{ fill: '#fff' }}
          transform="translate(1 4)"
        />
      </g>
    </svg>
  );
};

export const TimesheetLeftArrowEnableIcon = () => {
  return (
    <svg
      xmlns="http://www.w3.org/2000/svg"
      data-name="Component 1000 – 9"
      width="26"
      height="32"
      viewBox="0 0 26 32"
    >
      <rect
        data-name="Rectangle 8463"
        width="26"
        height="32"
        rx="4"
        style={{ fill: '#4a4a4a' }}
      />
      <g data-name="arrow_forward_ios_black_24dp (1)">
        <path
          data-name="Path 28520"
          d="M0 24h24V0H0z"
          style={{ fill: 'none', opacity: '.87' }}
          transform="translate(1 4)"
        />
        <path
          data-name="Path 28521"
          d="M17.384 21.01a1.249 1.249 0 0 1-1.77 0L7.3 12.7a1 1 0 0 1 0-1.41l8.31-8.31a1.252 1.252 0 0 1 1.77 1.77L10.144 12l7.25 7.25a1.246 1.246 0 0 1-.01 1.76z"
          transform="translate(.236 4)"
          style={{ fill: '#fff' }}
        />
      </g>
    </svg>
  );
};

export const TimesheetLeftArrowDisableIcon = () => {
  return (
    <svg
      xmlns="http://www.w3.org/2000/svg"
      data-name="Component 1000 – 11"
      width="36"
      height="36"
      viewBox="0 0 26 32"
    >
      <rect
        data-name="Rectangle 8463"
        width="26"
        height="32"
        rx="4"
        style={{ fill: '#9e9fa1' }}
      />
      <g data-name="arrow_forward_ios_black_24dp (1)">
        <path
          data-name="Path 28520"
          d="M0 24h24V0H0z"
          style={{ fill: 'none', opacity: '.87' }}
          transform="translate(1 4)"
        />
        <path
          data-name="Path 28521"
          d="M17.384 21.01a1.249 1.249 0 0 1-1.77 0L7.3 12.7a1 1 0 0 1 0-1.41l8.31-8.31a1.252 1.252 0 0 1 1.77 1.77L10.144 12l7.25 7.25a1.246 1.246 0 0 1-.01 1.76z"
          transform="translate(.236 4)"
          style={{ fill: '#fff' }}
        />
      </g>
    </svg>
  );
};
