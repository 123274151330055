import { markupFields } from 'enums/fields.ts';

const downloadExcelServiceTypeValue = 'markup';
const serviceType = 'bidrateUnionRates';

const serviceTypeName = 'Markups';
const downloadExcelServiceTypeName = 'Markups';

const columnsArray = [
  {
    key: markupFields.billingCode,
    value: 'Billing Code',
    required: true,
    showHeader: true,
  },
  {
    key: markupFields.billingDescription,
    value: 'Description',
    required: true,
    showHeader: true,
  },
  {
    key: markupFields.workCategory,
    value: 'Work Category',
    required: true,
    showHeader: true,
  },
  {
    key: markupFields.location,
    value: 'Location',
    required: true,
    showHeader: true,
  },
  {
    key: markupFields.effStartDate,
    value: 'Effective Start Date',
    required: true,
    showHeader: true,
  },
  {
    value: 'Effective End Date',
    key: markupFields.effEndDate,
    required: false,
    showHeader: false,
  },
  { value: 'Local', key: 'local', required: false, showHeader: false },
  { value: 'Cost($)', key: 'cost', required: false, showHeader: false },
  {
    value: 'ST Percentage(%)',
    key: 'stPercentage',
    required: false,
    showHeader: false,
  },
  {
    value: 'OT/DT Percentage(%)',
    key: 'otDtPercentage',
    required: false,
    showHeader: false,
  },
];

let autoFillColumnskeys = {
  code: 'billingCode',
  description: 'billingDescription',
};

// TODO: Remove this
const options = [
  { value: 'Local', key: 'local' },
  { value: 'ST Percentage(%)', key: 'stPercentage' },
  { value: 'OT/DT Percentage(%)', key: 'otDtPercentage' },
  { value: 'Cost($)', key: 'cost' },
  { value: 'Effective End Date', key: markupFields.effEndDate },
];

let referenceObject = {
  billingCode: null,
  billingDescription: null,
  location: null,
  effStartDate: null,
  local: null,
  cost: null,
  effEndDate: null,
  workCategory: null,
  stPercentage: null,
  otDtPercentage: null,
};

const header = {};
columnsArray.map(item => {
  header[item.key] = item.value;
});
options.map(item => {
  header[item.key] = item.value;
});

const markupExcelHeaderRefObj = [header];

// const markupExcelHeaderRefObj = [
//   {
//     billingCode: 'Billing Code',
//     billingDescription: 'Billing Description',
//     location: 'Location',
//     effStartDate: 'Eff Start Date',
//     local: 'Local',
//     cost: 'Cost',
//     bidrateUnionNmbr: 'Bidrate Union Nmbr',
//     bidrateNmbr: 'Bidrate Nmbr',
//     updatedDate: 'Updated Date',
//     updatedBy: 'Updated By',
//     enteredBy: 'Entered By',
//     enteredDate: 'Entered Date',
//     effEndDate: 'Eff End Date',
//     workCategory: 'Work Category',
//     stPercentage: 'St Percentage',
//     otDtPercentage: 'Ot Dt Percentage',
//     comments: 'Comments',
//     // unionindicator: 'string',
//   },
// ];

export {
  columnsArray,
  options,
  referenceObject,
  serviceType,
  serviceTypeName,
  downloadExcelServiceTypeValue,
  downloadExcelServiceTypeName,
  markupExcelHeaderRefObj,
  autoFillColumnskeys,
};
