import { useEffect, useState } from 'react';
import { Badge, Col, Row, Tab, Table, Tabs } from 'react-bootstrap';
import { useDispatch, useSelector } from 'react-redux';
import { useNavigate } from 'react-router-dom';
import { FilterChipCloseIcon } from 'assets/images/FilterChipCloseIcon/FilterChipCloseIcon';
import { InvoiceDisplayValue } from 'constants/statusValues';
import { Page } from 'enums/page.ts';
import _ from 'lodash';
import { AEPPagination } from 'components/common/Pagination/AEPPagination';
import { CaretDown } from 'assets/images/CaretIcon/CaretDown/CaretDown';
import { CaretUp } from 'assets/images/CaretIcon/CaretUp/CaretUp';
import ClipLoader from 'react-spinners/ClipLoader';
import { ROUTES_STRING } from 'constants/routes';
import {
  fetchInvoicesAnalytics,
  setInputFilterData,
  setInvoicePagination,
  setAnalyticsFilters,
} from 'store/slices/Invoice/invoiceSlice';
import { BackArrowIcon } from 'assets/images/BackArrowIcon/BackArrowIcon';
import { DownloadIcon } from 'assets/images/DownloadIcon/DownloadIcon';
import { PROMISE_TRACKER } from 'constants/promiseTrackers';
import { ModuleACL } from 'enums/entitlements.ts';
import {
  InvoiceActivitiesList,
  InvoiceLaborList,
  InvoiceEquipmentList,
} from 'enums/fields.ts';
import { usePromiseTracker } from 'react-promise-tracker';
import { excelDownloadInvoice } from 'services/excelDownloadInvoice';
import { setActivePage } from 'store/slices/pageSlice';
import { filterAllowedOptionsACL } from 'utils/aclHelper';

const InvoiceAnalyticsList = props => {
  const dispatch = useDispatch();
  const navigate = useNavigate();
  const user = useSelector(state => state.auth);
  const accessToken = useSelector(state => state.auth.accessToken);
  const inputListData = useSelector(state => state.invoice.inputList);
  const invoiceList = useSelector(state => state.invoice.items);

  // End Timesheet list variables

  const [key, setKey] = useState('Activities');
  const [chipFilterValues, setChipFilterValues] = useState({});
  const pagination = useSelector(state => state.invoice.pagination);
  const [tabs, setTabs] = useState([]);
  const currentPage = useSelector(
    state => state.invoice.pagination.currentPage,
  );
  const sortBy = useSelector(state => state.invoice.pagination.sortBy);
  const sortOrder = useSelector(state => state.invoice.pagination.sortOrder);

  const TabKey = {
    ACTIVITIES: 'Activities',
    LABOR: 'Labor',
    EQUIPMENT: 'Equipment',
  };

  const optionsTab = [
    {
      key: TabKey.ACTIVITIES,
      value: 'Activities',
      permission: 'can_view_all_invoice',
    },
    { key: TabKey.LABOR, value: 'Labor', permission: 'can_view_all_invoice' },
    {
      key: TabKey.EQUIPMENT,
      value: 'Equipment',
      permission: 'can_view_all_invoice',
    },
  ];

  const activitiesHeaders = [
    { key: InvoiceActivitiesList.invoiceNumber, value: 'Invoice No' },
    { key: InvoiceActivitiesList.startDate, value: 'Start Date' },
    { key: InvoiceActivitiesList.endDate, value: 'End Date' },
    { key: InvoiceActivitiesList.status, value: 'Status' },
    { key: InvoiceActivitiesList.activitynum, value: 'Activity Number' },
    {
      key: InvoiceActivitiesList.activityDescription,
      value: 'Activity Description',
    },
    { key: InvoiceActivitiesList.laborHrs, value: 'Labor Hours' },
    { key: InvoiceActivitiesList.equipHours, value: 'Equipment Hours' },
    { key: InvoiceActivitiesList.amount, value: 'Amount' },
  ];

  const laborHeaders = [
    { key: InvoiceLaborList.invoiceNumber, value: 'Invoice No' },
    { key: InvoiceLaborList.code, value: 'Code' },
    { key: InvoiceLaborList.name, value: 'Name' },
    { key: InvoiceLaborList.type, value: 'Type' },
    { key: InvoiceLaborList.shift, value: 'Shift' },
    { key: InvoiceLaborList.rate, value: 'Rate' },
    { key: InvoiceLaborList.hours, value: 'Hours' },
    { key: InvoiceLaborList.amount, value: 'Amount' },
  ];

  const equipmentHeaders = [
    { key: InvoiceEquipmentList.invoiceNumber, value: 'Invoice No' },
    { key: InvoiceEquipmentList.equipDescription, value: 'Description' },
    { key: InvoiceEquipmentList.rate, value: 'Rate' },
    { key: InvoiceEquipmentList.hours, value: 'Hours' },
    { key: InvoiceEquipmentList.amount, value: 'Amount' },
  ];
  const { promiseInProgress: fetchAnalyticsDataLoading } = usePromiseTracker({
    area: PROMISE_TRACKER.getAnalyticsData,
    delay: 0,
  });

  const setTab = key => {
    let selectedFilterData = {};
    selectedFilterData['analyticsTab'] = key;
    selectedFilterData.currentPage = 1;
    dispatch(setAnalyticsFilters(selectedFilterData));
    setKey(key);
  };

  const handleSorting = (sortBy, sortOrder) => {
    dispatch(
      setInvoicePagination({
        ...pagination,
        // currentPage: 1,
        sortBy,
        sortOrder,
      }),
    );
    // handleContinueClick(key, true);
  };

  useEffect(() => {
    dispatch(
      setActivePage(
        props.type !== 'poinvoice' ? Page.INVOICE : Page.PURCHASE_ORDER,
      ),
    );

    const optionsAllowed = filterAllowedOptionsACL(
      user,
      ModuleACL.INVOICE,
      optionsTab,
    );
    setTabs(optionsAllowed);

    let selectedFilterData = {};
    let selectedFilterValue = {};
    let uniqueFilterValues = [];
    inputListData.map((el, id) => {
      if (uniqueFilterValues.indexOf(el.FilterValue) === -1) {
        uniqueFilterValues.push(el.FilterValue);
      }
    });
    uniqueFilterValues.map(value => {
      let temp1 = [];
      inputListData.map((val, ids) => {
        if (val.FilterValue == value) {
          temp1.push(val.selectFilterValue);
        }
      });
      selectedFilterData[value] = temp1;
    });
    inputListData.map((el, id) => {
      Object.keys(selectedFilterData).map(function (item) {
        if (item == el.FilterValue && el.FilterLabel) {
          selectedFilterValue[el.FilterLabel] = selectedFilterData[item];
        }
      });
    });
    setChipFilterValues(selectedFilterValue);
  }, [dispatch]);

  const handleFilterChip = (chipname, chipValue) => {
    let filterchipData = {};

    Object.keys(chipFilterValues).map(function (item, i) {
      let chipList = [...chipFilterValues[item]];
      chipList.map((val, ids) => {
        if (val == chipValue) {
          chipList.splice(ids, 1);
        }
      });
      chipFilterValues[item] = chipList;
    });

    inputListData.map((el, id) => {
      Object.keys(chipFilterValues).map(function (item) {
        if (item == el.FilterLabel) {
          filterchipData[el.FilterValue] = chipFilterValues[item];
        }
      });
    });

    let indextodelete;
    let newInputList1 = _.cloneDeep(inputListData);
    newInputList1.map((val, ids) => {
      if (val.FilterValue === 'vendorName') {
        if (val.FilterLabel == chipname && val.selectFilterLabel == chipValue) {
          indextodelete = ids;
        }
      } else {
        if (val.FilterLabel == chipname && val.selectFilterValue == chipValue) {
          indextodelete = ids;
        }
      }
    });
    newInputList1.splice(indextodelete, 1);
    dispatch(setInputFilterData(newInputList1));
    setChipFilterValues(chipFilterValues);

    let vendorSelectFilterValue = [];
    if (filterchipData['Vendor Name'] != undefined) {
      filterchipData['Vendor Name'].map((e, id) => {
        inputListData.map((val, ind) => {
          if (val.selectFilterLabel == e) {
            vendorSelectFilterValue.push(val.selectFilterValue);
          }
        });
        filterchipData['Vendor Name'] = vendorSelectFilterValue;
        return filterchipData['Vendor Name'];
      });
    }

    dispatch(fetchInvoicesAnalytics(filterchipData));
  };

  const backToInvoice = () => {
    navigate(`/${ROUTES_STRING.invoices}`);
  };

  const handleDownloadAnalytics = () => {
    let url = {
      name: 'analytics',
      url: '/invoices/reportDownload',
    };
    excelDownloadInvoice(url, accessToken);
  };

  return (
    <>
      {props.type !== 'poinvoice' && (
        <>
          <div className="aepContainer aepHeader">Invoices</div>
          <div
            className="aepContainer aepBreadcrumb"
            style={{ display: 'flex' }}
          >
            <div className="backArrow" onClick={backToInvoice}>
              <BackArrowIcon />
            </div>
            Cost Details
          </div>
        </>
      )}

      <div className={props.type !== 'poinvoice' ? 'aepContainer' : ''}>
        <Tabs
          id="invoiceListTab"
          activeKey={key}
          onSelect={k => {
            setTab(k);
          }}
        >
          {tabs.map((tab, index) => (
            <Tab
              eventKey={tab.key}
              title={tab.value}
              key={index}
              tabClassName="tab-item"
            >
              <div className="tableContainer">
                <Row style={{ float: 'left' }}>
                  <Col sm={10}>
                    <div className="filterSection">
                      {Object.keys(chipFilterValues).map(
                        (chipName, chipNameIndex) => {
                          return chipFilterValues[chipName].map(
                            (chipValue, chipValueIndex) => {
                              return (
                                <div className="filterChipIcon">
                                  <Badge
                                    bg="light"
                                    text="dark"
                                    className="filterNames"
                                  >
                                    <div>
                                      <span>{chipName}:</span>{' '}
                                      <span>{chipValue}</span>
                                    </div>
                                    <span
                                      className="filtercloseIcon"
                                      onClick={() =>
                                        handleFilterChip(chipName, chipValue)
                                      }
                                    >
                                      <FilterChipCloseIcon />
                                    </span>
                                  </Badge>
                                </div>
                              );
                            },
                          );
                        },
                      )}
                    </div>
                  </Col>
                  <Col sm={2}>
                    {props?.type !== 'poinvoice' && (
                      <div className="feature-btn filterIcons">
                        <div
                          //onClick={handleDownloadAnalytics}
                          className="disable-icon"
                        >
                          <DownloadIcon />
                        </div>
                      </div>
                    )}
                  </Col>
                </Row>
                <Table hover responsive striped bordered={false}>
                  <thead>
                    <tr>
                      {key === 'Activities' &&
                        activitiesHeaders.map((header, idx) => (
                          <th key={idx}>
                            {sortOrder === 'asc' && (
                              <div
                                id={header.key}
                                onClick={e => handleSorting(header.key, 'desc')}
                              >
                                {header.value}
                                <CaretUp style={{ marginLeft: '12px' }} />
                              </div>
                            )}
                            {sortOrder === 'desc' && (
                              <div
                                id={header.key}
                                onClick={e => handleSorting(header.key, 'asc')}
                              >
                                {header.value}
                                <CaretDown style={{ marginLeft: '12px' }} />
                              </div>
                            )}
                          </th>
                        ))}
                      {key === 'Labor' &&
                        laborHeaders.map((header, idx) => (
                          <th key={idx}>
                            {sortOrder === 'asc' && (
                              <div
                                id={header.key}
                                onClick={e => handleSorting(header.key, 'desc')}
                              >
                                {header.value}
                                <CaretUp style={{ marginLeft: '12px' }} />
                              </div>
                            )}
                            {sortOrder === 'desc' && (
                              <div
                                id={header.key}
                                onClick={e => handleSorting(header.key, 'asc')}
                              >
                                {header.value}
                                <CaretDown style={{ marginLeft: '12px' }} />
                              </div>
                            )}
                          </th>
                        ))}
                      {key === 'Equipment' &&
                        equipmentHeaders.map((header, idx) => (
                          <th key={idx}>
                            {sortOrder === 'asc' && (
                              <div
                                id={header.key}
                                onClick={e => handleSorting(header.key, 'desc')}
                              >
                                {header.value}
                                <CaretUp style={{ marginLeft: '12px' }} />
                              </div>
                            )}
                            {sortOrder === 'desc' && (
                              <div
                                id={header.key}
                                onClick={e => handleSorting(header.key, 'asc')}
                              >
                                {header.value}
                                <CaretDown style={{ marginLeft: '12px' }} />
                              </div>
                            )}
                          </th>
                        ))}
                    </tr>
                  </thead>
                  <tbody>
                    {key === 'Activities' &&
                      invoiceList.map((row, index) =>
                        index < invoiceList.length - 1 ? (
                          <tr className="table-hover-row">
                            {activitiesHeaders.map(({ key, value }) => (
                              <td key={index}>
                                {value == 'Status'
                                  ? InvoiceDisplayValue[row[key]]
                                  : value == 'Amount'
                                  ? '$' + row[key]
                                  : row[key]}
                              </td>
                            ))}
                          </tr>
                        ) : (
                          <tr
                            className="table-hover-row"
                            style={{ backgroundColor: '#e4f4fd' }}
                          >
                            <td colSpan={6}>Grand Total</td>
                            <td>{row.laborHoursSum}</td>
                            <td>{row.equipmentHoursSum}</td>
                            <td>${row.activityAmountSum}</td>
                          </tr>
                        ),
                      )}

                    {key === 'Labor' &&
                      invoiceList.map((row, index) =>
                        index < invoiceList.length - 1 ? (
                          <tr className="table-hover-row">
                            {laborHeaders.map(({ key, value }) => (
                              <td key={index}>
                                {value == 'Amount' ? '$' + row[key] : row[key]}
                              </td>
                            ))}
                          </tr>
                        ) : (
                          <tr
                            className="table-hover-row"
                            style={{ backgroundColor: '#e4f4fd' }}
                          >
                            <td colSpan={7}>Total Labor Cost</td>
                            <td>${row.laborAmountSum}</td>
                          </tr>
                        ),
                      )}
                    {key === 'Equipment' &&
                      invoiceList.map((row, index) =>
                        index < invoiceList.length - 1 ? (
                          <tr className="table-hover-row">
                            {equipmentHeaders.map(({ key, value }) => (
                              <td key={index}>
                                {value == 'Amount' ? '$' + row[key] : row[key]}
                              </td>
                            ))}
                          </tr>
                        ) : (
                          <tr style={{ backgroundColor: '#e4f4fd' }}>
                            <td colSpan={4}>Total Equipment Cost</td>
                            <td>${row.equipmentsAmountSum}</td>
                          </tr>
                        ),
                      )}
                  </tbody>
                </Table>
                {tab.key === key && (
                  <AEPPagination
                    fetchItems={fetchInvoicesAnalytics}
                    setPagination={setInvoicePagination}
                    pagination={pagination}
                    currentPage={currentPage}
                    sortBy={sortBy}
                    sortOrder={sortOrder}
                  />
                )}
              </div>
            </Tab>
          ))}
        </Tabs>
        {/* {modalShow && (
          <AEPFilterModal
            show={modalShow}
            setShowModal={setModalShow}
            inputListData={inputListData}
            filterValues={Invoicefilters}
            setInputFilterData={setInputFilterData}
            setAddNewInputFilterData={setAddNewInputFilterData}
            selectedFilterRecordList={selectedFilterRecordList}
            continueBtnDisable={continueBtnDisable}
            handleFilterclose={handleFilterclose}
            type={'invoiceAnalytics'}
          />
        )} */}
      </div>

      <ClipLoader
        color="#009cde"
        loading={fetchAnalyticsDataLoading}
        cssOverride={{
          top: '50vh',
          left: '50vw',
          position: 'absolute',
          zIndex: 1,
        }}
        size={50}
        aria-label="Loading Spinner"
        data-testid="loader"
      />
    </>
  );
};

export { InvoiceAnalyticsList };
