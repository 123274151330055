import React from 'react';

export const TimesheetListViewIconDefault = () => {
  return (
    <svg
      xmlns="http://www.w3.org/2000/svg"
      width="36"
      height="36"
      viewBox="0 0 32 32"
    >
      <g data-name="Group 161645">
        <g
          data-name="🔲🎨 Container l Color"
          style={{ fill: '#fff', stroke: '#4a4a4a', strokeMiterlimit: '10' }}
        >
          <path
            d="M4 0h28v32H4a4 4 0 0 1-4-4V4a4 4 0 0 1 4-4z"
            style={{ stroke: 'none' }}
          />
          <path
            d="M4 .5h27.5v31H4A3.5 3.5 0 0 1 .5 28V4A3.5 3.5 0 0 1 4 .5z"
            style={{ fill: 'none' }}
          />
        </g>
        <path
          data-name="Path 28516"
          d="M0 0h24v24H0z"
          style={{ fill: 'none' }}
          transform="translate(4 4)"
        />
        <path
          data-name="Path 28517"
          d="M4 18h4a1 1 0 0 0 0-2H4a1 1 0 0 0 0 2zM3 7a1 1 0 0 0 1 1h16a1 1 0 0 0 0-2H4a1 1 0 0 0-1 1zm1 6h10a1 1 0 0 0 0-2H4a1 1 0 0 0 0 2z"
          style={{ fill: '#4a4a4a' }}
          transform="translate(4 4)"
        />
      </g>
    </svg>
  );
};

export const SiteID = () => {
  return (
    <svg
      xmlns="http://www.w3.org/2000/svg"
      width="20"
      height="20"
      viewBox="0 0 20 20"
    >
      <path
        id="settings_applications_FILL0_wght300_GRAD0_opsz24"
        d="M149.164-804.569h1.674l.285-1.606a4.408,4.408,0,0,0,.882-.348,3.507,3.507,0,0,0,.729-.539l1.548.529.848-1.434-1.229-1.041a4.231,4.231,0,0,0,.127-.991,4.23,4.23,0,0,0-.127-.991l1.229-1.041-.848-1.434-1.548.529a3.509,3.509,0,0,0-.729-.538,4.408,4.408,0,0,0-.882-.348l-.285-1.606h-1.674l-.285,1.606a4.408,4.408,0,0,0-.882.348,3.509,3.509,0,0,0-.729.538l-1.548-.529-.848,1.434,1.229,1.041a4.23,4.23,0,0,0-.127.991,4.231,4.231,0,0,0,.127.991l-1.229,1.041.848,1.434,1.548-.529a3.507,3.507,0,0,0,.729.539,4.408,4.408,0,0,0,.882.348Zm.837-3.077a2.266,2.266,0,0,1-1.662-.691,2.266,2.266,0,0,1-.691-1.662,2.266,2.266,0,0,1,.691-1.662,2.266,2.266,0,0,1,1.662-.691,2.266,2.266,0,0,1,1.662.691,2.266,2.266,0,0,1,.691,1.662,2.266,2.266,0,0,1-.691,1.662A2.266,2.266,0,0,1,150-807.646ZM142.128-800a2.053,2.053,0,0,1-1.509-.618,2.053,2.053,0,0,1-.618-1.509v-15.747a2.053,2.053,0,0,1,.618-1.509,2.053,2.053,0,0,1,1.509-.618h15.747a2.053,2.053,0,0,1,1.509.618,2.053,2.053,0,0,1,.618,1.509v15.747a2.053,2.053,0,0,1-.618,1.509,2.053,2.053,0,0,1-1.509.618Zm0-1.765h15.747a.346.346,0,0,0,.249-.113.346.346,0,0,0,.113-.249v-15.747a.346.346,0,0,0-.113-.249.346.346,0,0,0-.249-.113H142.128a.346.346,0,0,0-.249.113.346.346,0,0,0-.113.249v15.747a.346.346,0,0,0,.113.249A.346.346,0,0,0,142.128-801.764Zm-.362-16.471v0Z"
        transform="translate(-140.001 819.999)"
        fill="#4a4a4a"
      ></path>
    </svg>
  );
};
