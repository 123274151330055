import { http } from '../services/helpers/BaseApi.ts';

const excelInvoiceDownload = async params => {
  await http.downloadInvoiceFilePDF({
    url: params.url,
    type: 'application/pdf',
    name: params.name,
    extension: 'pdf',
  });
};

export { excelInvoiceDownload };
