import React from 'react';

export const TimesheetIcon = () => {
  return (
    <svg
      data-name="Timesheets Default"
      width="18"
      height="21"
      viewBox="0 0 18 21"
    >
      <g data-name="Group 12492">
        <path
          data-name="Path 25896"
          d="M19 6h-1V1H6v5H5a2.006 2.006 0 0 0-2 2v12a2.006 2.006 0 0 0 2 2h14a2.006 2.006 0 0 0 2-2V8a2.006 2.006 0 0 0-2-2zM8 3h8v3H8zm11 17H5V8h14z"
          style={{ fill: '#4a4a4a' }}
          transform="translate(-3 -1)"
        />
        <path
          data-name="Path 25897"
          d="M12 9a5 5 0 1 0 5 5 5 5 0 0 0-5-5zm0 8.5a3.5 3.5 0 1 1 3.5-3.5 3.5 3.5 0 0 1-3.5 3.5z"
          style={{ fill: '#4a4a4a' }}
          transform="translate(-3 -1)"
        />
        <path
          data-name="Path 25898"
          d="M12.5 11.5h-1v2.71l1.64 1.64.71-.71-1.35-1.35z"
          style={{ fill: '#4a4a4a' }}
          transform="translate(-3 -1)"
        />
      </g>
    </svg>
  );
};
