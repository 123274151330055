export const ROUTES_STRING = {
  purchaseOrders: 'purchaseOrders',
  importTimeSheet: 'importTimesheet',
  timesheets: 'timesheets',
  invoices: 'invoices',
  reports: 'reports',
  bidRateSheets: 'bidRateSheets',
  oauthCallback: 'oauthCallback',
  compatibleUnitManual: 'compatibleUnitManual',
  unitRevisions: 'unitRevisions',
  standardRevisions: 'standardRevisions',
  aepDistributionsStandard: 'aepDistributionsStandard',
  audit: 'audit',
  admin: 'admin',
  login: 'login',
  logout: 'logout',
  cuDownload: 'cuDownload',
};

export const ROUTES = {
  home: '/',
  purchaseOrders: `/${ROUTES_STRING.purchaseOrders}`,
  timesheets: `/${ROUTES_STRING.timesheets}`,
  importTimeSheet: `/${ROUTES_STRING.importTimeSheet}`,
  invoices: `/${ROUTES_STRING.invoices}`,
  reports: `/${ROUTES_STRING.reports}`,
  bidRateSheets: `/${ROUTES_STRING.bidRateSheets}`,
  oauthCallback: `/${ROUTES_STRING.oauthCallback}`,
  compatibleUnitManual: `/${ROUTES_STRING.compatibleUnitManual}`,
  unitRevisions: `/${ROUTES_STRING.unitRevisions}`,
  aepDistributionsStandard: `/${ROUTES_STRING.aepDistributionsStandard}`,
  standardRevisions: `/${ROUTES_STRING.standardRevisions}`,
  audit: `/${ROUTES_STRING.audit}`,
  admin: `/${ROUTES_STRING.admin}`,
  login: `/${ROUTES_STRING.login}`,
  logout: `/${ROUTES_STRING.logout}`,
  cuDownload: `/${ROUTES_STRING.cuDownload}`,
};
